import { Grid } from '@mui/material'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'
import { Fragment } from 'react'
import LightCompactionModal from './LightCompactionModal'

const LightCompaction = ({
    result,
    isView,
    overall,
    setOverall,
    handleCustomRequirementChange,
    handleRequirementChange,
}) => {
    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <JobAccordion
                        title={result?.groupName}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        requirementvalue={overall?.requirements}
                        remarksValue={overall?.remarks}
                        overall={overall}
                        averagevalue={
                            overall?.maximunDryDensity === -Infinity
                                ? '0'
                                : overall?.maximunDryDensity
                        }
                        avgLabel={' Maximum Dry Density '}
                        avgLabel2={' Optimum Moisture Content'}
                        averagevalue2={
                            overall?.maximumWaterContent === -Infinity
                                ? '0'
                                : overall?.maximumWaterContent
                        }
                    >
                        <Grid item xs={12}>
                            <LightCompactionModal
                                result={result}
                                isView={isView}
                                overall={overall}
                                setOverall={setOverall}
                            />
                        </Grid>

                        {/* <Grid item>
                            <Box>
                                <InputLabel>Maximum Dry Density</InputLabel>
                                <TextField
                                    focused
                                    fullWidth
                                    multiline
                                    value={
                                        overall?.maximunDryDensity === -Infinity
                                            ? '0'
                                            : overall?.maximunDryDensity
                                    }
                                    size="small"
                                />
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box>
                                <InputLabel>Maximum Water Content</InputLabel>
                                <TextField
                                    focused
                                    fullWidth
                                    multiline
                                    value={
                                        overall?.maximumWaterContent ===
                                        -Infinity
                                            ? '0'
                                            : overall?.maximumWaterContent
                                    }
                                    size="small"
                                />
                            </Box>
                        </Grid> */}
                    </JobAccordion>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default LightCompaction
