import styled from '@emotion/styled'
import { AddCircle } from '@mui/icons-material'
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import { useContext, useState } from 'react'
import * as Yup from 'yup'
import { valueCalc } from '../../../method/method'
import { requirements } from '../../Requirements'
import ElongationTable from '../ElongationTable'

const initialValues = {
    totalWeight: '',
    sieveSizePassing: '',
    wtRetainedOnISSieve: '',
    guagethickness: '',
    weightOfAggregateRetainedOnGuage: '',
}

const validationSchema = Yup.object().shape({
    totalWeight: Yup.number().required('Required'),
    sieveSizePassing: Yup.string().required('Required'),
    wtRetainedOnISSieve: Yup.number().required('Required'),
    guagethickness: Yup.number().required('Required'),
    weightOfAggregateRetainedOnGuage: Yup.number().required('Required'),
})
const ElongationModal = (props) => {
    const {
        result,
        isView,
        setOverall,
        handleCustomRequirementChange,
        overall,
    } = props
    const handleClose = () => setOpen(false)
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)

    const [data, setData] = useState(result?.data ? result?.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const { finalData, setFinalData } = useContext(AppContext)
    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        let requirementText = requirements[result.groupName]

        arr.push(values)
        let totalElongation = 0
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === 'Elongation Index' && arr.length > 0) {
                arr.forEach((item1) => {
                    let value =
                        Number(
                            item1.weightOfAggregateRetainedOnGuage /
                                item1.totalWeight
                        ) * 100
                    item1.individualElongation = Number(value.toFixed(4))
                    totalElongation += Number(value.toFixed(4))
                })

                item.overall = {
                    ...overall,
                    totalElongation: valueCalc(totalElongation),
                }
                // handleCustomRequirementChange(values?.guagethickness)
            }
            if (item.groupName === result.groupName) {
                item.data = arr
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
        // resetForm()
    }

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props
                    return (
                        <Form>
                            <Modal open={open} onClose={handleClose}>
                                <StyledCard>
                                    <CardHeader title="Elongation Index" />
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Total weight(g),X"
                                                    required
                                                    value={values.totalWeight}
                                                    name="totalWeight"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.totalWeight &&
                                                        touched.totalWeight
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.totalWeight &&
                                                    touched.totalWeight
                                                        ? errors.totalWeight
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={4}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                        Sieve Size Passing (mm)
                                                    </InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={
                                                            values.sieveSizePassing
                                                        }
                                                        name="sieveSizePassing"
                                                        label="Sieve Size Passing (mm)"
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                        }}
                                                        onBlur={handleBlur}
                                                        error={
                                                            errors.sieveSizePassing &&
                                                            touched.sieveSizePassing
                                                        }
                                                    >
                                                        <MenuItem
                                                            value={'25 – 20'}
                                                        >
                                                            25 – 20
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'20 – 16'}
                                                        >
                                                            20 – 16
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'16 – 12.5 '}
                                                        >
                                                            16 – 12.5{' '}
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'12.5 – 10 '}
                                                        >
                                                            12.5 – 10{' '}
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'10 – 6.3 '}
                                                        >
                                                            10 – 6.3{' '}
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <HelperText>
                                                    {errors.sieveSizePassing &&
                                                    touched.sieveSizePassing
                                                        ? errors.sieveSizePassing
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Wt. of aggregate Retained on Sieve (g)"
                                                    value={
                                                        values.wtRetainedOnISSieve
                                                    }
                                                    name="wtRetainedOnISSieve"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.wtRetainedOnISSieve &&
                                                        touched.wtRetainedOnISSieve
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.wtRetainedOnISSieve &&
                                                    touched.wtRetainedOnISSieve
                                                        ? errors.wtRetainedOnISSieve
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={4}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                        Guage thickness (mm)
                                                    </InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={
                                                            values.guagethickness
                                                        }
                                                        name="guagethickness"
                                                        label="Guage thickness (mm)"
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                        }}
                                                        onBlur={handleBlur}
                                                        error={
                                                            errors.guagethickness &&
                                                            touched.guagethickness
                                                        }
                                                    >
                                                        <MenuItem
                                                            value={'40.5'}
                                                        >
                                                            40.5
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'32.4'}
                                                        >
                                                            32.4
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'25.6'}
                                                        >
                                                            25.6
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'20.2'}
                                                        >
                                                            20.2
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'14.7'}
                                                        >
                                                            14.7
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <HelperText>
                                                    {errors.guagethickness &&
                                                    touched.guagethickness
                                                        ? errors.guagethickness
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Weight of aggregate retained on  guage (g), Y"
                                                    value={
                                                        values.weightOfAggregateRetainedOnGuage
                                                    }
                                                    name="weightOfAggregateRetainedOnGuage"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.weightOfAggregateRetainedOnGuage &&
                                                        touched.weightOfAggregateRetainedOnGuage
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.weightOfAggregateRetainedOnGuage &&
                                                    touched.weightOfAggregateRetainedOnGuage
                                                        ? errors.weightOfAggregateRetainedOnGuage
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </StyledCard>
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
            {data && (
                <ElongationTable
                    data={data}
                    setData={setData}
                    finalData={finalData}
                    setFinalData={setFinalData}
                    result={result}
                    setOverall={setOverall}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    overall={overall}
                />
            )}

            {isView !== true && (
                <Grid container justifyContent="end">
                    <Button
                        sx={{ boxShadow: 'none', mt: 2 }}
                        variant="contained"
                        endIcon={<AddCircle />}
                        onClick={handleOpen}
                        size="small"
                    >
                        Add
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default ElongationModal
