import React from 'react'
import { Image, Text, View } from '@react-pdf/renderer'
import { labels, title } from '../TableHeading/RequriementHeading'
import { dataObject } from '../TableHeading/RequirementRowHeading'

//import { StyleSheet } from 'react-native'
import { StyleSheet } from '@react-pdf/renderer'
const labelfontfamily = 'Times-Bold'
const borderColor = '#ffffff'
const borderColor1 = '#d9d9d9'
const headborderColor = '#ffffff'
const paddingLeft = 3
const paddingRight = 3
const reportFont = 'Inconsolata'
const fontSize = 10

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#d9d9d9',
        borderBottomWidth: 1,
        alignItems: 'center',
        border: '1px solid #d9d9d9',
        maxWidth: '100%',
        textAlign: 'center',
        paddingTop: 2,
        flexGrow: 1,
        fontSize: fontSize,
        fontFamily: labelfontfamily,
    },
    
    subHeaderContainer: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#d9d9d9',
        borderBottomWidth: 1,
        alignItems: 'center',
        border: '1px solid #d9d9d9',
        maxWidth: '100%',
        textAlign: 'center',
        paddingTop: 2,
        flexGrow: 1,
        fontSize: fontSize,
        fontFamily: labelfontfamily,
    },
    sNo: {
        width: '10%',
        paddingLeft: 3,
        paddingRight: 3,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    sNo1: {
        width: '10%',
        paddingLeft: paddingLeft,
        paddingRight: paddingRight,
        borderRightColor: borderColor1,
        borderRightWidth: 1,
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        fontFamily: reportFont,
    },
    _data: {
        width: '100%',
        paddingLeft: 5,
        paddingRight: 5,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        display: 'flex',
    },
    _lastData: {
        width: '100%',
        paddingLeft: 5,
        paddingRight: 5,
        textAlign: 'center',
        wordWrap: 'break-word',
        display: 'flex',
    },
    _data1: {
        width: '100%',
        paddingLeft: paddingLeft,
        paddingRight: paddingRight,
        fontFamily: reportFont,
        borderRightColor: borderColor1,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        display: 'flex',
    },
    _lastData1: {
        width: '100%',
        paddingLeft: paddingLeft,
        fontFamily: reportFont,
        paddingRight: paddingRight,
        textAlign: 'center',
        wordWrap: 'break-word',
        display: 'flex',
    },
    container1: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        alignItems: 'center',
        maxWidth: '100%',
        textAlign: 'center',
        flexGrow: 1,
        fontSize: fontSize,
        border: '1px solid #d9d9d9',
        borderTop: 0,
    },
})

const TableComponent = ({ groupName }) => {
   // const dataArray = dataObject[groupName]
   const dataArray = dataObject[groupName] || [];

    const label = labels[groupName]

    //let values = Object.values(label)
    let values = label ? Object.values(label) : [];
    const titles = title[groupName]
    
    let titleValue = titles ? Object.values(titles) : [];
    
    return (
        <View>
            <View style={styles.container}>
                {titleValue.map((value,index)=>(
                  <Text style={styles._lastData}>{value}</Text>
                ))}
           
                {/* <Text style={styles.sNo}>S. No</Text> */}
                <View style={styles.subHeaderContainer}>
                {values.map((value, index) => (
                   
                    <Text
                        key={index}
                        style={
                            index === values.length - 1
                                ? styles._lastData
                                : styles._data
                        }
                    >
                        {value}
                    </Text>
                ))}
                </View>
            </View>
            {dataArray.map((item, rowIndex) => {
                let keys = Object.keys(label)
                return (
                    <View key={rowIndex} style={styles.container1}>
                        {/* <Text style={styles.sNo1}>{rowIndex + 1}</Text> */}
                        {keys.map((key, colIndex) => (
                            <Text
                                key={colIndex}
                                style={
                                    colIndex === keys.length - 1
                                        ? styles._lastData1
                                        : styles._data1
                                }
                            >
                                {item[key] !== undefined
                                    ? item[key].toString()
                                    : '-'}
                            </Text>
                        ))}
                    </View>
                )
            })}
        </View>
    )
}

export default TableComponent
