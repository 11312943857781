const { USERS } = require('../actions/Actions')

const initialState = {
    usersData: [],
}

const UserAction = (state = initialState, action) => {
    switch (action.type) {
        case USERS.SET_USERS_DATA: {
            return {
                ...state,
                usersData: action.payload,
            }
        }
        default: {
            return state
        }
    }
}
export default UserAction
