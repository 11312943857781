//features to be added
export const configLicense = {
    Search: true,
    pdfMailing: true,
    Filtering: true,
    clientPublishing: false,
    pagetoPdfDownload: true,
    pagetoExcelDownload: true,
    dateFilter: true,
    assignees: true,
    projects: true,
    status: true,
    discipline: true,
    dueDateFilter: true,
}
