import {
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { Box } from '@mui/system'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const SieveAnalysisTable = ({
    data,
    setData,
    setFinalData,
    finalData,
    result,
    setOverall,
    overall,
    keyAndLabel,
}) => {
    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        let finenessModulus = 0
        let addedweightretained = 0
        let cumulativeWeightRetained = 0

        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && item.data.length > 0) {
                let a = 0

                _data.forEach((item1) => {
                    //item1.addedretained = item1.addedretained

                    addedweightretained += Number.parseFloat(
                        item1.weightRetainedInput
                    )
                    cumulativeWeightRetained += Number.parseFloat(
                        item1.weightRetainedInput
                    )
                    item1.cumulativeWeightRetained =
                        cumulativeWeightRetained.toString()
                })

                _data.forEach((item1) => {
                    item1.weightRetained =
                        item1.cumulativeWeightRetained === '0'
                            ? item1.cumulativeWeightRetained
                            : (
                                  (item1.cumulativeWeightRetained /
                                      addedweightretained) *
                                  100
                              ).toFixed(2)

                    item1.passing = (100 - item1?.weightRetained).toFixed(2)

                    let value = Number(item1.passing)
                    finenessModulus += Number(value / 100)
                })
            }
            if (item.groupName === result.groupName) {
                item.data = _data
                item.overall = {
                    ...overall,
                    finenessModulus: valueCalc(finenessModulus),
                }
                setOverall(item.overall)
            }
        })

        setFinalData(_finalData)
    }

    const Column = [
        { text: 'Weight Retained', dataField: 'weight_retained' },
        { text: 'Passing', dataField: 'passing' },
    ]

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        {/* <TableCell align="center" >IS Sieve Designation</TableCell> */}
                        {keyAndLabel?.map((v, i) => (
                            <TableCell align="center">{v.label}</TableCell>
                        ))}
                        {/* {Column.map((item, index) => (
                            <TableCell align='center' key={index}>{item.text}</TableCell>
                        ))} */}
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((item, index) => (
                        <TableRow key={index}>
                            {/* <TableCell align="center">{item.is_sieve_designation}</TableCell> */}
                            {keyAndLabel?.map(
                                (v, i) => (
                                    console.log(v, 'v'),
                                    (
                                        <TableCell align="center">
                                            {item[v.key]}
                                        </TableCell>
                                    )
                                )
                            )}
                            {/* {Column.map((subItem, idx) => (
                                <TableCell
                                    align="center"
                                    key={`row-${idx}-index`}
                                >
                                    {valueCalc(item[subItem.dataField])}
                                </TableCell>
                            ))} */}
                            <TableCell align="center">
                                <Button onClick={(e) => handleDelete(index, e)}>
                                    <Icon>delete</Icon>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default SieveAnalysisTable
