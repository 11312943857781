import { JOB } from '../actions/Actions'

const initialState = {
    jobTableData: [],
}

function JobTableAction(state = initialState, action) {
    switch (action.type) {
        case JOB.SET_JOB_TABLE_DATA: {
            return {
                ...state,
                jobTableData: action.payload,
            }
        }
        default: {
            return state
        }
    }
}

export default JobTableAction
