import { Fragment, useContext, useState } from 'react'

import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Modal,
    TextField,
} from '@mui/material'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { AppContext } from 'app/contexts/AppContext'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { valueCalc } from '../../method/method'
import ConcreteCoreTable from './ConcreteCoreTable'

const ConcreteCore = (props) => {
    const { finalData, setFinalData, EditDetails } = useContext(AppContext)

    const {
        result,
        isView,
        overall,
        setOverall,
        handleCustomRequirementChange,
        handleRequirementChange,
    } = props

    const initialValues = {
        sample_id: '',
        core_length: '',
        core_dia: '',
        CA: '',
        core_cursh: '',
        core_weight: '',
        core_comp_strength: '',
        id_ratio: '',
        correction_factor: '',
        corrected_cyl_comp: '',
        equivalent_cube_comp: '',
    }

    const validationSchema = Yup.object().shape({
        sample_id: Yup.string().required('Required'),
        core_length: Yup.number().required('Required'),
        core_dia: Yup.number().required('Required'),
        core_cursh: Yup.number().required('Required'),
        core_weight: Yup.number().required('Required'),
    })

    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [initialState, setInitialState] = useState({ ...initialValues })

    const [data, setData] = useState(result?.data ? result?.data : [])
    // const [overall, setOverall] = useState(
    //     result?.overall ? result?.overall : {}
    // )

    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        arr = applyFormula(arr)
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && arr.length !== 0) {
                let average = 0
                let value = 0
                arr.forEach((item1) => {
                    value += Number(item1.equivalent_cube_comp)
                    average = Number(value / arr.length)
                })

                item.overall = {
                    ...overall,
                    average: average === 0 ? null : Number(average).toFixed(2),
                }
                setOverall(item.overall)
            }
            if (item.groupName === result.groupName) {
                item.data = arr
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
    }
    const applyFormula = (arr) => {
        arr.forEach((item) => {
            item.CA = ((3.14 * (item.core_dia * item.core_dia)) / 4).toFixed(2)
            item.core_comp_strength = (
                (item.core_cursh * 1000) /
                item.CA
            ).toFixed(2)
            item.id_ratio = (item.core_length / item.core_dia).toFixed(2)
            item.correction_factor = (0.11 * item.id_ratio + 0.78).toFixed(2)
            item.corrected_cyl_comp = (item.core_comp_strength * 1.06).toFixed(
                2
            )
            item.equivalent_cube_comp = (
                item.corrected_cyl_comp * 1.25
            ).toFixed(2)
        })
        return arr
    }

    return (
        <Fragment>
            <Grid item>
                <Formik
                    initialValues={initialState}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        } = props
                        return (
                            <Form>
                                <Grid item xs={12}>
                                    <JobAccordion
                                        title={result.groupName}
                                        requirementvalue={overall?.requirements}
                                        remarksValue={overall?.remarks}
                                        handleCustomRequirementChange={
                                            handleCustomRequirementChange
                                        }
                                        handleRequirementChange={
                                            handleRequirementChange
                                        }
                                        overall={overall}
                                        avgLabel={'Average'}
                                        averagevalue={overall?.average}
                                    >
                                        <Grid container spacing={2}>
                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                            >
                                                <StyledCard>
                                                    <CardHeader
                                                        title={result.groupName}
                                                    />
                                                    <CardContent
                                                        sx={{
                                                            m: 4,
                                                        }}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={4}
                                                        >
                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    // disabled
                                                                    name="sample_id"
                                                                    value={
                                                                        values.sample_id
                                                                    }
                                                                    label="Sample ID"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.sample_id &&
                                                                        touched.sample_id
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.sample_id &&
                                                                    touched.sample_id
                                                                        ? errors.sample_id
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    // disabled
                                                                    name="core_length"
                                                                    value={
                                                                        values.core_length
                                                                    }
                                                                    label="Core Length(L in mm)"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.core_length &&
                                                                        touched.core_length
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.core_length &&
                                                                    touched.core_length
                                                                        ? errors.core_length
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    // disabled
                                                                    name="core_dia"
                                                                    value={
                                                                        values.core_dia
                                                                    }
                                                                    label="Core dia (d in mm)"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.core_dia &&
                                                                        touched.core_dia
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.core_dia &&
                                                                    touched.core_dia
                                                                        ? errors.core_dia
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    // disabled
                                                                    name="core_cursh"
                                                                    value={
                                                                        values.core_cursh
                                                                    }
                                                                    label="Core Cursh Load(KN)"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.core_cursh &&
                                                                        touched.core_cursh
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.core_cursh &&
                                                                    touched.core_cursh
                                                                        ? errors.core_cursh
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    // disabled
                                                                    name="core_weight"
                                                                    value={
                                                                        values.core_weight
                                                                    }
                                                                    label="Core Weight(Kg)"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.core_weight &&
                                                                        touched.core_weight
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.core_weight &&
                                                                    touched.core_weight
                                                                        ? errors.core_weight
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button
                                                            size="small"
                                                            onClick={
                                                                handleClose
                                                            }
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            size="small"
                                                            variant="contained"
                                                            type="submit"
                                                            onClick={
                                                                handleSubmit
                                                            }
                                                        >
                                                            Save
                                                        </Button>
                                                    </CardActions>
                                                </StyledCard>
                                            </Modal>

                                            {data && (
                                                <Grid item>
                                                    <ConcreteCoreTable
                                                        data={data}
                                                        setData={setData}
                                                        setFinalData={
                                                            setFinalData
                                                        }
                                                        result={result}
                                                        finalData={finalData}
                                                        setOverall={setOverall}
                                                        overall={overall}
                                                    />
                                                </Grid>
                                            )}

                                            {isView !== true && (
                                                <Grid
                                                    item
                                                    container
                                                    justifyContent="end"
                                                >
                                                    <Button
                                                        sx={{
                                                            boxShadow: 'none',
                                                        }}
                                                        variant="contained"
                                                        endIcon={<AddCircle />}
                                                        onClick={handleOpen}
                                                        size="small"
                                                    >
                                                        Add
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </JobAccordion>
                                </Grid>
                            </Form>
                        )
                    }}
                </Formik>
            </Grid>
        </Fragment>
    )
}

export default ConcreteCore
