import { StyleSheet, Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import React from 'react'

const borderColor = 'black'
const fontSize = '10px'
const fontFamily = 'Times-Bold'

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        backgroundColor: '#ffffff',
        border: '1px solid black',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        textAlign: 'center',
        alignSelf: 'center',
        margin: 2,
        fontSize: fontSize,
        fontFamily: fontFamily,
        wordWrap: 'break-word',
    },
    _data: {
        display: 'flex',
        alignSelf: 'center',
        paddingLeft: 2,

        paddingRight: 5,
        paddingTop: 2,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    _lastData: {
        display: 'flex',
        alignSelf: 'center',
        paddingLeft: 2,
        paddingRight: 5,
        paddingTop: 2,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    data: {
        alignSelf: 'center',

        display: 'flex',
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 2,
        width: '50%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    lastData: {
        alignSelf: 'center',

        display: 'flex',
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 2,
        width: '50%',
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
})

const ReportHeader = ({ details, jobNo, request_type }) => {
    let ulr_no = details?.ulr_no ?? details?.customer_samples?.ulr_no

    let SampleReportingDate = moment(
        details?.customer_samples?.due_date
    ).format('DD-MM-YYYY')

    let reportingDate = moment(details?.invoice_id?.completedOn).format(
        'DD-MM-YYYY'
    )
    let trNo = String(jobNo).replace('TJ', 'TR').replace('J', 'R')

    return (
        <View style={styles.container} fixed>
            <Text style={request_type === 'NABL' ? styles._data : styles.data}>
                Test Report No : {trNo}
            </Text>

            {request_type === 'NABL' && (
                <Text style={styles._data}>ULR No : {ulr_no + 'F'}</Text>
            )}

            <Text style={request_type === 'NABL' ? styles._data : styles.data}>
                Report Date :
                {details?.invoice_id?.completedOn ? reportingDate : '-'}
            </Text>
            <Text
                style={
                    request_type === 'NABL' ? styles._lastData : styles.lastData
                }
                render={({ pageNumber, totalPages }) =>
                    `Pg.No : ${pageNumber} / ${totalPages}`
                }
            />
        </View>
    )
}

export default ReportHeader
