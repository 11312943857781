import {
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material'
import { Box } from '@mui/system'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import React from 'react'
import { valueCalc } from '../../method/method'

const WaterAbsorptionTable = (props) => {
    const {
        data,
        setData,
        setFinalData,
        finalData,
        result,
        overall,
        setOverall,
    } = props

    const [rowsPerPage, setRowsPerPage] = React.useState(5)
    const [page, setPage] = React.useState(0)

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        let avg = 0
        _finalData.forEach((item) => {
            if (item?.groupName === result?.groupName && _data.length !== 0) {
                _data?.forEach((item1) => {
                    let waterAbsorption =
                        (100 * (item1.wetWeight - item1.dryWeight)) /
                        item1.dryWeight
                    avg += Number(waterAbsorption / _data.length)
                })
            }
            if (item.groupName === result.groupName) {
                item.data = _data
                item.overall = {
                    ...overall,
                    average: valueCalc(avg),
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
    }

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Sample ID</TableCell>
                        <TableCell align="center">Length , L (mm)</TableCell>
                        <TableCell align="center">Breadth , B (mm)</TableCell>
                        <TableCell align="center">Height, H (mm)</TableCell>
                        <TableCell align="center">
                            Dry weight, W1 (kg)
                        </TableCell>
                        <TableCell align="center">
                            Wet Weight, W2 (Kg)
                        </TableCell>
                        <TableCell align="center">
                            Water Absorption, 100*(W2-W1)/W1
                        </TableCell>
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data
                        // ?.slice(
                        //     page * rowsPerPage,
                        //     page * rowsPerPage + rowsPerPage
                        // )
                        .map((item, index) => (
                            <TableRow key={index}>
                                <TableCell align="center">
                                    {(item.sampleId = index + 1)}
                                </TableCell>
                                <TableCell align="center">{item.len}</TableCell>
                                <TableCell align="center">
                                    {item.breadth}
                                </TableCell>
                                <TableCell align="center">
                                    {item.height}
                                </TableCell>
                                <TableCell align="center">
                                    {item.dryWeight}
                                </TableCell>
                                <TableCell align="center">
                                    {item.wetWeight}
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        (item.waterAbsorption = valueCalc(
                                            100 *
                                                ((item.wetWeight -
                                                    item.dryWeight) /
                                                    item.dryWeight)
                                        )
                                            ? valueCalc(
                                                  100 *
                                                      ((item.wetWeight -
                                                          item.dryWeight) /
                                                          item.dryWeight)
                                              )
                                            : '0')
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    <Button
                                        onClick={(e) => handleDelete(index, e)}
                                    >
                                        <Icon>delete</Icon>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default WaterAbsorptionTable
