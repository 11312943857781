import { Box, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const ClayLumpsTable = (props) => {
    const { data } = props

    // //Formula
    // data.clay_lumps = valueCalc((data.weight_oven_dry_sample - data.weight_sample_residue) / (data.weight_oven_dry_sample) * 100)

    let clayLumps

    clayLumps = [
        {
            label: 'Weight of Oven dried sample, W (g)',
            value: 'weight_oven_dry_sample',
        },
        {
            label: 'Weight of sample residue after removal of clay lumps,R(g)',
            value: 'weight_sample_residue',
        },
        { label: 'Clay Lumps in % : (W-R)/W*100', value: 'clay_lumps' },
    ]

    const Column = [
        { text: 'Observation', dataField: 'label' },
        { text: 'Result', dataField: 'value' },
    ]

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        {/* <TableCell align="center">Actions</TableCell> */}
                        <TableCell align="center">SI.No</TableCell>
                        {Column.map((item, index) => (
                            <TableCell align="center" key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                {clayLumps?.map((item, index) => (
                    <TableBody>
                        <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{item.label}</TableCell>
                            <TableCell align="center">
                                {data[item.value] ? data[item.value] : '0'}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ))}
            </StyledTable>
        </Box>
    )
}

export default ClayLumpsTable


// const handleDelete = (index, e) => {
//     let _data = data.filter((v, i) => i !== index);
//     setData(_data);
//     let _finalData = finalData;
//     _finalData.forEach(item => {
//         if (item.groupName === result.groupName) {
//             item.overall = _data;
//         }
//     })
//     setFinalData(_finalData);
// }


