import { StyleSheet, Text, View } from '@react-pdf/renderer'
// import { sampleColloectGrade } from 'app/assets/Constant/constant'
// import moment from 'moment'

const fontSize = 10
const labelfontfamily = 'Times-Bold'

// const styles = StyleSheet.create({
//     SampleContainer: {
//         display: 'flex',
//         flexDirection: 'row',
//     },
//     dataContainer: {
//         display: 'flex',
//         flexDirection: 'row',
//         marginTop: 2,
//         maxwidth: '100%',

//         justifyContent: 'space-between',
//     },
//     value: {
//         display: 'flex',
//         fontSize: fontSize,
//         marginTop: 1,
//         flexWrap: 'wrap',
//         flex: 0.8,
//     },
//     label: {
//         display: 'flex',
//         width: '55%',
//         marginTop: 1,

//         fontSize: fontSize,
//         flex: 0.8,
//         fontFamily: labelfontfamily,
//     },

//     container: {
//         justifyContent: 'flex-end',
//         marginTop: 2,
//     },
// })
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row', // Arrange columns horizontally
        justifyContent: 'space-between', // Evenly space columns
    },
    column: {
        flex: 1, // Each column takes equal space
    },
    dataContainer: {
        flexDirection: 'row',
        //marginBottom: 10, // Adjust spacing between data items
        marginTop: 2,
        maxWidth: '100%',
        //justifyContent: 'space-between',
    },

    value: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: fontSize,
        marginTop: 1,
        flexWrap: 'wrap',
        flex: 1.1,
    },
    label: {
        display: 'flex',
        flexDirection: 'row',
        width: '55%',
        marginTop: 1,

        fontSize: fontSize,
        flex: 0.8,
        fontFamily: labelfontfamily,
    },
})

//  {
//      details?.material.name === 'Soil'
//          ? details?.specification + ' ' + details?.material.name
//          : details?.material.name
//  }
const SampleDetails = ({ details, type, titleName, standard_ref }) => {
    console.log(details, 'details')

    let invoiceStatus = details?.invoice_id?.invoiceStatus
    let invoice_no1 = details?.invoice_id?.invoice_no
    let invoiceDetailsStatus = details?.invoice_id?.status
    let paymentPaid = details?.invoice_id?.paymentPaid
    let paymentGP = details?.invoice_id?.paymentGP
    const dataItems = [
        {
            label: `\u2022 Invoice No `,
            value: invoice_no1,
            condition: invoice_no1 && invoiceStatus === 'show',
        },
        {
            label: `\u2022 Status `,
            value: invoiceDetailsStatus,
            condition: invoiceDetailsStatus && invoiceStatus === 'show',
        },
        {
            label: `\u2022 Payment Paid `,
            value: paymentPaid,
            condition: paymentPaid && invoiceStatus === 'show',
        },
        {
            label: `\u2022 Payment GP `,
            value: paymentGP,
            condition: paymentGP && invoiceStatus === 'show',
        },
    ]

    return (
        // <View>
        //     {invoice_no1 && invoiceStatus === 'show' ? (
        //         <View style={styles.dataContainer}>
        //             <Text style={styles.label}>{`\u2022 Invoice No `}</Text>
        //             <Text style={styles.value}>{invoice_no1}</Text>
        //         </View>
        //     ) : null}
        //     {invoiceDetailsStatus && invoiceStatus === 'show' ? (
        //         <View style={styles.dataContainer}>
        //             <Text style={styles.label}>{`\u2022 Status `}</Text>
        //             <Text style={styles.value}>{invoiceDetailsStatus}</Text>
        //         </View>
        //     ) : null}
        //     {paymentPaid && invoiceStatus === 'show' ? (
        //         <View style={styles.dataContainer}>
        //             <Text style={styles.label}>{`\u2022 Payment Paid `}</Text>
        //             <Text style={styles.value}>{paymentPaid}</Text>
        //         </View>
        //     ) : null}
        //     {paymentGP && invoiceStatus === 'show' ? (
        //         <View style={styles.dataContainer}>
        //             <Text style={styles.label}>{`\u2022 Payment GP `}</Text>
        //             <Text style={styles.value}>{paymentGP}</Text>
        //         </View>
        //     ) : null}
        // </View>

        <View style={styles.container}>
            {[0, 1].map(
                (columnIndex) => (
                    console.log(columnIndex, 'column'),
                    (
                        <View key={columnIndex} style={styles.column}>
                            {dataItems
                                .filter(
                                    (item) =>
                                        item.condition !== undefined &&
                                        item.condition !== false &&
                                        item.value !== ''
                                )
                                .filter(
                                    (item, index) => index % 2 === columnIndex
                                ) // Filter odd and even items for each column
                                .map((item, index) => (
                                    <View
                                        key={index}
                                        style={styles.dataContainer}
                                    >
                                        <Text style={styles.label}>
                                            {item.label}
                                        </Text>
                                        <Text style={styles.value}>
                                            {item.value}
                                        </Text>
                                    </View>
                                ))}
                        </View>
                    )
                )
            )}
        </View>
    )
}

export default SampleDetails
