import { Drawer, IconButton } from '@mui/material'
import Divider from '@mui/material/Divider'
import { styled } from '@mui/styles'
import React from 'react'
import { StyledIcon } from '../jobs/StyledComponents/StyledComponents'

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}))
const drawerWidth = '75%'

function DrawerLayout({
    show,
    initialValues,
    toggle,
    callBack,
    state,
    setState,
    openDialog,
    setOpenDialog,
    setDialogMessage,
    dialogMessage,
    handleDialogClose,
    signature1,
    setSignature1,
    component: Component,
}) {
    return (
        <React.Fragment>
            {show && (
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    anchor={'right'}
                    open={show}
                    onClose={toggle}
                    variant="persistent"
                >
                    <DrawerHeader>
                        <IconButton
                            onClick={() => {
                                setState(initialValues)

                                toggle()
                            }}
                        >
                            <StyledIcon>close</StyledIcon>
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <Component
                        callBack={callBack}
                        state={state}
                        setState={setState}
                        toggle={toggle}
                        dialogMessage={dialogMessage}
                        setDialogMessage={setDialogMessage}
                        setOpenDialog={setOpenDialog}
                        openDialog={openDialog}
                        handleDialogClose={handleDialogClose}
                        signature1={signature1}
                        setSignature1={setSignature1}
                    />
                </Drawer>
            )}
        </React.Fragment>
    )
}

export default DrawerLayout
