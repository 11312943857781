export const labels = {
    'Compressive Strength': {
        // id: 'Id',
        density: 'Density in dry condition (kg/m³)',
        compressiveStrengthGrade1: 'Compressive strength, min (N/mm³) Grade1',

        compressiveStrengthGrade2: 'Compressive strength, min (N/mm³) Grade2',

        thermalConductivity: 'Thermal conductivity in W/m.k',
        dryingShrinkage: 'Drying Shrinkage',
    },
    'Block Compressive Strength': {
        type: 'Type',
        grade: 'Grade',
        MinAvgCompStr: 'Min.Avg. Comp.Str (N/mm²)',
        MinIndividualCompStr: 'Min.Individual Comp.Str (N/mm²)',
    },
    'Bricks Compressive Strength': {
        classDesignation: 'Class Designation',
        averageCompressiveStrengthN:
            'Average Compressive Strength Not Less Than N/mm2',
        averageCompressiveStrengthKgf:
            'Average Compressive Strength Not Less Than (Kgf/cm2)(approx)',
    },
    'Bricks Compressive Strength Report': {
        a: 'A',
        forModularBricks: 'For Modular Bricks',
    },
    'Bricks Compressive Strength Reports': {
        b: 'B',
        forNonModularBricks: 'For Non Modular Bricks',
    },
    'Coarse Aggregate Sieve Analysis - 20mm':{
       // specification:'Specification as per IS:383-2016 in respect of 20 mm nominal size aggregate (% passing)',
        graded: 'Graded',
        singleSize: 'Single Size',
    },
    'Coarse Aggregate Sieve Analysis - 12.5mm': {
        graded: 'Graded',
        singleSize: 'Single Size',
    },
    'Fine Aggregate Sieve Analysis - P sand':{
        specification:'Specification as per IS:1542 -1992(RA2019) for Grading of Sand for Internal Wall or External Wall or Ceiling Plaster (Percentage Passing)',
    },
    'Fine Aggregate Sieve Analysis - M sand':{
        zoneI:'Zone-I',
        zoneII:'Zone-II',
        zoneIII:'Zone-III',
    },
    'Fine Aggregate Sieve Analysis - River sand':{
        zoneI:'Zone-I',
        zoneII:'Zone-II',
        zoneIII:'Zone-III',
    },

}

export const title = {
    'Coarse Aggregate Sieve Analysis - 20mm':{
        specification:'Specification as per IS:383-2016 in respect of 20 mm nominal size aggregate (% passing)',
    },
    'Coarse Aggregate Sieve Analysis - 12.5mm':{
        specification:'Specification as per IS:383-2016 in respect of 12.5 mm nominal size aggregate (% passing)',
    },
    'Fine Aggregate Sieve Analysis - M sand':{
        specification:'Specification as per IS:383-2016 for Fine Aggregate (Percentage Passing)',
    },
    'Fine Aggregate Sieve Analysis - River sand':{
        specification:'Specification as per IS:383-2016 for Fine Aggregate (Percentage Passing)',
    },
    
}
