import {
    clearNotificationData,
    fetchNotificationData,
} from 'app/services/AppService'
import { fetchJobTableData } from 'app/services/JobService'
import { NOTIFICATION } from './Actions'

export const getNotificationsData = (props) => (dispatch) => {
    fetchNotificationData(
        props,
        (res) =>
            dispatch({
                type: NOTIFICATION.SET_TEST_NOTIFICATION_DATA,
                payload: res?.data?.data?.docs,
            }),

        (err) => console.log(err)
    )
    // fetchJobTableData(
    //     props,
    //     (res) =>
    //         dispatch({
    //             type: NOTIFICATION.SET_JOB_NOTIFICATION_DATA,
    //             payload: res?.data?.data?.docs,
    //         }),
    //     (err) => console.error(err)
    // )
}

export const getNotificationJobData = (props) => (dispatch) => {
    fetchJobTableData(
        props,
        (res) =>
            dispatch({
                type: NOTIFICATION.SET_JOB_NOTIFICATION_DATA,
                payload: res?.data?.data?.docs,
            }),
        (err) => console.error(err)
    )
}

export const clearNotificationByID = (url, reqBody, callBack) => {
    clearNotificationData(
        url,
        reqBody,
        (res) => callBack(res),
        (err) => console.log(err)
    )
}
