import { ExpandMore } from '@mui/icons-material'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
} from '@mui/material'
import React from 'react'
import { RequirementTextFiled } from './StyledComponents'

const JobAccordion = ({
    children,
    title,
    handleRequirementChange,
    requirementvalue,
    avgLabel,
    averagevalue,
    remarksValue,
    averagevalue2,
    averagevalue3,
    editaverage,
    avgLabel2,
    avgLabel3,
    avgLabel4,
    overall,
}) => {
    return (
        <Accordion>
            <AccordionSummary
                sx={{ bgcolor: '#cdcdcd4f' }}
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Grid item xs={12}>
                    <Typography
                        fontWeight={'bolder'}
                        textTransform="capitalize"
                    >
                        {title}
                    </Typography>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {children}
                    </Grid>

                    <Grid item xs={12}>
                        <RequirementTextFiled
                            handleRequirementChange={handleRequirementChange}
                            requirementvalue={requirementvalue}
                            avgLabel={avgLabel}
                            averagevalue={averagevalue}
                            averagevalue2={averagevalue2}
                            averagevalue3={averagevalue3}
                            editaverage={editaverage}
                            avgLabel2={avgLabel2}
                            avgLabel3={avgLabel3}
                            avgLabel4={avgLabel4}
                            remarksValue={remarksValue}
                            overall={overall}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default JobAccordion
