import jsPDF from 'jspdf'
import 'jspdf-autotable'
import falconLogo from '../../report/assets/images/falcon.png'

const header = 'Falcon Industrial Testing Laboratory Pvt. Ltd'

export const JobPdfTable = (view) => {
    // const unit = 'pt'
    // const size = 'A4' // Use A1, A2, A3 or A4
    // const orientation = 'portrait' // portrait or landscape
    const marginLeft = 77
    const doc = new jsPDF()

    const title = 'Job Data'
    const headers = [['Job No', 'Test', 'Due Date', 'Status', 'Publish Status']]
    const value = view?.map((value) => [
        value.job_no,
        value.material?.name,
        new Date(value?.due_date).toLocaleDateString(),
        value.status,
        value.publish ? 'True' : 'False',
    ])
    var totalPagesExp = '{total_pages_count_string}'
    var pageContent = function (rows) {
        var str = 'Page ' + rows.pageCount

        if (typeof doc.putTotalPages === 'function') {
            str = str + ' of ' + totalPagesExp
        }
        doc.setFontSize(10)
        doc.text(
            str,
            rows.settings.margin.left,
            doc.internal.pageSize.height - 10
        )
    }
    let content = {
        startY: 31,
        head: headers,
        body: value,
        addPageContent: pageContent,
    }
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(20)
    doc.text(header, 30, 10)
    doc.setFontSize(22)
    doc.setFont('helvetica')
    // doc.addImage(falconLogo, 'JPEG', 15, 2, 180, 32)
    doc.line(15, 14, 195, 14)
    doc.text(title, marginLeft, 25)
    if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp)
    }
    doc.autoTable(content)
    window.open(doc.output('bloburl'), '_blank')
    // doc.save('report.pdf')
}

export const AllJobPdfTable = (data) => {
    // const unit = 'pt'
    const size = 'A4' // Use A1, A2, A3 or A4
    const orientation = 'portrait' // portrait or landscape
    const marginLeft = 77
    const doc = new jsPDF()
    const title = 'All Job Data'

    const headers = [['Job No', 'Test', 'Due Date', 'Status', 'Publish Status']]
    const value = data?.map((value) => [
        value.job_no,
        value.material?.name,
        new Date(value?.due_date).toLocaleDateString(),
        value.status,
        value.publish ? 'True' : 'False',
    ])
    var totalPagesExp = '{total_pages_count_string}'
    var pageContent = function (rows) {
        var str = 'Page ' + rows.pageCount

        if (typeof doc.putTotalPages === 'function') {
            str = str + ' of ' + totalPagesExp
        }
        doc.setFontSize(10)
        doc.text(
            str,
            rows.settings.margin.left,
            doc.internal.pageSize.height - 10
        )
    }
    let content = {
        startY: 51,
        head: headers,
        body: value,

        addPageContent: pageContent,
    }
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(20)
    doc.text(header, 30, 10)

    doc.setFontSize(22)

    doc.setFont('helvetica')
    // doc.addImage(falconLogo, 'JPEG', 15, 2, 180, 32)
    doc.line(15, 14, 195, 14)
    doc.text(title, marginLeft, 20)

    doc.autoTable(content)
    var totalPagesExp = '{total_pages_count_string}'

    if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp)
    }
    doc.output('table.pdf')
    // doc.save('report.pdf')
    window.open(doc.output('bloburl'), '_blank')
}

export const TestPdfTable = (view) => {
    // const unit = 'pt'
    const size = 'A4' // Use A1, A2, A3 or A4
    const orientation = 'portrait' // portrait or landscape
    const marginLeft = 77
    const doc = new jsPDF()
    const title = 'Test Data'
    const headers = [
        [
            'Created At',
            'Job No',
            'Cutomer Name',
            'Review By',
            'Project Name',
            'Test Group',
            'Due Date',
            'Status',
            'Publish Status',
        ],
    ]
    const value = view?.map((value) => [
        new Date(value?.createdAt).toLocaleDateString(),
        value.job_no,
        value?.customer?.name,
        value.reviewer_by?.name,
        value?.projects?.join(', '),
        value?.group?.name,
        new Date(value?.due_date).toLocaleDateString(),
        value.status,
        value.publish ? 'True' : 'False',
    ])
    var totalPagesExp = '{total_pages_count_string}'

    var pageContent = function (rows) {
        var str = 'Page ' + rows.pageCount

        if (typeof doc.putTotalPages === 'function') {
            str = str + ' of ' + totalPagesExp
        }
        doc.setFontSize(10)
        doc.text(
            str,
            rows.settings.margin.left,
            doc.internal.pageSize.height - 10
        )
    }
    let content = {
        startY: 31,
        head: headers,
        body: value,
        addPageContent: pageContent,
        columnStyles: {
            0: { cellWidth: 22 }, // Created At
            1: { cellWidth: 20 }, // Job No
            2: { cellWidth: 22 }, // Customer Name
            3: { cellWidth: 22 }, // Review By
            4: { cellWidth: 22 }, // Project Name
            5: { cellWidth: 20 }, // Test Group
            6: { cellWidth: 22 }, // Due Date
            7: { cellWidth: 20 }, // Status
            8: { cellWidth: 20 }, // Publish Status
        },
    }
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(20)
    doc.text(header, 30, 10)

    doc.setFontSize(22)

    doc.setFont('helvetica')
    // doc.addImage(falconLogo, 'JPEG', 15, 2, 180, 32)
    doc.line(15, 14, 195, 14)
    doc.text(title, marginLeft, 25)

    doc.autoTable(content)

    if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp)
    }
    doc.output('table.pdf')

    window.open(doc.output('bloburl'), '_blank')

    // doc.save('report.pdf')
}
export const AllTestPdfTable = (data) => {
    // const unit = 'pt'
    const size = 'A4' // Use A1, A2, A3 or A4
    const orientation = 'portrait' // portrait or landscape
    const marginLeft = 77
    const doc = new jsPDF()
    const title = 'All Test Data'

    const headers = [
        [
            'Created At',
            'Job No',
            'Cutomer Name',
            'Review By',
            'Project Name',
            'Test Group',
            'Due Date',
            'Status',
            'Publish Status',
        ],
    ]
    const value = data?.map((value) => [
        new Date(value?.createdAt).toLocaleDateString(),
        value.job_no,
        value?.customer?.name,
        value.reviewer_by?.name,
        value?.projects?.join(', '),
        value?.group?.name,
        new Date(value?.due_date).toLocaleDateString(),
        value.status,
        value.publish ? 'True' : 'False',
    ])
    var totalPagesExp = '{total_pages_count_string}'
    var pageContent = function (rows) {
        var str = 'Page ' + rows.pageCount

        if (typeof doc.putTotalPages === 'function') {
            str = str + ' of ' + totalPagesExp
        }
        doc.setFontSize(10)
        doc.text(
            str,
            rows.settings.margin.left,
            doc.internal.pageSize.height - 10
        )
    }
    let content = {
        startY: 31,
        head: headers,
        body: value,

        addPageContent: pageContent,
        columnStyles: {
            0: { cellWidth: 22 }, // Created At
            1: { cellWidth: 20 }, // Job No
            2: { cellWidth: 22 }, // Customer Name
            3: { cellWidth: 22 }, // Review By
            4: { cellWidth: 22 }, // Project Name
            5: { cellWidth: 20 }, // Test Group
            6: { cellWidth: 22 }, // Due Date
            7: { cellWidth: 20 }, // Status
            8: { cellWidth: 20 }, // Publish Status
        },
    }
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(20)
    doc.text(header, 30, 10)

    doc.setFontSize(22)

    doc.setFont('helvetica')
    // doc.addImage(falconLogo, 'JPEG', 15, 2, 180, 32)
    doc.line(15, 14, 195, 14)
    doc.text(title, marginLeft, 25)

    doc.autoTable(content)

    if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp)
    }
    doc.output('table.pdf')
    // doc.save('report.pdf')
    window.open(doc.output('bloburl'), '_blank')
}
