import { Grid } from '@mui/material'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'
import { Fragment } from 'react'
import BulkDensityModal from './BulkDensityModal'

const BulkDensity = ({
    result,
    isView,
    overall,
    setOverall,
    handleCustomRequirementChange,
    handleRequirementChange,
}) => {
    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <JobAccordion
                        title={result.groupName}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        requirementvalue={overall?.requirements}
                        remarksValue={overall?.remarks}
                        overall={overall}
                    >
                        <Grid item xs={12}>
                            <BulkDensityModal
                                result={result}
                                isView={isView}
                                overall={overall}
                                setOverall={setOverall}
                            />
                        </Grid>
                    </JobAccordion>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default BulkDensity
