import {
    Box,
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const CompressiveStrengthTable = ({
    data,
    setData,
    setFinalData,
    finalData,
    result,
    setOverall,
    overall,
    materialName,
}) => {
    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        let average = 0

        let threeDays = 0
        let threeDaysCount = 0
        let threeDaysAvg = 0
        let threeDaysRemarks = ''
        let threeDaysRequirements = ''

        let sevenDays = 0
        let sevenDaysCount = 0
        let sevenDaysAvg = 0
        let sevenDayRemarks = ''
        let sevenDaysRequirements = ''

        let twentyEightDays = 0
        let twentyEightDaysCount = 0
        let twentyEightDaysAvg = 0
        let twentyEightDaysRemarks = ''
        let twentyEightDaysRequirements = ''

        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && item.data.length > 0) {
                item.data = _data
                if (item.data.length > 0) {
                    _data.forEach((item1) => {
                        let value = Number(item1.comp_st / _data.length)
                        average += Number(value.toFixed(3))
                        if (item1?.days === '3days') {
                            threeDays += Number(item1.comp_st)
                            threeDaysCount++

                            threeDaysAvg = threeDays / threeDaysCount
                            threeDaysRemarks = item1?.remarks
                            threeDaysRequirements = item?.requirement
                        }
                        if (item1?.days === '7days') {
                            sevenDays += Number(item1.comp_st)
                            sevenDaysCount++

                            sevenDaysAvg = sevenDays / sevenDaysCount
                            sevenDayRemarks = item1?.remarks
                            sevenDaysRequirements = item?.requirement
                        }
                        if (item1?.days === '28days') {
                            twentyEightDays += Number(item1.comp_st)
                            twentyEightDaysCount++

                            twentyEightDaysAvg =
                                twentyEightDays / twentyEightDaysCount
                            twentyEightDaysRemarks = item1?.remarks
                            twentyEightDaysRequirements = item?.requirement
                        }
                    })
                }
            }
            if (item.groupName === result.groupName) {
                item.data = _data
                item.overall = {
                    ...overall,
                    avgComp_st: valueCalc(average),
                    threeDaysAvg:
                        threeDaysAvg === 0 ? null : valueCalc(threeDaysAvg),
                    threeDaysRemarks: threeDaysRemarks,
                    threeDaysRequirements: threeDaysRequirements,
                    sevenDaysAvg:
                        sevenDaysAvg === 0 ? null : valueCalc(sevenDaysAvg),
                    sevenDayRemarks: sevenDayRemarks,
                    sevenDaysRequirements: sevenDaysRequirements,
                    twentyEightDaysAvg:
                        twentyEightDaysAvg === 0
                            ? null
                            : valueCalc(twentyEightDaysAvg),
                    twentyEightDaysRemarks: twentyEightDaysRemarks,
                    twentyEightDaysRequirements: twentyEightDaysRequirements,
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
    }

    const Column = [
        { text: 'DAYS', dataField: 'days' },
        { text: 'Weight (kg)', dataField: 'weight' },
        { text: 'Cross sectional area, A (mm²)', dataField: 'crossArea' },

        { text: 'Load (kN)', dataField: 'load' },
        {
            text: 'Comp.St (N/mm²)',
            dataField: 'comp_st',
        },
    ]
    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        {Column.map((item, index) => (
                            <TableCell align={'center'} key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                        {materialName == 'Cement' && (
                            <TableCell align={'center'}>Requirement</TableCell>
                        )}
                        {materialName == 'Cement' && (
                            <TableCell align={'center'}>Remarks</TableCell>
                        )}

                        <TableCell sx={{ width: 80 }} align={'center'}>
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((item, index) => (
                        <TableRow key={index}>
                            {Column.map((subItem, idx) => (
                                <TableCell
                                    align="center"
                                    key={`row-${idx}-index`}
                                >
                                    {item[subItem.dataField]}
                                </TableCell>
                            ))}

                            {materialName == 'Cement' && (
                                <TableCell align="center" key={index}>
                                    {item?.requirement}
                                </TableCell>
                            )}
                            {materialName == 'Cement' && (
                                <TableCell align="center" key={index}>
                                    {item?.remarks}
                                </TableCell>
                            )}
                            <TableCell>
                                <Button onClick={(e) => handleDelete(index, e)}>
                                    <Icon>delete</Icon>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default CompressiveStrengthTable
