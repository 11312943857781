import React from 'react'

import {
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material'
import { Box } from '@mui/system'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const NaturalMoistureContentTable = ({
    data,
    setData,
    setFinalData,
    finalData,
    result,
    overall,
    setOverall,
}) => {
    const [rowsPerPage, setRowsPerPage] = React.useState(5)
    const [page, setPage] = React.useState(0)

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    //formula

    const natural_moisture_content = [
        { text: 'Weight of Wet Soil, W1', value: 'wt_wet_soil' },
        {
            text: 'Weight of Dry soil, W2',
            value: 'dry_wt_soil',
        },
        {
            text: 'Natural Moisture Content, 100*(W1-W2)/W2',
            value: 'natural_moisture',
        },
    ]

    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        let avg = 0

        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && _data.length !== 0) {
                _data?.forEach((item1) => {
                    let total = valueCalc(
                        (100 * (item1.wt_wet_soil - item1.dry_wt_soil)) /
                            item1.dry_wt_soil
                    )
                    avg += Number(total / _data.length)
                })
            }
            if (item.groupName === result.groupName) {
                item.data = _data
                item.overall = {
                    ...overall,
                    average: valueCalc(avg),
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
    }

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">S.No</TableCell>
                        {natural_moisture_content?.map((item, index) => (
                            <TableCell align="center">{item.text} </TableCell>
                        ))}
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {data?.map((item, index) => (
                        <TableRow>
                            <TableCell align="center">{index + 1}</TableCell>

                            <TableCell align="center">
                                {item.wt_wet_soil}{' '}
                            </TableCell>
                            <TableCell align="center">
                                {item.dry_wt_soil}{' '}
                            </TableCell>
                            <TableCell align="center">
                                {' '}
                                {item.natural_moisture}{' '}
                            </TableCell>
                            <TableCell align="center">
                                <Button onClick={(e) => handleDelete(index, e)}>
                                    <Icon>delete</Icon>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default NaturalMoistureContentTable
