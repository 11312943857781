import {
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material'
import { Box } from '@mui/system'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import React from 'react'
import { valueCalc } from '../../method/method'

const CompressiveStrengthTable = (props) => {
    const {
        data,
        setData,
        setFinalData,
        finalData,
        result,
        overall,
        setOverall,
    } = props

    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        let avg = 0
        _finalData.forEach((item) => {
            if (item?.groupName === result?.groupName && _data.length !== 0) {
                _data?.forEach((item1) => {
                    let crossArea = item1?.len * item1?.breadth
                    let compStrength = (item1?.crushingLoad * 1000) / crossArea
                    avg += Number(compStrength / _data.length)
                })
            }
            if (item.groupName === result.groupName) {
                item.data = _data
                item.overall = {
                    ...overall,
                    average: valueCalc(avg),
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
    }

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Sample ID</TableCell>
                        <TableCell align="center">Length , L (mm)</TableCell>
                        <TableCell align="center">Breadth , B (mm)</TableCell>
                        <TableCell align="center"> Height, H (mm) </TableCell>
                        <TableCell align="center"> Weight, W (kg) </TableCell>
                        <TableCell align="center">
                            Cross sectional area, A=L*B (mm²)
                        </TableCell>
                        <TableCell align="center">
                            Crushing load, P (kN)
                        </TableCell>
                        <TableCell align="center">
                            Compressive Strength, P/A (N/mm²)
                        </TableCell>
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, index) => (
                        <TableRow>
                            <TableCell align="center">
                                {(item.sampleId = index + 1)}
                            </TableCell>
                            <TableCell align="center">{item.len}</TableCell>
                            <TableCell align="center">{item.breadth}</TableCell>
                            <TableCell align="center">{item.height}</TableCell>
                            <TableCell align="center">{item.weight}</TableCell>
                            <TableCell align="center">
                                {item.crossArea}
                            </TableCell>
                            <TableCell align="center">
                                {item.crushingLoad}
                            </TableCell>
                            <TableCell align="center">
                                {item.compStrength}
                            </TableCell>
                            <TableCell align="center">
                                <Button onClick={(e) => handleDelete(index, e)}>
                                    <Icon>delete</Icon>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default CompressiveStrengthTable
