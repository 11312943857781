import { DropzoneArea } from 'react-mui-dropzone'
//import React from 'react'
import React, { useState } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

function Dropzone({ saveFile, base64Files, signature1, setSignature1 }) {
    const [open, setOpen] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')

    const handleClick = (message) => {
        setAlertMessage(message)
        setOpen(true)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpen(false)
    }
    // const handleChange = (files) => {
    // saveFile(files)
    // }
    const handleChange = async (files) => {
        if (files[0]) {
            const isValidDimensions = await validateImageDimensions(files[0]) // Adjust maxWidth and maxHeight as needed
            if (isValidDimensions) {
                if (!signature1) {
                    handleClick('Added Successfully!!!')
                }
                saveFile(files)
            } else {
                alert('Image for Signature should be in horzonital view.')
                saveFile('')
            }
            setSignature1(false)
        }
    }

    return (
        <>
            {base64Files[0] ? (
                <DropzoneArea
                    onChange={handleChange}
                    filesLimit={1}
                    showPreviews={false}
                    initialFiles={base64Files}
                    maxFileSize={90000000}
                    key="1"
                    showAlerts={false}
                />
            ) : (
                <DropzoneArea
                    key="2"
                    filesLimit={1}
                    onChange={handleChange}
                    showPreviews={false}
                    maxFileSize={90000000}
                    showAlerts={false}
                />
            )}
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity="success"
                    sx={{ width: '100%' }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Dropzone

function validateImageDimensions(file) {
    return new Promise((resolve) => {
        const img = new Image()
        img.src = URL.createObjectURL(file)
        img.onload = () => {
            const width = img.width
            const height = img.height
            if (width > height) {
                resolve(true)
            } else {
                resolve(false)
            }
        }
    })
}

export const getBase64 = (file) => {
    return new Promise((resolve) => {
        let baseURL = ''
        // Make new FileReader
        let reader = new FileReader()

        // Convert the file to base64 text
        reader.readAsDataURL(file)

        // on reader load somthing...
        reader.onload = () => {
            // Make a fileInfo Object
            baseURL = reader.result

            resolve(baseURL)
        }
    })
}
