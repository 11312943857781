import { useContext, useState } from 'react'

import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Modal,
    TextField,
    Typography,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { valueCalc } from '../../method/method'
import DryingShrinkageNewTable from './DryingShrinkageNewTable'
import { number } from 'prop-types'

const initialValues = {
    sampleId: '',
    dry_len: '',
    // dry_breadth: '',
    // dry_height: '',
    // l1: '',
    wet_len: '',
    breadth: '',
    height: '',
    //l2: '',
    dryingShrinkage: '',
}

const validationSchema = Yup.object().shape({
    // id: Yup.string().required('Required'),
    wet_len: Yup.number().required('Required'),
    breadth: Yup.number().required('Required'),
    height: Yup.number().required('Required'),
    dry_len: Yup.number().required('Required'),
    // dry_breadth: Yup.number().required('Required'),
    // dry_height: Yup.number().required('Required'),
})
const DryingShrinkageNewModal = ({ result, isView, overall, setOverall }) => {
    const handleClose = () => setOpen(false)
    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)
    const [data, setData] = useState(result.data ? result.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const { finalData, setFinalData } = useContext(AppContext)
    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && arr.length !== 0) {
                let dryingShrinkage = 0
                let moistureMovement = 0
                arr.forEach((item1) => {
                    // item1.l1 = Number(
                    //     //item1.wet_len * item1.breadth * item1.height
                    // )
                    // item1.l2 = Number(
                    //     item1.dry_len * item1.dry_breadth * item1.height
                    // )
                    let value = valueCalc(
                        ((item1.wet_len - item1.dry_len) / item1.dry_len) * 100
                    )

                    if (result.groupName === 'Moisture Movement') {
                        item1.moistureMovement = value
                        moistureMovement += Number(value / arr.length)
                    } else {
                        item1.dryingShrinkage = value
                        dryingShrinkage += Number(value / arr.length)
                    }
                })
                item.overall = {
                    ...overall,
                    [result.groupName === 'Moisture Movement'
                        ? 'moistureMovement'
                        : 'dryingShrinkage']: valueCalc(
                        result.groupName === 'Moisture Movement'
                            ? moistureMovement
                            : dryingShrinkage
                    ),
                }

                setOverall(item.overall)
            }

            if (item.groupName === result.groupName) {
                item.data = arr
            }
        })

        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
    }

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props
                    return (
                        <Form>
                            <Modal open={open} onClose={handleClose}>
                                <StyledCard>
                                    <CardHeader title={result?.groupName} />
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            {/* <Grid item xs={12}>
                                                <Typography
                                                    variant="h6"
                                                    fontWeight={'normal'}
                                                >
                                                    Dimension in mm (Wet) - L1
                                                </Typography>
                                            </Grid> */}
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Wet Length, L (mm)"
                                                    value={values.wet_len}
                                                    name="wet_len"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.wet_len &&
                                                        touched.wet_len
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.wet_len &&
                                                    touched.wet_len
                                                        ? errors.wet_len
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label=" Dry Length, L (mm)"
                                                    value={values.dry_len}
                                                    name="dry_len"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.dry_len &&
                                                        touched.dry_len
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.dry_len &&
                                                    touched.dry_len
                                                        ? errors.wet_len
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Breadth, B (mm)"
                                                    value={values.breadth}
                                                    name="breadth"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.breadth &&
                                                        touched.breadth
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.breadth &&
                                                    touched.breadth
                                                        ? errors.breadth
                                                        : ''}
                                                </HelperText>
                                            </Grid>{' '}
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Height, H (mm)"
                                                    value={values.height}
                                                    name="height"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.height &&
                                                        touched.height
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.height &&
                                                    touched.height
                                                        ? errors.height
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            {/* <Grid item xs={12}>
                                                <Typography
                                                    variant="h6"
                                                    fontWeight={'normal'}
                                                >
                                                    Dimension in mm (Dry) - L2
                                                </Typography>
                                            </Grid> */}
                                            {/* <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Length, L (mm)"
                                                    value={values.dry_len}
                                                    name="dry_len"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.dry_len &&
                                                        touched.dry_len
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.dry_len &&
                                                    touched.dry_len
                                                        ? errors.dry_len
                                                        : ''}
                                                </HelperText>
                                            </Grid>{' '} */}
                                            {/* <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Breadth, B (mm)"
                                                    value={values.dry_breadth}
                                                    name="dry_breadth"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.dry_breadth &&
                                                        touched.dry_breadth
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.dry_breadth &&
                                                    touched.dry_breadth
                                                        ? errors.dry_breadth
                                                        : ''}
                                                </HelperText>
                                            </Grid>{' '}
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Height, H (mm)"
                                                    value={values.dry_height}
                                                    name="dry_height"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.dry_height &&
                                                        touched.dry_height
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.dry_height &&
                                                    touched.dry_height
                                                        ? errors.dry_height
                                                        : ''}
                                                </HelperText>
                                            </Grid> */}
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </StyledCard>
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
            {data && (
                <DryingShrinkageNewTable
                    data={data}
                    setData={setData}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    result={result}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {isView !== true && (
                <Grid container justifyContent="end">
                    <Button
                        sx={{ boxShadow: 'none', mt: 2 }}
                        variant="contained"
                        endIcon={<AddCircle />}
                        onClick={handleOpen}
                        size="small"
                    >
                        Add
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default DryingShrinkageNewModal
