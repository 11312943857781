import React, { useContext, useState } from 'react'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Modal,
    TextField,
    Autocomplete,
} from '@mui/material'
import { Form, Formik } from 'formik'
import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { AddCircle } from '@mui/icons-material'
import * as Yup from 'yup'
import PaverCompressiveStrengthTable from './ParverCompressiveStrengthTable'
import IconButton from '@mui/material/IconButton'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const initialValues = {
    thickness: '',
    plainBlock: '',
}

const validationSchema = Yup.object().shape({
    result: Yup.number().required('Required'),
})

const PaverCompressiveStrengthModal = ({
    result,
    isView,
    setOverall,
    overall,
}) => {
    const [open, setOpen] = useState(false)
    const [data, setData] = useState(result.data ? result.data : [])
    const { finalData, setFinalData } = useContext(AppContext)

    // const initialValues = {
    //     testParams: '',
    //     unit: '',
    // }

    const [initialState, setInitialState] = useState({ ...initialValues })

    const [requirement, setRequirement] = useState({
        thickness: initialValues.thickness,
        plainBlock: initialValues.plainBlock,
    })

    const handleSubmit = (values, formikBag) => {
        if (!values.thickness && !values.plainBlock) {
            formikBag.setFieldError('thickness', 'Thickness is required')
            formikBag.setFieldError('plainBlock', 'PlainBlock is required')
            return
        }
        if (!values.thickness) {
            formikBag.setFieldError('thickness', 'Thickness is required')
            return
        }
        if (!values.plainBlock) {
            formikBag.setFieldError('plainBlock', 'PlainBlock is required')
            return
        }
        let arr = [...data]
        arr.push(values)

        let _finalData = finalData.map((item) => {
            if (item.groupName === result.groupName && arr.length !== 0) {
                let sum = arr.reduce((acc, item1) => acc + item1.testParams, 0)
                let avg = sum / arr.length
                // Do something with the average value if needed
            }
            if (item.groupName === result.groupName) {
                item.data = arr
            }
            return item
        })

        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
        formikBag.resetForm()

        // Reset form values to the initial state
        //  formikBag.resetForm({ values: initialState })
    }

    const handleTests = (e, newValue) => {
        const valueToFind = newValue
        const index = result?.gradeAndSize.findIndex(
            (item) => item.Thickness === valueToFind
        )
        //let requirements = options[index]?.requirement || []
        let plainBlock = result?.gradeAndSize[index]?.plainBlock || ''

        setRequirement({
            thickness: valueToFind,
            plainBlock: plainBlock,
        })
    }

    const handleReq = (e) => {
        const { name, value } = e.target
        setRequirement({
            ...requirement,
            [name]: value,
        })
    }

    return (
        <Grid item xs={12}>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {(props) => {
                    const {
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        touched, // Destructure touched from props
                        errors,
                    } = props

                    return (
                        <Form>
                            <Modal open={open} onClose={() => setOpen(false)}>
                                <StyledCard>
                                    <CardHeader title={result?.groupName} />
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <Autocomplete
                                                    id="Paving Block Thickness"
                                                    freeSolo
                                                    options={result?.gradeAndSize.map(
                                                        (option) =>
                                                            option.Thickness
                                                    )}
                                                    filterOptions={(
                                                        options,
                                                        params
                                                    ) => {
                                                        const { inputValue } =
                                                            params
                                                        const filtered =
                                                            options.filter(
                                                                (option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(
                                                                            inputValue.toLowerCase()
                                                                        )
                                                            )

                                                        // Suggest the creation of a new value
                                                        const isExisting =
                                                            options.some(
                                                                (option) =>
                                                                    inputValue ===
                                                                    option
                                                            )

                                                        if (
                                                            inputValue !== '' &&
                                                            !isExisting
                                                        ) {
                                                            filtered.push(
                                                                inputValue
                                                            )
                                                        }

                                                        return filtered
                                                    }}
                                                    getOptionLabel={(option) =>
                                                        typeof option ===
                                                        'string'
                                                            ? option
                                                            : option.inputValue
                                                    }
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <li {...props}>
                                                            {typeof option ===
                                                            'string'
                                                                ? option
                                                                : option.inputValue}
                                                        </li>
                                                    )}
                                                    value={
                                                        (values.thickness =
                                                            requirement.thickness)
                                                    }
                                                    onChange={(e, newValue) => {
                                                        handleChange(e)
                                                        handleTests(e, newValue)
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Thickness"
                                                            error={
                                                                touched.thickness &&
                                                                Boolean(
                                                                    errors.thickness
                                                                )
                                                            }
                                                            helperText={
                                                                touched.thickness &&
                                                                errors.thickness
                                                            }
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        <IconButton
                                                                            aria-label="search"
                                                                            onClick={() => {
                                                                                console.log(
                                                                                    'Searching...'
                                                                                )
                                                                            }}
                                                                        >
                                                                            <ArrowDropDownIcon />
                                                                        </IconButton>
                                                                        {
                                                                            params
                                                                                .InputProps
                                                                                .endAdornment
                                                                        }
                                                                    </>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="PlainBlock"
                                                    name="plainBlock"
                                                    value={
                                                        (values.plainBlock =
                                                            requirement.plainBlock)
                                                    }
                                                    //value={requirement.unit}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        handleReq(e)
                                                    }}
                                                    //onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        touched.plainBlock &&
                                                        Boolean(
                                                            errors.plainBlock
                                                        )
                                                    }
                                                    helperText={
                                                        touched.plainBlock &&
                                                        errors.plainBlock
                                                    }
                                                />
                                                <HelperText>
                                                    {/* Add your error message logic here */}
                                                </HelperText>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            onClick={() => setOpen(false)}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </StyledCard>
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
            {data && (
                <PaverCompressiveStrengthTable
                    data={data}
                    setData={setData}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    result={result}
                    overall={overall}
                    setOverall={setOverall}
                />
            )}
            {!isView && (
                <Grid container justifyContent="end">
                    <Button
                        sx={{ boxShadow: 'none', mt: 2 }}
                        variant="contained"
                        endIcon={<AddCircle />}
                        onClick={() => setOpen(true)}
                        size="small"
                    >
                        Add
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default PaverCompressiveStrengthModal
