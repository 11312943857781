import { TEST } from '../actions/Actions'

const initialState = {
    testTableData: [{}],
    testProgressData: [],
    reviewerName: [],
    assigneeList: [],
    allDiscipline: [],
    allGroups: [],
    samplesList: [],
    customerList: [],
    totalPage: '',
    ulrNo: '',
    projectsList: [],
}

const TestAction = (state = initialState, action) => {
    switch (action.type) {
        case TEST.SET_TEST_DATA: {
            return {
                ...state,
                testTableData: action.payload,
            }
        }
        case TEST.SET_PROGRESS_TEST_DATA: {
            return {
                ...state,
                testProgressData: action.payload,
            }
        }
        case TEST.SET_REVIEWER_DATA: {
            return {
                ...state,
                reviewerName: action.payload,
            }
        }
        case TEST.SET_ASSIGNEE_DATA: {
            return {
                ...state,
                assigneeList: action.payload,
            }
        }
        case TEST.SET_DISCIPLINE_DATA: {
            return {
                ...state,
                allDiscipline: action.payload,
            }
        }
        case TEST.SET_GROUP_DATA: {
            return {
                ...state,
                allGroups: action.payload,
            }
        }
        case TEST.SET_SAMPLE_DATA: {
            return {
                ...state,
                samplesList: action.payload,
            }
        }
        case TEST.SET_ULR_NO: {
            return {
                ...state,
                ulrNo: action.payload,
            }
        }
        case TEST.SET_PROJECTS: {
            return {
                ...state,
                projectsList: action.payload,
            }
        }
        case TEST.SET_CUSTOMER_DATA: {
            return {
                ...state,
                customerList: action.payload?.docs,
                totalPage: action.payload?.totalPages,
            }
        }
        case TEST.SET_TOTALPAGE: {
            return {
                ...state,
                totalPage: action.payload?.totalPages,
            }
        }
        default: {
            return state
        }
    }
}

export default TestAction
