import { Image, Line, StyleSheet, Svg, Text, View } from '@react-pdf/renderer'
import React from 'react'
import mobile from './assets/images/clarity_mobile-solid.png'
import email from './assets/images/email.png'
import website from './assets/images/website.png'
import arrow from './assets/images/arrow.png'
import FooterDesgin from './Images/FooterDesgin'

const styles = StyleSheet.create({
    footerContainer: {
        letterSpacing: 0.5,
        width: '55%',
        // paddingTop: '5%',
        // marginBottom: '1px',
    },
    observationFooterContainer: {
        letterSpacing: 0.8,
        width: '100%',

        // paddingTop: '5%',
        marginBottom: '3px',
    },
    footer: {
        display: 'flex',
        fontSize: 10,
        textAlign: 'center',
        color: 'red',
        textTransform: 'uppercase',
        borderBottom: '1px solid red',
    },
    observationFooter: {
        display: 'flex',
        fontSize: 10,
        textAlign: 'center',
        color: 'red',
        textTransform: 'uppercase',
        borderBottom: '1px solid red',
    },
    terms: {
        display: 'flex',
        fontSize: 8.5,
        justifyContent: 'center',
        color: 'red',
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
    value: {
        display: 'flex',
        fontSize: 8.5,
        marginTop: 2,
    },
    dataContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 3,
        justifyContent: 'flex-start',
    },
    label: {
        fontSize: 8.5,
        marginTop: 3,
    },
    logo: {
        width: '13px',
        height: '13px',
        marginRight: 2,
    },
    termsData: {
        display: 'flex',
        fontSize: 8.5,
        // marginBottom: '2',
    },
    termsContainer: {
        letterSpacing: 0.2,
        display: 'flex',
        flexDirection: 'column',
        width: '40%',
        // paddingTop: '5%',
    },
})

const terms = [
    { data: 'The test results relate to the items tested' },
    {
        data: 'The report is generated for the sole use and specific purpose of the customer and based on item sample submitted by the customer',
    },
    {
        data: 'The report shall not be considered to be the representative of similar or equivalent items',
    },
    { data: 'Any correction makes this report invalid' },
]

const Footer = ({ type, organizationName }) => {
    return organizationName !== 'CareLab' ? (
        <View
            style={
                type !== 'observation'
                    ? {
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          position: 'absolute',
                          bottom: 0,
                          left: 20,
                          right: 20,
                          //   marginTop: '80%',
                          maxHeight: '70%',
                          marginTop: '20',
                          //   paddingTop: '5px',
                      }
                    : {
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          position: 'absolute',
                          bottom: 0,
                          left: 20,
                          right: 20,
                          //   marginTop: '80%',
                          maxHeight: '70%',
                          //   paddingTop: '5px',
                      }
            }
            fixed
        >
            <View
                style={
                    type !== 'observation'
                        ? styles.footerContainer
                        : styles.observationFooterContainer
                }
            >
                <Text
                    style={
                        type !== 'observation'
                            ? styles.footer
                            : styles.observationFooter
                    }
                >
                    Falcon Industrial Testing Laboratory Pvt. Ltd
                </Text>
                <View style={styles.dataContainer}>
                    <Text style={styles.value}>
                        Plot No. 181, Estate 2nd Main Road , Burma Colony , OMR
                        , Perungudi , Chennai - 600 096 , Tamilnadu, India.
                    </Text>
                </View>
                <View style={styles.dataContainer}>
                    <Image
                        src={mobile}
                        style={{ width: '13px', height: '13px', marginTop: 2 }}
                    />
                    <Text style={styles.label}>Mobile : </Text>
                    <View>
                        <Text style={styles.value}>
                            +91 80561 59517 - Admin Executive
                        </Text>
                        <Text style={styles.value}>
                            +91 87545 95273 - Technical
                        </Text>
                    </View>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        // marginBottom: '3',
                    }}
                >
                    <View style={styles.dataContainer}>
                        <Image src={email} style={styles.logo} />

                        <Text style={styles.label}>E-mail : </Text>

                        <View>
                            <Text style={styles.value}>lab@fitpl.in</Text>
                            <Text style={styles.value}>
                                falconlab01@gmail.com
                            </Text>
                        </View>
                    </View>
                    <View style={styles.dataContainer}>
                        <Image src={website} style={styles.logo} />
                        <Text style={styles.label}>Website : </Text>

                        <Text style={styles.value}>www.fitpl.in</Text>
                    </View>
                </View>
            </View>
            {type !== 'observation' && (
                <View
                    style={{
                        marginRight: 2,
                        marginLeft: 2,
                        display: 'flex',
                    }}
                >
                    <Svg height="110" width="2">
                        <Line
                            x1="0"
                            y1="0"
                            x2="1"
                            y2="200"
                            strokeWidth={2}
                            stroke="black"
                        />
                    </Svg>
                </View>
            )}
            {type !== 'observation' && (
                <View style={styles.termsContainer}>
                    <Text style={styles.terms}>Terms & Conditions :</Text>

                    {terms.map((term, index) => (
                        <View
                            key={index}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                fontSize: 8,
                                // marginBottom: '3',
                            }}
                        >
                            <Image
                                src={arrow}
                                style={{
                                    display: 'flex',
                                    width: '7px',
                                    paddingTop: '2px',
                                    height: '8px',
                                    marginRight: 1,
                                }}
                            />
                            <Text>{term.data}</Text>
                        </View>
                    ))}
                </View>
            )}
        </View>
    ) : (
        <View
            style={
                type !== 'observation'
                    ? {
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          position: 'absolute',
                          bottom: 0,
                          //   left: 20,
                          //   right: 20,
                          //marginTop: '80%',
                          //maxHeight: '70%',
                          //marginTop: '20',
                          //marginBottom: '5',
                          //paddingBottom: '100',
                          //paddingTop: '50px',
                          //borderTop: '1px solid black',
                      }
                    : {
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          position: 'absolute',
                          bottom: 0,
                          left: 20,
                          right: 20,
                          //   marginTop: '80%',
                          maxHeight: '70%',
                          //   paddingTop: '5px',
                      }
            }
            fixed
        >
            {type !== 'observation' && (
                <View style={{ width: '100%' }}>
                    {/* <Svg src={CareLaboratoriesFooter} /> */}
                    {/* <CareLaboratoriesFooter /> */}
                    {/* <CareLabFooter /> */}
                    {/* <Image src={carelabfooter1} /> */}
                    <FooterDesgin />
                </View>
            )}
        </View>
    )
}

export default Footer

// <View>
//         <Text>
//              Plot No . 181 ...................................................
//         </Text>
//           <View style={styles.dataContainer}>
//           <Image src={mobile} style={styles.logo} />
//             <Text >Mobile image </Text>

//             <Text >Mobile : </Text>
//             <View >
//           <Text>+91 9003717978 - Admin Executive</Text>
//               <Text>+91 9003717979 - Soil Test & Technical</Text>
//               </View>
//           </View>
//           </View>
//         <View style={{ flexDirection : "row" ,  justifyContent : 'space-around'}}>
// <View  style={styles.dataContainer}>
//   <Image src={email} style={styles.logo} />
// <Text >E-mail : </Text>

// <Text>lab@fitpl.com</Text>
// </View >
// <View style={styles.dataContainer}>
//   <Image src={website} style={styles.logo} />
// <Text >Website : </Text>

// <Text>www.fitpl.in</Text>
//   </View>
//       </View>
