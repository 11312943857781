import { Box, TableCell, TableHead, TableRow } from '@mui/material'

import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'

const SiltContentTable = (props) => {
    const { data } = props

    let crushingValue

    crushingValue = [
        {
            label: 'Volume of sample, V1 (ml)',
            value: 'volume_of_sample',
        },
        {
            label: 'Volume after deposition, V2 (ml)',
            value: 'volume_after_depostion',
        },
        {
            label: 'Silt content ((V1-V2)/V2)*100',
            value: 'silt_content',
        },
    ]

    const Column = [
        {
            text: 'Description',
            dataField: 'label',
        },
        {
            text: 'Result',
            dataField: 'value',
        },
    ]

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">SI.No</TableCell>
                        {Column.map((item, index) => (
                            <TableCell align="center" key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                {crushingValue?.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">{item.label}</TableCell>
                        <TableCell align="center">
                            {data[item.value] ? data[item.value] : '0'}
                        </TableCell>
                    </TableRow>
                ))}
            </StyledTable>
        </Box>
    )
}

export default SiltContentTable


// const handleDelete = (index, e) => {
//     let _data = data.filter((v, i) => i !== index);
//     setData(_data);
//     let _finalData = finalData;
//     _finalData.forEach(item => {
//         if (item.groupName === result.groupName) {
//             item.overall = _data;
//         }
//     })
//     setFinalData(_finalData);
// }