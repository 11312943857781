import { Grid } from '@mui/material'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'
import { Fragment } from 'react'
import SandReplacementMethodModal from './SandReplacementMethodModal'

const SandReplacementMethod = ({
    result,
    isView,
    overall,
    setOverall,
    handleCustomRequirementChange,
    handleRequirementChange,
}) => {
    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <JobAccordion
                        title={result?.groupName}
                        requirementvalue={overall?.requirements}
                        remarksValue={overall?.remarks}
                        overall={overall}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        // avgLabel={' Maximum Dry Density (MDD) g/cm3'}
                        // averagevalue={overall?.maximunDryDensity === -Infinity ? '0' : overall?.maximunDryDensity}
                    >
                        <SandReplacementMethodModal
                            result={result}
                            isView={isView}
                            overall={overall}
                            setOverall={setOverall}
                        />
                    </JobAccordion>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default SandReplacementMethod
