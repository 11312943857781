import { ExpandMore } from '@mui/icons-material'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
    // IconButton,
    InputLabel,
    TextField,
    Typography,
} from '@mui/material'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'

import { StyledDivider } from 'app/views/jobs/StyledComponents/StyledComponents'

import { Fragment } from 'react'
import ElongationModal from './modal/ElongationModal'
import FlakinesModal from './modal/FlakinessModal'

const JOBCARD007A = ({
    result,
    isView,
    index,
    overall,
    setOverall,
    handleCustomRequirementChange,
    handleRequirementChange,
}) => {
    // const { finalData, setFinalData } = useContext(AppContext)
    // let requirementText = requirements[result.groupName]

    // const [overall, setOverall] = useState(
    //     result?.overall?.requirements
    //         ? result.overall
    //         : (result.overall = {
    //               requirements:
    //                   typeof requirementText === 'string'
    //                       ? requirementText
    //                       : '',
    //           })
    // )
    // const handleCustomRequirementChange = (value) => {
    //     const _finalData = finalData
    //     _finalData[index].overall.requirements = requirementText[value]
    //     setOverall({
    //         ...overall,
    //         requirements: requirementText[value],
    //     })
    //     setFinalData(_finalData)
    // }
    // const handleRequirementChange = (e) => {
    //     const { value } = e.target

    //     const _finalData = finalData
    //     _finalData[index].overall.requirements = value
    //     setFinalData(_finalData)
    //     setOverall({
    //         ...overall,
    //         requirements: value,
    //     })
    // }

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {result.groupName === 'Flakiness Index' && (
                        <JobAccordion
                            title={result?.groupName}
                            requirementvalue={overall?.requirements}
                            remarksValue={overall?.remarks}
                            handleCustomRequirementChange={
                                handleCustomRequirementChange
                            }
                            handleRequirementChange={handleRequirementChange}
                            avgLabel={'Total Flakiness Index'}
                            averagevalue={overall?.totalFlakiness}
                            overall={overall}
                        >
                            <FlakinesModal
                                result={result}
                                isView={isView}
                                overall={overall}
                                setOverall={setOverall}
                                requirement={overall?.requirements}
                            />
                        </JobAccordion>
                    )}
                    {result.groupName === 'Elongation Index' && (
                        <JobAccordion
                            title={result?.groupName}
                            requirementvalue={overall?.requirements}
                            remarksValue={overall?.remarks}
                            handleCustomRequirementChange={
                                handleCustomRequirementChange
                            }
                            handleRequirementChange={handleRequirementChange}
                            avgLabel={'Total Elongation Index'}
                            averagevalue={overall?.totalElongation}
                            overall={overall}
                        >
                            <ElongationModal
                                result={result}
                                isView={isView}
                                overall={overall}
                                setOverall={setOverall}
                                handleCustomRequirementChange={(e) =>
                                    handleCustomRequirementChange(e)
                                }
                            />
                        </JobAccordion>
                    )}
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default JOBCARD007A
