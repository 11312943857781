import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import { Fragment, useContext, useState } from 'react'
import * as Yup from 'yup'
import { valueCalc } from '../../method/method'
import CouplerTable from './CouplerTable'

const initialValues = {
    dia: '',
    weight: '',
    len: '',
    weightPerMeter: '',
    yieldLoad: '',
    ultimateLoad: '',
    crossSectionalArea: '',
    yieldStress: '',
    ultimateTensileStress: '',
    originalGaugeLength: '',
    finalGaugeLength: '',
    percentageOfElongation: '',
    _10_perc_uts: '',
    minUts: '',
    remark: '',
}

const validationSchema = Yup.object().shape({
    weight: Yup.number().required('Required'),
    len: Yup.number().required('Required'),
    yieldLoad: Yup.number().required('Required'),
    ultimateLoad: Yup.number().required('Required'),
    finalGaugeLength: Yup.number().required('Required'),
    minUts: Yup.number().required('Required'),
})

const Coupler = (props) => {
    const {
        result,
        isView,
        overall,
        setOverall,
        handleCustomRequirementChange,
        handleRequirementChange,
    } = props

    const { finalData, setFinalData, EditDetails } = useContext(AppContext)
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [dia, setDia] = useState(
        EditDetails?.data?.dimension ?? initialValues.dia
    )
    const [originalGaugeLength, setOriginalGaugeLength] = useState(
        initialValues.originalGaugeLength
    )
    const [data, setData] = useState(result.data ? result.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        arr = applyFormula(arr)
        let _finalData = finalData

        _finalData.forEach((item) => {
            let mass_avg = 0
            let yeild_avg = 0
            let uts_avg = 0
            let elongation_avg = 0
            let ratio_avg = 0
            if (item.groupName === result.groupName && arr.length !== 0) {
                arr.forEach((item1) => {
                    // let mass = Number(item1.weight / item1.len)
                    mass_avg += Number(item1.weightPerMeter / arr.length)
                    //
                    // let yield_stress = Number(
                    //     (Number(item1.yieldLoad) * 1000) / (mass / 0.00785)
                    // )

                    yeild_avg += Number(item1.yieldStress / arr.length)

                    //
                    // let uts = Number(
                    //     Number(item1.ultimateLoad * 1000) / (mass / 0.00785)
                    // )

                    uts_avg += Number(item1.ultimateTensileStress / arr.length)
                    //
                    // let elongation = Number(
                    //     (100 *
                    //         (Number(item1.finalGaugeLength) -
                    //             Number(item1.originalGaugeLength))) /
                    //     Number(item1.originalGaugeLength)
                    // )
                    elongation_avg += Number(
                        item1.percentageOfElongation / arr.length
                    )

                    //
                    // let ratio = Number(uts / yield_stress)
                    ratio_avg += Number(item1._10_perc_uts / arr.length)
                })

                item.overall = {
                    ...overall,
                    mass_avg: valueCalc(mass_avg),
                    yeild_avg: valueCalc(yeild_avg),
                    uts_avg: valueCalc(uts_avg),
                    elongation_avg: valueCalc(elongation_avg),
                    ratio_avg: valueCalc(ratio_avg),
                }
                setOverall(item.overall)
            }
            if (item.groupName === result.groupName) {
                item.data = arr
            }
        })

        arr?.forEach((value, key) => {
            value.dia = dia + '/' + EditDetails?.data?.grade ?? ''
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
    }

    const handleDiaChange = (e) => {
        setDia(e.target.value)
        setOriginalGaugeLength(e.target.value * 5)
    }
    const handleOriginal = (e) => {
        setOriginalGaugeLength(e.target.value)
    }

    const applyFormula = (arr) => {
        arr.forEach((item, index) => {
            item.weightPerMeter = valueCalc(item.weight / item.len)
            item.crossSectionalArea = valueCalc(item.weightPerMeter / 0.00785)
            item.yieldStress = valueCalc(
                (item.yieldLoad * 1000) / item.crossSectionalArea
            )
            item.ultimateTensileStress = valueCalc(
                (item.ultimateLoad * 1000) / item.crossSectionalArea
            )
            item._10_perc_uts = valueCalc(
                item.ultimateTensileStress / item.yieldStress
            )
            item.percentageOfElongation = valueCalc(
                100 *
                    ((item.finalGaugeLength - item.originalGaugeLength) /
                        item.originalGaugeLength)
            )
        })
        return arr
    }

    return (
        <Fragment>
            <Grid item xs={12}>
                <JobAccordion
                    title={result?.groupName}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    requirementvalue={overall?.requirements}
                    remarksValue={overall?.remarks}
                    overall={overall}
                >
                    <Grid item xs={12}>
                        <Formik
                            initialValues={initialState}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                            enableReinitialize
                        >
                            {(props) => {
                                const {
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                } = props

                                return (
                                    <Form>
                                        <Modal
                                            open={open}
                                            onClose={handleClose}
                                        >
                                            <StyledCard>
                                                <CardHeader
                                                    title={result?.groupName}
                                                />
                                                <CardContent
                                                    sx={{
                                                        m: 4,
                                                    }}
                                                >
                                                    <Grid container spacing={4}>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            xs={12}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                value={
                                                                    values.weight
                                                                }
                                                                label="Weight, W(kg)"
                                                                required
                                                                name="weight"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                error={
                                                                    errors.weight &&
                                                                    touched.weight
                                                                }
                                                            />
                                                            <HelperText>
                                                                {errors.weight &&
                                                                touched.weight
                                                                    ? errors.weight
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            xs={12}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                label="Length, L(m)"
                                                                value={
                                                                    values.len
                                                                }
                                                                name="len"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                error={
                                                                    errors.len &&
                                                                    touched.len
                                                                }
                                                            />
                                                            <HelperText>
                                                                {errors.len &&
                                                                touched.len
                                                                    ? errors.len
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            lg={6}
                                                            xs={12}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                label="Yield Load, P  (kN)"
                                                                value={
                                                                    values.yieldLoad
                                                                }
                                                                name="yieldLoad"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                error={
                                                                    errors.yieldLoad &&
                                                                    touched.yieldLoad
                                                                }
                                                            />
                                                            <HelperText>
                                                                {errors.yieldLoad &&
                                                                touched.yieldLoad
                                                                    ? errors.yieldLoad
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            xs={12}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                label="Ultimate Load, F (kN)"
                                                                value={
                                                                    values.ultimateLoad
                                                                }
                                                                name="ultimateLoad"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                error={
                                                                    errors.ultimateLoad &&
                                                                    touched.ultimateLoad
                                                                }
                                                            />
                                                            <HelperText>
                                                                {errors.ultimateLoad &&
                                                                touched.ultimateLoad
                                                                    ? errors.ultimateLoad
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            lg={6}
                                                            xs={12}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                label="Original Gauge Length,L1(mm)"
                                                                value={
                                                                    (values.originalGaugeLength =
                                                                        originalGaugeLength)
                                                                }
                                                                name="originalGaugeLength"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    handleChange(
                                                                        e
                                                                    )
                                                                    handleOriginal(
                                                                        e
                                                                    )
                                                                }}
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                error={
                                                                    errors.originalGaugeLength &&
                                                                    touched.originalGaugeLength
                                                                }
                                                            />
                                                            <HelperText>
                                                                {errors.originalGaugeLength &&
                                                                touched.originalGaugeLength
                                                                    ? errors.originalGaugeLength
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            xs={12}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                label="Final Gauge Length,L2(mm)"
                                                                value={
                                                                    values.finalGaugeLength
                                                                }
                                                                name="finalGaugeLength"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                error={
                                                                    errors.finalGaugeLength &&
                                                                    touched.finalGaugeLength
                                                                }
                                                            />
                                                            <HelperText>
                                                                {errors.finalGaugeLength &&
                                                                touched.finalGaugeLength
                                                                    ? errors.finalGaugeLength
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            xs={12}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                label="Min UTS (SI)"
                                                                value={
                                                                    values.minUts
                                                                }
                                                                name="minUts"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                error={
                                                                    errors.minUts &&
                                                                    touched.minUts
                                                                }
                                                            />
                                                            <HelperText>
                                                                {errors.minUts &&
                                                                touched.minUts
                                                                    ? errors.minUts
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            xs={12}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                label="Remark"
                                                                value={
                                                                    values.remark
                                                                }
                                                                name="remark"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                error={
                                                                    errors.remark &&
                                                                    touched.remark
                                                                }
                                                                multiline
                                                            />
                                                            <HelperText>
                                                                {errors.remark &&
                                                                touched.remark
                                                                    ? errors.remark
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                                <CardActions>
                                                    <Button
                                                        size="small"
                                                        onClick={handleClose}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        onClick={handleSubmit}
                                                    >
                                                        Save
                                                    </Button>
                                                </CardActions>
                                            </StyledCard>
                                        </Modal>
                                    </Form>
                                )
                            }}
                        </Formik>

                        {data && (
                            <CouplerTable
                                Dia={dia}
                                data={data}
                                setData={setData}
                                setFinalData={setFinalData}
                                result={result}
                                finalData={finalData}
                                setOverall={setOverall}
                                overall={overall}
                                handleCustomRequirementChange={
                                    handleCustomRequirementChange
                                }
                            />
                        )}
                        {isView !== true && (
                            <Grid container justifyContent="end" spacing={2}>
                                <Grid item>
                                    <Button
                                        sx={{ boxShadow: 'none', m: 2 }}
                                        variant="contained"
                                        endIcon={<AddCircle />}
                                        onClick={handleOpen}
                                        size="small"
                                    >
                                        Add
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </JobAccordion>
            </Grid>
        </Fragment>
    )
}

export default Coupler
