import { combineReducers } from 'redux'
import appReducer from './AppReducer'
import DashboardReducer from './DashboardReducer'
import JobReducer from './JobReducer'
import NotificationReducer from './NotificationReducer'
import TestReducer from './TestReducer'
import UsersReducer from './UserReducer'

const RootReducer = combineReducers({
    dashboardReducer: DashboardReducer,
    jobReducer: JobReducer,
    appReducer: appReducer,
    testReducer: TestReducer,
    usersReducer: UsersReducer,
    notificationReducer: NotificationReducer,
})

export default RootReducer
