import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Icon,
    InputLabel,
    TextField,
} from '@mui/material'
import { memo, useState } from 'react'
import { Transition } from '../jobs/StyledComponents/StyledComponents'

const DialogBox = memo(
    ({
        value,
        open,
        handlePdf,
        handleClose,
        email,
        setEmail,
        // handleChangeEmail,
        emailData,
    }) => {
        const [emailValue, setEmailValue] = useState(
            emailData.current ? emailData.current : ' '
        )
        const handleChangeEmail = (e) => {
            emailData.current = e.target.value
            // e.preventDefault()
            setEmailValue(e.target.value)
            // setEmail(e.target.value)
        }

        return (
            <Dialog
                value={value}
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <DialogContent>
                    <InputLabel>To</InputLabel>
                    <TextField
                        // disabled
                        // ref={emailData}
                        fullWidth
                        onChange={(e) => handleChangeEmail(e)}
                        variant="outlined"
                        value={emailValue}
                        size="small"
                    />
                    <DialogContentText>
                        Are you sure you want to Send this Pdf?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ width: 200 }} onClick={() => handleClose()}>
                        Cancel
                    </Button>
                    <Button
                        sx={{ width: 200 }}
                        onClick={() => handlePdf()}
                        variant="contained"
                        endIcon={<Icon>email</Icon>}
                    >
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
)
export default DialogBox

//                     onClick={handleClose}

//     <Modal
//     open={open}
//     onClose={handleClose}
//     aria-labelledby="modal-modal-title"
//     aria-describedby="modal-modal-description"
// >
//     <Box sx={style}>
//     <Typography id="modal-modal-title">
//         To
//     </Typography>
//     <TextField
//     fullWidth
//     // value={email}
//     // onChange={(e) => handleChangeEmail(e)}
//     ></TextField>
//     <Typography id="modal-modal-description" sx={{ mt: 2 }}>
//     Are you sure you want to Send this Pdf?
//     </Typography>
//     <Grid item xs={6} lg={6}>

//     <Button
//             onClick={handleClose}
//             >
//                 Cancel
//             </Button>
//             <Button
//             onClick={() => handlePdf()}
//             variant='contained' endIcon={<Icon>email</Icon>}>
//                 Send
//             </Button>
//     </Grid>
//     </Box>
// </Modal>
