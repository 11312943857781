import {
    Button,
    Grid,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'

const LightCompactionTable = ({
    data,
    setData,
    setFinalData,
    finalData,
    result,
    setOverall,
    overall,
}) => {
    const handleDelete = (index, e) => {
        let dry_density_arr = []
        let water_content_arr = []
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        _finalData.forEach((item) => {
            let maximunDryDensity = 0
            let maximumWaterContent = 0
            if (_data.length > 0) {
                const maxDryDensityObj = _data.reduce((maxObj, currentObj) => {
                    const maxDensity = parseFloat(maxObj.dry_density)
                    const currentDensity = parseFloat(currentObj.dry_density)
                    return maxDensity > currentDensity ? maxObj : currentObj
                }, _data[0])
                maximunDryDensity = maxDryDensityObj.dry_density
                maximumWaterContent = maxDryDensityObj.water_content
            }
            if (item.groupName === result.groupName) {
                item.data = _data
                item.overall = {
                    ...overall,
                    maximunDryDensity: maximunDryDensity,
                    maximumWaterContent: maximumWaterContent,
                }
                setOverall(item.overall)
            }
            return item
        })
        setFinalData(_finalData)
    }

    const waterContent = [
        // { text: 'Container Number', dataField: 'containerNumber' },
        { text: 'Weight of Container (g) W1 ', dataField: 'wt_container' },
        {
            text: 'Weight of Container + Wet Soil (g) W2',
            dataField: 'wt_container_wetSoil',
        },
        {
            text: 'Weight of Container +Dry Soil (g) W3',
            dataField: 'wt_container_drySoil',
        },
        {
            text: 'Weight of Water (g) Ww',
            dataField: 'wt_water',
        },
        {
            text: 'Weight of Dry Soil (g) Ws',
            dataField: 'wt_drySoil',
        },
        {
            text: 'Water Content (w) % =((Ww/Ws)x100)',
            dataField: 'water_content',
        },
    ]
    const dryDensity = [
        { text: 'Weight of Mould (g) W1', dataField: 'wt_mould' },
        {
            text: 'Weight of mould + Compacted soil (w2) g ',
            dataField: 'wt_mould_soil',
        },
        {
            text: 'Weight of compacted soil (w2-w1) g',
            dataField: 'wt_compact_soil',
        },
        {
            text: 'Volume of mould (cm³)',
            dataField: 'vol_mould',
        },
        {
            text: 'Wet density  γb = ((w2-w1)/v)  g/cm3',
            dataField: 'wet_density',
        },
        {
            text: 'Dry density γd   = (γb/(1+(w/100))) g/cm3 ',
            dataField: 'dry_density',
        },
    ]

    return (
        <Box width="100%">
            <Grid container spacing={2}>
                <Grid item>
                    <Typography>
                        Light Compaction - Optimum Moisture Content
                    </Typography>

                    <StyledTable>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Actions</TableCell>
                                <TableCell align="center">
                                    Container Number
                                </TableCell>
                                {waterContent.map((item, index) => (
                                    <TableCell align="center" key={index}>
                                        {item.text}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center">
                                        <Button
                                            onClick={(e) =>
                                                handleDelete(index, e)
                                            }
                                        >
                                            <Icon>delete</Icon>
                                        </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                        {index + 1}
                                    </TableCell>
                                    {waterContent.map((subItem, idx) => (
                                        <TableCell
                                            align="center"
                                            key={`row-${idx}-index`}
                                        >
                                            {item[subItem.dataField]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </StyledTable>
                </Grid>
                <Grid item>
                    <Typography>
                        Light Compaction - Maximum Dry Density
                    </Typography>
                    <StyledTable>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Actions</TableCell>

                                <TableCell align="center">Trials</TableCell>
                                {dryDensity.map((item, index) => (
                                    <TableCell align="center" key={index}>
                                        {item.text}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center">
                                        <Button
                                            onClick={(e) =>
                                                handleDelete(index, e)
                                            }
                                        >
                                            <Icon>delete</Icon>
                                        </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                        Trial {index + 1}
                                    </TableCell>
                                    {dryDensity.map((subItem, idx) => (
                                        <TableCell
                                            align="center"
                                            key={`row-${idx}-index`}
                                        >
                                            {item[subItem.dataField]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </StyledTable>
                </Grid>
            </Grid>
        </Box>
    )
}

export default LightCompactionTable
