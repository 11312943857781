import {
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { Box } from '@mui/system'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import React from 'react'
import { valueCalc } from '../../method/method'

const FlakinessTable = (props) => {
    const {
        data,
        setData,
        setFinalData,
        finalData,
        result,
        setOverall,
        overall,
    } = props

    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        let totalFlakiness = 0
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && item.data.length > 0) {
                _data.forEach((item1) => {
                    let value =
                        Number(
                            item1.weightOfAggregatePassingThroughGuage /
                                item1.totalWeight
                        ) * 100
                    item1.individualFlakiness = Number(value.toFixed(4))
                    totalFlakiness += Number(value.toFixed(4))
                })
            }
            if (item.groupName === result.groupName) {
                item.data = _data
                item.overall = {
                    ...overall,
                    totalFlakiness: valueCalc(totalFlakiness),
                }
                setOverall(item.overall)
            }
        })

        setFinalData(_finalData)
    }

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Total weight (g),X</TableCell>
                        <TableCell align="center">
                            Sieve Size Passing (mm)
                        </TableCell>
                        <TableCell align="center">
                            Wt Retained on IS Sieve (g)
                        </TableCell>
                        <TableCell align="center">
                            Guage thickness (mm)
                        </TableCell>
                        <TableCell align="center">
                            Weight of aggregate passing through guage (g), Y
                        </TableCell>
                        <TableCell align="center">
                            Individual Flakiness (Y/X)*100
                        </TableCell>
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((value, index) => (
                        <TableRow key={index}>
                            <TableCell align="center">
                                {value.totalWeight}
                            </TableCell>
                            <TableCell align="center">
                                {value.sieveSizePassing}
                            </TableCell>
                            <TableCell align="center">
                                {value.wtRetainedOnISSieve}
                            </TableCell>
                            <TableCell align="center">
                                {value.guagethickness}
                            </TableCell>
                            <TableCell align="center">
                                {value.weightOfAggregatePassingThroughGuage}
                            </TableCell>
                            <TableCell align="center">
                                {
                                    (value.individualFlakiness = valueCalc(
                                        (value.weightOfAggregatePassingThroughGuage /
                                            value.totalWeight) *
                                            100
                                    ))
                                }
                            </TableCell>
                            <TableCell align="center">
                                <Button onClick={(e) => handleDelete(index, e)}>
                                    <Icon>delete</Icon>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default FlakinessTable
