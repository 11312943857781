// import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
// import {
//     getReportTitleForChemical,
//     getReportTitleForChemicalAndBiological,
//     getReportTitleForPhysical,
//     getReportTitleForWater,
// } from 'app/assets/Constant/constant'
// import JsBarcode from 'jsbarcode'
// import QRCodeCanvas from 'qrcode'
// import { Fragment } from 'react'
// import { changeTitleName } from '../method'
// import newImage from '../assets/images/new.jpg'

// const fontfamily = 'Times-Bold'
// const styles = StyleSheet.create({
//     titleContainer: {
//         letterSpacing: 1,
//         flex: 1,
//         justifyContent: 'center',
//     },
//     title: {
//         fontSize: '12px',
//         fontFamily: fontfamily,
//         textTransform: 'uppercase',
//         textDecoration: 'underline',
//         //textAlign: 'justify',
//     },
//     titleSpecial: {
//         fontSize: '13px',
//         fontFamily: fontfamily,
//         textTransform: 'uppercase',
//         textDecoration: 'underline',
//         textAlign: 'center',
//     },
//     title2: {
//         fontSize: '13px',
//         fontFamily: fontfamily,
//         textTransform: 'uppercase',
//         textDecoration: 'underline',
//         textAlign: 'center',
//     },
//     conatiner: {
//         display: 'flex',
//         flexDirection: 'row',
//         alignItems: 'center',
//     },
//     bar: {
//         maxHeight: '50',
//         alignItems: 'center',
//         justifyContent: 'flex-end',
//     },
//     bar1: {
//         maxHeight: 30,
//         alignItems: 'center',
//         justifyContent: 'flex-end',
//         width: 50,
//         marginTop: 8,
//         marginRight: 1,
//         // marginLeft: '4',
//         // height: '30px',
//     },
// })

// const Title = ({ title, jobNo, id, type, headingName, organizationName }) => {
//     let urlTitle = String(title).replace(/ /g, '%20')

//     let baseURL = window.location.origin

//     let url = `${baseURL}/pdf/download/${id}/${urlTitle}?type=${type}&category=download`
//     // let url = `http://localhost:3000/pdf/download/${id}/${urlTitle}?type=${type}&category=download`

//     let canvas

//     // For QR Code
//     canvas = document.createElement('canvas')
//     QRCodeCanvas.toCanvas(canvas, url)
//     const qr = canvas.toDataURL()

//     // For Barcode
//     canvas = document.createElement('canvas')
//     JsBarcode(canvas, jobNo)
//     const barcode = canvas.toDataURL()
//     // let titleName =
//     //     type === 'report' || type === 'reportOnly'
//     //         ? //||
//     //           // type === 'observation' ||
//     //           // type === 'observationReport'
//     //           title
//     //         : headingName
//     // titleName = changeTitleName(titleName)
//     let titleName =
//         organizationName === 'CareLab' &&
//         (type === 'report' ||
//             type === 'reportOnly' ||
//             type === 'observation' ||
//             type === 'testReport' ||
//             type === 'observationNull')
//             ? title
//             : organizationName === 'FalconLab' &&
//                 (type === 'report' ||
//                     type === 'reportOnly' ||
//                     type === 'observationNull')
//               ? title
//               : headingName

//     titleName = changeTitleName(titleName)
//     return (
//         <Fragment>
//             <View
//                 style={{
//                     flexDirection: 'row',
//                     textAlign: 'center',
//                 }}
//             >
//                 <View style={styles.titleContainer}>
//                     {type === 'observation' || type === 'observationNull' ? (
//                         <Text style={styles.title}>
//                             Observation Result of {title}
//                         </Text>
//                     ) : (
//                         // <Text style={styles.title}>
//                         //     {title.includes('(Chemical)')
//                         //         ? `Chemical Test Result of ${title.replace(
//                         //               '(Chemical)',
//                         //               ''
//                         //           )}`
//                         //         : title.includes('Hardened Concrete Cube')
//                         //           ? `Compressive Strength Test Result On ${titleName}`
//                         //           : getReportTitleForChemical.includes(title)
//                         //             ? `Chemical Test Result of ${titleName}`
//                         //             : getReportTitleForPhysical.includes(title)
//                         //               ? `Physical Test Result of ${titleName}`
//                         //               : getReportTitleForWater.includes(title)
//                         //                 ? `Test Result of ${titleName}`
//                         //                 : `Physical And Chemical  Test Result of ${titleName}`}
//                         // </Text>
//                         <Text
//                             // style={
//                             //     title === 'Bakery and Confectionary' ||
//                             //     title === 'Cereal and Cereals Products'
//                             //         ? styles.titleSpecial
//                             //         : styles.title
//                             // }
//                             style={
//                                 title === 'Bakery and Confectionary' ||
//                                 title === 'Cereal and Cereals Products'
//                                     ? styles.titleSpecial
//                                     : title ===
//                                         'Half-Cell Potential difference test'
//                                       ? styles.title2
//                                       : styles.title
//                             }
//                         >
//                             {title === 'Bakery and Confectionary'
//                                 ? 'Physical And Chemical Test Result of Bakery and \nConfectionary'
//                                 : title.includes('(Chemical)')
//                                   ? `Chemical Test Result of ${title.replace('(Chemical)', '')}`
//                                   : title.includes('Hardened Concrete Cube')
//                                     ? `Compressive Strength Test Result On ${titleName}`
//                                     : getReportTitleForChemical.includes(title)
//                                       ? `Chemical Test Result of ${titleName}`
//                                       : getReportTitleForPhysical.includes(
//                                               title
//                                           )
//                                         ? `Physical Test Result of ${titleName}`
//                                         : getReportTitleForWater.includes(title)
//                                           ? `Test Result of ${titleName}`
//                                           : `Physical And Chemical Test Result of ${titleName}`}
//                         </Text>
//                     )}
//                 </View>
//                 {/* {(type === 'report' ||
//                     type === 'reportOnly' ||
//                     type === 'testReport') && (
//                     <Image src={newImage} style={styles.bar1} />
//                 )} */}
//                 {(type === 'report' ||
//                     type === 'reportOnly' ||
//                     type === 'testReport') && (
//                     <Image src={qr} style={styles.bar} />
//                 )}
//             </View>
//             {/* <View
//                 style={{
//                     display: 'flex',
//                     flexDirection: 'row',
//                     justifyContent: 'flex-end',
//                 }}
//             >
//                 {(type === 'report' ||
//                     type === 'reportOnly' ||
//                     type === 'testReport') && (
//                     <Image
//                         src={newImage}
//                         style={{
//                             maxHeight: '30',
//                             // alignItems: 'center',
//                             // justifyContent: 'flex-end',
//                             width: '46',
//                             //marginTop: '8',
//                             marginRight: '1',
//                         }}
//                     />
//                 )}

//             </View> */}
//         </Fragment>
//     )
// }

// export default Title

import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import {
    getReportTitleForChemical,
    getReportTitleForChemicalAndBiological,
    getReportTitleForPhysical,
    getReportTitleForWater,
} from 'app/assets/Constant/constant'
import JsBarcode from 'jsbarcode'
import QRCodeCanvas from 'qrcode'
import { Fragment } from 'react'
import { changeTitleName } from '../method'
import newImage from '../assets/images/new.jpg'

const fontfamily = 'Times-Bold'
const styles = StyleSheet.create({
    titleContainer: {
        letterSpacing: 1,
        flex: 1,
        justifyContent: 'center',
    },
    title: {
        fontSize: '12px',
        fontFamily: fontfamily,
        textTransform: 'uppercase',
        textDecoration: 'underline',
    },
    titleSpecial: {
        fontSize: '13px',
        fontFamily: fontfamily,
        textTransform: 'uppercase',
        textDecoration: 'underline',
        textAlign: 'center',
    },
    title2: {
        fontSize: '13px',
        fontFamily: fontfamily,
        textTransform: 'uppercase',
        textDecoration: 'underline',
        textAlign: 'center',
    },
    conatiner: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    bar: {
        maxHeight: '50',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    bar1: {
        maxHeight: 30,
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: 50,
        marginTop: 8,
        marginRight: 1,
    },
})

const Title = ({ title, jobNo, id, type, headingName, organizationName }) => {
    let urlTitle = String(title).replace(/ /g, '%20')

    let baseURL = window.location.origin

    let url = `${baseURL}/pdf/download/${id}/${urlTitle}?type=${type}&category=download`

    let canvas

    // For QR Code
    canvas = document.createElement('canvas')
    QRCodeCanvas.toCanvas(canvas, url)
    const qr = canvas.toDataURL()

    // For Barcode
    canvas = document.createElement('canvas')
    JsBarcode(canvas, jobNo)
    const barcode = canvas.toDataURL()

    let displayTitle =
        title === 'CHE-Drinking Water' || title === 'BIO-Drinking Water'
            ? 'Drinking Water'
            : title
    let titleName =
        organizationName === 'CareLab' &&
        (type === 'report' ||
            type === 'reportOnly' ||
            type === 'observation' ||
            type === 'testReport' ||
            type === 'observationNull')
            ? displayTitle
            : organizationName === 'FalconLab' &&
                (type === 'report' ||
                    type === 'reportOnly' ||
                    type === 'observationNull') || 
                    (organizationName === 'FalconLab' && headingName === 'DYNAMIC_JOBCARD' 
                        && type === 'testReport')
              ? displayTitle
              : headingName

    titleName = changeTitleName(titleName)    
    return (
        <Fragment>
            <View
                style={{
                    flexDirection: 'row',
                    textAlign: 'center',
                }}
            >
                <View style={styles.titleContainer}>
                    {type === 'observation' || type === 'observationNull' ? (
                        <Text style={styles.title}>
                            Observation Result of {displayTitle}
                        </Text>
                    ) : (
                        <Text
                            style={
                                displayTitle === 'Bakery and Confectionary' ||
                                displayTitle === 'Cereal and Cereals Products'
                                    ? styles.titleSpecial
                                    : displayTitle ===
                                        'Half-Cell Potential difference test'
                                      ? styles.title2
                                      : styles.title
                            }
                        >
                            {/* {displayTitle === 'Bakery and Confectionary'
                                ? 'Physical And Chemical Test Result of Bakery and \nConfectionary'
                                : displayTitle.includes('(Chemical)')
                                  ? `Chemical Test Result of ${displayTitle.replace('(Chemical)', '')}`
                                  : displayTitle.includes(
                                          'Hardened Concrete Cube'
                                      )
                                    ? `Compressive Strength Test Result On ${titleName}`
                                    : getReportTitleForChemical.includes(
                                            displayTitle
                                        )
                                      ? `Chemical Test Result of ${titleName}`
                                      : getReportTitleForPhysical.includes(
                                              displayTitle
                                          )
                                        ? `Physical Test Result of ${titleName}`
                                        : getReportTitleForWater.includes(
                                                displayTitle
                                            )
                                          ? `Test Result of ${titleName}`
                                          : `Physical And Chemical Test Result of ${titleName}`} */}
                            {displayTitle === 'Bakery and Confectionary'
                                ? 'Test Result of Bakery and \nConfectionary'
                                : displayTitle.includes('(Chemical)')
                                  ? `Test Result of ${displayTitle.replace('(Chemical)', '')}`
                                  : displayTitle.includes(
                                          'Hardened Concrete Cube'
                                      )
                                    ? `Test Result On ${titleName}`
                                    : getReportTitleForChemical.includes(
                                            displayTitle
                                        )
                                      ? `Test Result of ${titleName}`
                                      : getReportTitleForPhysical.includes(
                                              displayTitle
                                          )
                                        ? `Test Result of ${titleName}`
                                        : getReportTitleForWater.includes(
                                                displayTitle
                                            )
                                          ? `Test Result of ${titleName}`
                                          : `Test Result of ${titleName}`}
                        </Text>
                    )}
                </View>
                {(type === 'report' ||
                    type === 'reportOnly' ||
                    type === 'testReport') && (
                    <Image src={qr} style={styles.bar} />
                )}
            </View>
        </Fragment>
    )
}

export default Title
