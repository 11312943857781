import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import TableTopHeader from './TableTopHeader'

const borderColor = '#ffffff'
const labelfontfamily = 'Times-Bold'
const fontSize = 10

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        borderBottomColor: borderColor,
        backgroundColor: '#d9d9d9',
        borderBottomWidth: 1,
        alignItems: 'center',
        border: '1px solid #d9d9d9',
        maxWidth: '100%',
        textAlign: 'center',
        paddingTop: 2,
        flexGrow: 1,
        fontSize: fontSize,
        fontFamily: labelfontfamily,
    },
    sNo: {
        width: '10%',
        paddingLeft: 3,
        paddingRight: 3,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    _sNo: {
        width: '14%',
        paddingLeft: 3,
        paddingRight: 3,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    description: {
        width: '15%',
        paddingLeft: 3,
        paddingRight: 3,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },

    qty: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },

    data: {
        width: '45%',
        paddingLeft: 3,
        paddingRight: 3,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        display: 'flex',
    },
    lastData: {
        paddingLeft: 3,
        paddingRight: 3,
        textAlign: 'center',
        width: '45%',
        wordWrap: 'break-word',
        display: 'flex',
    },
    _data: {
        width: '25%',
        paddingLeft: 3,
        paddingRight: 3,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        display: 'flex',
    },
    _lastData: {
        width: '25%',
        paddingLeft: 3,
        paddingRight: 3,
        textAlign: 'center',
        wordWrap: 'break-word',
        display: 'flex',
    },
    _lastDataFor3Label: {
        width: '33%',
        paddingLeft: 3,
        paddingRight: 3,
        textAlign: 'center',
        wordWrap: 'break-word',
        display: 'flex',
    },
    rowContainer: {
        flexDirection: 'column',
        borderBottomColor: '#ffffff',
        backgroundColor: '#d9d9d9',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 'auto',

        maxWidth: '100%',
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    rowData: {
        width: '80%',
        paddingLeft: 3,
        paddingRight: 3,
        // border: '1px solid #d9d9d9',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
    },
    rowLastData: {
        width: '20%',
        paddingLeft: 3,
        paddingRight: 3,
        borderLeftColor: borderColor,
        borderLeftWidth: 1,
        alignItems: 'center',
        textAlign: 'center',
        wordWrap: 'break-word',
    },
    trials: {
        width: '15%',
        paddingLeft: 3,
        paddingRight: 3,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
    },
    // _lastData1: {
    //     width: '20%',
    //     paddingLeft: 18,
    //     paddingRight: 48,
    //     textAlign: 'center',
    //     wordWrap: 'break-word',
    //     display: 'flex',
    // },
    _lastDataFor3Label1: {
        width: '20%',
        paddingLeft: 18,
        paddingRight: 48,
        textAlign: 'center',
        wordWrap: 'break-word',
        display: 'flex',
    },
    //new

    _data2: {
        width: '27%',
        paddingLeft: 3,
        paddingRight: 3,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    _lastData2: {
        width: '27%',
        paddingLeft: 3,
        paddingRight: 3,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },

    container1: {
        //display: 'flex',
        // // justifyContent:'flex-end',
        // flexDirection: 'row',
        // backgroundColor: '#d9d9d9',
        // alignItems: 'center',

        // height: 'auto',
        // // flexWrap: 'wrap',
        // // wordWrap: 'break-word',
        // fontSize: fontSize,
        // fontFamily: labelfontfamily,

        // maxWidth: '100%',
        // textAlign: 'center',
        // fontStyle: 'bold',
        // flexGrow: 1,

        maxWidth: '100%',
        flexDirection: 'row',
        alignItems: 'center',

        height: 'auto',
        fontSize: fontSize,
        fontFamily: labelfontfamily,

        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    _data1: {
        width: '20%',
        //height: '10px',
        paddingLeft: 20,
        paddingRight: 50,

        borderRightColor: borderColor,
        borderRightWidth: 1.5,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    _lastData1: {
        width: '20%',
        paddingLeft: 20,
        paddingRight: 50,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    sNo1: {
        width: '8%',
        paddingLeft: 3,
        paddingRight: 3,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    container2: {
        flexDirection: 'row',
        backgroundColor: '#d9d9d9',
        alignItems: 'center',

        height: 'auto',
        // flexWrap: 'wrap',
        // wordWrap: 'break-word',
        fontSize: fontSize,
        fontFamily: labelfontfamily,

        maxWidth: '100%',
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
        paddingTop: 2,
    },
    json:{
        width: '25%',
        paddingLeft: 3,
        paddingRight: 3,
        textAlign: 'center',
        wordWrap: 'break-word',
        display: 'flex',
        borderRightColor: borderColor,
        borderRightWidth: 1.5,
    },
    _json:{
        width: '30%',
        paddingLeft: 3,
        paddingRight: 3,
        textAlign: 'center',
        wordWrap: 'break-word',
        display: 'flex',
        borderRightColor: borderColor,
        borderRightWidth: 1.5,
    },
 json1:{
        width: '45%',
        paddingLeft: 3,
        paddingRight: 3,
        // borderRightColor: borderColor,
        // borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    _json1:{
        width: '45%',
        paddingLeft: 3,
        paddingRight: 3,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
json2:{
        width: '16%',
        //height: '10px',
        paddingLeft: 6,
        paddingRight: 43,

        borderRightColor: borderColor,

        borderRightWidth: 1.5,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    _json2:{
        width: '16%',
        borderRightColor: borderColor,
        
        borderRightWidth: 1.5,
        paddingLeft: 6,
        paddingRight: 43,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
})

const TableHeader = ({
    headerKeys,
    valueKeys,
    groupName,
    groupType,
    type,
    gradeAndSize,
    keyAndLabel,
    titleName,
    reportHeader,
    result,
    json,
}) => {
    if (
        headerKeys &&
        !valueKeys &&
        groupName !== 'Half-Cell Potential difference test'
    ) {
        let values = Object.values(headerKeys)

        return (
            <View style={styles.container} fixed>
                {(groupName === 'Reinforcement Steel (Physical)' &&
                    type === 'observationNull') ||
                groupName ===
                    'Equivalent Cube Compressive Stregnth Results of Concrete Core' ? (
                    <Text style={styles._sNo}>S. No</Text>
                ) : groupName != 'Normal Consistency' ? (
                    <Text style={styles.sNo}>S. No</Text>
                ) : (
                    <Text style={styles.trials}>Trials</Text>
                )}

                {values?.map((value, index) =>
                    values.length > 4 ? (
                        <Text
                            key={index}
                            style={
                                index === values.length - 1
                                    ? styles._lastData
                                    : styles._data
                            }
                        >
                            {value}
                        </Text>
                    ) : (
                        <Text
                            key={index}
                            style={
                                index === values.length - 1
                                    ? styles.lastData
                                    : styles.data
                            }
                        >
                            {value}
                        </Text>
                    )
                )}
            </View>
        )
    }
    if (valueKeys) {
        let headerValues = Object.values(headerKeys)

        return (
            <View>
                {(type === 'report' || type === 'reportOnly') &&
                    groupName !== 'Water Absorption' && (
                        <TableTopHeader groupName={groupName} />
                    )}

                <View style={styles.container}>
                    {headerValues?.map((value, index) => (
                        <Text
                            key={index}
                            style={
                                index === headerValues.length - 1
                                    ? styles.rowLastData
                                    : styles.rowData
                            }
                        >
                            {value}
                        </Text>
                    ))}
                </View>
            </View>
        )
    }
    if (headerKeys && groupName === 'Half-Cell Potential difference test') {
        let headerValues = Object.values(headerKeys)

        return (
            <View style={styles.container}>
                {type === 'report' ||
                    (type === 'reportOnly' && (
                        <TableTopHeader groupName={groupName} />
                    ))}

                <Text
                    style={{
                        width: '4%',
                        paddingLeft: 3,
                        paddingRight: 3,
                        borderRightColor: borderColor,
                        borderRightWidth: 1,
                        textAlign: 'center',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                    }}
                >
                    S. No
                </Text>
                <Text
                    style={{
                        width: '90%',
                        paddingLeft: 49,
                        paddingRight: 3,

                        textAlign: 'center',
                        wordWrap: 'break-word',
                        display: 'flex',
                    }}
                >
                    Observation
                </Text>

                <Text
                    style={{
                        width: '9%',
                        paddingLeft: 2,
                        paddingRight: 3,
                        borderLeftColor: borderColor,
                        borderLeftWidth: 1,
                        textAlign: 'center',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                    }}
                >
                    Average
                </Text>
            </View>
        )
    }
    if (keyAndLabel?.length !== 0 && groupName !== 'Sieve Analysis Result on GSB Sample') {
        return (
            <View style={styles.container}>
                {keyAndLabel?.map((value, index) => (
                    <>
                        <Text
                            style={
                                keyAndLabel?.length === 3
                                    ? styles._lastDataFor3Label
                                    : styles._lastData
                            }
                        >
                            {value.label}
                        </Text>
                    </>
                ))}
            </View>
        )
    }

    if (json?.length !== 0 && groupName === 'Sieve Analysis Result on GSB Sample') {
        return (
          <View style={styles.container}>
            {json.map((value, index) => {
              if (value?.keys?.length === 0) {
                return (
                  <Text
                    key={index}
                    style={
                      json.length === 3
                        ? styles._json
                        : styles.json
                    }
                  >
                    {value.label}
                  </Text>
                );
              } else {
                return (
                  <View key={index} 
                 // style={styles.container}
                  >
                    <Text
                      style={
                        index === json.length - 1
                        ? styles._json1
                        : styles.json1
                      }
                    >
                      {value.label}
                    </Text>
                    <View style={styles.container1}>
                      {value.keys.map((val, idx) => (
                        <Text
                          key={idx}
                          style={
                            idx === value.keys.length - 1
                              ? styles._json2
                              : styles.json2
                          }
                        >
                          {val.label}
                        </Text>
                      ))}
                    </View>
                  </View>
                );
              }
            })}
          </View>
        );
      }

    // if (groupType === 'DYNAMIC_JOBCARD') {
    //     if (reportHeader?.fields.length !== 0) {
    //         return (
    //             <View style={styles.container}>
    //                 <Text
    //                     style={
    //                         keyAndLabel?.length === 3
    //                             ? styles._lastDataFor3Label
    //                             : styles._lastData
    //                     }
    //                 >
    //                     S.No
    //                 </Text>
    //                 {reportHeader?.fields.map((value, index) => {
    //                     if (value?.fields.length === 0) {
    //                         return (
    //                             <Text
    //                                 key={index}
    //                                 style={
    //                                     keyAndLabel?.length === 3
    //                                         ? styles._lastDataFor3Label
    //                                         : styles._lastData
    //                                 }
    //                             >
    //                                 {value.label}
    //                             </Text>
    //                         )
    //                     } else {
    //                         return (
    //                             <View key={index}>
    //                                 <Text
    //                                     style={
    //                                         keyAndLabel?.length === 3
    //                                             ? styles._lastDataFor3Label
    //                                             : styles._lastData
    //                                     }
    //                                 >
    //                                     {value.label}
    //                                 </Text>
    //                                 <View style={styles.container}>
    //                                     {value?.fields?.map((val, idx) => (
    //                                         <Text
    //                                             key={idx}
    //                                             style={
    //                                                 keyAndLabel?.length === 3
    //                                                     ? styles._lastDataFor3Label1
    //                                                     : styles._lastData1
    //                                             }
    //                                         >
    //                                             {val?.label}
    //                                         </Text>
    //                                     ))}
    //                                 </View>
    //                             </View>
    //                         )
    //                     }
    //                 })}
    //             </View>
    //         )
    //     }
    // }

    if (groupType === 'DYNAMIC_JOBCARD') {
        if (reportHeader?.fields.length !== 0) {
            return (
                <View style={styles.container2} fixed>
                    <Text style={styles.sNo1}>S.No</Text>
                    {reportHeader?.fields.map((value, index) => {
                        if (value?.fields.length === 0) {
                            return (
                                <Text
                                    key={index}
                                    style={
                                        index === reportHeader.fields.length - 1
                                            ? styles._lastData2
                                            : styles._data2
                                    }
                                >
                                    {value.label}
                                </Text>
                            )
                        } else {
                            return (
                                <>
                                    <View
                                    //style={{ maxWidth: '100%' }}
                                    //style={styles.container}
                                    >
                                        <Text
                                            key={index}
                                            style={
                                                index ===
                                                reportHeader.fields.length - 1
                                                    ? styles._lastData2
                                                    : styles._data2
                                            }
                                        >
                                            {value.label}
                                        </Text>
                                        <View style={styles.container1}>
                                            {value?.fields?.map((val, idx) => {
                                                return (
                                                    <Text
                                                        key={idx}
                                                        style={
                                                            idx ===
                                                            reportHeader.fields
                                                                .length -
                                                                1
                                                                ? styles._lastData1
                                                                : styles._data1
                                                        }
                                                        //     style={{
                                                        //         maxWidth: '150%',
                                                        //         width: '50px',
                                                        //     }}
                                                    >
                                                        {val?.label}
                                                    </Text>
                                                )
                                            })}
                                        </View>
                                    </View>
                                    {/* <Text
                                        key={index}
                                        style={
                                            index ===
                                            result.reportheader.fields.length -
                                                1
                                                ? styles._lastData
                                                : styles._data
                                        }
                                    >
                                        {value.label}
                                    </Text> */}
                                </>
                            )
                        }
                    })}
                </View>
            )
        }
    }

    // if (groupType === 'DYNAMIC_JOBCARD') {
    //     if (reportHeader?.fields.length !== 0) {
    //         return (
    //             <View style={styles.container}>
    //                 <Text
    //                     style={
    //                         keyAndLabel?.length === 3
    //                             ? styles._lastDataFor3Label
    //                             : styles._lastData
    //                     }
    //                 >
    //                     S.No
    //                 </Text>
    //                 {reportHeader?.fields.map((value, index) => (
    //                     if (value?.fields.length === 0) {
    //                         return (
    //                     // <>
    //                         <Text
    //                             style={
    //                                 keyAndLabel?.length === 3
    //                                     ? styles._lastDataFor3Label
    //                                     : styles._lastData
    //                             }
    //                         >
    //                             {value.label}
    //                         </Text>
    //                     // </>
    //                         )
    //                     }
    //                     else{
    //                         return(
    //                             <>
    //                             <View>
    //                             <Text
    //                             style={
    //                                 keyAndLabel?.length === 3
    //                                     ? styles._lastDataFor3Label
    //                                     : styles._lastData
    //                             }
    //                         >
    //                             {value.label}
    //                         </Text>
    //                         <View>
    //                         {value?.fields?.map((val, idx) => {
    //                             return (
    //                                 <Text
    //                                     key={idx}
    //                                     >
    //                                                     {val?.label}
    //                                                 </Text>
    //                                                 )
    //                                             })}

    //                         </View>
    //                         </View>
    //                         )
    //                     }
    //                 ))}
    //             </View>
    //         )
    //     }
    // }
    // if (groupType === 'DYNAMIC_JOBCARD') {
    //     if (reportHeader?.fields.length !== 0) {
    //         return (
    //             <View style={styles.container}>
    //                 <Text
    //                     style={
    //                         keyAndLabel?.length === 3
    //                             ? styles._lastDataFor3Label
    //                             : styles._lastData
    //                     }
    //                 >
    //                     S.No
    //                 </Text>
    //                 {reportHeader?.fields.map((value, index) => {
    //                     if (value?.fields.length === 0) {
    //                         return (
    //                             <Text
    //                                 key={index}
    //                                 // style={
    //                                 //     index === reportHeader.fields.length - 1
    //                                 //         ? styles._lastData
    //                                 //         : styles._data
    //                                 // }
    //                                 style={
    //                                     keyAndLabel?.length === 3
    //                                         ? styles._lastDataFor3Label
    //                                         : styles._lastData
    //                                 }
    //                             >
    //                                 {value.label}
    //                             </Text>
    //                         )
    //                     } else {
    //                         return (
    //                             <>
    //                                 <View
    //                                 //style={{ maxWidth: '100%' }}
    //                                 //style={styles.container}
    //                                 >
    //                                     <Text
    //                                         key={index}
    //                                         // style={
    //                                         //     index ===
    //                                         //     reportHeader.fields.length - 1
    //                                         //         ? styles._lastData
    //                                         //         : styles._data
    //                                         // }
    //                                         style={
    //                                             keyAndLabel?.length === 3
    //                                                 ? styles._lastDataFor3Label
    //                                                 : styles._lastData
    //                                         }
    //                                     >
    //                                         {value.label}
    //                                     </Text>
    //                                     <View style={styles.container1}>
    //                                         {value?.fields?.map((val, idx) => {
    //                                             return (
    //                                                 <Text
    //                                                     key={idx}
    //                                                     style={
    //                                                         idx ===
    //                                                         reportHeader.fields
    //                                                             .length -
    //                                                             1
    //                                                             ? styles._lastData1
    //                                                             : styles._data1
    //                                                     }
    //                                                     //     style={{
    //                                                     //         maxWidth: '150%',
    //                                                     //         width: '50px',
    //                                                     //     }}
    //                                                 >
    //                                                     {val?.label}
    //                                                 </Text>
    //                                             )
    //                                         })}
    //                                     </View>
    //                                 </View>
    //                                 {/* <Text
    //                                     key={index}
    //                                     style={
    //                                         index ===
    //                                         result.reportheader.fields.length -
    //                                             1
    //                                             ? styles._lastData
    //                                             : styles._data
    //                                     }
    //                                 >
    //                                     {value.label}
    //                                 </Text> */}
    //                             </>
    //                         )
    //                     }
    //                 })}
    //             </View>
    //         )
    //     }
    // }
    else {
        return <View></View>
    }
}

export default TableHeader
