import {
    deleteArchivedUserData,
    deleteUserData,
    fetchUserData,
} from 'app/services/UserService'

export const getUsersList = (props, callBack) => {
    fetchUserData(
        props,
        (res) =>
            // dispatch({
            //     type: USERS.SET_USERS_DATA,
            //     payload: res?.data?.data,
            // }),
            callBack(res?.data),

        (err) => console.log(err)
    )
}

export const deleteUser = (props, callBack) => {
    deleteUserData(
        props,
        (res) =>
            // dispatch({
            //     type: USERS.SET_USERS_DATA,
            //     payload: res?.data?.data,
            // }),
            callBack(res?.data),

        (err) => console.log(err)
    )
}

export const deleteArchivedUser = (props, callBack) => {
    deleteArchivedUserData(
        props,
        (res) =>
            // dispatch({
            //     type: USERS.SET_USERS_DATA,
            //     payload: res?.data?.data,
            // }),
            callBack(res),

        (err) => console.log(err)
    )
}
