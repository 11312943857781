import { Button, Grid, Icon } from '@mui/material'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import SampleRequestTable from '../clientView/SampleRequestTable'
import { Container } from '../material-kit/tables/AppTable'

const Sample = () => {
    const navigate = useNavigate()
    const role = Cookies.get('userRole')
    return (
        <Container>
            <Grid container spacing={2}>
                {role !== 'COLLECTOR' && (
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            endIcon={<Icon>control_point</Icon>}
                            onClick={() => {
                                navigate('/sample/add')
                            }}
                        >
                            Sample Request
                        </Button>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <SampleRequestTable />
                </Grid>
            </Grid>
        </Container>
    )
}

export default Sample
