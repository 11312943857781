import {
    Box,
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const PlywoodTable = ({
    data,
    setData,
    setFinalData,
    finalData,
    result,
    setOverall,
    overall,
}) => {
    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && item.data.length > 0) {
                item.data = _data
                if (item.data.length > 0) {
                    _data.forEach((item1) => {})
                }
            }
            if (item.groupName === result.groupName) {
                item.data = _data
            }
        })
        setFinalData(_finalData)
    }

    const Column = [
        // { text: 'Test Conducted', dataField: 'tests' },

        { text: 'Test Results', dataField: 'result' },
        { text: 'Requirements as per Standard Ref', dataField: 'req' },
    ]

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">{'Test Results'}</TableCell>

                        {Column.map((item, index) => (
                            <TableCell colSpan={2} align={'center'} key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                        <TableCell sx={{ width: 80 }} align={'center'}>
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((item, index) =>
                        !item?.tests.includes('Bending') ? (
                            <TableRow key={index}>
                                <TableCell variant="head" align="center">
                                    {item?.tests}
                                </TableCell>
                                {Column.map((subItem, idx) => (
                                    <TableCell
                                        align="center"
                                        colSpan={2}
                                        key={`row-${idx}-index`}
                                    >
                                        {item[subItem.dataField]}
                                    </TableCell>
                                ))}

                                <TableCell align="center">
                                    <Button
                                        onClick={(e) => handleDelete(index, e)}
                                    >
                                        <Icon>delete</Icon>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                <TableRow>
                                    <TableCell variant="head" align="center">
                                        {item?.tests}
                                    </TableCell>
                                    <TableCell align="center">
                                        Average Modulus of Elasticity (N/Sq.mm)
                                    </TableCell>
                                    <TableCell align="center">
                                        Average Modulus of Rupture (N/Sq.mm)
                                    </TableCell>
                                    <TableCell align="center">
                                        Modulus of Elasticity (N/Sq.mm)
                                    </TableCell>
                                    <TableCell align="center">
                                        Modulus of Rupture (N/Sq.mm)
                                    </TableCell>
                                    <TableCell align="center" rowSpan={3}>
                                        <Button
                                            onClick={(e) =>
                                                handleDelete(index, e)
                                            }
                                        >
                                            <Icon>delete</Icon>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">
                                        i ) Along (Parallel to the Grain
                                        Direction of the face Veneer)
                                    </TableCell>
                                    <TableCell align="center">
                                        {item?.elasticity_along}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item?.rupture_along}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item?.elasticity_along_req}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item?.rupture_along_req}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">
                                        ii ) Across(Perpendicular to the Grain
                                        direction of the face Veneer)
                                    </TableCell>
                                    <TableCell align="center">
                                        {item?.elasticity_across}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item?.rupture_across}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item?.elasticity_across_req}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item?.rupture_across_req}
                                    </TableCell>
                                </TableRow>
                            </>
                        )
                    )}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default PlywoodTable
