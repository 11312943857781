import { Box, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const ImpactValueTable = (props) => {
    const { data } = props

    //Formula
    // let value = ((data.weight_fraction_passing_through / data.weight_oven_dry_sample) * 100)
    // data.aggregate_impact_value = (value) ? valueCalc(value) : '0'

    let impactValue

    impactValue = [
        {
            label: 'Weight of Oven dried sample, A (g)',
            value: 'weight_oven_dry_sample',
        },
        {
            label: 'Weight of fraction passing through 2.36mm seive, B (g)',
            value: 'weight_fraction_passing_through',
        },
        {
            label: 'Aggregate impact value (B/A)* 100',
            value: 'aggregate_impact_value',
        },
    ]

    const Column = [
        {
            text: 'Observation',
            dataField: 'label',
        },
        {
            text: 'Result',
            dataField: 'value',
        },
    ]

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">SI.No</TableCell>
                        {Column.map((item, index) => (
                            <TableCell align="center" key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                {impactValue?.map((item, index) => (
                    <TableBody>
                        <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{item.label}</TableCell>
                            <TableCell align="center">
                                {data[item.value] ? data[item.value] : '0'}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ))}
            </StyledTable>
        </Box>
    )
}

export default ImpactValueTable


// const handleDelete = (index, e) => {
//     let _data = data.filter((v, i) => i !== index);
//     setData(_data);
//     let _finalData = finalData;
//     _finalData.forEach(item => {
//         if (item.groupName === result.groupName) {
//             item.overall = _data;
//         }
//     })
//     setFinalData(_finalData);
// }