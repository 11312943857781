import {
    Autocomplete,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Dialog,
    DialogContent,
    FormControlLabel,
    Grid,
    InputLabel,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { updateJobList } from 'api/Action'
import { ContentBox } from 'app/assets/styled-components/SharedComponents'
import { Breadcrumb, MatxLoading } from 'app/components'
import DeleteDilaog from 'app/components/Dilaog/DeleteDilaog'
import {
    getNotificationJobData,
    getNotificationsData,
} from 'app/redux/actions/NotificationAction'
import {
    deleteSampleByArchived,
    deleteSampleById,
    getAssigneeList,
    getTestView,
} from 'app/redux/actions/TestActions'
import TestViewTable from 'app/views/material-kit/tables/TestViewTable'
import Cookies from 'js-cookie'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import SnackBar from '../../components/SnackBar'
import {
    StyledDivider,
    TestStyledCard,
} from '../jobs/StyledComponents/StyledComponents'
import { createPdfFromHtml } from './htmltopdf'

const status = [{ label: 'TODO' }, { label: 'PROGRESS' }, { label: 'REJECTED' }]

const TestView = () => {
    const params = useParams()
    const navigate = useNavigate()
    const userRole = Cookies.get('userRole')
    let user_id = Cookies.get('userId')
    let cookiesRequestType = Cookies.get('request_type')

    const dispatch = useDispatch()
    const { assigneeList } = useSelector((state) => state.testReducer)

    const [getDetails, setDetails] = useState({})
    const [open, setOpen] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [date, setDate] = useState(null)
    const [statusValue, setStatusValue] = useState(null)
    const [jobData, setJobData] = useState([])
    const [jobDataById, setJobDataById] = useState(null)
    const [AssigneeValue, setAssigneeValue] = useState(null)
    const [jobList, setJobList] = useState([])
    const [loading, setLoading] = useState(true)
    const [value, setValue] = useState({})

    const [snack, setSnack] = useState({
        snackOpen: false,
        snackVaraint: '',
        snackMsg: '',
    })
    const [jobbyId, setJobById] = useState({
        allocated_to: null,
        due_date: '',
        status: '',
        test_data: '',
        job_no: '',
        _id: '',
        request_type: '',
    })
    const [jobIndex, setJobIndex] = useState()

    useEffect(() => {
        getTestView(params?.id, (response) => {
            setDetails(response?.data)
            setJobData(response?.data?.jobs)
            if (response?.data?.jobs)
                setRowsPerPage(response?.data?.jobs.length)
            response?.data?.jobs?.map((item) => {
                let arr = jobList
                arr.push({
                    _id: item._id,
                    allocated_to: item.allocated_to?._id,
                    job_no: item.job_no,
                    status: item.status,
                    test_data: item.test_data,
                    due_date: item.due_date,
                    request_type: item.request_type,
                })
                setJobList(arr)
            })
            if (assigneeList.length == 0) {
                dispatch(getAssigneeList('LAB_TECHNICIANS'))
            }
            setLoading(false)
        })
        // dispatch(getAssigneeList('LAB_TECHNICIANS'))
    }, [])

    const handleOpen = (value, index) => {
        setJobIndex(index)
        setJobDataById({
            allocated_to: value.allocated_to,
            status: value.status,
            test_data: value.test_data,
            due_date: value.due_date,
            job_no: value.job_no,
            _id: value._id,
            test_name: value?.material
                ? value?.material?.name
                : value?.test_name,
        })
        setJobById({
            allocated_to: value.allocated_to,
            status: value.status,
            test_data: value.test_data,
            due_date: value.due_date,
            job_no: value.job_no,
            _id: value._id,
            request_type: value.request_type,
        })
        setDate(value?.due_date)
        setStatusValue({ label: value?.status })
        setAssigneeValue(value?.allocated_to)
        setAssignee(value?.allocated_to)
        setOpen(true)
    }
    const handleClose = () => setOpenDialog(false)
    const handleDialogClose = () => setOpen(false)
    const [rowsPerPage, setRowsPerPage] = useState(20)
    // const [page, setPage] = React.useState(0)
    const [assignee, setAssignee] = useState()

    // const handleChange = (e) => {
    //     const selectedAssignee = e.target.value

    //  const selectedAssigneeState = testList.filter(
    //      (data) => data.id === selectedAssignee
    //  )[0]
    //
    //  setAssignee(selectedAssigneeState)

    const handleStatusChange = (value) => {
        setStatusValue(value)
    }
    const handleDateChange = (e, value) => {
        setDate(e)
    }

    const onAdd = () => {
        let finalDate = date ? new Date(date) : '-'
        let value = jobDataById
        let job = jobbyId
        value.due_date = finalDate
        value.status = statusValue?.label
        value.allocated_to = AssigneeValue
        job.due_date = finalDate
        job.status = statusValue?.label
        job.allocated_to = AssigneeValue?._id
        let jobArr = jobData

        jobArr[jobIndex] = value
        let arr = jobList
        arr[jobIndex] = job
        setJobList(arr)
        setJobData(jobArr)
        if (jobArr) setRowsPerPage(jobArr.length)
        setOpen(false)
    }

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage)
    // }

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(+event.target.value)
    //     setPage(0)
    // }

    const handleAssigneeChange = (value) => {
        if (value) {
            setAssignee(value?._id)
            setAssigneeValue(value)
        }
    }
    const handleOpenDialog = () => {
        setOpenDialog(true)
    }

    const handleSave = () => {
        let obj = {
            specification: getDetails.specification,
            // request_type: getDetails.request_type,
            supplier_name: getDetails.supplier_name,
            invoiceNo: getDetails?.invoiceNo,

            // ulr_no: getDetails.ulr_no,
            source: getDetails.source,
            due_date: getDetails.due_date,
            return_sample_customer: getDetails?.return_sample_customer,
            billing: getDetails.billing,
            customer: getDetails.customer,
            prepared_by: getDetails.prepared_by?._id,
            reviewer_by: getDetails.reviewer_by?._id,
            authorized_by: getDetails.authorized_by?._id,
            jobs: jobList,
        }

        setLoading(true)
        updateJobList(params.id, obj).then(
            (response) => {
                if (response.status === 200) {
                    setLoading(false)
                    setSnack({
                        ...snack,
                        snackOpen: 'true',
                        snackMsg: 'Saved Successfully',
                        snackVaraint: 'success',
                    })
                    setTimeout(() => {
                        navigate(`/test`)
                    }, 2000)
                } else {
                    setLoading(false)

                    setSnack({
                        ...snack,
                        snackOpen: 'true',
                        snackMsg: response ?? 'Network Failed',
                        snackVaraint: 'error',
                    })
                }
            },
            (error) => {
                setLoading(false)
                setSnack({
                    ...snack,
                    snackOpen: 'true',
                    snackMsg: error ?? 'Network Failed',
                    snackVaraint: 'error',
                })
            }
        )
    }

    const fetchNotification = () => {
        dispatch(
            getNotificationsData({
                user_id: user_id,
                status: 'ARCHIVED',
                visited: false,
                size: 100,
            })
        )
        dispatch(
            getNotificationJobData({
                user_id: user_id,
                status: 'ARCHIVED',
                visited: false,
                size: 100,
            })
        )
    }

    const handleRestore = () => {
        let status = { status: 'TODO' }
        let test_id = getDetails?._id
        deleteSampleByArchived({ test_id, status }, (res) => {
            let msg = res?.Message

            if (res.status === 200) {
                fetchNotification()
                setSnack({
                    ...snack,
                    snackOpen: 'true',
                    snackMsg: 'Restore Successfully',
                    snackVaraint: 'success',
                })
                setTimeout(() => {
                    navigate(-1)
                }, 500)
            } else {
                setSnack({
                    ...snack,
                    snackOpen: 'false',
                    snackMsg: msg,
                    snackVaraint: 'error',
                })
            }
        })
    }

    const handleDelete = () => {
        let test_id = getDetails?._id
        deleteSampleById(test_id, (res) => {
            let msg = res?.Message
            // if (res.status === 200) {
            setSnack({
                ...snack,
                snackOpen: 'true',
                snackMsg: 'Delete Successfully',
                snackVaraint: 'success',
            })
            setTimeout(() => {
                navigate(-1)
            }, 500)

            // } else {
            //     setSnack({
            //         ...snack,
            //         snackOpen: 'false',
            //         snackMsg: msg,
            //         snackVaraint: 'error'
            //     })
            // }
        })
    }

    let due_date = new Date(getDetails?.due_date).toLocaleDateString()
    const current_date = new Date()
    let min_date = new Date(getDetails?.due_date)

    const handleClick = () => {
        createPdfFromHtml(document.getElementById('content'))
    }

    if (loading) {
        return <MatxLoading />
    } else {
        return (
            <>
                <Fragment>
                    <ContentBox>
                        <Grid container spacing={2}>
                            {userRole !== 'CUSTOMER' && (
                                <Grid item xs={12}>
                                    <Breadcrumb
                                        routeSegments={[
                                            { name: 'Tests ', path: '/Test' },
                                            { name: 'Test View' },
                                        ]}
                                    />
                                </Grid>
                            )}
                            {/* {userRole === "ADMIN" && <Grid item xs={12} >

                                <Button variant='contained' endIcon={<Icon>picture_as_pdf</Icon>} onClick={handleClick}>Download</Button>
                            </Grid>} */}
                            {(userRole === 'ADMIN' || userRole === 'SUPER_ADMIN') && (
                                <Grid item xs={12}>
                                    <Card>
                                        <CardHeader title="Customer Details" />
                                        <StyledDivider />
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={6}
                                                    sm={6}
                                                    xs={6}
                                                >
                                                    <Box>
                                                        <InputLabel>
                                                            {'Customer Name'}
                                                        </InputLabel>
                                                        <TextField
                                                            variant="standard"
                                                            disabled
                                                            multiline
                                                            value={
                                                                getDetails
                                                                    ?.customer
                                                                    ?.name
                                                            }
                                                            size="small"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={6}
                                                    sm={6}
                                                    xs={6}
                                                >
                                                    <Box>
                                                        <InputLabel>
                                                            {'Email'}
                                                        </InputLabel>
                                                        <TextField
                                                            variant="standard"
                                                            disabled
                                                            multiline
                                                            value={
                                                                getDetails
                                                                    ?.customer
                                                                    ?.email
                                                            }
                                                            size="small"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={6}
                                                    sm={6}
                                                    xs={6}
                                                >
                                                    <Box>
                                                        <InputLabel>
                                                            {'Address'}
                                                        </InputLabel>
                                                        <TextField
                                                            variant="standard"
                                                            disabled
                                                            multiline
                                                            value={
                                                                getDetails
                                                                    ?.customer
                                                                    ?.address
                                                            }
                                                            size="small"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={6}
                                                    sm={6}
                                                    xs={6}
                                                >
                                                    <Box>
                                                        <InputLabel>
                                                            {'Contact Person'}
                                                        </InputLabel>
                                                        <TextField
                                                            variant="standard"
                                                            disabled
                                                            multiline
                                                            value={
                                                                getDetails
                                                                    ?.customer
                                                                    ?.contact_person
                                                            }
                                                            size="small"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={6}
                                                    sm={6}
                                                    xs={6}
                                                >
                                                    <Box>
                                                        <InputLabel>
                                                            {'Mobile Number'}
                                                        </InputLabel>
                                                        <TextField
                                                            variant="standard"
                                                            disabled
                                                            multiline
                                                            value={
                                                                getDetails
                                                                    ?.customer
                                                                    ?.phoneNumber
                                                            }
                                                            size="small"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={6}
                                                    sm={6}
                                                    xs={6}
                                                >
                                                    <Box>
                                                        <InputLabel>
                                                            {'Quotation'}
                                                        </InputLabel>
                                                        <TextField
                                                            variant="standard"
                                                            disabled
                                                            multiline
                                                            value={
                                                                getDetails
                                                                    ?.customer
                                                                    ?.quotation_ref
                                                            }
                                                            size="small"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={6}
                                                    sm={6}
                                                    xs={6}
                                                >
                                                    <Box>
                                                        <InputLabel>
                                                            {'Project'}
                                                        </InputLabel>
                                                        <TextField
                                                            variant="standard"
                                                            disabled
                                                            multiline
                                                            value={
                                                                getDetails
                                                                    ?.customer
                                                                    ?.projects
                                                            }
                                                            size="small"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={6}
                                                    sm={6}
                                                    xs={6}
                                                >
                                                    <Box>
                                                        <InputLabel>
                                                            {'Secondary Email'}
                                                        </InputLabel>
                                                        <TextField
                                                            variant="standard"
                                                            disabled
                                                            multiline
                                                            value={
                                                                getDetails
                                                                    ?.customer
                                                                    ?.customer_additional_email
                                                            }
                                                            size="small"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={6}
                                                    sm={6}
                                                    xs={6}
                                                >
                                                    <Box>
                                                        <InputLabel>
                                                            {
                                                                'Secondary Mobile Number'
                                                            }
                                                        </InputLabel>
                                                        <TextField
                                                            variant="standard"
                                                            disabled
                                                            multiline
                                                            value={
                                                                getDetails
                                                                    ?.customer
                                                                    ?.customer_additional_phoneNo
                                                            }
                                                            size="small"
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Card>
                                    <CardHeader title="Test Details" />
                                    <StyledDivider />
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid
                                                item
                                                lg={4}
                                                md={6}
                                                sm={6}
                                                xs={6}
                                            >
                                                <Box>
                                                    <InputLabel>
                                                        {'Job No.'}
                                                    </InputLabel>
                                                    <TextField
                                                        variant="standard"
                                                        disabled
                                                        multiline
                                                        value={
                                                            getDetails.job_no
                                                        }
                                                        size="small"
                                                    />
                                                </Box>
                                            </Grid>

                                            <Grid
                                                item
                                                lg={4}
                                                md={6}
                                                sm={6}
                                                xs={6}
                                            >
                                                <Box>
                                                    <InputLabel>
                                                        {'Project Name'}
                                                    </InputLabel>
                                                    <TextField
                                                        variant="standard"
                                                        disabled
                                                        multiline
                                                        value={
                                                            getDetails?.customer
                                                                ?.projects
                                                        }
                                                        size="small"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={4}
                                                md={6}
                                                sm={6}
                                                xs={6}
                                            >
                                                <Box>
                                                    <InputLabel>
                                                        {'Supplier Name'}
                                                    </InputLabel>
                                                    <TextField
                                                        variant="standard"
                                                        disabled
                                                        multiline
                                                        value={
                                                            getDetails?.supplier_name
                                                        }
                                                        size="small"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={4}
                                                md={6}
                                                sm={6}
                                                xs={6}
                                            >
                                                <Box>
                                                    <InputLabel>
                                                        {
                                                            'Invoice No. / Lot No.'
                                                        }
                                                    </InputLabel>
                                                    <TextField
                                                        variant="standard"
                                                        disabled
                                                        multiline
                                                        value={
                                                            getDetails?.invoiceNo
                                                        }
                                                        size="small"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={4}
                                                md={6}
                                                sm={6}
                                                xs={6}
                                            >
                                                <Box>
                                                    <InputLabel>
                                                        {'Sample drawn by'}
                                                    </InputLabel>
                                                    <TextField
                                                        variant="standard"
                                                        disabled
                                                        multiline
                                                        value={
                                                            getDetails?.sample_drawn_by
                                                        }
                                                        size="small"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={4}
                                                md={6}
                                                sm={6}
                                                xs={6}
                                            >
                                                <Box>
                                                    <InputLabel>
                                                        {
                                                            'Mode of Receipt of Samples'
                                                        }
                                                    </InputLabel>
                                                    <TextField
                                                        variant="standard"
                                                        disabled
                                                        multiline
                                                        value={
                                                            getDetails?.mode_of_receipt
                                                        }
                                                        size="small"
                                                    />
                                                </Box>
                                            </Grid>
                                            {/* {userRole === 'ADMIN' && <Grid item lg={4} md={6} sm={6} xs={6}>
                                                <Box>
                                                    <InputLabel>
                                                        {'Mode'}
                                                    </InputLabel>
                                                    <TextField
                                                        variant="standard"
                                                        disabled
                                                        multiline
                                                        value={getDetails?.request_type}
                                                        size="small"
                                                    />
                                                </Box>
                                            </Grid>}
                                            {getDetails.request_type === "NABL" && (
                                                <Grid item lg={4} xs={6}>
                                                    <Box>
                                                        <InputLabel>
                                                            {'ULR No.'}
                                                        </InputLabel>
                                                        <TextField
                                                            fullWidth
                                                            multiline
                                                            variant="standard"
                                                            disabled
                                                            value={
                                                                getDetails.ulr_no + 'F'

                                                            }
                                                            size="small"
                                                        />
                                                    </Box>
                                                </Grid>
                                            )} */}

                                            {jobData?.map((value, indx) => {
                                                return (
                                                    <>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <CardHeader
                                                                title={
                                                                    value
                                                                        ?.material
                                                                        ?.name
                                                                }
                                                            />
                                                            <StyledDivider />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={4}
                                                            md={6}
                                                            sm={6}
                                                            xs={6}
                                                        >
                                                            <Box>
                                                                <InputLabel>
                                                                    {
                                                                        'Specification'
                                                                    }
                                                                </InputLabel>
                                                                <TextField
                                                                    variant="standard"
                                                                    disabled
                                                                    multiline
                                                                    value={
                                                                        value?.specification
                                                                    }
                                                                    size="small"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={4}
                                                            md={6}
                                                            sm={6}
                                                            xs={6}
                                                        >
                                                            <Box>
                                                                <InputLabel>
                                                                    {'Type'}
                                                                </InputLabel>
                                                                <TextField
                                                                    variant="standard"
                                                                    disabled
                                                                    multiline
                                                                    value={
                                                                        value?.type
                                                                    }
                                                                    size="small"
                                                                />
                                                            </Box>
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            lg={4}
                                                            md={6}
                                                            sm={6}
                                                            xs={6}
                                                        >
                                                            <Box>
                                                                <InputLabel>
                                                                    {
                                                                        'Sample Condition'
                                                                    }
                                                                </InputLabel>
                                                                <TextField
                                                                    variant="standard"
                                                                    disabled
                                                                    multiline
                                                                    value={
                                                                        value?.sample_conditions
                                                                    }
                                                                    size="small"
                                                                />
                                                            </Box>
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            lg={4}
                                                            md={6}
                                                            sm={6}
                                                            xs={6}
                                                        >
                                                            <Box>
                                                                <InputLabel>
                                                                    {'Quantity'}
                                                                </InputLabel>
                                                                <TextField
                                                                    variant="standard"
                                                                    disabled
                                                                    multiline
                                                                    value={
                                                                        value?.quantity
                                                                    }
                                                                    size="small"
                                                                />
                                                            </Box>
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            lg={4}
                                                            md={6}
                                                            sm={6}
                                                            xs={6}
                                                        >
                                                            <Box>
                                                                <InputLabel>
                                                                    {'Unit'}
                                                                </InputLabel>
                                                                <TextField
                                                                    variant="standard"
                                                                    disabled
                                                                    multiline
                                                                    value={
                                                                        value?.unit
                                                                    }
                                                                    size="small"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        {(userRole === 'ADMIN' || userRole === 'SUPER_ADMIN') &&
                                                            cookiesRequestType !==
                                                                'NABL' && (
                                                                <Grid
                                                                    item
                                                                    lg={4}
                                                                    md={6}
                                                                    sm={6}
                                                                    xs={6}
                                                                >
                                                                    <Box>
                                                                        <InputLabel>
                                                                            {
                                                                                'Mode'
                                                                            }
                                                                        </InputLabel>
                                                                        <TextField
                                                                            variant="standard"
                                                                            disabled
                                                                            multiline
                                                                            value={
                                                                                value?.request_type
                                                                            }
                                                                            size="small"
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                            )}
                                                        {value.request_type ===
                                                            'NABL' &&
                                                            value?.ulr_no && (
                                                                <Grid
                                                                    item
                                                                    lg={4}
                                                                    xs={6}
                                                                >
                                                                    <Box>
                                                                        <InputLabel>
                                                                            {
                                                                                'ULR No.'
                                                                            }
                                                                        </InputLabel>
                                                                        <TextField
                                                                            fullWidth
                                                                            multiline
                                                                            variant="standard"
                                                                            disabled
                                                                            value={
                                                                                value.ulr_no +
                                                                                'F'
                                                                            }
                                                                            size="small"
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                            )}
                                                    </>
                                                )
                                            })}
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <TestStyledCard>
                                    <TestViewTable
                                        data={jobData}
                                        handleOpen={handleOpen}
                                        status={getDetails.status}
                                        handleClose={handleClose}
                                        // handleChangePage={handleChangePage}
                                        // handleChangeRowsPerPage={
                                        //     handleChangeRowsPerPage
                                        // }
                                        // handleChange={handleChange}
                                        // onAdd={onAdd}
                                        // page={page}
                                        // rowsPerPage={rowsPerPage}
                                    />
                                </TestStyledCard>
                            </Grid>

                            {(userRole === 'ADMIN' || userRole === 'SUPER_ADMIN') && (
                                <Grid item lg={12} md={6} sm={12} xs={12}>
                                    <Card>
                                        <CardHeader title="Billing Details" />
                                        <StyledDivider />
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={6}
                                                    sm={6}
                                                    xs={6}
                                                >
                                                    <Box>
                                                        <InputLabel>
                                                            {'Customer Name'}
                                                        </InputLabel>
                                                        <TextField
                                                            variant="standard"
                                                            disabled
                                                            multiline
                                                            value={
                                                                getDetails
                                                                    ?.billing
                                                                    ?.name
                                                            }
                                                            size="small"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={6}
                                                    sm={6}
                                                    xs={6}
                                                >
                                                    <Box>
                                                        <InputLabel>
                                                            {'Email'}
                                                        </InputLabel>
                                                        <TextField
                                                            variant="standard"
                                                            disabled
                                                            multiline
                                                            value={
                                                                getDetails
                                                                    ?.billing
                                                                    ?.email
                                                            }
                                                            size="small"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={6}
                                                    sm={6}
                                                    xs={6}
                                                >
                                                    <Box>
                                                        <InputLabel>
                                                            {'Address'}
                                                        </InputLabel>
                                                        <TextField
                                                            variant="standard"
                                                            disabled
                                                            multiline
                                                            value={
                                                                getDetails
                                                                    ?.billing
                                                                    ?.address
                                                            }
                                                            size="small"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={6}
                                                    sm={6}
                                                    xs={6}
                                                >
                                                    <Box>
                                                        <InputLabel>
                                                            {'Tel/Mobile'}
                                                        </InputLabel>
                                                        <TextField
                                                            variant="standard"
                                                            disabled
                                                            multiline
                                                            value={
                                                                getDetails
                                                                    ?.billing
                                                                    ?.phone_number
                                                            }
                                                            size="small"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={6}
                                                    sm={6}
                                                    xs={6}
                                                >
                                                    <Box>
                                                        <InputLabel>
                                                            {'GST No'}
                                                        </InputLabel>
                                                        <TextField
                                                            variant="standard"
                                                            disabled
                                                            multiline
                                                            value={
                                                                getDetails
                                                                    .billing
                                                                    ?.gst_no
                                                            }
                                                            size="small"
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}

                            {(userRole === 'ADMIN' || userRole === 'SUPER_ADMIN') && (
                                <Grid item lg={12} md={6} sm={12} xs={12}>
                                    <Card>
                                        <CardHeader title="Report Details" />
                                        <StyledDivider />
                                        <CardContent>
                                            <Grid container spacing={4}>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={6}
                                                    sm={6}
                                                    xs={6}
                                                >
                                                    <Box>
                                                        <InputLabel>
                                                            {'Report Due Date'}
                                                        </InputLabel>
                                                        <TextField
                                                            variant="standard"
                                                            disabled
                                                            multiline
                                                            value={due_date}
                                                            size="small"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={8}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <InputLabel>
                                                        {'Mode of Delivery'}
                                                    </InputLabel>
                                                    <RadioGroup row>
                                                        <FormControlLabel
                                                            value="BY_COURIER"
                                                            control={
                                                                <Radio color="success" />
                                                            }
                                                            label="By Courier"
                                                            labelPlacement="end"
                                                            checked={
                                                                getDetails?.mode_of_delivery ===
                                                                'BY_COURIER'
                                                            }
                                                            disabled={true}
                                                        />
                                                        <FormControlLabel
                                                            value="BY_QR_CODE"
                                                            control={
                                                                <Radio color="success" />
                                                            }
                                                            label="By QR code"
                                                            labelPlacement="end"
                                                            checked={
                                                                getDetails?.mode_of_delivery ===
                                                                'BY_QR_CODE'
                                                            }
                                                            disabled={true}
                                                        />
                                                        <FormControlLabel
                                                            value="BY_LAB_PERSONNEL"
                                                            control={
                                                                <Radio color="success" />
                                                            }
                                                            label="By Lab personnel"
                                                            labelPlacement="end"
                                                            checked={
                                                                getDetails?.mode_of_delivery ===
                                                                'BY_LAB_PERSONNEL'
                                                            }
                                                            disabled={true}
                                                        />
                                                    </RadioGroup>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Grid
                                            item
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            container
                                            justifyContent={'space-around'}
                                            spacing={2}
                                        >
                                            {getDetails?.prepared_by?.name && (
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={6}
                                                    sm={6}
                                                    xs={6}
                                                >
                                                    <Box>
                                                        <InputLabel>
                                                            Prepared By
                                                        </InputLabel>
                                                        <TextField
                                                            fullWidth
                                                            value={
                                                                getDetails
                                                                    ?.prepared_by
                                                                    ?.name
                                                            }
                                                            variant="standard"
                                                            disabled
                                                            multiline
                                                            size="small"
                                                        />
                                                    </Box>
                                                </Grid>
                                            )}
                                            {getDetails?.reviewer_by?.name && (
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={6}
                                                    sm={6}
                                                    xs={6}
                                                >
                                                    <Box>
                                                        <InputLabel>
                                                            Approved & Issued By
                                                        </InputLabel>
                                                        <TextField
                                                            fullWidth
                                                            value={
                                                                getDetails
                                                                    ?.reviewer_by
                                                                    ?.name
                                                            }
                                                            variant="standard"
                                                            disabled
                                                            multiline
                                                            size="small"
                                                        />
                                                    </Box>
                                                </Grid>
                                            )}
                                            {getDetails?.authorized_by
                                                ?.name && (
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={6}
                                                    sm={6}
                                                    xs={6}
                                                >
                                                    <Box>
                                                        <InputLabel>
                                                            Authorized By
                                                        </InputLabel>
                                                        <TextField
                                                            fullWidth
                                                            value={
                                                                getDetails
                                                                    ?.authorized_by
                                                                    ?.name
                                                            }
                                                            variant="standard"
                                                            disabled
                                                            multiline
                                                            size="small"
                                                        />
                                                    </Box>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid
                                container
                                spacing={2}
                                sx={{ mt: 1 }}
                                justifyContent="end"
                            >
                                <Grid item>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="inherit"
                                        onClick={() => {
                                            navigate('/test')
                                        }}
                                    >
                                        Back
                                    </Button>
                                </Grid>
                                {userRole !== 'CUSTOMER' &&
                                    getDetails?.status !== 'ARCHIVED' && (
                                        <Grid item>
                                            <Button
                                                type="submit"
                                                disabled={
                                                    (userRole === 'ADMIN' || userRole === 'SUPER_ADMIN') ||
                                                    userRole ===
                                                        'TECHNICAL_MANAGER'
                                                        ? false
                                                        : true
                                                }
                                                size="small"
                                                variant="contained"
                                                onClick={() => {
                                                    handleSave()
                                                }}
                                            >
                                                Update
                                            </Button>
                                        </Grid>
                                    )}

                                {userRole !== 'CUSTOMER' &&
                                    getDetails?.status === 'ARCHIVED' && (
                                        <Grid item>
                                            <Button
                                                type="submit"
                                                disabled={
                                                    (userRole === 'ADMIN' || userRole === 'SUPER_ADMIN') ||
                                                    userRole ===
                                                        'TECHNICAL_MANAGER'
                                                        ? false
                                                        : true
                                                }
                                                size="small"
                                                variant="outlined"
                                                onClick={() => {
                                                    handleOpenDialog()
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </Grid>
                                    )}
                                {userRole !== 'CUSTOMER' &&
                                    getDetails?.status === 'ARCHIVED' && (
                                        <Grid item>
                                            <Button
                                                type="submit"
                                                disabled={
                                                    (userRole === 'ADMIN' || userRole === 'SUPER_ADMIN') ||
                                                    userRole ===
                                                        'TECHNICAL_MANAGER'
                                                        ? false
                                                        : true
                                                }
                                                size="small"
                                                variant="contained"
                                                onClick={() => {
                                                    handleRestore()
                                                }}
                                            >
                                                Restore
                                            </Button>
                                        </Grid>
                                    )}
                            </Grid>
                        </Grid>
                    </ContentBox>
                </Fragment>
                <Dialog open={open} onClose={handleDialogClose}>
                    <DialogContent>
                        <CardHeader title="Edit" />
                        <CardContent>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6}>
                                    <Autocomplete
                                        value={AssigneeValue}
                                        options={assigneeList || []}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e, value) =>
                                            handleAssigneeChange(value)
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                label="Assignee To"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {/* <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <DatePicker
                                        value={date}
                                        onChange={(e, value) =>
                                            handleDateChange(e, value)
                                        }
                                        renderInput={(props) => (
                                            <TextField
                                                {...props}
                                                id="mui-pickers-date"
                                                label="Report Due Date"
                                                sx={{
                                                    mb: 2,
                                                    width: '100%',
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider> */}

                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <DatePicker
                                            inputFormat="dd/MM/yyyy"
                                            maxDate={min_date}
                                            label="Report Due Date"
                                            value={date ?? '-'}
                                            onChange={(e, value) =>
                                                handleDateChange(e, value)
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Autocomplete
                                        value={statusValue}
                                        options={status || []}
                                        getOptionLabel={(option) =>
                                            option.label
                                        }
                                        onChange={(e, value) =>
                                            handleStatusChange(value)
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                label="Status"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Button size="small" onClick={handleDialogClose}>
                                Cancel
                            </Button>
                            <Button
                                size="small"
                                variant="contained"
                                onClick={onAdd}
                            >
                                Update
                            </Button>
                        </CardActions>
                    </DialogContent>
                </Dialog>
                <SnackBar
                    open={snack.snackOpen}
                    severity={snack.snackVaraint}
                    message={snack.snackMsg}
                    onClose={() =>
                        setSnack({
                            ...snack,
                            snackOpen: false,
                        })
                    }
                />
                <DeleteDilaog
                    value={value}
                    open={openDialog}
                    handleClose={handleClose}
                    handleDelete={handleDelete}
                />
            </>
        )
    }
}

export default TestView
