import { AddCircle, Edit } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Modal,
    TextField,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
    StyledIcon,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import { useContext, useState } from 'react'
import * as Yup from 'yup'
import { valueCalc } from '../../method/method'
import SoilPlasticLimitTable from './SoilPlasticLimitTable'

const initialValues = {
    wet_weight: '',
    weight_of_oven: '',
    plastic_limit: '',
}

const validationSchema = Yup.object().shape({
    wet_weight: Yup.number().required('Required'),
    weight_of_oven: Yup.number().required('Required'),
})
const SoilPlasticLimitModal = (props) => {
    const { result, isView, overall, setOverall } = props

    const handleClose = () => setOpen(false)
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)

    const [initialState, setInitialState] = useState({ ...initialValues })
    const [data, setData] = useState(
        result.overall ? result?.overall : { ...initialValues }
    )
    const { finalData, setFinalData } = useContext(AppContext)
    const handleSubmit = (values, { resetForm }) => {
        let plasticLimitFormula =
            (100 * (values?.wet_weight - values?.weight_of_oven)) /
            values?.weight_of_oven
        //formula
        values.plastic_limit = plasticLimitFormula
            ? valueCalc(plasticLimitFormula) //condition operator
            : '0'
            //'To be Calculated'

        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName) {
                item.overall = {
                    ...values,
                    requirements: overall.requirements,
                    remarks: overall.remarks,
                    requirementStatus: overall.requirementStatus,
                    remarkStatus: overall?.remarkStatus,
                }
            }
        })
        setFinalData(_finalData)
        setData(values)
        setInitialState({ ...initialValues })
        setOpen(false)
    }
    const handleDeleteTable = (val) => {
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName) {
                item.overall = {
                    requirements: overall.requirements,
                    requirementStatus: overall.requirementStatus,
                    remarkStatus: overall?.remarkStatus,
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData({ ...initialValues })
    }

    let length = Object.keys(result.overall).length

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props
                    return (
                        <Form>
                            <Modal open={open} onClose={handleClose}>
                                <StyledCard>
                                    <CardHeader title={result?.groupName} />
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Wet Weight of soil crumble at 3mm dia, W1 (g)"
                                                    required
                                                    value={values.wet_weight}
                                                    name="wet_weight"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.wet_weight &&
                                                        touched.wet_weight
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.wet_weight &&
                                                    touched.wet_weight
                                                        ? errors.wet_weight
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Weight of oven dried soil crumble at 3mm dia, W2 (g)"
                                                    value={
                                                        values.weight_of_oven
                                                    }
                                                    name="weight_of_oven"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.weight_of_oven &&
                                                        touched.weight_of_oven
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.weight_of_oven &&
                                                    touched.weight_of_oven
                                                        ? errors.weight_of_oven
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        {length > 4 ? (
                                            <Button
                                                size="small"
                                                variant="contained"
                                                onClick={handleSubmit}
                                            >
                                                Edit
                                            </Button>
                                        ) : (
                                            <Button
                                                size="small"
                                                variant="contained"
                                                onClick={handleSubmit}
                                            >
                                                Save
                                            </Button>
                                        )}
                                    </CardActions>
                                </StyledCard>
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
            {data && (
                <SoilPlasticLimitTable
                    data={data}
                    setData={setData}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    result={result}
                />
            )}
            {isView !== true && (
                <Grid container justifyContent="end">
                    <IconButton
                        size="small"
                        onClick={(e) => handleDeleteTable(result?.overall)}
                        sx={{ boxShadow: 'none', mt: 2 }}
                    >
                        <StyledIcon>delete</StyledIcon>
                    </IconButton>
                    {length > 4 ? (
                        <Button
                            sx={{ boxShadow: 'none', mt: 2 }}
                            variant="contained"
                            endIcon={<Edit />}
                            onClick={handleOpen}
                            size="small"
                        >
                            Edit
                        </Button>
                    ) : (
                        <Button
                            sx={{ boxShadow: 'none', mt: 2 }}
                            variant="contained"
                            endIcon={<AddCircle />}
                            onClick={handleOpen}
                            size="small"
                        >
                            Add
                        </Button>
                    )}
                </Grid>
            )}
        </Grid>
    )
}

export default SoilPlasticLimitModal
