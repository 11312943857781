import { Button, Grid } from '@mui/material'
import { ContentBox } from 'app/assets/styled-components/SharedComponents'
// import ApprovalCard from 'app/views/test/shared/ApprovalCard'

import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import JOBCARD007A from '../shared/jobCards/007A/JOBCARD007A'
import JobDetails from './JobDetails'

const JobCard = () => {
    const navigate = useNavigate()

    return (
        <Fragment>
            <ContentBox>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <JobDetails />
                    </Grid>

                    <Grid item xs={12}>
                        <JOBCARD007A />
                    </Grid>

                    <Grid
                        container
                        spacing={1}
                        sx={{ mt: 1 }}
                        justifyContent="end"
                    >
                        <Grid item>
                            <Button
                                type="submit"
                                size="small"
                                variant="contained"
                                color="inherit"
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                type="submit"
                                size="small"
                                variant="contained"
                                onClick={() => {
                                    navigate('/job/view')
                                }}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </ContentBox>
        </Fragment>
    )
}

export default JobCard
