import { useContext, useState } from 'react'

import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Modal,
    TextField,
    Typography,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import SandReplacementMethodTable from './SandReplacementMethodTable'
import { valueCalc } from '../../method/method'

const initialValues = {
    //Calibration
    wt_sand_cylinder_before: '',
    mean_wt_sand_cone: '',
    mean_wt_sand_cylinder_after: '',
    wt_sand_fill: '',
    vol_calibrate: '',
    bulk_density_1: '',
    //soil Density
    wt_wet_soil: '',
    wt_sand_cylinder_after: '',
    wt_sand_hole: '',
    bulk_density_2: '',
    water_content: '',
    dry_density: '',
    perc_compaction: '',
    compaction: '',
    maximumDryDensity: '',
}

const validationSchema = Yup.object().shape({
    location: Yup.string().required('Required'),
    wt_sand_cylinder_before: Yup.number().required('Required'),
    mean_wt_sand_cone: Yup.number().required('Required'),
    mean_wt_sand_cylinder_after: Yup.number().required('Required'),
    bulk_density_1: Yup.number().required('Required'),
    wt_wet_soil: Yup.number().required('Required'),
    water_content: Yup.number().required('Required'),
    vol_calibrate: Yup.number().required('Required'),
    wt_sand_cylinder_after: Yup.number().required('Required'),
    maximumDryDensity: Yup.number().required('Required'),
})
const SandReplacementMethodModal = ({
    result,
    isView,
    setOverall,
    overall,
}) => {
    const handleClose = () => setOpen(false)
    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)
    const [data, setData] = useState(result.data ? result.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const { finalData, setFinalData } = useContext(AppContext)
    const handleSubmit = (values, { resetForm }) => {
        let dry_density_arr = []
        let Compaction_arr = []
        let avg = 0
        let arr = [...data]
        arr.push(values)
        arr = applyFormula(arr)
        console.log(arr, 'arrrrrrrrrrrrr')
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && arr.length !== 0) {
                dry_density_arr = arr.map(
                    (item1) => item1.dry_density && valueCalc(item1.dry_density)
                )
                Compaction_arr = arr.map(
                    (item1) => item1.compaction && valueCalc(item1.compaction)
                )
                Compaction_arr.forEach((value) => {
                    avg += parseFloat(value) / arr.length // Assuming values are strings representing numbers
                })
            }
            if (item.groupName === result.groupName) {
                item.data = arr
                item.overall = {
                    ...overall,
                    average: avg,
                    maximunDryDensity: Math.max(...dry_density_arr),
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
    }

    const applyFormula = (arr) => {
        arr.forEach((item, index) => {
            //Calibration
            item.wt_sand_fill =
                item.wt_sand_cylinder_before -
                item.mean_wt_sand_cylinder_after -
                item.mean_wt_sand_cone
            //Soil Density
            item.wt_sand_hole =
                item.wt_sand_cylinder_before -
                item.wt_sand_cylinder_after -
                item.mean_wt_sand_cone
            // item.bulk_density_2 =
            //     item.wt_wet_soil / (item.wt_sand_hole * item.bulk_density_1)
            item.bulk_density_2 =
                ((item.wt_wet_soil / item.wt_sand_hole) * item.bulk_density_1) /
                1000
            // item.dry_density = 100 + Number.parseFloat(item.water_content)
            item.dry_density =
                (100 * item.bulk_density_2) /
                (100 + Number.parseFloat(item.water_content))
            item.compaction = (item.dry_density / item.maximumDryDensity) * 100
        })
        return arr
    }

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props
                    return (
                        <Form>
                            <Modal open={open} onClose={handleClose}>
                                <StyledCard>
                                    <CardHeader title={result?.groupName} />
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={8}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label="Location"
                                                        value={values.location}
                                                        name="location"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={
                                                            errors.location &&
                                                            touched.location
                                                        }
                                                    />
                                                    <HelperText>
                                                        {errors.location &&
                                                        touched.location
                                                            ? errors.location
                                                            : ''}
                                                    </HelperText>
                                                </Grid>

                                                <Typography>
                                                    Calibration
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Wt of Sand + Cylinder before Poring (W1) kg"
                                                    value={
                                                        values.wt_sand_cylinder_before
                                                    }
                                                    name="wt_sand_cylinder_before"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.wt_sand_cylinder_before &&
                                                        touched.wt_sand_cylinder_before
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.wt_sand_cylinder_before &&
                                                    touched.wt_sand_cylinder_before
                                                        ? errors.wt_sand_cylinder_before
                                                        : ''}
                                                </HelperText>
                                            </Grid>{' '}
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Mean wt of Sand in Cone W2, kg"
                                                    value={
                                                        values.mean_wt_sand_cone
                                                    }
                                                    name="mean_wt_sand_cone"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.mean_wt_sand_cone &&
                                                        touched.mean_wt_sand_cone
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.mean_wt_sand_cone &&
                                                    touched.mean_wt_sand_cone
                                                        ? errors.mean_wt_sand_cone
                                                        : ''}
                                                </HelperText>
                                            </Grid>{' '}
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Mean wt of sand + Cylinder after Pouring W3, kg"
                                                    value={
                                                        values.mean_wt_sand_cylinder_after
                                                    }
                                                    name="mean_wt_sand_cylinder_after"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.mean_wt_sand_cylinder_after &&
                                                        touched.mean_wt_sand_cylinder_after
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.mean_wt_sand_cylinder_after &&
                                                    touched.mean_wt_sand_cylinder_after
                                                        ? errors.mean_wt_sand_cylinder_after
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Vol of Calibrating Container (V), in ml"
                                                    value={values.vol_calibrate}
                                                    name="vol_calibrate"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.vol_calibrate &&
                                                        touched.vol_calibrate
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.vol_calibrate &&
                                                    touched.vol_calibrate
                                                        ? errors.vol_calibrate
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Bulk Density of Sand  γa"
                                                    value={
                                                        values.bulk_density_1
                                                    }
                                                    name="bulk_density_1"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.bulk_density_1 &&
                                                        touched.bulk_density_1
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.bulk_density_1 &&
                                                    touched.bulk_density_1
                                                        ? errors.bulk_density_1
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    Measurement of Soil Density
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Wt of wet soil from hole (Ww) in kg"
                                                    value={values.wt_wet_soil}
                                                    name="wt_wet_soil"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.wt_wet_soil &&
                                                        touched.wt_wet_soil
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.wt_wet_soil &&
                                                    touched.wt_wet_soil
                                                        ? errors.wt_wet_soil
                                                        : ''}
                                                </HelperText>
                                            </Grid>{' '}
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Wt of Sand + Cylinder After pouring (W4) in kg"
                                                    value={
                                                        values.wt_sand_cylinder_after
                                                    }
                                                    name="wt_sand_cylinder_after"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.wt_sand_cylinder_after &&
                                                        touched.wt_sand_cylinder_after
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.wt_sand_cylinder_after &&
                                                    touched.wt_sand_cylinder_after
                                                        ? errors.wt_sand_cylinder_after
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Water Content (w) percent"
                                                    value={values.water_content}
                                                    name="water_content"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.water_content &&
                                                        touched.water_content
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.water_content &&
                                                    touched.water_content
                                                        ? errors.water_content
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Maximum Dry Density, MDD (g/cc)"
                                                    value={
                                                        values.maximumDryDensity
                                                    }
                                                    name="maximumDryDensity"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.maximumDryDensity &&
                                                        touched.maximumDryDensity
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.maximumDryDensity &&
                                                    touched.maximumDryDensity
                                                        ? errors.maximumDryDensity
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </StyledCard>
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
            {data && (
                <SandReplacementMethodTable
                    data={data}
                    setData={setData}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    result={result}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {isView !== true && (
                <Grid container justifyContent="end">
                    <Button
                        sx={{ boxShadow: 'none', mt: 2 }}
                        variant="contained"
                        endIcon={<AddCircle />}
                        onClick={handleOpen}
                        size="small"
                    >
                        Add
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default SandReplacementMethodModal
