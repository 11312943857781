import {
    Autocomplete,
    Button,
    Card,
    CardHeader,
    FormControl,
    Grid,
    Icon,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { Box, styled, useTheme } from '@mui/system'
import { StyledDivider } from 'app/views/jobs/StyledComponents/StyledComponents'
import { configLicense } from 'Conflit/Features'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { format } from 'date-fns'
import InputLabel from '@mui/material/InputLabel'
import { useNavigate } from 'react-router-dom'
import { SiMicrosoftexcel } from 'react-icons/si'
import {
    getGroupListById,
    getSampleListById,
} from 'app/redux/actions/TestActions'
import { useDispatch } from 'react-redux'

const CardRoot = styled(Card)(() => ({
    height: '100%',
    width: '100%',
    padding: '20px 24px',
    overflowX: 'auto',
}))

const CardTitle = styled(CardHeader)(({ subtitle }) => ({
    textTransform: 'capitalize',
    marginBottom: !subtitle && '2px',
}))
const status1 = [
    { label: 'TODO', value: 'TODO' },
    { label: 'PROGRESS', value: 'PROGRESS' },
    { label: 'COMPLETE', value: 'COMPLETE' },
    { label: 'ARCHIVED', value: 'ARCHIVED' },
]
const SimpleCard = ({
    children,
    title,
    handleSearch,
    label,
    suggestions,
    isfilterOpen,
    handleProjects,
    handleFilter = () => {},
    handleAssigneeChange,
    handleStatusChange,
    handleDisciplineChange,
    handleGroupChange,
    handleSampleChange,
    handleDayChange,
    handleDueDayChange,
    handleCustomDateConfirm,
    handleCustomDueDateConfirm,
    handleUserStatusChange,
    filterLabel,
    filterLabel2,
    filterLabel3,
    filterLabel4,
    filterLabel5,
    filterLabel6,
    filterLabel7,
    data,
    _data,
    data_,
    handlePdf,
    handleAllPdf,
    CustomerSearch,
    toggle_,
    toggles,
    open_,
    opens,
    handleReset = () => {},
    handleExcel,
    handleAllExcel,
    isTotalDocsEnable,
    isStatusEnable,
    total,
    countstatus,
    materialId,
}) => {
    let search = window.location.search
    let param = new URLSearchParams(search)
    let statusFilter1 = param.get('statusFilter')
    let dateFilter1 = param.get('dateFilter')
    let due_dateFilter1 = param.get('due_dateFilter')
    const theme = useTheme()
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    // const [filterOpen, setFilterOpen] = useState(false);
    const [filterOpen, setFilterOpen] = useState(!!statusFilter1)
    const { assigneeList } = useSelector((state) => state.testReducer)
    const [selectedDiscipline, setSelectedDiscipline] = useState('')
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [selectedSamples, setSelectedSamples] = useState(null)
    const { allDiscipline, customerList, samplesList, allGroups, totalPage } =
        useSelector((state) => state.testReducer)
    const [customStartDate, setCustomStartDate] = useState(null)
    const [customEndDate, setCustomEndDate] = useState(null)
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [customDueStartDate, setCustomDueStartDate] = useState(null)
    const [customDueEndDate, setCustomDueEndDate] = useState(null)
    const [showDueDatePicker, setShowDueDatePicker] = useState(false)
    const [showStatus, setShowStatus] = useState(false)
    const userRole = Cookies.get('userRole')

    const toggle = () => {
        setOpen(!open)
        handleSearch({ target: { value: '' } })
    }
    const customertoggle = () => {
        setOpen(!open)
        CustomerSearch({ target: { value: '' } })
    }

    const _toggle = () => {
        setFilterOpen(!filterOpen)
    }

    const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const handleCustomStartDateChange = (date) => {
        setCustomStartDate(new Date(date))
    }
    const handleCustomEndDateChange = (date) => {
        setCustomEndDate(new Date(date))
    }
    const handleCustomDueStartDateChange = (date) => {
        setCustomDueStartDate(new Date(date))
    }
    const handleCustomDueEndDateChange = (date) => {
        setCustomDueEndDate(new Date(date))
    }
    const dispatch = useDispatch()
    useEffect(() => {
        if (selectedDiscipline) {
            dispatch(getGroupListById(selectedDiscipline?._id))
        }
        if (selectedGroup) {
            dispatch(getSampleListById(selectedGroup?._id))
        }
    }, [selectedGroup, selectedDiscipline])

    return (
        <CardRoot elevation={6}>
            <CardTitle
                title={title}
                action={
                    !isMobileScreen && (
                        <Grid
                            container
                            spacing={2}
                            sx={{ justifyContent: 'end' }}
                        >
                            {configLicense.pagetoPdfDownload === true &&
                                (data || _data || data_) && (
                                    <Grid item>
                                        <Box display="flex" flexDirection="row">
                                            {!opens && (
                                                <IconButton onClick={toggles}>
                                                    <SiMicrosoftexcel
                                                        size={23}
                                                    />
                                                </IconButton>
                                            )}
                                            {opens && (
                                                <IconButton
                                                    onClick={() =>
                                                        handleExcel()
                                                    }
                                                >
                                                    <Typography>
                                                        {data
                                                            ? 'Download Job Data'
                                                            : _data
                                                              ? 'Download Test Data'
                                                              : 'Download Recent Test Data'}
                                                    </Typography>
                                                </IconButton>
                                            )}
                                            {opens && (
                                                <IconButton
                                                    onClick={() => {
                                                        toggles()
                                                        handleAllExcel()
                                                    }}
                                                >
                                                    <Typography>
                                                        {data
                                                            ? 'Download All Job Data'
                                                            : _data
                                                              ? 'Download  All Test Data'
                                                              : 'Download All Recent Test Data'}
                                                    </Typography>
                                                </IconButton>
                                            )}
                                            {opens && (
                                                <IconButton
                                                    onClick={toggles}
                                                    sx={{
                                                        verticalAlign: 'middle',
                                                    }}
                                                >
                                                    <Icon>close</Icon>
                                                </IconButton>
                                            )}
                                        </Box>
                                    </Grid>
                                )}
                            {configLicense.pagetoPdfDownload === true &&
                                (data || _data || data_) && (
                                    <Grid item>
                                        <Box display="flex" flexDirection="row">
                                            {!open_ && (
                                                <IconButton onClick={toggle_}>
                                                    <Icon>picture_as_pdf</Icon>
                                                </IconButton>
                                            )}
                                            {open_ && (
                                                <IconButton
                                                    onClick={() => handlePdf()}
                                                >
                                                    <Typography>
                                                        {data
                                                            ? 'Download Job Data'
                                                            : _data
                                                              ? 'Download Test Data'
                                                              : 'Download Recent Test Data'}
                                                    </Typography>
                                                </IconButton>
                                            )}
                                            {open_ && (
                                                <IconButton
                                                    onClick={() => {
                                                        toggle_()
                                                        handleAllPdf()
                                                    }}
                                                >
                                                    <Typography>
                                                        {data
                                                            ? 'Download All Job Data'
                                                            : _data
                                                              ? 'Download  All Test Data'
                                                              : 'Download All Recent Test Data'}
                                                    </Typography>
                                                </IconButton>
                                            )}
                                            {open_ && (
                                                <IconButton
                                                    onClick={toggle_}
                                                    sx={{
                                                        verticalAlign: 'middle',
                                                    }}
                                                >
                                                    <Icon>close</Icon>
                                                </IconButton>
                                            )}
                                        </Box>
                                    </Grid>
                                )}
                            {configLicense.Filtering === true &&
                                suggestions && (
                                    <Grid item>
                                        <Box display="flex" flexDirection="row">
                                            {!filterOpen && (
                                                <IconButton onClick={_toggle}>
                                                    <Icon>filter_list</Icon>
                                                </IconButton>
                                            )}
                                        </Box>
                                    </Grid>
                                )}
                            {configLicense.Search === true && handleSearch && (
                                <Grid item>
                                    {!open && (
                                        <IconButton onClick={toggle}>
                                            <Icon>search</Icon>
                                        </IconButton>
                                    )}
                                    {open && (
                                        <TextField
                                            size="small"
                                            label={`Search By ${label}`}
                                            fullWidth
                                            onChange={handleSearch}
                                            placeholder="Search here..."
                                            autoComplete="off"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton
                                                        onClick={toggle}
                                                        sx={{
                                                            ml: 2,
                                                            verticalAlign:
                                                                'middle',
                                                            paddingRight: 0,
                                                        }}
                                                    >
                                                        <Icon>close</Icon>
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                    )}
                                </Grid>
                            )}
                            {CustomerSearch && (
                                <Grid item>
                                    {!open && (
                                        <IconButton onClick={customertoggle}>
                                            <Icon>search</Icon>
                                        </IconButton>
                                    )}
                                    {open && (
                                        <TextField
                                            size="small"
                                            onChange={CustomerSearch}
                                            placeholder="Search here..."
                                            autoComplete="off"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton
                                                        onClick={customertoggle}
                                                        sx={{
                                                            ml: 2,
                                                            verticalAlign:
                                                                'middle',
                                                            paddingRight: 0,
                                                        }}
                                                    >
                                                        <Icon>close</Icon>
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                    )}
                                </Grid>
                            )}
                            {configLicense.dueDateFilter === true &&
                                handleDueDayChange && (
                                    <Grid item>
                                        <FormControl
                                            sx={{ m: 1, minWidth: 140 }}
                                        >
                                            <InputLabel
                                                id="dueDateFilterLabel"
                                                sx={{ marginTop: '1px' }}
                                            >
                                                Filter Due Date
                                            </InputLabel>
                                            <Select
                                                size="small"
                                                defaultValue={'THISMONTH'}
                                                label="Due date filter"
                                                onChange={(e) => {
                                                    const selectedDate =
                                                        e.target.value
                                                    if (
                                                        selectedDate ===
                                                        'CUSTOM'
                                                    ) {
                                                        setShowDueDatePicker(
                                                            true
                                                        )
                                                    } else {
                                                        setShowDueDatePicker(
                                                            false
                                                        )
                                                    }
                                                    handleDueDayChange(e)
                                                }}
                                                sx={{ width: '100%' }}
                                            >
                                                <MenuItem value="ALL">
                                                    All
                                                </MenuItem>
                                                <MenuItem value="THISMONTH">
                                                    This Month
                                                </MenuItem>
                                                <MenuItem value="LASTMONTH">
                                                    Last Month
                                                </MenuItem>
                                                <MenuItem value="THISWEEK">
                                                    This Week
                                                </MenuItem>
                                                <MenuItem value="YESTERDAY">
                                                    Yesterday
                                                </MenuItem>
                                                <MenuItem value="TODAY">
                                                    Today
                                                </MenuItem>
                                                <MenuItem value="THISYEAR">
                                                    This Year
                                                </MenuItem>
                                                <MenuItem value="CUSTOM">
                                                    Custom Date{' '}
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}
                            {configLicense.dateFilter === true &&
                                handleDayChange && (
                                    <Grid item>
                                        <FormControl
                                            sx={{ m: 1, minWidth: 140 }}
                                        >
                                            <InputLabel
                                                id="startDateFilterLabel"
                                                sx={{ marginTop: '1px' }}
                                            >
                                                Filter Create Date
                                            </InputLabel>
                                            <Select
                                                size="small"
                                                defaultValue={'THISMONTH'}
                                                label="Start date filter"
                                                value={
                                                    dateFilter1 || 'THISMONTH'
                                                }
                                                onChange={(e) => {
                                                    const selectedMonth =
                                                        e.target.value
                                                    if (
                                                        selectedMonth ===
                                                        'CUSTOM'
                                                    ) {
                                                        setShowDatePicker(true)
                                                    } else {
                                                        setShowDatePicker(false)
                                                    }
                                                    handleDayChange(e)
                                                }}
                                                sx={{ width: '100%' }}
                                            >
                                                {/* <MenuItem value="ALL">All</MenuItem> */}
                                                <MenuItem value="THISMONTH">
                                                    This Month
                                                </MenuItem>
                                                <MenuItem value="LASTMONTH">
                                                    Last Month
                                                </MenuItem>
                                                <MenuItem value="THISWEEK">
                                                    This Week
                                                </MenuItem>
                                                <MenuItem value="YESTERDAY">
                                                    Yesterday
                                                </MenuItem>
                                                <MenuItem value="TODAY">
                                                    Today
                                                </MenuItem>
                                                <MenuItem value="THISYEAR">
                                                    This Year
                                                </MenuItem>
                                                <MenuItem value="CUSTOM">
                                                    Custom Date{' '}
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}

                            {configLicense.dueDateFilter === true &&
                                handleUserStatusChange && (
                                    <Grid item>
                                        <FormControl
                                            sx={{ m: 1, minWidth: 140 }}
                                        >
                                            <InputLabel
                                                id="statusLabel"
                                                sx={{ marginTop: '1px' }}
                                            >
                                                Status
                                            </InputLabel>
                                            <Select
                                                size="small"
                                                defaultValue={'ACTIVE'}
                                                label="filter"
                                                onChange={(e) => {
                                                    const selectedStatus =
                                                        e.target.value
                                                    handleUserStatusChange(
                                                        selectedStatus
                                                    )
                                                }}
                                                sx={{ width: '100%' }}
                                            >
                                                <MenuItem value="ALL">
                                                    All
                                                </MenuItem>
                                                <MenuItem value="ACTIVE">
                                                    ACTIVE
                                                </MenuItem>
                                                <MenuItem value="ARCHIVED">
                                                    ARCHIVED
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}
                        </Grid>
                    )
                }
            />
            <Grid
                xs={10}
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginLeft: '200px',
                    paddingBottom: '16px',
                }}
            >
                {showDueDatePicker && (
                    //  <Box p={2}>
                    // <Box display="flex"  flexDirection="row" alignItems="center">
                    <Grid item xs={5} sx={{ justifyContent: 'flex-end' }}>
                        <Grid
                            container
                            spacing={0.5}
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Grid item>
                                <TextField
                                    label="Due Start Date"
                                    type="date"
                                    size="small"
                                    value={
                                        customDueStartDate
                                            ? format(
                                                  customDueStartDate,
                                                  'yyyy-MM-dd'
                                              )
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleCustomDueStartDateChange(
                                            new Date(e.target.value)
                                        )
                                    }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{ width: '130px' }}
                                />
                            </Grid>

                            <Grid item>
                                <TextField
                                    label="Due End Date"
                                    type="date"
                                    size="small"
                                    value={
                                        customDueEndDate
                                            ? format(
                                                  customDueEndDate,
                                                  'yyyy-MM-dd'
                                              )
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleCustomDueEndDateChange(
                                            new Date(e.target.value)
                                        )
                                    }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{ width: '130px' }}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    onClick={(e) => {
                                        setShowDueDatePicker(true)
                                        handleCustomDueDateConfirm(
                                            customDueStartDate,
                                            customDueEndDate
                                        )
                                    }}
                                >
                                    Filter
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    //  </Box>
                    //  </Box>
                )}

                {showDatePicker && (
                    //<Box p={2}>
                    // <Box display="flex"  flexDirection="row" alignItems="center">
                    <Grid
                        item
                        xs={5}
                        sx={{ justifyContent: 'flex-end' }}
                        // sx={{
                        //     mt: showDueDatePicker ? -5 : 1,
                        //     ml: 100,
                        // }}
                    >
                        <Grid
                            container
                            spacing={0.5}
                            justifyContent="flex-end"
                            flexDirection="row"
                            alignItems="center"
                        >
                            <Grid item>
                                <TextField
                                    label="Start Date"
                                    type="date"
                                    size="small"
                                    value={
                                        customStartDate
                                            ? format(
                                                  customStartDate,
                                                  'yyyy-MM-dd'
                                              )
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleCustomStartDateChange(
                                            new Date(e.target.value)
                                        )
                                    }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{ width: '130px' }}
                                />
                            </Grid>
                            <Grid item>
                                {/* <Typography variant="body1" component="div"  >

                             </Typography> */}
                            </Grid>
                            <Grid item>
                                <TextField
                                    label="End Date"
                                    type="date"
                                    size="small"
                                    value={
                                        customEndDate
                                            ? format(
                                                  customEndDate,
                                                  'yyyy-MM-dd'
                                              )
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleCustomEndDateChange(
                                            new Date(e.target.value)
                                        )
                                    }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{ width: '130px' }}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    onClick={(e) => {
                                        setShowDatePicker(true)
                                        handleCustomDateConfirm(
                                            customStartDate,
                                            customEndDate
                                        )
                                    }}
                                >
                                    Filter
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    //  </Box>
                    //  </Box>
                )}
            </Grid>
            <StyledDivider />

            {filterOpen && (
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Grid container spacing={2} justifyContent="flex-end">
                        {configLicense.projects === true && handleProjects && (
                            <Grid item xs={12} sm={1.5}>
                                <TextField
                                    label={`Filter ${filterLabel2}`} //project
                                    size="small"
                                    sx={{ width: '100%' }}
                                    onChange={handleProjects}
                                />
                            </Grid>
                        )}
                        {configLicense.assignees === true &&
                            handleAssigneeChange && (
                                <Grid item xs={12} sm={1.5}>
                                    <Autocomplete
                                        size="small"
                                        //value={AssigneeValue}
                                        options={
                                            assigneeList ? assigneeList : []
                                        }
                                        onChange={(e, value) =>
                                            handleAssigneeChange(value)
                                        }
                                        //onChange={(e, value) => handleFilter(value)}
                                        //options={suggestions ? suggestions : []}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={`Filter ${filterLabel3}`}
                                                size="small"
                                            /> //assignees
                                        )}
                                        sx={{ width: '100%' }}
                                    />
                                </Grid>
                            )}
                        <Grid item xs={12} sm={1.5}>
                            <Autocomplete
                                size="small"
                                onChange={(e, value) => handleFilter(value)}
                                options={suggestions ? suggestions : []}
                                getOptionLabel={(option) => option.name}
                                // value={
                                //     materialId && suggestions
                                //         ? suggestions.find(
                                //               (el) => el._id === materialId
                                //           ) ?? ''
                                //         : ''
                                // }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={`Filter ${filterLabel}`}
                                        size="small"
                                    /> //reviewer
                                )}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        {configLicense.status === true &&
                            handleStatusChange && (
                                <Grid item xs={12} sm={1.5}>
                                    <Autocomplete
                                        value={{ label: statusFilter1 }}
                                        size="small"
                                        options={status1 || []}
                                        getOptionLabel={(option) =>
                                            option.label || ''
                                        }
                                        onChange={(e, value) =>
                                            handleStatusChange(value)
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                //fullWidth
                                                label={`Filter ${filterLabel7}`}
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                        {/* {configLicense.discipline === true &&
                            handleDisciplineChange && (
                                <Grid item xs={12} sm={1.5}>
                                    <Autocomplete
                                        size="small"
                                        onChange={(e, value) => {
                                            handleDisciplineChange(value)
                                            setSelectedDiscipline(value)
                                            setSelectedGroup('')
                                        }}
                                        //options={suggestions ? suggestions : []}
                                        //value={selectedDiscipline}
                                        options={allDiscipline ?? []}
                                        getOptionLabel={(option) => option.name}
                                        //onChange={handleDisciplineChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={`Filter ${filterLabel4}`}
                                                size="small"
                                            /> //discipline
                                        )}
                                        sx={{ width: '100%' }}
                                    />
                                </Grid>
                            )}

                        {selectedDiscipline && (
                            <Grid item xs={12} sm={1.5}>
                                <Autocomplete
                                    size="small"
                                    onChange={(e, value) => {
                                        handleGroupChange(value)
                                        // setSelectedDiscipline(value)
                                        setSelectedGroup(value)
                                        setSelectedSamples('')
                                    }}
                                    //onChange={handleGroupChange}
                                    //options={suggestions ? suggestions : []}
                                    //value={selectedGroup}
                                    options={allGroups ? allGroups : []}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`Filter ${filterLabel5}`}
                                            size="small"
                                        /> //group
                                    )}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                        )} */}
                        {
                            // selectedGroup &&
                            filterLabel === 'Review By' && (
                                <Grid item xs={12} sm={1.5}>
                                    <Autocomplete
                                        size="small"
                                        onChange={handleSampleChange}
                                        //options={suggestions ? suggestions : []}
                                        //value={selectedSamples}
                                        //onChange={(e, value) => {
                                        //handleSampleChange(value)
                                        //}}
                                        options={samplesList}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={`Filter ${filterLabel6}`}
                                                size="small"
                                            /> //sample information
                                        )}
                                        sx={{ width: '100%' }}
                                    />
                                </Grid>
                            )
                        }
                        <Grid item xs={12} sm={0.5}>
                            {filterOpen && (
                                <IconButton
                                    sx={{ textAlign: 'right' }}
                                    //onClick={_toggle}
                                    //sx={{ verticalAlign: 'end' }}
                                    onClick={() => {
                                        _toggle()
                                        handleReset()
                                    }}
                                >
                                    <Icon>close</Icon>
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {isMobileScreen && (
                <Grid
                    container
                    spacing={2}
                    sx={{ mb: 4, mt: 1, justifyContent: 'end' }}
                >
                    {configLicense.pagetoPdfDownload === true &&
                        (data || _data) && (
                            <Grid item>
                                <Box display="flex" flexDirection="row">
                                    {!open_ && (
                                        <IconButton onClick={toggle_}>
                                            <Icon>download</Icon>
                                        </IconButton>
                                    )}
                                    {open_ && (
                                        <IconButton onClick={() => handlePdf()}>
                                            <Typography>
                                                {data
                                                    ? 'Download Job Data'
                                                    : 'Download Test Data'}
                                            </Typography>
                                        </IconButton>
                                    )}
                                    {open_ && (
                                        <IconButton
                                            onClick={() => handleAllPdf()}
                                        >
                                            <Typography>
                                                {data
                                                    ? 'Download All Job Data'
                                                    : 'Download All Test Data'}
                                            </Typography>
                                        </IconButton>
                                    )}
                                    {open_ && (
                                        <IconButton
                                            onClick={toggle_}
                                            sx={{ verticalAlign: 'middle' }}
                                        >
                                            <Icon>close</Icon>
                                        </IconButton>
                                    )}
                                </Box>
                            </Grid>
                        )}

                    {handleSearch && (
                        <Grid item>
                            {!open && (
                                <IconButton onClick={toggle}>
                                    <Icon>search</Icon>
                                </IconButton>
                            )}
                            {open && (
                                <TextField
                                    size="small"
                                    onChange={handleSearch}
                                    placeholder="Search here..."
                                    autoComplete="off"
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                onClick={toggle}
                                                sx={{
                                                    ml: 2,
                                                    verticalAlign: 'middle',
                                                    paddingRight: 0,
                                                }}
                                            >
                                                <Icon>close</Icon>
                                            </IconButton>
                                        ),
                                    }}
                                />
                            )}
                        </Grid>
                    )}
                </Grid>
            )}

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    gap: '10px',
                    margin: '18px',
                }}
            >
                {isStatusEnable && (
                    <>
                        <Grid align="right" sx={{ fontWeight: '500' }}>
                            ToDo :{' '}
                            <span
                                style={{ fontSize: '15px', color: '#3535a6' }}
                            >
                                {countstatus?.countByStatus?.TODO}
                            </span>
                        </Grid>
                        <span
                            style={{ backgroundColor: 'black', width: '2px' }}
                        ></span>
                        <Grid align="right" sx={{ fontWeight: '500' }}>
                            In Progress :{' '}
                            <span
                                style={{ fontSize: '15px', color: '#3535a6' }}
                            >
                                {countstatus?.countByStatus?.PROGRESS}
                            </span>
                        </Grid>
                        <span
                            style={{ backgroundColor: 'black', width: '2px' }}
                        ></span>
                        <Grid align="right" sx={{ fontWeight: '500' }}>
                            Completed :{' '}
                            <span
                                style={{ fontSize: '15px', color: '#3535a6' }}
                            >
                                {countstatus?.countByStatus?.COMPLETE}
                            </span>
                        </Grid>
                        <span
                            style={{ backgroundColor: 'black', width: '2px' }}
                        ></span>
                    </>
                )}
                <>
                    {isTotalDocsEnable && (
                        <Grid align="right" sx={{ fontWeight: '500' }}>
                            Total Docs :{' '}
                            <span
                                style={{ fontSize: '15px', color: '#3535a6' }}
                            >
                                {total}
                            </span>
                        </Grid>
                    )}
                </>
            </div>
            {children && <Box sx={{ ml: 2, mt: 5 }}>{children}</Box>}
        </CardRoot>
    )
}
export default SimpleCard
