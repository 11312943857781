import { useContext, useState } from 'react'

import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Modal,
    TextField,
    Typography,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import UltrasonicPulseVelocityTable from './UltrasonicPulseVelocityTable'
import { valueCalc } from '../../method/method'

const initialValues = {
    direction: '',
    _1: '',
    _2: '',
    _3: '',
    _4: '',
    _5: '',
    _6: '',
    _7: '',
    _8: '',
    _9: '',
    _10: '',
    average: '',
}

const validationSchema = Yup.object().shape({
    direction: Yup.string().required('Required'),
    _1: Yup.number().required('Required'),
    _2: Yup.number().required('Required'),
    _3: Yup.number().required('Required'),
    _4: Yup.number().required('Required'),
    _5: Yup.number().required('Required'),
    _6: Yup.number().required('Required'),
    _7: Yup.number().required('Required'),
    _8: Yup.number().required('Required'),
    _9: Yup.number().required('Required'),
    _10: Yup.number().required('Required'),
})
const UltrasonicPulseVelocityModal = ({
    result,
    isView,
    overall,
    setOverall,
}) => {
    const handleClose = () => setOpen(false)

    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)
    const [data, setData] = useState(result.data ? result.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const { finalData, setFinalData } = useContext(AppContext)
    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        arr = applyFormula(arr)
        let avg = 0
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item?.groupName === result?.groupName && arr.length !== 0) {
                arr?.forEach((item1) => {
                    let average = item1.average
                    avg += Number(average / arr.length)
                })
            }
            if (item.groupName === result.groupName) {
                item.data = arr

                item.overall = {
                    ...overall,
                    average: valueCalc(avg),
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
    }

    const applyFormula = (arr) => {
        arr.forEach((item, index) => {
            let average =
                (Number.parseFloat(item._1) +
                    Number.parseFloat(item._2) +
                    Number.parseFloat(item._3) +
                    Number.parseFloat(item._4) +
                    Number.parseFloat(item._5) +
                    Number.parseFloat(item._6) +
                    Number.parseFloat(item._7) +
                    Number.parseFloat(item._8) +
                    Number.parseFloat(item._9) +
                    Number.parseFloat(item._10)) /
                10
            // item.average = average
            item.average =
                !isNaN(average) && average.toString().indexOf('.') != -1
                    ? Number(average).toFixed(3)
                    : average
        })
        return arr
    }

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props
                    return (
                        <Form>
                            <Modal open={open} onClose={handleClose}>
                                <StyledCard>
                                    <CardHeader title={result?.groupName} />
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    Direction
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Direction"
                                                    value={values.direction}
                                                    name="direction"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.direction &&
                                                        touched.direction
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.direction &&
                                                    touched.direction
                                                        ? errors.direction
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography>Values</Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="1"
                                                    value={values._1}
                                                    name="_1"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors._1 && touched._1
                                                    }
                                                />
                                                <HelperText>
                                                    {errors._1 && touched._1
                                                        ? errors._1
                                                        : ''}
                                                </HelperText>
                                            </Grid>

                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="2"
                                                    value={values._2}
                                                    name="_2"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors._2 && touched._2
                                                    }
                                                />
                                                <HelperText>
                                                    {errors._2 && touched._2
                                                        ? errors._2
                                                        : ''}
                                                </HelperText>
                                            </Grid>

                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="3"
                                                    value={values._3}
                                                    name="_3"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors._3 && touched._3
                                                    }
                                                />
                                                <HelperText>
                                                    {errors._3 && touched._3
                                                        ? errors._3
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="4"
                                                    value={values._4}
                                                    name="_4"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors._4 && touched._4
                                                    }
                                                />
                                                <HelperText>
                                                    {errors._4 && touched._4
                                                        ? errors._4
                                                        : ''}
                                                </HelperText>
                                            </Grid>

                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="5"
                                                    value={values._5}
                                                    name="_5"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors._5 && touched._5
                                                    }
                                                />
                                                <HelperText>
                                                    {errors._5 && touched._5
                                                        ? errors._5
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="6"
                                                    value={values._6}
                                                    name="_6"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors._6 && touched._6
                                                    }
                                                />
                                                <HelperText>
                                                    {errors._6 && touched._6
                                                        ? errors._6
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="7"
                                                    value={values._7}
                                                    name="_7"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors._7 && touched._7
                                                    }
                                                />
                                                <HelperText>
                                                    {errors._7 && touched._7
                                                        ? errors._7
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="8"
                                                    value={values._8}
                                                    name="_8"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors._8 && touched._8
                                                    }
                                                />
                                                <HelperText>
                                                    {errors._8 && touched._8
                                                        ? errors._8
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="9"
                                                    value={values._9}
                                                    name="_9"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors._9 && touched._9
                                                    }
                                                />
                                                <HelperText>
                                                    {errors._9 && touched._9
                                                        ? errors._9
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="10"
                                                    value={values._10}
                                                    name="_10"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors._10 &&
                                                        touched._10
                                                    }
                                                />
                                                <HelperText>
                                                    {errors._10 && touched._10
                                                        ? errors._10
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </StyledCard>
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
            {data && (
                <UltrasonicPulseVelocityTable
                    data={data}
                    setData={setData}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    result={result}
                    overall={overall}
                    setOverall={setOverall}
                />
            )}
            {isView !== true && (
                <Grid container justifyContent="end">
                    <Button
                        sx={{ boxShadow: 'none', mt: 2 }}
                        variant="contained"
                        endIcon={<AddCircle />}
                        onClick={handleOpen}
                        size="small"
                    >
                        Add
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default UltrasonicPulseVelocityModal
