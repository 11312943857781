import { Grid } from '@mui/material'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'
import { Fragment } from 'react'
import DryingShrinkageNewModal from './DryingShrinkageNewModal'

const DryingShrinkageNew = ({
    result,
    isView,
    overall,
    setOverall,
    handleCustomRequirementChange,
    handleRequirementChange,
}) => {
    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <JobAccordion
                        title={result?.groupName}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        requirementvalue={overall?.requirements}
                        //avgLabel="Average of Drying Shrinkage"
                        avgLabel={
                            result.groupName === 'Moisture Movement'
                                ? 'Average of Moisture Movement'
                                : 'Average of Drying Shrinkage'
                        }
                        averagevalue={
                            result.groupName === 'Moisture Movement'
                                ? overall?.moistureMovement
                                : overall?.dryingShrinkage
                        }
                        //averagevalue={overall?.dryingShrinkage}
                        remarksValue={overall?.remarks}
                        overall={overall}
                    >
                        <DryingShrinkageNewModal
                            result={result}
                            isView={isView}
                            overall={overall}
                            setOverall={setOverall}
                        />
                    </JobAccordion>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default DryingShrinkageNew
