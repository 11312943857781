export const TableRowHeadings = {
    'Bulk Density': {
        wt_cylindrical_measure: 'Weight of the Clylindrical  measure W1, kg',
        wt_measure_loosely_filled:
            'Wt of Cylindrical Measure with loosely filled sample, W2, kg',
        wt_measure_rodded_compacted:
            'Wt of Cylindrical Measure with Rodded Compacted sample, W3, kg',
        vol_cylinder: 'Volume of Cylindrical measure, V, lit',
        loose_bulk: 'Loose Bulk Density, (W2-W1)/V  Kg/lit',
        rodded_bulk: 'Rodded Bulk Density, (W3-W1)/V  kg/lit',
    },
    Bulkage: {
        height_of_loose: 'Height of  loose sand in container, H1 (mm)',
        height_of_sand:
            'Height of  Sand in container during filled with water, H2 (mm)',
        bulkage: 'Bulkage (H1-H2)/H1)* 100',
    },
    'Silt content': {
        volume_of_sample: 'Volume of sample, V1 (ml)',
        volume_after_depostion: 'Volume after deposition, V2 (ml)',
        silt_content: 'Silt content (V2/V1))* 100 (%)',
    },
    'Clay lumps': {
        weight_oven_dry_sample: 'Weight of Oven dried sample, W (g)',
        weight_sample_residue:
            'Weight of sample residue after removal of clay lumps,R(g)',
        clay_lumps: 'Clay Lumps in % : (W-R)/W*100  ',
    },
    'Specific Gravity': {
        weightSaturated: 'Weight in g Saturated Surface-dry Sample (g) [C]',
        weightPycnometer:
            'Weight in g Bottle +Sample + Distilled Water (g) [A]',
        weightPycnometerDistilled:
            'Weight in g Bottle + Distilled Water (g) [B]',
        weightOven: 'Weight in g  of oven-dried sample [D]',
        specificGravity: 'Specific Gravity [D/(A-(B-C))]',
    },
    'Aggregate Water Absorption': {
        weightSaturated: 'Weight in g Saturated Surface-dry Sample (g) [A]',
        weightPycnometer:
            'Weight in g Bottle +Sample + Distilled Water (g) [B]',
        weightPycnometerDistilled:
            'Weight in g Bottle + Distilled Water (g) [C]',
        weightOven: 'Weight in g  of oven-dried sample D',
        waterAbsorption: 'Water Absorbtion(%) [100*(A-D)/D]',
    },
    'Crushing Value': {
        weight_surface_dry_sample: 'Weight of the oven-dried sample, A(Kg)',
        weight_fraction_passing_through:
            'Weight of fraction passing through 2.36mm seive after loading, B (kg)',
        aggregate_crushing_value: 'Aggregate Crushing Value (B/A)* 100',
    },
    'Impact Value': {
        weight_oven_dry_sample: 'Weight of Oven dried sample, A (g)',
        weight_fraction_passing_through:
            'Weight of fraction passing through 2.36mm seive, B (g)',
        aggregate_impact_value: 'Aggregate impact value (B/A)* 100',
    },
    '10% fine value': {
        load: 'Load in tonnes, x',
        percentage_fines: 'Mean Percentage of two tests with x Load, y',
        ten_per_fine_value: '10% fines Load in tonnes: (14*x)/(y+4)',
    },
    'Particle finer than 75 micron': {
        weight_oven_dry_sample: 'Weight of Oven dried sample, A (g)',
        weight_sample_after_wash: 'Weight of sample after wash, B(g)',
        particles_fine: 'Particles finer than 75 micron in % , (A-B)/A*100',
    },
    'Soundness test': {
        weight_of_oven_dried_A: 'Weight of Oven dried sample after wash, A (g)',
        weight_of_sample_after_removal_B:
            'Weight of sample after removal from Sodium Sulphate, B(g)',
        weight_of_sample_after_removal_C:
            'Weight of sample after removal from Magnesium Sulphate, C(g)',
        sodium_sulphate_solution:
            'Soundness for Sodium Sulphate Solution in % : (A-B)/A*100',
        magnesium_sulphate_solution:
            'Soundness for Magnesium Sulphate Solution in % : (A-C)/A*100',
    },
    'Fineness by Dry Sieving': {
        weight_of_sample_retained_75_45_micron:
            'Weight of the Sample Retained on 75 & 45micron sieve',
        total_weight_of_sample: 'Total Weight of the sample',
        particles_retained_on_45mic:
            'Particles retained on 45mic sieve(Wet Sieve)',
    },
    'Moisture content': {
        actual_sample_weight: 'Actual Sample Weight',
        oven_dried_sample_weight: 'Oven Dried Sample Weight',
        moisture_content: 'Moisture Content(%)',
    },
    'Soundness by Le Chatlier method': {
        initial_measurement_in_mm: 'Initial Measurement in mm',
        final_measurement_in_mm: 'Final Measurement in mm',
        total_measurement: 'Le-chatlier Soundness,(Final-Initial)/Final*100(%)',
    },
    'Specific gravity by Le Chatlier flask': {
        weight_cement: 'Weight of Cement, W1',
        weight_water_equal_vol: 'Weight of Water of Equal Volume,W2',
        specificGravity: 'Specific Gravity, (W1/W2)',

        // weight_empty_bottle: 'Weight of Empty Bottle,W1(g)',
        // weight_empty_bottle_cement: 'Weight of Empty Bottle + Cement,W2(g)',
        // weight_empty_bottle_cement_kerosene:
        //     'Weight of Empty Bottle + Cement + Kerosene,W3(g)',
        // weight_empty_bottle_fullKerosene:
        //     'Weight of empty Bottle + Full Kerosene,W4(g)',
        // specificGravity: 'Specific Gravity,0.79*(W2-W1)/((W4-W1)-(W3-W2))',
    },
    'Fineness by Dry Sieving %': {
        weight_of_sample_retained_90_micron:
            'Weight of the Sample Retained on 90micron sieve',
        total_weight_of_sample: 'Total Weight of the sample',
        Fineness_in_per: 'Fineness in %',
    },
    'Initial Setting Time': {
        initialSettingTime: 'Initial Setting Time',
    },
    'Final Setting Time': {
        finalSettingTime: 'Final Setting Time',
    },
    Slump: {
        slump: 'Slump',
    },
    'Plastic Limit': {
        wet_weight: 'Wet Weight of soil crumble at 3mm dia, W1 (g)',
        weight_of_oven: 'Weight of oven dried soil crumble at 3mm dia, W2 (g)',
        plastic_limit: 'Plastic limit 100*(W1-W2)/W2  %	',
    },
    'Soil Specific Gravity': {
        weight_of_bottle_w1: 'Weight of bottle (W1), g',
        weight_of_bottle_w2: 'Weight of bottle + soils (W2), g ',
        weight_of_bottle_w3: 'Weight of bottle + soils + water  (W3), g ',
        weight_of_bottle_w4: 'Weight of bottle + water  (W4), g',
        total_weight: 'Specific Gravity (W2-W1)/(W4-W1)-(W3-W2)	',
    },
    // 'Construction Water': {
    //     pH: 'pH @ 25⁰C',
    //     total_suspended_matter: 'Total Suspended Matter',
    //     organic_solids: 'Organic Solids @ 550◦C',
    //     inOrganic_solids: 'Inorganic Solids @ 180◦C',
    //     chloride: 'Chloride as Cl',
    //     sulphate: 'Sulphate as SO(4)',
    //     neutralize_mixed_indicator:
    //         'To neutralize 100ml of sample water using Mixed Indicator using 0.02N H(2)SO(4)',
    //     neutralize_phonolphthalein_indicator:
    //         'To neutralize 100ml of sample water using Phonolphthalein as Indicator using 0.02N NaOH',
    // },
}
