import {
    Box,
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const ACTTable = ({
    data,
    setData,
    setFinalData,
    finalData,
    result,
    setOverall,
    overall,
}) => {
    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        let average = 0
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && item.data.length > 0) {
                item.data = _data
                if (item.data.length > 0) {
                    _data.forEach((item1) => {
                        let value = Number(item1.estimated_comp / _data.length)
                        average += Number(value.toFixed(3))
                    })
                }
            }
            if (item.groupName === result.groupName) {
                item.data = _data
                item.overall = {
                    ...overall,
                    avgCompStr: valueCalc(average),
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
    }

    //formula
    // data.accelerated_comp = (Number(data?.compressiveLoad) * 1000) / 22500 ? valueCalc((Number(data?.compressiveLoad) * 1000) / 22500) : 0
    // data.estimated_comp = valueCalc(1.64 * Number(data?.accelerated_comp) + 8.09) ?? 0

    const Column = [
        { text: 'Sample Id', dataField: 'sampleId' },
        { text: 'Weight (Kgs)', dataField: 'weight' },
        {
            text: 'Comp. Load (kN)',
            dataField: 'compressiveLoad',
        },

        {
            text: 'Accelerated Comp. Str.Ra (N / sq.mm)',
            dataField: 'accelerated_comp',
        },
        {
            text: 'Estimated Comp. Str (N/mm²) R28 @ 28 days as per ACT ',
            dataField: 'estimated_comp',
        },
    ]

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        {Column.map((item, index) => (
                            <TableCell align={'center'} key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                        <TableCell sx={{ width: 80 }} align={'center'}>
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((item, index) => (
                        <TableRow key={index}>
                            {Column.map((subItem, idx) => (
                                <TableCell
                                    align="center"
                                    key={`row-${idx}-index`}
                                >
                                    {item[subItem.dataField]}
                                </TableCell>
                            ))}
                            <TableCell>
                                <Button onClick={(e) => handleDelete(index, e)}>
                                    <Icon>delete</Icon>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default ACTTable
