import { useContext, useState } from 'react'

import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import CementChemicalTable from './CementChemicalTable'

const initialValues = {
    tests: '',
    unit: '',
    req: '',
    result: '',
}

const validationSchema = Yup.object().shape({
    result: Yup.string().required('Required'),
})
const CementChemicalModal = ({ result, isView, setOverall, overall }) => {
    const handleClose = () => setOpen(false)
    const [requirement, setRequirement] = useState({
        req: initialValues.req,
        unit: initialValues.unit,
    })
    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)
    const [data, setData] = useState(result.data ? result.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const { finalData, setFinalData } = useContext(AppContext)
    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        // arr = applyFormula(arr);
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && arr.length !== 0) {
                let avg = 0
                arr.forEach((item1) => {})
            }
            if (item.groupName === result.groupName) {
                item.data = arr
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
        // resetForm()
    }

    // const applyFormula = (arr) => {
    //     arr.forEach((item, index) => {
    //         // let grades = conditions[item.is_sieve_designation]
    //         // item.requirement =  grades.req

    //     })
    //     return arr;
    // }

    const handleTests = (e) => {
        const index = result?.requirement[0]?.requirement
            ?.map((item) => item.testParams)
            .indexOf(e.target.value)
        let requirements = result?.requirement[0]?.requirement[index]?.limit
        let unit = result?.requirement[0]?.requirement[index]?.unit

        setRequirement({
            ...requirement,
            req: requirements,
            unit: unit,
        })
    }

    const handleReq = (e) => {
        const { name, value } = e.target
        setRequirement({
            ...requirement,
            [name]: value,
        })
    }

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props
                    return (
                        <Form>
                            <Modal open={open} onClose={handleClose}>
                                <StyledCard>
                                    <CardHeader title={result?.groupName} />
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                        Test Carried
                                                    </InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={values.tests}
                                                        name="tests"
                                                        label="Test Carried"
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            handleTests(e)
                                                        }}
                                                    >
                                                        {result?.requirement[0]?.requirement?.map(
                                                            (value) => (
                                                                <MenuItem
                                                                    value={
                                                                        value?.testParams
                                                                    }
                                                                >
                                                                    {
                                                                        value?.testParams
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Unit"
                                                    value={
                                                        (values.unit =
                                                            requirement.unit)
                                                    }
                                                    name="unit"
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        handleReq(e)
                                                    }}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.unit &&
                                                        touched.unit
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.unit && touched.unit
                                                        ? errors.unit
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Requirement"
                                                    value={
                                                        (values.req =
                                                            requirement.req)
                                                    }
                                                    name="req"
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        handleReq(e)
                                                    }}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.req &&
                                                        touched.req
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.req && touched.req
                                                        ? errors.req
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Result"
                                                    value={values.result}
                                                    name="result"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.result &&
                                                        touched.result
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.result &&
                                                    touched.result
                                                        ? errors.result
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </StyledCard>
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
            {data && (
                <CementChemicalTable
                    data={data}
                    setData={setData}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    result={result}
                    overall={overall}
                    setOverall={setOverall}
                />
            )}
            {isView !== true && (
                <Grid container justifyContent="end">
                    <Button
                        sx={{ boxShadow: 'none', mt: 2 }}
                        variant="contained"
                        endIcon={<AddCircle />}
                        onClick={handleOpen}
                        size="small"
                    >
                        Add
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default CementChemicalModal
