import { ExpandMore } from '@mui/icons-material'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Button,
    Grid,
    Typography,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import {
    downloadDocumentTemplate,
    getJobView,
    getReportJobDocument,
    uploadJobDocument,
} from 'app/redux/actions/JobActions'
import { Fragment, useContext, useState } from 'react'
import { DropzoneArea } from 'react-mui-dropzone'
import { useParams } from 'react-router-dom'

const CommonDoc = ({
    materialName,
    materialId,
    setSnack,
    snack,
    isView,
    document,
}) => {
    const params = useParams()
    const { setEditDetails } = useContext(AppContext)
    const [jobId, setJobId] = useState(params?.id ?? '')
    const [boolean, setBoolean] = useState(
        document?.length === 0 ? false : true
    )
    const handleDropZone = (files) => {
        if (files[0] !== undefined) {
            const formData = new FormData()
            formData.append('files', files[0])
            uploadJobDocument({ materialId, jobId, formData }, (res) => {
                if (res) {
                    alert(res + ' ' + 'And Click Save')
                }
            })
            setTimeout(() => {
                getJobView(params?.id, (res) => {
                    console.log(res)
                    if (res) {
                        setEditDetails(res)
                        setBoolean(true)
                    }
                })
            }, 1000)
        }
    }
    const handleDownloadTemplate = () => {
        downloadDocumentTemplate({ materialId }, (res) => {
            if (res) {
                window.open(res, '_blank')
            }
        })
    }

    const handleDownloadDoc = () => {
        getReportJobDocument({ materialId, jobId }, (res) => {
            if (res) {
                window.open(res, '_blank')
            }
        })
    }

    const filePath = document[0]?.key
    const dateData = document[0]?.date
    const date = new Date(dateData).toLocaleDateString()
    const time = new Date(dateData).toLocaleTimeString()
    const filename = filePath?.split('/')?.slice(-1)[0]

    return (
        <Fragment>
            <Grid container spacing={2} sx={{ pt: 2 }}>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary
                            sx={{ bgcolor: '#cdcdcd4f' }}
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Grid item xs={12}>
                                <Typography
                                    fontWeight={'bolder'}
                                    textTransform="capitalize"
                                >
                                    {materialName}
                                </Typography>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid item xs={12}>
                                <Button
                                    variant="outlined"
                                    onClick={handleDownloadTemplate}
                                    disabled={isView}
                                >
                                    Download Template
                                </Button>
                                <Grid item xs={12} sx={{ pt: 2 }}>
                                    {isView !== true && (
                                        <DropzoneArea
                                            onChange={handleDropZone}
                                            filesLimit={1}
                                            showPreviews={false}
                                            // initialFiles={base64Files}
                                            maxFileSize={90000000}
                                            key="3"
                                            acceptedFiles={['.docx']}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            {boolean === true && (
                                <Grid item xs={12} sx={{ pt: 2 }}>
                                    <Alert severity="info">
                                        <AlertTitle>
                                            File Name: {filename}
                                            <br></br>
                                            Date: {date}
                                            <br></br>
                                            Time: {time}
                                        </AlertTitle>
                                        <Button
                                            disabled={isView}
                                            variant="outlined"
                                            onClick={handleDownloadDoc}
                                        >
                                            Download Report
                                        </Button>
                                    </Alert>
                                </Grid>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </Fragment>
    )
}
export default CommonDoc
