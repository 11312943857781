import Axios from 'app/configs/Axios'

export const fetchUserData = (props, callback, errorCallback) => {
    return Axios({
        method: 'get',
        url: 'users',
        params: {
            ...props,
        },
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}

export const deleteUserData = (id, callback, errorCallback) => {
    return Axios({
        method: 'delete',
        url: `users/${id}`,
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}

export const deleteArchivedUserData = (props, callback, errorCallback) => {
    return Axios({
        method: 'patch',
        url: `users/${props.jobId}`,
        data: props.status,
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}
