import { useEffect, useRef } from 'react'

//formula
export const valueCalc = (value, groupName) => {
    return !isNaN(value) && value.toString().indexOf('.') != -1
        ? Number(value).toFixed(groupName === 'Block Density' ? 4 : 3)
        : isNaN(value)
          ? value
          : value == 0
            ? '0'
            : Number(value).toFixed(groupName === 'Block Density' ? 4 : '')
    // return !isNaN(value) && value.toString().indexOf('.') !== -1
    //     ? parseFloat(Number(value).toFixed(4))
    //     : isNaN(value)
    //     ? value
    //     : value === 0
    //     ? '0'
    //     : parseFloat(Number(value).toFixed(2)).toFixed(2)
}

function usePrevious(value) {
    const ref = useRef()
    useEffect(() => {
        ref.current = value //assign the value of ref to the argument
    }, [value]) //this code will run when the value of 'value' changes
    return ref.current //in the end, return the current ref value.
}

export default usePrevious

export const getRequirement = ({ materialName, result }) => {
    let obj = {}
    if (materialName === 'Cement') {
        obj.requirement = result?.requirement[0]?.requirement?.find(
            (req) => req?.testParams === ''
        )?.limit
    } else if (materialName === 'Soil') {
        obj.requirement = result?.requirement[0]?.requirement
    } else {
        obj = result?.requirement?.find((req) => req?.test_params == '')
    }
    return obj
}

export const countDecimalPlaces = (number) => {
    const decimalMatch = String(number).match(/\.(\d+)/)
    if (decimalMatch) {
        if (decimalMatch[1].length === 1) {
            return Number(number).toFixed(1)
        } else if (decimalMatch[1].length === 2) {
            return Number(number).toFixed(2)
        } else if (decimalMatch[1].length === 3) {
            return Number(number).toFixed(3)
        } else if (decimalMatch[1].length > 3) {
            return Number(number).toFixed(3)
        }
    } else {
        return number
    }
}
