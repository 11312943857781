import { Box, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const CementFinenessTable = (props) => {
    const { data } = props

    let cementFiness

    cementFiness = [
        {
            label: 'Weight of the Sample Retained on 90micron sieve',
            value: 'weight_of_sample_retained_90_micron',
        },
        {
            label: 'Total Weight of the sample',
            value: 'total_weight_of_sample',
        },
        { label: 'Fineness in %', value: 'Fineness_in_per' },
    ]

    const Column = [
        {
            text: 'Observation',
            dataField: 'label',
        },
        {
            text: 'Result',
            dataField: 'value',
        },
    ]

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">SI.No</TableCell>
                        {Column.map((item, index) => (
                            <TableCell align="center" key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {cementFiness?.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{item.label}</TableCell>
                            <TableCell align="center">
                                {data[item.value] ? data[item.value] : '0'}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default CementFinenessTable
