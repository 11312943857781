import { Box, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const SpecificGravityTable = (props) => {
    const { data } = props

    // //Formula
    // let value = data.weightOven / (data.weightSaturated - (data.weightPycnometer - data.weightPycnometerDistilled))
    // let value2 = (100*(data.weightSaturated - data.weightOven)/data.weightOven)
    // data.specificGravity = (value) !== 0 ? valueCalc(value) : '0'
    // data.waterAbsorption = (value2) !== 0 ? valueCalc(value2) : '0'

    let specific_Gravity

    specific_Gravity = [
        {
            label: 'Weight in g Saturated Surface-dry Sample (g)',
            formula: 'A',
            value: 'weightSaturated',
        },
        {
            label: 'Weight in g Bottle +Sample + Distilled Water (g)',
            formula: 'B',
            value: 'weightPycnometer',
        },
        {
            label: 'Weight in g Bottle + Distilled Water (g)',
            formula: 'C',
            value: 'weightPycnometerDistilled',
        },
        {
            label: 'Weight in g  of oven-dried sample',
            formula: 'D',
            value: 'weightOven',
        },
        {
            label: 'Specific Gravity',
            formula: 'D/(A-(B-C))',
            value: 'specificGravity',
        },
        // { label: 'Water Absorption', formula: '100*(A-D)/D', value: "waterAbsorption" },
    ]

    const Column = [
        {
            text: 'Observation',
            dataField: 'label',
        },
        {
            text: 'Formula',
            dataField: 'formula',
        },
        {
            text: 'Result',
            dataField: 'value',
        },
    ]

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">SI.No</TableCell>
                        {Column.map((item, index) => (
                            <TableCell align="center" key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {specific_Gravity?.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{item.label}</TableCell>
                            <TableCell align="center">{item.formula}</TableCell>
                            <TableCell align="center">
                                {data[item.value] ? data[item.value] : '0'}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default SpecificGravityTable


// const handleDelete = (index, e) => {
//     let _data = data.filter((v, i) => i !== index);
//     setData(_data);
//     let _finalData = finalData;
//     _finalData.forEach(item => {
//         if (item.groupName === result.groupName) {
//             item.overall = _data;
//         }
//     })
//     setFinalData(_finalData);
// }