// import VisibilityIcon from '@mui/icons-material/Visibility'
import {
    // Icon,
    IconButton,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { Box } from '@mui/system'
import {
    StyledIcon,
    StyledTable,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import Cookies from 'js-cookie'

let role = Cookies.get('userRole')

const TestViewTable = ({
    data,
    handleOpen,
    status,
    handleClose,
    // handleChangePage,
    // handleChangeRowsPerPage,
    // handleChange,
    // onAdd,
    // page,
    // rowsPerPage,
}) => {
    return (
        <Box width="100%" overflow="auto">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Job No</TableCell>

                        <TableCell align="center">Test Name</TableCell>
                        <TableCell align="center">Assigned To</TableCell>
                        {/* <TableCell>Start Date</TableCell> */}
                        <TableCell align="center">Due Date</TableCell>
                        <TableCell align="center">Status</TableCell>
                        {!(
                            role === 'CUSTOMER' ||
                            role === 'COLLECTOR' ||
                            status === 'ARCHIVED'
                        ) && <TableCell align="center">Actions</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((test, index) => {
                        // let date = new Date(test?.due_date).getDate()
                        // let month = new Date(test?.due_date).getMonth()
                        // let year = new Date(test?.due_date)?.getFullYear()
                        // let finalDate = date + '-' + month + '-' + year
                        let finalDate = test?.due_date
                            ? new Date(test?.due_date).toLocaleDateString()
                            : '-'
                        return (
                            <TableRow key={index}>
                                <TableCell align="center">
                                    {test.job_no}
                                </TableCell>

                                <TableCell align="center">
                                    {test?.material?.name}
                                    {test?.test_name}
                                </TableCell>
                                <TableCell align="center">
                                    {test?.allocated_to
                                        ? typeof test.allocated_to === 'object'
                                            ? test.allocated_to.name
                                            : test.allocated_to
                                        : '-'}
                                </TableCell>
                                {/* <TableCell align="left">
                                    {test.start_date}
                                </TableCell> */}
                                <TableCell align="center">
                                    {finalDate !== 'Invalid Date'
                                        ? finalDate
                                        : '-'}
                                </TableCell>
                                <TableCell align="center">
                                    {test.status}
                                </TableCell>

                                {!(
                                    role === 'CUSTOMER' ||
                                    role === 'COLLECTOR' ||
                                    status === 'ARCHIVED'
                                ) && (
                                    <TableCell align="center">
                                        {/* <IconButton>
                                        <StyledIcon>visibility</StyledIcon>
                                    </IconButton> */}
                                        <IconButton>
                                            <StyledIcon
                                                onClick={() =>
                                                    handleOpen(test, index)
                                                }
                                            >
                                                edit
                                            </StyledIcon>
                                        </IconButton>
                                    </TableCell>
                                )}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default TestViewTable
