import { Box, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const TenPercFineValueTable = (props) => {
    const { data } = props

    // data.percentage_fines = valueCalc((data.wt_surface_fraction_passing_through / data.wt_surface_dry_sample) * 100)

    // let x = 14 * data.load
    // let y = Number.parseFloat(data.percentage_fines) + 4

    // // let y = (Number.parseFloat(data.mean)
    // //     + 4)

    // //Formula
    // let value = (x / y) * 0.1
    // data.ten_per_fine_value = value ? valueCalc(value) : '0'

    let tenPercFineValue

    tenPercFineValue = [
        {
            label: 'Load in tonnes, x',
            value: 'load',
        },
        {
            label: 'Weight of surface dry sample, A (kg)',
            value: 'wt_surface_dry_sample',
        },
        {
            label: 'Weight of fraction passing through 2.36mm seive after loading, B (kg)',
            value: 'wt_surface_fraction_passing_through',
        },
        {
            label: 'Percentage Fines (B/A)*100',
            value: 'percentage_fines',
        },
        // {
        //     label: 'Mean Percentage of two tests with x Load, y',
        //     value: "mean"
        // },
        {
            label: '10% fines Load in tonnes: (14*x)/(y+4)',
            value: 'ten_per_fine_value',
        },
    ]

    const Column = [
        { text: 'Observation', dataField: 'label' },
        { text: 'Result', dataField: 'value' },
    ]

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        {/* <TableCell align="center">Actions</TableCell> */}
                        <TableCell align="center">SI.No</TableCell>
                        {Column.map((item, index) => (
                            <TableCell align="center" key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                {tenPercFineValue?.map((item, index) => (
                    <TableBody>
                        <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{item.label}</TableCell>
                            <TableCell align="center">
                                {data[item.value] ? data[item.value] : '0'}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ))}
            </StyledTable>
        </Box>
    )
}

export default TenPercFineValueTable


// const handleDelete = (index, e) => {
//     let _data = data.filter((v, i) => i !== index);
//     setData(_data);
//     let _finalData = finalData;
//     _finalData.forEach(item => {
//         if (item.groupName === result.groupName) {
//             item.overall = _data;
//         }
//     })
//     setFinalData(_finalData);
// }