export const gradeDia = [
    '8mm / Fe 415',
    '10mm / Fe 415',
    '12mm / Fe 415',
    '16mm / Fe 415',
    '20mm / Fe 415',
    '25mm / Fe 415',
    '32mm / Fe 415',

    '8mm / Fe 415D',
    '10mm / Fe 415D',
    '12mm / Fe 415D',
    '16mm / Fe 415D',
    '20mm / Fe 415D',
    '25mm / Fe 415D',
    '32mm / Fe 415D',

    '8mm / Fe 500',
    '10mm / Fe 500',
    '12mm / Fe 500',
    '16mm / Fe 500',
    '20mm / Fe 500',
    '25mm / Fe 500',
    '32mm / Fe 500',

    '8mm / Fe 500D',
    '10mm / Fe 500D',
    '12mm / Fe 500D',
    '16mm / Fe 500D',
    '20mm / Fe 500D',
    '25mm / Fe 500D',
    '32mm / Fe 500D',

    '8mm / Fe 550',
    '10mm / Fe 550',
    '12mm / Fe 550',
    '16mm / Fe 550',
    '20mm / Fe 550',
    '25mm / Fe 550',
    '32mm / Fe 550',

    '8mm / Fe 550D',
    '10mm / Fe 550D',
    '12mm / Fe 550D',
    '16mm / Fe 550D',
    '20mm / Fe 550D',
    '25mm / Fe 550D',
    '32mm / Fe 550D',

    '8mm / Fe 600',
    '10mm / Fe 600',
    '12mm / Fe 600',
    '16mm / Fe 600',
    '20mm / Fe 600',
    '25mm / Fe 600',
    '32mm / Fe 600',
]

export const gradeForCement = [
    'OPC 33',
    'OPC 43',
    'OPC 53',
    'OPC 43S',
    'OPC 53S',
    'PPC',
]

export const couplerDia = [
    '8mm',
    '10mm',
    '12mm',
    '16mm',
    '20mm',
    '25mm',
    '32mm',
]

export const grades = ['415', '415D', '500', '500D', '550', '550D', '600']

export const sieveSize = ['12.5mm', '20mm', '40mm', '6.0mm']

export const sizeFor20mm = ['40.00 mm', '20.00 mm', '10.00 mm', '04.75 mm']

export const sizeFor12_5mm = ['20.00 mm', '12.50 mm', '10.00 mm', '04.75 mm']

export const CBRPenetration = [
    '0.0',
    '0.5',
    '1.5',
    '2.0',
    '2.5',
    '4.0',
    '5.0',
    '7.5',
    '10.0',
    '12.5',
]

export const getDays = ['3days', '7days', '28days']

export const titleNameCheck = [
    'Coarse Aggregate',
    'Fine Aggregate',
    'GSB Sample',
    'Solid Blocks',
    'Autoclaved Aerated Concrete Blocks (AAC Blocks)',
    'Paver blocks',
    'Drinking Water (Bore Water)',
    'Hardened Concrete (Concrete core)',
    'Fly ash',
    'Soil',
    'WMM Sample',
    'Concrete Blocks (Hollow & Solid)',
    'Bricks & pre-casted Blocks',
    'Red Clay Bricks',
]

export const isReport = [
    'Coarse Aggregate',
    'Fine Aggregate',
    'Autoclaved Aerated Concrete Blocks (AAC Blocks)',
    'Bricks & pre-casted Blocks',
    'Concrete Blocks (Hollow & Solid)',
    'Paver blocks',
    'Solid Blocks',
    'Fresh Concrete',
    'Hardened Concrete Cube',
    'Hardened Concrete Cube By ACT Method',
    'Hardened Concrete (Concrete core)',
    'Fly ash',
    'Ply Wood',
    'Admixture',
    'Runner Pipe',
    'Soil',
    'GSB Sample',
    'WMM Sample',
    'Carbonation test',
    'Half-Cell Potential difference test',
    'AAC Blocks',
    'Hardened Concrete (Rebound Hammer)',
    'Crack width measurement in reinforcement concrete',
    'Soils',
    'Bricks',
    'Soil Core',
    'Coupler',
    'Reinforcement Steel (Chemical)',
    'Reinforcement Steel (Physical)',
    'Cement (Chemical)',
    'Coupler (Chemical)',
    'Drinking Water (Bore Water)',
    'Red Clay Bricks',
    // 'Drinking Water',
    // 'Swimming Pool Water'
]

export const isNotReport = ['Solid Blocks']

export const sampleColloectGrade = [
    'Cement',
    'Cement(Physical)',
    'Cement (Chemical)',
]

export const getReportTitleForChemical = [
    // 'Construction Water',
    'Cement (Chemical)',
    'Reinforcement Steel (Chemical)',
    // 'Chemical Waste Water (STP Treated Water)'
]

export const getReportTitleForPhysical = [
    'Paver blocks',
    'Ply Wood',
    'Autoclaved Aerated Concrete Blocks (AAC Blocks)',
    'Cement',
    'Cement(Physical)',
    'Reinforcement Steel (Physical)',
    // 'Fly ash',
    // 'Soil',
    'Hardened Concrete (Concrete core)',
    'Solid Blocks',
    'Coupler',
    'WMM Sample',
    'Hardened Concrete (Concrete core)',
    'Bricks & pre-casted Blocks',
    'Concrete Blocks (Hollow & Solid)',
    'Red Clay Bricks',
]

export const getReportTitleForChemicalAndBiological = [
    'Drinking Water (Bore Water)',
]
export const getReportTitleForWater = [
    'Construction Water',
    'Drinking Water (RO Water)',
    'Swimming Pool Water',
    'Cooling Tower Water',
    'Drinking Water (Bore Water)',
    'Well Water',
    'Waste Water (STP Treated Water)',
    'Waste Water (ETP Effluent Water)',
    'Waste Water (ETP Treated Water)',
    'Chemical Waste Water (STP Treated Water)',
]

export const gradeOption = [
    'M10',
    'M15',
    'M20',
    'M25',
    'M30',
    'M35',
    'M40',
    'M45',
    'M50',
    'M55',
    'M60',
    'M65',
    'M70',
    'M75',
    'M80',
    'M85',
    'M90',
]

export const getDocReport = [
    'HARDNENED CONCRETE (REBOUND HAMMER)',
    'ULTRASONIC PULSE VELOCITY TEST (UPV)',
]

export const JobHeaders = [
    {
        label: 'Test Parameter',
        field: 'testParameter',
        fieldData: [
            {
                requirement: '--',
                testParameter: 'Physical Appearance After Filteration',
                unit: '3',
            },
            {
                requirement: '--',
                testParameter: 'pH @ 25˚C',
                unit: '5',
            },
            {
                requirement: '--',
                testParameter: 'Electrical Conductivity @ 25⁰C',
                unit: '10',
            },
            {
                requirement: '--',
                testParameter: 'Bacterial Count',
                unit: 'CFU/plate',
            },
            {
                requirement: '--',
                testParameter: 'Coliform',
                unit: 'CFU/plate',
            },
            {
                requirement: '--',
                testParameter: 'Yeast and Mould',
                unit: 'CFU/plate',
            },
        ],
        type: 'dropDown',
    },
    {
        label: 'Result',
        field: 'result',
        fieldData: 'result',
        type: 'textBox',
    },
    {
        label: 'Unit',
        field: 'unit',
        fieldData: 'unit',
        type: 'textBox',
    },
    {
        label: 'Requirement',
        field: 'requirement',
        fieldData: 'requirement',
        type: 'textBox',
    },
    {
        label: 'Requirement1',
        field: 'requirement1',
        fieldData: ['Data 1', 'Data 2'],
        type: 'createableDropdown',
    },
]
// export const JobHeaders = [
//     {
//                 label: "Test Parameter",
//                 field: "testParameter",
//                 fieldData: [
//                     {
//                         requirement:"--",
//                         testParameter:"Swab Test- Yeast and Mould",
//                         unit:"--"
//                     },
//                     {
//                         requirement:"--",
//                         testParameter:"Swab Test- Coliform",
//                         unit:"--"
//                     }
//                 ],
//                 type: "dropDown",
//             },
//     {
//         label: "Sum of Colonies on all dishes selected(N)",
//         field: "w1",
//         fieldData:"w1",
//         type: "textBox",
//     },
//     {
//         label: "Amount of dilution fluid in the tube in ml(F) ",
//         field: "w2",
//         fieldData:"w2",
//         type: "textBox",
//     },
//     {
//         label: "Surface Investigated in cm2(A) ",
//         field: "w3",
//         fieldData:"w3",
//         type: "textBox",
//     },
//     {
//         label: "Reciprocal of Dilution used(D)",
//         field: "w4",
//         fieldData:"w4",
//         type: "textBox",
//     },
//     {
//         label: "Defined Area",
//         field: "w5",
//         fieldData:"w5",
//         type: "formula",
//         formula: "(w1*w2*w4)/w3",
//     },
// ]

export const getStateValue = (result) => {
    let valueObj = {}
    result?.header?.fields?.forEach((it) => {
        valueObj[it?.fields] = ''
    })
    return valueObj
}

export const valueObj = {
    testParameter: '',
    result: '',
    unit: '',
    requirement: '',
    requirement1: '',
}

export const dynamicData = {
    'Block Density': {
        sampleId: 'Sample ID',
        len: 'Length',
        breadth: 'Breadth',
        height: 'Height',
        dryWeight: 'Dry Weight',
        volume: 'Volume',
        block_denisty: 'Block Density',
    },
    'Block Compressive Strength': {
        // id: 'Id',
        sampleId: 'Sample ID',
        len: 'Length',
        breadth: 'Breadth',
        height: 'Height',
        crossArea: 'Cross Sectional Area',
        crushingLoad: 'Crushing Load',
        compStrength: 'Compressive Strength',
    },
}

export const reportHeader = [
    {
        key: 'dia',
        label: 'Dia',
    },
    {
        key: 'perc_carbon',
        label: 'Perc Carbon',
    },
    {
        key: 'perc_phosphorus',
        label: 'Perc Phosphorus',
    },
    {
        key: 'perc_sulphur',
        label: 'Perc Sulphur',
    },
]

export const observationMappings = {
    'Flakiness Index': [
        { sieveSizePassing: '25 -20', guagethickness: '13.5' },
        { sieveSizePassing: '20 -16', guagethickness: '10.8' },
        { sieveSizePassing: '16 -12.5', guagethickness: '8.55' },
        { sieveSizePassing: '12.5 -10', guagethickness: '6.75' },
        { sieveSizePassing: '10 -6.3', guagethickness: '4.89' },
    ],
    'Elongation Index': [
        { sieveSizePassing: '25 -20', guagethickness: '13.5' },
        { sieveSizePassing: '20 -16', guagethickness: '10.8' },
        { sieveSizePassing: '16 -12.5', guagethickness: '8.55' },
        { sieveSizePassing: '12.5 -10', guagethickness: '6.75' },
        { sieveSizePassing: '10 -6.3', guagethickness: '4.89' },
    ],
    'Grain Size Analysis(0.075 to 4.75 mm)': [
        { sieve_size: '4.75mm' },
        { sieve_size: '2.36mm' },
        { sieve_size: '1.18mm' },
        { sieve_size: '425micron' },
        { sieve_size: '212micron' },
        { sieve_size: '125micron' },
        { sieve_size: '75micron' },
        { sieve_size: 'pan' },
    ],
    'Compressive Strength': [
        { days: '3days' },
        { days: '7days' },
        { days: '28days' },
    ],
    'CBR Value(Calfornia Bearing Ratio)': [
        {
            penetration: '0.0',
        },
        {
            penetration: '0.5',
        },
        {
            penetration: '1.5',
        },
        {
            penetration: '2.0',
        },
        {
            penetration: '2.5',
        },
        {
            penetration: '4.0',
        },
        {
            penetration: '5.0',
        },
        {
            penetration: '7.5',
        },
        {
            penetration: '10.0',
        },
        {
            penetration: '12.5',
        },
    ],
}

export const groupName1 = [
    'Compressive Strength',
    'Chemical Parameters',
    'Abrasion value',
    'Chemical Parameters',
    'Construction Water',
    'Water Neutralization',
    'Ply Wood',
    'Admixture',
    'Swimming Pool Water',
    'Bacteriological Parameters',
    'Cooling Tower Water',
    'Runner Pipe',
    'STP Treated Water',
    'Organo chlorine Pesticides (OCPS)',
    'Organo Phosphorus Pesticides (OPPs)',
    'Synthetic Pyrethroids (SPs)',
    'Herbicides',
    'Radioactive Material',
    'ETP Effluent Water',
    'ETP Treated Water',
    'Chemical Parameters',
    'GSB Sample',
    'WMM Sample',
    'Drinking Water (Bore Water)',
    'Bacteriological Parameters',
    'Well Water',
    'Specifications as per En 8 BS970 (%)',
    
   
]

export const material1 = [
    'Cement(Physical)',
    'Fly ash',
    'Coarse Aggregate',
    'Fine Aggregate',
    'Construction Water',
    'Ply Wood',
    'Admixture',
    'MECH-Swimming Pool Water',
    'Cooling Tower Water',
    'Runner Pipe',
    'Waste Water (STP Treated Water)',
    'Waste Water (ETP Effluent Water)',
    'Waste Water (ETP Treated Water)',
    'Soil',
    'GSB Sample',
    'WMM Sample',
    'Drinking Water (Bore Water)',
    'Well Water',
    'Coupler (Chemical)'
    
]

export const json = [
    {
        key: 'sieveDesignation',
        label: 'IS Sieve',
        keys: [],
    },
    {
        key: 'specification',
        label: 'Specification',
        keys: [
            {
                key: 'grade-I',
                label: 'Grade-I',
            },
            {
                key: 'grade-II',
                label: 'Grade-II',
            },
            {
                key: 'grade-III',
                label: 'Grade-III',
            },
            {
                key: 'grade-IV',
                label: 'Grade-IV',
            },
            {
                key: 'grade-V',
                label: 'Grade-V',
            },
        ],
    },
    {
        key: 'weightRetainedInput',
        label: 'Weight Retained (gm)',
        keys: [],
    },
    {
        key: 'weightRetained',
        label: 'Cumulative Weight Retained (%)',
        keys: [],
    },
    {
        key: 'passing',
        label: 'Cumulative Passing (%)',
        keys: [],
    },
]