import { useContext, useState } from 'react'

import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { valueCalc } from '../../method/method'
import CompressiveStrengthTable from './CompressiveStrengthTable'
import { getDays } from 'app/assets/Constant/constant'

const initialValues = {
    days: '',
    weight: '',
    crossArea: '',
    load: '',
    comp_st: '',
    remarks: '',
    requirement: '',
}

const validationSchema = Yup.object().shape({
    days: Yup.string().required('Required'),
    weight: Yup.number().required('Required'),
    load: Yup.number().required('Required'),
    crossArea: Yup.number().required('Required'),
})
const CompressiveStrengthModal = ({
    result,
    isView,
    overall,
    setOverall,
    materialName,
}) => {
    const handleClose = () => setOpen(false)
    //
    initialValues.crossArea = materialName === 'Cement' ? 4984 : ''

    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)
    const [data, setData] = useState(result.data ? result.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const [requirement, setRequirement] = useState(initialValues.requirement)
    const { finalData, setFinalData } = useContext(AppContext)
    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        arr = applyFormula(arr)
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && arr.length !== 0) {
                let average = 0
                let threeDays = 0
                let threeDaysCount = 0
                let threeDaysAvg = 0
                let threeDaysRemarks = ''
                let threeDaysRequirements = ''

                let sevenDays = 0
                let sevenDaysCount = 0
                let sevenDaysAvg = 0
                let sevenDayRemarks = ''
                let sevenDaysRequirements = ''

                let twentyEightDays = 0
                let twentyEightDaysCount = 0
                let twentyEightDaysAvg = 0
                let twentyEightDaysRemarks = ''
                let twentyEightDaysRequirements = ''

                arr.forEach((item1) => {
                    let value = item1.comp_st / arr.length

                    average += Number(value.toFixed(3))
                    if (item1?.days === '3days') {
                        threeDays += Number(item1.comp_st)
                        threeDaysCount++

                        threeDaysAvg = Number(threeDays / threeDaysCount)
                        threeDaysRemarks = item1?.remarks
                        threeDaysRequirements = item1?.requirement
                    }
                    if (item1?.days === '7days') {
                        sevenDays += Number(item1.comp_st)
                        sevenDaysCount++

                        sevenDaysAvg = Number(sevenDays / sevenDaysCount)
                        sevenDayRemarks = item1?.remarks
                        sevenDaysRequirements = item1?.requirement
                    }
                    if (item1?.days === '28days') {
                        twentyEightDays += Number(item1.comp_st)
                        twentyEightDaysCount++

                        twentyEightDaysAvg = Number(
                            twentyEightDays / twentyEightDaysCount
                        )
                        twentyEightDaysRemarks = item1?.remarks
                        twentyEightDaysRequirements = item1?.requirement
                    }
                })
                item.overall = {
                    ...overall,
                    avgComp_st: valueCalc(average),
                    threeDaysAvg:
                        threeDaysAvg === 0 ? null : valueCalc(threeDaysAvg),
                    threeDaysRemarks: threeDaysRemarks,
                    threeDaysRequirements: threeDaysRequirements,
                    sevenDaysAvg:
                        sevenDaysAvg === 0 ? null : valueCalc(sevenDaysAvg),
                    sevenDayRemarks: sevenDayRemarks,
                    sevenDaysRequirements: sevenDaysRequirements,
                    twentyEightDaysAvg:
                        twentyEightDaysAvg === 0
                            ? null
                            : valueCalc(twentyEightDaysAvg),
                    twentyEightDaysRemarks: twentyEightDaysRemarks,
                    twentyEightDaysRequirements: twentyEightDaysRequirements,
                }

                setOverall(item.overall)
            }
            if (item.groupName === result.groupName) {
                item.data = arr
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
    }

    const applyFormula = (arr) => {
        arr.forEach((item, index) => {
            let comp_st = valueCalc(
                ((item.load / item.crossArea) * 1000) / 1000
            )
            item.comp_st = comp_st
        })
        return arr
    }

    const handleDaysChange = (e) => {
        let isArray = Array.isArray(result?.requirement[0]?.requirement)
        let requirements = ' '
        if (isArray) {
            const index = result?.requirement[0]?.requirement
                ?.map((item) => item.day)
                .indexOf(e.target.value)
            requirements = result?.requirement[0]?.requirement[index]?.limit
        } else {
            requirements = e.target.value
        }
        setRequirement(requirements)
    }

    const handleReq = (e) => {
        setRequirement(e.target.value)
    }

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props
                    return (
                        <Form>
                            <Modal open={open} onClose={handleClose}>
                                <StyledCard>
                                    <CardHeader title="Compressive Strength" />
                                    <CardContent sx={{ m: 4 }}>
                                        <Grid container spacing={4}>
                                            <Grid
                                                item
                                                lg={4}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                        Days
                                                    </InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={values.days}
                                                        name="days"
                                                        label="Days"
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            handleDaysChange(e)
                                                        }}
                                                    >
                                                        {getDays?.map(
                                                            (value) => (
                                                                <MenuItem
                                                                    value={
                                                                        value
                                                                    }
                                                                >
                                                                    {value}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                                <HelperText>
                                                    {errors.days && touched.days
                                                        ? errors.days
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={4}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Weight (kg)"
                                                    value={values.weight}
                                                    name="weight"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.weight &&
                                                        touched.weight
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.weight &&
                                                    touched.weight
                                                        ? errors.weight
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={4}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Cross sectional area, A (mm²)"
                                                    value={values.crossArea}
                                                    name="crossArea"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.crossArea &&
                                                        touched.crossArea
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.crossArea &&
                                                    touched.crossArea
                                                        ? errors.crossArea
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={4}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Load (kN)"
                                                    value={values.load}
                                                    name="load"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.load &&
                                                        touched.load
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.load && touched.load
                                                        ? errors.load
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            {materialName === 'Cement' && (
                                                <>
                                                    {' '}
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            label="Requirement"
                                                            value={
                                                                (values.requirement =
                                                                    requirement)
                                                            }
                                                            name="requirement"
                                                            onChange={(e) => {
                                                                handleChange(e)
                                                                handleReq(e)
                                                            }}
                                                            onBlur={handleBlur}
                                                            error={
                                                                errors.requirement &&
                                                                touched.requirement
                                                            }
                                                        />
                                                        <HelperText>
                                                            {errors.requirement &&
                                                            touched.requirement
                                                                ? errors.requirement
                                                                : ''}
                                                        </HelperText>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            label="Remarks"
                                                            value={
                                                                values.remarks
                                                            }
                                                            name="remarks"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            error={
                                                                errors.remarks &&
                                                                touched.remarks
                                                            }
                                                        />
                                                        <HelperText>
                                                            {errors.remarks &&
                                                            touched.remarks
                                                                ? errors.remarks
                                                                : ''}
                                                        </HelperText>
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </StyledCard>
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
            {data && (
                <CompressiveStrengthTable
                    data={data}
                    setData={setData}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    result={result}
                    setOverall={setOverall}
                    materialName={materialName}
                    overall={overall}
                />
            )}
            {isView !== true && (
                <Grid container justifyContent="end">
                    <Button
                        sx={{ boxShadow: 'none', mt: 2 }}
                        variant="contained"
                        endIcon={<AddCircle />}
                        onClick={handleOpen}
                        size="small"
                    >
                        Add
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default CompressiveStrengthModal
