import { AddCircle, Edit } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Modal,
    TextField,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'
import {
    HelperText,
    StyledCard,
    StyledIcon,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import { Fragment, useContext, useState } from 'react'
import * as Yup from 'yup'
import { valueCalc } from '../../method/method'
import ParticleFinerThan75MicronTable from './ParticleFinerThan75MicronTable'

const initialValues = {
    weight_oven_dry_sample: '',
    weight_sample_after_wash: '',
    particles_fine: '',
}
const validationSchema = Yup.object().shape({
    weight_oven_dry_sample: Yup.number().required('Required'),
    weight_sample_after_wash: Yup.number().required('Required'),
})
const ParticleFinerThan75Micron = (props) => {
    const {
        result,
        isView,
        handleCustomRequirementChange,
        handleRequirementChange,
        overall,
        setOverall,
    } = props
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [data, setData] = useState(
        result?.overall ? result?.overall : { ...initialValues }
    )
    const [initialState, setInitialState] = useState({ ...initialValues })

    const { finalData, setFinalData } = useContext(AppContext)

    const handleSubmit = (values, { resetForm }) => {
        //Formula
        let value =
            ((values.weight_oven_dry_sample - values.weight_sample_after_wash) /
                values.weight_oven_dry_sample) *
            100
        values.particles_fine = valueCalc(value)

        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName) {
                item.overall = {
                    ...values,
                    requirements: overall.requirements,
                    remarks: overall.remarks,
                    requirementStatus: overall.requirementStatus,
                    remarkStatus: overall.remarkStatus,
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData(values)
        setInitialState({ ...initialValues })
        setOpen(false)
    }
    const handleDeleteTable = () => {
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName) {
                item.overall = {
                    requirements: overall.requirements,
                    requirementStatus: overall.requirementStatus,
                    remarkStatus: overall.remarkStatus,
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData({ ...initialValues })
    }

    let length = Object.keys(result.overall).length

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <JobAccordion
                        title={result?.groupName}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        requirementvalue={overall?.requirements}
                        remarksValue={overall?.remarks}
                        overall={overall}
                    >
                        <Grid item xs={12}>
                            <Formik
                                initialValues={initialState}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {(props) => {
                                    const {
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    } = props
                                    return (
                                        <Form>
                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                            >
                                                <StyledCard>
                                                    <CardHeader
                                                        title={
                                                            result?.groupName
                                                        }
                                                    />
                                                    <CardContent
                                                        sx={{
                                                            m: 4,
                                                        }}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={4}
                                                        >
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Weight of Oven dried sample, A (g)"
                                                                    value={
                                                                        values.weight_oven_dry_sample
                                                                    }
                                                                    name="weight_oven_dry_sample"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.weight_oven_dry_sample &&
                                                                        touched.weight_oven_dry_sample
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.weight_oven_dry_sample &&
                                                                    touched.weight_oven_dry_sample
                                                                        ? errors.weight_oven_dry_sample
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Weight of sample after wash, B(g)"
                                                                    value={
                                                                        values.weight_sample_after_wash
                                                                    }
                                                                    name="weight_sample_after_wash"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.weight_sample_after_wash &&
                                                                        touched.weight_sample_after_wash
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.weight_sample_after_wash &&
                                                                    touched.weight_sample_after_wash
                                                                        ? errors.weight_sample_after_wash
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button
                                                            size="small"
                                                            onClick={
                                                                handleClose
                                                            }
                                                        >
                                                            Cancel
                                                        </Button>
                                                        {data ===
                                                        result?.overall ? (
                                                            <Button
                                                                size="small"
                                                                variant="contained"
                                                                onClick={
                                                                    handleSubmit
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                size="small"
                                                                variant="contained"
                                                                onClick={
                                                                    handleSubmit
                                                                }
                                                            >
                                                                Save
                                                            </Button>
                                                        )}
                                                    </CardActions>
                                                </StyledCard>
                                            </Modal>
                                        </Form>
                                    )
                                }}
                            </Formik>
                            {data && (
                                <ParticleFinerThan75MicronTable
                                    data={data}
                                    setData={setData}
                                    setFinalData={setFinalData}
                                    result={result}
                                    finalData={finalData}
                                />
                            )}
                            {isView !== true && (
                                <Grid container justifyContent="end">
                                    <IconButton
                                        size="small"
                                        onClick={(e) => handleDeleteTable()}
                                        sx={{ boxShadow: 'none', mt: 2 }}
                                    >
                                        <StyledIcon>delete</StyledIcon>
                                    </IconButton>
                                    {length > 4 ? (
                                        <Button
                                            sx={{ boxShadow: 'none', mt: 2 }}
                                            variant="contained"
                                            endIcon={<Edit />}
                                            onClick={handleOpen}
                                            size="small"
                                        >
                                            Edit
                                        </Button>
                                    ) : (
                                        <Button
                                            sx={{ boxShadow: 'none', mt: 2 }}
                                            variant="contained"
                                            endIcon={<AddCircle />}
                                            onClick={handleOpen}
                                            size="small"
                                        >
                                            Add
                                        </Button>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </JobAccordion>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default ParticleFinerThan75Micron
