import { StyleSheet, Text, View } from '@react-pdf/renderer'

const fontSize = 10
const labelfontfamily = 'Times-Bold'
const styles = StyleSheet.create({
    // dataContainer: {
    //     display: 'flex',
    //     flexDirection: 'row',
    //     marginTop: 1,
    //     marginBottom: 1,
    //     maxWidth: '100%',
    // },
    // value: {
    //     display: 'flex',
    //     fontSize: fontSize,
    //     flex: 0.8,
    //     marginTop: 0.5,
    //     // marginRight: 50,
    //     textTransform: 'capitalize',
    //     width: '45%',
    // },
    // label: {
    //     display: 'flex',
    //     width: '55%',
    //     marginTop: 0.5,

    //     fontSize: fontSize,
    //     flex: 0.8,

    //     fontFamily: labelfontfamily,
    // },
    dataContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 1,
        marginBottom: 1,
        maxWidth: '100%',
    },
    dataContainer1: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 2,
        maxwidth: '100%',

        justifyContent: 'space-between',
    },
    value: {
        display: 'flex',
        fontSize: fontSize,
        flex: 0.8,
        marginTop: 0.5,
        marginRight: 50,
        textTransform: 'capitalize',
        //  width: '45%',
    },
    label: {
        display: 'flex',
        width: '55%',
        marginTop: 0.5,

        fontSize: fontSize,
        flex: 0.8,

        fontFamily: labelfontfamily,
    },
    value1: {
        display: 'flex',
        fontSize: fontSize,
        marginTop: 1,
        marginRight: 10,
        flexWrap: 'wrap',
        flex: 2.9,
        //width: '70%',
    },
    label1: {
        display: 'flex',
        width: '55%',
        marginTop: 1,

        fontSize: fontSize,
        flex: 0.8,
        fontFamily: labelfontfamily,
    },
})

const CustomerDetails = ({
    data,
    details,
    jobNo,
    customerRef,
    projectDetails,
    organizationName,
    groupType,
}) => (
    <View>
        {organizationName === 'CareLab' ||
        (groupType === 'DYNAMIC_JOBCARD' && organizationName !== 'CareLab') ? (
            <>
                {/* <View style={styles.dataContainer}>
                    <Text
                        style={styles.label}
                    >{`\u2022 Customer Name & Address : `}</Text>
                    <Text style={styles.value}>
                        {data?.name} {data.address}
                    </Text>
                    <Text
                        style={styles.label}
                    >{`\u2022 Customer Reference : `}</Text>
                    <Text style={styles.value}>
                        {details?.customerReference}
                    </Text>
                </View> */}
                <View style={styles.dataContainer}>
                    <Text style={styles.label}>{`\u2022 Customer Name: `}</Text>
                    <Text style={styles.value1}>{data?.name}</Text>
                </View>
                <View style={styles.dataContainer1}>
                    <Text style={styles.label}>{`\u2022 Address : `}</Text>
                    <Text style={styles.value1}>{data.address}</Text>
                </View>
                {customerRef ? (
                    <View style={styles.dataContainer1}>
                        <Text
                            style={styles.label}
                        >{`\u2022 Customer Reference : `}</Text>
                        <Text style={styles.value1}>
                            {/* {details?.customerReference} */}
                            {customerRef}
                        </Text>
                    </View>
                ) : null}
            </>
        ) : (
            <>
                <View style={styles.dataContainer}>
                    <Text style={styles.label}>{`\u2022 Customer Name`}</Text>
                    <Text style={styles.value}>{data?.name}</Text>
                    <Text style={styles.label}>{`\u2022 Address`}</Text>
                    <Text style={styles.value}>{data?.address}</Text>
                </View>

                {projectDetails ? (
                    <View style={styles.dataContainer}>
                        <Text
                            style={styles.label1}
                        >{`\u2022 Project Details`}</Text>
                        {Array.isArray(projectDetails) ? (
                            <Text style={styles.value1}>
                                {projectDetails.join(', ')}
                            </Text>
                        ) : (
                            <Text style={styles.value1}>{projectDetails}</Text>
                        )}
                    </View>
                ) : null}
                {customerRef ? (
                    <View style={styles.dataContainer}>
                        <Text
                            style={styles.label1}
                        >{`\u2022 Customer Reference`}</Text>
                        <Text style={styles.value1}>{customerRef}</Text>
                    </View>
                ) : null}
                {/* <View style={styles.dataContainer}>
            <Text style={styles.label}>{`\u2022 Email`}</Text>
            <Text style={styles.value}>{data.email}</Text>
        </View>
        <View style={styles.dataContainer}>
            <Text style={styles.label}>{`\u2022 Address`}</Text>
            <Text style={styles.value}>{data.address}</Text>
        </View> */}
            </>
        )}
    </View>
)

export default CustomerDetails
