import { TableBody, TableCell, Box, TableHead, TableRow } from '@mui/material'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const BulkageTable = ({ data }) => {
    let bulkage

    bulkage = [
        {
            observation: 'Height of  loose sand in container, H1 (mm)',
            value: 'height_of_loose',
        },
        {
            observation:
                'Height of  Sand in container during filled with water, H2 (mm)',
            value: 'height_of_sand',
        },
        {
            observation: 'Bulkage (H1-H2)/H2)* 100',
            value: 'bulkage',
        },
    ]

    const Column = [
        { text: 'Observation', dataField: 'observation' },
        { text: 'Result', dataField: 'value' },
    ]
    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        {/* <TableCell align="center">Actions</TableCell> */}
                        <TableCell align="center">SI.No</TableCell>
                        {Column.map((item, index) => (
                            <TableCell align="center" key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                {bulkage?.map((item, index) => (
                    <TableBody>
                        <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">
                                {item.observation}
                            </TableCell>
                            <TableCell align="center">
                                {data[item.value] ? data[item.value] : 0}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ))}
            </StyledTable>
        </Box>
    )
}

export default BulkageTable
