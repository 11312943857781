import {
    Box,
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { countDecimalPlaces, valueCalc } from '../../../method/method'

const ReinforcementSteelChemicalTable = (props) => {
    let {
        data,
        dia,
        setData,
        setFinalData,
        finalData,
        result,
        setOverall,
        overall,
        handleCustomRequirementChange,
    } = props

    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData

        let avg_perc_carbon = 0
        let avg_perc_sulphur = 0
        let avg_perc_phosphorus = 0
        let avg_sulphur_phosphorus = 0
        let carbon = 0
        let sulphur = 0
        let phosphorus = 0

        _finalData.forEach((item) => {
            if (item.groupName === result.groupName) {
                if (item.data.length > 0) {
                    _data.forEach((item1) => {
                        carbon += Number(item1?.perc_carbon)
                        sulphur += Number(item1?.perc_sulphur)
                        phosphorus += Number(item1?.perc_phosphorus)
                    })
                    avg_perc_carbon = carbon / _data.length
                    avg_perc_sulphur = sulphur / _data.length
                    avg_perc_phosphorus = phosphorus / _data.length
                    avg_sulphur_phosphorus = Number(
                        avg_perc_sulphur + avg_perc_phosphorus
                    )
                    dia = _data[0]?.dia
                }
                item.overall = {
                    ...overall,
                    avg_perc_carbon: countDecimalPlaces(avg_perc_carbon),
                    avg_perc_sulphur: countDecimalPlaces(avg_perc_sulphur),
                    avg_perc_phosphorus:
                        countDecimalPlaces(avg_perc_phosphorus),
                }
                setOverall(item.overall)
                if (item.data.length > 0) {
                    handleCustomRequirementChange(dia, item.overall)
                } else {
                    handleCustomRequirementChange('', item.overall)
                }

                item.data = _data
            }
        })

        setFinalData(_finalData)
    }

    const Column = [
        { text: '% Carbon', dataField: 'perc_carbon' },
        { text: '% Sulphur', dataField: 'perc_sulphur' },
        { text: '% Phosphorus', dataField: 'perc_phosphorus' },
        {
            text: 'Sulphur + Phosphorus(%)',
            dataField: 'sulphur_phosphorus',
        },
    ]

    return (
        <Box width="100%" sx={{ overflowX: 'auto' }}>
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">S.No</TableCell>
                        <TableCell align="center">Dia/Grade</TableCell>
                        {Column.map((item, index) => (
                            <TableCell align="center" key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                        <TableCell sx={{ width: 80 }} align={'center'}>
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{dia}</TableCell>
                            {Column.map((subItem, idx) => (
                                <TableCell
                                    align="center"
                                    key={`row-${idx}-index`}
                                >
                                    {subItem.dataField !== 'sulphur_phosphorus'
                                        ? item[subItem.dataField]
                                        : Number(item.perc_sulphur) +
                                          Number(item.perc_phosphorus)}
                                </TableCell>
                            ))}
                            <TableCell align="center">
                                <Button onClick={(e) => handleDelete(index, e)}>
                                    <Icon>delete</Icon>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell align="center" colSpan={2}>
                            Mean Value
                        </TableCell>
                        <TableCell align="center">
                            {overall?.avg_perc_carbon}
                        </TableCell>
                        <TableCell align="center">
                            {overall?.avg_perc_sulphur}
                        </TableCell>
                        <TableCell align="center">
                            {overall?.avg_perc_phosphorus}
                        </TableCell>
                        <TableCell align="center">
                            {overall?.avg_sulphur_phosphorus}
                        </TableCell>
                        <TableCell align="center">-</TableCell>
                    </TableRow>
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default ReinforcementSteelChemicalTable
