import Loadable from 'app/components/Loadable/Loadable'
import React, { lazy } from 'react'
import Sample from '../clientView/Sample'
import Job from '../jobs/shared/Job'
import JobCard from '../jobs/shared/JobCard'
import JobView from '../jobs/shared/JobView'
import ReportCegil from '../report/ReportIndex'
import ReportView from '../report/ReportView'
import TestView from '../test/TestView'
import Users from '../users/Users'

import ReportIndex from '../report/ReportIndex'

const Analytics = Loadable(lazy(() => import('./Index')))

const Test = Loadable(lazy(() => import('../test/Test')))
const TestReportForm = Loadable(lazy(() => import('../test/TestReportForm')))

const dashboardRoutes = [
    {
        path: '/dashboard',
        element: <Analytics />,
    },
    {
        path: '/test',
        element: <Test />,
    },
    {
        path: '/test/add',
        element: <TestReportForm />,
    },
    {
        path: '/test/edit/:id',
        element: <TestReportForm />,
    },
    {
        path: '/test/view/:id',
        element: <TestView />,
    },
    {
        path: '/job',
        element: <Job />,
    },
    {
        path: '/job/edit',
        element: <JobCard />,
    },
    {
        path: 'job/view',
        element: <JobView />,
    },
    {
        path: '/pdf/view/:id',
        element: <ReportIndex />,
    },

    {
        path: '/test/edit/:id',
        element: <TestReportForm />,
    },

    {
        path: '/sample/add',
        element: <TestReportForm />,
    },

    {
        path: '/sample/edit/:id',
        element: <TestReportForm />,
    },
    {
        path: '/sample/view/:id',
        element: <TestView />,
    },

    {
        path: '/sample',
        element: <Sample />,
    },
    {
        path: '/report',
        element: <ReportView />,
    },
    {
        path: '/user',
        element: <Users />,
    },
]

export default dashboardRoutes
