import { StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'

const borderColor = '#ffffff'
const fontSize = 10
const labelfontfamily = 'Times-Bold'
const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        borderBottomColor: '#ffffff',
        backgroundColor: '#d9d9d9',
        borderBottomWidth: 1,
        height: 'auto',
        maxWidth: '100%',
        textAlign: 'center',
        flexGrow: 1,
        paddingTop: 1,
        fontSize: fontSize,
        fontFamily: labelfontfamily,
        justifyContent: 'space-between',
    },

    label: {
        paddingLeft: 3,

        // minWidth: '10%',
    },
    value: {
        paddingRight: '3%',
    },
    data: {
        width: '30.5%',
        paddingLeft: 3,
        paddingRight: 3,
        borderRightColor: borderColor,
        borderRightWidth: 1,

        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        paddingTop: 2,
    },
    lastData: {
        width: '30.5%',
        paddingLeft: 3,
        paddingRight: 3,
        paddingTop: 2,
        borderRightColor: '#d9d9d9',
        borderRightWidth: 1,
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
})
const DynamicFooterTable = ({ result }) => {
    return (
        <View style={styles.container}>
            <Text style={styles.label}>Fineness Modulus :</Text>
            <Text style={styles.data}>{result?.finenessModulus ?? ''}</Text>
        </View>
    )
}

export default DynamicFooterTable
