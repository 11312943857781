import { fetchMaterialData } from 'app/services/AppService'
import { MATERIAL } from './Actions'

export const getMaterialData = () => (dispatch) => {
    fetchMaterialData(
        (res) =>
            dispatch({ type: MATERIAL.SET_MATERIAL_DATA, payload: res?.data }),
        (err) => console.log(err)
    )
}
