import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Modal,
    TextField,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import { useContext, useState } from 'react'
import * as Yup from 'yup'
import { valueCalc } from '../../../method/method'
import BlockDenistyTable from '../BlockDensityTable'

const initialValues = {
    sampleId: '',
    len: '',
    breadth: '',
    height: '',
    dryWeight: '',
    volume: '',
    block_denisty: '',
}

const validationSchema = Yup.object().shape({
    len: Yup.number().required('Required'),
    breadth: Yup.number().required('Required'),
    height: Yup.number().required('Required'),
    dryWeight: Yup.number().required('Required'),
    // volume: Yup.number().required('Required'),
})
const BlockDensityModal = (props) => {
    const { result, isView, overall, setOverall } = props
    const handleClose = () => setOpen(false)
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const [data, setData] = useState(result.data ? result.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const { finalData, setFinalData } = useContext(AppContext)
    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        let _finalData = finalData
        let avg = 0
        let volume1
        const groupName = result?.groupName
        _finalData.forEach((item) => {
            if (item?.groupName === result?.groupName && arr.length !== 0) {
                arr?.forEach((item1) => {
                    item1.volume = valueCalc(
                        (item1?.len * item1?.breadth * item1?.height) /
                            1000000000,
                        groupName
                    )
                    if (Number(item1.volume) === 0.0) {
                        item1.volume = 0.0001
                    }
                    item1.block_denisty = valueCalc(
                        item1?.dryWeight / item1?.volume,
                        groupName
                    )
                    //item1.block_denisty = valueCalc( item1?.volume / 1000000000)
                    avg += Number(item1.block_denisty / arr.length)
                })
                item.overall = {
                    ...overall,
                    average: valueCalc(avg),
                }
                setOverall(item.overall)
            }
            if (item.groupName === result.groupName) {
                item.data = arr
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
    }

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props
                    return (
                        <Form>
                            <Modal open={open} onClose={handleClose}>
                                <StyledCard>
                                    <CardHeader title={result?.groupName} />
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            {/* <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Sample ID"
                                                    required
                                                    value={values.id}
                                                    name="id"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.id && touched.id
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.id && touched.id
                                                        ? errors.id
                                                        : ''}
                                                </HelperText>
                                            </Grid> */}
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Length , L (mm)"
                                                    value={values.len}
                                                    name="len"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.len &&
                                                        touched.len
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.len && touched.len
                                                        ? errors.len
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Breadth , B (mm)"
                                                    value={values.breadth}
                                                    name="breadth"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.breadth &&
                                                        touched.breadth
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.breadth &&
                                                    touched.breadth
                                                        ? errors.breadth
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Height, H (mm)"
                                                    value={values.height}
                                                    name="height"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.height &&
                                                        touched.height
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.height &&
                                                    touched.height
                                                        ? errors.height
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    value={values.dryWeight}
                                                    name="dryWeight"
                                                    label="Dry Weight, M (kg)"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.dryWeight &&
                                                        touched.dryWeight
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.dryWeight &&
                                                    touched.dryWeight
                                                        ? errors.dryWeight
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            {/* <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Volume, V (m3)"
                                                    value={values.volume}
                                                    name="volume"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.volume &&
                                                        touched.volume
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.volume &&
                                                        touched.volume
                                                        ? errors.volume
                                                        : ''}
                                                </HelperText>
                                            </Grid> */}
                                            {/* <Grid item lg={4} md={8} sm={12} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Block Density, M/V (kg/m3)"value={values.block_denisty}
                                    name="block_denisty"
                                />
                            </Grid> */}
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </StyledCard>
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
            {data && (
                <BlockDenistyTable
                    data={data}
                    setData={setData}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    result={result}
                    overall={overall}
                    setOverall={setOverall}
                />
            )}
            <Grid container justifyContent="end">
                {isView !== true && (
                    <Button
                        sx={{ boxShadow: 'none', mt: 2 }}
                        variant="contained"
                        endIcon={<AddCircle />}
                        onClick={handleOpen}
                        size="small"
                    >
                        Add
                    </Button>
                )}
            </Grid>
        </Grid>
    )
}

export default BlockDensityModal
