import {
    Button,
    Grid,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { LargeStyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const SoilCoreCutterTable = ({
    data,
    setData,
    setFinalData,
    finalData,
    result,
    setOverall,
    overall,
}) => {
    const handleDelete = (index, e) => {
        let findMax = []
        let Compaction_arr = []
        let avg = 0
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.data.length > 0) {
                findMax = _data.map(
                    (item1) =>
                        item1.dryDensity && valueCalc(item1.dryDensity) * 100
                )
                Compaction_arr = _data.map(
                    (item1) => item1.compaction && valueCalc(item1.compaction)
                )
                Compaction_arr.forEach((value) => {
                    avg += parseFloat(value) / _data.length // Assuming values are strings representing numbers
                })
            }
            if (item.groupName === result.groupName) {
                item.data = _data
                item.overall = {
                    ...overall,
                    average: avg,
                    maximunDryDensity: Math.max(...findMax),
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
    }

    const calibration = [
        //  { text: 'Container No', dataField: 'containerNo' },
        { text: 'Internal Dia of the Core, D (mm)', dataField: 'dia' },
        {
            text: 'Height of the Mould, H (mm)',
            dataField: 'heightOfMould',
        },
        {
            text: 'Weight of Core Cutter + Wet Soil, Ws (g)',
            dataField: 'weightOfCoreCutter',
        },
        {
            text: 'Weight of Core Cutter, Wc (g)',
            dataField: 'weightOfWc',
        },
        {
            text: 'Weight of Wet soil (WS-WC), Ww (g)',
            dataField: 'weightSoil',
        },
        {
            text: 'Volume of the Core Cutter, Vc (g/cc)',
            dataField: 'volumeOfCoreCutter',
        },
        {
            text: 'Bulk density of Soil, yb = (WS-WC)/Vc , Yb (g/cc)',
            dataField: 'bulkDensity',
        },
    ]
    const soilDensity = [
        { text: 'Location', dataField: 'location' },
        {
            text: 'Weight of Container with , W1 (g)',
            dataField: 'weightOfContainer',
        },

        {
            text: 'Weight of Container with and Wet Soil, W2 (g)',
            dataField: 'weightOfContainerSoil',
        },
        {
            text: 'Weight of Container with dry soil, W3 (g)',
            dataField: 'weightOfContainerDrySoil',
        },
        {
            text: 'Water Content W =[(W2-W3) / (W3-W1)] * 100, W (%)',
            dataField: 'waterContent',
        },
        {
            text: 'Dry Density, yd = 100 * yb/(100+W), yd (g/cc)',
            dataField: 'dryDensity',
        },
        {
            text: 'Maximum Dry Density, MDD (g/cc)',
            dataField: 'maximumDryDensity',
        },
        {
            text: '% of Compaction(%),(yd/MDD)*100',
            dataField: 'compaction',
        },
    ]

    return (
        <Box sx={{ width: '100%', overflowX: 'auto' }}>
            <Grid container spacing={2}>
                <Grid item>
                    <LargeStyledTable>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">S.No</TableCell>
                                {calibration.map((item, index) => (
                                    <TableCell align="center" key={index}>
                                        {item.text}
                                    </TableCell>
                                ))}
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center">
                                        {index + 1}
                                    </TableCell>
                                    {calibration.map((subItem, idx) => (
                                        <TableCell
                                            align="center"
                                            key={`row-${idx}-index`}
                                        >
                                            {isNaN(item[subItem.dataField])
                                                ? '0'
                                                : valueCalc(
                                                      item[subItem.dataField]
                                                  )}
                                        </TableCell>
                                    ))}
                                    <TableCell align="center">
                                        <Button
                                            onClick={(e) =>
                                                handleDelete(index, e)
                                            }
                                        >
                                            <Icon>delete</Icon>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </LargeStyledTable>
                </Grid>
                <Grid item>
                    <LargeStyledTable>
                        <TableHead>
                            <TableRow>
                                {/* <TableCell align="center">Container No</TableCell> */}
                                {soilDensity.map((item, index) => (
                                    <TableCell align="center" key={index}>
                                        {item.text}
                                    </TableCell>
                                ))}
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((item, index) => (
                                <TableRow key={index}>
                                    {/* <TableCell align="center">
                                            {index + 1}
                                        </TableCell> */}
                                    {soilDensity.map((subItem, idx) => (
                                        <TableCell
                                            align="center"
                                            key={`row-${idx}-index`}
                                        >
                                            {valueCalc(item[subItem.dataField])}
                                        </TableCell>
                                    ))}
                                    <TableCell align="center">
                                        <Button
                                            onClick={(e) =>
                                                handleDelete(index, e)
                                            }
                                        >
                                            <Icon>delete</Icon>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </LargeStyledTable>
                </Grid>
            </Grid>
        </Box>
    )
}

export default SoilCoreCutterTable
