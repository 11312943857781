import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { TableHeaderData } from '../TableHeading/TableHeading'
import { TableRowHeadings } from '../TableHeading/TableRowHeadings'
import TableRow from '../TableRow/TableRow'
import AdditionalDetails from './AdditionalDetails'
import TableFooter from './TableFooter'
import TableHeader from './TableHeader'
import TableTopHeader from './TableTopHeader'
import DynamicFooterTable from './DynamicFooterTable'
import {
    titleNameCheck,
    getDays,
    observationMappings,
    CBRPenetration,
    material1,
    groupName1,
    json,
} from 'app/assets/Constant/constant'
import { getNotes } from '../DetailsTable/Note'

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        //marginTop: 5,
        // paddingBottom: 20,
        marginBottom: '5%',
    },

    mainHead: {
        display: 'flex',
        width: '100%',
        alignItems: 'flex-end',
    },
})
// const type = 'observationNull';
const NON_RESULT_FIELDS = ['remarkStatus', 'requirementStatus']
const BodyTable = ({
    result,
    details,
    type,
    titleName,
    selectedResult,
    organizationName,
    groupType,
    arr1,
}) => {
    let remarksArray1 =
        result?.requirement
            ?.filter((q) => q !== undefined && q !== null && q.remark)
            ?.map((q) => q.remark)
            .join(', ') ?? ''

    if (
        type === 'observationNull' &&
        (selectedResult === 'Flakiness Index' ||
            selectedResult === 'Elongation Index' ||
            (selectedResult === 'Grain Size Analysis(0.075 to 4.75 mm)' &&
                details?.material?.name === 'Soil') ||
            (selectedResult === 'Compressive Strength' &&
                details?.material?.name === 'Fly ash') ||
            (selectedResult === 'CBR Value(Calfornia Bearing Ratio)' &&
                details?.material?.name === 'GSB Sample') ||
            (selectedResult === 'CBR Value(Calfornia Bearing Ratio)' &&
                details?.material?.name === 'WMM Sample'))
    ) {
        result.data = observationMappings[selectedResult]
     } 
     else if (
        type === 'observationNull' &&
        result.groupType === 'DYNAMIC_JOBCARD'
    ) {
        // Filter the fields to find those with field === "testParameter"
        const fieldDataArray = result?.header?.fields?.filter(f => f.field === "testParameter");
        console.log(fieldDataArray, 'field');
    
        // Initialize an empty array to collect the data
        result.data = [];
    
        // Map over the filtered array to get fieldData
        fieldDataArray.forEach(f => {
            if (f.fieldData) {
                console.log(f.fieldData.test_method)

                result.data.push(...f.fieldData);
            }
        });
    
        console.log(result.data, 'data');
    }
    
    
    // else if (
    //     type === 'observationNull' &&
    //     result.groupType === 'DYNAMIC_JOBCARD'
    // ) {
    //     result.data = result.data.map((d) => {
    //         const { result, ...rest } = d
    //         return rest
    //     })
    // }
    else if (
        type === 'observationNull' &&
        result?.requirement?.length > 0 &&
        material1?.includes(details?.material?.name) &&
        groupName1?.includes(result?.groupName) &&
        details?.material?.name !== 'Cement (Chemical)'
    ) {
        result.data = result.requirement.map((value) => {
            return {
                ...value,
                tests: value.test_params,
                req: value.requirement,
                days: value.test_params,
            }
        })
    } else if (
        type === 'observationNull' &&
        result?.gradeAndSize?.length > 0 &&
        details?.material?.name === 'GSB Sample'
    ) {
        result.data = result?.gradeAndSize?.map((value) => {
            return {
                ...value,
            }
        })
    } else if (
        type === 'observationNull' &&
        result?.requirement?.length > 0 &&
        details?.material?.name === 'Cement (Chemical)'
    ) {
        const data1 = result.requirement.map((value) => value?.requirement)

        result.data = data1?.[0]?.map((value) => {
            return {
                ...value,
                tests: value.testParams,
                req: value.requirement,
            }
        })
    } else if (
        type === 'observationNull' &&
        result.groupType !== 'DYNAMIC_JOBCARD'
    ) {
        result.data = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
    }
    if (
        type === 'observationNull' &&
        selectedResult !== 'Reinforcement Steel (Physical)' &&
        selectedResult !== 'Reinforcement Steel (Chemical)'
    ) {
        result.overall = {}
    }

    let headerKeys =
        (result.groupName === 'Compressive Strength' ||
            result.groupName === 'Water Absorption') &&
        details?.material?.name ===
            'Autoclaved Aerated Concrete Blocks (AAC Blocks)'
            ? TableHeaderData[
                  result.groupName === 'Compressive Strength'
                      ? 'Block Compressive Strength'
                      : 'Block Water Absorption'
              ]
            : TableHeaderData[result?.groupName]
    if (
        (result.groupName === 'Field Dry Density by Core Cutter Method' ||
            result?.groupName ===
                'Field Dry Density by Sand Replacement Method') &&
        (type === 'report' || type === 'reportOnly')
    ) {
        headerKeys = TableHeaderData[result?.groupName + ' Report']
    }
    if (
        (result.groupName === 'Reinforcement Steel (Physical)' ||
            result.groupName === 'Concrete Cube By ACT Method') &&
        type === 'observationNull'
    ) {
        headerKeys = TableHeaderData[result?.groupName + ' Report']
    }

    if (
        (details?.material?.name === 'WMM Sample' ||
            details?.material?.name === 'GSB Sample') &&
        result.groupName === 'CBR Value(Calfornia Bearing Ratio)' &&
        (type === 'observation' ||
            type === 'testReport' ||
            type === 'observationNull')
    ) {
        headerKeys = TableHeaderData[result?.groupName + ' Report']
    }
    let valueKeys =
        result.groupName === 'Water Absorption' &&
        details?.material?.name ===
            'Autoclaved Aerated Concrete Blocks (AAC Blocks)'
            ? TableRowHeadings['Block Water Absorption']
            : TableRowHeadings[result?.groupName]
    let ObjValue = Object.values(result?.overall || {})
    let ObjKey = Object.keys(result?.overall || {})
    // let remarks =
    //     type === 'report' || type === 'reportOnly'
    //         ? details?.reason?.length > 1
    //             ? details?.reason
    //             : result?.overall?.remarks
    //         : result?.overall?.remarks
    // let remarks =
    //     type === 'report' || type === 'reportOnly'
    //         ? details?.reason?.length > 1
    //             ? details?.reason
    //             : !(
    //                   result?.overall?.remarks !== null &&
    //                   result?.overall?.remarks !== undefined
    //               )
    //             ? result?.overall?.remarks
    //             : ''
    //         : result?.overall?.remarks ?? ''
    let remarks =
        type === 'report' || type === 'reportOnly'
            ? result?.overall?.remarks ?? ''
            : result?.overall?.remarks ?? ''

    let requirements = result?.overall?.requirements
    let requirementStatus = result?.overall?.requirementStatus
    let remarkStatus = result?.overall?.remarkStatus

    let tableRequirements = result?.requirement[0]?.requirement
    let note = getNotes[titleName]
    let overallKeys = Object.keys(result.overall ?? {}).filter(
        (el) => !NON_RESULT_FIELDS.includes(el)
    )

    if (result?.data?.length !== 0 || overallKeys?.length !== 0) {
        return (
            <>
                <View
                    style={{
                        ...styles.tableContainer,
                        marginBottom:
                            titleName == 'Reinforcement Steel (Physical)' ||
                            (titleName === 'Hardened Concrete Cube' &&
                                type === 'reportOnly') ||
                            titleName ===
                                'Hardened Concrete Cube By ACT Method' ||
                            titleName === 'Hardened Concrete (Concrete core)'
                                ? ''
                                : titleName === 'Hardened Concrete Cube' &&
                                    type === 'report'
                                  ? '5px'
                                  : titleName === 'Red Clay Bricks' &&
                                      result?.groupName ===
                                          'Bricks Compressive Strength'
                                    ? '7%'
                                    : '5%',
                    }}
                    // wrap={
                    //     type === 'report' ||
                    //     type === 'reportOnly' ||
                    //     type === 'observation' ||
                    //     type === 'testReport'
                    // }
                    // wrap={
                    //     (type === 'reportOnly' &&
                    //         titleName === 'Drinking Water (Bore Water)') ||
                    //     ['observation', 'testReport'].includes(type)
                    // }
                    wrap={
                        ((type === 'report' || type === 'reportOnly') &&
                            titleName === 'Drinking Water (Bore Water)') ||
                        (titleName === 'Hardened Concrete (Concrete core)' &&
                            (type !== 'observation' ||
                                //type === 'report' ||
                                type !== 'testReport')) ||
                        titleName === 'Admixture' ||
                        titleName === 'Cement (Chemical)' ||
                        titleName === 'Ply Wood' ||
                        titleName === 'Fly ash' ||
                        titleName === 'Reinforcement Steel (Physical)' ||
                        titleName === 'Reinforcement Steel (Chemical)' ||
                        titleName === 'Hardened Concrete Cube By ACT Method' ||
                        titleName === 'Hardened Concrete Cube' ||
                        titleName === 'Hardened Concrete Cube' ||
                        titleName === 'Runner Pipe' ||
                        titleName === 'Coupler' ||
                        titleName === 'Solid Blocks' ||
                        type === 'observation' ||
                        (type === 'observationNull' &&
                            result.groupType === 'DYNAMIC_JOBCARD') ||
                        type === 'testReport' ||
                        type === 'observationNull'
                    }
                >
                    {(type === 'report' || type === 'reportOnly') &&
                        titleNameCheck?.includes(titleName) && (
                            <TableTopHeader groupName={result?.groupName} /> // Test params heading
                        )}
                    {/* {(type === 'report' || type === 'reportOnly') &&
                        titleNameCheck?.includes(titleName) && (
                            <TableTopHeader groupName={result?.groupName} /> // Test params heading
                        )} */}

                    <TableHeader // Table Header
                        groupName={result?.groupName}
                        reportHeader={result.reportheader}
                        type={type}
                        headerKeys={headerKeys}
                        valueKeys={valueKeys}
                        gradeAndSize={result?.gradeAndSize}
                        keyAndLabel={result?.keyAndLabel}
                        titleName={titleName}
                        organizationName={organizationName}
                        groupType={result?.groupType}
                        result={result}
                        json={json}
                    />

                    {(result?.data?.length !== 0 ||
                        ObjValue?.length !== 0 ||
                        ObjKey?.length == 1) && (
                        <TableRow //Table Data
                            type={type}
                            arr1={arr1}
                            header={result?.header}
                            groupName={result?.groupName}
                            reportHeader={result.reportheader}
                            tableData={result.data}
                            headerKeys={headerKeys}
                            valueKeys={valueKeys}
                            overallData={result?.overall}
                            gradeAndSize={result?.gradeAndSize}
                            keyAndLabel={result?.keyAndLabel}
                            titleName={titleName}
                            organizationName={organizationName}
                            groupType={result?.groupType}
                            diaAndGrade={details?.diaAndGrade}
                            json={json}
                            dimension ={details?.dimension}
                        />
                    )}

                    {result?.keyAndLabel?.length !== 0 ? ( // For Sieve analysis test params
                        <>
                            {type === 'report' ||
                                (type === 'reportOnly' &&
                                    result?.gradeAndSize !== null && (
                                        <DynamicFooterTable
                                            result={result?.overall}
                                        />
                                    ))}
                        </>
                    ) : (
                        <>
                            {headerKeys ===
                                TableHeaderData[
                                    'Cube Compressive Strength'
                                ] && ( // ALL Footer
                                <TableFooter
                                    headerKeys={headerKeys}
                                    data={result?.overall?.average}
                                />
                            )}
                            {headerKeys ===
                                TableHeaderData['Compressive Strength'] && (
                                <TableFooter
                                    headerKeys={headerKeys}
                                    threeDaysAvg={result?.overall?.threeDaysAvg}
                                    sevenDaysAvg={result?.overall?.sevenDaysAvg}
                                    twentyEightDaysAvg={
                                        result?.overall?.twentyEightDaysAvg
                                    }
                                />
                            )}
                            {headerKeys ===
                                TableHeaderData['Dimension Test'] && (
                                <TableFooter
                                    headerKeys={headerKeys}
                                    avgLength={result?.overall?.avgLength}
                                    avgBreadth={result?.overall?.avgBreadth}
                                    avgHeight={result?.overall?.avgHeight}
                                    typeValue={type}
                                />
                            )}

                            {result?.overall?.average &&
                                headerKeys !==
                                    TableHeaderData[
                                        'Cube Compressive Strength'
                                    ] && (
                                    <TableFooter
                                        headerKeys={'Average'}
                                        data={result?.overall?.average}
                                        editdata={result?.overall?.editaverage}
                                        groupName={result?.groupName}
                                    />
                                )}
                            {result?.overall?.avgCompStr &&
                                headerKeys ===
                                    TableHeaderData[
                                        'Concrete Cube By ACT Method'
                                    ] && (
                                    <TableFooter
                                        headerKeys={'Average'}
                                        groupName={result?.groupName}
                                        data={result?.overall?.avgCompStr}
                                    />
                                )}
                            {(headerKeys ===
                                TableHeaderData[
                                    'Reinforcement Steel (Physical)'
                                ] ||
                                headerKeys ===
                                    TableHeaderData[
                                        'Reinforcement Steel (Physical) Report'
                                    ] ||
                                headerKeys === TableHeaderData['Coupler']) &&
                                result?.overall !== undefined && (
                                    <TableFooter
                                        type={type}
                                        headerKeys={headerKeys}
                                        mass_avg={result?.overall?.mass_avg}
                                        yeild_avg={result?.overall?.yeild_avg}
                                        uts_avg={result?.overall?.uts_avg}
                                        elongation_avg={
                                            result?.overall?.elongation_avg
                                        }
                                        ratio_avg={result?.overall?.ratio_avg}
                                    />
                                )}
                            {headerKeys ===
                                TableHeaderData['Elongation Index'] && (
                                <TableFooter
                                    headerKeys={headerKeys}
                                    data={result?.overall?.totalElongation}
                                />
                            )}
                            {headerKeys ===
                                TableHeaderData['Flakiness Index'] && (
                                <TableFooter
                                    headerKeys={headerKeys}
                                    data={result?.overall?.totalFlakiness}
                                />
                            )}
                            {headerKeys ===
                                TableHeaderData[
                                    'Coarse Aggregate Sieve Analysis'
                                ] &&
                                titleName !== 'Fine Aggregate' &&
                                details?.material?.name !==
                                    'Fine Aggregate' && (
                                    <TableFooter
                                        headerKeys={headerKeys}
                                        data={result?.overall?.finenessModulus}
                                    />
                                )}
                            {titleName === 'Fine Aggregate' && (
                                <TableFooter
                                    headerKeys={headerKeys}
                                    data={result?.overall?.finenessModulus}
                                    titleName={titleName}
                                />
                            )}
                            {(result.groupName === 'Light Compaction' ||
                                result.groupName === 'Heavy Compaction') && (
                                <TableFooter
                                    headerKeys={result.groupName}
                                    maximumWaterContent={
                                        result?.overall?.maximumWaterContent
                                    }
                                    maximunDryDensity={
                                        result?.overall?.maximunDryDensity
                                    }
                                />
                            )}

                            {/* {(result.groupName ===
                                'Field Dry Density by Core Cutter Method' ||
                                result.groupName ===
                                    'Field Dry Density by Sand Replacement Method') &&
                                (type === 'observation' ||
                                    type === 'testReport' ||
                                    type === 'observationNull') && (
                                    <TableFooter
                                        headerKeys={result.groupName}
                                        maximunDryDensity={
                                            result?.overall?.maximunDryDensity
                                        }
                                    />
                                )} */}

                            {headerKeys ===
                                TableHeaderData['Drying Shrinkage'] && (
                                <TableFooter
                                    headerKeys={headerKeys}
                                    data={result?.overall?.dryingShrinkage}
                                />
                            )}
                            {headerKeys ===
                                TableHeaderData['Moisture Content'] && (
                                <TableFooter
                                    headerKeys={headerKeys}
                                    data={result?.overall?.moistureMovement}
                                />
                            )}
                            {headerKeys ===
                                TableHeaderData['Moisture Movement'] && (
                                <TableFooter
                                    headerKeys={headerKeys}
                                    data={result?.overall?.moistureMovement}
                                />
                            )}
                            {headerKeys ===
                                TableHeaderData['Compressive  Strength'] && (
                                <TableFooter
                                    headerKeys={headerKeys}
                                    threeDaysAvg={result?.overall?.threeDaysAvg}
                                />
                            )}
                            {headerKeys ===
                                TableHeaderData[
                                    'Reinforcement Steel (Chemical)'
                                ] && (
                                <TableFooter
                                    type={type}
                                    headerKeys={headerKeys}
                                    avg_perc_carbon={
                                        result?.overall?.avg_perc_carbon
                                    }
                                    avg_perc_phosphorus={
                                        result?.overall?.avg_perc_phosphorus
                                    }
                                    avg_perc_sulphur={
                                        result?.overall?.avg_perc_sulphur
                                    }
                                    avg_sulphur_phosphorus={
                                        result?.overall?.avg_sulphur_phosphorus
                                    }
                                />
                            )}

                            {headerKeys ===
                                TableHeaderData[
                                    'Reinforcement Steel (Physical)'
                                ] && (
                                // ||
                                // headerKeys ===
                                //     TableHeaderData[
                                //         'Reinforcement Steel (Physical) Report'
                                //     ]
                                <TableFooter
                                    type={type}
                                    headerKeys={headerKeys}
                                    isRequirement={true}
                                    standard_ref={result?.overall?.standard_ref}
                                    mass_avg={
                                        tableRequirements?.find(
                                            (i) =>
                                                i?.testParams ===
                                                'Mass Per Meter'
                                        )?.limit
                                    }
                                    yeild_avg={
                                        tableRequirements?.find(
                                            (i) =>
                                                i?.testParams === 'Yield Stress'
                                        )?.limit
                                    }
                                    uts_avg={
                                        tableRequirements?.find(
                                            (i) =>
                                                i?.testParams ===
                                                'Ultimate Tensile Strenth'
                                        )?.limit
                                    }
                                    elongation_avg={
                                        tableRequirements?.find(
                                            (i) =>
                                                i?.testParams === 'Elongation'
                                        )?.limit
                                    }
                                    ratio_avg={
                                        tableRequirements?.find(
                                            (i) =>
                                                i?.testParams ===
                                                'Tensile Strength/Yield Strength'
                                        )?.limit
                                    }
                                />
                            )}

                            {headerKeys ===
                                TableHeaderData[
                                    'Reinforcement Steel (Chemical)'
                                ] && (
                                <TableFooter
                                    isRequirement={true}
                                    standard_ref={result?.overall?.standard_ref}
                                    type={type}
                                    headerKeys={headerKeys}
                                    avg_perc_carbon={
                                        tableRequirements?.find(
                                            (i) => i?.testParams === 'Carbon'
                                        )?.limit
                                    }
                                    avg_perc_phosphorus={
                                        tableRequirements?.find(
                                            (i) =>
                                                i?.testParams === 'Phosphorus'
                                        )?.limit
                                    }
                                    avg_perc_sulphur={
                                        tableRequirements?.find(
                                            (i) => i?.testParams === 'Sulphur'
                                        )?.limit
                                    }
                                    avg_sulphur_phosphorus={
                                        tableRequirements?.find(
                                            (i) =>
                                                i?.testParams ===
                                                'Sulphur & Phosphorus'
                                        )?.limit
                                    }
                                />
                            )}
                        </>
                    )}

                    {result?.groupName !==
                        'Equivalent Cube Compressive Stregnth Results of Concrete Core' && (
                        <AdditionalDetails // Notes
                            type={type}
                            note={note}
                            remarks={remarks ?? remarksArray1}
                            remarkStatus={remarkStatus}
                            requirements={requirements}
                            requirementStatus={requirementStatus}
                            groupName={result?.groupName}
                            titleName={titleName}
                        />
                    )}

                    {/* {type === 'report' && (
                    <View style={{ width: '100%', marginTop: '4px' }}>
                        <Text style={{ fontSize: 9, fontFamily: 'Times-Bold' }}>
                            *As Furnished by the customer
                        </Text>
                    </View>
                )} */}
                </View>
            </>
        )
    } else {
        return <></>
    }
}
export default BodyTable
