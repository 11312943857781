import { Font } from '@react-pdf/renderer'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import Inconsolata from '../../report/assets/Font/InconsolataFontStyle.ttf'
import { changeTestNameOnTableRow } from '../method'

Font.register({
    family: 'Inconsolata',
    src: Inconsolata,
})

const borderColor = '#d9d9d9'
const headborderColor = '#ffffff'
const paddingLeft = 3
const paddingRight = 3
const labelfontfamily = 'Times-Bold'
const reportFont = 'Inconsolata'
const fontSize = 10
const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        alignItems: 'center',
        maxWidth: '100%',
        textAlign: 'center',
        flexGrow: 1,
        // paddingTop: '2px',
        // textTransform: 'capitalize',
        fontSize: fontSize,
        border: '2px solid #d9d9d9',
        borderTop: 0,
    },
    sNo: {
        width: '10%',
        paddingLeft: paddingLeft,
        paddingRight: paddingRight,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        fontFamily: reportFont,
    },
    _sNo: {
        width: '9%',
        paddingLeft: paddingLeft,
        fontFamily: reportFont,
        paddingRight: paddingRight,

        borderRightColor: borderColor,
        borderRightWidth: 1,
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    sNo1: {
        width: '11%',
        paddingLeft: paddingLeft,
        fontFamily: reportFont,
        paddingRight: paddingRight,

        borderRightColor: borderColor,
        borderRightWidth: 1,
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    description: {
        width: '10%',
        borderRightColor: borderColor,
        textAlign: 'center',
        paddingLeft: 5,
        paddingRight: 5,
        borderRightWidth: 1,
        fontFamily: reportFont,
    },
    qty: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
        fontFamily: reportFont,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
        fontFamily: reportFont,
    },
    amount: {
        width: '15%',
        textAlign: 'center',
        paddingRight: 8,
        fontFamily: reportFont,
    },
    data: {
        width: '45%',
        fontSize: fontSize,
        paddingLeft: paddingLeft,
        fontFamily: reportFont,
        paddingRight: paddingRight,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        display: 'flex',
    },
    lastData: {
        display: 'flex',
        paddingLeft: paddingLeft,
        fontFamily: reportFont,
        paddingRight: paddingRight,
        textAlign: 'center',
        width: '45%',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    _data: {
        width: '20%',
        paddingLeft: paddingLeft,
        paddingRight: paddingRight,
        fontFamily: reportFont,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        display: 'flex',
    },
    _lastData: {
        width: '20%',
        paddingLeft: paddingLeft,
        fontFamily: reportFont,
        paddingRight: paddingRight,
        textAlign: 'center',
        wordWrap: 'break-word',
        display: 'flex',
    },
    rowData: {
        width: '80%',
        paddingTop: 3,
        paddingBottom: 3,
        border: '1px solid #d9d9d9',
        backgroundColor: '#ffffff',
        textAlign: 'center',
        fontFamily: reportFont,
    },
    rowLastData: {
        width: '20%',
        paddingTop: 3,
        paddingBottom: 3,
        border: '1px solid #d9d9d9',
        backgroundColor: '#ffffff',
        textAlign: 'center',
        fontFamily: reportFont,
    },
    trials: {
        width: '15%',
        paddingLeft: paddingLeft,
        fontFamily: reportFont,
        paddingRight: paddingRight,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    h_container: {
        flexDirection: 'row',
        borderBottomColor: '#ffffff',
        backgroundColor: '#d9d9d9',
        borderBottomWidth: 1,
        alignItems: 'center',
        border: '1px solid #d9d9d9',
        height: 'auto',
        maxWidth: '100%',
        textAlign: 'center',
        flexGrow: 1,
        fontSize: fontSize,
        fontFamily: reportFont,
    },
    h_data: {
        width: '45%',
        paddingLeft: paddingLeft,
        paddingRight: paddingRight,
        borderRightColor: headborderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        display: 'flex',
        fontFamily: reportFont,
        //fontFamily: labelfontfamily,
        fontFamily: 'Times-Bold',
    },
    h_lastData: {
        paddingLeft: paddingLeft,
        fontFamily: reportFont,
        paddingRight: paddingRight,
        textAlign: 'center',
        width: '45%',
        wordWrap: 'break-word',
        display: 'flex',
        //fontFamily: labelfontfamily,
        fontFamily: 'Times-Bold',
    },
    h_trials: {
        width: '15%',
        paddingLeft: paddingLeft,
        fontFamily: reportFont,
        //fontFamily: labelfontfamily,
        paddingRight: paddingRight,
        borderRightColor: headborderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        display: 'flex',
    },
    test_data: {
        width: '45%',
        fontFamily: reportFont,
        paddingLeft: 2,
        paddingRight: 2,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        display: 'flex',
    },
    testConducted: {
        width: '90%',
        fontFamily: reportFont,
        paddingLeft: 2,
        paddingRight: 2,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        display: 'flex',
    },
    subTestConducted: {
        width: '90%',
        fontFamily: reportFont,
        paddingLeft: 2,
        paddingRight: 2,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        display: 'flex',
    },

    //new
    data1: {
        width: '17.4%',
        paddingLeft: 19.4,
        paddingRight: 50,
        //fontFamily: reportFont,
        // borderLeftColor: borderColor,
        // borderLeftWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    lastData1: {
        width: '17.4%',
        // fontFamily: reportFont,
        borderRightColor: borderColor,
        borderRightWidth: 1.5,
        paddingLeft: 19.4,
        paddingRight: 50,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    container1: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 'auto',
        // fontFamily: reportFont,
        fontSize: fontSize,
        marginRight: -5,
        maxWidth: '100%',
        textAlign: 'center',
        flexGrow: 1,
        borderTop: 0,
    },
    sno2: {
        width: '6.6%',
        paddingLeft: 3,
        paddingRight: 3,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        fontFamily: reportFont,
    },
    data2: {
        width: '23.3%',
        paddingLeft: 3,
        paddingRight: 4,
        //fontFamily: reportFont,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    lastData2: {
        width: '23.3%',
        //fontFamily: reportFont,
        paddingLeft: 3,
        paddingRight: 4,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    data3: {
        width: '30%',
        paddingLeft: 3,
        paddingRight: 3,
        //fontFamily: reportFont,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    lastData3: {
        width: '30%',
        //fontFamily: reportFont,
        paddingLeft: 3,
        paddingRight: 4,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    container2: {
        flexDirection: 'row',
        alignItems: 'center',
        //minHeight: '10',
        height: 'auto',
        border: '2px solid #d9d9d9',
        //fontFamily: reportFont,
        // flexWrap: 'wrap',
        // wordWrap: 'break-word',
        fontSize: 9.5,
        // textTransform: 'capitalize',

        maxWidth: '100%',
        textAlign: 'center',
        flexGrow: 1,
        borderTop: 0,
    },
    json: {
        width: '25.5%',
        paddingLeft: 3,
        paddingRight: 4,
        //fontFamily: reportFont,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    _json: {
        width: '25.5%',
        //fontFamily: reportFont,
        paddingLeft: 3,
        paddingRight: 4,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    json1: {
        width: '35%',
        paddingLeft: 3,
        paddingRight: 3,
        //fontFamily: reportFont,
        // borderRightColor: borderColor,
        // borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    _json1: {
        width: '35%',
        //fontFamily: reportFont,
        paddingLeft: 3,
        paddingRight: 4,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    json2: {
        width: '17%',
        paddingLeft: 6,
        paddingRight: 43,
        //paddingTop:4,
        paddingBottom: 10,
        //fontFamily: reportFont,
        // borderLeftColor: borderColor,
        // borderLeftWidth: 1,
        borderRightColor: borderColor,
        borderRightWidth: 1.5,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    _json2: {
        width: '17%',
        // fontFamily: reportFont,
        borderRightColor: borderColor,
        borderRightWidth: 1.5,
        paddingLeft: 6,
        paddingRight: 43,
        //paddingTop:4,
        paddingBottom: 10,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    container12: {
        display: 'flex',
        flexDirection: 'row',
        // backgroundColor: '#ffffff',
        // alignItems: 'center',
        // maxWidth: '100%',
        // textAlign: 'center',
        // flexGrow: 1,
        // // paddingTop: '2px',
        // // textTransform: 'capitalize',
        // fontSize: fontSize,
        // border: '2px solid #d9d9d9',
        // borderTop: 0,
    },
    data12: {
        width: '23.3%',
        paddingLeft: 3,
        paddingRight: 4,
        //fontFamily: reportFont,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    lastData12: {
        width: '23.3%',
        //fontFamily: reportFont,
        paddingLeft: 3,
        paddingRight: 4,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
})

const TableRow = ({
    type,
    tableData,
    headerKeys,
    valueKeys,
    overallData,
    groupName,
    gradeAndSize,
    keyAndLabel,
    titleName,
    diaAndGrade,
    arr1,
    reportHeader,
    groupType,
    json,
    header,
    formulaData,
    dimension,
}) => {
    console.log(tableData,'tableData');
    
    const valueCalc = (value) => {
        return !isNaN(value) && value.toString().indexOf('.') !== -1
            ? Number(value).toFixed(
                  groupName === 'Reinforcement Steel (Chemical)' ||
                      groupName !== 'Block Density'
                      ? 3
                      : 4
              )
            : // ? Number(value).toFixed(
              //       groupName === 'Reinforcement Steel (Chemical)' ? 3 : 2
              //   )
              isNaN(value)
              ? value?.replace('/', ' / ').replace('-', '- ')
              : value == 0
                ? '0'
                : Number(value)
    }
    if (groupName === 'Cement (Chemical)')
        tableData = changeTestNameOnTableRow(tableData)
    let arr = []
    arr.push(overallData)

    if (headerKeys && !valueKeys) {
        return (
            <>
                {groupName !== 'CBR Value(Calfornia Bearing Ratio)'
                    ? tableData?.map((value, index) => {
                          let keys = Object.keys(headerKeys)
                          return (
                              <View style={styles.container} wrap={false}>
                                  {(groupName ===
                                      'Reinforcement Steel (Physical)' &&
                                      type === 'observationNull') ||
                                  groupName ===
                                      'Equivalent Cube Compressive Stregnth Results of Concrete Core' ? (
                                      <Text style={styles.sNo1}>
                                          {index + 1}.
                                      </Text>
                                  ) : groupName != 'Normal Consistency' ? (
                                      <Text
                                          style={
                                              keys.length > 4
                                                  ? styles._sNo
                                                  : styles.sNo
                                          }
                                      >
                                          {index + 1}.
                                      </Text>
                                  ) : (
                                      <Text style={styles.trials}>
                                          Trial {index + 1}
                                      </Text>
                                  )}
                                  {keys?.map((key, index) =>
                                      keys.length > 4 ? (
                                          <Text
                                              style={
                                                  index === keys.length - 1
                                                      ? styles._lastData
                                                      : styles._data
                                              }
                                              key={index}
                                          >
                                              
                                              {key === 'efflorescence' &&
                                              groupName === 'Efflorescence'
                                                  ? 'NIL'
                                                  : key === 'diaAndGrade'
                                                    ? //   &&
                                                      //       type !== 'observationNull'
                                                      valueCalc(diaAndGrade)
                                                    : key === 'dia'
                                                      ? valueCalc(dimension)
                                                      : valueCalc(value[key])
                                                        ? value[key] === ''
                                                            ? ' '
                                                            : valueCalc(
                                                                  value[key]
                                                              )
                                                        : ' '}
                                          </Text>
                                      ) : (
                                          <Text
                                              style={
                                                  index === keys.length - 1
                                                      ? styles.lastData
                                                      : styles.data
                                              }
                                              key={index}
                                          >
                                             {key === 'diaAndGrade' &&
                                              type !== 'observationNull'
                                                  ? valueCalc(diaAndGrade)
                                                  : value[key] === '0' &&
                                                      value.tests !==
                                                          'Atterberg Limit'
                                                    ? 'NIL'
                                                    : value.tests ===
                                                            'Liquid Limit' &&
                                                        key === 'tests' &&
                                                        (groupName ===
                                                            'WMM Sample' ||
                                                            groupName ===
                                                                'GSB Sample')
                                                      ? '(2a)' +
                                                        valueCalc(value[key])
                                                      : value.tests ===
                                                              'Plastic Limit' &&
                                                          key === 'tests' &&
                                                          (groupName ===
                                                              'WMM Sample' ||
                                                              groupName ===
                                                                  'GSB Sample')
                                                        ? '(2b)' +
                                                          valueCalc(value[key])
                                                        : value.tests ===
                                                                'Plasticity Index' &&
                                                            key === 'tests' &&
                                                            (groupName ===
                                                                'WMM Sample' ||
                                                                groupName ===
                                                                    'GSB Sample')
                                                          ? '(2c)' +
                                                            valueCalc(
                                                                value[key]
                                                            )
                                                          : value.tests ===
                                                                  'Atterberg Limit' &&
                                                              (groupName ===
                                                                  'WMM Sample' ||
                                                                  groupName ===
                                                                      'GSB Sample') &&
                                                              (key === 'unit' ||
                                                                  key ===
                                                                      'req' ||
                                                                  key ===
                                                                      'result')
                                                            ? ''
                                                            : value[key] === ''
                                                              ? ' '
                                                              : valueCalc(
                                                                      value[key]
                                                                  )
                                                                ? valueCalc(
                                                                      value[key]
                                                                  )
                                                                : ' '}
                                              {/* {valueCalc(value[key])
                                      ? valueCalc(value[key])
                                      : ' '} */}
                                          </Text>
                                      )
                                  )}
                              </View>
                          )
                      })
                    : (type === 'observation' ||
                            type === 'testReport' ||
                            type === 'observationNull') &&
                        groupName === 'CBR Value(Calfornia Bearing Ratio)'
                      ? tableData?.map((value, index) => {
                            let keys = Object.keys(headerKeys)
                            return (
                                <View style={styles.container} wrap={false}>
                                    {groupName != 'Normal Consistency' ? (
                                        <Text
                                            style={
                                                keys.length > 4
                                                    ? styles._sNo
                                                    : styles.sNo
                                            }
                                        >
                                            {index + 1}.
                                        </Text>
                                    ) : (
                                        <Text style={styles.trials}>
                                            Trial {index + 1}
                                        </Text>
                                    )}
                                    {keys?.map((key, index) =>
                                        keys.length > 4 ? (
                                            <Text
                                                style={
                                                    index === keys.length - 1
                                                        ? styles._lastData
                                                        : styles._data
                                                }
                                                key={index}
                                            >
                                                {valueCalc(value[key])
                                                    ? valueCalc(value[key])
                                                    : ' '}
                                            </Text>
                                        ) : (
                                            <Text
                                                style={
                                                    index === keys.length - 1
                                                        ? styles.lastData
                                                        : styles.data
                                                }
                                                key={index}
                                            >
                                                {key === 'diaAndGrade'
                                                    ? valueCalc(diaAndGrade)
                                                    : //key === 'result' &&
                                                      value[key] === '0'
                                                      ? 'NIL'
                                                      : value[key] === ''
                                                        ? ' '
                                                        : key === 'penetration'
                                                          ? value[key]
                                                          : valueCalc(
                                                                  value[key]
                                                              )
                                                            ? valueCalc(
                                                                  value[key]
                                                              )
                                                            : ' '}
                                                {/* {valueCalc(value[key])
                                      ? valueCalc(value[key])
                                      : ' '} */}
                                            </Text>
                                        )
                                    )}
                                </View>
                            )
                        })
                      : (type === 'report' || type === 'reportOnly') &&
                          groupName === 'CBR Value(Calfornia Bearing Ratio)'
                        ? arr1?.map((value, index) => {
                              let keys = Object.keys(headerKeys)
                              return (
                                  <View
                                      style={styles.container}
                                      wrap={false}
                                      key={index}
                                  >
                                      <Text style={styles.sNo}>
                                          {index + 1}.
                                      </Text>
                                      {keys?.map((key, keyIndex) => (
                                          <Text
                                              style={
                                                  keyIndex === keys.length - 1
                                                      ? styles.lastData
                                                      : styles.data
                                              }
                                              key={keyIndex}
                                          >
                                              {!(
                                                  (value.titleName ===
                                                      'WMM Sample' &&
                                                      value.description ===
                                                          'CBR value at 5.0mm penetration' &&
                                                      value[key] ===
                                                          'Min 30%') ||
                                                  (value.titleName ===
                                                      'GSB Sample' &&
                                                      value.description ===
                                                          'CBR value at 5.0mm penetration' &&
                                                      value[key] === 'Min 30%')
                                              )
                                                  ? value[key] !== undefined
                                                      ? value[key]
                                                      : ' '
                                                  : ' '}
                                              {/* {value[key] ? value[key] : ''} */}
                                          </Text>
                                      ))}
                                  </View>
                              )
                          })
                        : null}
            </>
        )
    }
    if (valueKeys) {
        let values = Object.values(valueKeys)
        let keys = Object.keys(valueKeys)

        return (
            <>
                {arr?.map((value, index) => {
                    return (
                        <>
                            <View style={styles.rowData}>
                                {values?.map((value, index) => (
                                    <View
                                        style={
                                            index === keys.length - 1
                                                ? {
                                                      textAlign: 'center',
                                                      alignItems: 'center',
                                                  }
                                                : {
                                                      borderBottom:
                                                          '1px solid #d9d9d9',
                                                      textAlign: 'center',
                                                      alignItems: 'center',
                                                  }
                                        }
                                    >
                                        <Text
                                            style={{
                                                paddingTop: 2,
                                            }}
                                            key={index}
                                        >
                                            {value
                                                ? value
                                                : type === 'observationNull'
                                                  ? '  '
                                                  : '0'}
                                        </Text>
                                    </View>
                                ))}
                            </View>

                            <View style={styles.rowLastData}>
                                {keys?.map((key, index) => (
                                    <View
                                        style={
                                            index === keys.length - 1
                                                ? {
                                                      alignContent: 'center',
                                                      justifyContent: 'center',
                                                  }
                                                : {
                                                      borderBottom:
                                                          '1px solid #d9d9d9',
                                                  }
                                        }
                                    >
                                        <Text
                                            style={{
                                                paddingTop: 2,
                                            }}
                                            key={index}
                                        >
                                            {groupName ===
                                            'Initial Setting Time'
                                                ? value[key]
                                                : groupName ===
                                                    'Final Setting Time'
                                                  ? value[key]
                                                  : value[key]
                                                    ? valueCalc(value[key])
                                                    : type === 'observationNull'
                                                      ? '  '
                                                      : '0'}
                                        </Text>
                                    </View>
                                ))}
                            </View>
                        </>
                    )
                })}
            </>
        )
    }
    if (groupName === 'Heavy Compaction' || groupName === 'Light Compaction') {
        return (
            <View>
                <View style={{ marginBottom: 10, marginTop: 10 }}>
                    <Text
                        style={{
                            marginBottom: 2,
                            fontSize: fontSize,
                            fontFamily: 'Inconsolata',
                        }}
                    >
                        Heavy Compaction - Maximum Dry Density
                    </Text>
                    <View style={styles.h_container}>
                        <Text style={styles.h_trials}>Trials</Text>
                        <Text style={styles.h_data}>
                            Weight of mould + Compacted soil g
                        </Text>
                        <Text style={styles.h_data}>
                            Weight of compacted soil g
                        </Text>
                        <Text style={styles.h_data}>Wet density , g/cm3 </Text>
                        <Text style={styles.h_lastData}>
                            Dry density , g/cm3
                        </Text>
                    </View>
                    {tableData?.map((value, index) => {
                        let keys = Object.keys(value)

                        return (
                            <>
                                <View style={styles.container}>
                                    <Text style={styles.trials}>
                                        Trial {index + 1}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.wt_mould_soil) ?? '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.wt_compact_soil) ??
                                            '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.wet_density) ?? '  '}
                                    </Text>
                                    <Text style={styles.lastData}>
                                        {valueCalc(value.dry_density) ?? '  '}
                                    </Text>
                                </View>
                            </>
                        )
                    })}
                </View>
                <View style={{ marginTop: 10, marginBottom: 10 }}>
                    <Text
                        style={{
                            marginBottom: 2,
                            fontSize: fontSize,

                            fontFamily: 'Inconsolata',
                        }}
                    >
                        Heavy Compaction - Optimum Moisture Content
                    </Text>
                    <View style={styles.h_container}>
                        <Text style={styles.h_data}>Container Number</Text>
                        <Text style={styles.h_data}>Weight of container g</Text>
                        <Text style={styles.h_data}>
                            Weight of Container + Wet soil (g)
                        </Text>
                        <Text style={styles.h_data}>
                            Weight of Container + dry soil (g)
                        </Text>
                        <Text style={styles.h_data}>Weight of water (g)</Text>
                        <Text style={styles.h_data}>
                            Weight of dry soil (g)
                        </Text>
                        <Text style={styles.h_lastData}>Water content %</Text>
                    </View>
                    {tableData?.map((value, index) => {
                        let keys = Object.keys(value)

                        return (
                            <View style={styles.container}>
                                <Text style={styles.data}>{index + 1}</Text>
                                <Text style={styles.data}>
                                    {valueCalc(value.wt_container) ?? '  '}
                                </Text>
                                <Text style={styles.data}>
                                    {valueCalc(value.wt_container_wetSoil) ??
                                        '  '}
                                </Text>
                                <Text style={styles.data}>
                                    {valueCalc(value.wt_container_drySoil) ??
                                        '  '}
                                </Text>
                                <Text style={styles.data}>
                                    {valueCalc(value.wt_water) ?? '  '}
                                </Text>
                                <Text style={styles.data}>
                                    {valueCalc(value.wt_drySoil) ?? '  '}
                                </Text>
                                <Text style={styles.lastData}>
                                    {valueCalc(value.water_content) ?? '  '}
                                </Text>
                            </View>
                        )
                    })}
                </View>
            </View>
        )
    }
    if (
        groupName === 'Field Dry Density by Core Cutter Method' &&
        (type === 'observation' ||
            type === 'observationNull' ||
            type === 'testReport')
    ) {
        return (
            <View>
                <View style={{ marginBottom: 10, marginTop: 10 }}>
                    <Text
                        style={{
                            marginBottom: 2,
                            fontSize: fontSize,
                            fontFamily: 'Inconsolata',
                        }}
                    >
                        Container 1
                    </Text>
                    <View style={styles.h_container}>
                        <Text style={styles.h_data}>S.No</Text>
                        {/* <Text style={styles.h_data}>No</Text> */}
                        <Text style={styles.h_data}>
                            Internal Dia of the Core, D (mm)
                        </Text>
                        <Text style={styles.h_data}>
                            Height of the Mould, H (mm)
                        </Text>
                        <Text style={styles.h_data}>
                            Weight of Core Cutter+ Wet Soil, Ws (g)
                        </Text>
                        <Text style={styles.h_data}>
                            Weight of Core Cutter, Wc (g)
                        </Text>
                        <Text style={styles.h_data}>
                            Weight of Wet soil , Ww (g)
                        </Text>
                        <Text style={styles.h_data}>
                            Volume of the core cutter, Vc (g/cc)
                        </Text>
                        <Text style={styles.h_data}>
                            Bulk density of Soil, , Yb (g/cc)
                        </Text>
                    </View>
                    {tableData?.map((value, index) => {
                        return (
                            <>
                                <View style={styles.container}>
                                    <Text style={styles.data}>{index + 1}</Text>
                                    {/* <Text style={styles.data}>{value.no}</Text> */}
                                    <Text style={styles.data}>
                                        {value.dia ?? '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.heightOfMould) ?? '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.weightOfCoreCutter) ??
                                            '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.weightOfWc) ?? '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.weightSoil) ?? '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.volumeOfCoreCutter) ??
                                            '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.bulkDensity) ?? '  '}
                                    </Text>
                                </View>
                            </>
                        )
                    })}
                </View>
                <View style={{ marginBottom: 10, marginTop: 10 }}>
                    <Text
                        style={{
                            marginBottom: 2,
                            fontSize: fontSize,
                            fontFamily: 'Inconsolata',
                        }}
                    >
                        Container 2
                    </Text>
                    <View style={styles.h_container}>
                        <Text style={styles.h_data}>Location</Text>
                        {/* <Text style={styles.h_data}>No</Text> */}
                        <Text style={styles.h_data}>
                            Weight of Container with , W1 (g)
                        </Text>
                        <Text style={styles.h_data}>
                            Weight of Container with and Wet Soil, W2 (g)
                        </Text>
                        <Text style={styles.h_data}>
                            Weight of Container with dry soil, W3 (g)
                        </Text>
                        <Text style={styles.h_data}>Water Content , W (%)</Text>
                        <Text style={styles.h_data}>
                            Dry Density, (Yd) g/cc
                        </Text>
                        <Text style={styles.h_data}>
                            Maximum Dry Density, MDD (g/cc)
                        </Text>
                        <Text style={styles.h_data}>% of Compaction(%)</Text>
                    </View>
                    {tableData?.map((value, index) => {
                        return (
                            <>
                                <View style={styles.container}>
                                    <Text style={styles.data}>
                                        {value.location ?? '  '}
                                    </Text>
                                    {/* <Text style={styles.data}>{value.no}</Text> */}
                                    <Text style={styles.data}>
                                        {valueCalc(value.weightOfContainer) ??
                                            '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(
                                            value.weightOfContainerSoil
                                        ) ?? '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(
                                            value.weightOfContainerDrySoil
                                        ) ?? '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.waterContent) ?? '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.dryDensity) ?? '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.maximumDryDensity) ??
                                            '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.compaction) ?? '  '}
                                    </Text>
                                </View>
                            </>
                        )
                    })}
                </View>
            </View>
        )
    }
    if (
        groupName === 'Field Dry Density by Sand Replacement Method' &&
        (type === 'observation' ||
            type === 'observationNull' ||
            type === 'testReport')
    ) {
        return (
            <View>
                <View style={{ marginBottom: 10, marginTop: 10 }}>
                    <Text
                        style={{
                            marginBottom: 2,
                            fontSize: fontSize,
                            fontFamily: 'Inconsolata',
                        }}
                    >
                        Container 1
                    </Text>
                    <View style={styles.h_container}>
                        <Text style={styles.h_data}>S.No</Text>
                        <Text style={styles.h_data}>
                            Wt of Sand + Cylinder before Poring, W1 (kg)
                        </Text>
                        <Text style={styles.h_data}>
                            Mean wt of Sand in Cone, W2 (kg)
                        </Text>
                        <Text style={styles.h_data}>
                            Mean wt of sand + Cylinder after Pouring W3, (kg)
                        </Text>
                        <Text style={styles.h_data}>
                            Wt of sand to fill calibrating Container , Kg
                        </Text>
                        <Text style={styles.h_data}>
                            Vol of Calibrating Container (V), in (ml)
                        </Text>
                        <Text style={styles.h_data}>Bulk Density of Sand</Text>
                    </View>
                    {tableData?.map((value, index) => {
                        return (
                            <>
                                <View style={styles.container}>
                                    <Text style={styles.data}>{index + 1}</Text>
                                    <Text style={styles.data}>
                                        {valueCalc(
                                            value.wt_sand_cylinder_before
                                        ) ?? '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.mean_wt_sand_cone) ??
                                            '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(
                                            value.mean_wt_sand_cylinder_after
                                        ) ?? '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.wt_sand_fill) ?? '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.vol_calibrate) ?? '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.bulk_density_1) ??
                                            '  '}
                                    </Text>
                                </View>
                            </>
                        )
                    })}
                </View>
                <View style={{ marginBottom: 10, marginTop: 10 }}>
                    <Text
                        style={{
                            marginBottom: 2,
                            fontSize: fontSize,
                            fontFamily: 'Inconsolata',
                        }}
                    >
                        Container 2
                    </Text>
                    <View style={styles.h_container}>
                        <Text style={styles.h_data}>Location</Text>
                        <Text style={styles.h_data}>
                            Wt of wet soil from hole (Ww) in kg
                        </Text>
                        <Text style={styles.h_data}>
                            Wt of Sand + Cylinder After pouring (W4) in kg
                        </Text>
                        <Text style={styles.h_data}>
                            Wt of sand in hole (kg)
                        </Text>
                        <Text style={styles.h_data}>Bulk Density, kg /m3</Text>
                        <Text style={styles.h_data}>
                            Water Content (w) percent
                        </Text>
                        <Text style={styles.h_data}>
                            Dry Density, (Yd) g/cc
                        </Text>
                        <Text style={styles.h_data}>
                            Maximum Dry Density, MDD (g/cc)
                        </Text>
                        <Text style={styles.h_data}>% of Compaction(%)</Text>
                    </View>
                    {tableData?.map((value, index) => {
                        return (
                            <>
                                <View style={styles.container}>
                                    <Text style={styles.data}>
                                        {value.location ?? '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.wt_sand_hole) ?? '  '}
                                    </Text>
                                    {/* <Text style={styles.data}>{value.weightOfContainerSoil}</Text> */}
                                    <Text style={styles.data}>
                                        {valueCalc(
                                            value.wt_sand_cylinder_after
                                        ) ?? '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.wt_sand_hole) ?? '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.bulk_density_2) ??
                                            '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.water_content) ?? '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.dry_density) ?? '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.maximumDryDensity) ??
                                            '  '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.compaction) ?? '  '}
                                    </Text>
                                </View>
                            </>
                        )
                    })}
                </View>
            </View>
        )
    }
    if (groupName === 'Ply Wood') {
        return (
            <View>
                <View style={styles.h_container} fixed>
                    <Text style={styles.h_data}>Test Conducted</Text>

                    <Text style={styles.h_data}>Test Results</Text>
                    <Text style={styles.h_lastData}>
                        Requirements as per IS:4990: 2011
                    </Text>
                </View>
                {tableData?.map((value, index) => {
                    return (
                        <>
                            {/* // {!value?.tests.includes('Bending') ? ( */}
                            {!value.tests ||
                            !value.tests.includes ||
                            !value.tests.includes('Bending') ? (
                                <View style={styles.container}>
                                    <Text
                                        style={{
                                            ...styles.data,
                                            fontFamily: 'Inconsolata',
                                        }}
                                    >
                                        {valueCalc(value.tests) ?? ' '}
                                    </Text>

                                    <Text style={styles.data}>
                                        {valueCalc(value.result) ?? ' '}
                                    </Text>
                                    <Text style={styles.data}>
                                        {valueCalc(value.req) ?? ' '}
                                    </Text>
                                </View>
                            ) : (
                                <>
                                    <View style={styles.container}>
                                        <Text style={styles.testConducted}>
                                            {value.tests}
                                        </Text>

                                        <Text
                                            style={{
                                                ...styles.data,
                                                fontFamily: 'Inconsolata',
                                            }}
                                        >
                                            Average Modulus of Elasticity
                                            (N/Sq.mm)
                                        </Text>
                                        <Text
                                            style={{
                                                ...styles.data,
                                                fontFamily: 'Inconsolata',
                                            }}
                                        >
                                            Average Modulus of Rupture (N/Sq.mm)
                                        </Text>
                                        <Text
                                            style={{
                                                ...styles.data,
                                                fontFamily: 'Inconsolata',
                                            }}
                                        >
                                            Modulus of Elasticity (N/Sq.mm)
                                        </Text>
                                        <Text
                                            style={{
                                                ...styles.data,
                                                fontFamily: 'Inconsolata',
                                            }}
                                        >
                                            Modulus of Rupture (N/Sq.mm)
                                        </Text>
                                    </View>
                                    <View style={styles.container}>
                                        <Text style={styles.subTestConducted}>
                                            1) Along (Parallel to the Grain
                                            Direction of the face Veneer)
                                        </Text>

                                        <Text style={styles.data}>
                                            {value?.elasticity_along ?? ' '}
                                        </Text>
                                        <Text style={styles.data}>
                                            {value?.rupture_along ?? ' '}
                                        </Text>
                                        <Text style={styles.data}>
                                            {value?.elasticity_along_req ?? ' '}
                                        </Text>
                                        <Text style={styles.data}>
                                            {value?.rupture_along_req ?? ' '}
                                        </Text>
                                    </View>
                                    <View style={styles.container}>
                                        <Text style={styles.subTestConducted}>
                                            2) Across(Perpendicular to the Grain
                                            direction of the face Veneer)
                                        </Text>

                                        <Text style={styles.data}>
                                            {value?.elasticity_across ?? ' '}
                                        </Text>
                                        <Text style={styles.data}>
                                            {value?.rupture_across ?? ' '}
                                        </Text>
                                        <Text style={styles.data}>
                                            {value?.elasticity_across_req ??
                                                ' '}
                                        </Text>
                                        <Text style={styles.data}>
                                            {value?.rupture_across_req ?? ' '}
                                        </Text>
                                    </View>
                                </>
                            )}
                        </>
                    )
                })}
            </View>
        )
    }
    if (
        keyAndLabel?.length !== 0 &&
        type !== 'observationNull' &&
        groupName !== 'Sieve Analysis Result on GSB Sample'
    ) {
        return (
            <>
                {tableData?.map((value, index) => {
                    return (
                        <View style={styles.container} wrap={false}>
                            {keyAndLabel?.map((key, idx) => (
                                <Text style={styles.data}>
                                    {value[key?.key]}
                                </Text>
                            ))}
                        </View>
                    )
                })}
            </>
        )
    }

    if (
        json.length !== 0 &&
        groupName === 'Sieve Analysis Result on GSB Sample'
    ) {
        return (
            <>
                {tableData?.map((value, index) => (
                    <View style={styles.container2} wrap={false} key={index}>
                        {json.map((item, fieldIndex) => {
                            if (item?.keys.length === 0) {
                                return (
                                    <Text
                                        style={
                                            fieldIndex === json.length - 1
                                                ? styles._json
                                                : styles.json
                                        }
                                        key={fieldIndex}
                                    >
                                        {value[item?.key] ?? ' '}
                                    </Text>
                                )
                            } else {
                                return (
                                    <View key={fieldIndex}>
                                        <Text
                                            style={
                                                fieldIndex === json.length - 1
                                                    ? styles._json1
                                                    : styles.json1
                                            }
                                            key={fieldIndex}
                                        >
                                            {value[item?.key] ?? ' '}
                                        </Text>
                                        <View style={styles.container1}>
                                            {item?.keys.map((item1, idx) => (
                                                <Text
                                                    key={idx}
                                                    style={
                                                        fieldIndex ===
                                                        json.length - 1
                                                            ? styles._json2
                                                            : styles.json2
                                                    }
                                                >
                                                    {value[item1?.key] ?? ' '}
                                                </Text>
                                            ))}
                                        </View>
                                    </View>
                                )
                            }
                        })}
                    </View>
                ))}
            </>
        )
    }

    if (
        keyAndLabel?.length !== 0 &&
        type === 'observationNull' &&
        groupName !== 'Sieve Analysis Result on GSB Sample'
    ) {
        return (
            <>
                {gradeAndSize?.map((value, index) => {
                    return (
                        <View style={styles.container} wrap={false}>
                            {keyAndLabel?.map((key, idx) => (
                                <Text style={styles.data}>
                                    {value[key?.key] ? value[key?.key] : ' '}
                                </Text>
                            ))}
                        </View>
                    )
                })}
            </>
        )
    }
    if (keyAndLabel?.length !== 0 && type === 'observationNull') {
        return (
            <>
                {gradeAndSize?.map((value, index) => {
                    return (
                        <View style={styles.container} wrap={false}>
                            {keyAndLabel?.map((key, idx) => (
                                <Text style={styles.data}>
                                    {value[key?.key] ? value[key?.key] : ' '}
                                </Text>
                            ))}
                        </View>
                    )
                })}
            </>
        )
    }
    // if (groupType === 'DYNAMIC_JOBCARD') {
    //     if (reportHeader?.fields.length !== 0) {
    //         return (
    //             <>
    //                 {tableData?.map((value, index) => {
    //                     return (
    //                         <View
    //                             style={styles.container}
    //                             wrap={false}
    //                             key={index}
    //                         >
    //                             <Text style={styles.data2}>
    //                                 {index + 1} {/* Serial number (1-based) */}
    //                             </Text>
    //                             {reportHeader?.fields.map((item, idx) => {
    //                                 if (item?.fields.length === 0) {
    //                                     return (
    //                                         <Text
    //                                             key={idx}
    //                                             style={styles.data2}
    //                                         >
    //                                             {value[item?.field] ?? '  '}
    //                                         </Text>
    //                                     )
    //                                 } else {
    //                                     return (
    //                                         <View key={idx}>
    //                                             <Text style={styles.data2}>
    //                                                 {value[item?.field] ?? ' '}
    //                                             </Text>
    //                                             <View style={styles.container1}>
    //                                                 {item?.fields.map(
    //                                                     (item1, innerIdx) => (
    //                                                         <Text
    //                                                             key={innerIdx}
    //                                                             style={
    //                                                                 styles.data1
    //                                                             }
    //                                                             // style={
    //                                                             //     innerIdx ===
    //                                                             //     reportHeader
    //                                                             //         ?.fields
    //                                                             //         .length -
    //                                                             //         1
    //                                                             //         ? styles.lastData1
    //                                                             //         : styles.data1
    //                                                             // }
    //                                                         >
    //                                                             {value[
    //                                                                 item1?.field
    //                                                             ] ?? ' '}
    //                                                         </Text>
    //                                                     )
    //                                                 )}
    //                                             </View>
    //                                         </View>
    //                                     )
    //                                 }
    //                             })}
    //                         </View>
    //                     )
    //                 })}
    //             </>
    //         )
    //     }
    // }

    if (groupType === 'DYNAMIC_JOBCARD') {
        if (reportHeader?.fields.length !== 0) {
            return (
                <>
                    {tableData?.map((value, index) => (
                        <>
                            <View
                                style={styles.container2}
                                wrap={false}
                                key={index}
                            >
                                <Text style={styles.sno2}>
                                    {index + 1} {/* Serial number (1-based) */}
                                </Text>
                                {reportHeader?.fields.map(
                                    (item, fieldIndex) => {
                                        if (
                                            item?.fields.length === 0
                                            // &&
                                            //item?.type !== 'NESTED'
                                        ) {
                                            return (
                                                <Text
                                                    style={
                                                        fieldIndex ===
                                                        reportHeader?.fields
                                                            .length -
                                                            1
                                                            ? styles.lastData2
                                                            : styles.data2
                                                    }
                                                    key={fieldIndex}
                                                >
                                                    {value[item?.field] ?? ' '}
                                                </Text>
                                            )
                                        } else {
                                            return (
                                                <View
                                                    key={fieldIndex}
                                                    //style={styles.container}
                                                >
                                                    <Text
                                                        style={
                                                            fieldIndex ===
                                                            reportHeader?.fields
                                                                .length -
                                                                1
                                                                ? styles.lastData3
                                                                : styles.data3
                                                        }
                                                        key={fieldIndex}
                                                    >
                                                        {value[item?.field] ??
                                                            ' '}
                                                    </Text>
                                                    <View
                                                        style={
                                                            styles.container1
                                                        }
                                                    >
                                                        {item?.fields.map(
                                                            (item1, idx) => (
                                                                <Text
                                                                    key={idx}
                                                                    style={
                                                                        fieldIndex ===
                                                                        reportHeader
                                                                            ?.fields
                                                                            .length -
                                                                            1
                                                                            ? styles.lastData1
                                                                            : styles.data1
                                                                    }
                                                                >
                                                                    {value[
                                                                        item1
                                                                            ?.field
                                                                    ] ?? ' '}
                                                                </Text>
                                                            )
                                                        )}
                                                    </View>
                                                </View>
                                            )
                                        }
                                    }
                                )}
                            </View>
                            {/* {formula && ( */}

                            {/* {formulaData.find(f=>f.testType_id === value.id ) && ( */}
                            {header.fields.map(
                                (headerField, fieldIndex) =>
                                    headerField?.testType_id === value?.id && (
                                        <View
                                            style={styles.container2}
                                            wrap={false}
                                            key={fieldIndex}
                                        >
                                            <Text style={styles.sno2}>
                                                {' '}
                                                {/* Serial number (1-based) */}
                                            </Text>

                                            {reportHeader?.fields.map(
                                                (item, fieldIndex) => {
                                                    if (
                                                        item?.fields.length ===
                                                        0
                                                        // &&
                                                        //item?.type !== 'NESTED'
                                                    ) {
                                                        return (
                                                            <Text
                                                                style={
                                                                    fieldIndex ===
                                                                    reportHeader
                                                                        ?.fields
                                                                        .length -
                                                                        1
                                                                        ? styles.lastData2
                                                                        : styles.data2
                                                                }
                                                                key={fieldIndex}
                                                            >
                                                                {item.field ===
                                                                'testParameter'
                                                                    ? headerField?.label
                                                                    : item.field ===
                                                                            'result' &&
                                                                        (type ===
                                                                            'observation' || type === 'testReport')
                                                                      ? value[
                                                                            headerField
                                                                                ?.field
                                                                        ] ?? ' '
                                                                      : ' ' ??
                                                                        ' '}
                                                            </Text>
                                                        )
                                                    } else {
                                                        return (
                                                            <View
                                                                key={fieldIndex}
                                                                //style={styles.container}
                                                            >
                                                                <Text
                                                                    style={
                                                                        fieldIndex ===
                                                                        reportHeader
                                                                            ?.fields
                                                                            .length -
                                                                            1
                                                                            ? styles.lastData3
                                                                            : styles.data3
                                                                    }
                                                                    key={
                                                                        fieldIndex
                                                                    }
                                                                >
                                                                    {value[
                                                                        item
                                                                            ?.field
                                                                    ] ?? ' '}
                                                                </Text>
                                                                <View
                                                                    style={
                                                                        styles.container1
                                                                    }
                                                                >
                                                                    {item?.fields.map(
                                                                        (
                                                                            item1,
                                                                            idx
                                                                        ) => (
                                                                            <Text
                                                                                key={
                                                                                    idx
                                                                                }
                                                                                style={
                                                                                    fieldIndex ===
                                                                                    reportHeader
                                                                                        ?.fields
                                                                                        .length -
                                                                                        1
                                                                                        ? styles.lastData1
                                                                                        : styles.data1
                                                                                }
                                                                            >
                                                                                {value[
                                                                                    item1
                                                                                        ?.field
                                                                                ] ??
                                                                                    ' '}
                                                                            </Text>
                                                                        )
                                                                    )}
                                                                </View>
                                                            </View>
                                                        )
                                                    }
                                                }
                                            )}
                                        </View>
                                    )
                            )}
                        </>
                    ))}
                </>
            )
        }
    } else {
        return (
            <>
                <Text>There is no Data to be shown !!!</Text>
            </>
        )
    }
}

export default TableRow

// const headerData = {
//     'Field Dry Density by Core Cutter Method' : {
//         dia: 'Internal Dia of the Core, D (mm)',
//         heightOfMould: 'Height of the Mould, H (mm)',

//     }
// }
