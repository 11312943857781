import {
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const UltrasonicPulseVelocityTable = ({
    data,
    setData,
    setFinalData,
    finalData,
    result,
    overall,
    setOverall,
}) => {
    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        let avg = 0

        _finalData.forEach((item) => {
            if (item.groupName === result.groupName) {
                item.data = _data
                if (item.data.length > 0) {
                    _data.forEach((item1) => {
                        let average = item1.average
                        avg += Number(average / _data.length)
                    })
                }
                item.overall = {
                    ...overall,
                    average: valueCalc(avg),
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
    }
    const Column = [
        { text: '1', dataField: '_1' },
        { text: '2', dataField: '_2' },
        { text: '3', dataField: '_3' },
        { text: '4', dataField: '_4' },
        { text: '5', dataField: '_5' },
        { text: '6', dataField: '_6' },
        { text: '7', dataField: '_7' },
        { text: '8', dataField: '_8' },
        { text: '9', dataField: '_9' },
        { text: '10', dataField: '_10' },
        { text: 'Average', dataField: 'average' },
    ]

    return (
        <>
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell rowSpan={2} colSpan={2} align={'center'}>
                            Direction
                        </TableCell>
                        <TableCell colSpan={11} align={'center'}>
                            {' '}
                            Observation
                        </TableCell>
                        <TableCell align={'center'} rowSpan={2}>
                            Actions
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        {Column.map((item, index) => (
                            <TableCell align={'center'} key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell colSpan={2} align={'center'}>
                                {item.direction}
                            </TableCell>
                            {Column.map((subItem, idx) => (
                                <TableCell
                                    align="center"
                                    key={`row-${idx}-index`}
                                >
                                    {item[subItem.dataField]}
                                </TableCell>
                            ))}
                            <TableCell align="center">
                                <Button onClick={(e) => handleDelete(index, e)}>
                                    <Icon>delete</Icon>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </>
    )
}

export default UltrasonicPulseVelocityTable
