import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Modal,
    TextField,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import { useContext, useState } from 'react'
import * as Yup from 'yup'
import { valueCalc } from '../../../method/method'
import EfflorescenceTable from '../EfflorescenceTable'

const initialValues = {
    sampleId: '',
    len: '',
    breadth: '',
    height: '',
    //final_deposition: '',
    //average_deposition: '',
    efflorescence: '',
}

const validationSchema = Yup.object().shape({
    // id: Yup.string().required('Required'),
    len: Yup.number().required('Required'),
    breadth: Yup.number().required('Required'),
    height: Yup.number().required('Required'),
    //final_deposition: Yup.number().required('Required'),
    //average_deposition: Yup.number().required('Required'),
    efflorescence: Yup.string().required('Required'),
})
const EfflorescenceModal = (props) => {
    const { result, isView, overall, setOverall } = props

    const handleClose = () => setOpen(false)
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)

    const [data, setData] = useState(result.data ? result.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })

    const { finalData, setFinalData } = useContext(AppContext)
    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        let _finalData = finalData
        let avg = 0
        _finalData.forEach((item) => {
            if (item?.groupName === result?.groupName && arr.length !== 0) {
                arr?.forEach((item1) => {
                    let averageDeposition = item1?.efflorescence
                    // avg += Number(averageDeposition / arr.length)
                })
            }
            if (item.groupName === result.groupName) {
                item.data = arr
                item.overall = {
                    ...overall,
                    // average: valueCalc(avg)
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
    }

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props
                    return (
                        <Form>
                            <Modal open={open} onClose={handleClose}>
                                <StyledCard>
                                    <CardHeader title="Efflorescence" />
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            {/* <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Sample ID"
                                                    required
                                                    value={values.id}
                                                    name="id"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.id && touched.id
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.id && touched.id
                                                        ? errors.id
                                                        : ''}
                                                </HelperText>
                                            </Grid> */}
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Length , L (mms)"
                                                    value={values.len}
                                                    name="len"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.len &&
                                                        touched.len
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.len && touched.len
                                                        ? errors.len
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Breadth , B (mms)"
                                                    value={values.breadth}
                                                    name="breadth"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.breadth &&
                                                        touched.breadth
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.breadth &&
                                                    touched.breadth
                                                        ? errors.breadth
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Height, H (mms)"
                                                    value={values.height}
                                                    name="height"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.height &&
                                                        touched.height
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.height &&
                                                    touched.height
                                                        ? errors.height
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            {/* <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Final deposition of salts after removal of immence in %"
                                                    value={values.final_deposition}
                                                    name="final_deposition"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.final_deposition &&
                                                        touched.final_deposition
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.final_deposition &&
                                                        touched.final_deposition
                                                        ? errors.final_deposition
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Average salt deposition in %"
                                                    value={values.average_deposition}
                                                    name="average_deposition"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.average_deposition &&
                                                        touched.average_deposition
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.average_deposition &&
                                                        touched.average_deposition
                                                        ? errors.average_deposition
                                                        : ''}
                                                </HelperText>
                                            </Grid> */}
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Determination of Efflorescence"
                                                    value={values.efflorescence}
                                                    name="efflorescence"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.efflorescence &&
                                                        touched.efflorescence
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.efflorescence &&
                                                    touched.efflorescence
                                                        ? errors.efflorescence
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            Add
                                        </Button>
                                    </CardActions>
                                </StyledCard>
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
            {data && (
                <EfflorescenceTable
                    data={data}
                    setData={setData}
                    setFinalData={setFinalData}
                    result={result}
                    finalData={finalData}
                    overall={overall}
                    setOverall={setOverall}
                />
            )}

            <Grid container justifyContent="end">
                {isView !== true && (
                    <Button
                        sx={{ boxShadow: 'none', mt: 2 }}
                        variant="contained"
                        endIcon={<AddCircle />}
                        onClick={handleOpen}
                        size="small"
                    >
                        Add
                    </Button>
                )}
            </Grid>
        </Grid>
    )
}

export default EfflorescenceModal
