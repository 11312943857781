import { StyleSheet, Text, View } from '@react-pdf/renderer'

import { DetailsTableHeading } from '../DetailsTableHeading/DetailsTableHeader'
import AdditionalDetails from '../TableBody/AdditionalDetails'
import TableFooter from '../TableBody/TableFooter'
import { TableHeaderData } from '../TableHeading/TableHeading'
import { TableRowHeadings } from '../TableHeading/TableRowHeadings'
import DetailsTableHeader from './DetailsTableHeader'
import DetailsTableRow from './DetailsTableRow'
import { getNotes } from './Note'

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 10,
        // paddingBottom: 40,
        // marginBottom: '10%',
        // fontSize: 11,
        // fontFamily: 'Times-Bold',
        // paddingBottom: 20,
    },

    mainHead: {
        display: 'flex',
        width: '100%',
        alignItems: 'flex-end',
    },
})

const DetailsTable = ({
    arr,
    title,
    result,
    details,
    type,
    organizationName,
}) => {
    let footerKeys = TableHeaderData[result[0]?.groupName]
    let headerKeys = DetailsTableHeading[title]
    let valueKeys = TableRowHeadings[arr?.groupName]
    let note = getNotes[title]
    // let remarks =
    //     type === 'report' || type === 'reportOnly'
    //         ? details?.reason
    //         : result[0]?.overall?.remarks ?? ''

    // let remarks =
    //     type === 'report' || type === 'reportOnly'
    //         ? //details?.reason
    //         result[0]?.overall?.remarks
    //  : ''
    let remarks =
        type === 'report' || type === 'reportOnly'
            ? result?.overall?.remarks ?? ''
            : result?.overall?.remarks ?? ''

    let requirements =
        // organizationName === 'FalconLab'
        //     ? result?.length <= 1
        //         ? result[0]?.overall?.requirements
        //         : ''
        //     : 
            result?.overall?.requirements

    let requirementStatus =
        // organizationName === 'FalconLab'
        //     ? result?.length <= 1
        //         ? result[0]?.overall?.requirementStatus
        //         : ''
        //     : 
        result?.overall?.requirementStatus

    // let remarkStatus =
    //     organizationName === 'FalconLab'
    //         ? result?.length >= 1
    //             ? result[0]?.overall?.remarkStatus
    //             : ''
    //         : result?.overall?.remarkStatus

    // let requirements =
    //     result?.length <= 1 ? result[0]?.overall?.requirements : ''

    // let requirementStatus =
    //     result?.length <= 1 ? result[0]?.overall?.requirementStatus : ''

    let remarkStatus =result?.overall?.remarkStatus
        //result?.length <= 1 ? result[0]?.overall?.remarkStatus : ''
        
        
    return (
        <View
            style={{
                ...styles.tableContainer,
                marginBottom:
                    title !== 'Reinforcement Steel (Physical)' ||
                    title !== 'Hardened Concrete Cube'
                        ? '10%'
                        : '',
            }}
        >
            <View>
                <DetailsTableHeader
                    headerKeys={headerKeys}
                    valueKeys={valueKeys}
                    result={result}
                    organizationName={organizationName}
                />
                <DetailsTableRow
                    headerKeys={headerKeys}
                    detailsData={arr}
                    result={result}
                    organizationName={organizationName}
                />
                {footerKeys ===
                    TableHeaderData['Reinforcement Steel (Physical)'] && (
                    <TableFooter
                        type={type}
                        headerKeys={footerKeys}
                        mass_avg={result[0]?.overall?.mass_avg}
                        yeild_avg={result[0]?.overall?.yeild_avg}
                        uts_avg={result[0]?.overall?.uts_avg}
                        elongation_avg={result[0]?.overall?.elongation_avg}
                        ratio_avg={result[0]?.overall?.ratio_avg}
                    />
                )}
            </View>

                <AdditionalDetails
                type={type}
                note={note}
                remarks={remarks}
                remarkStatus={remarkStatus}
                requirements={requirements}
                requirementStatus={requirementStatus}
            />
        </View>
    )
}
export default DetailsTable
