import { Font, StyleSheet, Text, View } from '@react-pdf/renderer'
import { TableHeaderData } from '../TableHeading/TableHeading'
import Inconsolata from '../../report/assets/Font/InconsolataFontStyle.ttf'

Font.register({
    family: 'Inconsolata',
    src: Inconsolata,
})

const borderColor = '#d9d9d9'
const fontSize = 10
const labelfontfamily = 'Inconsolata'

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        //minHeight: '10',
        height: 'auto',
        border: '2px solid #d9d9d9',
        fontFamily: labelfontfamily,
        // flexWrap: 'wrap',
        // wordWrap: 'break-word',
        fontSize: fontSize,
        // textTransform: 'capitalize',

        maxWidth: '100%',
        textAlign: 'center',
        flexGrow: 1,
        borderTop: 0,
    },
    sno: {
        width: '8%',
        paddingLeft: 3,
        paddingRight: 3,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        fontFamily: labelfontfamily,
    },
    data: {
        width: '30%',
        paddingLeft: 3,
        paddingRight: 3,
        fontFamily: labelfontfamily,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    lastData: {
        width: '30%',
        fontFamily: labelfontfamily,
        paddingLeft: 3,
        paddingRight: 4,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    _data: {
        width: '30%',
        paddingLeft: 3,
        paddingRight: 3,
        fontFamily: labelfontfamily,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    headerContainer: {
        flexDirection: 'row',
        backgroundColor: '#d9d9d9',
        alignItems: 'center',
        fontFamily: labelfontfamily,
        height: 'auto',
        // flexWrap: 'wrap',
        // wordWrap: 'break-word',
        fontSize: fontSize,
        maxWidth: '100%',
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    headerSno: {
        width: '8%',
        paddingLeft: 3,
        paddingRight: 3,
        borderRightColor: '#ffffff',
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        fontFamily: labelfontfamily,
    },
    headerData: {
        width: '30%',
        paddingLeft: 3,
        paddingRight: 3,
        borderRightColor: '#ffffff',
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        fontFamily: labelfontfamily,
    },
    headerLastData: {
        width: '30%',
        paddingLeft: 3,
        paddingRight: 3,
        fontFamily: labelfontfamily,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    data1: {
        width: '17.4%',
        paddingLeft: 19.4,
        paddingRight: 50,
        //fontFamily: labelfontfamily,
        // borderLeftColor: borderColor,
        // borderLeftWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    lastData1: {
        width: '17.4%',
        //fontFamily: labelfontfamily,
        borderRightColor: borderColor,
        borderRightWidth: 1.5,
        paddingLeft: 19.4,
        paddingRight: 50,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    container1: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 'auto',
        //fontFamily: labelfontfamily,
        fontSize: fontSize,
        marginRight: -5,
        maxWidth: '100%',
        textAlign: 'center',
        flexGrow: 1,
        borderTop: 0,
    },
    sno2: {
        width: '6.6%',
        paddingLeft: 3,
        paddingRight: 3,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        fontFamily: labelfontfamily,
    },
    data2: {
        width: '23.3%',
        paddingLeft: 3,
        paddingRight: 4,
        //fontFamily: labelfontfamily,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    lastData2: {
        width: '23.3%',
        //fontFamily: labelfontfamily,
        paddingLeft: 3,
        paddingRight: 4,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    container2: {
        flexDirection: 'row',
        alignItems: 'center',
        //minHeight: '10',
        height: 'auto',
        border: '2px solid #d9d9d9',
        //fontFamily: labelfontfamily,
        // flexWrap: 'wrap',
        // wordWrap: 'break-word',
        fontSize: 9.5,
        // textTransform: 'capitalize',

        maxWidth: '100%',
        textAlign: 'center',
        flexGrow: 1,
        borderTop: 0,
    },
    data3: {
        width: '30%',
        paddingLeft: 3,
        paddingRight: 3,
        //fontFamily: reportFont,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    lastData3: {
        width: '30%',
        //fontFamily: reportFont,
        paddingLeft: 3,
        paddingRight: 4,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
})

const DetailsTableRow = ({
    headerKeys,
    detailsData,
    result,
    organizationName,
}) => {
    if (detailsData) {
        let radioActiveMaterialKeys = TableHeaderData['Radioactive Material']

        let radioMaterialKey = [
            'Test Parameters',
            'Unit',
            'Test Method',
            'Results',
            'Activity  Determination Limit of the Instrument',
            'Maximum Limits as per Standard Ref',
        ]

        let biologicalAnalysisKey = [
            'Test Parameters',
            'Unit',
            'Test Method',
            'Results',
            'Requirement Limits as per Standard Ref',
            '-',
        ]

        let radioActiveMaterialKey = [
            'Test Parameters',
            'Unit',
            'Test Method',
            'Results',
            'Activity  Determination Limit of the Instrument',
            'Maximum Limits as per Standard Ref',
        ]
        if (result?.groupType === 'DYNAMIC_JOBCARD') {
            if (
                // organizationName === 'CareLab' &&
                result?.reportheader?.fields.length !== 0
            ) {
                return (
                    <>
                        {result?.data?.map((value, index) => (
                            <View
                                style={styles.container2}
                                wrap={false}
                                key={index}
                            >
                                <Text style={styles.sno2}>
                                    {index + 1} {/* Serial number (1-based) */}
                                </Text>
                                {result?.reportheader?.fields.map(
                                    (item, fieldIndex) => {
                                        if (
                                            item?.fields.length === 0
                                            // &&
                                            //item?.type !== 'NESTED'
                                        ) {
                                            return (
                                                <Text
                                                    style={
                                                        fieldIndex ===
                                                        result?.reportheader
                                                            ?.fields.length -
                                                            1
                                                            ? styles.lastData2
                                                            : styles.data2
                                                    }
                                                    key={fieldIndex}
                                                >
                                                    {value[item?.field] ?? ' '}
                                                </Text>
                                            )
                                        } else {
                                            return (
                                                <View
                                                    key={fieldIndex}
                                                    //style={styles.container}
                                                >
                                                    <Text
                                                        style={
                                                            fieldIndex ===
                                                            result?.reportheader
                                                                ?.fields
                                                                .length -
                                                                1
                                                                ? styles.lastData3
                                                                : styles.data3
                                                        }
                                                        key={fieldIndex}
                                                    >
                                                        {value[item?.field] ??
                                                            ' '}
                                                    </Text>
                                                    <View
                                                        style={
                                                            styles.container1
                                                        }
                                                    >
                                                        {item?.fields.map(
                                                            (item1, idx) => (
                                                                <Text
                                                                    key={idx}
                                                                    style={
                                                                        fieldIndex ===
                                                                        result
                                                                            ?.reportheader
                                                                            ?.fields
                                                                            .length -
                                                                            1
                                                                            ? styles.lastData1
                                                                            : styles.data1
                                                                    }
                                                                >
                                                                    {value[
                                                                        item1
                                                                            ?.field
                                                                    ] ?? ' '}
                                                                </Text>
                                                            )
                                                        )}
                                                    </View>
                                                </View>
                                            )
                                        }
                                    }
                                )}
                            </View>
                        ))}
                    </>
                )
            }
        }

        return (
            <>
                {detailsData?.map((value, index) => {
                    let indexValue = value?.index
                    let groupName = value?.groupName
                    let keys =
                        groupName === 'Radioactive Material'
                            ? Object.keys(radioActiveMaterialKeys)
                            : Object.keys(headerKeys || {})
                    return (
                        <>
                            {value?.groupName &&
                                (index === 0 ||
                                    // (index > 1 &&
                                    //     detailsData[index - 1].groupName !==
                                    //         value?.groupName)
                                    detailsData[index - 1].groupName !==
                                        value?.groupName) && (
                                    <View style={styles.container}>
                                        <Text
                                            style={{
                                                width: '100%',
                                                fontFamily: 'Times-Bold',
                                                fontWeight: '900',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {/* {value.groupName} */}
                                            {value.groupName ===
                                                'Proctor Density' ||
                                            value.groupName ===
                                                'Specific Gravity'
                                                ? value.groupName
                                                : `${value.groupName} (%)`}
                                        </Text>
                                    </View>
                                )}
                            {/* {value?.groupName &&
                                value?.groupName === '*Radio Active Material' &&
                                (index === 0 ||
                                    (index > 1 &&
                                        detailsData[index - 1].groupName !==
                                            value?.groupName)) && (
                                    <View style={styles.headerContainer}>
                                        <Text style={styles.headerSno}>
                                            S. No
                                        </Text>
                                        {radioMaterialKey?.map((key, idx) => (
                                            <Text
                                                style={
                                                    idx ===
                                                    radioMaterialKey.length - 1
                                                        ? styles?.headerLastData
                                                        : styles?.headerData
                                                }
                                            >
                                                {key}
                                            </Text>
                                        ))}
                                    </View>
                                )} */}

                            {/* {value?.groupName &&
                                value?.groupName ===
                                    'Bacteriological Analysis Results' &&
                                (index === 0 ||
                                    (index > 1 &&
                                        detailsData[index - 1].groupName !==
                                            value?.groupName)) && (
                                    <View style={styles.headerContainer}>
                                        <Text style={styles.headerSno}>
                                            S. No
                                        </Text>
                                        {biologicalAnalysisKey?.map(
                                            (key, idx) => (
                                                <Text
                                                    style={
                                                        idx ===
                                                        biologicalAnalysisKey.length -
                                                            1
                                                            ? styles?.headerLastData
                                                            : styles?.headerData
                                                    }
                                                >
                                                    {key}
                                                </Text>
                                            )
                                        )}
                                    </View>
                                )} */}
                            {/* {value?.groupName &&
                                value?.groupName === 'Radioactive Material' &&
                                (index === 0 ||
                                    (index > 1 &&
                                        detailsData[index - 1].groupName !==
                                            value?.groupName)) && (
                                    <View style={styles.headerContainer}>
                                        <Text style={styles.headerSno}>
                                            S. No
                                        </Text>
                                        {radioActiveMaterialKey?.map(
                                            (key, idx) => (
                                                <Text
                                                    style={
                                                        idx ===
                                                        radioActiveMaterialKey.length -
                                                            1
                                                            ? styles?.headerLastData
                                                            : styles?.headerData
                                                    }
                                                >
                                                    {key}
                                                </Text>
                                            )
                                        )}
                                    </View>
                                )} */}
                            <View style={styles.container} wrap={false}>
                                <Text style={styles.sno}>
                                    {indexValue?.length > 0 && indexValue
                                        ? indexValue
                                        : index + 1}
                                </Text>
                                {keys?.map((key, index) => (
                                    <Text
                                        style={
                                            indexValue?.length === 0
                                                ? styles?._data
                                                : index === keys.length - 1
                                                  ? styles?.lastData
                                                  : styles?.data
                                        }
                                    >
                                        {
                                        value[key] !== undefined 
                                        && value[key] !==null && value[key] !=='0'
                                        
                                        //!isNaN(value[key]=== 'result')
                                        ?value[key] ?? ' ':value.groupName !== 'Grain Sieve Analysis'?'-':value[key] ?? ' '}
                                        {/* {!(
                                            value.description ===
                                                '5.0mm Penetration ' &&
                                            value[key] === 'Min 30%'
                                        ) &&
                                        !(
                                            value.description ===
                                                'Plastic Limit' &&
                                            value[key] === 'Maximum 20%'
                                        ) &&
                                        !(
                                            (value.description ===
                                                'Optimum Moisture Content  Heavy Compaction (%)' ||
                                                value.description ===
                                                    'Optimum Moisture Content  Light Compaction (%)') &&
                                            value[key] === '1.50 to 2.20g/cc'
                                        )
                                            ? value[key] ?? ' '
                                            : '-'} */}
                                    </Text>
                                ))}
                            </View>
                        </>
                    )
                })}
            </>
        )
    } else {
        return (
            <>
                <Text>***There is no Data to shown***</Text>
            </>
        )
    }
}

export default DetailsTableRow
