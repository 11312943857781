import CommonDoc from './jobCards/CommonDoc/CommonDoc'

const JobCardTableDoc = ({
    result,
    materialId,
    setSnack,
    snack,
    isView,
    document,
}) => {
    return (
        <>
            <CommonDoc
                materialName={result}
                materialId={materialId}
                setSnack={setSnack}
                snack={snack}
                isView={isView}
                document={document}
            />
        </>
    )
}
export default JobCardTableDoc
