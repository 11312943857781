import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material'
import { Transition } from 'app/views/jobs/StyledComponents/StyledComponents'

const UserDialog = ({ openDialog, handleDialogClose, dialogMessage }) => {
    return (
        <>
            <Dialog open={openDialog}>
                <DialogTitle style={{ textTransform: 'capitalize' }}>
                    {dialogMessage}
                </DialogTitle>
                <DialogActions
                    style={{ justifyContent: 'center', marginTop: '-10px' }}
                >
                    <Button
                        onClick={handleDialogClose}
                        variant="contained"
                        color="primary"
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default UserDialog
