import Axios from 'app/configs/Axios'

export const fetchMaterialData = (callback, errorCallback) => {
    return Axios({
        method: 'get',
        url: 'materials',
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}

export const fetchNotificationData = (props, callback, errorCallback) => {
    return Axios({
        method: 'get',
        url: '/customerSample',
        params: {
            ...props,
        },
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}

export const clearNotificationData = (url, reqbody, callBack, error) => {
    Axios({
        method: 'patch',
        url: url,
        data: reqbody,
    })
        .then((res) => callBack(res))
        .catch((err) => {
            error(err?.response?.data)
        })
}
