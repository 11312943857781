export const resultField = {
    'Bulk Density': {
        loose_bulk: 'loose_bulk',
        rodded_bulk: 'rodded_bulk',
    },
    'Crushing Value': {
        aggregate_crushing_value: 'aggregate_crushing_value',
    },
    'Elongation Index': {
        totalElongation: 'totalElongation',
    },
    'Flakiness Index': {
        totalFlakiness: 'totalFlakiness',
    },
    'Impact Value': {
        aggregate_impact_value: 'aggregate_impact_value',
    },
    'Specific Gravity': {
        specificGravity: 'specificGravity',
    },
    'Aggregate Water Absorption': {
        waterAbsorption: 'waterAbsorption',
    },
    '10% fine value': {
        ten_per_fine_value: 'ten_per_fine_value',
    },
    'Clay lumps': {
        clay_lumps: 'clay_lumps',
    },
    'Particle finer than 75 micron': {
        particles_fine: 'particles_fine',
    },
    'Soundness test': {
        sodium_sulphate_solution: 'sodium_sulphate_solution',
    },
    'Coarse Aggregate Sieve Analysis': {
        finenessModulus: 'finenessModulus',
    },
    'Fine Aggregate Sieve Analysis': {
        finenessModulus: 'finenessModulus',
    },
    Bulkage: {
        bulkage: 'bulkage',
    },
    'Silt content': {
        silt_content: 'silt_content',
    },
    'Natural Moisture content': {
        average: 'average',
    },
    'Abrasion value': {
        average: 'average',
    },
    'Natural Moisture Content': {
        average: 'average',
    },
    //    for cement
    'Compressive Strength': {
        // avgComp_st: 'avgComp_st',
        threeDaysAvg: 'threeDaysAvg',
        sevenDaysAvg: 'sevenDaysAvg',
        twentyEightDaysAvg: 'twentyEightDaysAvg',
    },
    'Fineness by Dry Sieving %': {
        Fineness_in_per: 'Fineness_in_per',
    },
    'Initial Setting Time': {
        initialSettingTime: 'initialSettingTime',
    },
    'Final Setting Time': {
        finalSettingTime: 'finalSettingTime',
    },
    'Normal Consistency': {
        average: 'average',
    },
    'Soundness by Le Chatlier method': {
        total_measurement: 'total_measurement',
    },
    'Specific gravity by Le Chatlier flask': {
        specificGravity: 'specificGravity',
    },
    'Moisture content': {
        moisture_content: 'moisture_content',
    },
    'Fineness by Dry Sieving': {
        particles_retained_on_45mic: 'particles_retained_on_45mic',
    },
    // paver block for Bricks-----------
    'Dimension Test': {
        avgLength: 'avgLength',
        avgHeight: 'avgHeight',
        avgBreadth: 'avgBreadth',
    },
    //  paver blocks------------
    'Block Compressive Strength': {
        average: 'average',
    },

    'Block Water Absorption': {
        average: 'average',
    },
    'AAC Block Density': {
        average: 'average',
    },
    // Bricks --------------
    'Bricks Compressive Strength': {
        average: 'average',
    },
    'Bricks Water Absorption': {
        average: 'average',
    },
    // Bricks  & pre casted
    Efflorescence: {
        average: 'average',
    },
    'Drying Shrinkage': {
        dryingShrinkage: 'dryingShrinkage',
    },
    'Moisture Content': {
        moistureMovement: 'moistureMovement',
    },
    // hardened concrete-----
    'Rebound Hammer': {
        average: 'average',
    },
    'Ultrasonic Pulse Velocity': {
        average: 'average',
    },
    'Cube Compressive Strength': {
        average: 'average',
    },
    // soil ------------
    'California Bearing Ratio': {
        result_for_2_5: 'result_for_2_5',
        result_for_5_0: 'result_for_5_0',
    },
    'Free Swell Index': {
        average: 'average',
    },
    'Grain Size Analysis(0.075 to 4.75 mm)': {
        gravel: 'gravel',
        coarseSand: 'coarseSand',
        mediumSand: 'mediumSand',
        fineSand: 'fineSand',
        silt: 'silt',
    },
    'Heavy Compaction': {
        maximunDryDensity: 'maximunDryDensity',
        maximumWaterContent: 'maximumWaterContent',
    },
    'Light Compaction': {
        maximumWaterContent: 'maximumWaterContent',
        maximunDryDensity: 'maximunDryDensity',
    },
    'Liquid Limit': {
        average: 'average',
    },
    'Soil Natural Moisture Content': {
        average: 'average',
    },
    'Plastic Limit': {
        plastic_limit: 'plastic_limit',
    },
    'Soil Specific Gravity': {
        total_weight: 'total_weight',
    },
    //  Carbonation test--------
    'Carbonation test': {
        average: 'average',
    },
    'Field Dry Density by Core Cutter Method': {
        maxDryDensity: 'maxDryDensity',
    },
    // Crack width measurement ------
    'Crack width measurement': {
        crackVelocity: 'crackVelocity',
        nonCrackVelocity: 'nonCrackVelocity',
    },
    'Half-Cell Potential difference test': {
        average: 'average',
    },
    // Slump ---------------
    Slump: {
        slump: 'slump',
    },
    // soils
    'Shrinkage limit': {
        average: 'average',
    },
    'Core Compressive Strength': {
        average: 'average',
    },
    // soil core
    'Field Dry Density by Sand Replacement Method': {
        maximunDryDensity: 'maximunDryDensity',
    },
    'Construction Water': {
        pH: 'pH @ 25⁰C',
        total_suspended_matter: 'Total Suspended Matter',
        organic_solids: 'Organic Solids @ 550◦C',
        inOrganic_solids: 'Inorganic Solids @ 180◦C',
        chloride: 'Chloride as Cl',
        sulphate: 'Sulphate as SO(4)',
        neutralize_mixed_indicator:
            'To neutralize 100ml of sample water using Mixed Indicator using 0.02N H(2)SO(4)',
        neutralize_phonolphthalein_indicator:
            'To neutralize 100ml of sample water using Phonolphthalein as Indicator using 0.02N NaOH',
    },
    'CBR Value(Calfornia Bearing Ratio)': {
        result_for_2_5: 'result_for_2_5',
        result_for_5_0: 'result_for_5_0',
    },
}
