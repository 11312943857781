import { Defs, G, Svg, Path, ClipPath, Line, Rect } from '@react-pdf/renderer'

function HeaderDesign() {
    return (
        <Svg
            width="548"
            height="29"
            viewBox="0 0 548 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
                d="M32.2775 28.6911H0V0H116.199V12.1937H40.1675L32.2775 28.6911Z"
                fill="#E85253"
            />
            <Path
                d="M116.199 12.1937V0H530.785L497.073 28.6911H196.534L187.927 12.0805L116.199 12.1937Z"
                fill="#01B0F1"
            />
            <Path
                d="M548 0H530.068L497.073 28.6911H516.44L548 0Z"
                fill="#5CD7FF"
            />
        </Svg>
    )
}

export default HeaderDesign
