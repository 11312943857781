import React from 'react'
import { Store } from './redux/Store'
import { Provider } from 'react-redux'
import rootRoutes from './RootRoutes'
import { MatxTheme } from 'app/components'
import { useRoutes } from 'react-router-dom'
import { AuthProvider } from 'app/contexts/JWTAuthContext'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import { AppContext } from 'app/contexts/AppContext'
import 'app/assets/index.css'
import IdleTimer from 'react-idle-timer'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'

function App() {
    const [statusData, setStatusData] = React.useState()
    const [EditDetails, setEditDetails] = React.useState([])
    const [Flakiness, setFlakiness] = React.useState([])
    const [Elongation, setElongation] = React.useState([])
    const [CompressiveStrength, setCompressiveStrength] = React.useState([])
    const [GroupName, setGroupName] = React.useState()
    const [WaterAbsorption, setWaterAbsorption] = React.useState([])
    const [ReinforcementSteel, setReinforcementSteel] = React.useState([])
    const [ConcreteCube, setConcreteCube] = React.useState([])
    const [SOIL, setSOIL] = React.useState([])
    const [role, setRole] = React.useState()
    const [finalData, setFinalData] = React.useState([])
    const [GroupType, setGroupType] = React.useState()

    let value = {
        EditDetails,
        setEditDetails,
        Elongation,
        setElongation,
        Flakiness,
        setFlakiness,
        CompressiveStrength,
        setCompressiveStrength,
        WaterAbsorption,
        setWaterAbsorption,
        ReinforcementSteel,
        setReinforcementSteel,
        ConcreteCube,
        setConcreteCube,
        SOIL,
        setSOIL,
        role,
        setRole,
        finalData,
        setFinalData,
        GroupName,
        setGroupName,
        statusData,
        setStatusData,
        GroupType,
        setGroupType,
    }

    const navigate = useNavigate()
    const handleIdle = () => {
        const pathname = window.location.pathname
        if (
            pathname === '/session/signin' ||
            pathname === '/session/signin-all'
        ) {
            window.location.reload()
        } else {
            const request_type = Cookies.get('request_type')
            Cookies.remove('token')
            Cookies.remove('userRole')
            Cookies.remove('userId')
            Cookies.remove('organizations')
            Cookies.remove('userName')
            Cookies.remove('request_type')
            if (request_type === 'ALL') {
                navigate('/session/signin-all')
            } else {
                navigate('/session/signin')
            }
        }
        return false
    }

    const routes = useRoutes(rootRoutes)
    return (
        <AppContext.Provider value={value}>
            <Provider store={Store}>
                <SettingsProvider>
                    <MatxTheme>
                        <AuthProvider>{routes}</AuthProvider>
                        <IdleTimer
                            timeout={1000 * 60 * 60}
                            onIdle={handleIdle}
                        />
                    </MatxTheme>
                </SettingsProvider>
            </Provider>
        </AppContext.Provider>
    )
}

export default App
