import {
    Button,
    Grid,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { Box } from '@mui/system'
import { LargeStyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const ShrinkageLimitTable = ({
    data,
    setData,
    setFinalData,
    finalData,
    result,
    setOverall,
    overall,
}) => {
    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        let shrinkageAvg = 0
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && _data.length !== 0) {
                _data.forEach((item1) => {
                    let value = valueCalc(
                        item1.moisture_content -
                            ((item1.initial_volume - item1.vol_dry_cake) /
                                (item1.wt_container_dry_soil -
                                    item1.wt_container)) *
                                100
                    )
                    shrinkageAvg += Number(value / _data.length)
                })
            }
            if (item.groupName === result.groupName) {
                item.data = _data
                item.overall = {
                    ...overall,
                    average: valueCalc(shrinkageAvg),
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
    }

    const shrinkage = [
        { text: 'Weight of Container W1, (g)', dataField: 'wt_container' },
        {
            text: 'Weight of Container + Wet Soil W2, (g)',
            dataField: 'wt_container_wet_soil',
        },
        {
            text: 'Weight of Container + Dry Soil W3, (g)',
            dataField: 'wt_container_dry_soil',
        },

        {
            text: 'Moisture Content W = (W2-W3)/(W3-W1)*100, (g)',
            dataField: 'moisture_content',
        },
        {
            text: 'Weight of Shrinkage Dish W4, (g)',
            dataField: 'wt_shrinkage_dish',
        },
        {
            text: 'Weight of Mercury + Shrinkage Dish W5, (g)',
            dataField: 'wt_mercury_shrinkage_dish',
        },
        {
            text: 'Weight of Mercury W6 =(W5-W4), (g)',
            dataField: 'wt_mercury',
        },
        {
            text: 'Initial Volume of Shrinkage Dish Vi = (W6/13.53g/cm³), (cm³)',
            dataField: 'initial_volume',
        },
        {
            text: 'Weight of Mercury displaced by the dry cake + weight of shrinkage dish W7, (g)',
            dataField: 'wt_mercrury_plus_shrinkage_dish',
        },
        {
            text: 'Volume of dry cake Vf = (W5-W7)/13.53g/cm³, (cm³) ',
            dataField: 'vol_dry_cake',
        },
        {
            text: 'Shrinkage Limit ((W-(Vi-Vf)/(W3-W1)*100) S, %',
            dataField: 'shrinkage_limit',
        },
    ]

    return (
        <Box sx={{ width: '100%', overflowX: 'auto' }}>
            <Grid container spacing={2}>
                <Grid item>
                    <LargeStyledTable>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">S.No</TableCell>
                                {shrinkage.map((item, index) => (
                                    <TableCell align="center" key={index}>
                                        {item.text}
                                    </TableCell>
                                ))}
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center">
                                        {index + 1}
                                    </TableCell>
                                    {shrinkage.map((subItem, idx) => (
                                        <TableCell
                                            align="center"
                                            key={`row-${idx}-index`}
                                        >
                                            {valueCalc(item[subItem.dataField])}
                                        </TableCell>
                                    ))}
                                    <TableCell align="center">
                                        <Button
                                            onClick={(e) =>
                                                handleDelete(index, e)
                                            }
                                        >
                                            <Icon>delete</Icon>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </LargeStyledTable>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ShrinkageLimitTable
