import { Button, Grid, Icon, IconButton } from '@mui/material'
import { ContentBox } from 'app/assets/styled-components/SharedComponents'
import { Breadcrumb, MatxLoading } from 'app/components'
import { AppContext } from 'app/contexts/AppContext'
import {
    getJobView,
    getRequirements,
    saveJobDetails,
} from 'app/redux/actions/JobActions'
import Cookies from 'js-cookie'
import { Fragment, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SnackBar from '../../../components/SnackBar'
import InvoiceDetails from './InvoiceDetails'
import JobCardTable from './jobCards'
import JobDetails from './JobDetails'
import JobCardTableDoc from './JobCardTableDoc'
import { getDocReport } from 'app/assets/Constant/constant'

const initialValues = {
    invoice_no: '',
    status: '',
    date: '',
    paymentPaid: '',
    paymentGP: '',
    startedOn: '',
    completedOn: '',
    invoiceStatus: 'show',
}

function JobEdit(props) {
    const {
        EditDetails,
        setEditDetails,
        GroupName,
        setGroupName,
        finalData,
        setFinalData,
        statusData,
        setStatusData,
        GroupType,
        setGroupType,
    } = useContext(AppContext)

    let organizationName = Cookies.get('organizationName')
    const navigate = useNavigate()
    const [initialState, setInitialState] = useState({ ...initialValues })
    const [reason, setReason] = useState('')
    const [standard_ref, setStandard_ref] = useState('')
    const userRole = Cookies.get('userRole')
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [viewStatus, setViewStatus] = useState(true)
    const [snack, setSnack] = useState({
        snackOpen: false,
        snackVaraint: '',
        snackMsg: '',
    })

    const [invoiceData, setInvoiceData] = useState({
        invoice_no: '',
        status: '',
        date: '',
        paymentPaid: '',
        paymentGP: '',
        startedOn: '',
        completedOn: '',
        invoiceStatus: 'show',
    })

    const [additionalDatas, setAdditionalDatas] = useState()

    EditDetails?.data?.result?.map((value, idx) => {
        setGroupName(value.groupName)
        setGroupType(value.groupType)
    })
    const materialName = EditDetails?.data?.material?.name

    const remarksValue = EditDetails?.data?.remark

    let result = []
    result.push({ groupName: GroupName, data: finalData, groupType: GroupType })
    let payLoad = {
        result: finalData,
        status: statusData,
        invoice_id: invoiceData,
        reason: reason ? reason : ' ',
        standard_ref: standard_ref ? standard_ref : '',
    }
    let _finalData = finalData
    let _status = true
    const checkStatus = () => {
        if (
            statusData === 'COMPLETE' ||
            statusData === 'PAID' ||
            statusData === 'APPROVED'
        ) {
            _finalData?.forEach((item) => {
                let obj = Object?.values(item?.overall || {})
                if (
                    item?.data?.length === 0 &&
                    obj?.length === 0 &&
                    item?.data != []
                ) {
                    _status = false
                }
            })
        }
        if (statusData === 'COMPLETE' && reason?.trim().length === 0) {
            _status = false
        }
    }

    const onhandleSave = () => {
        // Check if invoice_id contains startedOn and completedOn values
        if (
            (statusData === 'COMPLETE' &&
                payLoad.invoice_id &&
                payLoad.invoice_id.startedOn &&
                payLoad.invoice_id.completedOn) ||
            statusData !== 'COMPLETE'
        ) {
            setLoading(true)
            saveJobDetails(
                params.id,
                payLoad,
                (res) => {
                    let msg = res?.data?.message
                    if (res.status === 200) {
                        setLoading(false)
                        setSnack({
                            ...snack,
                            snackOpen: 'true',
                            snackMsg: msg,
                            snackVaraint: 'success',
                        })
                        setTimeout(() => {
                            navigate(-1)
                        }, 500)
                    } else {
                        setStatusData('TODO')
                        setLoading(false)

                        setSnack({
                            ...snack,
                            snackOpen: 'false',
                            snackMsg: msg,
                            snackVaraint: 'error',
                        })
                    }
                },
                (error) => {
                    setLoading(false)

                    console.log(error?.message)
                    let msg
                    if (error?.message) {
                        msg = error.message
                    }
                    setSnack({
                        ...snack,
                        snackOpen: 'false',
                        snackMsg: msg ? msg : 'Something went wrong',
                        snackVaraint: 'error',
                    })
                }
            )
        } else {
            setSnack({
                ...snack,
                snackOpen: 'false',
                snackMsg:
                    'Please Select Test Started On & Test Completed On Date',
                snackVaraint: 'error',
            })
        }
    }

    // const onhandleSave = () => {
    //     // checkStatus()
    //     // if (_status) {
    //     setLoading(true)
    //     saveJobDetails(
    //         params.id,
    //         payLoad,
    //         (res) => {
    //             let msg = res?.data?.message
    //             if (res.status === 200) {
    //                 setLoading(false)
    //                 setSnack({
    //                     ...snack,
    //                     snackOpen: 'true',
    //                     snackMsg: msg,
    //                     snackVaraint: 'success',
    //                 })
    //                 setTimeout(() => {
    //                     navigate(-1)
    //                 }, 500)
    //             } else {
    //                 setStatusData('TODO')
    //                 setLoading(false)

    //                 setSnack({
    //                     ...snack,
    //                     snackOpen: 'false',
    //                     snackMsg: msg,
    //                     snackVaraint: 'error',
    //                 })
    //             }
    //         },
    //         (error) => {
    //             setLoading(false)

    //             console.log(error?.message)
    //             let msg
    //             if (error?.message) {
    //                 msg = error.message
    //             }
    //             setSnack({
    //                 ...snack,
    //                 snackOpen: 'false',
    //                 snackMsg: msg ? msg : 'Something went wrong',
    //                 snackVaraint: 'error',
    //             })
    //         }
    //     )

    //     // } else {
    //     //     setSnack({
    //     //         ...snack,
    //     //         snackOpen: 'false',
    //     //         snackMsg: 'Please Check all Field Before Complete',
    //     //         snackVaraint: 'error'
    //     //     })
    //     // }
    // }

    const fetchRequiremntsData = (materialId) => {
        getRequirements(materialId, (res) => {
            console.log(res)
            setAdditionalDatas(res)
        })
    }

    useEffect(() => {
        getJobView(params.id, (res) => {
            
            // Conflict

            let job = {...res}
            setInvoiceData(res?.data?.invoice_id ? { ...invoiceData, ...res?.data?.invoice_id } : initialValues)
            console.log("res?.data?.result === ", res?.data?.result)
            if (res?.data?.result) {
                let newResult = res?.data?.result.map(result => {
                    if (
                        result.groupType === "DYNAMIC_JOBCARD" &&
                        result.data.length === 0
                    ) {
                        const fieldData = result.header?.fields?.[0]?.fieldData?.map(row => {
                            let currentRow = {...row}
                            for (let key in currentRow) {
                                if (!key.endsWith("_options") && Array.isArray(currentRow[key])) {
                                    currentRow[key + "_options"] = [...currentRow[key]]
                                    currentRow[key] = currentRow[key][0]
                                }
                            }
                            return currentRow
                        });
                        console.log("fieldData === ",fieldData)
                        // Assuming setFinalData is a function that takes an argument
                        return {
                            ...result,
                            data: fieldData
                        }
                    }
                    return result
                })
                console.log("newResult === ",newResult)
                setFinalData(newResult)
                job = {
                    ...job,
                    data: {
                        ...job.data,
                        result: newResult
                    }
                }
            }
            setEditDetails(job)
            
            
            setReason(res?.data?.reason)
            // // let standard_ref_Overall = res?.data?.result[0]?.overall?.standard_ref;
            let standard_ref_Overall = res?.data?.material?.name === 'Reinforcement Steel (Physical)' ?
                res?.data?.result?.[0]?.standard_ref : res?.data?.result?.[0]?.overall?.standard_ref;
            
            let standard_ref_test_type2 = res?.data?.test_type.map(input => input.standard_ref)?.filter(ref => ref !== '').join(', ');
            
            let standard_ref_test_type_6 = Array.from(new Set(standard_ref_test_type2.split(', '))).join(', ')
            console.log(standard_ref_test_type_6,'999');
            
            // const res16 = res?.data?.result?.flatMap(m =>
            //     m?.header?.fields[0]?.fieldData?.flatMap(f =>
            //         Array.isArray(f?.test_method) ? f?.test_method?.join(', ') : []
            //     )
            // );

            const res16 = res?.data?.result?.flatMap(m =>
                m?.header?.fields[0]?.fieldData?.flatMap(f =>
                    Array.isArray(f?.test_method) ? f?.test_method.filter(method => method !== '') : []
                )
            );
            
            const resultString = res16.join(', ');
            
            let standard_ref;
            if (organizationName === 'FalconLab'){
                if (res.data && res.data.standard_ref) {
                    standard_ref = res.data.standard_ref;
                } else if (res.data && res.data.material && res.data.material.standard_ref) {
                    standard_ref = res.data.material.standard_ref;
                } else if (standard_ref_Overall) {
                    standard_ref = standard_ref_Overall;
                } else if (resultString && res?.data?.result?.[0]?.groupType === 'DYNAMIC_JOBCARD'){
                    standard_ref = resultString;
                }
                else {
                    standard_ref = standard_ref_test_type_6;
                }
            } else {
                if(res.data && res.data.standard_ref){
                    standard_ref = res.data.standard_ref;
                }
                else{
                    standard_ref = resultString;
            
                }
            }
            setStandard_ref(standard_ref)
            setLoading(false)
        })
    }, [])

    EditDetails?.data?.result?.map((value, idx) => {
        setGroupName(value.groupName)
        setGroupType(value.groupType)
    })
    const handleChange = (event) => {
        setInvoiceData({
            ...invoiceData,
            [event.target.name]: event.target.value,
        })
    }

    // const onhandleDate = (newValue) => {
    //     setInvoiceData({ ...invoiceData, date: newValue })
    // }

    const onhandleReason = (e) => {
        setReason(e.target.value)
    }
    const onhandleStandard_ref = (e) => {
        setStandard_ref(e.target.value)
    }
    // const handlePublish = () => {
    //     publishJobByID(params.id).then((res, data) => {
    //         let msg = res?.data.message

    //         setDialog(false)

    //         if (res.status === 200) {

    //             publishStatus === true ? publishStatus = false : publishStatus = true
    //             setSnack({
    //                 ...snack,
    //                 snackOpen: 'true',
    //                 snackMsg: msg,
    //                 snackVaraint: 'success',
    //             })

    //         } else {
    //             setSnack({
    //                 ...snack,
    //                 snackOpen: 'false',
    //                 snackMsg: msg,
    //                 snackVaraint: 'error'
    //             })
    //         }
    //     }).catch((err) => {
    //         setSnack({
    //             ...snack,
    //             snackOpen: 'false',
    //             snackMsg: 'Network Error',
    //             snackVaraint: 'error'
    //         })
    //     })

    // }

    // const handlePublishOpen = () => {
    //     setDialog(true)

    // }

    // const handlePublishClose = () => setDialog(false);

    // let publishStatus = EditDetails?.data?.publish

    if (loading) {
        return <MatxLoading />
    } else {
        return (
            <Fragment>
                <ContentBox>
                    <Grid container spacing={2}>
                        <Grid item xs={12} display="flex">
                            <IconButton
                                onClick={() => {
                                    navigate(-1)
                                }}
                            >
                                <Icon>arrow_back</Icon>
                            </IconButton>
                            <Breadcrumb
                                routeSegments={[
                                    { name: 'Jobs ', path: '/job' },
                                    { name: 'Job Edit' },
                                ]}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <JobDetails
                                jobDetail={EditDetails?.data}
                                onhandleReason={onhandleReason}
                                onhandleStandard_ref={onhandleStandard_ref}
                                reason={reason}
                                standard_ref={standard_ref}
                                initialState={invoiceData}
                                setInitialState={setInitialState}
                                onhandleChange={handleChange}
                            />
                        </Grid>
                        {EditDetails &&
                            EditDetails?.data &&
                            EditDetails?.data?.result &&
                            EditDetails?.data?.result.map((value, index) => (
                                <JobCardTable
                                    result={value}
                                    index={index}
                                    materialName={
                                        EditDetails?.data?.material?.name
                                    }
                                    remarksValue={EditDetails?.data?.remark}
                                    standard_ref={value.standard_ref}
                                    organizationName={organizationName}
                                    test_type={EditDetails?.data?.test_type}
                                    materialId={
                                        EditDetails?.data?.material?._id
                                    }
                                />
                            ))}

                        {getDocReport.includes(materialName) && (
                            <JobCardTableDoc
                                result={EditDetails?.data?.material?.name}
                                materialId={EditDetails?.data?.material?._id}
                                setSnack={setSnack}
                                snack={snack}
                                isView={false}
                                document={EditDetails?.data?.document}
                                organizationName={organizationName}
                            />
                        )}
                        <Grid item xs={12}>
                            {(userRole === 'ADMIN' || userRole === 'SUPER_ADMIN'||
                                userRole === 'CUSTOMER') && (
                                <InvoiceDetails
                                    initialState={invoiceData}
                                    setInitialState={setInitialState}
                                    onhandleChange={handleChange}
                                />
                            )}
                        </Grid>
                        <Grid
                            container
                            spacing={1}
                            sx={{ mt: 1 }}
                            justifyContent="end"
                        >
                            <Grid item>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="inherit"
                                    onClick={() => {
                                        navigate(-1)
                                    }}
                                >
                                    Back
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    type="submit"
                                    size="small"
                                    variant="contained"
                                    onClick={onhandleSave}
                                >
                                    Save
                                </Button>
                            </Grid>

                            {/* {userRole === 'ADMIN' && (publishStatus === true) && <Grid item>
                            <Button
                                type="submit"
                                size="small"
                                variant="contained"

                                color='error'
                                onClick={handlePublishOpen}
                                endIcon={<Icon>publish</Icon>}
                            >
                                Un-Publish
                            </Button>
                        </Grid> */}

                            {/* } */}

                            {/* Publish Dialog */}

                            {/* <Dialog
                                // fullScreen={fullScreen
                                value={params.id}
                                open={dialog}
                                onClose={handlePublishClose}


                            >
                                <DialogContent>
                                    <DialogContentText >
                                        Are you sure you want to UnPublish this Job?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button autoFocus onClick={handlePublishClose}>
                                        Cancel
                                    </Button>
                                    <Button onClick={(e) => handlePublish()} autoFocus variant='contained' endIcon={<Icon>publish</Icon>}>
                                        Publish
                                    </Button>
                                </DialogActions>

                            </Dialog> */}
                        </Grid>
                        <SnackBar
                            open={snack.snackOpen}
                            severity={snack.snackVaraint}
                            message={snack.snackMsg}
                            onClose={() =>
                                setSnack({
                                    ...snack,
                                    snackOpen: false,
                                })
                            }
                        />
                    </Grid>
                </ContentBox>
            </Fragment>
        )
    }
}

export default JobEdit
