import React from 'react'
import useSettings from 'app/hooks/useSettings'
import Cookies from 'js-cookie'
import { CssBaseline, ThemeProvider } from '@mui/material'

const THEMS = {
    FalconLab: 'blueFalcon',
    CareLab: 'greenCareLab',
}

const MatxTheme = ({ children }) => {
    const { settings, updateSettings } = useSettings()
    // console.log(settings,'setting');
    // updateSettings({...settings,activeTheme:})

    let activeTheme = {
        ...settings.themes[
            THEMS[Cookies.get('organizationName')] ?? settings.activeTheme
        ],
    }
    return (
        <ThemeProvider theme={activeTheme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    )
}

export default MatxTheme
