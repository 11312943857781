import React from 'react'
import JobEdit from './shared/JobEdit'
import JobView from './shared/JobView'

const jobCardRoutes = [
    {
        path: `/job/edit/:id`,
        element: <JobEdit />,
    },
    {
        path: `/job/view/:id`,
        element: <JobView />,
    },
]

export default jobCardRoutes
