import { TableBody, TableCell, TableHead, TableRow, Box } from '@mui/material'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const SoilPlasticLimitTable = ({ data }) => {
    // let plasticLimitFormula = 100 * (data?.wet_weight - data?.weight_of_oven) / data?.weight_of_oven
    // //formula
    // data.plastic_limit = (plasticLimitFormula ?
    //     valueCalc(plasticLimitFormula) :   //condition operator
    //     "To be Calculated")

    let plasticLimit

    plasticLimit = [
        {
            observation: 'Wet Weight of soil crumble at 3mm dia, W1 (g)',
            result: 'wet_weight',
        },
        {
            observation: 'Weight of oven dried soil crumble at 3mm dia, W2 (g)',
            result: 'weight_of_oven',
        },
        {
            observation: 'Plastic limit 100*(W1-W2)/W2  %',
            result: 'plastic_limit',
        },
    ]

    const Column = [
        { text: 'Observation', dataField: 'observation' },
        { text: 'Result', dataField: 'result' },
    ]
    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        {/* <TableCell align="center">Actions</TableCell> */}
                        <TableCell align="center">SI.No</TableCell>
                        {Column.map((item, index) => (
                            <TableCell align="center" key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                {plasticLimit?.map((item, index) => (
                    <TableBody>
                        <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">
                                {item.observation}
                            </TableCell>
                            <TableCell align="center">
                                {valueCalc(data[item.result])}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ))}
            </StyledTable>
        </Box>
    )
}

export default SoilPlasticLimitTable
