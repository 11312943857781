export const getName = (val, testCarried) => {
    if (val === 'loose_bulk') testCarried = `Loose ${testCarried}`
    else if (val === 'rodded_bulk') testCarried = `Rodded ${testCarried}`
    else if (val === 'avgLength') testCarried = `Length ${testCarried}`
    else if (val === 'avgHeight') testCarried = `Height ${testCarried}`
    else if (val === 'avgBreadth') testCarried = `Breadth ${testCarried}`
    else if (val === 'result_for_2_5')
        testCarried = `CBR value at 2.5mm penetration`
    else if (val === 'result_for_5_0')
        testCarried = `CBR value at 5.0mm penetration`
    else if (val === 'maximumWaterContent')
        testCarried = `Optimum Moisture Content  ${testCarried} (%)`
    else if (val === 'maximunDryDensity')
        testCarried = `Max-Dry Density ${testCarried} (g/cc)`
    // else if (val === 'crackVelocity') testCarried = testCarried
    else if (val === 'nonCrackVelocity') testCarried = `Non- ${testCarried}`
    else if (val === 'threeDaysAvg') testCarried = `3 Days of ${testCarried}`
    else if (val === 'sevenDaysAvg') testCarried = `7 Days of ${testCarried}`
    else if (val === 'twentyEightDaysAvg')
        testCarried = `28 Days of ${testCarried}`
    else if (val === 'coarseSand')
        testCarried = 'ii)Coarse Sand (4.75mm to 2.36mm)'
    else if (val === 'mediumSand')
        testCarried = 'iii)Medium Sand (2.36mm to 425µ) '
    else if (val === 'fineSand') testCarried = 'iv)Fine Sand (425µ to 75µ)'
    else if (val === 'silt') testCarried = 'v)Silt(<75µ)'
    else if (val === 'gravel') testCarried = 'i)Gravel (>4.75mm) '

    return testCarried
}

export const getGroupName = (value) => {
    if (value === 'Grain Size Analysis(0.075 to 4.75 mm)')
        value = 'Grain Sieve Analysis'
    else if (value === 'Liquid Limit') value = 'Atterberg Limts'
    else if (value === 'Specific Gravity') value = 'Specific Gravity'
    else if (value === 'Plastic Limit') value = 'Atterberg Limts'
    else if (value === 'Free Swell Index') value = 'Free Swell Index'
    else if (value === 'Heavy Compaction') value = 'Proctor Density'
    else if (value === 'Light Compaction') value = 'Proctor Density'
    else if (value === 'Soil Natural Moisture Content')
        value = 'Proctor Density'
    else if (value === 'Block Compressive Strength')
        value = 'Compressive Strength'
    else if (value === 'Block Water Absorption')
        value = 'Water Absorption (Moisture Content)'
    else return value

    return value
}

export const changeTitleName = (value) => {
    if (value === 'Reinforcement Steel (Physical)')
        value = 'Reinforcement Steel'
    else if (value === 'Cement(Physical)') value = 'Cement'
    else return value
    return value
}

export const changeTestNameOnTableRow = (data) => {
    data?.forEach((item, idx) => {
        if (
            item?.tests ===
            'Ratio of % of Lime to % of Silica, Alumina and Iron Oxide,when calculated by the formula: CaO – 0.7 SO3\n--------------------------------------\n2.8 x SiO2 + 1.2 x Al2O3 + 0.65 x Fe2O3'
        ) {
            item.tests =
                'Ratio of % of Lime to % of Silica, Alumina and Iron Oxide,when calculated by the formula: CaO – 0.7 SO3\n--------------------\n2.8 x SiO2 + 1.2 x Al2O3 + 0.65 x Fe2O3'
        }
    })
    return data
}
