import {
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const NormalConsistencyTable = (props) => {
    let {
        data,
        setData,
        setFinalData,
        finalData,
        result,
        setOverall,
        overall,
    } = props

    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData

        let average = 0
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && item.data.length > 0) {
                _data.forEach((item1) => {
                    let value = Number(item1.consistency) / _data.length
                    average += Number(value)
                })
            }

            if (item.groupName === result.groupName) {
                item.data = _data
                item.overall = {
                    ...overall,
                    average: valueCalc(average),
                }
                setOverall(item.overall)
            }
        })

        setFinalData(_finalData)
    }

    const Column = [
        {
            text: 'Quantity of water for 5-7 mm penetration',
            dataField: 'qty_water',
        },
        { text: 'Weight of cement', dataField: 'weight_cement' },
        { text: 'Consistency of cement in (%)', dataField: 'consistency' },
    ]

    return (
        <StyledTable>
            <TableHead>
                <TableRow>
                    <TableCell align="center">Trials</TableCell>
                    {Column.map((item, index) => (
                        <TableCell align={'center'} key={index}>
                            {item.text}
                        </TableCell>
                    ))}
                    <TableCell sx={{ width: 80 }} align={'center'}>
                        Actions
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data?.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell align="center">Trial {index + 1}</TableCell>
                        {Column.map((subItem, idx) => (
                            <TableCell align="center" key={`row-${idx}-index`}>
                                {item[subItem.dataField]}
                            </TableCell>
                        ))}
                        <TableCell>
                            <Button onClick={(e) => handleDelete(index, e)}>
                                <Icon>delete</Icon>
                            </Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </StyledTable>
    )
}

export default NormalConsistencyTable
