import {
    Document,
    Font,
    Page,
    StyleSheet,
    Text,
    View,
    Image,
} from '@react-pdf/renderer'
import { isReport } from 'app/assets/Constant/constant'
import { useState } from 'react'
import { valueCalc } from '../jobs/shared/method/method'
import DetailsTable from './DetailsTable/DetailsTable'
import { resultField } from './DetailsTableData/resultField'
import { unitData } from './DetailsTableData/unitData'
import Footer from './Footer'
import Header from './Header'
import ReportHeader from './ReportHeader/ReportHeader'
import Signature from './Signature'
import StandardRef from './StandardRef'
import BodyTable from './TableBody/BodyTable'
import Details from './TableData/Details'
import Title from './Title/Title'
import { getGroupName, getName } from './method'
import Remarks from './Remarks'
import AdditionalDetails from './TableBody/AdditionalDetails'

import newImage6 from './assets/images/new.jpg'
import { getNotes } from './DetailsTable/Note'
import logo from './assets/images/nabllogo.png'
import seal from './assets/images/Seal_dark.png'
import QRCodeCanvas from 'qrcode'

const fontSize = '10px'
const titleFontSize = '12px'
const fontFamily = 'Times-Roman'

const styles = StyleSheet.create({
    page: {
        display: 'flex',
        fontFamily: fontFamily,
        fontSize: fontSize,
        // paddingLeft: 40,
        // paddingRight: 40,
        lineHeight: 1.5,
        flexDirection: 'column',
        border: '4px #696969',
        paddingBottom: 115,
        paddingHorizontal: 20,
        
    },
    page1: {
        display: 'flex',
        fontFamily: fontFamily,
        fontSize: fontSize,
        // paddingLeft: 40,
        // paddingRight: 40,
        lineHeight: 1.5,
        flexDirection: 'column',
        border: '4px #696969',
        paddingBottom: 135,
        //paddingHorizontal: 20,
    },
    backgroundImage: {
        position: 'absolute',
        bottom: 80,
        right: '5%',
        width: '17%',
        //height: '100%',
        zIndex: -1, // Place behind other content
        justifyContent: 'center',
       // opacity: 0.1
       transform: 'rotate(-30)',
      },
    pdf: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
    },
    logo: {
        width: '100%',
        height: 'auto',
    },

    title: {
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center',
        fontFamily: 'Times-Bold',
        textTransform: 'uppercase',
        fontSize: titleFontSize,
        letterSpacing: 1,
        //paddingLeft: 40,
        // justifyContent: 'center',
        textDecoration: 'underline',
    },
    seal: {
        display: 'flex',
        maxWidth: '20%',
        maxHeight: 'auto',
        transform: 'rotate(-30)',
    },
    timestampContainer: {
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    timestampText: {
        fontSize: 10,
        fontWeight: 'bold',
        color: 'black',
    },
    bar1: {
        //maxHeight: 100,
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: 70,
        // marginTop: 8,
        // marginRight: 1,
        // marginLeft: '4',
        // height: '30px',
    },
    titleContainer: {
        letterSpacing: 1,
        flex: 1,
        justifyContent: 'center',
    },
    label: {
        paddingLeft: 3,

        // minWidth: '10%',
    },
    label1: {
        paddingLeft: 420,
        paddingRight: 10,
        fontSize: 12,
        fontWeight: 'bold',
    },
    bar: {
        maxHeight: '50',
        alignItems: 'center',
        //justifyContent: 'flex-end',
        marginLeft: '89%',
    },
})

const Report = ({
    details,
    data,
    result,
    selectedResult,
    params,
    type,
    avgLength,
    avgBreadth,
    avgHeight,
}) => {
    let group_type = result.map((item) => item.groupType).join(',')
    let organizationName = details?.organization?.name
    let nablNo = 'TC-6832'
    let nablNo1 = 'TC-12840'
    //'T-3695 & T-3696'
    const [jobNo, setJobNo] = useState(details.job_no)
    const groupName1 = selectedResult?.groupName
    if (details.test_type) {
        if (type === 'report' || type === 'reportOnly') {
            details.standard_refs = details.test_type.map(
                (input) => input.standard_ref
            )
        } else {
            details.standard_refs = details.test_type
                .filter(
                    (type) =>
                        type.job_group &&
                        type.job_group.name === selectedResult.groupName
                )
                .map((input) => input.standard_ref)
        }

        if (details.standard_refs) {
            details.standard_refs = Array.from(new Set(details.standard_refs))
        }
    }
    let titleName = details?.material?.name
    let arrayData = details.test_type
    let arr = []
    let arr1 = []
    let resultval
    let requirement = '--'
    let unit
    let keyvalue
    let keys
    let JobTableResult = []
    if (details.standard_refs) {
        details.standard_refs = Array.from(new Set(details.standard_refs))
    }

    if (type === 'report' || type === 'reportOnly') {
        if (isReport?.includes(titleName)) {
            result?.forEach((item) => {
                let requirement1 = item?.overall?.requirements ?? '-'
                let resultvalue1 = resultField[item?.groupName]
                let keyvalue1 = resultvalue1 ? Object.values(resultvalue1) : []

                if (keyvalue1.length > 1) {
                    arr1 = keyvalue1
                        .filter((val) => item?.overall?.[val] !== undefined)
                        .map((val) => ({
                            titleName: titleName,
                            groupName: getGroupName(item?.groupName),
                            description: getName(val, item?.groupName),
                            result: valueCalc(item?.overall?.[val]),
                            requirement: requirement1,
                        }))
                }
            })
        }
    }

    if (type === 'report' || type === 'reportOnly') {
        if (isReport?.includes(titleName)) {
            result?.forEach((item) => {
                let testMethod = arrayData
                    ?.filter(
                        (item1) => item?.groupName === item1?.job_group?.name
                    )
                    ?.map((item1) => item1?.standard_ref)
                let isResultAdded = false
                unitData?.forEach((item2) => {
                    if (
                        item?.groupName === item2?.name &&
                        (!item2?.notShowMaterials ||
                            !item2?.notShowMaterials.includes(titleName))
                    ) {
                        isResultAdded = true
                        if (item?.requirement[0]?.unit !== undefined) {
                            unit =
                                item?.requirement[0]?.unit.length !== 0
                                    ? item?.requirement[0]?.unit
                                    : item2?.unit
                        } else {
                            unit = item2?.unit
                        }
                        requirement = item?.overall?.requirements ?? ' '
                        let resultvalue = resultField[item?.groupName]
                        keyvalue = Object?.values(resultvalue || {})
                        if (keyvalue?.length > 1) {
                            keyvalue?.forEach((val) => {
                                if (
                                    item?.overall !== undefined &&
                                    item?.overall[val]
                                ) {
                                    resultval = item?.overall[val]
                                    let testCarried = getName(
                                        val,
                                        item?.groupName
                                    )
                                    if (titleName === 'Soil') {
                                        arr?.push({
                                            groupName: getGroupName(
                                                item?.groupName
                                            ),
                                            description: testCarried,
                                            result: valueCalc(resultval),
                                            requirement: requirement,
                                        })
                                    } else {
                                        arr?.push({
                                            testCarried: testCarried,
                                            testMethod: testMethod,
                                            unit: unit,
                                            result: valueCalc(resultval),
                                            requirement: requirement,
                                        })
                                    }
                                }
                            })
                        }
                        if (keyvalue?.length === 1) {
                            if (item?.overall !== undefined) {
                                var val = keyvalue.pop()
                                resultval = valueCalc(item?.overall[val])
                            }
                            if (titleName === 'Soil') {
                                arr?.push({
                                    groupName: getGroupName(item?.groupName),
                                    description: item.groupName,
                                    result: valueCalc(resultval),
                                    requirement: requirement,
                                })
                            } else {
                                arr?.push({
                                    testCarried: item.groupName,
                                    testMethod: testMethod,
                                    unit: unit,
                                    result: valueCalc(resultval),
                                    requirement: requirement,
                                })
                            }
                        }
                        if (item?.groupName === 'Plastic Limit') {
                            let plasticLimit = valueCalc(resultval) ?? 0
                            let liquidLimitData = result?.find(
                                (res) => res.groupName === 'Liquid Limit'
                            )
                            let liquidLimit = liquidLimitData?.overall?.average
                                ? valueCalc(liquidLimitData?.overall?.average)
                                : 0
                            arr?.push({
                                groupName: getGroupName(item?.groupName),
                                description: 'Plasticity Index',
                                result: valueCalc(liquidLimit - plasticLimit),
                                requirement: 'Maximum 20%',
                            })
                        }
                    }
                })
                if (isResultAdded === false) {
                    JobTableResult.push(item)
                }
            })
        }
        if (titleName === 'Cement' || titleName === 'Cement(Physical)') {
            result?.forEach((item) => {
                unitData?.forEach((item1) => {
                    if (item1?.name === item?.groupName) {
                        let resultvalue = resultField[item?.groupName]
                        keyvalue = Object?.values(resultvalue || {})
                        let unit = item1?.unit
                        let dynamicUnit =
                            item?.requirement?.[0]?.requirement[0]?.unit ?? unit
                        let requirement =
                            item?.requirement?.[0]?.requirement[0]?.limit ??
                            item?.overall?.requirements
                        if (keyvalue?.length > 1) {
                            keyvalue?.forEach((val) => {
                                let testCarried = item?.groupName
                                let index = ''
                                let remark = item?.overall?.remarks
                                if (item?.overall !== undefined) {
                                    resultval = item?.overall[val]
                                        ? valueCalc(item?.overall[val])
                                        : '-'
                                    unit = item1?.unit
                                } else {
                                    resultval = '--'
                                }
                                if (val === 'avgComp_st') {
                                    testCarried = testCarried
                                    resultval = ' '
                                    unit = ' '
                                    remark = ' '
                                }
                                if (val === 'threeDaysAvg') {
                                    testCarried = '3rd Comp.Str'
                                    index = 'a)'
                                    unit = unit
                                    remark =
                                        item?.overall?.threeDaysRemarks ??
                                        remark
                                    requirement =
                                        item?.overall?.threeDaysRequirements ??
                                        requirement
                                }
                                if (val === 'sevenDaysAvg') {
                                    testCarried = '7th Comp.Str'
                                    index = 'b)'
                                    unit = unit
                                    remark =
                                        item?.overall?.sevenDayRemarks ?? remark
                                    requirement =
                                        item?.overall?.sevenDaysRequirements ??
                                        requirement
                                }
                                if (val === 'twentyEightDaysAvg') {
                                    testCarried = '28th Comp.Str'
                                    index = 'c)'
                                    unit = unit
                                    remark =
                                        item?.overall?.twentyEightDaysRemarks ??
                                        remark
                                    requirement =
                                        item?.overall
                                            ?.twentyEightDaysRequirements ??
                                        requirement
                                }
                                arr?.push({
                                    testCarried: testCarried,
                                    unit: dynamicUnit,
                                    standardRef:
                                        item?.requirement?.[0]?.requirement[0]
                                            ?.standard_ref ?? '--',
                                    result: resultval,
                                    requirement: requirement ?? '--',
                                    // remark: remark ?? '--',
                                    index: index,
                                })
                            })
                        }

                        if (keyvalue?.length === 1) {
                            if (item?.overall !== undefined) {
                                var val = keyvalue.pop()
                                resultval = item?.overall[val]
                                    ? valueCalc(item?.overall[val])
                                    : '-'
                                unit = item1?.unit
                            } else {
                                resultval = '--'
                            }
                            arr?.push({
                                testCarried: item?.groupName,
                                unit: dynamicUnit,
                                // unit?.length > 0 ? unit : '-',
                                standardRef:
                                    item?.requirement?.[0]?.requirement[0]
                                        ?.standard_ref ?? '--',
                                result: resultval,
                                requirement: requirement ?? '--',
                                // remark: item?.overall?.remarks ?? '--',
                            })
                        }
                    }
                })
            })
        }

        //
        if (
            titleName === 'Drinking Water (RO Water)' ||
            titleName === 'Construction Water' ||
            titleName === 'Well Water'
        ) {
            result?.forEach((item, idx) => {
                item?.data.forEach((item1) => {
                    arr?.push({
                        groupName: item?.groupName,
                        tests: item1.tests ?? '---',
                        unit: item1?.unit,
                        standard_ref: item1.standard_ref ?? '---',
                        result: item1.result ?? '---',
                        req: item1.req ?? '---',
                        requirement_absence_source:
                            item1.requirement_absence_source ?? '---',
                    })
                })
            })
        }
        //
        if (
            titleName === 'Swimming Pool Water' ||
            titleName === 'Waste Water (STP Treated Water)' ||
            titleName === 'Chemical Waste Water (STP Treated Water)' ||
            titleName === 'Waste Water (ETP Treated Water)' ||
            titleName === 'Waste Water (ETP Effluent Water)'
        ) {
            result?.forEach((item, idx) => {
                item?.data.forEach((item1) => {
                    if (item?.groupName === 'Radioactive Material') {
                        arr?.push({
                            groupName: item?.groupName,
                            tests: item1.tests ?? '---',
                            unit: item1?.unit,
                            standard_ref: item1.standard_ref ?? '---',
                            result: item1.result ?? '---',
                            req: item1.req ?? '---',
                            requirement_absence_source:
                                item1.requirement_absence_source ?? '---',
                        })
                    } else {
                        arr?.push({
                            groupName: item?.groupName,
                            tests: item1.tests ?? '---',
                            unit: item1?.unit,
                            standard_ref: item1.standard_ref ?? '---',
                            result: item1.result ?? '---',
                            req: item1.req ?? '---',
                        })
                    }
                })
            })
        }
        //
        if (titleName === 'Cooling Tower Water') {
            result?.forEach((item, idx) => {
                item?.data.forEach((item1) => {
                    arr?.push({
                        // groupName: item?.groupName,
                        tests: item1?.tests ?? '---',
                        unit: item1?.unit,
                        standard_ref: item1?.standard_ref ?? '---',
                        result: item1?.result ?? '---',
                        req: item1?.req ?? '---',
                    })
                })
            })
        }
        //
        if (titleName === 'Coupler (Chemical)') {
            result?.forEach((item, idx) => {
                if (item.groupName === 'Specifications as per En 8 BS970 (%)') {
                    item?.data.forEach((item1) => {
                        arr?.push({
                            groupName: item?.groupName,
                            tests: item1.tests ?? '---',
                            min: item1?.min ?? '---',
                            max: item1?.max ?? '---',
                        })
                    })
                }
            })
        }
        if (
            titleName === 'Bakery & Confectionery Products' ||
            titleName === 'Fruits & Fruit products' ||
            titleName === 'Milk & Diary products' ||
            titleName ===
                'Other Specified Food Items (Raw,Cooked and Ready to eat Foods-Hotel & Canteen Foods)' ||
            'Raw Water, Drinking Water, Packaged Drinking Water' ||
            'Agar Settle Plate Method' ||
            'Swab Test Environmental Air(Clean room & Non clean room)'
        ) {
            result?.forEach((item, idx) => {
                item?.data.forEach((item1) => {
                    if (item?.groupName === 'DYNAMIC_JOBCARD') {
                        arr?.push({
                            groupName: item?.groupName,
                            tests: item1.tests ?? '---',
                            unit: item1?.unit,
                            standard_ref: item1.standard_ref ?? '---',
                            result: item1.result ?? '---',
                            req: item1.req ?? '---',
                            requirement_absence_source:
                                item1.requirement_absence_source ?? '---',
                        })
                    }
                    //   else {

                    //      arr?.push({
                    //          groupName: item?.groupName,
                    //          tests: item1.tests ?? '---',
                    //          unit: item1?.unit,
                    //          standard_ref: item1.standard_ref ?? '---',
                    //          result: item1.result ?? '---',
                    //          req: item1.req ?? '---',

                    //      })
                    //  }
                })
            })
        }

        //
        // if (titleName === 'Reinforcement Steel (rebar)') {
        //     result?.forEach((item, idx) => {
        //         item?.data.forEach((item1) => {
        //             arr?.push({
        //                 dia: item1?.dia ? item1?.dia : '--',
        //                 weightPerMeter: item1?.weightPerMeter ? valueCalc(item1?.weightPerMeter) : '--',
        //                 yieldStress: item1?.yieldStress ? valueCalc(item1?.yieldStress) : '--',
        //                 ultimateTensileStress: item1?.ultimateTensileStress ? valueCalc(item1?.ultimateTensileStress) : '--',
        //                 percentageOfElongation: item1?.percentageOfElongation ? valueCalc(item1?.percentageOfElongation) : '--',
        //                 _10_perc_uts: item1?._10_perc_uts ? valueCalc(item1?._10_perc_uts) : '--',
        //                 bend: item1?.bend,
        //                 reBend: item1?.reBend
        //             })
        //         })
        //     })
        // }
        //
        // if (titleName === 'Construction Water') {
        //     result?.forEach((item) => {
        //         let testMethod = arrayData?.filter(item1 => item?.groupName === item1?.job_group?.name)?.map(item1 => item1?.standard_ref);
        //         unitData?.forEach((item2) => {
        //             if (item?.groupName == item2?.name) {
        //                 unit = item2?.unit
        //                 let resultvalue = resultField[item?.groupName]
        //                 keys = Object?.keys(resultvalue || {})
        //                 keys?.forEach((key) => {
        //                     arr?.push({
        //                         parameter: resultvalue[key],
        //                         testMethod: testMethod,
        //                         result: item?.overall[key],
        //                         unit: unit
        //                     })
        //                 })

        //             }
        //         })
        //     })
        // }
    }

    let assigness = details?.allocated_to?.name
    let testedBy = details?.allocated_to?.signature
    let verifiedBy = details?.customer_samples?.reviewer_by?.signature
    let authorizedBy = details?.customer_samples?.authorized_by?.signature

    const testedByName = details?.allocated_to?.name
    const verifiedByName = details?.customer_samples?.reviewer_by?.name
    const authorizedByName = details?.customer_samples?.authorized_by?.name

    let request_type =
        details?.request_type ?? details?.customer_samples?.request_type

    let standard_ref = selectedResult?.overall?.standard_ref
    let material_standard_ref = details?.material?.standard_ref

    const currentTimestamp =
        type === 'observationNull' ? new Date().toLocaleString() : ''

    let standard_ref_Ref

    if (details?.standard_ref) {
        standard_ref_Ref = details?.standard_ref
    } else if (material_standard_ref) {
        standard_ref_Ref = material_standard_ref
    } else if (standard_ref) {
        standard_ref_Ref = standard_ref
    } else {
        standard_ref_Ref = details.standard_refs
    }
    let note = getNotes[titleName]
    let remarks =
        type === 'report' || type === 'reportOnly'
            ? selectedResult?.overall?.remarks ?? ''
            : selectedResult?.overall?.remarks ?? ''

    let requirements = selectedResult?.overall?.requirements
    let requirementStatus = selectedResult?.overall?.requirementStatus
    let remarkStatus = selectedResult?.overall?.remarkStatus

    let remarksArray1 =
        result?.requirement
            ?.filter((q) => q !== undefined && q !== null && q.remark)
            ?.map((q) => q.remark)
            .join(', ') ?? ''

    let urlTitle = String(titleName).replace(/ /g, '%20')

    let baseURL = window.location.origin

    let url = `${baseURL}/pdf/download/${params.idd}/${urlTitle}?type=${type}&category=download`

    let canvas

    // For QR Code
    canvas = document.createElement('canvas')
    QRCodeCanvas.toCanvas(canvas, url)
    const qr = canvas.toDataURL()

    return (
        <Document>
            <Page
                style={
                    organizationName !== 'CareLab' ? styles.page : styles.page1
                }
            >
                 {/* <Image src={seal} style={styles.backgroundImage} fixed/>  */}
                {(type === 'report' || type === 'reportOnly') && (
                    <Header
                        type={type}
                        nablNo={nablNo}
                        nablNo1={nablNo1}
                        request_type={request_type}
                        organizationName={organizationName}
                    />
                )}
                <View
                    style={
                        organizationName === 'CareLab'
                            ? { paddingHorizontal: 20 }
                            : null
                    }
                >
                    <View
                        style={{
                            flexDirection: 'row',
                            textAlign: 'center',
                        }}
                    >
                        {/* {type !== 'reportOnly' ? (
                            <> */}
                        <View style={styles.titleContainer}>
                            {type !== 'reportOnly' ? (
                                <Text
                                    style={
                                        type === 'report' ||
                                        type === 'reportOnly'
                                            ? {
                                                  paddingLeft: 70,
                                                  ...styles.title,
                                              }
                                            : type === 'testReport'
                                              ? {
                                                    paddingLeft: 70,
                                                    marginTop: '2%',
                                                    ...styles.title,
                                                }
                                              : {
                                                    marginTop: '2%',

                                                    ...styles.title,
                                                }
                                    }
                                    fixed
                                >
                                    {type === 'observation'
                                        ? 'Observation Report'
                                        : type === 'observationNull'
                                          ? 'Observation Report'
                                          : type === 'Test Report'
                                            ? 'Test Report'
                                            : 'Test Report'}
                                </Text>
                            ) : null}
                        </View>
                        {type !== 'observation' &&
                            type !== 'observationNull' && (
                                <View
                                    style={{
                                        marginTop:
                                            type === 'testReport' ? '10px' : '',
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: '5.5px',
                                        }}
                                    >
                                        IS YOUR REPORT
                                        <Text style={{ color: 'red' }}>
                                            AUTHENTIC?
                                        </Text>
                                    </Text>
                                    {/* <Text style={{ color: 'red', fontSize: '4px' }}>
                                    AUTHENTIC?
                                </Text> */}
                                    <Text
                                        style={{
                                            fontSize: '5.5px',
                                            paddingLeft: '20px',
                                        }}
                                    >
                                        SCAN QR CODE
                                    </Text>
                                </View>
                            )}
                    </View>
                    <View>
                        {(type === 'report' ||
                            type === 'reportOnly' ||
                            type === 'testReport') &&
                            (organizationName === 'CareLab' ||
                                (organizationName !== 'CareLab' &&
                                    selectedResult.groupName ===
                                        'DYNAMIC_JOBCARD')) && (
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        textAlign: 'center',
                                        //justifyContent:'flex-end'
                                    }}
                                >
                                    <Image src={qr} style={styles.bar} />
                                </View>
                            )}
                    </View>
                    {type === 'observationNull' ? (
                        <View style={styles.timestampContainer}>
                            <Text style={styles.timestampText}>
                                Timestamp:{currentTimestamp}
                            </Text>
                        </View>
                    ) : null}
                    <ReportHeader
                        jobNo={jobNo}
                        request_type={request_type}
                        details={details}
                        type={type}
                    />
                    <View>
                        {(organizationName !== 'CareLab' &&
                            selectedResult.groupName === 'DYNAMIC_JOBCARD') ||
                            (organizationName !== 'CareLab' && (
                                <>
                                    {type === 'observation' && (
                                        <Title
                                            //title={selectedResult.groupName}
                                            title={
                                                organizationName !==
                                                    'CareLab' &&
                                                selectedResult.groupName ===
                                                    'DYNAMIC_JOBCARD'
                                                    ? titleName
                                                    : organizationName !==
                                                        'CareLab'
                                                      ? selectedResult.groupName
                                                      : titleName
                                            }
                                            jobNo={jobNo}
                                            id={params.id}
                                            type={type}
                                            organizationName={organizationName}
                                        />
                                    )}
                                    {type === 'observationNull' && (
                                        <Title
                                            //title={selectedResult.groupName}
                                            title={
                                                organizationName !==
                                                    'CareLab' &&
                                                selectedResult.groupName ===
                                                    'DYNAMIC_JOBCARD'
                                                    ? titleName
                                                    : organizationName !==
                                                        'CareLab'
                                                      ? selectedResult.groupName
                                                      : titleName
                                            }
                                            jobNo={jobNo}
                                            id={params.id}
                                            type={type}
                                            organizationName={organizationName}
                                        />
                                    )}
                                    {(type === 'report' ||
                                        type === 'reportOnly') && (
                                        <Title
                                            title={titleName}
                                            jobNo={jobNo}
                                            id={params.id}
                                            type={type}
                                            organizationName={organizationName}
                                        />
                                    )}
                                    {type === 'testReport' && (
                                        <Title
                                            title={titleName}
                                            jobNo={jobNo}
                                            id={params.id}
                                            type={type}
                                            headingName={
                                                selectedResult.groupName
                                            }
                                            organizationName={organizationName}
                                        />
                                    )}
                                </>
                            ))}
                        <Details
                            standard_ref={standard_ref}
                            data={data}
                            jobNo={jobNo}
                            details={details}
                            type={type}
                            titleName={titleName}
                            organizationName={organizationName}
                            result={result}
                            groupType={selectedResult.groupType}
                        />

                        <StandardRef
                            organizationName={organizationName}
                            standard_ref={standard_ref_Ref}
                            groupType={selectedResult.groupType}
                        />
                        {(type === 'report' || type === 'reportOnly') &&
                            arr?.length > 0 && (
                                <DetailsTable
                                    type={type}
                                    arr={arr}
                                    title={details?.material.name}
                                    //result={result}
                                    result={selectedResult}
                                    details={details}
                                    organizationName={organizationName}
                                />
                            )}
                        {(type === 'report' || type === 'reportOnly') &&
                            JobTableResult.length !== 0 &&
                            JobTableResult.map((item) => (
                                <BodyTable
                                    result={item}
                                    details={details}
                                    type={type}
                                    titleName={titleName}
                                    arr1={arr1}
                                    organizationName={organizationName}
                                    groupType={result.groupType}
                                />
                            ))}
                        {type === 'observationNull' &&
                            groupName1 === 'Dimension Test' && (
                                <>
                                    <Text style={styles.label1}>
                                        No of Bricks Tested :
                                    </Text>
                                </>
                            )}
                        {(type === 'observation' ||
                            type === 'observationNull' ||
                            type === 'testReport') && (
                            <BodyTable
                                result={selectedResult}
                                details={details}
                                type={type}
                                selectedResult={groupName1}
                                organizationName={organizationName}
                                groupType={result.groupType}
                            />
                        )}
                        <View
                            style={{
                                marginTop:
                                    titleName === 'Hardened Concrete Cube' ||
                                    (titleName ===
                                        'Hardened Concrete Cube By ACT Method' &&
                                        type === 'reportOnly') ||
                                    (titleName ===
                                        'Hardened Concrete (Concrete core)' &&
                                        type === 'observation')
                                        ? ''
                                        : '10px',
                            }}
                            break={
                                type === 'testReport' ||
                                type === 'observationNull'
                            }
                        >
                            <View style={{ maxWidth: '100%', marginTop: '8' }}>
                                {selectedResult?.groupName ===
                                    'Equivalent Cube Compressive Stregnth Results of Concrete Core' && (
                                    <AdditionalDetails // Notes
                                        type={type}
                                        note={note}
                                        remarks={remarks ?? remarksArray1}
                                        remarkStatus={remarkStatus}
                                        requirements={requirements}
                                        requirementStatus={requirementStatus}
                                        groupName={selectedResult?.groupName}
                                        titleName={titleName}
                                    />
                                )}
                                <Remarks details={details} />
                                <Text
                                    style={{
                                        fontSize: '9',
                                        fontFamily: 'Times-Bold',
                                        paddingBottom: '10',
                                    }}
                                >
                                    *As Furnished by the customer
                                </Text>
                            </View>

                            {type !== 'observation' &&
                                type !== 'reportOnly' && (
                                    <Signature
                                        testedBy={testedBy}
                                        verifiedBy={verifiedBy}
                                        authorizedBy={authorizedBy}
                                        testedByName={testedByName}
                                        verifiedByName={verifiedByName}
                                        authorizedByName={authorizedByName}
                                        organizationName={organizationName}
                                        assigness={assigness}
                                        type={type}
                                        group_type={group_type}
                                    />
                                )}

                            {/* <View
                                style={{
                                    width: '100%',
                                    textAlign: 'center',
                                    fontSize: 10,
                                }}
                                wrap={false}
                            >
                                <Text>*** End of the Report ***</Text>
                            </View> */}
                            {organizationName !== 'CareLab' ? (
                                <View
                                    style={{
                                        width: '100%',
                                        textAlign: 'center',
                                        fontSize: 10,
                                    }}
                                    wrap={false}
                                >
                                    <Text>*** End of the Report ***</Text>
                                </View>
                            ) : type === 'testReport' || type === 'report' ? (
                                <View
                                    style={{
                                        width: '100%',
                                        textAlign: 'center',
                                        fontSize: 10,
                                        marginTop: -60,
                                        marginLeft: -10,
                                    }}
                                    wrap={false}
                                >
                                    <Text>*** End of the Report ***</Text>
                                </View>
                            ) : (
                                <View
                                    style={{
                                        width: '100%',
                                        textAlign: 'center',
                                        fontSize: 10,
                                    }}
                                    wrap={false}
                                >
                                    <Text>*** End of the Report ***</Text>
                                </View>
                            )}
                        </View>
                    </View>
                </View>
                {/* {group_type !== 'DYNAMIC_JOBCARD' &&
                // render={({ pageNumber, totalPages }) =>
                //     `Pg.No : ${pageNumber} / ${totalPages}`
                // }
                <Image src={seal} style={styles.backgroundImage} fixed/>
} */}
                {type === 'report' && (
                    <Footer type={type} organizationName={organizationName} />
                )}
            </Page>
        </Document>
    )
}

export default Report
