import styled from '@emotion/styled'
import { AddCircle, ExpandMore } from '@mui/icons-material'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    InputLabel,
    Modal,
    TextField,
    Typography,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { StyledDivider } from 'app/views/jobs/StyledComponents/StyledComponents'

import { Form, Formik } from 'formik'
import { Fragment, useContext, useState } from 'react'
import * as Yup from 'yup'
import { valueCalc } from '../../method/method'
import CarbonationTestTable from './CarbonationTestTable'

const initialValues = {
    lvl_carbonation: '',
    id: '',
    obs_result: '',
}
const validationSchema = Yup.object().shape({
    id: Yup.string().required('Required'),
    lvl_carbonation: Yup.string().required('Required'),
    obs_result: Yup.number().required('Required'),
})
const CarbonationTest = ({
    result,
    isView,
    handleCustomRequirementChange,
    handleRequirementChange,
    overall,
    setOverall,
}) => {
    const handleClose = () => setOpen(false)
    const [open, setOpen] = useState(false)

    const [data, setData] = useState(result.data ? result.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const { finalData, setFinalData } = useContext(AppContext)
    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        // arr = applyFormula(arr)
        let _finalData = finalData
        console.log({ _finalData })
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && arr.length !== 0) {
                let average = 0
                arr.forEach((item1) => {
                    let value = Number(item1.obs_result / arr.length)
                    average += Number(value.toFixed(4))
                })
                item.overall = {
                    ...overall,
                    average: valueCalc(average),
                }
                setOverall(item.overall)
            }
            if (item.groupName === result.groupName) {
                item.data = arr
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
    }

    // const applyFormula = (arr) => {

    //     arr.forEach((item, index) => {
    //         item.consistency = (item.qty_water / item.weight_cement) * 100

    //     })
    //     return arr;
    // }
    const handleOpen = () => setOpen(true)

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <JobAccordion
                        title={`${result?.groupName} on Concrete`}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        requirementvalue={overall?.requirements}
                        avgLabel="Average"
                        averagevalue={overall?.average}
                        remarksValue={overall?.remarks}
                        overall={overall}
                    >
                        <Grid item xs={12}>
                            <Formik
                                initialValues={initialState}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {(props) => {
                                    const {
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    } = props
                                    return (
                                        <Form>
                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                            >
                                                <StyledCard>
                                                    <CardHeader title="Carbonation Test on Concrete" />
                                                    <CardContent
                                                        sx={{
                                                            m: 4,
                                                        }}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={4}
                                                        >
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Sample Id"
                                                                    value={
                                                                        values.id
                                                                    }
                                                                    name="id"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.id &&
                                                                        touched.id
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.id &&
                                                                    touched.id
                                                                        ? errors.id
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Level Of Carbonation"
                                                                    value={
                                                                        values.lvl_carbonation
                                                                    }
                                                                    name="lvl_carbonation"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.lvl_carbonation &&
                                                                        touched.lvl_carbonation
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.lvl_carbonation &&
                                                                    touched.lvl_carbonation
                                                                        ? errors.lvl_carbonation
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Observed Result(Nil/Carbonated)"
                                                                    value={
                                                                        values.obs_result
                                                                    }
                                                                    name="obs_result"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.obs_result &&
                                                                        touched.obs_result
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.obs_result &&
                                                                    touched.obs_result
                                                                        ? errors.obs_result
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button
                                                            size="small"
                                                            onClick={
                                                                handleClose
                                                            }
                                                        >
                                                            Cancel
                                                        </Button>

                                                        <Button
                                                            size="small"
                                                            variant="contained"
                                                            onClick={
                                                                handleSubmit
                                                            }
                                                        >
                                                            Save
                                                        </Button>
                                                    </CardActions>
                                                </StyledCard>
                                            </Modal>
                                        </Form>
                                    )
                                }}
                            </Formik>
                            {data && (
                                <CarbonationTestTable
                                    data={data}
                                    setData={setData}
                                    setFinalData={setFinalData}
                                    finalData={finalData}
                                    result={result}
                                    setOverall={setOverall}
                                    overall={overall}
                                />
                            )}

                            {isView !== true && (
                                <Grid container justifyContent="end">
                                    <Button
                                        sx={{
                                            boxShadow: 'none',
                                            mt: 2,
                                        }}
                                        variant="contained"
                                        endIcon={<AddCircle />}
                                        onClick={handleOpen}
                                        size="small"
                                    >
                                        Add
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </JobAccordion>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default CarbonationTest
