import React from 'react'

import styled from '@emotion/styled'
import {
    Button,
    Icon,
    // Icon,
    // IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material'
import { Box } from '@mui/system'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const SoilLiquidLimitTable = ({
    data,
    setData,
    setFinalData,
    finalData,
    result,
    overall,
    setOverall,
}) => {
    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        let _finalData = finalData
        // let avg = 0

        _finalData.forEach((item) => {
            // if (item.groupName === result.groupName && _data.length !== 0) {
            //     _data?.forEach((item1) => {
            //         let total = valueCalc(100 * ((item1.wet_weight - item1.dry_weight) / item1.dry_weight))
            //
            //         avg += Number(total / _data.length)
            //
            //     })
            // }
            if (item.groupName === result.groupName) {
                item.data = _data
                // const filteredData = item.data.find(d => d.blow_count === "25" && d.moisture_content !== null);
                // const averageMoistureContent = filteredData?.moisture_content

                const filteredData25 = item.data.filter(
                    (d) => d.blow_count === '25'
                )

                const filteredData22to28 = item.data.filter((d) => {
                    const blowCount = parseInt(d.blow_count)
                    return blowCount >= 22 && blowCount <= 28
                })

                const averageMoistureContent25 =
                    filteredData25.length > 0
                        ? filteredData25.reduce(
                              (sum, d) => sum + parseFloat(d.moisture_content),
                              0
                          ) / filteredData25.length
                        : 0

                const averageMoistureContent22to28 =
                    filteredData22to28.length > 0
                        ? filteredData22to28.reduce(
                              (sum, d) => sum + parseFloat(d.moisture_content),
                              0
                          ) / filteredData22to28.length
                        : 0

                const averageMoistureContent =
                    filteredData25.length > 0
                        ? averageMoistureContent25
                        : averageMoistureContent22to28

                item.overall = {
                    ...overall,
                    average: averageMoistureContent ?? 0,
                }
                setOverall(item.overall)
            }
        })
        setData(_data)
        setFinalData(_finalData)
    }

    // data.map((value) => [
    //     {
    //         blow_count: value.blow_count,
    //         wet_weight: value.wet_weight,
    //         dry_weight: value.dry_weight,
    //         // moisture_content: (100 * (value.wet_weight - value.dry_weight) / value.dry_weight),
    //     },
    // ]
    // )

    const Column = [
        { text: 'Blow count', dataField: 'blow_count' },
        { text: 'Wet weight, W1 (g)', dataField: 'wet_weight' },
        { text: 'Dry weight, W2 (g)', dataField: 'dry_weight' },
        // {
        //     text: 'Moisture content, 100*(W1-W2)/W2 %',
        //     dataField: 'moisture_content',
        // },
    ]
    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">SI.No</TableCell>
                        {Column.map((item, index) => (
                            <TableCell align="center" key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                        <TableCell align="center">
                            Moisture content, 100*(W1-W2)/W2 %
                        </TableCell>
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            {Column.map((subItem, idx) => (
                                <TableCell
                                    align="center"
                                    key={`row-${idx}-index`}
                                >
                                    {item[subItem.dataField] ?? ''}
                                </TableCell>
                            ))}
                            <TableCell align="center">
                                {item.moisture_content}{' '}
                            </TableCell>
                            <TableCell align="center">
                                <Button onClick={(e) => handleDelete(index, e)}>
                                    <Icon>delete</Icon>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell align="right" colSpan={6}>
                            Liquid limit ( moisture content for 25 blow)
                        </TableCell>
                    </TableRow>
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default SoilLiquidLimitTable
