import {
    deleteArchivedJobData,
    deleteJobDataById,
    fetchDocumentsTemplate,
    fetchJobTableData,
    fetchJobStatusData,
    fetchReportDocuments,
    fetchReportDocumentsByObjLink,
    fetchRequirements,
    fetchViewData,
    publishJob,
    saveJobData,
    sortJobData,
    uploadJobDocuments,
} from 'app/services/JobService'

// Job Table
export const getJobTableData = (props, callBack) => {
    fetchJobTableData(
        props,
        (res) => callBack(res?.data?.data),
        // dispatch({
        //     type: JOB.SET_JOB_TABLE_DATA,
        //     payload: res?.data?.data?.docs,

        (err) => console.error(err)
    )
}
//Job Table StatusCount
export const getJobProgressData = (props, callBack) => {
    fetchJobStatusData(
        props,
        (res) => callBack(res?.data),
        (err) => console.log(err)
    )
}

// Job View and Edit
export const getJobView = (jobId, callBack) => {
    fetchViewData(
        jobId,
        (res) => callBack(res?.data),
        (err) => console.log(err)
    )
}

export const saveJobDetails = (jobId, reqBody, callBack, error) => {
    saveJobData(
        jobId,
        reqBody,
        (res) => callBack(res),
        (err) => {
            error(err)
        }
    )
}

// JobTable  Delete action
export const deleteJobById = (jobId, callBack) => {
    deleteJobDataById(
        jobId,
        (res) => callBack(res?.data),
        (err) => console.log(err)
    )
}

export const deleteArchivedJobById = (props, callBack) => {
    deleteArchivedJobData(
        props,
        (res) => callBack(res),
        (err) => console.log(err)
    )
}

//Job Table Sorting
export const getSortedJobTableList = (props, callBack) => {
    sortJobData(
        props,
        (res) => callBack(res?.data?.data),
        (err) => console.log(err)
    )
}

//publish
export const publishJobByID = (jobId, callBack) => {
    publishJob(
        jobId,
        (res) => callBack(res?.data),
        (err) => console.log(err)
    )
}

//
export const getRequirements = (materialId, callBack, error) => {
    fetchRequirements(
        materialId,
        (res) => callBack(res?.data),
        (err) => {
            console.log(err)
            // error(err)
        }
    )
}

export const uploadJobDocument = (props, callBack) => {
    uploadJobDocuments(
        props,
        (res) => callBack(res?.data),
        (err) => console.log(err)
    )
}

export const getReportJobDocument = (props, callBack) => {
    fetchReportDocuments(
        props,
        (res) => callBack(res?.data),
        (err) => console.log(err)
    )
}

export const getReportJobDocumentByObj = (props, callBack) => {
    fetchReportDocumentsByObjLink(
        props,
        (res) => callBack(res?.data),
        (err) => console.log(err)
    )
}

export const downloadDocumentTemplate = (props, callBack) => {
    fetchDocumentsTemplate(
        props,
        (res) => callBack(res?.data),
        (err) => console.log(err)
    )
}
