// import styled from '@emotion/styled'
import {
    Autocomplete,
    // Autocomplete,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Icon,
    IconButton,
    TextField,
    Typography,
    Checkbox,
    FormControlLabel,
    Switch,
} from '@mui/material'
import { Field } from 'formik'
import { createUser, getBranches, getRoles, updateUser } from 'api/Action'
import Dropzone, { getBase64 } from 'app/components/Dropzone'
import { StyledDivider } from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import Cookies from 'js-cookie'
import { Fragment, useEffect, useState } from 'react'
import * as yup from 'yup'
import { ContentBox } from '../../assets/styled-components/SharedComponents'

const initialValues = {
    name: '',
    emp_id: '',
    email: '',
    password: '',
    phoneNumber: '',
    roles: '',
    branch: '',
    status: 'ACTIVE',
    // signature: '',
    address: '',
    // organizations: ['62da24e23c1a7fd61d5dc526'],
}

const UserCreationForm = ({
    callBack,
    state,
    setState,
    toggle,
    setDialogMessage,
    setOpenDialog,
    signature1,
    setSignature1,
}) => {
    // const user = sessionStorage.getItem('userRole')
    const organisation = Cookies.get('organizations')

    const [roles, setRoles] = useState([])
    const [userRole, setUserRole] = useState('')
    const [branches, setBranches] = useState([])
    const [signature, setSignature] = useState(null)
    const [base64File, setBase64File] = useState('')
    const [visibility, setVisibility] = useState(true)
    const [branch, setBranch] = useState('')
    const [status, setStatus] = useState('ACTIVE')
    useEffect(() => {
        if (state && state.roles) {
            setUserRole(state.roles)
        }
    }, [state])
    const toggler = () => {
        setVisibility(!visibility)
    }

    const saveSignatureData = async (files) => {
        if (files[0]) {
            setSignature(files)
            let base64URL
            await getBase64(files[0]).then((result) => {
                base64URL = result
            })
            setBase64File(base64URL)
        } else {
            setBase64File('')
        }
    }

    useEffect(() => {
        if (state.signature) {
            setBase64File(state.signature)
        } else {
            setBase64File(null)
        }
    }, [])

    const handleSubmit = async (values, { resetForm, setFieldValue }) => {
        let branch =
            branches.find((branch) => branch.name == values.branch)?._id ?? ''
        //let roles = roles.find((role) => role.name == values.roles)?._id ?? ''
        let selectedRole = roles.find((role) => role.name === values.roles)
        let rolesId = selectedRole ? selectedRole._id : ''
        values.organizations = [JSON.parse(organisation)?._id ?? '']
        values.signature = base64File
        values.status = status

        const resetFunction = async () => {
            resetForm()

            await callBack()
            setBase64File('')
            setSignature(null)
            setState(initialValues)
            setFieldValue('roles', '')
            setFieldValue('branch', '')
        }

        const creationCallBack = async (message, success) => {
            if (success) {
                setDialogMessage(`${message?.data?.message}`)
                setOpenDialog(true)
                await resetFunction()
                toggle()
            } else {
                setDialogMessage(`User Creation Failed:- ${message}`)
                setOpenDialog(true)
            }
        }
        const updateCallBack = async (message, success) => {
            if (success) {
                setDialogMessage(`${message?.data?.message}`)
                setOpenDialog(true)
                await resetFunction()
                toggle()
            } else {
                setDialogMessage(`User Update Failed:- ${message}`)
                setOpenDialog(true)
            }
        }

        let payload = { ...values, branch: branch, roles: rolesId }

        if (state._id ?? '') {
            await updateUser(state._id, payload, updateCallBack)
        } else {
            await createUser(payload, creationCallBack)
        }
    }

    const handleStatusChange = () => {
        setStatus((prevStatus) =>
            prevStatus === 'ACTIVE' ? 'ARCHIVED' : 'ACTIVE'
        )
    }

    useEffect(() => {
        // Handle side effects or updates based on the 'status' state here
        console.log('Status has changed:', status)
    }, [status])

    useEffect(() => {
        getRoles()
            .then((response) => {
                setRoles(response.data)
            })
            .catch((error) => {
                setRoles([])
            })
        getBranches()
            .then((response) => {
                setBranches(response.data)
            })
            .catch((error) => {
                setBranches([])
            })
    }, [])

    const phoneRegExp =
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const validationSchema = yup.object({
        name: yup.string('Enter name').required('Name is required'),
        address: yup.string('Enter address').required('Address is required'),
        roles: yup.string('').required('Role is required'),
        // branch:
        //     userRole === 'CUSTOMER'
        //         ? yup.string('')
        //         : yup.string('').required('Branch is required'),
        // branch:
        //     userRole === 'CUSTOMER'
        //         ? yup.string('')
        //         : yup.string('').required('Branch is required'),
        email: yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required'),
        password: yup
            .string('Enter your password')
            .min(8, 'Password should be of minimum 8 characters ')
            .required('Password is required'),
        phoneNumber: yup
            .string()
            .matches(phoneRegExp, 'Phone number is not valid')
            .min(10, 'Phone number should be of minimum 10 characters ')
            .max(10, 'Phone numebr should be of maximum 10 characters '),

        // phoneNumber: yup
        //     .number('Enter your Phone Number')
        //     .min(10, 'Phone Number should be of minimum 10 characters length')
        //     .max(11, 'Phone Number should be of maximum 10 characters length')
        //     .required('Phone Number is required'),
    })

    return (
        <Fragment>
            <Formik
                initialValues={state}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setTouched,
                        setFieldTouched,
                        resetForm,
                        // ...props
                    } = props

                    return (
                        <Form onSubmit={handleSubmit}>
                            <ContentBox>
                                <Card>
                                    <CardHeader title="User Form" />
                                    <Divider />
                                    <CardContent sx={{ mt: 1 }}>
                                        <Grid container spacing={3}>
                                            <Grid
                                                item
                                                spacing={4}
                                                xs={12}
                                                container
                                            >
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={8}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label="Name"
                                                        size="small"
                                                        name="name"
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={
                                                            touched.name &&
                                                            Boolean(errors.name)
                                                        }
                                                        helperText={
                                                            touched.name &&
                                                            errors.name
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={8}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label="Email"
                                                        size="small"
                                                        name="email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={
                                                            touched.email &&
                                                            Boolean(
                                                                errors.email
                                                            )
                                                        }
                                                        helperText={
                                                            touched.email &&
                                                            errors.email
                                                        }
                                                    />
                                                </Grid>
                                                {/* {!state._id && ( */}
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={8}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label="Password"
                                                        type={
                                                            visibility
                                                                ? 'password'
                                                                : 'text'
                                                        }
                                                        size="small"
                                                        name="password"
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <IconButton
                                                                    onClick={
                                                                        toggler
                                                                    }
                                                                    disabled={
                                                                        state?._id
                                                                            ? true
                                                                            : false
                                                                    }
                                                                >
                                                                    {!state._id && (
                                                                        <Icon>
                                                                            {visibility
                                                                                ? 'visibility_off'
                                                                                : 'visibility'}
                                                                        </Icon>
                                                                    )}
                                                                </IconButton>
                                                            ),
                                                        }}
                                                        error={
                                                            touched.password &&
                                                            Boolean(
                                                                errors.password
                                                            )
                                                        }
                                                        helperText={
                                                            touched.password &&
                                                            errors.password
                                                        }
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={8}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label="Phone No."
                                                        name="phoneNumber"
                                                        size="small"
                                                        value={
                                                            values.phoneNumber
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={
                                                            touched.phoneNumber &&
                                                            Boolean(
                                                                errors.phoneNumber
                                                            )
                                                        }
                                                        helperText={
                                                            touched.phoneNumber &&
                                                            errors.phoneNumber
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={8}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label="Address"
                                                        name="address"
                                                        value={values.address}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        size="small"
                                                        multiline
                                                        rows={2}
                                                        maxRows={4}
                                                        error={
                                                            touched.address &&
                                                            Boolean(
                                                                errors.address
                                                            )
                                                        }
                                                        helperText={
                                                            touched.address &&
                                                            errors.address
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item spacing={3} container>
                                                <Grid
                                                    item
                                                    lg={8}
                                                    md={8}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Autocomplete
                                                        size="small"
                                                        id="tags-outlined"
                                                        options={
                                                            roles?.map(
                                                                (role) =>
                                                                    role.name
                                                            ) || []
                                                        }
                                                        value={values.roles}
                                                        // value={[values.roles]}
                                                        getOptionLabel={(
                                                            option,
                                                            value
                                                        ) => {
                                                            return option
                                                        }}
                                                        onBlur={() => {
                                                            setFieldTouched(
                                                                'roles',
                                                                true
                                                            )
                                                        }}
                                                        onChange={(
                                                            e,
                                                            value
                                                        ) => {
                                                            setFieldValue(
                                                                'roles',
                                                                value == null
                                                                    ? ''
                                                                    : value
                                                            )
                                                            setUserRole(value)
                                                        }}
                                                        name="roles"
                                                        filterSelectedOptions
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label="Role"
                                                                placeholder="Select Role"
                                                                fullWidth
                                                                error={
                                                                    touched.roles &&
                                                                    Boolean(
                                                                        errors.roles
                                                                    )
                                                                }
                                                                helperText={
                                                                    touched.roles &&
                                                                    errors.roles
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                {userRole !== 'CUSTOMER' && (
                                                    <Grid
                                                        item
                                                        lg={8}
                                                        md={8}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Autocomplete
                                                            size="small"
                                                            id="tags-outlined"
                                                            options={
                                                                branches?.map(
                                                                    (branch) =>
                                                                        branch.name
                                                                ) || []
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) => option}
                                                            value={
                                                                values.branch
                                                            }
                                                            onBlur={() => {
                                                                setFieldTouched(
                                                                    'branch',
                                                                    true
                                                                )
                                                            }}
                                                            onChange={(
                                                                e,
                                                                value
                                                            ) => {
                                                                setFieldValue(
                                                                    'branch',
                                                                    value ==
                                                                        null
                                                                        ? ''
                                                                        : value
                                                                )
                                                            }}
                                                            name="branch"
                                                            filterSelectedOptions
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    label="Branch"
                                                                    placeholder="Select Branch"
                                                                    fullWidth
                                                                    error={
                                                                        touched.branch &&
                                                                        Boolean(
                                                                            errors.branch
                                                                        )
                                                                    }
                                                                    helperText={
                                                                        touched.branch &&
                                                                        errors.branch
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                )}

                                                {userRole !== 'CUSTOMER' && (
                                                    <Grid
                                                        item
                                                        lg={8}
                                                        md={8}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Typography
                                                            variant="h6"
                                                            gutterBottom
                                                        >
                                                            Status
                                                        </Typography>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    name="statusToggle"
                                                                    color="primary"
                                                                    checked={
                                                                        status ===
                                                                        'ACTIVE'
                                                                    }
                                                                    onChange={
                                                                        handleStatusChange
                                                                    }
                                                                />
                                                            }
                                                            label={
                                                                status ===
                                                                'ACTIVE'
                                                                    ? 'ACTIVE'
                                                                    : 'ARCHIEVED'
                                                            }
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                            {userRole !== 'CUSTOMER' && (
                                                <>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            fontWeight={
                                                                'medium'
                                                            }
                                                            variant={'h6'}
                                                        >
                                                            Upload Signature
                                                        </Typography>
                                                        <StyledDivider />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Dropzone
                                                            saveFile={
                                                                saveSignatureData
                                                            }
                                                            base64Files={[
                                                                base64File,
                                                            ]}
                                                            signature1={
                                                                signature1
                                                            }
                                                            setSignature1={
                                                                setSignature1
                                                            }
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    </CardContent>
                                    <CardActions sx={{ justifyContent: 'end' }}>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            color="inherit"
                                            onClick={() => {
                                                resetForm()
                                                setState(initialValues)
                                                toggle()
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            type="submit"
                                            endIcon={<Icon>send</Icon>}
                                            disabled={
                                                values.roles !== 'CUSTOMER' &&
                                                !base64File
                                            }
                                            // onClick={handleClick}
                                            onClick={handleSubmit}
                                        >
                                            {state._id
                                                ? 'Update User'
                                                : 'Create User'}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </ContentBox>
                        </Form>
                    )
                }}
            </Formik>
        </Fragment>
    )
}

export default UserCreationForm
