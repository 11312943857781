import { useContext, useState } from 'react'

import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Modal,
    TextField,
    Typography,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import LightCompactionTable from './LightCompactionTable'
import { valueCalc } from '../../method/method'

const initialValues = {
    //waterContent
    // containerNumber: '',
    wt_container: '',
    wt_container_wetSoil: '',
    wt_container_drySoil: '',
    wt_water: '',
    wt_drySoil: '',
    water_content: '',
    //dryDensity
    wt_mould: '',
    wt_mould_soil: '',
    vol_mould: '',
    wt_compact_soil: '',
    wet_density: '',
    dry_density: '',
}

const validationSchema = Yup.object().shape({
    // containerNumber: Yup.number().required('Required'),
    wt_container: Yup.number().required('Required'),
    wt_container_wetSoil: Yup.number().required('Required'),
    wt_container_drySoil: Yup.number().required('Required'),
})
const LightCompactionModal = ({ result, isView, setOverall, overall }) => {
    const handleClose = () => setOpen(false)
    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)
    const [data, setData] = useState(result.data ? result.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const { finalData, setFinalData } = useContext(AppContext)
    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        arr = applyFormula(arr)
        let _finalData = finalData
        let dry_density_arr = []
        let water_content_arr = []
        _finalData.forEach((item) => {
            let maximunDryDensity = overall.maximunDryDensity
            let maximumWaterContent = overall.maximunDryDensity
            if (item.groupName === result.groupName && arr.length !== 0) {
                const maxDryDensityObj = arr.reduce((maxObj, currentObj) => {
                    const maxDensity = parseFloat(maxObj.dry_density)
                    const currentDensity = parseFloat(currentObj.dry_density)
                    return maxDensity > currentDensity ? maxObj : currentObj
                }, arr[0])

                maximunDryDensity = maxDryDensityObj.dry_density
                maximumWaterContent = maxDryDensityObj.water_content
            }
            if (item.groupName === result.groupName) {
                item.data = arr
                item.overall = {
                    ...overall,
                    maximunDryDensity: maximunDryDensity,
                    maximumWaterContent: maximumWaterContent,
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
        //resetForm()
    }

    const applyFormula = (arr) => {
        arr.forEach((item, index) => {
            //water_content
            item.wt_water = valueCalc(
                item.wt_container_wetSoil - item.wt_container_drySoil
            )
            item.wt_drySoil = valueCalc(
                item.wt_container_drySoil - item.wt_container
            )
            item.water_content = valueCalc(
                (item.wt_water / item.wt_drySoil) * 100
            )
            //dry Density
            item.wt_compact_soil = valueCalc(item.wt_mould_soil - item.wt_mould)
            item.wet_density = valueCalc(
                (item.wt_mould_soil - item.wt_mould) / item.vol_mould
            )
            item.dry_density = valueCalc(
                item.wet_density /
                    (1 + Number.parseFloat(item.water_content) / 100)
            )
        })
        return arr
    }

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props
                    return (
                        <Form>
                            <Modal open={open} onClose={handleClose}>
                                <StyledCard>
                                    <CardHeader title="Light Compaction" />
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    Optimum Moisture Content
                                                </Typography>
                                            </Grid>
                                            {/* <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Container Number"
                                                    value={
                                                        values.containerNumber
                                                    }
                                                    name="containerNumber"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.containerNumber &&
                                                        touched.containerNumber
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.containerNumber &&
                                                    touched.containerNumber
                                                        ? errors.containerNumber
                                                        : ''}
                                                </HelperText>
                                            </Grid>{' '} */}
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Weight of container W1 g"
                                                    value={values.wt_container}
                                                    name="wt_container"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.wt_container &&
                                                        touched.wt_container
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.wt_container &&
                                                    touched.wt_container
                                                        ? errors.wt_container
                                                        : ''}
                                                </HelperText>
                                            </Grid>{' '}
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Weight of Container + Wet soil (g) W2"
                                                    value={
                                                        values.wt_container_wetSoil
                                                    }
                                                    name="wt_container_wetSoil"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.wt_container_wetSoil &&
                                                        touched.wt_container_wetSoil
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.wt_container_wetSoil &&
                                                    touched.wt_container_wetSoil
                                                        ? errors.wt_container_wetSoil
                                                        : ''}
                                                </HelperText>
                                            </Grid>{' '}
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Weight of Container + Dry soil (g) W3"
                                                    value={
                                                        values.wt_container_drySoil
                                                    }
                                                    name="wt_container_drySoil"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.wt_container_drySoil &&
                                                        touched.wt_container_drySoil
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.wt_container_drySoil &&
                                                    touched.wt_container_drySoil
                                                        ? errors.wt_container_drySoil
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    Maximum Dry Density
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Weight of Mould (W1) g"
                                                    value={values.wt_mould}
                                                    name="wt_mould"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.wt_mould &&
                                                        touched.wt_mould
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.wt_mould &&
                                                    touched.wt_mould
                                                        ? errors.wt_mould
                                                        : ''}
                                                </HelperText>
                                            </Grid>{' '}
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Weight of mould + Compacted soil (w2) g"
                                                    value={values.wt_mould_soil}
                                                    name="wt_mould_soil"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.wt_mould_soil &&
                                                        touched.wt_mould_soil
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.wt_mould_soil &&
                                                    touched.wt_mould_soil
                                                        ? errors.wt_mould_soil
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Volume of mould (cm³)"
                                                    value={values.vol_mould}
                                                    name="vol_mould"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.vol_mould &&
                                                        touched.vol_mould
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.vol_mould &&
                                                    touched.vol_mould
                                                        ? errors.vol_mould
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </StyledCard>
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
            {data && (
                <LightCompactionTable
                    data={data}
                    setData={setData}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    result={result}
                    overall={overall}
                    setOverall={setOverall}
                />
            )}
            {isView !== true && (
                <Grid container justifyContent="end">
                    <Button
                        sx={{ boxShadow: 'none', mt: 2 }}
                        variant="contained"
                        endIcon={<AddCircle />}
                        onClick={handleOpen}
                        size="small"
                    >
                        Add
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default LightCompactionModal
