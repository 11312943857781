import { ExpandMore } from '@mui/icons-material'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Divider,
    Grid,
    InputLabel,
    styled,
    TextField,
    Typography,
} from '@mui/material'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'
import { Fragment, useState } from 'react'
import HalfCelPotentialDiferenceteModal from './HalfCelPotentialDiferenceteModal'

export const ContentBox = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
    borderColor: '#e7e5e5',
}))

const HalfCelPotentialDiferencete = ({
    result,
    isView,
    overall,
    setOverall,
    handleCustomRequirementChange,
    handleRequirementChange,
}) => {
    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <JobAccordion
                        title={result?.groupName}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        requirementvalue={overall?.requirements}
                        remarksValue={overall?.remarks}
                        overall={overall}
                    >
                        <HalfCelPotentialDiferenceteModal
                            result={result}
                            isView={isView}
                            overall={overall}
                            setOverall={setOverall}
                        />
                    </JobAccordion>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default HalfCelPotentialDiferencete
