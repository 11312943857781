import { TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { Box } from '@mui/system'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const SoilSpecificGravityTable = ({ data }) => {
    let specificGravity
    specificGravity = [
        {
            observation: 'Weight of bottle (W1), g',
            result: 'weight_of_bottle_w1',
        },
        {
            observation: 'Weight of bottle + soils (W2), g',
            result: 'weight_of_bottle_w2',
        },
        {
            observation: 'Weight of bottle + soils + water  (W3), g',
            result: 'weight_of_bottle_w3',
        },
        {
            observation: 'Weight of bottle + water  (W4), g',
            result: 'weight_of_bottle_w4',
        },
        {
            observation: 'Specific Gravity (W2-W1)/(W4-W1) - (W3-W2)',
            result: 'total_weight',
        },
    ]

    const Column = [
        { text: 'Observation', dataField: 'observation' },
        { text: 'Result', dataField: 'result' },
    ]
    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        {/* <TableCell align="center">Actions</TableCell> */}
                        <TableCell align="center">SI.No</TableCell>
                        {Column.map((item, index) => (
                            <TableCell align="center" key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                {specificGravity?.map((item, index) => (
                    <TableBody>
                        <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">
                                {item.observation}
                            </TableCell>
                            <TableCell align="center">
                                {valueCalc(data[item.result])}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ))}
            </StyledTable>
        </Box>
    )
}

export default SoilSpecificGravityTable
