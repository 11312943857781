import { StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'

const styles = StyleSheet.create({
    dataContainer: {
        flexDirection: 'row',
        marginTop: '1px',
        flexWrap: 'wrap',
    },
    value: {
        fontSize: 10,
    },
    label: {
        fontSize: '10px',
        fontFamily: 'Times-Bold',
        marginRight: '8px',
    },
})
const Remarks = ({ details }) => {
    return (
        <>
            {details?.reason?.trim().length > 0 && (
                <View style={styles.dataContainer}>
                    <Text style={styles.label}>Remarks :</Text>
                    <View style={styles.value}>
                        <Text>{details?.reason}</Text>
                    </View>
                </View>
            )}
        </>
    )
}

export default Remarks
