import {
    Card,
    CardContent,
    CardHeader,
    FormControlLabel,
    Grid,
    Radio,
    TextField,
    Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import {
    HelperText,
    StyledDivider,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

const validationSchema = Yup.object().shape({
    invoice_no: Yup.string().required('Required'),
    status: Yup.string().required('Required'),
    date: Yup.string().required('Required'),
    startedOn: Yup.string().required('Required'),
    completedOn: Yup.string().required('Required'),
    paymentPaid: Yup.string().required('Required'),
    paymentGP: Yup.string().required('Required'),
})

const InvoiceDetails = (props) => {
    const { isView, initialState, onhandleChange } = props
    return (
        <>
            {/* // <Grid item xs={12}    > */}
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                // onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        // values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Card>
                                <CardHeader title="Invoice Details" />
                                <StyledDivider />
                                <CardContent>
                                    <Grid container spacing={5}>
                                        <Grid item xs={4}>
                                            <TextField
                                                id="outlined-basic"
                                                required
                                                fullWidth
                                                label="Invoice No"
                                                variant="outlined"
                                                disabled={isView}
                                                value={
                                                    initialState?.invoice_no
                                                        ? initialState?.invoice_no
                                                        : ''
                                                }
                                                name="invoice_no"
                                                onChange={(event) => {
                                                    handleChange(event)
                                                    onhandleChange(event)
                                                }}
                                                onBlur={handleBlur}
                                                error={
                                                    errors.invoice_no &&
                                                    touched.invoice_no
                                                }
                                            />
                                            <HelperText>
                                                {errors.invoice_no &&
                                                touched.invoice_no
                                                    ? errors.invoice_no
                                                    : ''}
                                            </HelperText>
                                        </Grid>
                                        {/* <Grid item xs={4}>
                                            <LocalizationProvider
                                                dateAdapter={AdapterDateFns}
                                            >
                                                <DatePicker
                                                    label="Test Started On"
                                                    disabled={isView}
                                                    inputFormat="dd/MM/yyyy"
                                                    value={
                                                        initialState?.startedOn
                                                    }
                                                    name="startedOn"
                                                    onBlur={handleBlur}
                                                    onChange={(date) =>
                                                        onhandleChange({
                                                            target: {
                                                                value: date,
                                                                name: 'startedOn',
                                                            },
                                                        })
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                            <HelperText>
                                                {errors.startedOn &&
                                                touched.startedOn
                                                    ? errors.startedOn
                                                    : ''}
                                            </HelperText>
                                        </Grid>{' '} */}
                                        {/* <Grid item xs={4}>
                                            <LocalizationProvider
                                                dateAdapter={AdapterDateFns}
                                            >
                                                <DatePicker
                                                    label="Test Completed On"
                                                    disabled={isView}
                                                    inputFormat="dd/MM/yyyy"
                                                    value={
                                                        initialState?.completedOn
                                                    }
                                                    name="completedOn"
                                                    onChange={(date) =>
                                                        onhandleChange({
                                                            target: {
                                                                value: date,
                                                                name: 'completedOn',
                                                            },
                                                        })
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                            <HelperText>
                                                {errors.completedOn &&
                                                touched.completedOn
                                                    ? errors.completedOn
                                                    : ''}
                                            </HelperText>
                                        </Grid> */}
                                        <Grid item xs={4}>
                                            <TextField
                                                required
                                                id="outlined-basic"
                                                fullWidth
                                                label="Status"
                                                variant="outlined"
                                                disabled={isView}
                                                value={
                                                    initialState?.status
                                                        ? initialState?.status
                                                        : ''
                                                }
                                                name="status"
                                                onChange={(event) => {
                                                    handleChange(event)
                                                    onhandleChange(event)
                                                }}
                                                onBlur={handleBlur}
                                                error={
                                                    errors.status &&
                                                    touched.status
                                                }
                                            />
                                            <HelperText>
                                                {errors.status && touched.status
                                                    ? errors.status
                                                    : ''}
                                            </HelperText>
                                        </Grid>
                                        {/* <Grid item xs={3}>
                                            <LocalizationProvider
                                                dateAdapter={
                                                    AdapterDateFns
                                                }
                                            >
                                                <DatePicker
                                                    label="Date"
                                                    disabled={isView}
                                                    inputFormat="dd/MM/yyyy"
                                                    value={initialState?.date || new Date()}
                                                    name="date"
                                                    onChange={onhandleDate}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                            <HelperText>
                                                {errors.date &&
                                                    touched.date
                                                    ? errors.date
                                                    : ''}
                                            </HelperText>
                                        </Grid> */}
                                        <Grid item xs={4}>
                                            <TextField
                                                required
                                                id="outlined-basic"
                                                fullWidth
                                                label="Payment Paid"
                                                variant="outlined"
                                                disabled={isView}
                                                value={
                                                    initialState?.paymentPaid
                                                        ? initialState?.paymentPaid
                                                        : ''
                                                }
                                                name="paymentPaid"
                                                onChange={(event) => {
                                                    handleChange(event)
                                                    onhandleChange(event)
                                                }}
                                                onBlur={handleBlur}
                                                error={
                                                    errors.paymentPaid &&
                                                    touched.paymentPaid
                                                }
                                            />
                                            <HelperText>
                                                {errors.paymentPaid &&
                                                touched.paymentPaid
                                                    ? errors.paymentPaid
                                                    : ''}
                                            </HelperText>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                required
                                                id="outlined-basic"
                                                fullWidth
                                                label="Payment GP"
                                                variant="outlined"
                                                disabled={isView}
                                                value={
                                                    initialState?.paymentGP
                                                        ? initialState?.paymentGP
                                                        : ''
                                                }
                                                name="paymentGP"
                                                onChange={(event) => {
                                                    handleChange(event)
                                                    onhandleChange(event)
                                                }}
                                                onBlur={handleBlur}
                                                error={
                                                    errors.paymentGP &&
                                                    touched.paymentGP
                                                }
                                            />
                                            <HelperText>
                                                {errors.paymentGP &&
                                                touched.paymentGP
                                                    ? errors.paymentGP
                                                    : ''}
                                            </HelperText>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Typography>
                                                Invoice Status
                                            </Typography>
                                            <FormControlLabel
                                                name="invoiceStatus"
                                                value="show"
                                                checked={
                                                    initialState?.invoiceStatus ===
                                                    'show'
                                                }
                                                onChange={(event) => {
                                                    handleChange(event)
                                                    onhandleChange(event)
                                                }}
                                                control={<Radio />}
                                                label="Show"
                                            />
                                            <FormControlLabel
                                                name="invoiceStatus"
                                                checked={
                                                    initialState?.invoiceStatus ===
                                                    'hide'
                                                }
                                                onChange={(event) => {
                                                    handleChange(event)
                                                    onhandleChange(event)
                                                }}
                                                value="hide"
                                                control={<Radio />}
                                                label="Hide"
                                            />
                                        </Grid>
                                    </Grid>
                                    <br />
                                </CardContent>
                                {/* <CardActions> */}
                                {/* <Button
                size="small"
                // onClick={handleClose}
            >
                Cancel
            </Button> */}
                                {/* <Button
                size="small"
                variant="contained"
                onClick={handleSubmit}
            >
                Add
            </Button> */}
                                {/* </CardActions> */}
                            </Card>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}

export default InvoiceDetails
