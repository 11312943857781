import AuthGuard from 'app/auth/AuthGuard'
import chartsRoute from 'app/views/charts/ChartsRoute'
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes'
import jobCardRoutes from 'app/views/jobs/JobCardRoutes'
import materialRoutes from 'app/views/material-kit/MaterialRoutes'
import NotFound from 'app/views/sessions/NotFound'
import sessionRoutes from 'app/views/sessions/SessionRoutes'
import { Navigate } from 'react-router-dom'
import { MatxLayout } from './components'
import ReportIndex from 'app/views/report/ReportIndex'

const rootRoutes = [
    {
        element: (
            <AuthGuard>
                <MatxLayout />
            </AuthGuard>
        ),
        children: [
            ...dashboardRoutes,
            ...chartsRoute,
            ...materialRoutes,
            ...jobCardRoutes,
        ],
    },
    ...sessionRoutes,
    {
        path: '/',
        element: <Navigate to="session/signin" />,
    },
    {
        path: '*',
        element: <NotFound />,
    },
    {
        path: 'pdf/download/:id/:name',
        element: <ReportIndex />,
    },
]

export default rootRoutes
