import styled from '@emotion/styled'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Alert, Button, Grid, Icon, Snackbar, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import { PDFDownloadLink, PDFViewer, StyleSheet } from '@react-pdf/renderer'
import { configLicense } from 'Conflit/Features'
import { MatxLoading } from 'app/components'
import { getReportData, sendMailReport } from 'app/redux/actions/ReportAction'
import { findBase64 } from 'app/utils/utils'
import Cookies from 'js-cookie'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import DialogBox from './DialogBox'
import Report from './Report'

const ContentBox = styled(Box)(({ theme }) => ({
    height: '100%',
    margin: '30px',
}))

const styles = StyleSheet.create({
    pdf: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        border: '1px solid black',
    },
})

const role = Cookies.get('userRole')

const ReportIndex = () => {
    const params = useParams()
    const [loading, setLoading] = useState(true)
    let search = window.location.search
    let param = new URLSearchParams(search)
    let type = param.get('type')
    let category = param.get('category')
    let content
    // const ButtonMailto = ({ mailto, label }) => {
    //     return (
    //         <Link
    //             to='#'
    //             onClick={(e) => {
    //                 window.location.href = mailto;
    //                 e.preventDefault();
    //             }}
    //         >
    //             {label}
    //         </Link>
    //     );
    // };
    let organizationName = Cookies.get('organizationName')
    const [value, setValue] = useState(1)

    const [title, setTitle] = useState('')
    const [customerName, setCutomerName] = useState('')

    const [details, setDetails] = useState({})
    const [email, setEmail] = useState()
    const [data, setData] = useState({})
    const [result, setResult] = useState([])
    const [base64Data, setBase64Data] = useState()
    const [selectedResult, setSelectedResult] = useState({})
    const [open, setOpen] = useState(false)
    const [snack, setSnack] = useState({
        snackOpen: false,
        snackVaraint: '',
        snackMsg: '',
    })
    const emailData = useRef()

    useEffect(() => {
        getReportData(params.id, (res) => {
            setDetails(res?.data)
            setData(res?.data?.customer_samples?.customer)
            setEmail(res?.data?.customer_samples?.customer?.email)
            emailData.current = res?.data?.customer_samples?.customer?.email
            setResult(res?.data?.result)
            setCutomerName(res?.data?.customer_samples?.customer?.name)
            if (
                res?.data &&
                res?.data?.result &&
                res?.data?.result?.length !== 0
            ) {
                setSelectedResult(res?.data?.result[0])
                setTitle(res?.data?.result[0]?.groupName)

                setLoading(false)
            }
        })
    }, [])

    // const handleChangeEmail = (event) => {
    //     setEmail(event.target.value)
    // }

    const handleChange = (event, newValue) => {
        setSelectedResult(result[newValue - 1])
        setValue(newValue)
    }

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const handlePdf = () => {
        const jsonData = JSON.stringify(content)
        let fileName =
            type === 'report' || type === 'reportOnly'
                ? details?.material?.name
                : selectedResult?.groupName
        let payload = {
            email: emailData.current,
            subject: 'Test Reports',
            customerName: customerName,
            sampleName: fileName,
            // url: `${API_BASE_URL}/pdf/download/${params.id}/${title}?type=${type}&category=download`,
            content: jsonData,
            headers: { 'Content-Type': 'application/json' },
        }

        if (payload) {
            setOpen(false)
            sendMailReport(payload, (res) => {
                let msg = res?.data?.message

                if (res.status === 200) {
                    setSnack({
                        ...snack,
                        snackOpen: 'true',
                        snackMsg: msg,
                        snackVaraint: 'success',
                    })
                } else {
                    setSnack({
                        ...snack,
                        snackOpen: 'false',
                        snackMsg: msg,
                        snackVaraint: 'error',
                    })
                }
            })
            // }).catch((error) => {
            // })
        } else {
            setSnack({
                ...snack,
                snackOpen: 'false',
                snackMsg: 'Something went wrong',
                snackVaraint: 'error',
            })
        }
    }
    const handleSnackClose = () => setSnack(false)

    const materialName = details?.material?.name
    const materialNameArray = materialName ? [materialName] : []

    if (loading) {
        return <MatxLoading />
    } else {
        return (
            <ContentBox>
                {/* //pdf Donload */}
                <Grid container spacing={2} sx={{ mb: 4 }}>
                    <Grid item xs={12} md={6} lg={4}>
                        <PDFDownloadLink
                            document={
                                <Report
                                    details={details}
                                    data={data}
                                    result={result}
                                    params={params}
                                    type={type}
                                    selectedResult={selectedResult}
                                    organizationName={organizationName}
                                />
                            }
                            fileName={
                                type === 'report' || type === 'reportOnly'
                                    ? `${details?.job_no} ${details?.material?.name} Report`
                                    : (type === 'observation' ||
                                            type === 'testReport' ||
                                            type === 'observationNull') &&
                                        selectedResult?.groupName ===
                                            'DYNAMIC_JOBCARD'
                                      ? `${details?.job_no} ${details?.material?.name} Report`
                                      : `${details?.job_no} ${selectedResult?.groupName} Report`
                            }
                        >
                            {({ loading, url, blob }) => {
                                findBase64(blob).then((data) => {
                                    content = data
                                })
                                return (
                                    <Button
                                        disabled={loading}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        endIcon={<Icon>sim_card_download</Icon>}
                                    >
                                        {loading
                                            ? 'Loading...'
                                            : type === 'observation'
                                              ? 'Download  Observation Report'
                                              : 'Download Report'}
                                    </Button>
                                )
                            }}
                        </PDFDownloadLink>
                    </Grid>

                    {configLicense.pdfMailing === true &&
                        category == null &&
                        role !== 'CUSTOMER' && (
                            <Grid item xs={12} md={6} lg={3}>
                                <Button
                                    variant="outlined"
                                    disabled={loading}
                                    size="small"
                                    endIcon={<Icon>email</Icon>}
                                    fullWidth
                                    onClick={handleOpen}
                                >
                                    {type === 'observation'
                                        ? 'Send  Observation Report'
                                        : 'Send Report'}
                                </Button>
                            </Grid>
                        )}

                    {category === 'download' && (
                        <Grid
                            item
                            container
                            xs={12}
                            justifyContent="flex-start"
                        >
                            <Grid item>
                                <Typography
                                    sx={{ marginRight: 0.5 }}
                                    fontWeight={'bolder'}
                                    variant="caption"
                                >
                                    Note :
                                </Typography>{' '}
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    If the Pdf Viewer is not Supported on your
                                    Device... Please use the above
                                    <Box fontWeight="bolder" display="inline">
                                        {' '}
                                        Download Button
                                    </Box>{' '}
                                    to grap your Report..
                                    <Box> Thank You.. Have a Nice Day </Box>
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Grid>

                <DialogBox
                    value={value}
                    open={open}
                    handleClose={handleClose}
                    handlePdf={handlePdf}
                    setEmail={setEmail}
                    email={email}
                    // handleChangeEmail={handleChangeEmail}
                    emailData={emailData}
                />

                {/* <Snackbar
                    open={snack.snackOpen}
                    severity={snack.snackVaraint}
                    autoHideDuration={2000}
                    onClose={() =>
                        setSnack({
                            ...snack,
                            snackOpen: false,
                        })
                    }
                >
                    <Alert onClose={handleSnackClose} severity="success">
                        {snack.snackMsg}
                    </Alert>
                </Snackbar> */}
                <Snackbar
                    open={snack.snackOpen}
                    autoHideDuration={2000}
                    onClose={() =>
                        setSnack({
                            ...snack,
                            snackOpen: false,
                        })
                    }
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert
                        onClose={handleSnackClose}
                        severity={snack.snackVariant}
                    >
                        {snack.snackMsg}
                    </Alert>
                </Snackbar>

                {/* pdf View */}

                {(type === 'report' ||
                    type === 'reportOnly' ||
                    category === 'download') && (
                    <PDFViewer style={styles.pdf}>
                        {loading ? (
                            <MatxLoading />
                        ) : (
                            <Report
                                details={details}
                                data={data}
                                result={result}
                                params={params}
                                type={type}
                                selectedResult={selectedResult}
                                organizationName={organizationName}
                            />
                        )}
                    </PDFViewer>
                )}
                {(type === 'observation' ||
                    type === 'observationNull' ||
                    (type === 'testReport' && category !== 'download')) && (
                    <TabContext value={value}>
                        <Box display={'flex'} flexDirection="row">
                            <TabList
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                {/* {result.map((group, index) => (
                                    <Tab label={group.groupName} value={index + 1} />
                                ))} */}
                                {organizationName !== 'CareLab' &&
                                selectedResult.groupName === 'DYNAMIC_JOBCARD'
                                    ? materialNameArray.map((name, index) => (
                                          <Tab
                                              key={index}
                                              label={name}
                                              value={index + 1}
                                          />
                                      ))
                                    : organizationName !== 'CareLab'
                                      ? result.map((group, index) => (
                                            <Tab
                                                key={index}
                                                label={group.groupName}
                                                value={index + 1}
                                            />
                                        ))
                                      : materialNameArray.map((name, index) => (
                                            <Tab
                                                key={index}
                                                label={name}
                                                value={index + 1}
                                            />
                                        ))}
                            </TabList>
                        </Box>
                        {selectedResult &&
                            result.map((group, index) => (
                                <TabPanel
                                    sx={{ height: '100%' }}
                                    value={index + 1}
                                >
                                    <PDFViewer style={styles.pdf}>
                                        {selectedResult > 0 ? (
                                            <MatxLoading />
                                        ) : (
                                            <Report
                                                details={details}
                                                data={data}
                                                selectedResult={selectedResult}
                                                params={params}
                                                result={result}
                                                organizationName={
                                                    organizationName
                                                }
                                                type={type}
                                            />
                                        )}
                                    </PDFViewer>
                                </TabPanel>
                            ))}
                    </TabContext>
                )}
            </ContentBox>
        )
    }
}

export default ReportIndex
