import { Box, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const CrushingValueTable = (props) => {
    const { data } = props

    //Formula
    // let value = ((data.weight_fraction_passing_through / data.weight_surface_dry_sample) * 100)
    // data.aggregate_crushing_value = value ? valueCalc(value) : '0'

    let crushingValue

    crushingValue = [
        {
            label: 'Weight of the oven-dried sample, A (Kg)',
            value: 'weight_surface_dry_sample',
        },
        {
            label: 'Weight of fraction passing through 2.36mm seive after loading, B (kg)',
            value: 'weight_fraction_passing_through',
        },
        {
            label: 'Aggregate Crushing Value (B/A)* 100',
            value: 'aggregate_crushing_value',
        },
    ]

    const Column = [
        {
            text: 'Load',
            dataField: 'label',
        },
        {
            text: '40 Tonees',
            dataField: 'value',
        },
    ]

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">SI.No</TableCell>
                        {Column.map((item, index) => (
                            <TableCell align="center" key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {crushingValue?.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{item.label}</TableCell>
                            <TableCell align="center">
                                {data[item.value] ? data[item.value] : '0'}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default CrushingValueTable
