import { Grid } from '@mui/material'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'
import { Fragment } from 'react'
import HeavyCompactionModal from './HeavyCompactionModal'

const HeavyCompaction = ({
    result,
    isView,
    overall,
    setOverall,
    handleCustomRequirementChange,
    handleRequirementChange,
}) => {
    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <JobAccordion
                        title={result?.groupName}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        requirementvalue={overall?.requirements}
                        remarksValue={overall?.remarks}
                        overall={overall}
                        averagevalue={
                            overall?.maximunDryDensity === -Infinity
                                ? '0'
                                : overall?.maximunDryDensity
                        }
                        avgLabel={' Maximum Dry Density g/cm3'}
                        avgLabel2={' Optimum Moisture Content %'}
                        averagevalue2={
                            overall?.maximumWaterContent === -Infinity
                                ? '0'
                                : overall?.maximumWaterContent
                        }
                    >
                        <HeavyCompactionModal
                            result={result}
                            isView={isView}
                            overall={overall}
                            setOverall={setOverall}
                        />
                    </JobAccordion>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default HeavyCompaction
