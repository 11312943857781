import { Fragment, useContext, useState } from 'react'

import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Modal,
    TextField,
} from '@mui/material'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { AppContext } from 'app/contexts/AppContext'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { valueCalc } from '../../method/method'
import ACTTable from './ACTTable'

const ConcreteCubeByACTMethod = (props) => {
    const { finalData, setFinalData, EditDetails } = useContext(AppContext)

    const {
        result,
        isView,
        overall,
        setOverall,
        handleCustomRequirementChange,
        handleRequirementChange,
    } = props

    const initialValues = {
        sampleId: EditDetails?.data?.sampleId
            ? EditDetails?.data?.sampleId
            : ' ',
        weight: '',
        compressiveLoad: '',
        accelerated_comp: '',
        estimated_comp: '',
    }

    const validationSchema = Yup.object().shape({
        sampleId: Yup.string().required('Required'),
        weight: Yup.string().required('Required'),
        compressiveLoad: Yup.number().required('Required'),
    })

    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [initialState, setInitialState] = useState({ ...initialValues })

    const [data, setData] = useState(result?.data ? result?.data : [])
    // const [overall, setOverall] = useState(
    //     result?.overall ? result?.overall : {}
    // )

    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        arr = applyFormula(arr)
        let _finalData = finalData
        let average = 0
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && arr.length !== 0) {
                arr.forEach((item1) => {
                    let value = valueCalc(item1.estimated_comp / arr.length)

                    average += Number(value)
                })

                item.overall = {
                    ...overall,
                    avgCompStr: valueCalc(average),
                }
                setOverall(item.overall)
            }
            if (item.groupName === result.groupName) {
                item.data = arr
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
    }

    const applyFormula = (arr) => {
        arr.forEach((item, index) => {
            let accelerated_comp = valueCalc(
                (Number(item?.compressiveLoad) * 1000) / 22500
            )
            item.accelerated_comp = accelerated_comp
            let estimated_comp = valueCalc(
                1.64 * Number(item?.accelerated_comp) + 8.09
            )
            item.estimated_comp = estimated_comp
        })
        return arr
    }

    return (
        <Fragment>
            <Grid item>
                <Formik
                    initialValues={initialState}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        } = props
                        return (
                            <Form>
                                <Grid
                                    container
                                    spacing={5}
                                    sx={{ pt: 1, mb: 2 }}
                                    justifyContent="space-evenly"
                                >
                                    <Grid item xs={3}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                        >
                                            <DatePicker
                                                disabled
                                                label="Date of Casting"
                                                inputFormat="dd/MM/yyyy"
                                                value={
                                                    EditDetails?.data
                                                        ?.castingDate
                                                        ? EditDetails?.data
                                                              ?.castingDate
                                                        : '-'
                                                }
                                                name="doc"
                                                // onChange={onhandleDateOfCasting}
                                                error={
                                                    errors.doc && touched.doc
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        size="small"
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>

                                        <HelperText>
                                            {errors.doc && touched.doc
                                                ? errors.doc
                                                : ''}
                                        </HelperText>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            size="small"
                                            disabled
                                            id="outlined-basic"
                                            required
                                            fullWidth
                                            label="Age (Days)"
                                            name="age"
                                            value={
                                                EditDetails?.data?.testingDate
                                                    ? EditDetails?.data
                                                          ?.testingDate
                                                    : '-'
                                            }
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.age && touched.age}
                                        />
                                        <HelperText>
                                            {errors.age && touched.age
                                                ? errors.age
                                                : ''}
                                        </HelperText>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                        >
                                            <DatePicker
                                                disabled
                                                label="Date of Testing"
                                                inputFormat="dd/MM/yyyy"
                                                value={
                                                    EditDetails?.data
                                                        ?.dateofTesting
                                                        ? EditDetails?.data
                                                              ?.dateofTesting
                                                        : '-'
                                                }
                                                name="dot"
                                                // onChange={handleChange}
                                                // onBlur={handleBlur}
                                                // error={
                                                //     errors.dot && touched.dot
                                                // }
                                                renderInput={(params) => (
                                                    <TextField
                                                        size="small"
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>

                                        <HelperText>
                                            {errors.dot && touched.dot
                                                ? errors.dot
                                                : ''}
                                        </HelperText>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <JobAccordion
                                        title={result?.groupName}
                                        requirementvalue={overall?.requirements}
                                        remarksValue={overall?.remarks}
                                        handleCustomRequirementChange={
                                            handleCustomRequirementChange
                                        }
                                        handleRequirementChange={
                                            handleRequirementChange
                                        }
                                        overall={overall}
                                        avgLabel={'Avg. Comp.Str (N/mm²)'}
                                        averagevalue={overall?.avgCompStr}
                                    >
                                        <Grid container spacing={2}>
                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                            >
                                                <StyledCard>
                                                    <CardHeader
                                                        title={
                                                            result?.groupName
                                                        }
                                                    />
                                                    <CardContent
                                                        sx={{
                                                            m: 4,
                                                        }}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={4}
                                                        >
                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    // disabled
                                                                    name="sampleId"
                                                                    value={
                                                                        values.sampleId
                                                                    }
                                                                    label="Sample ID"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.sampleId &&
                                                                        touched.sampleId
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.sampleId &&
                                                                    touched.sampleId
                                                                        ? errors.sampleId
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    value={
                                                                        values.weight
                                                                    }
                                                                    name="weight"
                                                                    label="Weight"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.weight &&
                                                                        touched.weight
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.weight &&
                                                                    touched.weight
                                                                        ? errors.weight
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    value={
                                                                        values.compressiveLoad
                                                                    }
                                                                    name="compressiveLoad"
                                                                    label="Compressive Load, F (kN)"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.compressiveLoad &&
                                                                        touched.compressiveLoad
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.compressiveLoad &&
                                                                    touched.compressiveLoad
                                                                        ? errors.compressiveLoad
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button
                                                            size="small"
                                                            onClick={
                                                                handleClose
                                                            }
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            size="small"
                                                            variant="contained"
                                                            type="submit"
                                                            onClick={
                                                                handleSubmit
                                                            }
                                                        >
                                                            Save
                                                        </Button>
                                                    </CardActions>
                                                </StyledCard>
                                            </Modal>

                                            {data && (
                                                <Grid item>
                                                    <ACTTable
                                                        data={data}
                                                        setData={setData}
                                                        setFinalData={
                                                            setFinalData
                                                        }
                                                        result={result}
                                                        finalData={finalData}
                                                        setOverall={setOverall}
                                                        overall={overall}
                                                    />
                                                </Grid>
                                            )}

                                            {isView !== true && (
                                                <Grid
                                                    item
                                                    container
                                                    justifyContent="end"
                                                >
                                                    <Button
                                                        sx={{
                                                            boxShadow: 'none',
                                                        }}
                                                        variant="contained"
                                                        endIcon={<AddCircle />}
                                                        onClick={handleOpen}
                                                        size="small"
                                                    >
                                                        Add
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </JobAccordion>
                                </Grid>
                            </Form>
                        )
                    }}
                </Formik>
            </Grid>
        </Fragment>
    )
}

export default ConcreteCubeByACTMethod
