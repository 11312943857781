import { StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'

const borderColor = '#ffffff'
const labelfontfamily = 'Times-Bold'
const fontSize = 10
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        backgroundColor: '#d9d9d9',
        alignItems: 'center',

        height: 'auto',
        // flexWrap: 'wrap',
        // wordWrap: 'break-word',
        fontSize: fontSize,
        fontFamily: labelfontfamily,

        maxWidth: '100%',
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
        paddingTop: 2,
    },

    sNo: {
        width: '8%',
        paddingLeft: 3,
        paddingRight: 3,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    description: {
        // borderRightColor: borderColor,
        width: '15%',
        paddingLeft: 3,
        paddingRight: 3,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },

    qty: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },

    data: {
        // borderRightColor: borderColor,
        width: '25%',
        paddingLeft: 3,
        paddingRight: 3,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',

        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    lastData: {
        paddingLeft: 3,
        paddingRight: 3,
        textAlign: 'center',
        width: '25%',

        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    _data: {
        width: '30%',
        paddingLeft: 3,
        paddingRight: 3,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    _lastData: {
        width: '30%',
        paddingLeft: 3,
        paddingRight: 3,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    _data2: {
        width: '27%',
        paddingLeft: 3,
        paddingRight: 3,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    _lastData2: {
        width: '27%',
        paddingLeft: 3,
        paddingRight: 3,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    rowContainer: {
        flexDirection: 'column',
        borderBottomColor: '#ffffff',
        backgroundColor: '#d9d9d9',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 'auto',

        maxWidth: '100%',
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    rowData: {
        width: '80%',
        paddingLeft: 3,
        paddingRight: 3,
        border: '1px solid #d9d9d9',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    rowLastData: {
        width: '20%',
        paddingLeft: 3,
        paddingRight: 3,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        alignItems: 'center',
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    container1: {
        //display: 'flex',
        // // justifyContent:'flex-end',
        // flexDirection: 'row',
        // backgroundColor: '#d9d9d9',
        // alignItems: 'center',

        // height: 'auto',
        // // flexWrap: 'wrap',
        // // wordWrap: 'break-word',
        // fontSize: fontSize,
        // fontFamily: labelfontfamily,

        // maxWidth: '100%',
        // textAlign: 'center',
        // fontStyle: 'bold',
        // flexGrow: 1,

        maxWidth: '100%',
        flexDirection: 'row',
        alignItems: 'center',

        height: 'auto',
        fontSize: fontSize,
        fontFamily: labelfontfamily,

        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    _data1: {
        width: '20%',
        //height: '10px',
        paddingLeft: 20,
        paddingRight: 50,

        borderRightColor: borderColor,
        borderRightWidth: 1.5,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    _lastData1: {
        width: '20%',
        paddingLeft: 20,
        paddingRight: 50,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
})

const DetailsTableHeader = ({ headerKeys, valueKeys, result }) => {
    if (result?.groupType === 'DYNAMIC_JOBCARD') {
        if (result?.reportheader?.fields.length !== 0) {
            return (
                <View style={styles.container} fixed>
                    <Text style={styles.sNo}>S.No</Text>
                    {result?.reportheader?.fields.map((value, index) => {
                        if (value?.fields.length === 0) {
                            return (
                                <Text
                                    key={index}
                                    style={
                                        index ===
                                        result.reportheader.fields.length - 1
                                            ? styles._lastData2
                                            : styles._data2
                                    }
                                >
                                    {value.label}
                                </Text>
                            )
                        } else {
                            return (
                                <>
                                    <View
                                    //style={{ maxWidth: '100%' }}
                                    //style={styles.container}
                                    >
                                        <Text
                                            key={index}
                                            style={
                                                index ===
                                                result.reportheader.fields
                                                    .length -
                                                    1
                                                    ? styles._lastData2
                                                    : styles._data2
                                            }
                                        >
                                            {value.label}
                                        </Text>
                                        <View style={styles.container1}>
                                            {value?.fields?.map((val, idx) => {
                                                return (
                                                    <Text
                                                        key={idx}
                                                        style={
                                                            idx ===
                                                            result.reportheader
                                                                .fields.length -
                                                                1
                                                                ? styles._lastData1
                                                                : styles._data1
                                                        }
                                                        //     style={{
                                                        //         maxWidth: '150%',
                                                        //         width: '50px',
                                                        //     }}
                                                    >
                                                        {val?.label}
                                                    </Text>
                                                )
                                            })}
                                        </View>
                                    </View>
                                    {/* <Text
                                        key={index}
                                        style={
                                            index ===
                                            result.reportheader.fields.length -
                                                1
                                                ? styles._lastData
                                                : styles._data
                                        }
                                    >
                                        {value.label}
                                    </Text> */}
                                </>
                            )
                        }
                    })}
                </View>
            )
        }
    } else {
        if (headerKeys && !valueKeys) {
            let values = Object.values(headerKeys)
            return (
                <>
                    <View style={styles.container} fixed>
                        <Text style={styles.sNo}>S. No</Text>
                        {values?.map((value, index) =>
                            value.length > 3 ? (
                                <Text
                                    key={index}
                                    style={
                                        index === values.length - 1
                                            ? styles._lastData
                                            : styles._data
                                    }
                                >
                                    {value}
                                </Text>
                            ) : (
                                <Text
                                    key={index}
                                    style={
                                        index === values.length - 1
                                            ? styles.lastData
                                            : styles.data
                                    }
                                >
                                    {value}
                                </Text>
                            )
                        )}
                    </View>
                </>
            )
        } else if (valueKeys) {
            let headerValues = Object.values(headerKeys)

            return (
                <>
                    <View style={styles.container}>
                        {headerValues?.map((value, index) => (
                            <Text
                                key={index}
                                style={
                                    index === headerValues.length - 1
                                        ? styles.rowLastData
                                        : styles.rowData
                                }
                            >
                                {value}
                            </Text>
                        ))}
                    </View>
                    {/* <View style={styles.rowContainer}>
                    {values.map((value, index) => (
                        <Text key={index}>{value} </Text>
                    ))}
                </View> */}
                </>
            )
        } else {
            return <View style={styles.container}></View>
        }
    }
}

export default DetailsTableHeader
