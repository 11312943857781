import {
    Box,
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'

const CouplerSpecificationTable = ({
    data,
    setData,
    setFinalData,
    finalData,
    result,
    setOverall,
    overall,
}) => {
    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        let average = 0
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && item.data.length > 0) {
                item.data = _data
                if (item.data.length > 0) {
                    _data.forEach((item1) => {})
                }
            }
            if (item.groupName === result.groupName) {
                item.data = _data
            }
        })
        setFinalData(_finalData)
    }

    const Column = [
        { text: 'Parameter Name', dataField: 'tests' },
        { text: 'Min', dataField: 'min' },

        { text: 'Max', dataField: 'max' },
    ]

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        {Column.map((item, index) => (
                            <TableCell align={'center'} key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                        <TableCell sx={{ width: 80 }} align={'center'}>
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((item, index) => (
                        <TableRow key={index}>
                            {Column.map((subItem, idx) => (
                                <TableCell
                                    align="center"
                                    key={`row-${idx}-index`}
                                >
                                    {item[subItem.dataField]}
                                </TableCell>
                            ))}
                            <TableCell align="center">
                                <Button onClick={(e) => handleDelete(index, e)}>
                                    <Icon>delete</Icon>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default CouplerSpecificationTable
