// Your data object
export const dataObject = {
    'Compressive Strength': [
        {
            density: '451 to 550',
            compressiveStrengthGrade1: '2.0',
            compressiveStrengthGrade2: '1.5',
            thermalConductivity: 0.21,
            dryingShrinkage: 'Max: 0.10%',
        },
        {
            density: '551 to 650',
            compressiveStrengthGrade1: '4.0',
            compressiveStrengthGrade2: '3.0',
            thermalConductivity: 0.24,
        },
        {
            density: '651 to 750',
            compressiveStrengthGrade1: '5.0',
            compressiveStrengthGrade2: '4.0',
            thermalConductivity: 0.3,
            dryingShrinkage: 'Max: 0.05%',
        },
        {
            density: '751 to 850',
            compressiveStrengthGrade1: '6.0',
            compressiveStrengthGrade2: '5.0',
            thermalConductivity: 0.37,
        },
        {
            density: '851 to 1000',
            compressiveStrengthGrade1: '7.0',
            compressiveStrengthGrade2: '6.0',
            thermalConductivity: 0.42,
        },
    ],
    'Block Compressive Strength': [
        {
            type: 'Solid - Load Bearing Units',
            grade: 'C (5.0)',
            MinAvgCompStr: '5.0',
            MinIndividualCompStr: '4.0',
        },
        {
            type: 'Solid - Load Bearing Units',
            grade: 'C (4.0)',
            MinAvgCompStr: '4.0',
            MinIndividualCompStr: '3.2',
        },
    ],
    'Bricks Compressive Strength': [
        {
            classDesignation: '30',
            averageCompressiveStrengthN: '30',
            averageCompressiveStrengthKgf: '300',
        },
        {
            classDesignation: '25',
            averageCompressiveStrengthN: '25',
            averageCompressiveStrengthKgf: '250',
        },
        {
            classDesignation: '20',
            averageCompressiveStrengthN: '20',
            averageCompressiveStrengthKgf: '200',
        },
        {
            classDesignation: '17.5 ',
            averageCompressiveStrengthN: '17.5',
            averageCompressiveStrengthKgf: '175',
        },
        {
            classDesignation: '15 ',
            averageCompressiveStrengthN: '15',
            averageCompressiveStrengthKgf: '150',
        },
        {
            classDesignation: '12.5',
            averageCompressiveStrengthN: '12.5',
            averageCompressiveStrengthKgf: '125',
        },
        {
            classDesignation: '10',
            averageCompressiveStrengthN: '10',
            averageCompressiveStrengthKgf: '100',
        },
        {
            classDesignation: '7.5',
            averageCompressiveStrengthN: '7.5',
            averageCompressiveStrengthKgf: '75',
        },
        {
            classDesignation: '5',
            averageCompressiveStrengthN: '5',
            averageCompressiveStrengthKgf: '50',
        },
        {
            classDesignation: '3.5',
            averageCompressiveStrengthN: '3.5',
            averageCompressiveStrengthKgf: '35',
        },
    ],
    'Bricks Compressive Strength Report': [
        {
            a: 'Length',
            forModularBricks: '3720 to 3880 mm ( 3800+/- 80mm)',
        },
        {
            a: 'Width',
            forModularBricks: '1760 to 1840 mm ( 1800+/- 40mm)',
        },
        {
            a: 'Height',
            forModularBricks:
                '1760 to 1840 mm ( 1800+/- 40mm) for 90mm high bricks',
        },
        {
            a: 'Height',
            forModularBricks:
                '760 to 840 mm (800 +/- 40mm) [ for 40 mm high bricks]',
        },
    ],
    'Bricks Compressive Strength Reports': [
        {
            b: 'Length',
            forNonModularBricks: '4520 to 4680 mm ( 4600+/- 80mm)',
        },
        {
            b: 'Width',
            forNonModularBricks: '2240 to 2160 mm ( 2200+/- 40mm)',
        },
        {
            b: 'Height',
            forNonModularBricks:
                '1440 to 1360 mm ( 1400+/- 40mm) [for 70mm high bricks]',
        },
        {
            b: 'Height',
            forNonModularBricks:
                '640 to 560 mm ( 600 +/- 40mm) [ For 30 mm high bricks)',
        },
    ],
    'Coarse Aggregate Sieve Analysis - 20mm' : [
        {
        graded:'100',
        singleSize:'100',
       },
       {
        graded:'90 – 100',
        singleSize:'85 – 100',
       },
       {
        graded:'25 – 55',
        singleSize:'0 – 20',
       },
       {
        graded:'0 – 10 ',
        singleSize:'0 – 5',
      },
    ],
    'Coarse Aggregate Sieve Analysis - 12.5mm' : [
        {
        graded:'100',
        singleSize:'100',
       },
       {
        graded:'90 – 100',
        singleSize:'85 – 100',
       },
       {
        graded:'40 – 85',
        singleSize:'0 - 45',
       },
       {
        graded:'0 – 10 ',
        singleSize:'0 – 10',
      },
    ],
    'Fine Aggregate Sieve Analysis - P sand': [
        {
            specification:'100',
        },
        {
            specification:'95-100',
        },
        {
            specification:'95-100',
        },
        {
            specification:'90-100',
        },
        {
            specification:'80-100',
        },
        {
            specification:'20-65',
        },
        {
            specification:'0-15',
        },
    ],
    'Fine Aggregate Sieve Analysis - M sand': [
        {
            zoneI:'100',
        zoneII:'100',
        zoneIII:'100',
        },
        {
            zoneI:'90-100',
            zoneII:'90-100',
            zoneIII:'90-100',
        },
        {
            zoneI:'60-95',
            zoneII:'75-100',
            zoneIII:'85-100',
        },
        {
            zoneI:'30-70',
            zoneII:'55-90',
            zoneIII:'75-90',
        },
        {
            zoneI:'15-34',
            zoneII:'35-59',
            zoneIII:'60-79',
        },
        {
            zoneI:'5-20',
            zoneII:'8-30',
            zoneIII:'12-40',
        },
        {
            zoneI:'0-20',
            zoneII:'0-20',
            zoneIII:'0-20',
        },

    ],
    'Fine Aggregate Sieve Analysis - River sand': [
        {
            zoneI:'100',
        zoneII:'100',
        zoneIII:'100',
        },
        {
            zoneI:'90-100',
            zoneII:'90-100',
            zoneIII:'90-100',
        },
        {
            zoneI:'60-95',
            zoneII:'75-100',
            zoneIII:'85-100',
        },
        {
            zoneI:'30-70',
            zoneII:'55-90',
            zoneIII:'75-90',
        },
        {
            zoneI:'15-34',
            zoneII:'35-59',
            zoneIII:'60-79',
        },
        {
            zoneI:'5-20',
            zoneII:'8-30',
            zoneIII:'12-40',
        },
        {
            zoneI:'0-20',
            zoneII:'0-20',
            zoneIII:'0-20',
        },
    ],
}

// Assigning the label
