import React from 'react'
import ReportTable from '../material-kit/tables/ReportTable'
import { Grid } from '@mui/material'
import { Container } from 'app/views/material-kit/tables/AppTable'

const ReportView = () => {
    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ReportTable />
                </Grid>
            </Grid>
        </Container>
    )
}

export default ReportView
