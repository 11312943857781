import { MATERIAL } from '../actions/Actions'

const initialState = {
    materialNames: [],
}

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case MATERIAL.SET_MATERIAL_DATA: {
            return {
                ...state,
                materialNames: action.payload,
            }
        }
        default:
            return state
    }
}

export default appReducer
