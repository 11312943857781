import {
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { Box } from '@mui/system'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const ConcreteCoreTable = (props) => {
    const {
        data,
        setData,
        setFinalData,
        finalData,
        result,
        setOverall,
        overall,
    } = props

    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        _finalData.forEach((item) => {
            let average = 0
            let value = 0
            if (item.data.length > 0) {
                _data.forEach((item1) => {
                    value += Number(item1.equivalent_cube_comp)
                    average += Number(value / _data.length)
                })

                if (item.groupName === result.groupName) {
                    item.data = _data
                    item.overall = {
                        ...overall,
                        average: average === 0 ? null : valueCalc(average),
                    }
                    setOverall(item.overall)
                }
            }
        })
        setFinalData(_finalData)
    }

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Sample Id</TableCell>
                        <TableCell align="center">
                            Core Length(L in mm)
                        </TableCell>
                        <TableCell align="center">Core dia(d in mm)</TableCell>
                        <TableCell align="center">CA in mm²</TableCell>
                        <TableCell align="center">
                            Core Crush Load(KN)
                        </TableCell>
                        <TableCell align="center">Core Weight(Kg)</TableCell>
                        <TableCell align="center">
                            Core Comp Strength(N/mm²)
                        </TableCell>
                        <TableCell align="center">I/d Ratio</TableCell>
                        <TableCell align="center">
                            Correction factor for(l/d)ratio +
                        </TableCell>
                        <TableCell align="center">
                            Corrected Cyl.Comp.Strength(N/mm²)
                        </TableCell>
                        <TableCell align="center">
                            Equivalent Cube Comp Strength++(N/mm²)
                        </TableCell>
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell align="center">
                                {item.sample_id}
                            </TableCell>
                            <TableCell align="center">
                                {item.core_length}
                            </TableCell>
                            <TableCell align="center">
                                {item.core_dia}
                            </TableCell>
                            <TableCell align="center">{item.CA}</TableCell>
                            <TableCell align="center">
                                {item.core_cursh}
                            </TableCell>
                            <TableCell align="center">
                                {item.core_weight}
                            </TableCell>
                            <TableCell align="center">
                                {item.core_comp_strength}
                            </TableCell>
                            <TableCell align="center">
                                {item.id_ratio}
                            </TableCell>
                            <TableCell align="center">
                                {item.correction_factor}
                            </TableCell>
                            <TableCell align="center">
                                {item.corrected_cyl_comp}
                            </TableCell>
                            <TableCell align="center">
                                {item.equivalent_cube_comp}
                            </TableCell>
                            <TableCell align="center">
                                <Button onClick={(e) => handleDelete(index, e)}>
                                    <Icon>delete</Icon>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default ConcreteCoreTable
