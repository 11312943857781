import { Box, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const WaterAbsorptionTable = (props) => {
    const { data } = props

    // //Formula
    // let value = (100 * (data.weightSaturated - data.weightOven) / data.weightOven)
    // data.waterAbsorption = value ? valueCalc(value) : '0';

    let water_Absorption

    water_Absorption = [
        {
            label: 'Weight in g Saturated Surface-dry Sample (g)',
            formula: 'A',
            value: 'weightSaturated',
        },
        {
            label: 'Weight in g Bottle +Sample + Distilled Water (g)',
            formula: 'B',
            value: 'weightPycnometer',
        },
        {
            label: 'Weight in g Bottle + Distilled Water (g)',
            formula: 'C',
            value: 'weightPycnometerDistilled',
        },
        {
            label: 'Weight in g  of oven-dried sample',
            formula: 'D',
            value: 'weightOven',
        },
        {
            label: 'Water Absorbtion(%)',
            formula: '100*(A-D)/D',
            value: 'waterAbsorption',
        },
    ]

    const Column = [
        { text: 'Observation', dataField: 'label' },
        { text: 'Formula', dataField: 'formula' },

        { text: 'Result', dataField: 'value' },
    ]

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        {/* <TableCell align="center">Actions</TableCell> */}
                        <TableCell align="center">SI.No</TableCell>
                        {Column.map((item, index) => (
                            <TableCell align="center" key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                {water_Absorption?.map((item, index) => (
                    <TableBody>
                        <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{item.label}</TableCell>
                            <TableCell align="center">{item.formula}</TableCell>
                            <TableCell align="center">
                                {data[item.value] ? data[item.value] : '0'}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ))}
            </StyledTable>
        </Box>
    )
}

export default WaterAbsorptionTable


// const handleDelete = (index, e) => {
//     let _data = data.filter((v, i) => i !== index);
//     setData(_data);
//     let _finalData = finalData;
//     _finalData.forEach(item => {
//         if (item.groupName === result.groupName) {
//             item.overall = _data;
//         }
//     })
//     setFinalData(_finalData);
// }