import styled from '@emotion/styled'
import { Card, Grid, TextField } from '@mui/material'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'

import { Fragment } from 'react'
import CompressiveStrengthModal from './modal/CompressiveStrenghtModal'
import DimensionModal from './modal/DimensionModal'
import EfflorescenceModal from './modal/EfflorescenceModal'
import WaterAbsorptionModal from './modal/WaterAbsorptionModal'

function JOBCARD007I({
    result,
    isView,
    remarksValue,
    overall,
    setOverall,
    handleCustomRequirementChange,
    handleRequirementChange,
}) {
    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {result.groupName === 'Dimension Test' && (
                        <JobAccordion
                            title={result?.groupName}
                            requirementvalue={overall?.requirements}
                            remarksValue={overall?.remarks}
                            // remarksValue={remarksValue}
                            handleCustomRequirementChange={
                                handleCustomRequirementChange
                            }
                            handleRequirementChange={handleRequirementChange}
                            overall={overall}
                            avgLabel={'Average of Length'}
                            averagevalue={overall?.avgLength}
                            avgLabel2={'Average of Breadth'}
                            averagevalue2={overall?.avgBreadth}
                            avgLabel3={'Average of Height'}
                            averagevalue3={overall?.avgHeight}
                        >
                            <DimensionModal
                                result={result}
                                isView={isView}
                                overall={overall}
                                setOverall={setOverall}
                            />
                        </JobAccordion>
                    )}
                    {/* /* ................................. */}
                    {result.groupName === 'Bricks Water Absorption' && (
                        <JobAccordion
                            title={result?.groupName}
                            requirementvalue={overall?.requirements}
                            remarksValue={overall?.remarks}
                            // remarksValue={remarksValue}
                            handleCustomRequirementChange={
                                handleCustomRequirementChange
                            }
                            handleRequirementChange={handleRequirementChange}
                            overall={overall}
                            avgLabel={'Average '}
                            averagevalue={overall?.average}
                        >
                            <WaterAbsorptionModal
                                result={result}
                                isView={isView}
                                overall={overall}
                                setOverall={setOverall}
                            />
                        </JobAccordion>
                    )}
                    {/* ............................. */}
                    {result.groupName === 'Bricks Compressive Strength' && (
                        <JobAccordion
                            title={result?.groupName}
                            requirementvalue={overall?.requirements}
                            remarksValue={overall?.remarks}
                            // remarksValue={remarksValue}
                            handleCustomRequirementChange={
                                handleCustomRequirementChange
                            }
                            handleRequirementChange={handleRequirementChange}
                            overall={overall}
                            avgLabel={'Average '}
                            averagevalue={overall?.average}
                        >
                            <CompressiveStrengthModal
                                result={result}
                                isView={isView}
                                overall={overall}
                                setOverall={setOverall}
                            />
                        </JobAccordion>
                    )}
                    {/* .................................... */}
                    {result.groupName === 'Efflorescence' && (
                        <JobAccordion
                            title={result?.groupName}
                            requirementvalue={overall?.requirements}
                            remarksValue={overall?.remarks ?? remarksValue}
                            // remarksValue={remarksValue}
                            handleCustomRequirementChange={
                                handleCustomRequirementChange
                            }
                            handleRequirementChange={handleRequirementChange}
                            overall={overall}
                            // avgLabel={'Efflorescence'}
                            // averagevalue={overall?.average}
                            // avgLabel4={'Edit Efflorescence '}
                            // editaverage={overall?.editaverage}
                        >
                            <EfflorescenceModal
                                result={result}
                                isView={isView}
                                overall={overall}
                                setOverall={setOverall}
                            />
                        </JobAccordion>
                    )}
                </Grid>
            </Grid>
            {/* </ContentBox> */}
        </Fragment>
    )
}

export default JOBCARD007I
