import {
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { Box } from '@mui/system'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

export const DimensionTable = (props) => {
    const {
        data,
        setData,
        setFinalData,
        finalData,
        result,
        overall,
        setOverall,
    } = props

    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)

        let _finalData = finalData
        let avg = 0
        let avgLength = 0
        let avgBreadth = 0
        let avgHeight = 0
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && _data?.length !== 0) {
                _data?.forEach((item1) => {
                    let length = item1.len
                    avgLength += Number(length / _data.length)
                    let breadth = item1.breadth
                    avgBreadth += Number(breadth / _data.length)
                    let height = item1.height
                    avgHeight += Number(height / _data.length)
                    let average = item1.len * item1.breadth * item1.height
                    avg += Number(average / _data.length)
                })
            }
            if (item.groupName === result.groupName) {
                item.data = _data
                item.overall = {
                    ...overall,
                    avgHeight: valueCalc(avgHeight),
                    avgBreadth: valueCalc(avgBreadth),
                    avgLength: valueCalc(avgLength),
                    avg: valueCalc(avg),
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
    }

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">SI.No</TableCell>
                        <TableCell align="center">Length</TableCell>
                        <TableCell align="center">Breadth</TableCell>
                        <TableCell align="center">Height</TableCell>
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, index) => (
                        <TableRow>
                            <TableCell key={index} align="center">
                                {index + 1}
                            </TableCell>
                            <TableCell key={index} align="center">
                                {item.len}
                            </TableCell>
                            <TableCell key={index} align="center">
                                {item.breadth}
                            </TableCell>
                            <TableCell key={index} align="center">
                                {item.height}
                            </TableCell>
                            <TableCell align="center">
                                <Button onClick={(e) => handleDelete(index, e)}>
                                    <Icon>delete</Icon>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default DimensionTable
