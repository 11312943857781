import Axios from 'app/configs/Axios'

export const API_BASE_URL =
    process.env.NODE_ENV === 'development'
        ? //'https://stage-lims.icaniotech.com'
          'https://lims-ui.icaniotech.com'
        : //   'http://localhost:7000'
          ''

export const getSearch = async (data) => {
    try {
        const response = await Axios.get(`/users/search?q=${data}`)
        return response
    } catch (error) {
        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}

export const getCustomerSearch = async (data) => {
    try {
        const response = await Axios.get(`/users?role=CUSTOMER&q=${data}`)
        return response
    } catch (error) {
        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}

export const getSampleList = async (id) => {
    try {
        const response = await Axios.get(`/materials?group_id=${id}`)
        return response
    } catch (error) {
        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}

export const getSampleById = async (id) => {
    try {
        const response = await Axios.get(`/test-types?material_id=${id}`)
        return response
    } catch (error) {
        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}

export const addTest = async (reqbody) => {
    try {
        const response = await Axios.post(`/customerSample`, reqbody)
        return response
    } catch (error) {
        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}

export const getUsers = async (page, size = 10, role) => {
    try {
        const res = await Axios.get(`/users`, {
            params: { page, size, role },
        })
        return res
    } catch (err) {
        console.error(err)
    }
}

export const getTestSampleList = async (
    page = 1,
    user_id,
    q,
    status,
    reviewerId,
    assignees,
    disciplineId,
    groupId,
    material,
    projects,
    size = 10
) => {
    try {
        const response = await Axios.get(`/customerSample`, {
            params: {
                page,
                user_id,
                q,
                status,
                reviewerId,
                assignees,
                disciplineId,
                groupId,
                material,
                projects,
                size,
            },
        })
        return response
    } catch (error) {
        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}

export const getRecentTestSampleList = async (page, user_id, data) => {
    try {
        const response = await Axios.get(
            `/customerSample?page=${page}&size=5&user_id=${user_id}&q=${data}`
        )
        return response
    } catch (error) {
        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}
export const getMonthTestSampleList = async (month) => {
    try {
        const response = await Axios.get(
            `/customerSample?month=${month}&job_no=asc`
        )
        return response
    } catch (error) {
        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}

export const getSampleListById = async (id) => {
    try {
        const response = await Axios.get(`/customerSample/${id}`)
        return response
    } catch (error) {
        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}
export const getAssigneeList = async () => {
    try {
        const response = await Axios.get(`/users?role=LAB_TECHNICIANS`)
        return response
    } catch (error) {
        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}
export const updateJobList = async (id, reqBody) => {
    try {
        const response = await Axios.put(`/customerSample/${id}`, reqBody)
        return response
    } catch (error) {
        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}

export const getUserById = async (id) => {
    try {
        const response = await Axios.get(`/users/${id}`)
        return response
    } catch (error) {
        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}

export const createUser = async (reqBody, callBack) => {
    try {
        const response = await Axios.post(`/users`, reqBody)
        callBack(response, true)
        return response
    } catch (error) {
        callBack(
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            false
        )
        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}
export const updateUser = async (id, reqBody, callBack) => {
    try {
        const response = await Axios.put(`/users/${id}`, reqBody)
        callBack(response, true)
        return response
    } catch (error) {
        callBack(
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            false
        )

        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}

export const deleteUser = async (id) => {
    try {
        const response = await Axios.delete(`/users/${id}`)
        return response
    } catch (error) {
        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}
export const getRoles = async () => {
    try {
        const response = await Axios.get(`/roles`)
        return response
    } catch (error) {
        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}
export const getBranches = async () => {
    try {
        const response = await Axios.get(`/branch`)
        return response
    } catch (error) {
        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}

export const getBranchById = async (id) => {
    try {
        const response = await Axios.get(`/branch/${id}`)
        return response
    } catch (error) {
        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}

export const createBranch = async (reqBody, callBack) => {
    try {
        const response = await Axios.post(`${API_BASE_URL}/branch`, reqBody)
        callBack(response, true)

        return response
    } catch (error) {
        callBack(
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            false
        )
        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}
export const updateBranch = async (id, reqBody, callBack) => {
    try {
        const response = await Axios.put(
            `${API_BASE_URL}/branch/${id}`,
            reqBody
        )
        callBack(response, true)

        return response
    } catch (error) {
        callBack(
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            false
        )
        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}

export const deleteBranch = async (id) => {
    try {
        const response = await Axios.delete(`/branch/${id}`)
        return response
    } catch (error) {
        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}

export const deleteSampleById = async (id) => {
    try {
        const response = await Axios.delete(`/customerSample/${id}`)
        return response
    } catch (error) {
        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}
// export const publishCustomerSampleByID = async (id) => {
//     try {
//         const response = await Axios.put(
//             `${API_BASE_URL}/api/customerSample/${id}/publish`
//         )
//     }
// }
export const getSampleCollection = async (page, size = 10) => {
    try {
        const response = await Axios.get(
            `${API_BASE_URL}/api/sample-requests?page=${page}&size=${size}`
        )
        return response
    } catch (error) {
        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}

export const publishCustomerSampleByID = async (id) => {
    try {
        const response = await Axios.put(
            `${API_BASE_URL}/api/customerSample/${id}/publish`
        )

        return response
    } catch (error) {
        return error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    }
}
