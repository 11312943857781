import { AddCircle, Edit } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Modal,
    TextField,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'
import {
    HelperText,
    StyledCard,
    StyledIcon,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import { Fragment, useContext, useState } from 'react'
import * as Yup from 'yup'
import { valueCalc } from '../../method/method'
import WaterAbsorptionTable from './WaterAbsorptionTable'

const initialValues = {
    weightSaturated: '',
    weightPycnometer: '',
    weightPycnometerDistilled: '',
    weightOven: '',

    waterAbsorption: '',
}
const validationSchema = Yup.object().shape({
    weightSaturated: Yup.number().required('Required'),
    weightPycnometer: Yup.number().required('Required'),
    weightPycnometerDistilled: Yup.number().required('Required'),
    weightOven: Yup.number().required('Required'),
    // waterAbsorption: Yup.number().required('Required'),
})
const WaterAbsorption = (props) => {
    const {
        result,
        isView,
        overall,
        setOverall,
        handleCustomRequirementChange,
        handleRequirementChange,
    } = props
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [data, setData] = useState(
        result?.overall ? result?.overall : { ...initialValues }
    )
    const [initialState, setInitialState] = useState({ ...initialValues })

    const { finalData, setFinalData } = useContext(AppContext)

    const handleSubmit = (values, { resetForm }) => {
        //Formula
        let value =
            (100 * (values.weightSaturated - values.weightOven)) /
            values.weightOven
        values.waterAbsorption = value ? valueCalc(value) : '0'

        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName) {
                item.overall = {
                    ...values,
                    requirements: overall.requirements,
                    remarks: overall.remarks,
                    requirementStatus: overall.requirementStatus,
                    remarkStatus: overall?.remarkStatus,
                }
                setOverall(item.overall)
            }
        })

        setFinalData(_finalData)
        setData(values)
        setInitialState({ ...initialValues })
        setOpen(false)
    }
    const handleDeleteTable = () => {
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName) {
                item.overall = {
                    requirements: overall.requirements,
                    requirementStatus: overall.requirementStatus,
                    remarkStatus: overall?.remarkStatus,
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData({ ...initialState })
    }

    let length = Object.keys(result.overall).length

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <JobAccordion
                        title={result?.groupName}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        requirementvalue={overall?.requirements}
                        remarksValue={overall?.remarks}
                        overall={overall}
                        // avgLabel={`Average of ${result?.groupName}`}
                        //     averagevalue={overall?.average}
                    >
                        <Grid item xs={12}>
                            <Formik
                                initialValues={initialState}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {(props) => {
                                    const {
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    } = props
                                    return (
                                        <Form>
                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                            >
                                                <StyledCard>
                                                    <CardHeader
                                                        title={
                                                            result?.groupName
                                                        }
                                                    />
                                                    <CardContent>
                                                        <Grid
                                                            container
                                                            spacing={4}
                                                        >
                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="Weight in g Saturated Surface-dry Sample (g)"
                                                                    value={
                                                                        values.weightSaturated
                                                                    }
                                                                    name="weightSaturated"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.weightSaturated &&
                                                                        touched.weightSaturated
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.weightSaturated &&
                                                                    touched.weightSaturated
                                                                        ? errors.weightSaturated
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="Weight in g Bottle +Sample + Distilled Water (g)"
                                                                    value={
                                                                        values.weightPycnometer
                                                                    }
                                                                    name="weightPycnometer"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.weightPycnometer &&
                                                                        touched.weightPycnometer
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.weightPycnometer &&
                                                                    touched.weightPycnometer
                                                                        ? errors.weightPycnometer
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="Weight in g Bottle + Distilled Water (g)"
                                                                    value={
                                                                        values.weightPycnometerDistilled
                                                                    }
                                                                    name="weightPycnometerDistilled"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.weightPycnometerDistilled &&
                                                                        touched.weightPycnometerDistilled
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.weightPycnometerDistilled &&
                                                                    touched.weightPycnometerDistilled
                                                                        ? errors.weightPycnometerDistilled
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="Weight in g of oven-dried sample"
                                                                    value={
                                                                        values.weightOven
                                                                    }
                                                                    name="weightOven"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.weightOven &&
                                                                        touched.weightOven
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.weightOven &&
                                                                    touched.weightOven
                                                                        ? errors.weightOven
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button
                                                            size="small"
                                                            onClick={
                                                                handleClose
                                                            }
                                                        >
                                                            Cancel
                                                        </Button>
                                                        {data ===
                                                        result?.overall ? (
                                                            <Button
                                                                size="small"
                                                                variant="contained"
                                                                onClick={
                                                                    handleSubmit
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                size="small"
                                                                variant="contained"
                                                                onClick={
                                                                    handleSubmit
                                                                }
                                                            >
                                                                Save
                                                            </Button>
                                                        )}
                                                    </CardActions>
                                                </StyledCard>
                                            </Modal>
                                        </Form>
                                    )
                                }}
                            </Formik>
                            {data && (
                                <WaterAbsorptionTable
                                    data={data}
                                    setData={setData}
                                    setFinalData={setFinalData}
                                    result={result}
                                    finalData={finalData}
                                />
                            )}
                            {isView !== true && (
                                <Grid container justifyContent="end">
                                    <IconButton
                                        size="small"
                                        onClick={(e) => handleDeleteTable()}
                                        sx={{ boxShadow: 'none', mt: 2 }}
                                    >
                                        <StyledIcon>delete</StyledIcon>
                                    </IconButton>
                                    {length > 1 ? (
                                        <Button
                                            sx={{ boxShadow: 'none', mt: 2 }}
                                            variant="contained"
                                            endIcon={<Edit />}
                                            onClick={handleOpen}
                                            size="small"
                                        >
                                            Edit
                                        </Button>
                                    ) : (
                                        <Button
                                            sx={{ boxShadow: 'none', mt: 2 }}
                                            variant="contained"
                                            endIcon={<AddCircle />}
                                            onClick={handleOpen}
                                            size="small"
                                        >
                                            Add
                                        </Button>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </JobAccordion>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default WaterAbsorption
