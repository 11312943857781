export const getNotes = {
    'Drinking Water (Bore Water)': {
        note: 'BDL- Below Detection Limit; DL- Detection limit; MPN- Most Probable Number; <2 MPN/ 100ml = Absent',
    },
    'Waste Water (STP Treated Water)': {
        note: `MDA – Minimum Detectable Activity; BDL - Below Detection Limit; µCi - micro curie; DL- Detection Limit; 
        P/A – Present/Absent; IS-Indian Standard; APHA-American Public Health Association; ** Qualitatively by GCMS scan mode by 3270 C`,
    },
    'Hardened Concrete (Concrete core)': {
        note: `1) * Details as furnished by the client /Samples as supplied by the client.   2) The above concrete core samples extracted  by FITPL. 
                3) **  Core length and core weight after trimming and capping.
                4) +   For l/d ratio, correction factors are as per Figure -I of IS:516(P-4)-1959 (Reaffirmed in 2018). 
                5) ++  Equivalent cube compressive strength = 1.25 x corrected cylinder compressive strength as per IS:516-1959 (Reaffirmed in 2018).`,
    },
}
