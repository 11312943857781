import { ExpandMore } from '@mui/icons-material'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Modal,
    TextField,
} from '@mui/material'
import { Fragment } from 'react'
import { useContext, useState } from 'react'

import { AddCircle } from '@mui/icons-material'

import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import ReboundHammerTable from './ReboundHammerTable'
import { valueCalc } from '../../method/method'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'

const initialValues = {
    ht_direction: '',
    d_1: '',
    d_2: '',
    d_3: '',
    d_4: '',
    d_5: '',
    d_6: '',
    d_7: '',
    d_8: '',
    d_9: '',
    d_10: '',
    average: '',
}

const validationSchema = Yup.object().shape({
    ht_direction: Yup.string().required('Required'),
    d_1: Yup.number().required('Required'),
    d_2: Yup.number().required('Required'),
    d_3: Yup.number().required('Required'),
    d_4: Yup.number().required('Required'),
    d_5: Yup.number().required('Required'),
    d_6: Yup.number().required('Required'),
    d_7: Yup.number().required('Required'),
    d_8: Yup.number().required('Required'),
    d_9: Yup.number().required('Required'),
    d_10: Yup.number().required('Required'),
})

const ReboundHammer = ({
    result,
    isView,
    handleCustomRequirementChange,
    handleRequirementChange,
    overall,
    setOverall,
}) => {
    const handleClose = () => setOpen(false)

    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)
    const [data, setData] = useState(result.data ? result.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const { finalData, setFinalData } = useContext(AppContext)
    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        arr = applyFormula(arr)

        let _finalData = finalData
        let avg = 0
        _finalData.forEach((item) => {
            if (item?.groupName === result?.groupName && arr.length !== 0) {
                arr?.forEach((item1) => {
                    let average = item1.average

                    avg += Number(average / arr.length)
                })
            }
            if (item.groupName === result.groupName) {
                item.data = arr

                item.overall = {
                    ...overall,
                    average: valueCalc(avg),
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
        resetForm()
    }

    const applyFormula = (arr) => {
        arr.forEach((item, index) => {
            let average =
                (Number.parseFloat(item.d_1) +
                    Number.parseFloat(item.d_2) +
                    Number.parseFloat(item.d_3) +
                    Number.parseFloat(item.d_4) +
                    Number.parseFloat(item.d_5) +
                    Number.parseFloat(item.d_6) +
                    Number.parseFloat(item.d_7) +
                    Number.parseFloat(item.d_8) +
                    Number.parseFloat(item.d_9) +
                    Number.parseFloat(item.d_10)) /
                10
            item.average = valueCalc(average)
            // item.average =   !isNaN(average) && average.toString().indexOf('.') != -1 ? Number(average).toFixed(3) :average
        })
        return arr
    }

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <JobAccordion
                        title={result?.groupName}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        requirementvalue={overall?.requirements}
                        remarksValue={overall?.remarks}
                        overall={overall}
                    >
                        <Grid item xs={12}>
                            <Formik
                                initialValues={initialState}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {(props) => {
                                    const {
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    } = props
                                    return (
                                        <Form>
                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                            >
                                                <StyledCard>
                                                    <CardHeader
                                                        title={
                                                            result?.groupName
                                                        }
                                                    />
                                                    <CardContent sx={{ m: 4 }}>
                                                        <Grid
                                                            container
                                                            spacing={4}
                                                        >
                                                            <Grid item xs={12}>
                                                                <Typography>
                                                                    {' '}
                                                                    Ht Direction
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={12}
                                                                xs={6}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="Ht Direction"
                                                                    value={
                                                                        values.ht_direction
                                                                    }
                                                                    name="ht_direction"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.ht_direction &&
                                                                        touched.ht_direction
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.ht_direction &&
                                                                    touched.ht_direction
                                                                        ? errors.ht_direction
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography>
                                                                    Values
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={6}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="1"
                                                                    value={
                                                                        values.d_1
                                                                    }
                                                                    name="d_1"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.d_1 &&
                                                                        touched.d_1
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.d_1 &&
                                                                    touched.d_1
                                                                        ? errors.d_1
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                lg={6}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="2"
                                                                    value={
                                                                        values.d_2
                                                                    }
                                                                    name="d_2"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.d_2 &&
                                                                        touched.d_2
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.d_2 &&
                                                                    touched.d_2
                                                                        ? errors.d_2
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                lg={6}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="3"
                                                                    value={
                                                                        values.d_3
                                                                    }
                                                                    name="d_3"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.d_3 &&
                                                                        touched.d_3
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.d_3 &&
                                                                    touched.d_3
                                                                        ? errors.d_3
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={6}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="4"
                                                                    value={
                                                                        values.d_4
                                                                    }
                                                                    name="d_4"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.d_4 &&
                                                                        touched.d_4
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.d_4 &&
                                                                    touched.d_4
                                                                        ? errors.d_4
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                lg={6}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="5"
                                                                    value={
                                                                        values.d_5
                                                                    }
                                                                    name="d_5"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.d_5 &&
                                                                        touched.d_5
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.d_5 &&
                                                                    touched.d_5
                                                                        ? errors.d_5
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={6}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="6"
                                                                    value={
                                                                        values.d_6
                                                                    }
                                                                    name="d_6"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.d_6 &&
                                                                        touched.d_6
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.d_6 &&
                                                                    touched.d_6
                                                                        ? errors.d_6
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={6}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="7"
                                                                    value={
                                                                        values.d_7
                                                                    }
                                                                    name="d_7"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.d_7 &&
                                                                        touched.d_7
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.d_7 &&
                                                                    touched.d_7
                                                                        ? errors.d_7
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={6}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="8"
                                                                    value={
                                                                        values.d_8
                                                                    }
                                                                    name="d_8"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.d_8 &&
                                                                        touched.d_8
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.d_8 &&
                                                                    touched.d_8
                                                                        ? errors.d_8
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={6}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="9"
                                                                    value={
                                                                        values.d_9
                                                                    }
                                                                    name="d_9"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.d_9 &&
                                                                        touched.d_9
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.d_9 &&
                                                                    touched.d_9
                                                                        ? errors.d_9
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={6}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="10"
                                                                    value={
                                                                        values.d_10
                                                                    }
                                                                    name="d_10"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.d_10 &&
                                                                        touched.d_10
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.d_10 &&
                                                                    touched.d_10
                                                                        ? errors.d_10
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button
                                                            size="small"
                                                            onClick={
                                                                handleClose
                                                            }
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            size="small"
                                                            variant="contained"
                                                            onClick={
                                                                handleSubmit
                                                            }
                                                        >
                                                            Save
                                                        </Button>
                                                    </CardActions>
                                                </StyledCard>
                                            </Modal>
                                        </Form>
                                    )
                                }}
                            </Formik>
                            {data && (
                                <ReboundHammerTable
                                    data={data}
                                    setData={setData}
                                    setFinalData={setFinalData}
                                    finalData={finalData}
                                    result={result}
                                    overall={overall}
                                    setOverall={setOverall}
                                />
                            )}
                            {isView !== true && (
                                <Grid container justifyContent="end">
                                    <Button
                                        sx={{
                                            boxShadow: 'none',
                                            mt: 2,
                                        }}
                                        variant="contained"
                                        endIcon={<AddCircle />}
                                        onClick={handleOpen}
                                        size="small"
                                    >
                                        Add
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </JobAccordion>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default ReboundHammer
