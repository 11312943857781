import { AddCircle, ExpandMore } from '@mui/icons-material'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    InputLabel,
    Modal,
    TextField,
    Typography,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'
import {
    HelperText,
    StyledCard,
    StyledDivider,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import { Fragment, useContext, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { valueCalc } from '../../method/method'
import NormalConsistencyTable from './NormalConsistencyTable'

const initialValues = {
    qty_water: '',
    weight_cement: '',
    consistency: '',
}
const validationSchema = Yup.object().shape({
    qty_water: Yup.number().required('Required'),
    weight_cement: Yup.number().required('Required'),
})
const NormalConsistency = ({
    result,
    isView,
    overall,
    setOverall,
    handleCustomRequirementChange,
    handleRequirementChange,
}) => {
    const handleClose = () => setOpen(false)
    const [open, setOpen] = useState(false)
    const [data, setData] = useState(result.data ? result.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const { finalData, setFinalData } = useContext(AppContext)
    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        arr = applyFormula(arr)
        let _finalData = finalData
        _finalData.forEach((item) => {
            let average = 0
            if (item.groupName === result.groupName && arr.length !== 0) {
                arr.forEach((item1) => {
                    let value = Number(item1.consistency / arr.length)
                    average += Number(value)
                })
                item.overall = {
                    ...overall,
                    average: valueCalc(average),
                }
                setOverall(item.overall)
            }
            if (item.groupName === result.groupName) {
                item.data = arr
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
    }

    const applyFormula = (arr) => {
        arr.forEach((item, index) => {
            item.consistency = valueCalc(
                (item.qty_water / item.weight_cement) * 100
            )
        })
        return arr
    }
    const handleOpen = () => setOpen(true)

    // if(overall.requirements === ''){
    //     setOverall({...overall,requirements:"kan"})
    // }
    // console.log(overall);
    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <JobAccordion
                        title={result?.groupName}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        requirementvalue={overall?.requirements}
                        avgLabel="Average"
                        averagevalue={overall?.average}
                        remarksValue={overall?.remarks}
                        overall={overall}
                    >
                        <Grid item xs={12}>
                            <Formik
                                initialValues={initialState}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {(props) => {
                                    const {
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    } = props
                                    return (
                                        <Form>
                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                            >
                                                <StyledCard>
                                                    <CardHeader title="Normal Consistency" />
                                                    <CardContent
                                                        sx={{
                                                            m: 4,
                                                        }}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={4}
                                                        >
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Quantity of water for 5-7 mm penetration"
                                                                    value={
                                                                        values.qty_water
                                                                    }
                                                                    name="qty_water"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.qty_water &&
                                                                        touched.qty_water
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.qty_water &&
                                                                    touched.qty_water
                                                                        ? errors.qty_water
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Weight of cement"
                                                                    value={
                                                                        values.weight_cement
                                                                    }
                                                                    name="weight_cement"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.weight_cement &&
                                                                        touched.weight_cement
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.weight_cement &&
                                                                    touched.weight_cement
                                                                        ? errors.weight_cement
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button
                                                            size="small"
                                                            onClick={
                                                                handleClose
                                                            }
                                                        >
                                                            Cancel
                                                        </Button>

                                                        <Button
                                                            size="small"
                                                            variant="contained"
                                                            onClick={
                                                                handleSubmit
                                                            }
                                                        >
                                                            Save
                                                        </Button>
                                                    </CardActions>
                                                </StyledCard>
                                            </Modal>
                                        </Form>
                                    )
                                }}
                            </Formik>
                            {data && (
                                <NormalConsistencyTable
                                    data={data}
                                    setData={setData}
                                    setFinalData={setFinalData}
                                    finalData={finalData}
                                    result={result}
                                    setOverall={setOverall}
                                    overall={overall}
                                />
                            )}

                            {isView !== true && (
                                <Grid container justifyContent="end">
                                    <Button
                                        sx={{ boxShadow: 'none', mt: 2 }}
                                        variant="contained"
                                        endIcon={<AddCircle />}
                                        onClick={handleOpen}
                                        size="small"
                                    >
                                        Add
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </JobAccordion>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default NormalConsistency
