import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Icon,
    TextField,
    Typography,
} from '@mui/material'
import { createBranch, getBranches, getRoles, updateBranch } from 'api/Action'
import { Form, Formik } from 'formik'
import Cookies from 'js-cookie'
import { Fragment, useEffect, useState } from 'react'
import * as yup from 'yup'
import { ContentBox } from '../../assets/styled-components/SharedComponents'
import { StyledDivider } from 'app/views/jobs/StyledComponents/StyledComponents'

const initialValues = {
    name: '',
    branch_no: '',
    organizations: '',
    address: '',
    phoneNumbers: [''],
}

const BranchCreationForm = ({ callBack, state, setState, toggle }) => {
    // const user = sessionStorage.getItem('userRole')
    const organisation = Cookies.get('organizations')

    const [roles, setRoles] = useState([])
    const [branches, setBranches] = useState([])

    const handleSubmit = async (values, { resetForm, setFieldValue }) => {
        values.branch =
            branches.find((branch) => branch.name === values.branch)?._id ?? ''
        values.roles =
            roles.find((role) => role.name === values.roles)?._id ?? ''
        values.organizations = [JSON.parse(organisation)?._id ?? '']

        const creationCallBack = async (message, success) => {
            if (success) {
                alert('Added Successfully')
                // await resetFunction()
                resetForm()
                toggle()
            } else {
                alert(`Branch Creation Failed:- ${message}`)
            }
        }
        const updateCallBack = async (message, success) => {
            if (success) {
                alert('Updated Successfully')
                // await resetFunction()
                resetForm()

                toggle()
            } else {
                alert(`Branch Update Failed:- ${message}`)
            }
        }
        if (state._id ?? '') {
            await updateBranch(state._id, values, updateCallBack)
        } else {
            await createBranch(values, creationCallBack)
        }
    }

    useEffect(() => {
        getRoles()
            .then((response) => {
                setRoles(response.data)
            })
            .catch((error) => {
                setRoles([])
            })
        getBranches()
            .then((response) => {
                setBranches(response.data)
            })
            .catch((error) => {
                setBranches([])
            })
    }, [])

    const validationSchema = yup.object({
        name: yup.string('Enter name').required('Name is required'),
        address: yup.string('Enter address').required('Address is required'),
        phoneNumbers: yup.array().of(yup.number('Enter Phone Number')),
        branch_no: yup.string('').required('Branch is required'),
    })

    return (
        <Fragment>
            <Formik
                initialValues={state}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setTouched,
                        setFieldTouched,
                        resetForm,
                    } = props
                    return (
                        <Form onSubmit={handleSubmit}>
                            <ContentBox>
                                <Card>
                                    <CardHeader title="Branch Form" />
                                    <Divider />
                                    <CardContent sx={{ mt: 1 }}>
                                        <Grid container spacing={3}>
                                            <Grid
                                                item
                                                spacing={4}
                                                xs={12}
                                                container
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={8}
                                                    lg={4}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label="Name"
                                                        size="small"
                                                        name="name"
                                                        value={values?.name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={
                                                            touched.name &&
                                                            Boolean(errors.name)
                                                        }
                                                        helperText={
                                                            touched.name &&
                                                            errors.name
                                                        }
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={8}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label="Branch No"
                                                        size="small"
                                                        name="branch_no"
                                                        value={
                                                            values?.branch_no
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={
                                                            touched.branch_no &&
                                                            Boolean(
                                                                errors.branch_no
                                                            )
                                                        }
                                                        helperText={
                                                            touched.branch_no &&
                                                            errors.branch_no
                                                        }
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={8}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label="Address"
                                                        name="address"
                                                        value={values?.address}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        size="small"
                                                        multiline
                                                        rows={2}
                                                        maxRows={4}
                                                        error={
                                                            touched.address &&
                                                            Boolean(
                                                                errors.address
                                                            )
                                                        }
                                                        helperText={
                                                            touched.address &&
                                                            errors.address
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item spacing={3} container>
                                            <Grid item xs={12}>
                                                <Typography
                                                    fontWeight={'medium'}
                                                    variant={'h6'}
                                                >
                                                    Phone Numbers
                                                </Typography>
                                                <StyledDivider />
                                            </Grid>
                                            <Grid
                                                item
                                                lg={8}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                {/* {values.phoneNumbers?.[0] ??
                                                    'data'} */}

                                                {values?.phoneNumbers?.map(
                                                    (item, index) => (
                                                        <div key={index}>
                                                            <TextField
                                                                fullWidth
                                                                name={`phoneNumbers[${index}]`}
                                                                size="small"
                                                                value={
                                                                    values
                                                                        ?.phoneNumbers[
                                                                        index
                                                                    ]
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                error={
                                                                    (touched
                                                                        .phoneNumbers?.[
                                                                        index
                                                                    ] ??
                                                                        false) &&
                                                                    Boolean(
                                                                        errors
                                                                            .phoneNumbers?.[
                                                                            index
                                                                        ] ??
                                                                            false
                                                                    )
                                                                }
                                                                helperText={
                                                                    (touched
                                                                        .phoneNumbers?.[
                                                                        index
                                                                    ] ??
                                                                        false) &&
                                                                    (errors
                                                                        .phoneNumbers?.[
                                                                        index
                                                                    ] ??
                                                                        '')
                                                                }
                                                            />
                                                        </div>
                                                    )
                                                )}
                                                <ul
                                                    onClick={() => {
                                                        setFieldValue(
                                                            'phoneNumbers',
                                                            [
                                                                ...values.phoneNumbers,
                                                                '',
                                                            ]
                                                        )
                                                    }}
                                                >
                                                    Add a Phone Number
                                                </ul>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions sx={{ justifyContent: 'end' }}>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            color="inherit"
                                            onClick={() => {
                                                resetForm()
                                                setState(initialValues)
                                                toggle()
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            type="submit"
                                            endIcon={<Icon>send</Icon>}
                                            // onClick={handleClick}
                                            onClick={handleSubmit}
                                        >
                                            {state?._id
                                                ? 'Update Branch'
                                                : 'Create Branch'}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </ContentBox>
                        </Form>
                    )
                }}
            </Formik>
        </Fragment>
    )
}

export default BranchCreationForm
