import {
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { Box } from '@mui/system'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const ConcreteCubeTable = (props) => {
    const {
        data,
        setData,
        setFinalData,
        finalData,
        result,
        setOverall,
        overall,
    } = props

    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        _finalData.forEach((item) => {
            let average = 0
            if (item.data.length > 0) {
                _data.forEach((item1) => {
                    let value = valueCalc(
                        (item1.compressiveLoad / (item1.len * item1.breadth)) *
                            1000
                    )
                    item1.compressiveStrength = value
                    average += Number(value / _data.length)
                })

                if (item.groupName === result.groupName) {
                    item.data = _data
                    item.overall = {
                        ...overall,
                        average: valueCalc(average),
                    }
                    setOverall(item.overall)
                }
            }
        })
        setFinalData(_finalData)
    }

    // const Column = [
    //     { text: 'Cube ID', dataField: 'cubeId' },
    //     { text: 'Date of Casting', dataField: 'dateOfCasting' },
    //     {
    //         text: 'Date of Testing',
    //         dataField: 'dateOfTesting',
    //     }, {
    //         text: 'Age (Days)',
    //         dataField: 'age',
    //     }, {
    //         text: 'Weight (kg)',
    //         dataField: 'weight',
    //     }, {
    //         text: 'Cross sectional area, A(mm²)',
    //         dataField: 'crossSectionalArea',
    //     }, {
    //         text: ' Crussing Load, F (kN)',
    //         dataField: 'crussingLoad',
    //     }, {
    //         text: 'Compressive Strength, F/A (N/mm²)',
    //         dataField: 'compressiveStrength',
    //     }, {
    //         text: 'Average Compressive strength (N/mm²)',
    //         dataField: 'averageCompressiveStrength',
    //     }, {
    //         text: 'Remarks',
    //         dataField: 'remarks',
    //     },
    // ]

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Cube ID</TableCell>

                        <TableCell align="center">Length</TableCell>
                        <TableCell align="center">Breadth</TableCell>
                        <TableCell align="center">Height</TableCell>
                        <TableCell align="center">Area(mm²)</TableCell>

                        <TableCell align="center">Weight(Kgs)</TableCell>

                        <TableCell align="center">
                            Compressive Load, F (kN)
                        </TableCell>
                        <TableCell align="center">
                            Compressive Strength, F/A (N/mm²)
                        </TableCell>
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell align="center">{item.cubeId}</TableCell>
                            <TableCell align="center">{item.len}</TableCell>
                            <TableCell align="center">{item.breadth}</TableCell>
                            <TableCell align="center">{item.height}</TableCell>
                            <TableCell align="center">
                                {
                                    (item.area = valueCalc(
                                        item.len * item.breadth
                                    ))
                                }
                            </TableCell>
                            <TableCell align="center">{item.weight}</TableCell>

                            <TableCell align="center">
                                {item.compressiveLoad}
                            </TableCell>
                            <TableCell align="center">
                                {
                                    (item.compressiveStrength = valueCalc(
                                        (item.compressiveLoad / item.area) *
                                            1000
                                    ))
                                }
                            </TableCell>

                            <TableCell align="center">
                                <Button onClick={(e) => handleDelete(index, e)}>
                                    <Icon>delete</Icon>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default ConcreteCubeTable
