import { Box, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const FlyAshFinenessTable = (props) => {
    const { data } = props

    let moistureContent

    moistureContent = [
        {
            label: 'Weight of the Sample Retained on 75 & 45micron sieve',
            value: 'weight_of_sample_retained_75_45_micron',
        },
        {
            label: 'Total Weight of the sample',
            value: 'total_weight_of_sample',
        },
        {
            label: 'Particles retained on 45mic sieve(Wet Sieve)',
            value: 'particles_retained_on_45mic',
        },
    ]

    const Column = [
        {
            text: 'Observation',
            dataField: 'label',
        },
        {
            text: 'Result',
            dataField: 'value',
        },
    ]

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">SI.No</TableCell>
                        {Column.map((item, index) => (
                            <TableCell align="center" key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                {moistureContent?.map((item, index) => (
                    <TableBody>
                        <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{item.label}</TableCell>
                            <TableCell align="center">
                                {data[item.value] ? data[item.value] : '0'}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ))}
            </StyledTable>
        </Box>
    )
}

export default FlyAshFinenessTable
