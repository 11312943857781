import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { sampleColloectGrade } from 'app/assets/Constant/constant'
import { sendMailData } from 'app/services/ReportService'
import moment from 'moment'

const fontSize = 10
const labelfontfamily = 'Times-Bold'

// const styles = StyleSheet.create({
//     SampleContainer: {
//         display: 'flex',
//         flexDirection: 'row',
//     },
//     dataContainer: {
//         display: 'flex',
//         flexDirection: 'row',
//         marginTop: 2,
//         maxwidth: '100%',

//         justifyContent: 'space-between',
//     },
// value: {
//     display: 'flex',
//     flexDirection: 'row',
//     fontSize: fontSize,
//     marginTop: 1,
//     flexWrap: 'wrap',
//     flex: 0.8,
// },
// label: {
//     display: 'flex',
//     flexDirection: 'row',
//     width: '55%',
//     marginTop: 1,

//     fontSize: fontSize,
//     flex: 0.8,
//     fontFamily: labelfontfamily,
// },

//     container: {
//         justifyContent: 'flex-end',
//         marginTop: 2,
//     },
// })
// const styles = StyleSheet.create({
//     SampleContainer: {
//         display: 'flex',
//         flexDirection: 'row',
//     },
//     dataContainer: {
//         display: 'flex',
//         flexDirection: 'row',
//         marginTop: 2,
//         maxWidth: '100%',
//         justifyContent: 'space-between',
//     },
// value: {
//     display: 'flex',
//     flexDirection: 'row',
//     fontSize: fontSize,
//     marginTop: 1,
//     flexWrap: 'wrap',
//     flex: 0.8,
// },
// label: {
//     display: 'flex',
//     flexDirection: 'row',
//     width: '55%',
//     marginTop: 1,
//     fontSize: fontSize,
//     flex: 0.8,
//     fontFamily: labelfontfamily,
// },
//     container: {
//         justifyContent: 'flex-end',
//         marginTop: 2,
//     },
//     row: {
//         flexDirection: 'row',
//         justifyContent: 'space-between',
//         marginBottom: 5,
//     },
//     column: {
//         flex: 1,
//         marginRight: 5,
//     },
//})

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row', // Arrange columns horizontally
        justifyContent: 'space-between', // Evenly space columns
    },
    column: {
        flex: 1, // Each column takes equal space
    },
    dataContainer: {
        flexDirection: 'row',
        //marginBottom: 10, // Adjust spacing between data items
        marginTop: 2,
        maxWidth: '100%',
        //justifyContent: 'space-between',
    },
    value: {
        display: 'flex',
        fontSize: fontSize,
        marginTop: 1,
        marginRight: 50,
        flexWrap: 'wrap',
        flex: 0.8,
    },

    value1: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: fontSize,
        marginTop: 1,
        //marginRight: 50,
        flexWrap: 'wrap',
        flex: 1.1,
    },
    label: {
        display: 'flex',
        flexDirection: 'row',
        width: '55%',
        marginTop: 1,

        fontSize: fontSize,
        flex: 0.8,
        fontFamily: labelfontfamily,
    },
})

//  {
//      details?.material.name === 'Soil'
//          ? details?.specification + ' ' + details?.material.name
//          : details?.material.name
//  }
const SampleDetails = ({
    details,
    jobNo,
    type,
    titleName,
    standard_ref,
    organizationName,
    groupType,
}) => {
    let castingDate = details?.castingDate
        ? details?.castingDate
        : details?.customer_samples?.sample_created_at
          ? details?.customer_samples?.sample_created_at
          : details?.customer_samples?.createdAt
            ? details?.customer_samples?.createdAt
            : details?.start_date
              ? details?.start_date
              : details?.customer_samples?.updatedAt
    let trf = details?.material?.trf?.name

    let dateofTesting = details?.dateofTesting
        ? moment(details?.dateofTesting).format('DD-MM-YYYY')
        : null
    let sampleReceivedOn = details?.sampleReceivedOn
        ? moment(details?.sampleReceivedOn).format('DD-MM-YYYY')
        : null

    let testStartedOn = details?.invoice_id?.startedOn
        ? moment(details?.invoice_id?.startedOn).format('DD-MM-YYYY')
        : null

    let testCompletedOn = details?.invoice_id?.completedOn
        ? moment(details?.invoice_id?.completedOn).format('DD-MM-YYYY')
        : null

    // let invoice_no = details?.invoice_id?.invoice_no
    let invoiceStatus = details?.invoice_id?.invoiceStatus
    let invoice_no = details?.customer_samples?.invoiceNo
    let supplierName = details?.customer_samples?.supplier_name
    let sampleBy = 'CaRE Laboratory'

    // let filtered_standard_ref = details.standard_ref.filter((c, index) => {
    //     return details.standard_ref.indexOf(c) === index
    // })
    let trNo = String(jobNo).replace('TJ', '').replace('J', '')
    let titleName1 = titleName
    if (
        titleName === 'CHE-Drinking Water' ||
        titleName === 'BIO-Drinking Water'
    ) {
        titleName1 = 'Drinking Water'
    }
    const dataItems = [
        // {
        //     label: `\u2022 Description`,
        //     value: details?.specification
        //         ? `${details.specification} ${details.material.name}`
        //         : details?.material.name,
        //     condition: details?.specification
        //         ? `${details.specification} ${details.material.name}`
        //         : details?.material.name,
        // },
        {
            label: `\u2022 Description`,
            value: details?.specification
                ? `${details.specification} ${titleName1}`
                : titleName1,
            condition: details?.specification
                ? `${details.specification} ${titleName1}`
                : titleName1,
        },
        {
            label: `\u2022 Supplier Name`,
            value: supplierName,
            condition: supplierName,
        },
        {
            label: `\u2022 Invoice No. / Lot No.`,
            value: invoice_no,
            condition: invoice_no && invoiceStatus === 'show',
        },
        {
            label: `\u2022 Cube ID/Location`,
            value: `${details?.cube} / ${details?.location}`,
            condition:
                details?.location &&
                details?.material.name === 'Hardened Concrete Cube',
        },
        {
            label:
                trf === 'trf 4'
                    ? '\u2022 Grade/Brand'
                    : `\u2022 Grade of Concrete`,
            value: details?.grade ?? '',
            condition: details?.grade,
        },
        {
            label: `\u2022 Grade`,
            value: details?.diaAndGrade,
            condition:
                details?.diaAndGrade?.length > 0 &&
                sampleColloectGrade?.includes(details?.material?.name),
        },
        {
            label: `\u2022 Water Adding Time`,
            value: details?.waterAddingTime,
            condition: details?.waterAddingTime?.length > 0,
        },
        {
            label: `\u2022 Brand`,
            value: details?.type,
            condition: !details?.grade && details?.type,
        },
        {
            label: `\u2022 Source`,
            value: details?.source ? details?.source : '',
            condition: !details?.grade && details?.source,
        },
        {
            label: `\u2022 Date of Casting`,
            value: moment(castingDate).format('DD-MM-YYYY') ?? '',
            condition: details?.castingDate,
        },
        {
            label: `\u2022 Diameter`,
            value: details?.diameter ? details?.diameter : '',
            condition: details?.diameter,
        },
        {
            label: `\u2022 Dia`,
            value: details?.dimension ? details?.dimension : '',
            condition: details?.dimension,
        },
        {
            label: `\u2022 Date of Testing`,
            value: dateofTesting ? dateofTesting : '',
            condition: details?.dateofTesting,
        },
        {
            label: `\u2022 Quantity / Unit`,
            value: `${details?.quantity ? details?.quantity : ''} ${details?.unit ? details?.unit : ''}`,
            condition: details?.quantity,
        },
        {
            label: `\u2022 Sample Received on `,
            value: sampleReceivedOn,
            condition: sampleReceivedOn,
        },
        {
            label: `\u2022 Test Started on `,
            value: testStartedOn,
            condition: testStartedOn,
        },
        {
            label: `\u2022 Test Completed on `,
            value: testCompletedOn,
            condition: testCompletedOn,
        },
        {
            label: `\u2022 Age (or) No. of Days`,
            value:
                details?.testingDate <= 9
                    ? '0' + details?.testingDate
                    : details?.testingDate,
            condition: details?.testingDate,
        },
    ]
    const dataItems1 = [
        {
            label: `\u2022 Sample Code :`,
            value: trNo,
        },
        {
            label: `\u2022 Sampled on : `,
            value: testStartedOn,
        },
        // {
        //     label: `\u2022 Sample Name :`,
        //     value: details?.specification
        //         ? details?.specification + ' ' + details?.material.name
        //         : details?.material.name,
        // },
        {
            label: `\u2022 Sample Name :`,
            value: details?.specification
                ? details?.specification + ' ' + titleName1
                : titleName1,
        },
        {
            label: `\u2022 Sampled by :`,
            value: sampleBy,
        },
        {
            label: `\u2022 Sample Description : `,

            value: details?.sample_description,
        },
        {
            label: `\u2022 Sampling Location : `,
            value: details?.location,
        },
        {
            label: `\u2022 Customer Ref : `,
            value: details?.identificationByCustomer,
        },
        {
            label: `\u2022 Sampling Procedure : `,
            value: details?.samplingProcedure,
        },
        {
            label: `\u2022 Test Started on : `,
            value: testStartedOn,
        },
        {
            label: `\u2022 Sample Received on : `,
            value: sampleReceivedOn,
        },
        {
            label: `\u2022 Test Completed on : `,
            value: testCompletedOn,
        },
        {
            label: `\u2022 Sample Condition : `,
            value: details?.sample_conditions,
        },
    ]
    
    return (
        <View>
            {organizationName === 'CareLab' ||
            (groupType === 'DYNAMIC_JOBCARD' &&
                organizationName !== 'CareLab') ? (
                <>
                    <View style={styles.dataContainer}>
                        {[0, 1].map(
                            (columnIndex) => (
                                (
                                    <View
                                        key={columnIndex}
                                        style={styles.column}
                                    >
                                        {dataItems1
                                            .filter(
                                                (item) =>
                                                    item.value !== undefined &&
                                                    item.value !== null &&
                                                    item.value !== false &&
                                                    item.value !== ''
                                            )

                                            .filter(
                                                (item, index) =>
                                                    index % 2 === columnIndex
                                            ) // Filter odd and even items for each column
                                            .map(
                                                (item, index) => (
                                                    (
                                                        <View
                                                            key={index}
                                                            style={
                                                                styles.dataContainer
                                                            }
                                                        >
                                                            <Text
                                                                style={
                                                                    styles.label
                                                                }
                                                            >
                                                                {item.label}
                                                            </Text>
                                                            <Text
                                                                style={
                                                                    styles.value1
                                                                }
                                                            >
                                                                {item.value}
                                                            </Text>
                                                        </View>
                                                    )
                                                )
                                            )}
                                    </View>
                                )
                            )
                        )}
                    </View>
                    {/* <View style={styles.dataContainer}>
                        <Text style={styles.label}>
                            {`\u2022 Sample Code : `}
                        </Text>
                        <Text style={styles.value}>{trNo}</Text>
                        <Text style={styles.label}>
                            {`\u2022 Sampled on : `}
                        </Text>
                        <Text style={styles.value}>{testStartedOn}</Text>
                    </View>
                    <View style={styles.dataContainer}>
                        <Text style={styles.label}>
                            {`\u2022 Sample Name : `}
                        </Text>
                        <Text style={styles.value}>
                            {details?.specification
                                ? details?.specification + ' ' + titleName1
                                : titleName1}
                        </Text>
                        <Text style={styles.label}>
                            {`\u2022 Sampled by : `}
                        </Text>
                        <Text style={styles.value}>{sampleBy}</Text>
                    </View>
                    <View style={styles.dataContainer}>
                        <Text style={styles.label}>
                            {`\u2022 Sample Description : `}
                        </Text>
                        <Text style={styles.value}>
                            {details?.sample_description}
                        </Text>
                        <Text style={styles.label}>
                            {`\u2022 Sampling Location : `}
                        </Text>
                        <Text style={styles.value}>{details?.location}</Text>
                    </View>
                    <View style={styles.dataContainer}>
                        <Text style={styles.label}>
                            {`\u2022 Customer Ref : `}
                        </Text>
                        <Text style={styles.value}>
                            {details?.identificationByCustomer}
                        </Text>
                        <Text style={styles.label}>
                            {`\u2022 Sampling Procedure : `}
                        </Text>
                        <Text style={styles.value}>
                            {details?.samplingProcedure}
                        </Text>
                    </View>
                    <View style={styles.dataContainer}>
                        <Text style={styles.label}>
                            {`\u2022 Test Started on : `}
                        </Text>
                        <Text style={styles.value}>{testStartedOn}</Text>
                        <Text style={styles.label}>
                            {`\u2022 Sample Received on : `}
                        </Text>
                        <Text style={styles.value}>{sampleReceivedOn}</Text>
                    </View>
                    <View style={styles.dataContainer}>
                        <Text style={styles.label}>
                            {`\u2022 Test Completed on : `}
                        </Text>
                        <Text style={styles.value}>{testCompletedOn}</Text>
                        <Text style={styles.label}>
                            {`\u2022 Sample Condition : `}
                        </Text>
                        <Text style={styles.value}>
                            {details?.sample_conditions}
                        </Text>
                    </View> */}
                </>
            ) : (
                <>
                    <View style={styles.container}>
                        {[0, 1].map(
                            (columnIndex) => (
                                (
                                    <View
                                        key={columnIndex}
                                        style={styles.column}
                                    >
                                        {dataItems
                                            .filter(
                                                (item) =>
                                                    item.condition !==
                                                        undefined &&
                                                    item.condition !== null &&
                                                    item.condition !== false &&
                                                    item.value !== ''
                                            )
                                            .filter(
                                                (item, index) =>
                                                    index % 2 === columnIndex
                                            ) // Filter odd and even items for each column
                                            .map((item, index) => (
                                                <View
                                                    key={index}
                                                    style={styles.dataContainer}
                                                >
                                                    <Text style={styles.label}>
                                                        {item.label}
                                                    </Text>
                                                    <Text style={styles.value1}>
                                                        {item.value}
                                                    </Text>
                                                </View>
                                            ))}
                                    </View>
                                )
                            )
                        )}
                    </View>
                </>
            )}
        </View>
    )
}

export default SampleDetails
