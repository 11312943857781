import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Modal,
    TextField,
    Typography,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import { useContext, useState } from 'react'
import * as Yup from 'yup'
import { valueCalc } from '../../method/method'
import MoistureContentTable from './MoistureContentTable'

const initialValues = {
    sampleId: '',
    dry_length: '',
    dry_breadth: '',
    dry_height: '',
    l1: '',
    wet_length: '',
    wet_breadth: '',
    wet_height: '',
    l2: '',
    moisturecontent: '',
}

const validationSchema = Yup.object().shape({
    // sample_Id: Yup.string().required('Required'),
    dry_length: Yup.string().required('Required'),
    dry_breadth: Yup.number().required('Required'),
    dry_height: Yup.number().required('Required'),
    wet_length: Yup.number().required('Required'),
    wet_breadth: Yup.number().required('Required'),
    wet_height: Yup.number().required('Required'),
})
const MoistureContentModal = (props) => {
    const { result, isView, setOverall, overall } = props
    const handleClose = () => setOpen(false)
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const [data, setData] = useState(result?.data ? result?.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const { finalData, setFinalData } = useContext(AppContext)
    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && arr.length !== 0) {
                let moistureMovement = 0
                arr.forEach((item1) => {
                    item1.l1 = Number(
                        item1.dry_length * item1.dry_breadth * item1.dry_height
                    )
                    item1.l2 = Number(
                        item1.wet_length * item1.wet_breadth * item1.wet_height
                    )
                    let value = Number(
                        ((item1.l1 - item1.l2) / item1.l2) * 100
                    ).toFixed(2)
                    item1.moisturecontent = value
                    moistureMovement += Number(value / arr.length)
                })
                item.overall = {
                    ...overall,

                    moistureMovement: valueCalc(moistureMovement),
                }
                setOverall(item.overall)
            }
            if (item.groupName === result.groupName) {
                item.data = arr
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
    }
    return (
        <Grid item xs={12}>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props
                    return (
                        <Form>
                            <Modal open={open} onClose={handleClose}>
                                <StyledCard>
                                    <CardHeader title="Moisture Movement" />
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            {/* <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Sample ID"
                                                    required
                                                    value={values.sample_Id}
                                                    name="sample_Id"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.sample_Id &&
                                                        touched.sample_Id
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.sample_Id &&
                                                        touched.sample_Id
                                                        ? errors.sample_Id
                                                        : ''}
                                                </HelperText>
                                            </Grid> */}

                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h6"
                                                    fontWeight={'normal'}
                                                >
                                                    Dimension in mm (Dry) - L1
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Length,L (mm)"
                                                    required
                                                    value={values.dry_length}
                                                    name="dry_length"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.dry_length &&
                                                        touched.dry_length
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.dry_length &&
                                                    touched.dry_length
                                                        ? errors.dry_length
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Bredth,B(mm)"
                                                    value={values.dry_breadth}
                                                    name="dry_breadth"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.dry_breadth &&
                                                        touched.dry_breadth
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.dry_breadth &&
                                                    touched.dry_breadth
                                                        ? errors.dry_breadth
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Height,H(mm)"
                                                    required
                                                    value={values.dry_height}
                                                    name="dry_height"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.dry_height &&
                                                        touched.dry_height
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.dry_height &&
                                                    touched.dry_height
                                                        ? errors.dry_height
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h6"
                                                    fontWeight={'normal'}
                                                >
                                                    Dimension in mm(Wet) - L2
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Length,L (mm)"
                                                    required
                                                    value={values.wet_length}
                                                    name="wet_length"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.wet_length &&
                                                        touched.wet_length
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.wet_length &&
                                                    touched.wet_length
                                                        ? errors.wet_length
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Bredth,B(mm)"
                                                    value={values.wet_breadth}
                                                    name="wet_breadth"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.wet_breadth &&
                                                        touched.wet_breadth
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.wet_breadth &&
                                                    touched.wet_breadth
                                                        ? errors.wet_breadth
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Height,H(mm)"
                                                    required
                                                    value={values.wet_height}
                                                    name="wet_height"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.wet_height &&
                                                        touched.wet_height
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.wet_height &&
                                                    touched.wet_height
                                                        ? errors.wet_height
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </StyledCard>
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
            {data && (
                <MoistureContentTable
                    data={data}
                    setData={setData}
                    finalData={finalData}
                    setFinalData={setFinalData}
                    result={result}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}

            {isView !== true && (
                <Grid container justifyContent="end">
                    <Button
                        sx={{ boxShadow: 'none', mt: 2 }}
                        variant="contained"
                        endIcon={<AddCircle />}
                        onClick={handleOpen}
                        size="small"
                    >
                        Add
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default MoistureContentModal
