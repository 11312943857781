import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Modal,
    TextField,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import { useContext, useState } from 'react'
import * as Yup from 'yup'

import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import { valueCalc } from '../../../method/method'
import DimensionTable from '../DimensionTable'

const initialValues = {
    len: '',
    breadth: '',
    height: '',
}
const validationSchema = Yup.object().shape({
    len: Yup.number().required('Required'),
    breadth: Yup.number().required('Required'),
    height: Yup.number().required('Required'),
})

const DimensionModal = (props) => {
    const { result, isView, overall, setOverall } = props
    const handleClose = () => setOpen(false)
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)

    const [data, setData] = useState(result.data ? result.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const { finalData, setFinalData } = useContext(AppContext)
    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        let _finalData = finalData
        let avg = 0
        let avgLength = 0
        let avgBreadth = 0
        let avgHeight = 0
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && arr.length !== 0) {
                arr.forEach((item1) => {
                    let length = item1.len
                    avgLength += Number(length / arr.length)
                    let breadth = item1.breadth
                    avgBreadth += Number(breadth / arr.length)
                    let height = item1.height
                    avgHeight += Number(height / arr.length)
                    let average = item1.len * item1.breadth * item1.height
                    avg += Number(average / arr.length)
                })
            }
            if (item.groupName === result.groupName) {
                item.data = arr
                item.overall = {
                    ...overall,
                    avgHeight: valueCalc(avgHeight),
                    avgBreadth: valueCalc(avgBreadth),
                    avgLength: valueCalc(avgLength),
                    avg: valueCalc(avg),
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
        // resetForm()
    }

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props
                    return (
                        <Form>
                            <Modal open={open} onClose={handleClose}>
                                <StyledCard>
                                    <CardHeader title="Dimension Test" />
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    name="len"
                                                    value={values.len}
                                                    label="Length , L (mm)"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.len &&
                                                        touched.len
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.len && touched.len
                                                        ? errors.len
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    name="breadth"
                                                    value={values.breadth}
                                                    label="Breadth , B (mm)"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.breadth &&
                                                        touched.breadth
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.breadth &&
                                                    touched.breadth
                                                        ? errors.breadth
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    name="height"
                                                    value={values.height}
                                                    label="Height, H (mm)"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.height &&
                                                        touched.height
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.height &&
                                                    touched.height
                                                        ? errors.height
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            type="submit"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </StyledCard>
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
            {data && (
                <DimensionTable
                    data={data}
                    setData={setData}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    result={result}
                    overall={overall}
                    setOverall={setOverall}
                />
            )}
            {isView !== true && (
                <Grid container justifyContent="end">
                    <Button
                        sx={{ boxShadow: 'none', mt: 2 }}
                        variant="contained"
                        endIcon={<AddCircle />}
                        onClick={handleOpen}
                        size="small"
                    >
                        Add
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default DimensionModal
