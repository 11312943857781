import { fetchReportData, sendMailData } from 'app/services/ReportService'
// get report

export const getReportData = (id, callBack) => {
    fetchReportData(
        id,
        (res) => callBack(res?.data),
        (err) => console.log(err)
    )
}

// send Mail

export const sendMailReport = (payLoad, callBack) => {
    sendMailData(
        payLoad,
        (res) => {
            callBack(res)
        },
        (err) => console.log(err)
    )
}
