import { Box, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const ParticleFinerThan75MicronTable = (props) => {
    const { data } = props

    let particlesFine = (particlesFine = [
        {
            label: 'Weight of Oven dried sample, A (g)',
            value: 'weight_oven_dry_sample',
        },
        {
            label: 'Weight of sample after wash, B(g)',
            value: 'weight_sample_after_wash',
        },
        {
            label: 'Particles finer than 75 micron in % , (A-B)/A*100',
            value: 'particles_fine',
        },
    ])
    const Column = [
        { text: 'Observation', dataField: 'label' },
        { text: 'Result', dataField: 'value' },
    ]

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        {/* <TableCell align="center">Actions</TableCell> */}
                        <TableCell align="center">SI.No</TableCell>
                        {Column.map((item, index) => (
                            <TableCell align="center" key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                {particlesFine?.map((item, index) => (
                    <TableBody>
                        <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{item.label}</TableCell>
                            <TableCell align="center">
                                {data[item.value] ? data[item.value] : '0'}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ))}
            </StyledTable>
        </Box>
    )
}

export default ParticleFinerThan75MicronTable
