import { Box, TableCell, TableHead, TableRow } from '@mui/material'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'

const AggregateSoundnessTestTable = ({ data }) => {
    let aggregate

    aggregate = [
        {
            observation: 'Weight of Oven dried sample after wash, A (g)',
            value: 'weight_of_oven_dried_A',
        },
        {
            observation:
                'Weight of sample after removal from Sodium Sulphate, B(g)',
            value: 'weight_of_sample_after_removal_B',
        },
        {
            observation:
                'Weight of sample after removal from Magnesium Sulphate, C(g)',
            value: 'weight_of_sample_after_removal_C',
        },
        {
            observation:
                'Soundness for Sodium Sulphate Solution in % : (A-B)/A*100',
            value: 'sodium_sulphate_solution',
        },
        {
            observation:
                'Soundness for Magnesium Sulphate Solution in % : (A-C)/A*100',
            value: 'magnesium_sulphate_solution',
        },
    ]

    const Column = [
        { text: 'Observation', dataField: 'observation' },
        { text: 'Result', dataField: 'value' },
    ]
    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        {/* <TableCell align="center">Actions</TableCell> */}
                        <TableCell align="center">SI.No</TableCell>
                        {Column.map((item, index) => (
                            <TableCell align="center" key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                {aggregate?.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">{item.observation}</TableCell>
                        <TableCell align="center">
                            {data[item.value] ? data[item.value] : '0'}
                        </TableCell>
                    </TableRow>
                ))}
            </StyledTable>
        </Box>
    )
}

export default AggregateSoundnessTestTable
