import Axios from 'app/configs/Axios'

// Report Data

export const fetchReportData = (id, callback, errorCallback) => {
    return Axios({
        method: 'get',
        url: `/jobs/${id}/report`,
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}

// Send Mail

export const sendMailData = (payLoad, callback, errorCallback) => {
    return Axios({
        method: 'post',
        url: '/mail',
        data: payLoad,
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}
