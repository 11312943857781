import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import { useContext, useState } from 'react'
import * as Yup from 'yup'
import { valueCalc } from '../../method/method'
import SoilSieveAnalysisTable from './SoilSieveAnalysisTable'

const initialValues = {
    sieve_size: '4.75mm',
    retained_weight: '',
    cumulative_weight_retained: '',
    cumulative_passing_weight_retained: '',
    passing_percentage: '',
}
const validationSchema = Yup.object().shape({
    // sieve_size: Yup.number().required('Required'),
    retained_weight: Yup.number().required('Required'),
    //passing_percentage: Yup.number().required('Required'),
})

const SoilSieveAnalysisModal = ({ result, isView, overall, setOverall }) => {
    const handleClose = () => setOpen(false)
    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)
    const [data, setData] = useState(result.data ? result.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const [sieveSize, setSieveSize] = useState('4.75mm')
    const { finalData, setFinalData } = useContext(AppContext)
    let coarseSandValue = ['2.36mm']
    let mediumSandValue = ['1.18mm', '425micron']
    let fineSandValue = ['212micron', '125micron', '75micron']
    const handleSubmit = (values, { resetForm }) => {
        let coarseSand = 0
        let coarseSandAdded = false
        let mediumSand = 0
        let fineSand = 0
        let silt = 0
        let gravel = 0
        let cumulativeWeightRetained = 0
        let addedweightretained = 0

        let arr = [...data]
        console.log(arr, 'arr')
        arr.push(values)
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item?.groupName === result?.groupName && arr.length !== 0) {
                arr?.forEach((item1) => {
                    addedweightretained += Number.parseFloat(
                        item1.retained_weight
                    )

                    cumulativeWeightRetained += Number.parseFloat(
                        item1.retained_weight
                    )

                    item1.cumulative_weight_retained =
                        cumulativeWeightRetained.toString()
                    // item1?.retained_weight === '0'
                    //     ? item1.retained_weight
                    //     : cumulativeWeightRetained
                })

                arr?.forEach((item1) => {
                    item1.cumulative_passing_weight_retained =
                        item1.cumulative_weight_retained === '0'
                            ? item1.cumulative_weight_retained
                            : valueCalc(
                                  (item1?.cumulative_weight_retained /
                                      addedweightretained) *
                                      100
                              )

                    item1.passing_percentage = valueCalc(
                        100 - item1?.cumulative_passing_weight_retained
                    )

                    if (coarseSandValue.includes(item1?.sieve_size)) {
                        coarseSandAdded = true
                        coarseSand += parseFloat(item1?.retained_weight)
                    }

                    if (mediumSandValue.includes(item1?.sieve_size)) {
                        coarseSandAdded = true
                        mediumSand += parseFloat(item1?.retained_weight)
                    }
                    if (fineSandValue.includes(item1?.sieve_size)) {
                        coarseSandAdded = true
                        fineSand += parseFloat(item1?.retained_weight)
                    }
                    if (item1?.sieve_size === 'pan') {
                        coarseSandAdded = true
                        silt += parseFloat(item1?.retained_weight)
                    }
                    if (item1?.sieve_size === '4.75mm') {
                        coarseSandAdded = true
                        gravel += parseFloat(item1?.retained_weight)
                    }
                })
            }
            if (item.groupName === result.groupName) {
                item.data = arr
                if (coarseSandAdded) {
                    item.overall = {
                        ...overall,
                        coarseSand: valueCalc(coarseSand),
                        mediumSand: valueCalc(mediumSand),
                        fineSand: valueCalc(fineSand),
                        silt: valueCalc(silt),
                        gravel: valueCalc(gravel),
                    }
                } else {
                    item.overall = {
                        ...overall,
                        coarseSand: null,
                        mediumSand: null,
                        fineSand: null,
                        silt: null,
                        gravel: null,
                    }
                }
                // item.overall = {
                //     ...overall,
                //     coarseSand: coarseSand === 0 ? null : valueCalc(coarseSand),
                //     mediumSand: mediumSand === 0 ? null : valueCalc(mediumSand),
                //     fineSand: fineSand === 0 ? null : valueCalc(fineSand),
                //     silt: silt === 0 ? null : valueCalc(silt),
                //     gravel: gravel === 0 ? null : valueCalc(gravel),
                // }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
    }
    const handleChangeSize = (e) => {
        setSieveSize(e.target.value)
    }

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props
                    return (
                        <Form>
                            <Modal open={open} onClose={handleClose}>
                                <StyledCard>
                                    <CardHeader title=" Sieve Analysis" />
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                        Sieve Size
                                                    </InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={
                                                            values.sieve_size
                                                        }
                                                        name="sieve_size"
                                                        label="Sieve Size"
                                                        defaultValue="4.75mm"
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            handleChangeSize(e)
                                                        }}
                                                        onBlur={handleBlur}
                                                        error={
                                                            errors.sieve_size &&
                                                            touched.sieve_size
                                                        }
                                                    >
                                                        <MenuItem
                                                            value={'4.75mm'}
                                                        >
                                                            4.75mm
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'2.36mm'}
                                                        >
                                                            2.36mm
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'1.18mm'}
                                                        >
                                                            1.18mm
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'425micron'}
                                                        >
                                                            425micron
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'212micron'}
                                                        >
                                                            212micron
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'125micron'}
                                                        >
                                                            125micron
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'75micron'}
                                                        >
                                                            75micron
                                                        </MenuItem>
                                                        <MenuItem value={'pan'}>
                                                            pan
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <HelperText>
                                                    {errors.sieve_size &&
                                                    touched.sieve_size
                                                        ? errors.sieve_size
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Retained Weight (g)"
                                                    value={
                                                        values.retained_weight
                                                    }
                                                    name="retained_weight"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.retained_weight &&
                                                        touched.retained_weight
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.retained_weight &&
                                                    touched.retained_weight
                                                        ? errors.retained_weight
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            {/* <Grid
                                                item

                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Passing, %"
                                                    value={
                                                        values.passing_percentage
                                                    }
                                                    name="passing_percentage"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.passing_percentage &&
                                                        touched.passing_percentage
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.passing_percentage &&
                                                        touched.passing_percentage
                                                        ? errors.passing_percentage
                                                        : ''}
                                                </HelperText>
                                            </Grid> */}
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </StyledCard>
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
            {data && (
                <SoilSieveAnalysisTable
                    data={data}
                    setData={setData}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    result={result}
                    overall={overall}
                    setOverall={setOverall}
                    coarseSandValue={coarseSandValue}
                    mediumSandValue={mediumSandValue}
                    fineSandValue={fineSandValue}
                />
            )}
            {isView !== true && (
                <Grid container justifyContent="end">
                    <Button
                        sx={{ boxShadow: 'none', mt: 2 }}
                        variant="contained"
                        endIcon={<AddCircle />}
                        onClick={handleOpen}
                        size="small"
                    >
                        Add
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default SoilSieveAnalysisModal
