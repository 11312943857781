import {
    Box,
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../../method/method'

const CouplerChemicalTable = (props) => {
    let {
        data,
        setData,
        setFinalData,
        finalData,
        result,
        setOverall,
        overall,
        handleCustomRequirementChange,
    } = props

    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData

        let avg_perc_carbon = 0
        let avg_perc_manganese = 0
        let avg_perc_silicon = 0
        let avg_perc_sulphur = 0
        let avg_perc_phosphorus = 0
        let dia

        _finalData.forEach((item) => {
            if (item.groupName === result.groupName) {
                if (item.data.length > 0) {
                    _data.forEach((item1) => {
                        avg_perc_carbon += Number(
                            item1?.perc_carbon / _data.length
                        )
                        avg_perc_manganese += Number(
                            item1?.perc_manganese / _data.length
                        )
                        avg_perc_silicon += Number(
                            item1?.perc_silicon / _data.length
                        )
                        avg_perc_sulphur += Number(
                            item1?.perc_sulphur / _data.length
                        )
                        avg_perc_phosphorus += Number(
                            item1?.perc_phosphorus / _data.length
                        )

                        dia = item1?.dia
                    })
                }
                item.overall = {
                    ...overall,
                    avg_perc_carbon: valueCalc(avg_perc_carbon),
                    avg_perc_manganese: valueCalc(avg_perc_manganese),
                    avg_perc_silicon: valueCalc(avg_perc_silicon),
                    avg_perc_phosphorus: valueCalc(avg_perc_phosphorus),
                    avg_perc_sulphur: valueCalc(avg_perc_sulphur),
                }
                setOverall(item.overall)

                // if (item.data.length > 0) {

                //     handleCustomRequirementChange(dia + 'mm', item.overall)
                // } else {
                //     handleCustomRequirementChange('', item.overall)

                // }

                item.data = _data
            }
        })

        setFinalData(_finalData)
    }

    const Column = [
        { text: 'Dia (mm)', dataField: 'dia' },

        { text: '% Carbon', dataField: 'perc_carbon' },
        { text: '% Manganese', dataField: 'perc_manganese' },
        { text: '% Silicon', dataField: 'perc_silicon' },
        { text: '% Sulphur', dataField: 'perc_sulphur' },
        { text: '% Phosphorus', dataField: 'perc_phosphorus' },
    ]

    return (
        <Box width="100%" sx={{ overflowX: 'auto' }}>
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">S.No</TableCell>
                        {Column.map((item, index) => (
                            <TableCell align="center" key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                        <TableCell sx={{ width: 80 }} align={'center'}>
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            {Column.map((subItem, idx) => (
                                <TableCell
                                    align="center"
                                    key={`row-${idx}-index`}
                                >
                                    {item[subItem.dataField]}
                                </TableCell>
                            ))}
                            <TableCell align="center">
                                <Button onClick={(e) => handleDelete(index, e)}>
                                    <Icon>delete</Icon>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell align="center" colSpan={2}>
                            Mean Value
                        </TableCell>

                        <TableCell align="center">
                            {overall?.avg_perc_carbon}
                        </TableCell>
                        <TableCell align="center">
                            {overall?.avg_perc_manganese}
                        </TableCell>
                        <TableCell align="center">
                            {overall?.avg_perc_silicon}
                        </TableCell>
                        <TableCell align="center">
                            {overall?.avg_perc_sulphur}
                        </TableCell>
                        <TableCell align="center">
                            {overall?.avg_perc_phosphorus}
                        </TableCell>
                        <TableCell align="center">-</TableCell>
                    </TableRow>
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default CouplerChemicalTable
