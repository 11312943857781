import { NOTIFICATION } from '../actions/Actions'

const initialState = {
    notification: [],
    notificationJob: [],
}

const NotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATION.SET_TEST_NOTIFICATION_DATA: {
            const copyOfNotification = [state.notification]
            const data = [...action.payload]
            copyOfNotification.push(...data)

            return {
                ...state,
                notification: action.payload,
            }
        }

        case NOTIFICATION.SET_JOB_NOTIFICATION_DATA: {
            const copyOfNotificationJob = [state.notificationJob]
            const data = [...action.payload]
            copyOfNotificationJob.push(...data)
            return {
                ...state,
                notificationJob: action.payload,
            }
        }
        case NOTIFICATION.SET_REMOVED_NOTIFICATION: {
            const copyOfNotification = [...state.notification]
            copyOfNotification.splice(action.payload, 1)
            return {
                ...state,
                notification: copyOfNotification,
            }
        }
        case NOTIFICATION.SET_REMOVED_NOTIFICATION_JOB: {
            const copyOfNotificationJob = [...state.notificationJob]
            copyOfNotificationJob.splice(action.payload, 1)
            return {
                ...state,
                notificationJob: copyOfNotificationJob,
            }
        }

        default: {
            return state
        }
    }
}

export default NotificationReducer
