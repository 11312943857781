import { StyledCard } from 'app/views/jobs/StyledComponents/StyledComponents'

import { Fragment, useContext, useState } from 'react'
import * as Yup from 'yup'

import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Modal,
    TextField,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'
import { HelperText } from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import { valueCalc } from '../../method/method'
import CoreCompressiveStrengthTable from './CoreCompressiveStrengthTable'

function CoreCompressiveStrength({
    result,
    isView,
    overall,
    setOverall,
    handleCustomRequirementChange,
    handleRequirementChange,
}) {
    const { finalData, setFinalData, EditDetails } = useContext(AppContext)

    let castingDate = new Date(
        EditDetails?.data?.castingDate
    ).toLocaleDateString()
    let testingDate = new Date(
        EditDetails?.data?.dateofTesting
    ).toLocaleDateString()
    let age = EditDetails?.data?.testingDate

    const initialValues = {
        cubeId: EditDetails?.data?.cube ? EditDetails?.data?.cube : ' ',
        dateOfCasting: castingDate ? castingDate : '',
        dateOfTesting: testingDate ?? '',
        age: age ?? '',
        time_speciments_placed: '',
        timeOfTest: '',
        crossSectionalArea: '',
        weightCube: '',
        crushingLoad: '',
        compressiveStrength: '',
        compressiveStrengthAfterCorrection: '',
    }
    const validationSchema = Yup.object().shape({
        // cubeId: Yup.string().required('Required'),
        // dateOfCasting: Yup.string().required('Required'),
        dateOfTesting: Yup.string().required('Required'),
        age: Yup.number().required('Required'),
        time_speciments_placed: Yup.string().required('Required'),
        timeOfTest: Yup.string().required('Required'),
        crossSectionalArea: Yup.number().required('Required'),
        weightCube: Yup.number().required('Required'),
        crushingLoad: Yup.number().required('Required'),
        // compressiveStrength: Yup.number().required('Required'),
        compressiveStrengthAfterCorrection: Yup.number().required('Required'),
    })

    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const [data, setData] = useState(result.data ? result.data : [])

    const [initialState, setInitialState] = useState({ ...initialValues })

    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        arr = applyFormula(arr)
        let _finalData = finalData
        let avg = 0
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && arr.length > 0) {
                arr.forEach((item1) => {
                    let comp_st = valueCalc(
                        (item1.crushingLoad / item1.crossSectionalArea) * 1000
                    )
                    avg += Number(comp_st / arr.length)
                })
                item.overall = {
                    ...overall,
                    average: valueCalc(avg),
                }
                setOverall(item.overall)
            }

            if (item.groupName === result.groupName) {
                item.data = arr
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
        resetForm()
    }

    const applyFormula = (arr) => {
        arr.forEach((item, index) => {
            let comp_st = (item.crushingLoad / item.crossSectionalArea) * 1000
            item.compressiveStrength = valueCalc(comp_st)
        })
        return arr
    }

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <JobAccordion
                        title={result?.groupName ?? ''}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        requirementvalue={overall?.requirements}
                        remarksValue={overall?.remarks}
                        overall={overall}
                    >
                        <Grid item xs={12}>
                            <Formik
                                initialValues={initialState}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {(props) => {
                                    const {
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    } = props
                                    return (
                                        <Form>
                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                            >
                                                <StyledCard>
                                                    <CardHeader
                                                        title={
                                                            result?.groupName ??
                                                            ''
                                                        }
                                                    />
                                                    <CardContent
                                                        sx={{
                                                            m: 4,
                                                        }}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={4}
                                                        >
                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    // disabled
                                                                    fullWidth
                                                                    name="cubeId"
                                                                    value={
                                                                        values.cubeId
                                                                    }
                                                                    label="Cube ID"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.cubeId &&
                                                                        touched.cubeId
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.cubeId &&
                                                                    touched.cubeId
                                                                        ? errors.cubeId
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    // disabled
                                                                    fullWidth
                                                                    value={
                                                                        values.dateOfCasting
                                                                    }
                                                                    name="dateOfCasting"
                                                                    label="Date of Casting"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.dateOfCasting &&
                                                                        touched.dateOfCasting
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.dateOfCasting &&
                                                                    touched.dateOfCasting
                                                                        ? errors.dateOfCasting
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    value={
                                                                        values.dateOfTesting
                                                                    }
                                                                    name="dateOfTesting"
                                                                    label="Date of Testing"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.dateOfTesting &&
                                                                        touched.dateOfTesting
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.dateOfTesting &&
                                                                    touched.dateOfTesting
                                                                        ? errors.dateOfTesting
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    value={
                                                                        values.age
                                                                    }
                                                                    name="age"
                                                                    label="Age(Days)"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.age &&
                                                                        touched.age
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.age &&
                                                                    touched.age
                                                                        ? errors.age
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="Time at which the Speciments are Placed inside the Tank"
                                                                    value={
                                                                        values.time_speciments_placed
                                                                    }
                                                                    name="time_speciments_placed"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.time_speciments_placed &&
                                                                        touched.time_speciments_placed
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.time_speciments_placed &&
                                                                    touched.time_speciments_placed
                                                                        ? errors.time_speciments_placed
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    value={
                                                                        values.timeOfTest
                                                                    }
                                                                    name="timeOfTest"
                                                                    label="Time of Test"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.timeOfTest &&
                                                                        touched.timeOfTest
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.timeOfTest &&
                                                                    touched.timeOfTest
                                                                        ? errors.timeOfTest
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    value={
                                                                        values.crossSectionalArea
                                                                    }
                                                                    name="crossSectionalArea"
                                                                    label="Cross Sectional Area, A "
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.crossSectionalArea &&
                                                                        touched.crossSectionalArea
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.crossSectionalArea &&
                                                                    touched.crossSectionalArea
                                                                        ? errors.crossSectionalArea
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>{' '}
                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    value={
                                                                        values.weightCube
                                                                    }
                                                                    name="weightCube"
                                                                    label="Weight of the Cube (kg)"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.weightCube &&
                                                                        touched.weightCube
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.weightCube &&
                                                                    touched.weightCube
                                                                        ? errors.weightCube
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    value={
                                                                        values.crushingLoad
                                                                    }
                                                                    name="crushingLoad"
                                                                    label="Crushing Load, F (kN)"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.crushingLoad &&
                                                                        touched.crushingLoad
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.crushingLoad &&
                                                                    touched.crushingLoad
                                                                        ? errors.crushingLoad
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            {/* <Grid
                                                                            item
                                                                            lg={
                                                                                4
                                                                            }
                                                                            md={
                                                                                8
                                                                            }
                                                                            sm={
                                                                                12
                                                                            }
                                                                            xs={
                                                                                12
                                                                            }
                                                                        >
                                                                            <TextField
                                                                                fullWidth
                                                                                value={
                                                                                    values.compressiveStrength
                                                                                }
                                                                                name="compressiveStrength"
                                                                                label="Compressive Strength, F/A (N/mm²)"
                                                                                onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.blow_count &&
                                                        touched.blow_count
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.blow_count &&
                                                    touched.blow_count
                                                        ? errors.blow_count
                                                        : ''}
                                                </HelperText>
                                                                        </Grid> */}
                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="Compressive Strength After Correction(N/mm²)"
                                                                    value={
                                                                        values.compressiveStrengthAfterCorrection
                                                                    }
                                                                    name="compressiveStrengthAfterCorrection"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.compressiveStrengthAfterCorrection &&
                                                                        touched.compressiveStrengthAfterCorrection
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.compressiveStrengthAfterCorrection &&
                                                                    touched.compressiveStrengthAfterCorrection
                                                                        ? errors.compressiveStrengthAfterCorrection
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button
                                                            size="small"
                                                            onClick={
                                                                handleClose
                                                            }
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            size="small"
                                                            variant="contained"
                                                            onClick={
                                                                handleSubmit
                                                            }
                                                        >
                                                            Save
                                                        </Button>
                                                    </CardActions>
                                                </StyledCard>
                                            </Modal>{' '}
                                        </Form>
                                    )
                                }}
                            </Formik>
                            {data && (
                                <CoreCompressiveStrengthTable
                                    data={data}
                                    setData={setData}
                                    setFinalData={setFinalData}
                                    finalData={finalData}
                                    result={result}
                                    overall={overall}
                                    setOverall={setOverall}
                                />
                            )}
                        </Grid>
                        {isView !== true && (
                            <Grid container justifyContent="end">
                                <Button
                                    sx={{ boxShadow: 'none', mt: 2 }}
                                    variant="contained"
                                    endIcon={<AddCircle />}
                                    onClick={handleOpen}
                                    size="small"
                                >
                                    Add
                                </Button>
                            </Grid>
                        )}
                    </JobAccordion>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default CoreCompressiveStrength
