export const TableHeaderData = {
    'AAC Block Density': {
        // id: 'Id',
        sampleId: 'Sample ID',
        len: 'Length',
        breadth: 'Breadth',
        height: 'Height',
        dryWeight: 'Dry Weight',
        volume: 'Volume',
        block_denisty: 'Block Density',
    },
    'Field Dry Density by Core Cutter Method Report': {
        location: 'Location',
        dryDensity: 'Dry Density, (Yd) g/cc',
        maximumDryDensity: ' Maximum Dry Density, MDD (g/cc)',
        compaction: '% of Compaction(%)',
    },
    'Field Dry Density by Sand Replacement Method Report': {
        location: 'Location',
        dry_density: 'Dry Density, (Yd) g/cc',
        maximumDryDensity: ' Maximum Dry Density, MDD (g/cc)',
        compaction: '% of Compaction(%)',
    },
    'Block Density': {
        sampleId: 'Sample ID',
        len: 'Length (mm)',
        breadth: 'Breadth (mm)',
        height: 'Height (mm)',
        dryWeight: 'Dry Weight (kgs)',
        volume: 'Volume (m³)',
        block_denisty: 'Block Density (kg/m³)',
    },
    'Block Compressive Strength': {
        //id: 'Id',
        sampleId: 'Sample ID',
        len: 'Length (mm)',
        breadth: 'Breadth (mm)',
        height: 'Height (mm)',
        weight: 'Weight (kg)',
        crossArea: 'Cross Sectional Area (mm²)',
        crushingLoad: 'Crushing Load (KN)',
        compStrength: 'Compressive Strength (N/mm²)',
    },
    'Block Compressive Strength Report': {
        thickness: 'Thickness',
        plainBlock: 'PlainBlock',
    },
    'Cube Compressive Strength': {
        cubeId: 'Cube ID',
        len: 'Length (mm)',
        breadth: 'Breadth (mm)',
        height: 'Height (mm)',
        area: 'Area (mm²)',
        weight: 'Weight (Kgs)',
        compressiveLoad: 'Compr.Load (kN)',
        compressiveStrength: 'Compressive Strength (N/sq.mm)',
        // averageCompressiveStrength: 'Average (N/mm²)',
    },
    'Block Water Absorption': {
        sampleId: 'Sample ID',
        len: 'Length , L (mm)',
        breadth: 'Breadth , B (mm)',
        height: 'Height, H (mm)',
        dryWeight: ' Dry weight (kg)',
        wetWeight: '  Wet Weight (Kg)',
        waterAbsorption: ' Water Absorption',
    },
    'Elongation Index': {
        totalWeight: 'Total weight (g) (X)',
        sieveSizePassing: 'Sieve Size Passing (mm)',
        wtRetainedOnISSieve: 'Wt. Retained on IS Sieve (g)',
        guagethickness: 'Guage thickness(mm)',
        weightOfAggregateRetainedOnGuage:
            'Wt. of aggregate retained on guage (g) (Y)',
        individualElongation: 'Individual Elongation (Y/X)*100',
        // totalElongation: 'Total Elongation',
    },
    'Flakiness Index': {
        totalWeight: 'Total weight (g) (X)',
        sieveSizePassing: 'Sieve Size Passing (mm)',
        wtRetainedOnISSieve: 'Wt. Retained on IS Sieve (g)',
        guagethickness: 'Guage thickness (mm)',
        weightOfAggregatePassingThroughGuage:
            'Weight of aggregate passing through guage (g) (Y)',
        individualFlakiness: 'Individual Flakiness (Y/X)*100',
        // totalFlakiness: 'Total flakiness',
    },
    'Bricks Compressive Strength': {
        sampleId: 'Sample ID',
        len: 'Length , L (mm)',
        breadth: 'Breadth , B (mm)',
        height: 'Height, H (mm)',
        weight: 'Weight, W (kg)',
        crossArea: 'Cross sectional area (mm²)',
        crushingLoad: 'Crushing load (kN)',
        strength: 'Compressive Strength (N/mm²)',
    },
    'Thermal Conductivity': {
        sampleId: 'Sample Id',
        len: 'Length , L (mm)',
        breadth: 'Breadth , B (mm)',
        height: 'Height, H (mm)',
        dry_weight: 'Dry Weight in Kgs',
        thermal_conductivity: 'Thermal conductivity in W/m.k',
        // average: 'Average in W/m.k',
    },
    'Dimension Test': {
        len: 'Length , L (mm)',
        breadth: 'Breadth , B (mm)',
        height: 'Height, H (mm)',
    },
    'Bricks Water Absorption': {
        sampleId: 'Sample ID',
        len: 'Length , L (mm)',
        breadth: 'Breadth , B (mm)',
        height: 'Height, H (mm)',
        dryWeight: 'Dry weight (kg) (W1)',
        wetWeight: 'Wet Weight (Kg)  (W2)',
        waterAbsorption: 'Water Absorption(%)  (100*(W2-W1)/ W1)',
    },
    Efflorescence: {
        sampleId: 'Sample ID',
        len: 'Length , L (mm)',
        breadth: 'Breadth , B (mm)',
        height: 'Height, H (mm)',
        efflorescence: 'Result',
        // efflorescence: 'Determination of Efflorescence',
    },
    'ACT Compressive Strength': {
        cubeId: 'Cube ID',
        dateOfCasting: 'D.O.C',
        dateOfTesting: 'D.O.T',
        age: 'Age (days)',
        time_speciments_placed:
            'Time at which the Speciments are Placed inside the Tank',
        timeOfTest: 'Time of Test',
        crossSectionalArea: 'Cross sectional area, A',
        weightCube: 'Weight of the Cube (kg)',
        crushingLoad: 'Crushing load Load, P (kN)',
        compressiveStrength: 'Compressive Strength (N/mm²)',
        compressiveStrengthAfterCorrection:
            'Compressive Strength After Correction (N/mm²)',
    },
    'Reinforcement Steel (Physical)': {
        diaAndGrade: 'Dia/Grade',
        weightPerMeter: 'Mass / Meter (Kg/m)',
        yieldStress: 'Yield Stress (N/mm²)',
        ultimateTensileStress: 'U T S (N/mm²)',
        percentageOfElongation: 'Elongation (%)',
        _10_perc_uts: 'TS / YS  Ratio',
        bend: 'Bend',
        reBend: 'ReBend',
        // requirement: 'Requirement',
        // yieldLoad: 'Yield Load, P  (kN)',
        // ultimateLoad: 'Ultimate Load, F (kN)',
        // crossSectionalArea: 'Cross Sectional Area',
        // originalGaugeLength: 'Original Gauge Length',
        // finalGaugeLength: 'Final Gauge Length, L2(mm)',
    },
    'Reinforcement Steel (Physical) Report': {
        diaAndGrade: 'Dia of Samples',
        weight: 'Weight, W(kg)',
        len: 'Length, L(m)',
        weightPerMeter: 'Weight Per Meter, (W/L)',
        yieldLoad: 'Yield Load, P(KN)',
        ultimateLoad: 'Ultimate Load, F(KN)',
        crossSectionalArea: 'Cross Sectional Area, A(W/ 0.00785) (mm²))',
        yieldStress: 'Yield Stress, (F/A) (N/mm²)',
        ultimateTensileStress: 'Ultimate Tensile Stress, (F/A) (N/mm²)',
        originalGaugeLength: 'Original Gauge Length, L1(mm)',
        finalGaugeLength: 'Final Gauge Length, L2(mm)',
        percentageOfElongation: '% of Elongation 100* (L2-L1) / L1',
    },
    'Reinforcement Steel (Chemical)': {
        // dia
        diaAndGrade: 'Dia/Grade',
        perc_carbon: '% Carbon',
        perc_sulphur: '% Sulphur',
        perc_phosphorus: '% Phosphorus',
        avg_sulphur_phosphorus: 'Sulphur + Phosphorus (%)',
    },
    Coupler: {
        dia: 'Dia (mm)',
        weightPerMeter: 'Mass / Meter (Kg/m)',
        yieldStress: 'Yield Stress, (N/mm²)',
        ultimateTensileStress: 'U T S (N/mm²)',
        percentageOfElongation: 'Elongation (%)',
        // _10_perc_uts: 'TS / YS  Ratio',
        remark: 'Remark',
        // reBend: 'ReBend',
    },
    'Coupler (Chemical)': {
        dia: 'Dia (mm)',
        perc_carbon: '% Carbon',
        perc_manganese: '% Manganese',
        perc_silicon: '% Silicon',
        perc_sulphur: '% Sulphur',
        perc_phosphorus: '% Phosphorus',
    },
    // 'Free Swell Index': {
    //     water_height: 'Water Height ',
    //     kerosene_height: 'Kerosene height ',
    //     free_swell: 'Free swell Index ',
    // },
    'Grain Size Analysis(0.075 to 4.75 mm)': {
        sieve_size: 'Sieve size',
        retained_weight: 'Retained Weight (gm)',
        cumulative_weight_retained: 'Cumulative Weight Retained(gm)',
        cumulative_passing_weight_retained: 'Cumulative % Weight Retained',
        passing_percentage: 'Passing, %',
    },
    // 'Liquid Limit': {
    //     blow_count: 'Blow count',
    //     wet_weight: 'Wet weight (g)',

    //     dry_weight: 'Dry weight (g)',
    //     moisture_content: 'Moisture content %',
    // },
    // 'Plastic Limit': {
    //     wet_weight: 'Wet Weight of soil crumble at 3mm dia (g)',
    //     weight_oven: 'Weight of oven dried soil crumble at 3mm dia (g)',
    //
    //     plastic_limit: 'Plastic limit %',
    // },
    // 'Soil Specific Gravity': {
    //     wt_pycnometer_bottle: 'Weight of Pycnometer bottle ',
    //     wt_pycnometer_filled: 'Weight of Pycnometer filled with aggregate ',
    //     wt_pycnometer_distilled:
    //         'Weight of Pycnometer with distilled water and aggregate ',
    //     wt_pycnometer_bottle_distilled:
    //         'Weight of Pycnometer bottle filled with distilled water',
    //     specific_gravity: 'Specific Gravity',
    // },
    'Bulk Density': {
        observation: 'Observation',
        result: 'Results',
    },
    Bulkage: {
        observation: 'Observation',
        result: 'Results',
    },
    'Silt content': {
        observation: 'Observation',
        result: 'Results',
    },
    'Clay lumps': {
        observation: 'Observation',
        result: 'Results',
    },
    'Specific Gravity': {
        observation: 'Observation',
        result: 'Results',
    },
    'Aggregate Water Absorption': {
        observation: 'Observation',
        result: 'Results',
    },
    'Crushing Value': {
        observation: 'Load',
        result: '40 Tonees',
    },
    'Impact Value': {
        observation: 'Observation',
        result: 'Results',
    },
    // 'Coarse Aggregate Sieve Analysis - 6mm': {
    //     is_sieve_designation: 'IS Sieve Designation',
    //     graded: 'Graded',
    //     single_size : 'Single Size',
    //     weight_retained: 'Weight Retained',
    //     Passing: 'Passing',
    //     // is_sieve_designation: 'IS Sieve Designation',
    //     // weight_retained: 'Weight Retained',
    //     // percentage_weight_retained: '% Weight Retained',
    //     // cumulative_weight_retained: 'Cumulative % Weight retained',
    //     // cumulative_weight_passing: 'Cumulative % Weight passing',
    // },
    // 'Coarse Aggregate Sieve Analysis - 12.5mm': {
    //     is_sieve_designation: 'IS Sieve Designation',
    //     graded: 'Graded',
    //     single_size : 'Single Size',
    //     weight_retained: 'Weight Retained',
    //     Passing: 'Passing',
    //     // percentage_weight_retained: '% Weight Retained',
    //     // cumulative_weight_retained: 'Cumulative % Weight retained',
    //     // cumulative_weight_passing: 'Cumulative % Weight passing',
    // },
    // 'Coarse Aggregate Sieve Analysis - 20mm': {
    //     is_sieve_designation: 'IS Sieve Designation',
    //     graded: 'Graded',
    //     single_size : 'Single Size',
    //     weight_retained: 'Weight Retained',
    //     Passing: 'Passing',
    //     // is_sieve_designation: 'IS Sieve Designation',
    //     // weight_retained: 'Weight Retained',
    //     // percentage_weight_retained: '% Weight Retained',
    //     // cumulative_weight_retained: 'Cumulative % Weight retained',
    //     // cumulative_weight_passing: 'Cumulative % Weight passing',
    // },
    // 'Coarse Aggregate Sieve Analysis - 40mm': {
    //     is_sieve_designation: 'IS Sieve Designation',
    //     graded: 'Graded',
    //     single_size : 'Single Size',
    //     weight_retained: 'Weight Retained',
    //     Passing: 'Passing',
    //     // is_sieve_designation: 'IS Sieve Designation',
    //     // weight_retained: 'Weight Retained',
    //     // percentage_weight_retained: '% Weight Retained',
    //     // cumulative_weight_retained: 'Cumulative % Weight retained',
    //     // cumulative_weight_passing: 'Cumulative % Weight passing',
    // },
    'Fine Aggregate Sieve Analysis': {
        // is_sieve_designation: 'IS Sieve Designation',
        // graded: 'Graded',
        // single_size : 'Single Size',
        weight_retained: 'Weight Retained',
        passing: 'Passing',
        // is_sieve_designation: 'IS Sieve Designation',
        // weight_retained: 'Weight Retained',
        // percentage_weight_retained: '% Weight Retained',
        // cumulative_weight_retained: 'Cumulative % Weight retained',
        // cumulative_weight_passing: 'Cumulative % Weight passing',
    },
    'Natural Moisture content': {
        wt_wet_soil: 'Weight of wet soil (g)',
        dry_wt_soil: 'Dry weight of soil sample (g)',
        natural_moisture: 'Natural Moisture content, % ',
    },
    '10% fine value': {
        observation: 'Observation',
        result: 'Results',
    },
    'Particle finer than 75 micron': {
        observation: 'Observation',
        result: 'Results',
    },
    'Soundness test': {
        observation: 'Observation',
        result: 'Results',
    },
    'Compressive Strength': {
        days: 'DAYS',
        weight: 'Weight (kg)',
        load: 'Load (kN)',
        comp_st: 'Comp.St (N/mm²)',
    },
    'Final Setting Time': {
        observation: 'Observation',
        result: 'Results',
    },
    'Fineness by Dry Sieving': {
        observation: 'Observation',
        result: 'Results',
    },
    'Moisture content': {
        observation: 'Observation',
        result: 'Results',
    },
    'Normal Consistency': {
        qty_water: 'Quantity of water for 5-7 mm penetration',
        weight_cement: 'Weight of cement',
        consistency: 'Consistency of cement in (%)',
    },
    'Soundness by Le Chatlier method': {
        observation: 'Observation',
        result: 'Results',
    },
    'Specific gravity by Le Chatlier flask': {
        observation: 'Observation',
        result: 'Results',
    },
    'Fineness by Dry Sieving %': {
        observation: 'Observation',
        result: 'Results',
    },
    'Initial Setting Time': {
        observation: 'Observation',
        result: 'Results',
    },
    Slump: {
        observation: 'Observation',
        result: 'Results',
    },
    'Core Compressive Strength': {
        cubeId: 'Cube ID',
        // dateOfCasting: 'D.O.C',
        // dateOfTesting: 'D.O.T',
        age: 'Age (days)',
        time_speciments_placed: 'Speciments Placed (Time)',
        timeOfTest: 'Time of Test',
        crossSectionalArea: 'Cross sectional area, A ',
        weightCube: 'Weight of the Cube (kg)',
        crushingLoad: 'Crushing load Load, P (kN)',

        compressiveStrengthAfterCorrection:
            'Compressive Strength After Correction (N/mm²)',
    },
    'Equivalent Cube Compressive Stregnth Results of Concrete Core': {
        sample_id: 'Sample ID',
        core_length: 'Core Length (L in mm)',
        core_dia: 'Core dia (d in mm)',
        CA: 'CA in mm²',
        core_cursh: 'Core Crush Load (KN)',
        core_weight: 'Core Weight (Kg)',
        core_comp_strength: 'Core Comp Strength (N/mm²)',
        id_ratio: 'I/d Ratio',
        correction_factor: 'Correction factor for (l/d) ratio +',
        corrected_cyl_comp: 'Corrected Cyl. Comp. Strength (N/mm²)',
        equivalent_cube_comp: 'Equivalent Cube Comp Strength (N/mm²)',
    },
    'Rebound Hammer': {
        ht_direction: 'direction',
        d_1: '1',
        d_2: '2',
        d_3: '3',
        d_4: '4',
        d_5: '5',
        d_6: '6',
        d_7: '7',
        d_8: '8',
        d_9: '9',
        d_10: '10',
        average: 'Average',
    },
    'Ultrasonic Pulse Velocity': {
        direction: 'Direction',
        _1: '1',
        _2: '2',
        _3: '3',
        _4: '4',
        _5: '5',
        _6: '6',
        _7: '7',
        _8: '8',
        _9: '9',
        _10: '10',
        average: 'Average',
    },
    'Free Swell Index': {
        water_height: 'Water Height',
        kerosene_height: 'Kerosene height ',
        free_swell: 'Free swell Index %',
    },
    'Grain Size Analysis (0.075 to 4.75 mm)': {
        sieve_size: 'Sieve size',
        retained_weight: 'Retained Weight (g)',
        passing_percentage: 'Passing, %',
    },
    // 'Heavy Compaction': {
    //    wt_mould_soil: 'Weight of mould + Compacted soil  g',
    //   wt_compact_soil:  'Weight of compacted soil  g',
    //    wet_density: 'Wet density  , g/cm3 ',
    //   dry_density:'Dry density , g/cm3 ',
    // },
    'Liquid Limit': {
        blow_count: 'Blow count',
        wet_weight: 'Wet weight (g)	',
        dry_weight: 'Dry weight  (g)	',
        moisture_content: 'Moisture content %',
    },
    'Natural Moisture Content': {
        wt_wet_soil: 'Weight of wet soil (g) ',
        dry_wt_soil: 'Weight of Dry soil (g)',
        natural_moisture: 'Natural Moisture content %',
    },
    'Plastic Limit': {
        observation: 'Observation',
        result: 'Results',
    },
    'Soil Specific Gravity': {
        observation: 'Observation',
        result: 'Results',
    },
    'Carbonation test': {
        id: 'Sample Id',
        lvl_carbonation: 'Level Of Carbonation',
        obs_result: 'Observed Results (Nil/Carbonated)',
    },

    'Half-Cell Potential difference test': {
        _1: '',
        _2: '',
        _3: '',
        _4: '',
        _5: '',
        _6: '',
        _7: '',
        _8: '',
        _9: '',
        _10: '',
        average: '',
    },
    // 'Drying Shrinkage': {
    //     sampleId: 'Sample Id',
    //     wet_len: 'Wet Length (mm)',
    //     wet_breadth: 'Wet Breadth (mm)',
    //     wet_height: 'Wet Height (mm)',
    //     l1: 'L1 (mm)',
    //     dry_len: 'Dry Length (mm)',
    //     dry_breadth: 'Dry Breadth (mm)',
    //     dry_height: 'Dry Height (mm)',
    //     l2: 'L2 (mm)',
    //     dryingShrinkage: 'Drying Shrinkage',
    // },
    'Drying Shrinkage': {
        sampleId: 'Sample Id',
        wet_len: 'Dimension in mm (Wet) - L1',
        dry_len: 'Dimension in mm (Dry) - L2',
        breadth: 'Breadth, B (mm)',
        height: 'Height, H (mm)',
        dryingShrinkage: 'Drying Shrinkage',
    },
    'Moisture Content': {
        sampleId: 'Sample Id',
        dry_length: 'Dry Length (mm)',
        dry_breadth: 'Dry Breadth (mm)',
        dry_height: 'Dry Height (mm)',
        l1: 'L1 (mm)',
        wet_length: 'Wet Length (mm)',
        wet_breadth: 'Wet Breadth (mm)',
        wet_height: 'Wet Height (mm)',
        l2: 'L2 (mm)',
        moisturecontent: 'Moisture Content',
    },
    'Moisture Movement': {
        sampleId: 'Sample Id',
        wet_len: 'Dimension in mm (Wet) - L1',
        dry_len: 'Dimension in mm (Dry) - L2',
        breadth: 'Breadth, B (mm)',
        height: 'Height, H (mm)',
        moistureMovement: 'Moisture Movement',
    },
    // 'Moisture Movement': {
    //     sampleId: 'Sample Id',
    //     dry_length: 'Dry Length (mm)',
    //     dry_breadth: 'Dry Breadth (mm)',
    //     dry_height: 'Dry Height (mm)',
    //     l1: 'L1 (mm)',
    //     wet_length: 'Wet Length (mm)',
    //     wet_breadth: 'Wet Breadth (mm)',
    //     wet_height: 'Wet Height (mm)',
    //     l2: 'L2 (mm)',
    //     moistureMovement: 'Moisture Movement',
    // },

    'Crack width measurement': {
        id: 'id',
        non_crack_velocity_1: '1',
        non_crack_velocity_2: '2',
        non_crack_velocity_3: '3',
        non_crack_velocity_average: 'Average',
        crack_velocity_1: '1',
        crack_velocity_2: '2',
        crack_velocity_3: '3',
        crack_velocity_4: '4',
        crack_velocity_5: '5',
        crack_velocity_average: 'Average',
    },
    'California Bearing Ratio': {
        penetration: 'Penetration in (mm)',
        proving_ring: 'Proving Ring Readings',
        action_load: 'Actual Load in kg',
    },

    'CBR Value(Calfornia Bearing Ratio) Report': {
        penetration: 'Penetration in (mm)',
        proving_ring: 'Proving Ring Readings',
        action_load: 'Actual Load in kg',
    },

    'Shrinkage limit': {
        // wt_container: 'Wt of container (g)',
        // wt_container_wet_soil: 'Wt of container + Wet soil, (g)',
        // wt_container_dry_soil: 'Wt of container + Dry soil, (g)',
        moisture_content: 'Moisture Content, (g)',
        // wt_shrinkage_dish: 'Wt of Shrinkage Dish, (g)',
        // wt_mercury_shrinkage_dish: 'Wt of Mercury + Shrinkage Dish, (g)',
        wt_mercury: 'Wt. of Mercury, (g)',
        initial_volume: 'Initial Vol. of Shrinkage Dish, (cm³)',
        wt_mercrury_plus_shrinkage_dish:
            'Wt. of Mercury Displaced by the Dry Cake + Wt. of Shrinkage Dish , (g)',
        vol_dry_cake: 'Vol. of dry cake, (cm³)',
        shrinkage_limit: 'Shrinkage Limit (%)',
    },
    'Construction Water': {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: ' Method of Ref',
        result: 'Results',
        req: 'Min - Limit as per Standard Ref',
        requirement_absence_source: 'Max - Limit as per Standard Ref',
    },
    'Water Neutralization': {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: ' Method of Ref',
        result: 'Results',
        req: 'Min - Limit as per Standard Ref',
        requirement_absence_source: 'Max - Limit as per Standard Ref',
    },
    'Concrete Cube By ACT Method': {
        sampleId: 'Sample Id',
        weight: 'Weight (Kgs)',
        compressiveLoad: 'Comp. Load (kN)',
        accelerated_comp: 'Accelerated Comp. Str.Ra (N / sq.mm)',
        estimated_comp: 'Estimated Comp. Str (N/mm²) R28 @ 28 days as per ACT',
    },
    'Concrete Cube By ACT Method Report': {
        sampleId: 'Cube Id',
        doc: 'D.O.C',
        dot: 'D.O.T',
        age: 'Age (days)',
        crossArea: 'Cross Sectional Area, A',
        wtOfCube: 'Wt.of Cube (kg)',
        crushingLoad: 'Crushing Load, P (KN)',
        compStrength: 'Compressive Strength (P/A) (N/mm²)',
        compressiveStrengthAfterCorrection:
            'Compressive Strength after Correction (N/mm²)',
    },

    // 'Sieve Analysis Result on GSB Sample': {
    //     is_sieve_designation: 'IS Sieve',
    //     grade_1: 'Grade I',
    //     grade_2: 'Grade II',
    //     grade_3: 'Grade III',
    //     grade_4: 'Grade IV',
    //     grade_5: 'Grade V',
    //     perc_retained: '% Retained',
    //     perc_passing: '% Passing',
    // },
    'GSB Sample': {
        tests: 'Name of Tests',

        unit: 'Units',
        req: 'Limits',
        result: 'Results',
    },
    // 'Sieve Analysis Result on WMM Sample': {
    //     is_sieve_designation: 'Sieve Size',

    //     req: 'Requirements - % by weight passing the IS Sieves',
    //     perc_retained: '% Retained',
    //     perc_passing: '% Passing',
    // },
    'WMM Sample': {
        tests: 'Name of Tests',

        unit: 'Units',
        req: 'Limits',

        result: 'Results',
    },
    'CBR Value(Calfornia Bearing Ratio)': {
        description: 'Description',
        result: 'Result',
        requirement: 'Requirements ',
    },
    Admixture: {
        tests: 'Test Conducted',

        result: 'Results',
        req: 'Requirements as per Table 2 of IS:9103-1999(RA:2013)',
    },
    'Physical Parameters': {
        tests: 'Test Parameter',
        unit: 'Units',
        standard_ref: 'Test Method',
        result: 'Results',
        req: 'Acceptable Limit',
        requirement_absence_source:
            'Permissible limit in the absence of alternative source',
    },
    'General Parameters Concerning Substances Undesirable in Excessive Amounts':
        {
            tests: 'Test Parameter',
            unit: 'Units',
            standard_ref: 'Test Method',
            result: 'Results',
            req: 'Acceptable Limit',
            requirement_absence_source:
                'Permissible limit in the absence of alternative source',
        },
    '*Heavy Metal Parameters': {
        tests: 'Test Parameter',
        unit: 'Units',
        standard_ref: 'Test Method',
        result: 'Results',
        req: 'Acceptable Limit',
        requirement_absence_source:
            'Permissible limit in the absence of alternative source',
    },
    '*Pesticides': {
        tests: 'Test Parameter',
        unit: 'Units',
        standard_ref: 'Test Method',
        result: 'Results',
        req: 'Acceptable Limit',
        requirement_absence_source:
            'Permissible limit in the absence of alternative source',
    },
    '*Radio Active Material': {
        tests: 'Test Parameter',
        unit: 'Units',
        standard_ref: 'Test Method',
        req: 'Activity  Determination Limit of the Instrument',
        result: 'Results',
        requirement_absence_source: 'Maximum Limits as per Standard Ref',
    },
    'Bacteriological Analysis Results': {
        tests: 'Test Parameter',
        unit: 'Units',
        standard_ref: 'Test Method',
        result: 'Results',
        req: 'Requirements as per Standard Ref',
    },
    'Cement (Chemical)': {
        tests: 'Test Conducted',
        unit: 'Units',

        result: 'Results',
        req: 'Requirements as per IS 269-2015 (Reaff 2020)',
    },
    'Swimming Pool Water': {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: 'Test Method',
        result: 'Result',
        req: 'Requirement Limits as per Standard Ref',
    },
    'Bacteriological Parameters': {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: 'Test Method',
        result: 'Result',
        req: 'Requirement Limits',
    },
    'Cooling Tower Water': {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: 'Test Method',
        result: 'Result',
        req: 'Requirement Limits as per Standard Ref',
    },
    'Specifications as per En 8 BS970 (%)': {
        tests: 'Parameter Name',
        min: 'Min',
        max: 'Max',
    },
    'Runner Pipe': {
        tests: 'Test Conducted',

        result: 'Test Result',
        req: 'Requirements as per Standard Ref',
    },
    'Drinking Water (Bore Water)': {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: 'Test Method',
        result: 'Result',
        req: 'Requirement Limits as per IS 10500:2012',
    },
    'Chemical Parameters': {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: 'Test Method',
        result: 'Result',
        req: 'Requirement',
    },
    'Abrasion value': {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: 'Test Method',
        result: 'Result',
        req: 'Requirement',
    },
    'STP Treated Water': {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: 'Method of Ref',
        result: 'Result',
        req: 'Limit as Per PCB',
    },
    'ETP Treated Water': {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: 'Method of Ref',
        result: 'Result',
        req: 'Limit as Per PCB',
    },
    'Organo chlorine Pesticides (OCPS)': {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: 'Method of Ref',
        result: 'Result',
        req: 'Limit as Per PCB',
    },
    'Organo Phosphorus Pesticides (OPPs)': {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: 'Method of Ref',
        result: 'Result',
        req: 'Limit as Per PCB',
    },
    'Synthetic Pyrethroids (SPs)': {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: 'Method of Ref',
        result: 'Result',
        req: 'Limit as Per PCB',
    },

    Herbicides: {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: 'Method of Ref',
        result: 'Result',
        req: 'Limit as Per PCB',
    },
    'ETP Effluent Water': {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: 'Method of Ref',
        result: 'Result',
        req: 'Limit as Per PCB',
    },
    'Radioactive Material': {
        tests: 'Test Parameter',
        unit: 'Units',
        standard_ref: 'Method of Ref',
        // req: 'Activity  Determination Limit of the Instrument',
        req: 'Limit as Per PCB',
        result: 'Results',
        requirement_absence_source:
            'Activity Determination Limit of the Instrument',
    },
    'Well Water': {
        tests: 'Test Parameter',
        unit: 'Units',
        standard_ref: 'Test Method',
        result: 'Results',
        req: 'Acceptable Limit',
        requirement_absence_source:
            'Permissible limit in the absence of alternative source',
    },
    'Flexural Strength': {
        sampleId: 'Sample Id',
        thickness: 'Thickness (D)',
        length: 'Length,L (mm)',
        breadth: 'Breadth,B (mm)',
        height: 'Height,H (mm)',
        ulti_load: 'Ultimate Load (kN),P',
        fluxural_strength: 'Fluxural Strength (N/mm²) Fb',
    },
    'Abrasion Resistance': {
        // sampleId: 'Sample Id',
        mass: 'Loss in Mass after 16 cycles (g)',
        length: 'Length,L (mm)',
        breadth: 'Breadth,B (mm)',
        height: 'Height,H (mm)',
        density_specimen: 'Density of the specimen (g/mm³)',
        abrasion_resistance: 'Abrasion Resistance (mm³ per 5000 mm²)',
    },
}
