import { Box, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'

const BulkDensityTable = (props) => {
    const { data } = props

    //formula
    // data.loose_bulk = valueCalc(((data.wt_measure_loosely_filled - data.wt_cylindrical_measure) / data.vol_cylinder) * 1000)
    // data.rodded_bulk = valueCalc(((data.wt_measure_rodded_compacted - data.wt_cylindrical_measure) / data.vol_cylinder) * 1000)

    let bulkDensity

    bulkDensity = [
        {
            observation: 'Weight of the Clylindrical  measure W1, kg',
            result: 'wt_cylindrical_measure',
        },
        {
            observation:
                'Wt of Cylindrical Measure with loosely filled sample, W2, kg',
            result: 'wt_measure_loosely_filled',
        },
        {
            observation:
                'Wt of Cylindrical Measure with Rodded Compacted sample, W3, kg',
            result: 'wt_measure_rodded_compacted',
        },
        {
            observation: 'Volume of Cylindrical measure, V, lit',
            result: 'vol_cylinder',
        },
        {
            observation: 'Loose Bulk Density, (W2-W1)/V  Kg/lit',
            result: 'loose_bulk',
        },
        {
            observation: 'Rodded Bulk Density, (W3-W1)/V  kg/lit',
            result: 'rodded_bulk',
        },
    ]

    const Column = [
        { text: 'Observation', dataField: 'observation' },
        { text: 'Result', dataField: 'result' },
    ]
    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        {/* <TableCell align="center">Actions</TableCell> */}
                        <TableCell align="center">SI.No</TableCell>
                        {Column.map((item, index) => (
                            <TableCell align="center" key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                {bulkDensity?.map((item, index) => (
                    <TableBody>
                        <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">
                                {item.observation}
                            </TableCell>
                            <TableCell align="center">
                                {data[item.result] ? data[item.result] : '0'}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ))}
            </StyledTable>
        </Box>
    )
}

export default BulkDensityTable
