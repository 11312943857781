import {
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'

const CrackWidthMeasurementTable = ({
    data,
    setData,
    setFinalData,
    finalData,
    result,
    setOverall,
}) => {
    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName) {
                item.data = _data
            }
        })
        setFinalData(_finalData)
    }

    const Column = [
        { text: '1', dataField: 'non_crack_velocity_1' },
        { text: '2', dataField: 'non_crack_velocity_2' },
        { text: '3', dataField: 'non_crack_velocity_3' },

        { text: 'Average', dataField: 'non_crack_velocity_average' },
        { text: '1', dataField: 'crack_velocity_1' },
        { text: '2', dataField: 'crack_velocity_2' },
        { text: '3', dataField: 'crack_velocity_3' },
        { text: '4', dataField: 'crack_velocity_4' },
        { text: '5', dataField: 'crack_velocity_5' },

        { text: 'Average', dataField: 'crack_velocity_average' },
    ]

    return (
        <StyledTable>
            <TableHead>
                <TableRow>
                    <TableCell sx={{ width: 80 }} align={'center'} rowSpan={2}>
                        Actions
                    </TableCell>

                    <TableCell align="center" rowSpan={2}>
                        SI.No
                    </TableCell>
                    <TableCell align="center" rowSpan={2} colSpan={4}>
                        Location / Sample Id
                    </TableCell>
                    <TableCell colSpan={8} align="center">
                        Non - Cracked Location Velocity
                    </TableCell>
                    <TableCell colSpan={12} align="center">
                        Cracked Location Velocity
                    </TableCell>
                </TableRow>
                <TableRow>
                    {Column.map((item, index) => (
                        <TableCell colSpan={2} align={'center'} key={index}>
                            {item.text}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data?.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell>
                            <Button onClick={(e) => handleDelete(index, e)}>
                                <Icon>delete</Icon>
                            </Button>
                        </TableCell>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center" colSpan={4}>
                            {item.id}
                        </TableCell>
                        {Column.map((subItem, idx) => (
                            <TableCell
                                align="center"
                                colSpan={2}
                                key={`row-${idx}-index`}
                            >
                                {item[subItem.dataField]}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </StyledTable>
    )
}

export default CrackWidthMeasurementTable
