import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Icon,
} from '@mui/material'
import { Transition } from 'app/views/jobs/StyledComponents/StyledComponents'
import React from 'react'

const PublishDialog = ({
    value,
    dialog,
    handlePublishClose,
    handlePublish,
    title,
}) => {
    return (
        <Dialog
            value={value}
            open={dialog}
            onClose={handlePublishClose}
            TransitionComponent={Transition}
        >
            <DialogContent>
                <DialogContentText>{title}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handlePublishClose}>
                    Cancel
                </Button>
                <Button
                    onClick={(e) => handlePublish(value)}
                    autoFocus
                    variant="contained"
                    endIcon={<Icon>publish</Icon>}
                >
                    Publish
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default PublishDialog
