import {
    Box,
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const CBRTable = (props) => {
    let {
        data,
        setData,
        setFinalData,
        finalData,
        result,
        setOverall,
        setAvgLoad25mm,
        setAvgLoad5_0mm,
        overall,
        avgLoad2_5mm,
        avgLoad5_0mm,
    } = props

    const handleDelete = (index, e, val) => {
        let _data = data.filter((v, i) => i !== index)
        _data = applyFormula(_data)
        setData(_data)
        let _finalData = finalData
        _finalData.forEach((item) => {
            let oldOverall = { ...overall }
            if (item.groupName === result.groupName) {
                if (_data.length !== 0) {
                    let val1 = _data
                        .filter((i) => i.penetration === '5.0')
                        .map((i) => i.action_load)
                    let val2 = _data
                        .filter((i) => i.penetration === '2.5')
                        .map((i) => i.action_load)
                    const sum1 = val1.reduce(
                        (acc, curr) => acc + parseFloat(curr),
                        0
                    )
                    const sum2 = val2.reduce(
                        (acc, curr) => acc + parseFloat(curr),
                        0
                    )
                    let avg1 = sum1 / val1.length
                    let avg2 = sum2 / val2.length
                    oldOverall['result_for_2_5'] = valueCalc(avg2)
                    oldOverall['result_for_5_0'] = valueCalc(avg1)
                    setAvgLoad5_0mm(oldOverall['result_for_5_0'])
                    setAvgLoad25mm(oldOverall['result_for_2_5'])
                } else {
                    // Handle the case where either val1 or val2 is empty
                    oldOverall['result_for_2_5'] = 0
                    oldOverall['result_for_5_0'] = 0
                    setAvgLoad5_0mm(oldOverall['result_for_5_0'])
                    setAvgLoad25mm(oldOverall['result_for_2_5'])
                }
            }
            if (item.groupName === result.groupName) {
                item.data = _data
                item.overall = {
                    ...oldOverall,
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
    }
    const applyFormula = (_data) => {
        _data.forEach((item) => {
            if (item.penetration === '2.5') {
                let getValue = valueCalc(item.proving_ring * 0.056 * 100)
                item.action_load = valueCalc((getValue / 1370) * 100)
            } else if (item.penetration === '5.0') {
                let getValue = valueCalc(item.proving_ring * 0.056 * 100)
                item.action_load = valueCalc((getValue / 2055) * 100)
            } else {
                item.action_load = valueCalc(item.proving_ring * 0.056 * 100)
            }
        })
        return _data
    }
    return (
        <>
            <Box width="100%">
                <StyledTable>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                {' '}
                                Penetration in (mm)
                            </TableCell>
                            <TableCell align="center">
                                Proving Ring Readings
                            </TableCell>
                            <TableCell align="center">
                                Actual Load in kg
                            </TableCell>
                            <TableCell sx={{ width: 80 }} align="center">
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {data.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell align="center">
                                    {item.penetration}
                                </TableCell>
                                <TableCell align="center">
                                    {item.proving_ring}
                                </TableCell>

                                <TableCell align="center">
                                    {/* {item.action_load = valueCalc((item.proving_ring * 0.056) * 100)} */}
                                    {item.action_load}
                                </TableCell>
                                <TableCell align="center">
                                    <Button
                                        onClick={(e) =>
                                            handleDelete(index, e, item)
                                        }
                                    >
                                        <Icon>delete</Icon>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </StyledTable>
            </Box>

            <Box sx={{ background: 'whitesmoke', mt: 4 }}>
                <StyledTable>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                {' '}
                                Penetration Depth (mm)
                            </TableCell>
                            <TableCell align="center">
                                Unit Standard load kg/cm2
                            </TableCell>
                            <TableCell align="center">
                                Total Standard Load kg
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {tableData.map((item, index) => (
                            <TableRow key={index} disabled={true}>
                                <TableCell align="center">
                                    {item.depthValue}
                                </TableCell>
                                <TableCell align="center">
                                    {item.unitLoad}
                                </TableCell>

                                <TableCell align="center">
                                    {item.totalLoad}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </StyledTable>
            </Box>
        </>
    )
}

export default CBRTable
const tableData = [
    { depthValue: '2.5', unitLoad: '70', totalLoad: '1370' },
    { depthValue: '5.0', unitLoad: '105', totalLoad: '2055' },
]
