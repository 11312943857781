import React, { useState } from 'react'
import {
    Box,
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Autocomplete,
    TextField,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Modal,
} from '@mui/material'
import {
    StyledTable,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import MenuItem from '@mui/material/MenuItem'
import CommonField from '../../CommonField/CommonField'
const WaterTable = ({
    data,
    setData,
    setFinalData,
    finalData,
    result,
    setOverall,
    overall,
    materialName,
    setRequirement,
    requirement,
    selection,
    setSelection,
    dropDownField,
    setDropDownField,
    error,
    setError,
    applyFormula,
    editedData,
    setEditedData,
    editIndex,
    setEditIndex,
    formulaErrorMessage,
    setFormulaErrorMessage,
    formulaData,
}) => {
    const [isEditing, setIsEditing] = useState(false)
    const [open, setOpen] = useState(false)
    // const [editIndex, setEditIndex] = useState(null)
    // const [editedData, setEditedData] = useState({})
    const [testMethodValues, setTestMethodValues] = useState([])
    const [selectedValue, setSelectedValue] = useState(null)
    const handleChange = (event, newValue) => {
        setSelectedValue(newValue)
    }

    const a1 = editedData?.testParameter
    const dynamicValues = result?.header?.fields
        ?.find((field) => field.field === 'testParameter')
        ?.fieldData?.flatMap((dataItem) =>
            dataItem?.testParameter === a1 ? dataItem?.test_method || [] : []
        )
    if (
        dynamicValues.length !== testMethodValues.length ||
        dynamicValues.some((value, index) => value !== testMethodValues[index])
    ) {
        setTestMethodValues(dynamicValues)
    }

    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && item.data.length > 0) {
                item.data = _data
                if (item.data.length > 0) {
                    _data.forEach((item1) => {})
                }
            }
            if (item.groupName === result.groupName) {
                item.data = _data
            }
        })
        setFinalData(_finalData)
    }

    const handleEdit = (index) => {
        setEditIndex(index)
        setEditedData({ ...data[index] })
        setOpen(true)
        setIsEditing(true)
        setError(false)
    }
    const handleUpdate = () => {
        let hasError = false
        const list = result?.header?.fields
        const valueArray1 = []
        list.forEach(function (item) {
            if (item.testType_id !== '' && item.testType_id === editedData.id)
                valueArray1.push(item.field)
        })
        for (let i = 0; i < valueArray1.length; i++) {
            const s = valueArray1[i]
            if (
                s !== 'unit' &&
                s !== 'limit_field_1' &&
                s !== 'limit_field_2' &&
                s !== 'limit_field_main'
            ) {
                if (
                    !requirement.hasOwnProperty(s) &&
                    !editedData.hasOwnProperty(s)
                ) {
                    hasError = true
                    break
                }
            }
        }
        if (hasError || !editedData.result) {
            setError(true)
        } else {
            let _data = [...data]
            _data[editIndex] = editedData
            setData(_data)

            let _finalData = finalData.map((item) => {
                if (
                    item.groupName === result.groupName &&
                    item.data.length > 0
                ) {
                    item.data = _data
                }
                return item
            })
            setFinalData(_finalData)

            setEditIndex(null)
            setEditedData({})
            setOpen(false)
            setRequirement({})
            setError(false)
        }
    }

    const handleCancelEdit = () => {
        setEditIndex(null)
        setEditedData({})
        setRequirement({})
    }

    const handleFieldChange = (field, value) => {
        if (field === result?.header?.fields[0]?.field) {
            return
        }

        setEditedData((prevData) => ({ ...prevData, [field]: value }))
    }
    const selectStyle = {}

    const textFieldStyle = {
        width: '140px',
    }
    const testType_id = result?.header?.fields?.[1]?.resultFormula
        ?.map((item) => item.testType_id)
        .join(',')
    const formulOrder1 = result?.header?.fields?.[0]?.fieldData
        .map((item) => item.id)
        .join(',')
    const testTypeIds = testType_id.split(',')
    const sortedData = data.sort((a, b) => {
        const isAFormula = testTypeIds.includes(a.id)
        const isBFormula = testTypeIds.includes(b.id)

        if (isAFormula && !isBFormula) {
            return 1
        } else if (!isAFormula && isBFormula) {
            return -1
        } else {
            return 0
        }
    })
    const renderField = (item, idx) => {
        return (
            <CommonField
                key={idx}
                type={item.type}
                field={item.field}
                label={item.label}
                fieldData={item.fieldData}
                setRequirement={setRequirement}
                requirement={requirement}
                selection={selection}
                setSelection={setSelection}
                dropDownField={dropDownField}
                setDropDownField={setDropDownField}
                error={error}
                result={result}
                testType_id={item.testType_id}
                data={data}
                applyFormula={applyFormula}
                editedData={editedData}
                setEditedData={setEditedData}
                disabled={item.field === 'testParameter'}
                setIsEditing={setIsEditing}
                isEditing={isEditing}
                formulaErrorMessage={formulaErrorMessage}
                setFormulaErrorMessage={setFormulaErrorMessage}
                formulaData={formulaData}
                materialName={materialName}
            />
        )
    }

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        {result?.header?.fields?.map((item, index) =>
                            !item.testType_id ? (
                                <TableCell align={'center'} key={index}>
                                    {item.label}
                                </TableCell>
                            ) : null
                        )}
                        <TableCell sx={{ width: 80 }} align={'center'}>
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {/* {data?.map((item, index) => ( */}
                    {sortedData.map((item, index) => (
                        <TableRow key={index}>
                            {result?.header?.fields?.map((subItem, idx) =>
                                !subItem.testType_id ? (
                                    <TableCell
                                        align="center"
                                        key={`row-${idx}-index`}
                                    >
                                        {subItem.field === 'testParameter' ? (
                                            item[subItem.field]?.length > 0 ? (
                                                item[subItem.field]
                                            ) : (
                                                '-'
                                            )
                                        ) : subItem.field === 'test_method' ? (
                                            editIndex === index ? (
                                                <Autocomplete
                                                    value={
                                                        editedData[
                                                            subItem.field
                                                        ] || ''
                                                    }
                                                    onChange={(e, value) =>
                                                        handleFieldChange(
                                                            subItem.field,
                                                            value || ''
                                                        )
                                                    }
                                                    filterOptions={(
                                                        options,
                                                        params
                                                    ) => {
                                                        const filtered =
                                                            options.filter(
                                                                (option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(
                                                                            params.inputValue.toLowerCase()
                                                                        )
                                                            )

                                                        if (
                                                            params.inputValue !==
                                                            ''
                                                        ) {
                                                            filtered.push(
                                                                params.inputValue
                                                            )
                                                        }

                                                        return filtered
                                                    }}
                                                    fullWidth
                                                    selectOnFocus
                                                    clearOnBlur
                                                    handleHomeEndKeys
                                                    id="free-solo-with-text-demo"
                                                    options={
                                                        // editedData[
                                                        //     subItem.field +
                                                        //         '_options'
                                                        // ] || []
                                                        (
                                                            editedData[
                                                                subItem.field +
                                                                    '_options'
                                                            ] || []
                                                        ).filter(
                                                            (option) =>
                                                                option !== '-'
                                                        )
                                                    }
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <li {...props}>
                                                            {option}
                                                        </li>
                                                    )}
                                                    getOptionLabel={(option) =>
                                                        option
                                                    }
                                                    freeSolo
                                                    renderInput={(params) => (
                                                        <TextField
                                                            sx={{
                                                                marginLeft:
                                                                    '4px',
                                                            }}
                                                            {...params}
                                                            label={
                                                                subItem.label ||
                                                                ''
                                                            }
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            ) : item[subItem.field]?.length >
                                              0 ? (
                                                item[subItem.field]
                                            ) : (
                                                '-'
                                            )
                                        ) : editIndex === index &&
                                          !subItem.testType_id ? (
                                            <TextField
                                                sx={{ marginLeft: '8px' }}
                                                value={
                                                    editedData[subItem.field] ||
                                                    ''
                                                }
                                                onChange={(e) =>
                                                    handleFieldChange(
                                                        subItem.field,
                                                        e.target.value
                                                    )
                                                }
                                                error={
                                                    error &&
                                                    !editedData[
                                                        subItem.field
                                                    ] &&
                                                    subItem.field === 'result'
                                                }
                                                helperText={
                                                    error &&
                                                    !editedData[
                                                        subItem.field
                                                    ] &&
                                                    subItem.field === 'result'
                                                        ? ''
                                                        : ''
                                                }
                                                // disabled={subItem.resultField} // Disable the TextField if resultField is true
                                            />
                                        ) : item[subItem.field]?.length > 0 ? (
                                            item[subItem.field]
                                        ) : (
                                            ' '
                                        )}
                                    </TableCell>
                                ) : null
                            )}

                            <TableCell align="center">
                                {editIndex === index ? (
                                    // Edit mode
                                    !testType_id.includes(editedData.id) ? (
                                        // If editedData.id is in testType_id array
                                        <>
                                            <Button onClick={handleUpdate}>
                                                <Icon>done</Icon>
                                            </Button>
                                            <Button onClick={handleCancelEdit}>
                                                <Icon>cancel</Icon>
                                            </Button>
                                        </>
                                    ) : (
                                        // If editedData.id is not in testType_id array
                                        <Modal
                                            open={open}
                                            // onClose={handleClose}
                                            onClose={handleCancelEdit}
                                        >
                                            <StyledCard>
                                                <CardHeader
                                                    title={materialName}
                                                />
                                                <CardContent>
                                                    <Grid container spacing={4}>
                                                        {result?.header?.fields
                                                            ?.filter(
                                                                (item) =>
                                                                    !item.resultField &&
                                                                    !item.testType_id
                                                            )
                                                            .map((item, idx) =>
                                                                renderField(
                                                                    item,
                                                                    idx
                                                                )
                                                            )}
                                                        {result?.header?.fields
                                                            ?.filter(
                                                                (item) =>
                                                                    !item.resultField &&
                                                                    // requirement.testParameter &&
                                                                    item.testType_id
                                                                // ===
                                                                //     requirement.id
                                                            )
                                                            .map((item, idx) =>
                                                                renderField(
                                                                    item,
                                                                    idx
                                                                )
                                                            )}
                                                        {result?.header?.fields
                                                            ?.filter(
                                                                (item) =>
                                                                    item.resultField
                                                            )
                                                            .map((item, idx) =>
                                                                renderField(
                                                                    item,
                                                                    idx
                                                                )
                                                            )}
                                                    </Grid>
                                                </CardContent>
                                                <CardActions>
                                                    <Button
                                                        size="small"
                                                        onClick={
                                                            handleCancelEdit
                                                        }
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        onClick={handleUpdate}
                                                    >
                                                        Save
                                                    </Button>
                                                </CardActions>
                                            </StyledCard>
                                        </Modal>
                                    )
                                ) : (
                                    // Not in edit mode
                                    <>
                                        <Button
                                            onClick={() => handleDelete(index)}
                                        >
                                            <Icon>delete</Icon>
                                        </Button>
                                        <Button
                                            onClick={() => handleEdit(index)}
                                        >
                                            <Icon>edit</Icon>
                                        </Button>
                                    </>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default WaterTable
