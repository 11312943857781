import React from 'react'
// import useSettings from 'app/hooks/useSettings'
import logo from '../MatxLogo/logoShadow.png'

const FalconLogo = ({ className }) => {
    // const { settings } = useSettings()
    // const theme = settings.themes[settings.activeTheme]

    return <img width={'45px'} height={'45px'} src={logo} alt="Falcon" />
}

export default FalconLogo
