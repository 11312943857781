export const DASHBOARD = {
    SET_DOUGHNET_DATA: 'SET_DOUGHNET_DATA',
    SET_BARGRAPH: 'SET_BARGRAPH',
    SET_DATE_BARGRAPH: 'SET_DATE_BARGRAPH',
    SET_DOUGHNUT_GRAPH: 'SET_DOUGHNUT_GRAPH',
    SET_DASHBOARD_STATS: 'SET_DASHBOARD_STATS',
    SET_DASHBOARD_DUESTATS: 'SET_DASHBOARD_DUESTATS',
    SET_RECENT_TESTS: 'SET_RECENT_TESTS',
    SET_REVIEWER_DATA: 'SET_REVIEWER_DATA',
    SET_ASSIGNEE_DATA: 'SET_ASSIGNEE_DATA',
    SET_DISCIPLINE_DATA: 'SET_DISCIPLINE_DATA',
    SET_GROUP_DATA: 'SET_GROUP_DATA',
    SET_CUSTOMER_DATA: 'SET_CUSTOMER_DATA',
    SET_TOTALPAGE: 'SET_TOTALPAGE',
    SET_SAMPLE_DATA: 'SET_SAMPLE_DATA',
    SET_ULR_NO: 'SET_ULR_NO',
    SET_LINE_GRAPH: 'SET_LINE_GRAPH',
    SET_CUSTOMER_DATE_BARGRAPH: 'SET_CUSTOMER_DATE_BARGRAPH',
}
export const JOB = {
    SET_JOB_TABLE_DATA: 'SET_JOB_TABLE_DATA',
}
export const MATERIAL = {
    SET_MATERIAL_DATA: 'SET_MATERIAL_DATA',
}
export const TEST = {
    SET_REVIEWER_DATA: 'SET_REVIEWER_DATA',
    SET_ASSIGNEE_DATA: 'SET_ASSIGNEE_DATA',
    SET_DISCIPLINE_DATA: 'SET_DISCIPLINE_DATA',
    SET_GROUP_DATA: 'SET_GROUP_DATA',
    SET_CUSTOMER_DATA: 'SET_CUSTOMER_DATA',
    SET_TOTALPAGE: 'SET_TOTALPAGE',
    SET_SAMPLE_DATA: 'SET_SAMPLE_DATA',
    SET_ULR_NO: 'SET_ULR_NO',
    SET_PROJECTS: 'SET_PROJECTS',
}

export const USERS = {
    SET_USERS_DATA: 'SET_USERs_DATA',
}

export const NOTIFICATION = {
    SET_TEST_NOTIFICATION_DATA: 'SET_TEST_NOTIFICATION_DATA',
    SET_JOB_NOTIFICATION_DATA: 'SET_JOB_NOTIFICATION_DATA',
    SET_REMOVED_NOTIFICATION: 'SET_REMOVED_NOTIFICATION',
    SET_REMOVED_NOTIFICATION_JOB: 'SET_REMOVED_NOTIFICATION_JOB',
}
