import { Grid } from '@mui/material'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'
import { Fragment } from 'react'
import CrushingValueModal from './CrushingValueModal'

const CrushingValue = (props) => {
    const {
        result,
        isView,
        overall,
        setOverall,
        handleCustomRequirementChange,
        handleRequirementChange,
    } = props

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <JobAccordion
                        title={`Aggregate ${result?.groupName}`}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        requirementvalue={overall?.requirements}
                        remarksValue={overall?.remarks}
                        overall={overall}
                    >
                        <Grid item xs={12}>
                            <CrushingValueModal
                                result={result}
                                isView={isView}
                                overall={overall}
                                setOverall={setOverall}
                            />
                        </Grid>
                    </JobAccordion>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default CrushingValue
