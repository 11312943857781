import React from 'react'

import styled from '@emotion/styled'
import {
    Button,
    Icon,
    // Icon,
    // IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material'
import { Box } from '@mui/system'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const SoilFreeSwellIndexTable = ({
    data,
    setData,
    setFinalData,
    finalData,
    result,
    overall,
    setOverall,
}) => {
    {
        data.map((values) => [
            {
                water_height: values.water_height,
                kerosene_height: values.kerosene_height,
                free_swell: values.free_swell,
            },
        ])
    }

    const Column = [
        { text: 'Water Height (h1)', dataField: 'water_height' },
        { text: 'Kerosene height (h2)', dataField: 'kerosene_height' },
        { text: 'Free swell Index 100*(h1-h2)/h2', dataField: 'free_swell' },
    ]
    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        let avg = 0

        _finalData.forEach((item) => {
            if (item?.groupName === result?.groupName && _data.length !== 0) {
                _data?.forEach((item1) => {
                    let total =
                        (100 * (item1.water_height - item1.kerosene_height)) /
                        item1.kerosene_height
                        item1.free_swell = isNaN(total)?'0':total
                    avg += Number(item1.free_swell / _data.length)
                })
            }
            if (item.groupName === result.groupName) {
                item.data = _data
                item.overall = {
                    ...overall,
                    average: valueCalc(avg),
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
    }

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">SI.No</TableCell>
                        {Column.map((item, index) => (
                            <TableCell align="center" key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">
                                {item.water_height}
                            </TableCell>
                            <TableCell align="center">
                                {item.kerosene_height}
                            </TableCell>
                            <TableCell align="center">
                                {valueCalc(
                                    item.free_swell
                                    //  =
                                    //     (100 *
                                    //         (item.water_height -
                                    //             item.kerosene_height)) /
                                    //     item.kerosene_height)
                                )}
                            </TableCell>
                            <TableCell align="center">
                                <Button onClick={(e) => handleDelete(index, e)}>
                                    <Icon>delete</Icon>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                    {/* <TableRow>
                        <TableCell align="right" colSpan={2}>
                            Plasticity Index (Liquid limit-Plastic limit)
                        </TableCell>
                        <TableCell align="center"></TableCell>
                    </TableRow> */}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default SoilFreeSwellIndexTable
