import styled from '@emotion/styled'
import { AddCircle } from '@mui/icons-material'
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Modal,
    TextField,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import { useContext, useState } from 'react'
import * as Yup from 'yup'
import { valueCalc } from '../../../method/method'
import CompressiveStrengthTable from '../CompressiVeStrengthTable'

const initialValues = {
    sampleId: '',
    len: '',
    breadth: '',
    height: '',
    weight: '',
    crossArea: '',
    crushingLoad: '',
    strength: '',
}

const validationSchema = Yup.object().shape({
    len: Yup.number().required('Required'),

    breadth: Yup.number().required('Required'),
    height: Yup.number().required('Required'),
    weight: Yup.number().required('Required'),
    crushingLoad: Yup.number().required('Required'),
})

const CompressiveStrengthModal = ({ result, isView, overall, setOverall }) => {
    const handleReset = () => {
        setOpen(false)
    }

    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)

    const [data, setData] = useState(result?.data ? result?.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const { finalData, setFinalData } = useContext(AppContext)
    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        let _finalData = finalData
        let avg = 0
        _finalData.forEach((item) => {
            if (item?.groupName === result?.groupName && arr.length !== 0) {
                arr?.forEach((item1) => {
                    item1.crossArea = valueCalc(item1?.len * item1?.breadth)
                        ? valueCalc(item1?.len * item1?.breadth)
                        : '0'
                    item1.strength = valueCalc(
                        (item1?.crushingLoad * 1000) / item1?.crossArea
                    )
                        ? valueCalc(
                              (item1?.crushingLoad * 1000) / item1?.crossArea
                          )
                        : '0'
                    // let crossArea = (item1?.len * item1?.breadth) * 1000
                    // let compStrength = (item1?.crushingLoad / crossArea) * 1000
                    avg += Number(item1?.strength / arr.length)
                })
            }
            if (item.groupName === result.groupName) {
                item.data = arr
                item.overall = {
                    ...overall,
                    average: valueCalc(avg),
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
    }

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                    } = props
                    return (
                        <Form>
                            <Modal open={open} onClose={handleReset}>
                                <StyledCard>
                                    <CardHeader title=" Brick Compressive Strength" />
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Length , L (mm)"
                                                    value={values.len}
                                                    name="len"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.len &&
                                                        touched.len
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.len && touched.len
                                                        ? errors.len
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Breadth , B (mm)"
                                                    value={values.breadth}
                                                    name="breadth"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.breadth &&
                                                        touched.breadth
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.breadth &&
                                                    touched.breadth
                                                        ? errors.breadth
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Height, H (mm)"
                                                    value={values.height}
                                                    name="height"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.height &&
                                                        touched.height
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.height &&
                                                    touched.height
                                                        ? errors.height
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Weight, W (kg)"
                                                    value={values.weight}
                                                    name="weight"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.weight &&
                                                        touched.weight
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.weight &&
                                                    touched.weight
                                                        ? errors.weight
                                                        : ''}
                                                </HelperText>
                                            </Grid>

                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Crushing load, P (kN)"
                                                    value={values.crushingLoad}
                                                    name="crushingLoad"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.crushingLoad &&
                                                        touched.crushingLoad
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.crushingLoad &&
                                                    touched.crushingLoad
                                                        ? errors.crushingLoad
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            onClick={handleReset}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </StyledCard>
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
            {data && (
                <CompressiveStrengthTable
                    data={data}
                    setData={setData}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    result={result}
                    overall={overall}
                    setOverall={setOverall}
                />
            )}
            {isView !== true && (
                <Grid container justifyContent="end">
                    <Button
                        sx={{ boxShadow: 'none', mt: 2 }}
                        variant="contained"
                        endIcon={<AddCircle />}
                        onClick={handleOpen}
                        size="small"
                    >
                        Add
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default CompressiveStrengthModal
