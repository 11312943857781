import { Grid } from '@mui/material'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'

import { Fragment } from 'react'

import BlockDensityModal from './modal/BlockDensityModal'
import CompressiveStrengthModal from './modal/CompressiveStrengthModal'
import WaterAbsorptionModal from './modal/WaterAbsorptionModal'
import ThermalConductivityModal from './modal/ThermalConductivityModal'

function JOBCARD007G({
    result,
    isView,
    overall,
    setOverall,
    materialName,
    handleCustomRequirementChange,
    handleRequirementChange,
}) {
    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {(result.groupName === 'Block Compressive Strength' ||
                        result.groupName === 'Compressive Strength') && (
                        //  &&
                        //     materialName ===
                        //         'Autoclaved Aerated Concrete Blocks (AAC Blocks)'
                        <JobAccordion
                            title={result?.groupName}
                            requirementvalue={overall?.requirements}
                            remarksValue={overall?.remarks}
                            handleCustomRequirementChange={
                                handleCustomRequirementChange
                            }
                            handleRequirementChange={handleRequirementChange}
                            overall={overall}
                            avgLabel={`Average of ${result?.groupName}`}
                            averagevalue={overall?.average}
                        >
                            <Grid item xs={12}>
                                <CompressiveStrengthModal
                                    result={result}
                                    isView={isView}
                                    overall={overall}
                                    setOverall={setOverall}
                                />
                            </Grid>
                        </JobAccordion>
                    )}
                    {/* ............................. */}
                    {(result.groupName === 'Block Water Absorption' ||
                        result.groupName === 'Water Absorption') && (
                        <JobAccordion
                            title={result?.groupName}
                            requirementvalue={overall?.requirements}
                            remarksValue={overall?.remarks}
                            handleCustomRequirementChange={
                                handleCustomRequirementChange
                            }
                            handleRequirementChange={handleRequirementChange}
                            overall={overall}
                            avgLabel={`Average of ${result?.groupName}`}
                            averagevalue={overall?.average}
                        >
                            <Grid item xs={12}>
                                <WaterAbsorptionModal
                                    result={result}
                                    isView={isView}
                                    overall={overall}
                                    setOverall={setOverall}
                                />
                            </Grid>
                        </JobAccordion>
                    )}
                    {/* .................................... */}
                    {(result.groupName === 'AAC Block Density' ||
                        result.groupName === 'Block Density') && (
                        <JobAccordion
                            title={result?.groupName}
                            requirementvalue={overall?.requirements}
                            remarksValue={overall?.remarks}
                            handleCustomRequirementChange={
                                handleCustomRequirementChange
                            }
                            handleRequirementChange={handleRequirementChange}
                            overall={overall}
                            avgLabel="Average of Block Density"
                            averagevalue={overall?.average}
                        >
                            <BlockDensityModal
                                result={result}
                                isView={isView}
                                overall={overall}
                                setOverall={setOverall}
                            />
                        </JobAccordion>
                    )}

                    {result.groupName === 'Thermal Conductivity' && (
                        <JobAccordion
                            title={result?.groupName}
                            requirementvalue={overall?.requirements}
                            remarksValue={overall?.remarks}
                            handleCustomRequirementChange={
                                handleCustomRequirementChange
                            }
                            handleRequirementChange={handleRequirementChange}
                            overall={overall}
                            // avgLabel={`Average of ${result?.groupName}`}
                            avgLabel={'Average in W/m.k'}
                            averagevalue={overall?.average}
                        >
                            <Grid item xs={12}>
                                <ThermalConductivityModal
                                    result={result}
                                    isView={isView}
                                    overall={overall}
                                    setOverall={setOverall}
                                />
                            </Grid>
                        </JobAccordion>
                    )}
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default JOBCARD007G
