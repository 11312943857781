import { DASHBOARD } from '../actions/Actions'

const initialState = {
    doughnutData: {
        completed: 0,
        todo: 0,
        progress: 0,
        approved: 0,
        paid: 0,
    },
    // barGraph: {
    //     todo_status: 0,
    //     progress_status: 0,
    //     complete_status: 0,
    //     materialName: "",
    //     material: "",
    // },
    //barGraph: [],
    dashboardStats: {
        pendingTest: 0,
        ongoingTest: 0,
        completeTest: 0,
        pendingJob: 0,
        ongoingJob: 0,
        completeJob: 0,
        paidJob: 0,
        samplerequest: 0,
    },
    dashboardDueStats: {
        overdueJobs: 0,
        jobsDueOnDate: 0,
        jobsDueThisWeek: 0,
    },
    recentTests: [],
}

function DashboardAction(state = initialState, action) {
    switch (action.type) {
        case DASHBOARD.SET_DOUGHNET_DATA: {
            return {
                ...state,
                doughnutData: action.payload,
            }
        }

        case DASHBOARD.SET_BARGRAPH: {
            return {
                ...state,
                barGraph: action.payload,
            }
        }
        case DASHBOARD.SET_DATE_BARGRAPH: {
            return {
                ...state,
                dateBarGraph: action.payload,
            }
        }

        case DASHBOARD.SET_CUSTOMER_DATE_BARGRAPH: {
            return {
                ...state,
                customerDateBarGraph: action.payload,
            }
        }

        case DASHBOARD.SET_DOUGHNUT_GRAPH: {
            return {
                ...state,
                doughnutGraph: action.payload,
            }
        }

        case DASHBOARD.SET_DASHBOARD_STATS: {
            return {
                ...state,
                dashboardStats: action.payload,
            }
        }
        case DASHBOARD.SET_DASHBOARD_DUESTATS: {
            return {
                ...state,
                dashboardDueStats: action.payload,
            }
        }
        case DASHBOARD.SET_LINE_GRAPH: {
            return {
                ...state,
                dashboardlLineGraph: action.payload,
            }
        }

        case DASHBOARD.SET_RECENT_TESTS: {
            return {
                ...state,
                recentTests: action.payload,
            }
        }

        default: {
            return state
        }
    }
}

export default DashboardAction
