import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import { Fragment, useContext, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { valueCalc } from '../../method/method'
import CBRTable from './CBRTable'
import { CBRPenetration } from 'app/assets/Constant/constant'

const initialValues = {
    penetration: '',
    proving_ring: '',
    action_load: '',
}

const validationSchema = Yup.object().shape({
    penetration: Yup.string().required('Required'),
    proving_ring: Yup.number().required('Required'),
})

const CBR = (props) => {
    const {
        result,
        isView,
        overall,
        setOverall,
        handleCustomRequirementChange,
        handleRequirementChange,
    } = props
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [dia, setDia] = useState(initialValues.dia)
    const [avgLoad2_5mm, setAvgLoad25mm] = useState(
        result?.overall?.result_for_2_5 ?? ''
    )
    const [avgLoad5_0mm, setAvgLoad5_0mm] = useState(
        result?.overall?.result_for_5_0 ?? ''
    )
    const { finalData, setFinalData } = useContext(AppContext)

    const [data, setData] = useState(result.data ? result.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })

    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        arr = applyFormula(arr)
        let _finalData = finalData
        _finalData.forEach((item) => {
            let oldOverall = { ...overall }
            if (item.groupName === result.groupName) {
                if (arr.length !== 0) {
                    let val1 = arr
                        .filter((i) => i.penetration === '5.0')
                        .map((i) => i.action_load)
                    let val2 = arr
                        .filter((i) => i.penetration === '2.5')
                        .map((i) => i.action_load)
                    const sum1 = val1.reduce(
                        (acc, curr) => acc + parseFloat(curr),
                        0
                    )
                    const sum2 = val2.reduce(
                        (acc, curr) => acc + parseFloat(curr),
                        0
                    )
                    let avg1 = sum1 / val1.length
                    let avg2 = sum2 / val2.length
                    oldOverall['result_for_2_5'] = valueCalc(avg2)
                    oldOverall['result_for_5_0'] = valueCalc(avg1)
                    setAvgLoad5_0mm(oldOverall['result_for_5_0'])
                    setAvgLoad25mm(oldOverall['result_for_2_5'])
                } else {
                    // Handle the case where either val1 or val2 is empty
                    oldOverall['result_for_2_5'] = 0
                    oldOverall['result_for_5_0'] = 0
                    setAvgLoad5_0mm(oldOverall['result_for_5_0'])
                    setAvgLoad25mm(oldOverall['result_for_2_5'])
                }
            }
            if (item.groupName === result.groupName) {
                item.data = arr
                item.overall = {
                    ...oldOverall,
                }
            }
        })

        setFinalData(_finalData)
        setData(arr)

        setInitialState({ ...initialValues })
        setOpen(false)
    }

    const handleDiaChange = (e) => {
        setDia(e.target.value)
    }

    const applyFormula = (arr) => {
        arr.forEach((item) => {
            if (item.penetration === '2.5') {
                let getValue = valueCalc(item.proving_ring * 0.056 * 100)
                item.action_load = valueCalc((getValue / 1370) * 100)
            } else if (item.penetration === '5.0') {
                let getValue = valueCalc(item.proving_ring * 0.056 * 100)
                item.action_load = valueCalc((getValue / 2055) * 100)
            } else {
                item.action_load = valueCalc(item.proving_ring * 0.056 * 100)
            }
        })
        return arr
    }

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <JobAccordion
                        title={result?.groupName}
                        handleRequirementChange={handleRequirementChange}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        requirementvalue={overall?.requirements}
                        remarksValue={overall?.remarks}
                        overall={overall}
                        avgLabel={'CBR value at 2.5mm penetration '}
                        averagevalue={avgLoad2_5mm}
                        avgLabel2={'CBR value at 5.0mm penetration '}
                        averagevalue2={avgLoad5_0mm}
                    >
                        <Grid item xs={12}>
                            <Formik
                                initialValues={initialState}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {(props) => {
                                    const {
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    } = props
                                    return (
                                        <Form>
                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                            >
                                                <StyledCard>
                                                    <CardHeader
                                                        title={
                                                            result?.groupName
                                                        }
                                                    />
                                                    <CardContent
                                                        sx={{
                                                            m: 4,
                                                        }}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={4}
                                                        >
                                                            <Grid item xs={12}>
                                                                <FormControl
                                                                    fullWidth
                                                                >
                                                                    <InputLabel id="demo-simple-select-label">
                                                                        Penetration
                                                                        in (mm)
                                                                    </InputLabel>
                                                                    <Select
                                                                        fullWidth
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        name="penetration"
                                                                        label="Penetration in (mm)"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            handleChange(
                                                                                e
                                                                            )
                                                                            handleDiaChange(
                                                                                e
                                                                            )
                                                                        }}
                                                                    >
                                                                        {CBRPenetration?.map(
                                                                            (
                                                                                value,
                                                                                idx
                                                                            ) => (
                                                                                <MenuItem
                                                                                    value={
                                                                                        value
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        value
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </FormControl>
                                                                <HelperText>
                                                                    {errors.penetration &&
                                                                    touched.penetration
                                                                        ? errors.penetration
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    value={
                                                                        values.proving_ring
                                                                    }
                                                                    label="Proving Ring Readings"
                                                                    required
                                                                    name="proving_ring"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.proving_ring &&
                                                                        touched.proving_ring
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.proving_ring &&
                                                                    touched.proving_ring
                                                                        ? errors.proving_ring
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button
                                                            size="small"
                                                            onClick={
                                                                handleClose
                                                            }
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            size="small"
                                                            variant="contained"
                                                            onClick={
                                                                handleSubmit
                                                            }
                                                        >
                                                            Save
                                                        </Button>
                                                    </CardActions>
                                                </StyledCard>
                                            </Modal>
                                        </Form>
                                    )
                                }}
                            </Formik>

                            {data && (
                                <CBRTable
                                    data={data}
                                    setData={setData}
                                    setFinalData={setFinalData}
                                    result={result}
                                    finalData={finalData}
                                    setOverall={setOverall}
                                    setAvgLoad25mm={setAvgLoad25mm}
                                    setAvgLoad5_0mm={setAvgLoad5_0mm}
                                    avgLoad2_5mm={avgLoad2_5mm}
                                    avgLoad5_0mm={avgLoad5_0mm}
                                    overall={overall}
                                />
                            )}

                            {isView !== true && (
                                <Grid container justifyContent="end">
                                    <Button
                                        sx={{ boxShadow: 'none', mt: 2 }}
                                        variant="contained"
                                        endIcon={<AddCircle />}
                                        onClick={handleOpen}
                                        size="small"
                                    >
                                        Add
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </JobAccordion>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default CBR
