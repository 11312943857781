import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { getGroupName } from '../method'

const borderColor = '#ffffff'
const labelfontfamily = 'Times-Bold'

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        borderBottomColor: '#ffffff',
        backgroundColor: '#d9d9d9',
        borderBottomWidth: 1,
        alignItems: 'center',
        border: '1px solid #d9d9d9',
        // minHeight: '20',
        maxWidth: '100%',
        // textAlign: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        fontSize: '10px',
        fontFamily: labelfontfamily,
        // marginBottom: 1,
        marginTop: 4,
        marginBottom: 5,
    },
    label: {
        alignSelf: 'center',
        textAlign: 'center',
        // paddingLeft: '35%',
    },
})

const TableTopHeader = (groupName) => {
    let titleName = getGroupName(groupName?.groupName)
    return (
        // <View style={styles.container} fixed>
        //     <Text style={styles.label}>{titleName}</Text>
        // </View>
        <View style={styles.container} fixed>
            {titleName === 'Bricks Water Absorption' ? (
                <Text style={styles.label}>{titleName} (%)</Text>
            ) : (
                <Text style={styles.label}>{titleName}</Text>
            )}
        </View>
    )
}
export default TableTopHeader
