import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import { Fragment, useContext, useState } from 'react'
import * as Yup from 'yup'
import { valueCalc } from '../../../method/method'
import CouplerChemicalTable from './CouplerChemicalTable'

const initialValues = {
    dia: '',
    perc_carbon: '',
    perc_manganese: '',
    perc_silicon: '',
    perc_sulphur: '',
    perc_phosphorus: '',
}

const validationSchema = Yup.object().shape({
    dia: Yup.string().required('Required'),
    perc_carbon: Yup.string().required('Required'),
    perc_manganese: Yup.string().required('Required'),
    perc_silicon: Yup.string().required('Required'),
    perc_sulphur: Yup.string().required('Required'),
    perc_phosphorus: Yup.number().required('Required'),
})

const CouplerChemical = (props) => {
    const {
        result,
        isView,
        overall,
        setOverall,
        handleCustomRequirementChange,
        handleRequirementChange,
    } = props

    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [dia, setDia] = useState(initialValues.dia)

    const { finalData, setFinalData } = useContext(AppContext)
    const [data, setData] = useState(result.data ? result.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        let _finalData = finalData

        let avg_perc_carbon = 0
        let avg_perc_manganese = 0
        let avg_perc_silicon = 0
        let avg_perc_sulphur = 0
        let avg_perc_phosphorus = 0

        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && arr?.length !== 0) {
                arr.forEach((item1) => {
                    avg_perc_carbon += Number(item1?.perc_carbon / arr.length)
                    avg_perc_manganese += Number(
                        item1?.perc_manganese / arr.length
                    )
                    avg_perc_silicon += Number(item1?.perc_silicon / arr.length)
                    avg_perc_sulphur += Number(item1?.perc_sulphur / arr.length)
                    avg_perc_phosphorus += Number(
                        item1?.perc_phosphorus / arr.length
                    )
                })

                item.overall = {
                    ...overall,
                    avg_perc_carbon: valueCalc(avg_perc_carbon),
                    avg_perc_manganese: valueCalc(avg_perc_manganese),
                    avg_perc_silicon: valueCalc(avg_perc_silicon),
                    avg_perc_phosphorus: valueCalc(avg_perc_phosphorus),
                    avg_perc_sulphur: valueCalc(avg_perc_sulphur),
                }

                setOverall(item.overall)
                // handleCustomRequirementChange(values?.dia + 'mm', item.overall)
            }
            if (item.groupName === result.groupName) {
                item.data = arr
            }
        })

        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
    }

    const handleDiaChange = (e) => {
        setDia(e.target.value)
    }

    return (
        <Fragment>
            <Grid item xs={12}>
                <JobAccordion
                    title={result?.groupName}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    requirementvalue={overall?.requirements}
                    remarksValue={overall?.remarks}
                    overall={overall}
                    // averagevalue={overall?.avg_perc_carbon}
                    // averagevalue2={overall?.avg_perc_sulphur}
                    // averagevalue3={overall?.avg_perc_phosphorus}
                    // avgLabel={'% Carbon'}
                    // avgLabel2={'% Sulphur'}
                    // avgLabel3={'% Phosphorus'}
                >
                    <Grid item xs={12}>
                        <Formik
                            initialValues={initialState}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {(props) => {
                                const {
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                } = props

                                return (
                                    <Form>
                                        <Modal
                                            open={open}
                                            onClose={handleClose}
                                        >
                                            <StyledCard>
                                                <CardHeader
                                                    title={result?.groupName}
                                                />
                                                <CardContent
                                                    sx={{
                                                        m: 4,
                                                    }}
                                                >
                                                    <Grid container spacing={4}>
                                                        <Grid item xs={12}>
                                                            <FormControl
                                                                fullWidth
                                                            >
                                                                <InputLabel id="demo-simple-select-label">
                                                                    Dia
                                                                </InputLabel>
                                                                <Select
                                                                    fullWidth
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={dia}
                                                                    name="dia"
                                                                    label="Dia"
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        handleChange(
                                                                            e
                                                                        )
                                                                        handleDiaChange(
                                                                            e
                                                                        )
                                                                    }}
                                                                >
                                                                    {/* {result?.requirement?.map(
                                                                        (
                                                                            value
                                                                        ) => (
                                                                            <MenuItem
                                                                                value={value?.test_params?.replace(
                                                                                    'mm',
                                                                                    ''
                                                                                )}
                                                                            >
                                                                                {value?.test_params?.replace(
                                                                                    'mm',
                                                                                    ''
                                                                                )}
                                                                            </MenuItem>
                                                                        )
                                                                    )} */}
                                                                    <MenuItem
                                                                        value={
                                                                            '8'
                                                                        }
                                                                    >
                                                                        8
                                                                    </MenuItem>{' '}
                                                                    <MenuItem
                                                                        value={
                                                                            '10'
                                                                        }
                                                                    >
                                                                        10
                                                                    </MenuItem>{' '}
                                                                    <MenuItem
                                                                        value={
                                                                            '12'
                                                                        }
                                                                    >
                                                                        12
                                                                    </MenuItem>{' '}
                                                                    <MenuItem
                                                                        value={
                                                                            '16'
                                                                        }
                                                                    >
                                                                        16
                                                                    </MenuItem>{' '}
                                                                    <MenuItem
                                                                        value={
                                                                            '20'
                                                                        }
                                                                    >
                                                                        20
                                                                    </MenuItem>{' '}
                                                                    <MenuItem
                                                                        value={
                                                                            '25'
                                                                        }
                                                                    >
                                                                        25
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                        value={
                                                                            '32'
                                                                        }
                                                                    >
                                                                        32
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                            <HelperText>
                                                                {errors.dia &&
                                                                touched.dia
                                                                    ? errors.dia
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            xs={12}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                value={
                                                                    values.perc_carbon
                                                                }
                                                                label="% Carbon"
                                                                required
                                                                name="perc_carbon"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                error={
                                                                    errors.perc_carbon &&
                                                                    touched.perc_carbon
                                                                }
                                                            />
                                                            <HelperText>
                                                                {errors.perc_carbon &&
                                                                touched.perc_carbon
                                                                    ? errors.perc_carbon
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            xs={12}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                label="% Manganese"
                                                                value={
                                                                    values.perc_manganese
                                                                }
                                                                name="perc_manganese"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                error={
                                                                    errors.perc_manganese &&
                                                                    touched.perc_manganese
                                                                }
                                                            />
                                                            <HelperText>
                                                                {errors.perc_manganese &&
                                                                touched.perc_manganese
                                                                    ? errors.perc_manganese
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            xs={12}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                label="% Silicon"
                                                                value={
                                                                    values.perc_silicon
                                                                }
                                                                name="perc_silicon"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                error={
                                                                    errors.perc_silicon &&
                                                                    touched.perc_silicon
                                                                }
                                                            />
                                                            <HelperText>
                                                                {errors.perc_silicon &&
                                                                touched.perc_silicon
                                                                    ? errors.perc_silicon
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            lg={6}
                                                            xs={12}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                label="% Sulphur"
                                                                value={
                                                                    values.perc_sulphur
                                                                }
                                                                name="perc_sulphur"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                error={
                                                                    errors.perc_sulphur &&
                                                                    touched.perc_sulphur
                                                                }
                                                            />
                                                            <HelperText>
                                                                {errors.perc_sulphur &&
                                                                touched.perc_sulphur
                                                                    ? errors.perc_sulphur
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            lg={6}
                                                            xs={12}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                label="% Phosphorus"
                                                                value={
                                                                    values.perc_phosphorus
                                                                }
                                                                name="perc_phosphorus"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                error={
                                                                    errors.perc_phosphorus &&
                                                                    touched.perc_phosphorus
                                                                }
                                                            />
                                                            <HelperText>
                                                                {errors.perc_phosphorus &&
                                                                touched.perc_phosphorus
                                                                    ? errors.perc_phosphorus
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid>

                                                        {/* <Grid
                                                            item
                                                            lg={4}
                                                            md={8}
                                                            sm={8}
                                                            xs={6}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                label="Requirement"
                                                                value={
                                                                    values.requirement
                                                                }
                                                                name="requirement"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                error={
                                                                    errors.requirement &&
                                                                    touched.requirement
                                                                }
                                                            />
                                                            <HelperText>
                                                                {errors.requirement &&
                                                                touched.requirement
                                                                    ? errors.requirement
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid> */}
                                                    </Grid>
                                                </CardContent>
                                                <CardActions>
                                                    <Button
                                                        size="small"
                                                        onClick={handleClose}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        onClick={handleSubmit}
                                                    >
                                                        Save
                                                    </Button>
                                                </CardActions>
                                            </StyledCard>
                                        </Modal>
                                    </Form>
                                )
                            }}
                        </Formik>

                        {data && (
                            <CouplerChemicalTable
                                data={data}
                                setData={setData}
                                setFinalData={setFinalData}
                                result={result}
                                finalData={finalData}
                                setOverall={setOverall}
                                overall={overall}
                                handleCustomRequirementChange={
                                    handleCustomRequirementChange
                                }
                            />
                        )}
                        {isView !== true && (
                            <Grid container justifyContent="end" spacing={2}>
                                <Grid item>
                                    <Button
                                        sx={{ boxShadow: 'none', m: 2 }}
                                        variant="contained"
                                        endIcon={<AddCircle />}
                                        onClick={handleOpen}
                                        size="small"
                                    >
                                        Add
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </JobAccordion>
            </Grid>
        </Fragment>
    )
}

export default CouplerChemical
