import { AddCircle, Edit } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Modal,
    TextField,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
    StyledIcon,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import { useContext, useState } from 'react'
import * as Yup from 'yup'
import { valueCalc } from '../../method/method'
import BulkDensityTable from './BulkDensityTable'

const initialValues = {
    wt_cylindrical_measure: '',
    wt_measure_loosely_filled: '',
    wt_measure_rodded_compacted: '',
    vol_cylinder: '',
    loose_bulk: '',
    rodded_bulk: '',
}

const validationSchema = Yup.object().shape({
    wt_cylindrical_measure: Yup.number().required('Required'),
    wt_measure_loosely_filled: Yup.number().required('Required'),
    wt_measure_rodded_compacted: Yup.number().required('Required'),
    vol_cylinder: Yup.number().required('Required'),
})
const BulkDensityModal = (props) => {
    const { result, isView, overall, setOverall } = props

    const handleClose = () => setOpen(false)
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const [initialState, setInitialState] = useState({ ...initialValues })
    const [data, setData] = useState(
        result.overall ? result?.overall : { ...initialValues }
    )
    const { finalData, setFinalData } = useContext(AppContext)

    const handleSubmit = (values) => {
        // values.loose_bulk = valueCalc(((values.wt_measure_loosely_filled - values.wt_cylindrical_measure) / values.vol_cylinder) * 1000)
        // values.rodded_bulk = valueCalc(((values.wt_measure_rodded_compacted - values.wt_cylindrical_measure) / values.vol_cylinder) * 1000)
        values.loose_bulk = valueCalc(
            (values.wt_measure_loosely_filled - values.wt_cylindrical_measure) /
                values.vol_cylinder
        )
        values.rodded_bulk = valueCalc(
            (values.wt_measure_rodded_compacted -
                values.wt_cylindrical_measure) /
                values.vol_cylinder
        )

        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName) {
                item.overall = {
                    ...values,
                    requirements: overall.requirements,
                    remarks: overall.remarks,
                    requirementStatus: overall.requirementStatus,
                    remarkStatus: overall?.remarkStatus,
                }

                setOverall(item.overall)
            }
        })
        // const bulkDensityData = _finalData.find(item => item.groupName === result.groupName)
        // let { overall } = bulkDensityData;

        // overall = { ...overall, ...values }
        // setOverall(overall)

        setFinalData(_finalData)
        setData(values)
        setInitialState({ ...initialValues })
        setOpen(false)
    }

    const handleDeleteTable = (val) => {
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName) {
                item.overall = {
                    requirements: overall.requirements,
                    requirementStatus: overall.requirementStatus,
                    remarkStatus: overall?.remarkStatus,
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData({ ...initialValues })
    }

    let length = Object.keys(result.overall).length

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props
                    return (
                        <Modal open={open} onClose={handleClose}>
                            <Form>
                                <StyledCard>
                                    <CardHeader title={result.groupName} />
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Weight of the Clylindrical  Measure W1, kg'"
                                                    required
                                                    value={
                                                        values.wt_cylindrical_measure
                                                    }
                                                    name="wt_cylindrical_measure"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.wt_cylindrical_measure &&
                                                        touched.wt_cylindrical_measure
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.wt_cylindrical_measure &&
                                                    touched.wt_cylindrical_measure
                                                        ? errors.wt_cylindrical_measure
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Wt Measure  Loosely filled sample, W2; kg"
                                                    value={
                                                        values.wt_measure_loosely_filled
                                                    }
                                                    name="wt_measure_loosely_filled"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.wt_measure_loosely_filled &&
                                                        touched.wt_measure_loosely_filled
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.wt_measure_loosely_filled &&
                                                    touched.wt_measure_loosely_filled
                                                        ? errors.wt_measure_loosely_filled
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Wt Measure  Rodded Compacted sample, W3; kg"
                                                    value={
                                                        values.wt_measure_rodded_compacted
                                                    }
                                                    name="wt_measure_rodded_compacted"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.wt_measure_rodded_compacted &&
                                                        touched.wt_measure_rodded_compacted
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.wt_measure_rodded_compacted &&
                                                    touched.wt_measure_rodded_compacted
                                                        ? errors.wt_measure_rodded_compacted
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Volume of Cylindrical measure, lit"
                                                    value={values.vol_cylinder}
                                                    name="vol_cylinder"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.vol_cylinder &&
                                                        touched.vol_cylinder
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.vol_cylinder &&
                                                    touched.vol_cylinder
                                                        ? errors.vol_cylinder
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        {data === result?.overall ? (
                                            <Button
                                                size="small"
                                                variant="contained"
                                                onClick={handleSubmit}
                                            >
                                                Edit
                                            </Button>
                                        ) : (
                                            <Button
                                                size="small"
                                                variant="contained"
                                                onClick={handleSubmit}
                                            >
                                                Save
                                            </Button>
                                        )}
                                    </CardActions>
                                </StyledCard>
                            </Form>
                        </Modal>
                    )
                }}
            </Formik>
            {data && (
                <BulkDensityTable
                    data={data}
                    setData={setData}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    result={result}
                />
            )}
            {isView !== true && (
                <Grid container justifyContent="end">
                    <IconButton
                        size="small"
                        onClick={(e) => handleDeleteTable(result?.overall)}
                        sx={{ boxShadow: 'none', mt: 2 }}
                    >
                        <StyledIcon>delete</StyledIcon>
                    </IconButton>
                    {length > 4 ? (
                        <Button
                            sx={{ boxShadow: 'none', mt: 2 }}
                            variant="contained"
                            endIcon={<Edit />}
                            onClick={handleOpen}
                            size="small"
                        >
                            Edit
                        </Button>
                    ) : (
                        <Button
                            sx={{ boxShadow: 'none', mt: 2 }}
                            variant="contained"
                            endIcon={<AddCircle />}
                            onClick={handleOpen}
                            size="small"
                        >
                            Add
                        </Button>
                    )}
                </Grid>
            )}
        </Grid>
    )
}

export default BulkDensityModal
