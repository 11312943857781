import { Grid } from '@mui/material'
import JOBCARD007A from './007A/JOBCARD007A'
import JOBCARD007C from './007C/JOBCARD007C'
import JOBCARD007G from './007G/JOBCARD007G'
import JOBCARD007I from './007I/JOBCARD007I'
import AggregateSoundnessTest from './AggregateSoundnessTest/AggregateSoundnessTest'
import BulkDensity from './BulkDensity/BulkDensity'
import Bulkage from './Bulkage/Bulkage'
import CrushingValue from './CrushingValue/CrushingValue'
import DryingShrinkage from './DryingShrinkage/DryingShrinkage'
import DryingShrinkageNew from './DryingShrinkageNew/DryingShrinkageNew'
import FlyMoistureContent from './FlyAshMoistureContent/FlyMoistureContent'
import ReinforcementSteel from './ReinforcementSteel/ReinforcementSteel'

import CarbonationTest from './CarbonationTest/CarbonationTest'
import CementFineness from './CementFineness/CementFineness'
import CementSoundness from './CementSoundness/CementSoundness'
import ClayLumps from './ClayLumps/ClayLumps'
import CompressiveStrength from './CompressiveStrength/CompressiveStrength'
import CrackWidthMeasurement from './CrackWidthMeasurement/CrackWidthMeasurement'
import FinalSettingTime from './FinalSettingTime/FinalSettingTime'
import FlyAshFineness from './FlyAshFineness/FlyAshFineness'
import ImpactValue from './ImpactValue/ImpactValue'
import MoistureContent from './MoistureContent/MoistureContent'
import NaturalMoistureContent from './NaturalMoistureContent/NaturalMoistureContent'
import NormalConsistency from './NormalConsistency/NormalConsistency'
import ParticleFinerThan75Micron from './ParticleFinerThan75Micron/ParticleFinerThan75Micron'
import ReboundHammer from './ReboundHammer/ReboundHammer'
import SieveAnalysis from './SieveAnalysis/SieveAnalysis'
import SiltContent from './SiltContent/SiltContent'
import SpecicGravity from './SpecificGravity/SpecicGravity'
import SpecificGravity_Le_Chatlier_Flask from './SpecificGravity_Le Chatlier_Flask/SpecificGravity_Le_Chatlier_Flask'
import TenPercFineValue from './TenPercFineValue/TenPercFineValue'
import UltrasonicPulseVelocity from './UltrasonicPulseVelocity/UltrasonicPulseVelocity'
import WaterAbsorption from './WaterAbsorption/WaterAbsorption'
import PaverCompressiveStrength from './PaverCompressiveStrength/PaverCompressiveStrength'

import { AppContext } from 'app/contexts/AppContext'
import { useContext, useEffect, useState } from 'react'
import Admixture from './Admixture/Admixture'
import CBR from './CBR/CBR'
import CementChemical from './Cement-Chemical/CementChemical'
import ConcreteCubeByACTMethod from './ConcreteCubeByACTMethod/ConcreteCubeByACTMethod'
import CoreCompressiveStrength from './CoreCompressiveStrength/CoreCompressiveStrength'
import CouplerChemical from './Coupler/Chemical/CouplerChemical'
import CouplerSpecification from './Coupler/Chemical/CouplerSpecification'
import Coupler from './Coupler/Coupler'
import PhysicalParameters from './DrinkingWater/PhysicalParameters/PhysicalParameters'
import HalfCelPotentialDiferencete from './HalfCelPotentialDiferencete/HalfCelPotentialDiferencete'
import HeavyCompaction from './HeavyCompaction/HeavyCompaction'
import InitailSettingTime from './InitialSettingTime/InitialSettingTime'
import LightCompaction from './LightCompaction/LightCompaction'
import Plywood from './Plywood/Plywood'
import ReinforcementSteelChemical from './ReinforcementSteel/Chemical/ReinforcementSteelChemical'
import SandReplacementMethod from './SandReplacementMethod/SandReplacementMethod'
import ShrinkageLimit from './ShrinkageLimit/ShrinkageLimit'
import Slump from './Slump/Slump'
import SoilCoreCutter from './SoilCoreCutter/SoilCoreCutter'
import SoilFreeSwellIndex from './SoilFreeSwellIndex/SoilFreeSwellIndex'
import SoilLiquidLimit from './SoilLiquidLimit/SoilLiquidLimit'
import SoilPlasticLimit from './SoilPlasticLimit/SoilPlasticLimit'
import SoilSieveAnalysis from './SoilSieveAnalysis/SoilSieveAnalysis'
import SoilSpecificGravity from './SoilSpecificGravity/SoilSpecificGravity'
import SwimmingPoolWater from './SwimmingPoolWater/SwimmingPoolWater'
import WMMTests from './WMM/WMMTests/WMMTests'
import WaterTest from './WaterTest/WaterTest'
import { getRequirement } from '../method/method'
import ConcreteCore from './ConcreteCore/ConcreteCore'
import FlexuralStrength from './FlexuralStrength/FlexuralStrength'
import AbrasionResistance from './AbrasionResistance/AbrasionResistance'
import CommonDoc from './CommonDoc/CommonDoc'
import Water from './Common/Water/Water'

const JobCardTable = (props) => {
    const {
        result,
        isView,
        remarksValue,
        materialName,
        index,
        standard_ref,
        organizationName,
        test_type,
        materialId,
    } = props
    const { finalData, setFinalData, EditDetails } = useContext(AppContext)
    let obj = getRequirement({ materialName, result })
    const [overall, setOverall] = useState(
        result?.overall?.requirements
            ? result.overall
            : (result.overall = {
                  ...result.overall,
                  requirements: obj?.requirement ? obj?.requirement : '',
                  requirementStatus: obj?.requirementStatus ?? Boolean(true),
                  //?? Boolean(false),
                  remarkStatus: obj?.remarkStatus ?? Boolean(true),
                  //?? Boolean(false),
              })
    )

    // useEffect(() => {
    //     setOverall({
    //         ...overall,
    //         {organizationName === 'FalconLab' ?
    //         standard_ref: result?.overall?.standard_ref ?? standard_ref :
    //          (
    //             result?.overall?.standard_ref ?? standard_ref
    //         ).toString()}
    //     })
    // }, [standard_ref])

    useEffect(() => {
        setOverall({
            ...overall,
            standard_ref:
                organizationName === 'FalconLab'
                    ? result?.overall?.standard_ref ?? standard_ref
                    : (
                          result?.overall?.standard_ref ?? standard_ref
                      )?.toString(),
        })
    }, [standard_ref])

    const handleCustomRequirementChange = (value, overall) => {
        const _finalData = finalData
        let req = result?.requirement.find((data) => data?.test_params == value)
        _finalData[index].overall.requirements = req?.requirement
        setFinalData(_finalData)
        setOverall({
            ...overall,
            requirements: req?.requirement ?? '',
            requirementStatus: req?.requirementStatus ?? Boolean(true),
            //?? Boolean(false),
            remarkStatus: req?.remarkStatus ?? Boolean(true),
            // ?? Boolean(false),
        })
    }

    const handleRequirementChange = (e) => {
        const { value, name } = e.target
        let _finalData = finalData.map((itemOrg) => {
            let item = { ...itemOrg }
            if (item.groupName === result.groupName) {
                item.overall = {
                    ...overall,
                    [name]: value,
                }
                setOverall(item.overall)
            }
            return item
        })
        setFinalData(_finalData)
    }
    const remarksValue1 = result.requirement.map((q) => q.remark).join(', ')
    return (
        <Grid item xs={12}>
            {(result.groupName === 'Elongation Index' ||
                result.groupName === 'Flakiness Index') && (
                <JOBCARD007A
                    result={result}
                    isView={isView}
                    index={index}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Cube Compressive Strength' && (
                <JOBCARD007C
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {(result.groupName === 'AAC Block Density' ||
                result.groupName === 'Block Density' ||
                //(
                result.groupName === 'Block Compressive Strength' ||
                // &&
                //     materialName !== 'Paver blocks')
                result.groupName === 'Block Water Absorption' ||
                ((result.groupName === 'Compressive Strength' ||
                    result.groupName === 'Water Absorption' ||
                    result.groupName === 'Thermal Conductivity') &&
                    materialName ===
                        'Autoclaved Aerated Concrete Blocks (AAC Blocks)')) && (
                <JOBCARD007G
                    result={result}
                    isView={isView}
                    materialName={materialName}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {(result.groupName === 'Bricks Compressive Strength' ||
                result.groupName === 'Efflorescence' ||
                result.groupName === 'Bricks Water Absorption' ||
                result.groupName === 'Dimension Test') && (
                <JOBCARD007I
                    result={result}
                    isView={isView}
                    remarksValue={remarksValue1}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Bulk Density' && (
                <BulkDensity
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {/* {(result.groupName === 'Coarse Aggregate Sieve Analysis - 6mm' ||
                result.groupName === 'Coarse Aggregate Sieve Analysis - 12.5mm' ||
                result.groupName === 'Coarse Aggregate Sieve Analysis - 20mm' ||
                result.groupName ==='Coarse Aggregate Sieve Analysis - 40mm') && (
                    <SeiveAnalysis
                        result={result}
                        isView={isView}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        setOverall={setOverall}
                        overall={overall}
                    />
                )} */}
            {result?.groupType === 'Sieve Analysis' && (
                <SieveAnalysis
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Specific Gravity' && (
                <SpecicGravity
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Aggregate Water Absorption' &&
                materialName !==
                    'Autoclaved Aerated Concrete Blocks (AAC Blocks)' && (
                    <WaterAbsorption
                        result={result}
                        isView={isView}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        setOverall={setOverall}
                        overall={overall}
                    />
                )}
            {result.groupName === 'Crushing Value' && (
                <CrushingValue
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Silt content' && (
                <SiltContent
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Impact Value' && (
                <ImpactValue
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === '10% fine value' && (
                <TenPercFineValue
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Clay lumps' && (
                <ClayLumps
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Particle finer than 75 micron' && (
                <ParticleFinerThan75Micron
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Bulkage' && (
                <Bulkage
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Natural Moisture content' && (
                <NaturalMoistureContent
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Soundness test' && (
                <AggregateSoundnessTest
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {/* {result.groupName === 'Drying Shrinkage' && (
                    <DryingShrinkage
                        result={result}
                        isView={isView}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        setOverall={setOverall}
                        overall={overall}
                    />
                )} */}
            {result.groupName === 'Drying Shrinkage' && (
                <DryingShrinkageNew
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result?.groupName === 'Flexural Strength' && (
                <FlexuralStrength
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result?.groupName === 'Abrasion Resistance' && (
                <AbrasionResistance
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {(result.groupName === 'Moisture Content' ||
                result.groupName === 'Moisture Movement') && (
                <DryingShrinkageNew
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                    groupName={result?.groupName}
                />
                // <MoistureContent
                //     result={result}
                //     isView={isView}
                //     handleCustomRequirementChange={
                //         handleCustomRequirementChange
                //     }
                //     handleRequirementChange={handleRequirementChange}
                //     setOverall={setOverall}
                //     overall={overall}
                // />
            )}
            {result.groupName === 'Compressive Strength' &&
                materialName !==
                    'Autoclaved Aerated Concrete Blocks (AAC Blocks)' && (
                    <CompressiveStrength
                        result={result}
                        isView={isView}
                        materialName={materialName}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        setOverall={setOverall}
                        overall={overall}
                    />
                )}
            {/* {result.groupName === 'Block Compressive Strength' &&
                materialName === 'Paver blocks' && (
                    <PaverCompressiveStrength
                        result={result}
                        isView={isView}
                        materialName={materialName}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        setOverall={setOverall}
                        overall={overall}
                    />
                )} */}
            {result.groupName === 'Specific gravity by Le Chatlier flask' && (
                <SpecificGravity_Le_Chatlier_Flask
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Moisture content' && (
                <FlyMoistureContent
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Normal Consistency' && (
                <NormalConsistency
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Initial Setting Time' && (
                <InitailSettingTime
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Fineness by Dry Sieving' && (
                <FlyAshFineness
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Fineness by Dry Sieving %' && (
                <CementFineness
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Soundness by Le Chatlier method' && (
                <CementSoundness
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Final Setting Time' && (
                <FinalSettingTime
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {/* Removed Sample  */}
            {result.groupName === 'Crack width measurement' && (
                <CrackWidthMeasurement
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {/* ********************************************************************8 */}
            {result.groupName === 'Carbonation test' && (
                <CarbonationTest
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Ultrasonic Pulse Velocity' && (
                <UltrasonicPulseVelocity
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Rebound Hammer' && (
                <ReboundHammer
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Half-Cell Potential difference test' && (
                <HalfCelPotentialDiferencete
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Soil Specific Gravity' && (
                <SoilSpecificGravity
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Grain Size Analysis(0.075 to 4.75 mm)' && (
                <SoilSieveAnalysis
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Liquid Limit' && (
                <SoilLiquidLimit
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Plastic Limit' && (
                <SoilPlasticLimit
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {(result.groupName === 'Soil Natural Moisture Content' ||
                result.groupName === 'Natural Moisture Content') && (
                <NaturalMoistureContent
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Free Swell Index' && (
                <SoilFreeSwellIndex
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Reinforcement Steel (Physical)' && (
                <ReinforcementSteel
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Reinforcement Steel (Chemical)' && (
                <ReinforcementSteelChemical
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}

            {result.groupName === 'Coupler' && (
                <Coupler
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Coupler (Chemical)' && (
                <CouplerChemical
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}

            {result.groupName === 'Slump' && (
                <Slump
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Heavy Compaction' && (
                <HeavyCompaction
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Light Compaction' && (
                <LightCompaction
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {/* {result.groupName === 'Core Compressive Strength' && (
                    <CoreCompressiveStrength
                        result={result}
                        isView={isView}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        setOverall={setOverall}
                        overall={overall}
                    />
                )} */}

            {result.groupName ===
                'Equivalent Cube Compressive Stregnth Results of Concrete Core' && (
                <ConcreteCore
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {(result.groupName === 'California Bearing Ratio' ||
                result.groupName === 'CBR Value(Calfornia Bearing Ratio)') && (
                <CBR
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName ===
                'Field Dry Density by Sand Replacement Method' && (
                <SandReplacementMethod
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Shrinkage limit' && (
                <ShrinkageLimit
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Field Dry Density by Core Cutter Method' && (
                <SoilCoreCutter
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {(result.groupName === 'Construction Water' ||
                result.groupName === 'Water Neutralization') && (
                <WaterTest
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Concrete Cube By ACT Method' && (
                <ConcreteCubeByACTMethod
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {/* {result.groupName === 'Sieve Analysis Result on GSB Sample' && (
                    <GSB
                        result={result}
                        isView={isView}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        setOverall={setOverall}
                        overall={overall}
                    />
                )} */}
            {result.groupName === 'GSB Sample' && (
                <WMMTests
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}

            {/* {result.groupName === 'Sieve Analysis Result on WMM Sample' && (
                    <WMM
                        result={result}
                        isView={isView}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        setOverall={setOverall}
                        overall={overall}
                    />
                )} */}
            {result.groupName === 'WMM Sample' && (
                <WMMTests
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {(result.groupName === 'Admixture' ||
                result?.groupName === 'Runner Pipe') && (
                <Admixture
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Specifications as per En 8 BS970 (%)' && (
                <CouplerSpecification
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result.groupName === 'Ply Wood' && (
                <Plywood
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {(result.groupName === 'Physical Parameters' ||
                result.groupName ===
                    'General Parameters Concerning Substances Undesirable in Excessive Amounts' ||
                result.groupName === '*Heavy Metal Parameters' ||
                result.groupName === '*Pesticides' ||
                result.groupName === '*Radio Active Material' ||
                result.groupName === 'Bacteriological Analysis Results' ||
                result.groupName === 'Radioactive Material' ||
                result?.groupName === 'Well Water') && (
                <PhysicalParameters
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {(result?.groupName === 'Swimming Pool Water' ||
                result?.groupName === 'ETP Effluent Water' ||
                result?.groupName === 'Bacteriological Parameters' ||
                result?.groupName === 'Cooling Tower Water' ||
                result.groupName === 'Drinking Water (Bore Water)' ||
                result?.groupName === 'STP Treated Water' ||
                result?.groupName === 'ETP Treated Water' ||
                result?.groupName === 'Organo chlorine Pesticides (OCPS)' ||
                result?.groupName === 'Organo Phosphorus Pesticides (OPPs)' ||
                result?.groupName === 'Synthetic Pyrethroids (SPs)' ||
                result?.groupName === 'Herbicides' ||
                result?.groupName === 'Chemical Parameters' ||
                result?.groupName === 'Abrasion value') && (
                <SwimmingPoolWater
                    materialName={materialName}
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {result?.groupType === 'DYNAMIC_JOBCARD' && (
                <Water
                    materialName={materialName}
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                    test_type={test_type}
                    materialId={materialId}
                />
            )}
            {result.groupName === 'Cement (Chemical)' && (
                <CementChemical
                    result={result}
                    isView={isView}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
        </Grid>
    )
}

export default JobCardTable
