import {
    deleteArchivedSampleCollectionById,
    deleteSampleCollectionById,
    fetchSampleCollectionTableData,
    fetchSampleCollectionViewData,
} from 'app/services/SampleCollectionService'

// sample collection Table Data

export const getSampleCollectionTableData = (props, callBack) => {
    fetchSampleCollectionTableData(
        props,
        (res) => callBack(res?.data?.data),
        (err) => console.log(err)
    )
}
// Delele sample collection     deleteArchivedSampleCollectionById
export const deleteSampleById = (test_id, callBack) => {
    deleteSampleCollectionById(
        test_id,
        (res) => callBack(res?.data),
        (err) => console.log(err)
    )
}

export const deleteArchivedSampleById = (props, callBack) => {
    deleteArchivedSampleCollectionById(
        props,
        (res) => callBack(res?.data),
        (err) => console.log(err)
    )
}

//  get single sample collection

export const getSampleCollectionTestView = (test_id, callBack) => {
    fetchSampleCollectionViewData(
        test_id,
        (res) => callBack(res?.data),
        (err) => console.log(err)
    )
}
