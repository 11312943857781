import { useContext, useState } from 'react'

import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import SoilCoreCutterTable from './SoilCoreCutterTable'
import { valueCalc } from '../../method/method'

const initialValues = {
    // containerNo: '',
    dia: '',
    heightOfMould: '',
    weightOfCoreCutter: '',
    weightOfWc: '',
    weightSoil: '',
    volumeOfCoreCutter: '',
    bulkDensity: '',
    location: '',
    weightOfContainer: '',
    weightOfContainerSoil: '',
    weightOfContainerDrySoil: '',
    waterContent: '',
    dryDensity: '',
    compaction: '',
    maximumDryDensity: '',
}

const validationSchema = Yup.object().shape({
    dia: Yup.number().required('Required'),
    // dia: Yup.number().typeError('Must be a number').required('Required'),
    heightOfMould: Yup.number().required('Required'),
    weightOfCoreCutter: Yup.number().required('Required'),
    weightOfWc: Yup.number().required('Required'),
    volumeOfCoreCutter: Yup.number().required('Required'),
    // location: Yup.number().required('Required'),
    location: Yup.string().required('Required'),
    weightOfContainer: Yup.number().required('Required'),
    weightOfContainerSoil: Yup.number().required('Required'),
    weightOfContainerDrySoil: Yup.number().required('Required'),
    //compaction: Yup.number().required('Required'),
    maximumDryDensity: Yup.number().required('Required'),
})
const SoilCoreCutterModal = ({ result, isView, setOverall, overall }) => {
    const handleClose = () => setOpen(false)
    const [open, setOpen] = useState(false)
    const [dia, setDia] = useState(initialValues.dia)

    const handleOpen = () => setOpen(true)
    const [data, setData] = useState(result.data ? result.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const { finalData, setFinalData } = useContext(AppContext)
    const handleSubmit = (values, { resetForm }) => {
        let findMax = []
        let Compaction_arr = []
        let avg = 0
        let arr = [...data]
        arr.push(values)
        arr = applyFormula(arr)
        let _finalData = finalData
        console.log(_finalData, 'final')
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && arr.length !== 0) {
                findMax = arr.map(
                    (item1) => item1.dryDensity && valueCalc(item1.dryDensity)
                )
                Compaction_arr = arr.map(
                    (item1) => item1.compaction && valueCalc(item1.compaction)
                )
                Compaction_arr.forEach((value) => {
                    avg += parseFloat(value) / arr.length // Assuming values are strings representing numbers
                })
            }
            if (item.groupName === result.groupName) {
                item.data = arr
                item.overall = {
                    ...overall,
                    average: avg,
                    maximunDryDensity: Math.max(...findMax),
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
    }

    const handleDiaChange = (e) => {
        setDia(e.target.value)
    }

    const applyFormula = (arr) => {
        arr.forEach((item, index) => {
            item.weightSoil = valueCalc(
                item.weightOfCoreCutter - item.weightOfWc
            )
            item.bulkDensity = valueCalc(
                (item.weightOfCoreCutter - item.weightOfWc) /
                    item.volumeOfCoreCutter
            )
            item.waterContent = valueCalc(
                ((item.weightOfContainerSoil - item.weightOfContainerDrySoil) /
                    (item.weightOfContainerDrySoil - item.weightOfContainer)) *
                    100
            )
            item.dryDensity = valueCalc(
                (100 * (item.weightOfCoreCutter - item.weightOfWc)) /
                    item.volumeOfCoreCutter /
                    (100 +
                        Number(
                            ((item.weightOfContainerSoil -
                                item.weightOfContainerDrySoil) /
                                (item.weightOfContainerDrySoil -
                                    item.weightOfContainer)) *
                                100
                        ))
            )
            item.compaction = valueCalc(
                (item.dryDensity / item.maximumDryDensity) * 100
            )
        })
        return arr
    }

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props
                    console.log(values, 'val')
                    return (
                        <Form>
                            <Modal open={open} onClose={handleClose}>
                                <StyledCard>
                                    <CardHeader title="Field Dry Density by Core Cutter Method" />
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}></Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Location"
                                                    value={values.location}
                                                    name="location"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.location &&
                                                        touched.location
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.location &&
                                                    touched.location
                                                        ? errors.location
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {/* <TextField
                                                    fullWidth
                                                    label="Internal Dia of the Core,D(mm)"
                                                    value={values.dia}
                                                    name="dia"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.dia &&
                                                        touched.dia
                                                    }
                                                /> */}
                                                <FormControl fullWidth>
                                                    {/* <InputLabel id="demo-simple-select-label">
                                                        Internal Dia of the Core , D(mm)
                                                    </InputLabel> */}
                                                    <TextField
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select-label"
                                                        value={dia}
                                                        name="dia"
                                                        label="Internal Dia of the Core , D(mm)"
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            handleDiaChange(e)
                                                        }}
                                                        onBlur={handleBlur}
                                                        error={
                                                            errors.dia &&
                                                            touched.dia
                                                        }
                                                    >
                                                        {/* <MenuItem value={'0'} defaultValue disabled>Select the Dia</MenuItem>
                                                        <MenuItem value={'100'}>100</MenuItem> */}
                                                        {/* <MenuItem value={'12'}>12</MenuItem>
                                                    <MenuItem value={'16'}>16</MenuItem>
                                                    <MenuItem value={'20'}>20</MenuItem>
                                                    <MenuItem value={'25'}>25</MenuItem> */}
                                                    </TextField>
                                                </FormControl>
                                                <HelperText>
                                                    {errors.dia && touched.dia
                                                        ? errors.dia
                                                        : ''}
                                                </HelperText>
                                            </Grid>{' '}
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Height of the Mould,H(mm)"
                                                    value={values.heightOfMould}
                                                    name="heightOfMould"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.heightOfMould &&
                                                        touched.heightOfMould
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.heightOfMould &&
                                                    touched.heightOfMould
                                                        ? errors.heightOfMould
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Weight of Core Cutter+ Wet Soil,Ws(g)"
                                                    value={
                                                        values.weightOfCoreCutter
                                                    }
                                                    name="weightOfCoreCutter"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.weightOfCoreCutter &&
                                                        touched.weightOfCoreCutter
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.weightOfCoreCutter &&
                                                    touched.weightOfCoreCutter
                                                        ? errors.weightOfCoreCutter
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Weight of Core Cutter,Wc(g)"
                                                    value={values.weightOfWc}
                                                    name="weightOfWc"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.weightOfWc &&
                                                        touched.weightOfWc
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.weightOfWc &&
                                                    touched.weightOfWc
                                                        ? errors.weightOfWc
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Volume of the core cutter,Vc(g/cc)"
                                                    value={
                                                        values.volumeOfCoreCutter
                                                    }
                                                    name="volumeOfCoreCutter"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.volumeOfCoreCutter &&
                                                        touched.volumeOfCoreCutter
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.volumeOfCoreCutter &&
                                                    touched.volumeOfCoreCutter
                                                        ? errors.volumeOfCoreCutter
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Weight of Container with,W1(g)"
                                                    value={
                                                        values.weightOfContainer
                                                    }
                                                    name="weightOfContainer"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.weightOfContainer &&
                                                        touched.weightOfContainer
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.weightOfContainer &&
                                                    touched.weightOfContainer
                                                        ? errors.weightOfContainer
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Weight of Container with and Wet Soil,W2(g)"
                                                    value={
                                                        values.weightOfContainerSoil
                                                    }
                                                    name="weightOfContainerSoil"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.weightOfContainerSoil &&
                                                        touched.weightOfContainerSoil
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.weightOfContainerSoil &&
                                                    touched.weightOfContainerSoil
                                                        ? errors.weightOfContainerSoil
                                                        : ''}
                                                </HelperText>
                                            </Grid>{' '}
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Weight of Container with dry soil,W3(g)"
                                                    value={
                                                        values.weightOfContainerDrySoil
                                                    }
                                                    name="weightOfContainerDrySoil"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.weightOfContainerDrySoil &&
                                                        touched.weightOfContainerDrySoil
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.weightOfContainerDrySoil &&
                                                    touched.weightOfContainerDrySoil
                                                        ? errors.weightOfContainerDrySoil
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Maximum Dry Density, MDD (g/cc)"
                                                    value={
                                                        values.maximumDryDensity
                                                    }
                                                    name="maximumDryDensity"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.maximumDryDensity &&
                                                        touched.maximumDryDensity
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.maximumDryDensity &&
                                                    touched.maximumDryDensity
                                                        ? errors.maximumDryDensity
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            {/* <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="% of Compaction(%)"
                                                    value={values.compaction}
                                                    name="compaction"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.compaction &&
                                                        touched.compaction
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.compaction &&
                                                        touched.compaction
                                                        ? errors.compaction
                                                        : ''}
                                                </HelperText>
                                            </Grid> */}
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </StyledCard>
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
            {data && (
                <SoilCoreCutterTable
                    data={data}
                    setData={setData}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    result={result}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {isView !== true && (
                <Grid container justifyContent="end">
                    <Button
                        sx={{ boxShadow: 'none', mt: 2 }}
                        variant="contained"
                        endIcon={<AddCircle />}
                        onClick={handleOpen}
                        size="small"
                    >
                        Add
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default SoilCoreCutterModal
