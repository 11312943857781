import {
    Box,
    Card,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    Grid,
    Icon,
    Input,
    InputLabel,
    Slide,
    Table,
    TableCell,
    TextField,
} from '@mui/material'
import { styled } from '@mui/styles'
import { forwardRef } from 'react'
import Cookies from 'js-cookie'

export const StyleTable = styled(Table)(({ theme }) => ({
    whiteSpace: 'pre',
    minWidth: 550,

    '& thead': {
        '& tr': {
            '& th': {
                paddingLeft: 5,
                paddingRight: 5,
                // textTransform: 'capitalize',
                border: '1px solid #e7e5e5',
                wordBreak: 'break-word',
            },
        },
    },
    '& tbody': {
        '& tr': {
            '& td': {
                paddingLeft: 0,
                // textTransform: 'capitalize',
                border: '1px solid #e7e5e5',
                wordBreak: 'break-word',
            },
        },
    },
    '& tr': {
        '& td': {
            wordBreak: 'break-word',
        },
    },
}))

export const StyledTable = ({ children }) => {
    return (
        <Box sx={{ overflowX: 'auto' }}>
            <StyleTable>{children}</StyleTable>
        </Box>
    )
}

export const LargeStyledTable = styled(Table)(({ theme }) => ({
    whiteSpace: 'pre',
    minWidth: 1300,

    '& thead': {
        '& tr': {
            '& th': {
                paddingLeft: 5,
                paddingRight: 5,
                // textTransform: 'capitalize',

                border: '1px solid #e7e5e5',
                wordBreak: 'break-word',
            },
        },
    },
    '& tbody': {
        '& tr': {
            '& td': {
                paddingLeft: 0,
                textTransform: 'capitalize',
                border: '1px solid #e7e5e5',
                wordBreak: 'break-word',
            },
        },
    },
    '& tr': {
        '& td': {
            wordBreak: 'break-word',
        },
    },
}))

export const CustomerStyledTable = styled(Table)(({ theme }) => ({
    whiteSpace: 'pre',

    '& thead': {
        '& tr': {
            '& th': {
                paddingLeft: 5,
                paddingRight: 5,
                border: '1px solid #000000',
                background: '#e7e5e5',

                // textTransform: 'capitalize',
                wordBreak: 'break-word',
            },
        },
    },
    '& tbody': {
        '& tr': {
            '& td': {
                paddingLeft: 0,
                textTransform: 'capitalize',
                border: '1px solid #e7e5e5',
                cursor: 'pointer',
                wordBreak: 'break-word',
            },
        },
    },
    '& tr': {
        '& td': {
            wordBreak: 'break-word',
        },
    },
}))

export const CustomTableCell = ({ row, name, onChange }) => {
    const { isEditMode } = row

    return (
        <TableCell align="left">
            {isEditMode ? (
                <Input value={row[name]} onChange={(e) => onChange(e, row)} />
            ) : (
                row[name]
            )}
        </TableCell>
    )
}

export const StyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    maxHeight: '80%',
    maxWidth: '80%',
    overflow: 'auto',
    top: '50%',
    left: '50%',
    justifyContent: 'space-between',
    padding: '24px !important',
    background: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
        padding: '16px !important',
    },
    '.&MuiCardContent-root': {
        maxHeight: '80%',
        maxWidth: '80%',
        overflow: 'auto',
    },
}))

export const TestStyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px !important',
    background: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
        padding: '16px !important',
    },
}))

export const HelperText = styled('div')(({ theme }) => ({
    paddingTop: '0.5rem',
    fontSize: '0.75rem',
    color: '#d32f2f',
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
    borderColor: '#e7e5e5',
}))
export const StyledIcon = styled(Icon)(({ theme, bgcolor }) => ({
    color: bgcolor ?? theme.palette.primary.main,
}))

export const Small = styled('small')(({ bgcolor }) => ({
    height: 15,
    width: 50,
    color: '#fff',
    padding: '2px 9px',
    borderRadius: '9px',
    overflow: 'hidden',
    background: bgcolor,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
}))

export const StyledProgress = styled(CircularProgress)(() => ({
    position: 'absolute',
    top: '6px',
    left: '25px',
}))

export const StyledTextField = styled(TextField)(() => ({
    '& .MuiOutlinedInput-input': {
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
        },
    },
}))

//transisitions
export const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export const RequirementTextFiled = ({
    handleRequirementChange,
    requirementvalue,
    requirementLabel,
    avgLabel,
    averagevalue,
    remarksValue,
    averagevalue2,
    averagevalue3,
    editaverage,
    avgLabel2,
    avgLabel3,
    avgLabel4,
    overall,
}) => {
    let requirementTestName = requirementvalue?.groupName
    let requirementData = []
    if (
        requirementTestName === 'Reinforcement Steel (Physical)' ||
        requirementTestName === 'Reinforcement Steel (Chemical)'
    ) {
        requirementData = requirementvalue?.requirement[0]?.requirement
    }
    let organizationName = Cookies.get('organizationName')
    return (
        <>
            <Grid container justifyContent={'space-between'}>
                <Grid item>
                    <Grid container spacing={3}>
                        {(averagevalue || avgLabel) && (
                            <Grid item>
                                <Box>
                                    <InputLabel>
                                        {avgLabel ?? 'Average'}
                                    </InputLabel>
                                    <TextField
                                        focused
                                        multiline
                                        name="average"
                                        // onChange={(e) =>
                                        //     handleRequirementChange(e)
                                        // }
                                        value={averagevalue ? averagevalue : 0}
                                        size="small"
                                    />
                                </Box>
                            </Grid>
                        )}
                        {(editaverage || avgLabel4) && (
                            <Grid item>
                                <Box>
                                    <InputLabel>
                                        {avgLabel4 ?? 'Edit Efflorescence'}
                                    </InputLabel>
                                    <TextField
                                        focused
                                        multiline
                                        name="editaverage"
                                        onChange={(e) =>
                                            handleRequirementChange(e)
                                        }
                                        value={editaverage ? editaverage : ''}
                                        size="small"
                                    />
                                </Box>
                            </Grid>
                        )}
                        {(averagevalue2 || avgLabel2) && (
                            <Grid item>
                                <Box>
                                    <InputLabel>
                                        {avgLabel2 ?? 'Average'}
                                    </InputLabel>
                                    <TextField
                                        focused
                                        multiline
                                        name="average"
                                        onChange={(e) =>
                                            handleRequirementChange(e)
                                        }
                                        value={
                                            averagevalue2 ? averagevalue2 : 0
                                        }
                                        size="small"
                                    />
                                </Box>
                            </Grid>
                        )}
                        {(averagevalue3 || avgLabel3) && (
                            <Grid item>
                                <Box>
                                    <InputLabel>
                                        {' '}
                                        {avgLabel3 ?? 'Average'}
                                    </InputLabel>
                                    <TextField
                                        focused
                                        multiline
                                        name="average"
                                        value={
                                            averagevalue3 ? averagevalue3 : 0
                                        }
                                        size="small"
                                        onChange={(e) =>
                                            handleRequirementChange(e)
                                        }
                                    />
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {organizationName !== 'CareLab' && (
                    <Grid item>
                        <Box>
                            <InputLabel> {'Standard Ref'} </InputLabel>
                            <TextField
                                focused
                                multiline
                                name="standard_ref"
                                onChange={(e) => handleRequirementChange(e)}
                                value={overall?.standard_ref}
                                size="small"
                            />
                        </Box>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
                {requirementTestName !== 'Reinforcement Steel (Physical)' &&
                requirementTestName !== 'Reinforcement Steel (Chemical)' ? (
                    <>
                        <InputLabel>
                            {requirementLabel ?? 'Requirements'}
                        </InputLabel>
                        <TextField
                            onChange={(e) => handleRequirementChange(e)}
                            name="requirements"
                            value={requirementvalue}
                            focused
                            fullWidth
                            variant="standard"
                            multiline
                            size="small"
                            inputProps={{
                                style: {
                                    // textTransform: 'capitalize',
                                    letterSpacing: '0.5',
                                },
                            }}
                        />
                    </>
                ) : (
                    <>
                        <InputLabel sx={{ color: 'black' }}>
                            {'Requirements'}
                        </InputLabel>
                        {requirementData?.map((value, idx) => {
                            return (
                                <>
                                    <InputLabel>
                                        {value?.testParams +
                                            ' ' +
                                            '(' +
                                            value?.unit +
                                            ')'}
                                    </InputLabel>
                                    <TextField
                                        // onChange={(e) => handleRequirementChange(e)}
                                        name="requirements"
                                        value={value?.limit}
                                        focused
                                        disabled
                                        fullWidth
                                        variant="standard"
                                        multiline
                                        size="small"
                                        inputProps={{
                                            style: {
                                                // textTransform: 'capitalize',
                                                letterSpacing: '0.5',
                                            },
                                        }}
                                    />
                                </>
                            )
                        })}
                    </>
                )}
                <Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={
                                    overall?.requirementStatus === 'true' ||
                                    overall?.requirementStatus === true
                                }
                                // checked={overall?.requirementStatus}
                                name="requirementStatus"
                                onClick={(e) =>
                                    handleRequirementChange({
                                        target: {
                                            name: 'requirementStatus',
                                            value: e.target.checked,
                                        },
                                    })
                                }
                            />
                        }
                        label="Requirement Visibility"
                    />
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
                <Box>
                    <InputLabel>{'Remarks'}</InputLabel>
                    <TextField
                        onChange={(e) => handleRequirementChange(e)}
                        name="remarks"
                        value={remarksValue ?? ''}
                        focused
                        fullWidth
                        variant="standard"
                        multiline
                        size="small"
                        inputProps={{
                            style: {
                                // textTransform: 'capitalize',
                                letterSpacing: '0.5',
                            },
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={
                                    overall?.remarkStatus === 'true' ||
                                    overall?.remarkStatus === true
                                    //     overall?.remarkStatus === 'false' || // Add this condition for false
                                    //     overall?.remarkStatus === false
                                }
                                //checked={overall?.remarkStatus}
                                name="remarkStatus"
                                onClick={(e) =>
                                    handleRequirementChange({
                                        target: {
                                            name: 'remarkStatus',
                                            value: e.target.checked,
                                        },
                                    })
                                }
                            />
                        }
                        label="Remark Visibility"
                    />
                </Box>
            </Grid>
        </>
    )
}
