import {
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { Box } from '@mui/system'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const ElongationTable = (props) => {
    const {
        data,
        setData,
        setFinalData,
        finalData,
        result,
        setOverall,
        handleCustomRequirementChange,
        overall,
    } = props

    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        let totalElongation = 0
        _finalData.forEach((item) => {
            if (item.data.length > 0 && item.groupName === result.groupName) {
                _data.forEach((item1, index) => {
                    let value =
                        Number(
                            item1.weightOfAggregateRetainedOnGuage /
                                item1.totalWeight
                        ) * 100
                    item1.individualElongation = Number(value.toFixed(4))
                    totalElongation += Number(value.toFixed(4))
                    if (index === _data.length - 1) {
                        // handleCustomRequirementChange(item1?.guagethickness)
                        // setOverall({
                        //     ...overall, totalElongation: valueCalc(totalElongation)
                        // })
                    }
                })
            }
            if (item.groupName === result.groupName) {
                item.data = _data
                item.overall = {
                    ...overall,
                    totalElongation: valueCalc(totalElongation),
                }
                setOverall(item.overall)
            }
        })

        setFinalData(_finalData)
    }
    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">
                            Total weight (g), X
                        </TableCell>
                        <TableCell align="center">
                            Sieve Size Passing(mm)
                        </TableCell>
                        <TableCell align="center">
                            Wt. of aggregate Retained on Sieve (g)
                        </TableCell>
                        <TableCell align="center">
                            Guage thickness(mm)
                        </TableCell>
                        <TableCell align="center">
                            Wt. of aggregate retained on guage (g), Y
                        </TableCell>
                        <TableCell align="center">
                            Individual Elongation (Y/X)*100
                        </TableCell>
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {data.map((data, index) => (
                        <TableRow key={index}>
                            <TableCell align="center">
                                {data.totalWeight}
                            </TableCell>
                            <TableCell align="center">
                                {data.sieveSizePassing}
                            </TableCell>
                            <TableCell align="center">
                                {data.wtRetainedOnISSieve}
                            </TableCell>
                            <TableCell align="center">
                                {data.guagethickness}
                            </TableCell>
                            <TableCell align="center">
                                {data.weightOfAggregateRetainedOnGuage}
                            </TableCell>
                            <TableCell align="center">
                                {
                                    (data.individualElongation = valueCalc(
                                        (data.weightOfAggregateRetainedOnGuage /
                                            data.totalWeight) *
                                            100
                                    ))
                                }
                            </TableCell>
                            <TableCell align="center">
                                <Button onClick={(e) => handleDelete(index, e)}>
                                    <Icon>delete</Icon>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default ElongationTable
