import { Grid } from '@mui/material'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'
import { Fragment } from 'react'
import CouplerSpecificationModal from './CouplerSpecificationModal'

const CouplerSpecification = ({
    result,
    isView,
    overall,
    handleCustomRequirementChange,
    handleRequirementChange,
    setOverall,
}) => {
    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <JobAccordion
                        title={result?.groupName}
                        requirementvalue={overall?.requirements}
                        remarksValue={overall?.remarks}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        overall={overall}
                    >
                        <CouplerSpecificationModal
                            result={result}
                            isView={isView}
                            overall={overall}
                            setOverall={setOverall}
                        />
                    </JobAccordion>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default CouplerSpecification
