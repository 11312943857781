import styled from '@emotion/styled'
import { Button, Grid, Icon } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
// import SampleRequestTable from '../clientView/SampleRequestTable'
import BranchCreationForm from './BranchCreationForm'
import DrawerLayout from './DrawerLayout'
import UsersTable from './UsersTable'
import UserDialog from './UserDialog'

export const ContentBox = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
}))

const Users = (initialValues) => {
    const navigate = useNavigate()
    const [userFormDrawer, setUserFormDrawer] = useState(false)

    const toggleUserFormDrawer = () => {
        setUserFormDrawer(!userFormDrawer)
    }

    const [branchFormDrawer, setBranchFormDrawer] = useState(false)
    const [branchFormData, setBranchFormData] = useState({
        name: '',
        branch_no: '',
        address: '',
        phoneNumbers: [''],
    })
    const toggleBranchFormDrawer = () => {
        setBranchFormDrawer(!branchFormDrawer)
    }

    const [dialogMessage, setDialogMessage] = useState('')
    const [openDialog, setOpenDialog] = useState(false)

    const handleDialogClose = () => {
        setOpenDialog(false)
    }
    return (
        <ContentBox>
            <Grid container spacing={2}>
                <Grid item>
                    <Button
                        fullWidth
                        variant="contained"
                        endIcon={<Icon>control_point</Icon>}
                        onClick={toggleUserFormDrawer}
                    >
                        Users
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        fullWidth
                        variant="contained"
                        endIcon={<Icon>control_point</Icon>}
                        onClick={toggleBranchFormDrawer}
                    >
                        Branch
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <UsersTable
                        toggle={toggleUserFormDrawer}
                        show={userFormDrawer}
                        initialValues={initialValues}
                        dialogMessage={dialogMessage}
                        setDialogMessage={setDialogMessage}
                        setOpenDialog={setOpenDialog}
                        openDialog={openDialog}
                        handleDialogClose={handleDialogClose}
                    />
                </Grid>
            </Grid>
            <DrawerLayout
                toggle={toggleBranchFormDrawer}
                show={branchFormDrawer}
                state={branchFormData}
                setState={setBranchFormData}
                component={BranchCreationForm}
                dialogMessage={dialogMessage}
                setDialogMessage={setDialogMessage}
                setOpenDialog={setOpenDialog}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
            />
            <UserDialog
                dialogMessage={dialogMessage}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
            />
        </ContentBox>
    )
}

export default Users
