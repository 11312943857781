import Axios from 'app/configs/Axios'

// Job Table
export const fetchJobTableData = (props, callback, errorCallback) => {
    return Axios({
        method: 'get',
        url: '/jobs',
        params: {
            ...props,
        },
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}
//Job Table StatusCount
export const fetchJobStatusData = (props, callback, errorCallback) => {
    console.log('props === ', props)
    return Axios({
        method: 'get',
        url: '/jobsStatus',
        params: {
            ...props,
        },
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}
// Job Table sorting

export const sortJobData = (props, callback, errorCallback) => {
    return Axios({
        method: 'get',
        url: '/jobs',
        params: { ...props },
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}

// Job View
export const fetchViewData = (jobId, callback, errorCallback) => {
    return Axios({
        method: 'get',
        url: `/jobs/${jobId}`,
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}
//job save
export const saveJobData = (jobId, reqBody, callback, errorCallback) => {
    return Axios({
        method: 'put',
        url: `/jobs/${jobId}`,
        data: reqBody,
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err?.response?.data))
}

// JobTable  Delete action
export const deleteJobDataById = (jobId, callback, errorCallback) => {
    return Axios({
        method: 'delete',
        url: `/jobs/${jobId}`,
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}

export const deleteArchivedJobData = (props, callback, errorCallback) => {
    return Axios({
        method: 'patch',
        url: `/jobs/${props.jobId}`,
        data: props.status,
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}

//Publish Job
export const publishJob = (jobId, callback, errorCallback) => {
    return Axios({
        method: 'put',
        url: `jobs/${jobId}/publish`,
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}

//req
export const fetchRequirements = (materialId, callback, errorCallback) => {
    return Axios({
        method: 'get',
        url: `/material-requirements/${materialId}`,
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}

export const uploadJobDocuments = (props, callback, errorCallback) => {
    return Axios({
        method: 'post',
        url: `/docsUploadToJob?materialId=${props.materialId}&jobId=${props.jobId}`,
        data: props.formData,
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}

export const fetchReportDocuments = (props, callback, errorCallback) => {
    return Axios({
        method: 'get',
        url: `/getJobDocuments?materialId=${props.materialId}&jobId=${props.jobId}`,
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}

export const fetchDocumentsTemplate = (props, callback, errorCallback) => {
    return Axios({
        method: 'get',
        url: `/getMaterialDocuments?materialId=${props.materialId}`,
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}

export const fetchReportDocumentsByObjLink = (
    props,
    callback,
    errorCallback
) => {
    return Axios({
        method: 'get',
        url: `/getAwsDocument?jobId=${props.jobId}&materialId=${props.materialId}`,
        responseType: 'blob',
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}
