import {
    Box,
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { LargeStyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const CouplerTable = (props) => {
    let {
        data,
        setData,
        Dia,
        setFinalData,
        finalData,
        result,
        setOverall,
        overall,
        handleCustomRequirementChange,
    } = props

    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        let mass_avg = 0
        let yeild_avg = 0
        let uts_avg = 0
        let elongation_avg = 0
        let ratio_avg = 0
        let dia
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName) {
                if (item.data.length > 0) {
                    _data.forEach((item1) => {
                        // let mass = Number(item1.weight / item1.len)
                        mass_avg += Number(item1.weightPerMeter / _data.length)
                        //
                        // let yield_stress = Number(
                        //     (Number(item1.yieldLoad) * 1000) / (mass / 0.00785)
                        // )

                        yeild_avg += Number(item1.yieldStress / _data.length)

                        //
                        // let uts = Number(
                        //     Number(item1.ultimateLoad * 1000) / (mass / 0.00785)
                        // )

                        uts_avg += Number(
                            item1.ultimateTensileStress / _data.length
                        )
                        //
                        // let elongation = Number(
                        //     (100 *
                        //         (Number(item1.finalGaugeLength) -
                        //             Number(item1.originalGaugeLength))) /
                        //     Number(item1.originalGaugeLength)
                        // )
                        elongation_avg += Number(
                            item1.percentageOfElongation / _data.length
                        )

                        //
                        // let ratio = Number(uts / yield_stress)
                        ratio_avg += Number(item1._10_perc_uts / _data.length)
                        dia = item1?.dia
                    })
                }
                item.overall = {
                    ...overall,
                    mass_avg: valueCalc(mass_avg),
                    yeild_avg: valueCalc(yeild_avg),
                    uts_avg: valueCalc(uts_avg),
                    elongation_avg: valueCalc(elongation_avg),
                    ratio_avg: valueCalc(ratio_avg),
                }
                setOverall(item.overall)
                // if (item.data.length > 0) {

                //     handleCustomRequirementChange(dia + 'mm', item.overall)
                // } else {
                //     handleCustomRequirementChange('', item.overall)

                // }
                item.data = _data
            }
        })

        setFinalData(_finalData)
    }
    return (
        <Box sx={{ width: '100%', overflowX: 'auto' }}>
            <LargeStyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Dia (mm)</TableCell>
                        <TableCell align="center">Weight, W(kg)</TableCell>
                        <TableCell align="center">Length, L(m)</TableCell>
                        <TableCell align="center">
                            Weight per meter, (W/L)
                        </TableCell>
                        <TableCell align="center">
                            Cross Sectional Area , A (W per. m/0.00785) (mm²)
                        </TableCell>
                        <TableCell align="center">
                            Original Gauge Length, L1(mm)
                        </TableCell>

                        <TableCell align="center">Yield Load, P (kN)</TableCell>
                        <TableCell align="center">
                            Ultimate Load, F (kN)
                        </TableCell>
                        <TableCell align="center">
                            Final Gauge Length, L2(mm)
                        </TableCell>
                        <TableCell align="center">
                            Yield Stress,(N/mm²)
                        </TableCell>
                        <TableCell align="center">
                            Ultimate Tensile Stress, (F/A)(N/mm²)
                        </TableCell>
                        <TableCell align="center">TS/YS Ratio</TableCell>
                        <TableCell align="center">
                            % of Elongation 100*(L2-L1)/L1
                        </TableCell>

                        <TableCell align="center">Min. UTS (IS)</TableCell>
                        <TableCell align="center">Remark</TableCell>
                        <TableCell sx={{ width: 80 }} align="center">
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {data.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell align="center">{Dia}</TableCell>
                            <TableCell align="center">{item.weight}</TableCell>
                            <TableCell align="center">{item.len}</TableCell>
                            <TableCell align="center">
                                {item.weightPerMeter}
                            </TableCell>
                            <TableCell align="center">
                                {item.crossSectionalArea}
                            </TableCell>
                            <TableCell align="center">
                                {item.originalGaugeLength}
                            </TableCell>
                            <TableCell align="center">
                                {item.yieldLoad}
                            </TableCell>
                            <TableCell align="center">
                                {item.ultimateLoad}
                            </TableCell>
                            <TableCell align="center">
                                {item.finalGaugeLength}
                            </TableCell>
                            <TableCell align="center">
                                {item.yieldStress}
                            </TableCell>
                            <TableCell align="center">
                                {item.ultimateTensileStress}
                            </TableCell>
                            <TableCell align="center">
                                {item._10_perc_uts}
                            </TableCell>
                            <TableCell align="center">
                                {item.percentageOfElongation}
                            </TableCell>

                            <TableCell align="center">{item.minUts}</TableCell>
                            <TableCell align="center">{item.remark}</TableCell>
                            <TableCell align="center">
                                <Button onClick={(e) => handleDelete(index, e)}>
                                    <Icon>delete</Icon>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </LargeStyledTable>
        </Box>
    )
}

export default CouplerTable
