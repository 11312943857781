import { AddCircle, Edit } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Modal,
    TextField,
} from '@mui/material'

import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
    StyledIcon,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import { useContext, useState } from 'react'
import * as Yup from 'yup'
import { valueCalc } from '../../method/method'
import SiltContentTable from './SiltContentTable'

const initialValues = {
    volume_of_sample: '',
    volume_after_depostion: '',
    silt_content: '',
}
const validationSchema = Yup.object().shape({
    volume_of_sample: Yup.number().required('Required'),
    volume_after_depostion: Yup.number().required('Required'),
})
const SiltContentModal = (props) => {
    const { result, isView, overall, setOverall } = props
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [data, setData] = useState(
        result?.overall ? result?.overall : { ...initialValues }
    )
    const [initialState, setInitialState] = useState({ ...initialValues })

    const { finalData, setFinalData } = useContext(AppContext)

    const handleSubmit = (values, { resetForm }) => {
        //Formula
        values.silt_content = valueCalc(
            ((values.volume_of_sample - values.volume_after_depostion) /
                values.volume_after_depostion) *
                100
        )

        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName) {
                item.overall = {
                    ...values,
                    requirements: overall.requirements,
                    remarks: overall.remarks,
                    requirementStatus: overall.requirementStatus,
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData(values)
        setInitialState({ ...initialValues })
        setOpen(false)
    }
    const handleDelete = (val) => {
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName) {
                item.overall = {
                    requirements: overall.requirements,
                    requirementStatus: overall.requirementStatus,
                    remarkStatus: overall.remarkStatus,
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData({ ...initialValues })
    }
    let length = Object.keys(result.overall).length

    return (
        <Grid container>
            <Grid item xs={12}>
                <Formik
                    initialValues={initialState}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        } = props
                        return (
                            <Form>
                                <Modal open={open} onClose={handleClose}>
                                    <StyledCard>
                                        <CardHeader title={result?.groupName} />
                                        <CardContent
                                            sx={{
                                                m: 4,
                                            }}
                                        >
                                            <Grid container spacing={4}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Volume of sample, V1 (ml)"
                                                        value={
                                                            values.volume_of_sample
                                                        }
                                                        name="volume_of_sample"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={
                                                            errors.volume_of_sample &&
                                                            touched.volume_of_sample
                                                        }
                                                    />
                                                    <HelperText>
                                                        {errors.volume_of_sample &&
                                                        touched.volume_of_sample
                                                            ? errors.volume_of_sample
                                                            : ''}
                                                    </HelperText>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Volume after deposition, V2 (ml)"
                                                        value={
                                                            values.volume_after_depostion
                                                        }
                                                        name="volume_after_depostion"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={
                                                            errors.volume_after_depostion &&
                                                            touched.volume_after_depostion
                                                        }
                                                    />
                                                    <HelperText>
                                                        {errors.volume_after_depostion &&
                                                        touched.volume_after_depostion
                                                            ? errors.volume_after_depostion
                                                            : ''}
                                                    </HelperText>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <CardActions>
                                            <Button
                                                size="small"
                                                onClick={handleClose}
                                            >
                                                Cancel
                                            </Button>
                                            {data === result?.overall ? (
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    onClick={handleSubmit}
                                                >
                                                    Edit
                                                </Button>
                                            ) : (
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    onClick={handleSubmit}
                                                >
                                                    Save
                                                </Button>
                                            )}
                                        </CardActions>
                                    </StyledCard>
                                </Modal>
                            </Form>
                        )
                    }}
                </Formik>
                {data && (
                    <SiltContentTable
                        data={data}
                        setData={setData}
                        setFinalData={setFinalData}
                        result={result}
                        finalData={finalData}
                    />
                )}
                {isView !== true && (
                    <>
                        <Grid container justifyContent="end">
                            <IconButton
                                size="small"
                                onClick={(e) => handleDelete(result?.overall)}
                                sx={{ boxShadow: 'none', mt: 2 }}
                            >
                                <StyledIcon>delete</StyledIcon>
                            </IconButton>
                            {length > 4 ? (
                                <Button
                                    sx={{ boxShadow: 'none', mt: 2 }}
                                    variant="contained"
                                    endIcon={<Edit />}
                                    onClick={handleOpen}
                                    size="small"
                                >
                                    Edit
                                </Button>
                            ) : (
                                <Button
                                    sx={{ boxShadow: 'none', mt: 2 }}
                                    variant="contained"
                                    endIcon={<AddCircle />}
                                    onClick={handleOpen}
                                    size="small"
                                >
                                    Add
                                </Button>
                            )}
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    )
}

export default SiltContentModal


// const SiltContentModal = () => {
//     return(
//         <>
//         </>
//     )
// }
// export default SiltContentModal