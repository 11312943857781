import { Grid } from '@mui/material'
import { Container } from 'app/views/material-kit/tables/AppTable'
import JobTable from 'app/views/material-kit/tables/JobTable'
import React from 'react'

const Job = () => {
    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <JobTable />
                </Grid>
            </Grid>
        </Container>
    )
}

export default Job
