import React from 'react'

import {
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material'
import { Box } from '@mui/system'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const SoilSieveAnalysisTable = ({
    data,
    setData,
    setFinalData,
    finalData,
    result,
    overall,
    setOverall,
    coarseSandValue,
    mediumSandValue,
    fineSandValue,
}) => {
    // const [rowsPerPage, setRowsPerPage] = React.useState(5)
    // const [page, setPage] = React.useState(0)

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage)
    // }

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(+event.target.value)
    //     setPage(0)
    // }

    data?.map((value) => [
        {
            sieve_size: value.sieve_size,
            retained_weight: value.retained_weight,
            cumulative_weight_retained: value.cumulative_weight_retained,
            cumulative_passing_weight_retained:
                value.cumulative_passing_weight_retained,
            passing_percentage: value.passing_percentage,
        },
    ])

    const Column = [
        { text: 'Sieve size', dataField: 'sieve_size' },
        { text: 'Retained Weight (g)', dataField: 'retained_weight' },
        {
            text: 'Cumulative Weight Retained(gm)',
            dataField: 'cumulative_weight_retained',
        },
        {
            text: 'Cumulative % Weight Retained',
            dataField: 'cumulative_passing_weight_retained',
        },
        { text: 'Passing, %', dataField: 'passing_percentage' },
    ]
    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        let coarseSand = 0
        let coarseSandAdded = false
        let mediumSand = 0
        let fineSand = 0
        let silt = 0
        let gravel = 0
        let q
        let cumulativeWeightRetained = 0
        let cumulativepassingweightretained = 0
        let addedweightretained = 0

        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && item.data.length > 0) {
                _data?.forEach((item1) => {
                    addedweightretained += Number.parseFloat(
                        item1.retained_weight
                    )
                    cumulativeWeightRetained += Number.parseFloat(
                        item1.retained_weight
                    )

                    item1.cumulative_weight_retained =
                        cumulativeWeightRetained.toString()
                    // item1.cumulative_weight_retained =
                    //     item1?.retained_weight === '0'
                    //         ? item1.retained_weight
                    //         : cumulativeWeightRetained
                })

                q = _data.find(
                    (item) => item.sieve_size == 'pan'
                )?.cumulative_weight_retained

                _data.forEach((item1) => {
                    item1.cumulative_passing_weight_retained =
                        item1.cumulative_weight_retained === '0'
                            ? item1.cumulative_weight_retained
                            : valueCalc(
                                  (item1?.cumulative_weight_retained /
                                      addedweightretained) *
                                      100
                              )
                    //q
                    //  ?
                    //valueCalc((item1.cumulative_weight_retained / q) * 100)
                    //  : '0'

                    // item1.cumulative_passing_weight_retained =
                    //     item1.cumulative_weight_retained === '0'
                    //         ? item1.cumulative_weight_retained
                    //         : cumulativepassingweightretained

                    console.log(
                        (item1?.cumulative_weight_retained /
                            addedweightretained) *
                            100,
                        addedweightretained,
                        '========='
                    )

                    item1.passing_percentage = valueCalc(
                        100 - item1?.cumulative_weight_retained
                    )
                    if (coarseSandValue.includes(item1?.sieve_size)) {
                        coarseSandAdded = true
                        coarseSand += parseFloat(item1.retained_weight)
                    }
                    if (mediumSandValue.includes(item1?.sieve_size)) {
                        coarseSandAdded = true
                        mediumSand += parseFloat(item1.retained_weight)
                    }
                    if (fineSandValue.includes(item1?.sieve_size)) {
                        coarseSandAdded = true
                        fineSand += parseFloat(item1.retained_weight)
                    }
                    if (item1?.sieve_size === '75micron') {
                        coarseSandAdded = true
                        silt += parseFloat(item1.retained_weight)
                    }
                    if (item1?.sieve_size === '4.75mm') {
                        coarseSandAdded = true
                        gravel += parseFloat(item1.retained_weight)
                    }
                })
            }
            if (item.groupName === result.groupName) {
                item.data = _data
                if (coarseSandAdded) {
                    item.overall = {
                        ...overall,
                        coarseSand: valueCalc(coarseSand),
                        mediumSand: valueCalc(mediumSand),
                        fineSand: valueCalc(fineSand),
                        silt: valueCalc(silt),
                        gravel: valueCalc(gravel),
                    }
                } else {
                    item.overall = {
                        ...overall,
                        coarseSand: null,
                        mediumSand: null,
                        fineSand: null,
                        silt: null,
                        gravel: null,
                    }
                }
                // item.overall = {
                //     ...overall,
                //     coarseSand: coarseSand === 0 ? null : valueCalc(coarseSand),
                //     mediumSand: mediumSand === 0 ? null : valueCalc(mediumSand),
                //     fineSand: fineSand === 0 ? null : valueCalc(fineSand),
                //     silt: silt === 0 ? null : valueCalc(silt),
                // }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
    }
    console.log(overall, 'over')
    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">SI.No</TableCell>
                        {Column.map((item, index) => (
                            <TableCell align="center" key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            {Column.map((subItem, idx) => (
                                <TableCell
                                    align="center"
                                    key={`row-${idx}-index`}
                                >
                                    {item[subItem.dataField] ?? ''}
                                </TableCell>
                            ))}
                            <TableCell align="center">
                                <Button onClick={(e) => handleDelete(index, e)}>
                                    <Icon>delete</Icon>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                    {/* <TableRow>
                        <TableCell align="right" colSpan={2}>
                            Plasticity Index (Liquid limit-Plastic limit)
                        </TableCell>
                        <TableCell align="center"></TableCell>
                    </TableRow> */}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default SoilSieveAnalysisTable
