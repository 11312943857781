export const unitData = [
    {
        name: 'Specific Gravity',
        unit: '--',
        requirement: '--',
    },
    {
        name: 'Aggregate Water Absorption',
        unit: '%',
        requirement: '--',
        notShowMaterials: ['Autoclaved Aerated Concrete Blocks (AAC Blocks)'],
    },
    // {
    //     name: 'Abrasion value',
    //     // unit: '%',
    //     // requirement: '--',
    // },
    {
        name: 'Bulk Density (Loose/Rodded)',
        unit: 'kg/lit',
        requirement: '--',
    },
    {
        name: 'Bulk Density',
        unit: 'kg/lit',
        requirement: '--',
    },
    {
        name: 'Crushing Value',
        unit: '%',
        requirement:
            'The Aggregate Crushing Value shall not exceed 45% by weight for the aggregate used for concrete',
    },
    {
        name: 'Elongation Index',
        unit: '%',
        requirement: 'Combined Flakiness and Elongation index not exceed 40%',
    },
    {
        name: 'Flakiness Index',
        unit: '%',
        requirement: 'Combined Flakiness and Elongation index not exceed 40%',
    },
    {
        name: 'Impact Value',
        unit: '%',
        requirement:
            'The Aggregate impact value shall not exceed 45% by weight for the aggregate used for concrete',
    },

    {
        name: 'Abrasion Value',
        unit: '%',
        requirement: '30% Max for wearing surface 50% Max for other concrete',
    },
    {
        name: 'Abrasion value',
        unit: '%',
        requirement: '30% Max for wearing surface 50% Max for other concrete',
    },
    {
        name: '10% fine value',
        unit: 'Ton',
        requirement:
            'Min 15 Ton for heavy duty concrete Min 10 Ton for wearing  surface  5Ton for other concrete',
    },
    {
        name: 'Clay lumps',
        unit: '%',
        requirement: 'Max 1% by Wt',
    },
    {
        name: 'Particle finer than 75 micron',
        unit: ' ',
        requirement: 'Max 3% by Wt',
    },
    {
        name: 'Soundness test',
        unit: '%',
        requirement: '--',
    },
    {
        name: 'Coal and Lignite',
        unit: '%',
        requirement: 'Max 1%',
    },
    {
        name: 'Chloride',
        unit: '%',
        requirement: '.05% Max',
    },
    {
        name: 'Sulphate',
        unit: '%',
        requirement: '.4% Max',
    },
    // {
    //     name: 'Coarse Aggregate Sieve Analysis',
    //     unit : '',
    // },
    {
        name: 'Bulkage',
        unit: '%',
        requirement: '--',
    },
    // {
    //     name: 'Fine Aggregate Sieve Analysis',
    //     unit : ''
    // },
    {
        name: 'Silt content',
        unit: '%',
        requirement: '--',
    },
    // for cement
    {
        name: 'Normal Consistency',
        value: '--',
        unit: '%',
    },
    {
        name: 'Final Setting Time',
        value: 'Shall not be less than 600 min',
        unit: 'min',
    },
    {
        name: 'Fineness by Dry Sieving %',
        value: '225(m2/kg)min',
        unit: '%',
    },
    {
        name: 'Initial Setting Time',
        value: 'Shall not be less than 30 min',
        unit: 'min',
    },
    {
        name: 'Compressive Strength',
        value: '--',
        unit: '(N/mm²)',
        notShowMaterials: ['Autoclaved Aerated Concrete Blocks (AAC Blocks)'],
    },
    {
        name: 'Soundness by Le Chatlier method',
        value: 'Shall not be less than 10 mm',
        unit: 'mm',
    },
    {
        name: 'Moisture content',
        unit: '%',
    },
    {
        name: 'Specific gravity by Le Chatlier flask',
        unit: ' g/cc',
    },
    {
        name: 'Fineness by Dry Sieving',
        unit: '%',
    },
    {
        name: 'Natural Moisture content',
        unit: '%',
    },
    {
        name: 'Natural Moisture Content',
        unit: '%',
    },
    //    paver Block and bricks
    // {
    //     name: 'Dimension Test',
    //     unit: ' ',
    // },
    // paver Block ------------
    // {
    //     name: 'Block Compressive Strength',
    //     unit: ' ',
    // },
    // {
    //     name: 'Block Water Absorption',
    //     unit: ' ',
    // },
    // {
    //     name: 'AAC Block Density',
    //     unit: ' ',
    // },
    //  -------Bricks---------
    // {
    //     name: 'Bricks Compressive Strength',
    //     unit: ' ',
    // },
    // {
    //     name: 'Bricks Water Absorption',
    //     unit: ' ',
    // },
    // // Bricks  & pre casted
    // {
    //     name: 'Efflorescence',
    //     unit: ' ',
    // },
    //    Concrete Blocks -----------------
    // {
    //     name: 'Drying Shrinkage',
    //     unit: ' ',
    // },
    // {
    //     name: 'Moisture Content',
    //     unit: ' ',
    // },
    // hardened concrete--------
    {
        name: 'Rebound Hammer',
        unit: ' ',
    },
    {
        name: 'Ultrasonic Pulse Velocity',
        unit: ' ',
    },
    // {
    //     name: 'Cube Compressive Strength',
    //     unit : '',
    // },

    // soil------
    {
        name: 'California Bearing Ratio',
        unit: ' ',
    },
    {
        name: 'Free Swell Index',
        unit: ' ',
    },
    {
        name: 'Grain Size Analysis(0.075 to 4.75 mm)',
        unit: ' ',
    },
    {
        name: 'Heavy Compaction',
        unit: ' ',
    },
    {
        name: 'Light Compaction',
        unit: ' ',
    },
    {
        name: 'Liquid Limit',
        unit: ' ',
    },
    {
        name: 'Soil Natural Moisture Content',
        unit: ' ',
    },
    {
        name: 'Plastic Limit',
        unit: ' ',
    },
    {
        name: 'Plasticity Index',
        unit: ' ',
    },
    {
        name: 'Soil Specific Gravity',
        unit: ' ',
    },
    {
        name: 'Carbonation test',
        unit: ' - ',
    },
    // {
    //     name: 'Field Dry Density by Core Cutter Method',
    //     unit: ' ',
    // },
    // Crack width measurement =====
    {
        name: 'Crack width measurement',
        unit: ' ',
    },
    // Half-Cell Potential difference test ------
    {
        name: 'Half-Cell Potential difference test',
        unit: ' - ',
    },
    // Slump --------
    {
        name: 'Slump',
        unit: ' mm',
    },
    //  Core Compressive Strength ---------
    // {
    //     name: 'Core Compressive Strength',
    //     unit : '',
    // },
    // soils
    {
        name: 'Shrinkage limit',
        unit: '%',
    },
    // Soil Core
    // {
    //     name: 'Field Dry Density by Sand Replacement Method',
    //     unit: ' ',
    // },

    // Construction Water
    {
        name: 'Construction Water',
        unit: ' ',
    },
    //DrinkingWater
    {
        name: 'Physical Parameters',
    },
    {
        name: 'General Parameters Concerning Substances Undesirable in Excessive Amounts',
    },
    {
        name: '*Heavy Metal Parameters',
    },
    {
        name: '*Pesticides',
    },
    {
        name: '*Radio Active Material',
    },
    {
        name: 'Bacteriological Analysis Results',
    },
    {
        name: 'Specifications as per En 8 BS970 (%)',
    },
]
