import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { TableHeaderData } from '../TableHeading/TableHeading'
import { countDecimalPlaces } from 'app/views/jobs/shared/method/method'

const borderColor = '#ffffff'
const fontSize = 10
const labelfontfamily = 'Times-Bold'
const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        borderBottomColor: '#ffffff',
        backgroundColor: '#d9d9d9',
        borderBottomWidth: 1,
        height: 'auto',
        maxWidth: '100%',
        textAlign: 'center',
        flexGrow: 1,
        paddingTop: 1,
        fontSize: fontSize,
        fontFamily: labelfontfamily,
        justifyContent: 'space-between',
    },

    label: {
        paddingLeft: 3,

        // minWidth: '10%',
    },
    label2: {
        paddingLeft: 50,
    },
    label3: {
        paddingLeft: 10,
    },
    label4: {
        paddingRight: 80,
    },
    label1: {
        paddingLeft: 3,
        paddingRight: '220px',

        // minWidth: '10%',
    },
    value: {
        paddingRight: '3%',
        marginLeft: '77%',
    },
    data: {
        width: '30.5%',
        paddingLeft: 3,
        paddingRight: 3,
        borderRightColor: borderColor,
        borderRightWidth: 1,

        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        paddingTop: 2,
    },
    lastData: {
        width: '30.5%',
        paddingLeft: 3,
        paddingRight: 3,
        paddingTop: 2,
        borderRightColor: '#d9d9d9',
        borderRightWidth: 1,
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
})

const TableFooter = ({
    type,
    typeValue,
    data,
    editdata,
    maximumWaterContent,
    maximunDryDensity,
    headerKeys,
    mass_avg,
    yeild_avg,
    uts_avg,
    elongation_avg,
    ratio_avg,
    threeDaysAvg,
    sevenDaysAvg,
    twentyEightDaysAvg,
    avg_perc_carbon,
    avg_perc_sulphur,
    avg_perc_phosphorus,
    avg_sulphur_phosphorus,
    isRequirement,
    standard_ref,
    titleName,
    avgLength,
    avgBreadth,
    avgHeight,
    groupName,
}) => {
    const valueCalc = (value) => {
        return !isNaN(value) && value.toString().indexOf('.') != -1
            ? Number(value).toFixed(
                  groupName === 'Block Density' || groupName === 'Efflorescence'
                      ? 3
                      : 2
              )
            : value
    }
    const valueCalcNew = (value) => {
        return !isNaN(value) && value.toString().indexOf('.') != -1
            ? Number(value).toFixed(3)
            : value
    }
    return (
        <View style={styles.container} wrap={false}>
            {headerKeys === TableHeaderData['Cube Compressive Strength'] && (
                <Text style={styles.label}>Average : </Text>
            )}

            {threeDaysAvg && (
                <>
                    <Text style={styles.label}>3rd Comp.st (N/mm²) :</Text>
                    <Text style={styles.data}>{valueCalc(threeDaysAvg)}</Text>
                </>
            )}
            {sevenDaysAvg && (
                <>
                    <Text style={styles.label}>7th Comp.st (N/mm²) :</Text>
                    <Text style={styles.data}>{valueCalc(sevenDaysAvg)}</Text>
                </>
            )}
            {twentyEightDaysAvg && (
                <>
                    <Text style={styles.label}>28th Comp.st (N/mm²) :</Text>
                    <Text style={styles.data}>
                        {valueCalc(twentyEightDaysAvg)}
                    </Text>
                </>
            )}
            {avgLength && (
                <>
                    <Text style={styles.label}>Average of Length :</Text>
                    <Text style={styles.data}>
                        {valueCalc(avgLength) + ' mm'}
                    </Text>
                </>
            )}
            {avgBreadth && (
                <>
                    <Text style={styles.label}>Average of Breadth :</Text>
                    <Text style={styles.data}>
                        {valueCalc(avgBreadth) + ' mm'}
                    </Text>
                </>
            )}
            {avgHeight && (
                <>
                    <Text style={styles.label}>Average of Height :</Text>
                    <Text style={styles.data}>
                        {valueCalc(avgHeight) + ' mm'}
                    </Text>
                </>
            )}

            {typeValue === 'observationNull' &&
                headerKeys === TableHeaderData['Dimension Test'] && (
                    <>
                        <Text style={styles.label2}>Average of Length :</Text>
                    </>
                )}
            {typeValue === 'observationNull' &&
                headerKeys === TableHeaderData['Dimension Test'] && (
                    <>
                        <Text style={styles.label3}>Average of Breadth :</Text>
                    </>
                )}
            {typeValue === 'observationNull' &&
                headerKeys === TableHeaderData['Dimension Test'] && (
                    <>
                        <Text style={styles.label4}>Average of Height :</Text>
                    </>
                )}

            {headerKeys === TableHeaderData['Elongation Index'] && (
                <Text style={styles.label}> Total Elongation : </Text>
            )}
            {headerKeys === TableHeaderData['Flakiness Index'] && (
                <Text style={styles.label}>Total flakiness : </Text>
            )}
            {/* {(TableHeaderData['Coarse Aggregate Sieve Analysis']) && (
                <Text style={styles.label}>Fineness Modulus :</Text>
            )}
            {titleName === "Fine Aggregate" && (
            <>
                <Text style={styles.label}>Fineness Modulus :</Text>
            </>
            )} */}

            {maximunDryDensity && (
                <>
                    <Text style={styles.label1}>Maximum Dry Density :</Text>
                    <Text style={styles.data}>
                        {valueCalc(maximunDryDensity)}
                    </Text>
                </>
            )}
            {maximumWaterContent && (
                <>
                    <Text style={styles.label1}>Maximum Water Content :</Text>
                    <Text style={styles.data}>
                        {valueCalc(maximumWaterContent)}
                    </Text>
                </>
            )}

            {(headerKeys ===
                TableHeaderData['Reinforcement Steel (Physical)'] ||
                headerKeys ===
                    TableHeaderData[
                        'Reinforcement Steel (Physical) Report'
                    ]) && (
                <>
                    <Text
                        style={{
                            paddingTop: 2,
                            width:
                                type === 'report' || type === 'reportOnly'
                                    ? '39%'
                                    : '43%',
                            paddingLeft: 3,
                            paddingRight: 3,
                            borderRightColor: borderColor,
                            borderRightWidth: 1,
                        }}
                    >
                        {`${isRequirement ? standard_ref : 'Average :'}`}
                    </Text>
                    {type !== 'observationNull' ? (
                        <>
                            <Text style={styles.data}>
                                {isRequirement
                                    ? mass_avg
                                    : countDecimalPlaces(mass_avg)}
                            </Text>
                            <Text style={styles.data}>
                                {isRequirement
                                    ? yeild_avg
                                    : countDecimalPlaces(yeild_avg)}
                            </Text>
                            <Text style={styles.data}>
                                {isRequirement
                                    ? uts_avg
                                    : countDecimalPlaces(uts_avg)}
                            </Text>
                            <Text style={styles.data}>
                                {isRequirement
                                    ? elongation_avg
                                    : countDecimalPlaces(elongation_avg)}
                            </Text>
                            <Text style={styles.data}>
                                {isRequirement
                                    ? ratio_avg
                                    : countDecimalPlaces(ratio_avg)}
                            </Text>
                            <Text style={styles.lastData}></Text>
                            <Text style={styles.lastData}></Text>
                        </>
                    ) : (
                        <>
                            <Text style={styles.data}>
                                {' '}
                                {isRequirement ? mass_avg : ''}
                            </Text>
                            <Text style={styles.data}>
                                {' '}
                                {isRequirement ? yeild_avg : ''}
                            </Text>
                            <Text style={styles.data}>
                                {' '}
                                {isRequirement ? uts_avg : ''}
                            </Text>
                            <Text style={styles.data}>
                                {isRequirement ? elongation_avg : ''}
                            </Text>
                            <Text style={styles.data}>
                                {' '}
                                {isRequirement ? ratio_avg : ''}
                            </Text>
                            <Text style={styles.lastData}></Text>
                            <Text style={styles.lastData}></Text>
                        </>
                    )}
                </>
            )}

            {headerKeys === TableHeaderData['Coupler'] && (
                <>
                    <Text
                        style={{
                            paddingTop: 2,
                            width: '43%',
                            paddingLeft: 3,
                            paddingRight: 3,
                            borderRightColor: borderColor,
                            borderRightWidth: 1,
                        }}
                    >
                        Mean Value :
                    </Text>
                    <Text style={styles.data}>{valueCalc(mass_avg)}</Text>
                    <Text style={styles.data}>{valueCalc(yeild_avg)}</Text>
                    <Text style={styles.data}>{valueCalc(uts_avg)}</Text>
                    <Text style={styles.data}>{valueCalc(elongation_avg)}</Text>
                    <Text style={styles.lastData}></Text>
                </>
            )}
            {headerKeys ===
                TableHeaderData['Reinforcement Steel (Chemical)'] && (
                <>
                    <Text
                        style={{
                            paddingTop: 2,
                            width: '37.4%',
                            paddingLeft: 3,
                            paddingRight: 3,
                            borderRightColor: borderColor,
                            borderRightWidth: 1,
                        }}
                    >
                        {' '}
                        {`${isRequirement ? standard_ref : 'Mean Value :'}`}
                    </Text>
                    {type !== 'observationNull' ? (
                        <>
                            <Text style={styles.data}>
                                {isRequirement
                                    ? avg_perc_carbon
                                    : countDecimalPlaces(avg_perc_carbon)}
                            </Text>
                            <Text style={styles.data}>
                                {isRequirement
                                    ? avg_perc_sulphur
                                    : countDecimalPlaces(avg_perc_sulphur)}
                            </Text>
                            <Text style={styles.data}>
                                {isRequirement
                                    ? avg_perc_phosphorus
                                    : countDecimalPlaces(avg_perc_phosphorus)}
                            </Text>
                            <Text style={styles.data}>
                                {isRequirement
                                    ? avg_sulphur_phosphorus
                                    : countDecimalPlaces(
                                          avg_sulphur_phosphorus
                                      )}
                            </Text>
                        </>
                    ) : (
                        <>
                            <Text style={styles.data}>
                                {' '}
                                {isRequirement ? avg_perc_carbon : ''}
                            </Text>
                            <Text style={styles.data}>
                                {' '}
                                {isRequirement ? avg_perc_sulphur : ''}
                            </Text>
                            <Text style={styles.data}>
                                {isRequirement ? avg_perc_phosphorus : ''}
                            </Text>
                            <Text style={styles.data}>
                                {isRequirement ? avg_sulphur_phosphorus : ''}
                            </Text>
                        </>
                    )}
                </>
            )}

            {headerKeys === TableHeaderData['Drying Shrinkage'] && data && (
                <Text style={styles.label}>Average :</Text>
            )}
            {headerKeys === TableHeaderData['Moisture Content'] && data && (
                <Text style={styles.label}>Average :</Text>
            )}
            {headerKeys === TableHeaderData['Moisture Movement'] && data && (
                <Text style={styles.label}>Average :</Text>
            )}

            {headerKeys === 'Average' && groupName === 'Liquid Limit' && (
                <Text style={styles.label}>
                    Liquid limit ( moisture content for 25 blow) :
                </Text>
            )}
            {headerKeys === 'Average' && groupName === 'Efflorescence' && (
                <Text style={styles.label}>Efflorescence :</Text>
            )}
            {headerKeys === 'Average' &&
                groupName !== 'Liquid Limit' &&
                groupName !== 'Efflorescence' &&
                groupName !== 'Dimension Test' && (
                    <Text style={styles.label}>Average :</Text>
                )}

            {data && groupName !== 'Dimension Test' && (
                <Text style={styles.value}>
                    {editdata && groupName === 'Efflorescence'
                        ? valueCalc(editdata)
                        : valueCalc(data)}
                    {groupName === 'Block Density' && ' kg/m³'}
                    {groupName === 'Efflorescence' && ' mm'}
                    {groupName === 'Abrasion Resistance' && ' mm3'}

                    {(groupName === 'Block Compressive Strength' ||
                        groupName === 'Compressive Strength' ||
                        groupName ===
                            'Equivalent Cube Compressive Stregnth Results of Concrete Core' ||
                        groupName === 'Concrete Cube By ACT Method' ||
                        // groupName === 'Efflorescence' ||
                        groupName === 'Bricks Compressive Strength' ||
                        groupName === 'Flexural Strength' ||
                        headerKeys ===
                            TableHeaderData['Cube Compressive Strength']) &&
                        ' N/mm²'}
                </Text>
            )}
        </View>
    )
}

export default TableFooter
