import {
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const CarbonationTestTable = (props) => {
    let {
        data,
        setData,
        setFinalData,
        finalData,
        result,
        setOverall,
        overall,
    } = props

    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        let average = 0

        _finalData.forEach((item) => {
            if (item.groupName === result.groupName) {
                if (item.data.length > 0) {
                    _data.forEach((item1) => {
                        let value = Number(item1.obs_result) / _data.length
                        average += Number(value.toFixed(4))
                    })
                }
                item.overall = {
                    ...overall,
                    average: valueCalc(average),
                }
                setOverall(item.overall)
                item.data = _data
            }
        })

        setFinalData(_finalData)
    }

    const Column = [
        { text: 'Sample Id', dataField: 'id' },

        { text: 'Level Of Carbonation', dataField: 'lvl_carbonation' },
        { text: 'Observed Result(Nil/Carbonated)', dataField: 'obs_result' },
    ]

    return (
        <StyledTable>
            <TableHead>
                <TableRow>
                    <TableCell align="center"> S.No</TableCell>

                    {Column.map((item, index) => (
                        <TableCell align={'center'} key={index}>
                            {item.text}
                        </TableCell>
                    ))}
                    <TableCell sx={{ width: 80 }} align={'center'}>
                        Actions
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data?.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell align="center"> {index + 1}</TableCell>
                        {Column.map((subItem, idx) => (
                            <TableCell align="center" key={`row-${idx}-index`}>
                                {item[subItem.dataField]}
                            </TableCell>
                        ))}
                        <TableCell align="center">
                            <Button onClick={(e) => handleDelete(index, e)}>
                                <Icon>delete</Icon>
                            </Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </StyledTable>
    )
}

export default CarbonationTestTable
