import { StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'

const styles = StyleSheet.create({
    dataContainer: {
        flexDirection: 'row',
        maxwidth: '100%',
        marginTop: 10,
        justifyContent: 'space-between',
    },
    value: {
        fontSize: 7.2,
        width: '80%',
        flexDirection: 'row',
        letterSpacing: '1',
    },
    label: {
        fontSize: 10,
        maxWidth: '20%',
        fontFamily: 'Times-Bold',
        marginRight: 10,
    },
})

const StandardRef = ({ standard_ref, organizationName, groupType }) => {
    return (
        <View style={styles.dataContainer}>
            {(groupType === 'DYNAMIC_JOBCARD' &&
                    organizationName !== 'CareLab') || organizationName !== 'CareLab' 
                 && (
                        <>
                            <Text style={styles.label}>Standard Ref :</Text>
                            <View style={styles.value}>
                                <Text>{standard_ref}</Text>
                            </View>
                        </>
                    )}
        </View>
    )
}

export default StandardRef
