import Axios from 'app/configs/Axios'

// sample collection table data

export const fetchSampleCollectionTableData = (
    { userId, size = 10, page },
    callback,
    errorCallback
) => {
    return Axios({
        method: 'get',
        url: '/sample-requests',
        params: {
            user_id: userId,
            size,
            page,
        },
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}
//  delete sample collection
export const deleteSampleCollectionById = (
    test_id,
    callBack,
    errorCallback
) => {
    return Axios({
        method: 'delete',
        url: `/sample-requests/${test_id}`,
    })
        .then((res) => callBack(res))
        .catch((err) => errorCallback(err))
}

export const deleteArchivedSampleCollectionById = (
    props,
    callBack,
    errorCallback
) => {
    return Axios({
        method: 'patch',
        url: `/sample-requests/${props.jobId}`,
        data: props.status,
    })
        .then((res) => callBack(res))
        .catch((err) => errorCallback(err))
}

// get single sample collection

export const fetchSampleCollectionViewData = (
    test_id,
    callback,
    errorCallback
) => {
    return Axios({
        method: 'get',
        url: `/sample-requests/${test_id}`,
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}
