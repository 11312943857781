import { useContext, useState } from 'react'

import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import PlywoodTable from './PlywoodTable'

const initialValues = {
    tests: '',
    req: '',
    result: '',
    elasticity_along: '',
    elasticity_along_req: '',
    rupture_along: '',
    rupture_along_req: '',
    elasticity_across: '',
    elasticity_across_req: '',
    rupture_across: '',
    rupture_across_req: '',
}

const validationSchema = Yup.object().shape({
    // result: Yup.number().required('Required'),
})
const PlywoodModal = ({ result, isView, setOverall, overall }) => {
    const handleClose = () => setOpen(false)
    const [requirement, setRequirement] = useState({
        req: '',
        elasticity_along_req: '',
        rupture_along_req: '',
        elasticity_across_req: '',
        rupture_across_req: '',
    })
    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)
    const [data, setData] = useState(result.data ? result.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const { finalData, setFinalData } = useContext(AppContext)
    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        // arr = applyFormula(arr);
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && arr.length !== 0) {
                let avg = 0
                arr.forEach((item1) => {})
            }
            if (item.groupName === result.groupName) {
                item.data = arr
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
        // resetForm()
    }

    // const applyFormula = (arr) => {
    //     arr.forEach((item, index) => {
    //         // let grades = conditions[item.is_sieve_designation]
    //         // item.requirement =  grades.req

    //     })
    //     return arr;
    // }

    const handleTests = (e) => {
        const index = result?.requirement
            .map((item) => item.test_params)
            .indexOf(e.target.value)
        let requirements = result?.requirement[index].requirement
        setRequirement({
            ...requirement,
            req: requirements,
        })

        const testConducted = e.target.value
        if (testConducted === 'Static Bending Strength') {
            setRequirement({
                ...requirement,
                elasticity_along_req: 'Average 7500 Minimum Individual 6700',
                rupture_along_req: 'Average 50 Minimum Individual 45',
                elasticity_across_req: 'Average 4000 Minimum Individual 3600',
                rupture_across_req: 'Average 30 Minimum Individual 27',
            })
        }
        if (testConducted === 'Wet Bending Strength') {
            setRequirement({
                ...requirement,
                elasticity_along_req: 'Average 3750 Minimum Individual 3400',
                rupture_along_req: 'Average 25 Minimum Individual 22',
                elasticity_across_req: 'Average 2000 Minimum Individual 1800',
                rupture_across_req: 'Average 15 Minimum Individual 13',
            })
        }
    }

    const handleReq = (e) => {
        const { name, value } = e.target
        setRequirement({
            ...requirement,
            [name]: value,
        })
    }

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props
                    return (
                        <Form>
                            <Modal open={open} onClose={handleClose}>
                                <StyledCard>
                                    <CardHeader title={result?.groupName} />
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                        Test Conducted{' '}
                                                    </InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={values.tests}
                                                        name="tests"
                                                        label="Test Conducted"
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            handleTests(e)
                                                        }}
                                                    >
                                                        {result?.requirement?.map(
                                                            (value) => (
                                                                <MenuItem
                                                                    value={
                                                                        value?.test_params
                                                                    }
                                                                >
                                                                    {
                                                                        value?.test_params
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            {!values?.tests.includes(
                                                'Bending'
                                            ) ? (
                                                <>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            label="Requirement"
                                                            value={
                                                                (values.req =
                                                                    requirement?.req)
                                                            }
                                                            name="req"
                                                            onChange={(e) => {
                                                                handleChange(e)
                                                                handleReq(e)
                                                            }}
                                                            onBlur={handleBlur}
                                                            error={
                                                                errors.req &&
                                                                touched.req
                                                            }
                                                        />
                                                        <HelperText>
                                                            {errors.req &&
                                                            touched.req
                                                                ? errors.req
                                                                : ''}
                                                        </HelperText>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            label="Result"
                                                            value={
                                                                values.result
                                                            }
                                                            name="result"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            error={
                                                                errors.result &&
                                                                touched.result
                                                            }
                                                        />
                                                        <HelperText>
                                                            {errors.result &&
                                                            touched.result
                                                                ? errors.result
                                                                : ''}
                                                        </HelperText>
                                                    </Grid>
                                                </>
                                            ) : (
                                                <>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6">
                                                            i ) Along (Parallel
                                                            to the Grain
                                                            Direction of the
                                                            face Veneer)
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            label="Average Modulus of Elasticity (N/Sq.mm)"
                                                            value={
                                                                values.elasticity_along
                                                            }
                                                            name="elasticity_along"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            error={
                                                                errors.elasticity_along &&
                                                                touched.elasticity_along
                                                            }
                                                        />
                                                        <HelperText>
                                                            {errors.elasticity_along &&
                                                            touched.elasticity_along
                                                                ? errors.elasticity_along
                                                                : ''}
                                                        </HelperText>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            label="Modules of Elasticity"
                                                            value={
                                                                (values.elasticity_along_req =
                                                                    requirement?.elasticity_along_req)
                                                            }
                                                            name="elasticity_along_req"
                                                            onChange={(e) => {
                                                                handleChange(e)
                                                                handleReq(e)
                                                            }}
                                                            onBlur={handleBlur}
                                                            error={
                                                                errors.elasticity_along_req &&
                                                                touched.elasticity_along_req
                                                            }
                                                        />
                                                        <HelperText>
                                                            {errors.elasticity_along_req &&
                                                            touched.elasticity_along_req
                                                                ? errors.elasticity_along_req
                                                                : ''}
                                                        </HelperText>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            label="Average Modulus of Rupture (N/Sq.mm)"
                                                            value={
                                                                values.rupture_along
                                                            }
                                                            name="rupture_along"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            error={
                                                                errors.rupture_along &&
                                                                touched.rupture_along
                                                            }
                                                        />
                                                        <HelperText>
                                                            {errors.rupture_along &&
                                                            touched.rupture_along
                                                                ? errors.rupture_along
                                                                : ''}
                                                        </HelperText>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            label="Modules of Rupture"
                                                            value={
                                                                (values.rupture_along_req =
                                                                    requirement?.rupture_along_req)
                                                            }
                                                            name="rupture_along_req"
                                                            onChange={(e) => {
                                                                handleChange(e)
                                                                handleReq(e)
                                                            }}
                                                            onBlur={handleBlur}
                                                            error={
                                                                errors.rupture_along_req &&
                                                                touched.rupture_along_req
                                                            }
                                                        />
                                                        <HelperText>
                                                            {errors.rupture_along_req &&
                                                            touched.rupture_along_req
                                                                ? errors.rupture_along_req
                                                                : ''}
                                                        </HelperText>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6">
                                                            ii )
                                                            Across(Perpendicular
                                                            to the Grain
                                                            direction of the
                                                            face Veneer)
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            label="Average Modulus of Elasticity (N/Sq.mm)"
                                                            value={
                                                                values.elasticity_across
                                                            }
                                                            name="elasticity_across"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            error={
                                                                errors.elasticity_across &&
                                                                touched.elasticity_across
                                                            }
                                                        />
                                                        <HelperText>
                                                            {errors.elasticity_across &&
                                                            touched.elasticity_across
                                                                ? errors.elasticity_across
                                                                : ''}
                                                        </HelperText>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            label="Modules of Elasticity"
                                                            value={
                                                                (values.elasticity_across_req =
                                                                    requirement?.elasticity_across_req)
                                                            }
                                                            name="elasticity_across_req"
                                                            onChange={(e) => {
                                                                handleChange(e)
                                                                handleReq(e)
                                                            }}
                                                            onBlur={handleBlur}
                                                            error={
                                                                errors.elasticity_across_req &&
                                                                touched.elasticity_across_req
                                                            }
                                                        />
                                                        <HelperText>
                                                            {errors.elasticity_across_req &&
                                                            touched.elasticity_across_req
                                                                ? errors.elasticity_across_req
                                                                : ''}
                                                        </HelperText>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            label="Average Modulus of Rupture (N/Sq.mm)"
                                                            value={
                                                                values.rupture_across
                                                            }
                                                            name="rupture_across"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            error={
                                                                errors.rupture_across &&
                                                                touched.rupture_across
                                                            }
                                                        />
                                                        <HelperText>
                                                            {errors.rupture_across &&
                                                            touched.rupture_across
                                                                ? errors.rupture_across
                                                                : ''}
                                                        </HelperText>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            label="Modules of Rupture"
                                                            value={
                                                                (values.rupture_across_req =
                                                                    requirement?.rupture_across_req)
                                                            }
                                                            name="rupture_across_req"
                                                            onChange={(e) => {
                                                                handleChange(e)
                                                                handleReq(e)
                                                            }}
                                                            onBlur={handleBlur}
                                                            error={
                                                                errors.rupture_across_req &&
                                                                touched.rupture_across_req
                                                            }
                                                        />
                                                        <HelperText>
                                                            {errors.rupture_across_req &&
                                                            touched.rupture_across_req
                                                                ? errors.rupture_across_req
                                                                : ''}
                                                        </HelperText>
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </StyledCard>
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
            {data && (
                <PlywoodTable
                    data={data}
                    setData={setData}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    result={result}
                    overall={overall}
                    setOverall={setOverall}
                />
            )}
            {isView !== true && (
                <Grid container justifyContent="end">
                    <Button
                        sx={{ boxShadow: 'none', mt: 2 }}
                        variant="contained"
                        endIcon={<AddCircle />}
                        onClick={handleOpen}
                        size="small"
                    >
                        Add
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default PlywoodModal
