import { Image, Line, StyleSheet, Svg, Text, View } from '@react-pdf/renderer'
import { Fragment } from 'react'
import falconLogo from './assets/images/falcon.png'
import line from './assets/images/line.png'
import nabl from './assets/images/nabl.png'
import carelab from './assets/images/careLab.png'
import nonNabl from './assets/images/nonNabl.png'
import logo from './assets/images/nabllogo.png'
import HeaderDesign from './Images/HeaderDesign'
import newFalconLogo from './assets/images/newFalconLogo.png'
import newFalconLogo1 from './assets/images/newFalconLogo1.png'
import newFalconLogo2 from './assets/images/newFalconLogo2.png'


const styles = StyleSheet.create({
    header: {
        marginTop: 2,
        flexDirection: 'row',
        maxHeight: '10%',
        justifyContent: 'space-between',
    },
    header1: {
        flexDirection: 'row',
        maxHeight: '11%',
        //paddingBottom: '10%',
        justifyContent: 'space-between',
    },
    observationHeader: {
        marginTop: 2,
        flexDirection: 'row',
        maxHeight: '10%',
        justifyContent: 'center',
    },
})

const Header = ({ nablNo, nablNo1, request_type, type, organizationName }) => {
    return organizationName !== 'CareLab' ? (
        <Fragment>
            {type !== 'observation' ? (
                <>
                    {request_type === 'NABL' ? (
                        <View style={styles.header} fixed>
                            {type !== 'reportOnly' &&
                                type !== 'observation' && (
                                    <View style={{ width: '28%' }}>
                                        <Image src={logo} />
                                    </View>

                                   // New Falcon Logo in future
                                    // <View style={{ width: '48%' }}>
                                    //     <Image src={newFalconLogo} />
                                    // </View>
                                )}
                            {type !== 'reportOnly' &&
                                type !== 'observation' && (
                                    <View style={{ alignItems: 'center' }}>
                                        <Image src={nabl} />
                                        <Text>{nablNo}</Text>
                                    </View>
                                )}
                        </View>
                    ) : (
                        <View style={styles.header} fixed>
                            {type !== 'reportOnly' &&
                                type !== 'observation' && (
                                    <View
                                        style={{
                                            flexDirection: 'row',
                                            paddingTop: '10',
                                        }}
                                    >
                                        <Svg
                                            width="2"
                                            style={{
                                                marginRight: 7,
                                                height: '50',
                                            }}
                                        >
                                            <Line
                                                x1="0"
                                                y1="0"
                                                x2="1"
                                                y2="200"
                                                strokeWidth={4}
                                                stroke="red"
                                            />
                                        </Svg>

                                        <View
                                            style={{
                                                fontSize: 13,
                                                paddingTop: 9,
                                                letterSpacing: 2,
                                                color: 'red',
                                                textAlign: 'center',
                                                fontFamily: 'Helvetica-Bold',
                                            }}
                                        >
                                            <Text>ISO / IEC 17025</Text>
                                            <Text>
                                                NABL ACCREDITED LABORATORY
                                            </Text>
                                        </View>
                                    </View>
                                )}
                            {type !== 'reportOnly' &&
                                type !== 'observation' && (
                                    <View
                                        style={{
                                            width: '30%',
                                            alignItems: 'center',
                                            padding: 2,
                                        }}
                                    >
                                        <Image
                                            style={{ width: '90%' }}
                                            src={logo}
                                        />
                                        {/* <Text
                                    style={{
                                        fontSize: 6,
                                        letterSpacing: 1,
                                        color: 'red',
                                        textAlign: 'center',
                                        fontFamily: 'Helvetica-Bold',
                                        width: '80%',
                                    }}
                                >
                                    FALCON INDUSTRIAL TESTING LABORATORY PVT
                                    LTD.
                                </Text> */}
                                    </View>
                                )}
                        </View>
                    )}
                </>
            ) : (
                <View style={styles.observationHeader} fixed>
                    <View style={{ width: '58%' }}>
                        <Image src={falconLogo} />
                    </View>
                </View>
            )}
            {type !== 'reportOnly' && (
                <View fixed>
                    <Image src={line} />
                </View>
            )}
            {type === 'reportOnly' && (
                <View style={{ marginBottom: '5px', marginTop: '125px' }} fixed>
                    {/* <Image src={line} /> */}
                </View>
            )}
        </Fragment>
    ) : (
        <Fragment>
            <View style={styles.header1} fixed>
                {type !== 'observation' && type !== 'reportOnly' && (
                    <View style={{ width: '20%' }}>
                         <HeaderDesign />    
                    </View>
                )}
                {type !== 'observation' && type !== 'reportOnly' && (
                    <View
                        style={
                            //request_type === 'NABL'
                            // ?
                            {
                                width: '38%',
                                marginTop: '25', // old value =27
                                marginRight: '25%',
                            }
                            // : {
                            //       width: '38%',
                            //       marginTop: '27',
                            //       marginRight: '25%',
                            //   }
                        }
                    >
                        <Image src={carelab} />
                    </View>

                    // New Falcon Logo in Future
                    // <View
                    //     style={
                    //         //request_type === 'NABL'
                    //         // ?
                    //         {         
                    //              marginTop: 27,
                    //               marginRight: '20%',
                    //         }
                    //     }
                    // >
                    //     <Image src={newFalconLogo2} />
                    // </View>

                )}
                {type !== 'observation' && type !== 'reportOnly' && (
                    <>
                        <View
                            style={
                                request_type === 'NABL'
                                    ? {
                                          fontSize: 12,
                                          marginTop: '36',
                                          marginRight: '35%', // old value = 20  changed in new 
                                         //marginRight: '25%',//  New Feature in future
                                          color: 'red',
                                          textAlign: 'center',
                                          fontFamily: 'Helvetica-Bold',
                                      }
                                    : {
                                          fontSize: 12,
                                          marginTop: '36',
                                          marginRight: '30%',
                                          color: 'red',
                                          textAlign: 'center',
                                          fontFamily: 'Helvetica-Bold',
                                      }
                            }
                        >
                            {/* <Text>ISO / IEC 17025 : 2005</Text>
                            <Text>NABL ACCREDITED LABORATORY</Text> */}
                            {/* <Text
                                style={{
                                    fontSize: 9,
                                    color: 'blue',
                                    textAlign: 'center',
                                    fontFamily: 'Helvetica-Bold',
                                }}
                            >
                                ( Dept.of Science & Technology, Govt of India )
                            </Text> */}
                        </View>
                        {request_type === 'NABL' && (
                            <View style={{ alignItems: 'center' }}>
                                <Image
                                    style={{
                                        //   width: '58%', 
                                          marginTop: '12',//old value = 26
                                        marginRight: '15%',//old value = 20
                                        //New feature in future
                                    //     width: '66%',
                                    //     marginTop: '26',
                                    //    marginRight: '29%',
                                    }}
                                    src={nabl}
                                />
                                <Text 
                                style={{ marginRight: '10%' }} //pld value = 20%
                                >
                                    {nablNo1}
                                </Text>
                            </View>
                        )}
                    </>
                )}
            </View>
            {type !== 'reportOnly' && (
                <View style={{ marginBottom: 5, marginTop: 3 }} fixed>
                    <Image src={line} />
                </View>
            )}
            {type === 'reportOnly' && (
                <View style={{ marginBottom: 5, marginTop: '140px' }} fixed>
                    {/* <Image src={line} /> */}
                </View>
            )}
        </Fragment>
    )
}

export default Header
