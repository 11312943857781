import {
    Alert,
    Grid,
    Icon,
    IconButton,
    Pagination,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
} from '@mui/material'
import { Box, styled } from '@mui/system'
import { configLicense } from 'Conflit/Features'
import { MatxLoading, SimpleCard } from 'app/components'
import DeleteDilaog from 'app/components/Dilaog/DeleteDilaog'
import PublishDialog from 'app/components/Dilaog/PublishDialog'
import { getMaterialData } from 'app/redux/actions/AppAction'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import {
    deleteArchivedJobById,
    getJobTableData,
    getJobProgressData,
    getSortedJobTableList,
    publishJobByID,
    getReportJobDocument,
} from 'app/redux/actions/JobActions'
import {
    getNotificationJobData,
    getNotificationsData,
} from 'app/redux/actions/NotificationAction'
import { StyledIcon } from 'app/views/jobs/StyledComponents/StyledComponents'
import usePrevious from 'app/views/jobs/shared/method/method'
import { StatusBadge } from 'app/views/test/helpers/helper'
import Cookies from 'js-cookie'
import { debounce } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AllJobPdfTable, JobPdfTable } from './PdfTable'
import { getDocReport } from 'app/assets/Constant/constant'

import { useSearchParams } from 'react-router-dom'
import * as XLSX from 'xlsx'
import { getReportData } from '../../../redux/actions/ReportAction'

const StyledTable = styled(Table)(({ theme }) => ({
    whiteSpace: 'pre',
    minWidth: 850,

    '& thead': {
        '& tr': {
            '& th': {
                paddingLeft: 0,
                paddingRight: 0,
                wordBreak: 'break-word',
                backgroundColor: '#f5f5f5',
                cursor: 'default',
            },
        },
    },
    '& tbody': {
        '& tr': {
            '& td': {
                paddingLeft: 0,
                textTransform: 'capitalize',
                wordBreak: 'break-word',
                cursor: 'default',
            },
        },
    },
}))

const DATE_FILTER_TYPES = [
    'THISMONTH',
    'THISWEEK',
    'THISYEAR',
    'TODAY',
    'YESTERDAY',
    'CUSTOM',
]
const STATUS_TYPES = ['TODO', 'PROGRESS', 'COMPLETE', 'ARCHIVED']
const MATERIAL_ID = 'materialId'
const STATUS_FILTER = 'statusFilter'
const DUE_DATE_FILTER = 'due_dateFilter'
const DATE_FILTER = 'dateFilter'
const PAGE = 'page'

const JobTable = () => {
    // let search = window.location.search
    // let param = new URLSearchParams(search)

    const navigate = useNavigate()
    const role = Cookies.get('userRole')
    const dispatch = useDispatch()
    const { materialNames } = useSelector((state) => state.appReducer)
    let [searchParams, setSearchParams] = useSearchParams()
    let [searchParams1, setSearchParams1] = useSearchParams()
    let type = searchParams.get('type')
    let page_ = parseInt(searchParams.get(PAGE) ?? 1)

    const [sorting, setSorting] = useState({
        job_no: '',
        test: '',
        allocation: '',
        createdAt: '',
        start_date: '',
        due_date: '',
    })
    const [snack, setSnack] = useState({
        snackOpen: false,
        snackVaraint: '',
        snackMsg: '',
    })

    const [value, setValue] = useState({})
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)
    const [dialog, setDialog] = useState(false)
    const [queryParams, setQueryParams] = useState()
    // const [pages, setPages] = useState(1)
    // const [dateFilter, setDateFilter] = useState('THISMONTH')
    const [resData, setResData] = useState()
    const [totalDocs, setTotalDocs] = useState()
    const [view, setView] = useState([])
    const [customStartDate, setCustomStartDate] = useState(null)
    const [customEndDate, setCustomEndDate] = useState(null)
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [orderDirection, setOrderDirection] = useState('desc')
    const [valueOrderBy, setValueOrderBy] = useState('start_date')
    const [loading, setLoading] = useState(true)
    const [query, setQuery] = useState('')
    // const [statusFilter, setStatusFilter] = useState([])
    // const [due_dateFilter, setDue_dateFilter] = useState('THISMONTH')
    const [customDueStartDate, setCustomDueStartDate] = useState(null)
    const [customDueEndDate, setCustomDueEndDate] = useState(null)
    const [showDueDatePicker, setShowDueDatePicker] = useState(false)
    const [countStatus, setCountStatus] = useState({})
    // const [materialId, setMaterialId] = useState('')
    const materialId = searchParams.get(MATERIAL_ID) ?? ''
    const statusFilter = searchParams.get(STATUS_FILTER) ?? ''
    const due_dateFilter = searchParams.get(DUE_DATE_FILTER) ?? 'ALL'
    const dateFilter = searchParams.get(DATE_FILTER) ?? 'THISMONTH'

    const getStatus = () => {
        if (type === 'pending') {
            return 'TODO'
        }
        if (type === 'ongoing') return 'PROGRESS'
        else if (type === 'completed') return 'COMPLETE'
        else if (type === 'paid') return 'PAID'
        else if (type === 'archived') return 'ARCHIVED'
        else return ''
    }

    let user_id = Cookies.get('userId')

    const changeSearchParams = (key, value) => {
        searchParams.set(key, value)
        setSearchParams(searchParams)
    }

    const fetchData = (
        pageNo,
        currentDateFilter,
        currentStartDate,
        currentEndDate,
        currentDueDateFilter,
        currentDueStartDate,
        currentDueEndDate
    ) => {
        setLoading(true)
        let payload = {
            user_id: user_id,
            q: query,
            status: getStatus(),
            page: pageNo,
            materialId,
            size: 10,
            [valueOrderBy]: orderDirection,
            statusFilter: statusFilter,
            dateFilter: currentDateFilter ?? dateFilter,
            startDate: currentStartDate ?? customStartDate,
            endDate: currentEndDate ?? customEndDate,
            due_dateFilter: currentDueDateFilter ?? due_dateFilter,
            dueStartDate: currentDueStartDate ?? customDueStartDate,
            dueEndDate: currentDueEndDate ?? customDueEndDate,
        }
        getJobTableData(
            {
                user_id: user_id,
                q: query,
                status: getStatus(),
                page: pageNo,
                materialId,
                size: 10,
                [valueOrderBy]: orderDirection,
                statusFilter: statusFilter,
                dateFilter: currentDateFilter ?? dateFilter,
                startDate: currentStartDate ?? customStartDate,
                endDate: currentEndDate ?? customEndDate,
                due_dateFilter: currentDueDateFilter ?? due_dateFilter,
                dueStartDate: currentDueStartDate ?? customDueStartDate,
                dueEndDate: currentDueEndDate ?? customDueEndDate,
            },
            (res) => {
                setView(res?.docs)
                setResData(res?.totalPages)
                setTotalDocs(res?.totalDocs)
                // setPages(res?.page ? res?.page : 1)
                changeSearchParams(PAGE, res?.page ?? 1)
                // {
                //     !type &&
                //     pageNo &&
                //     // navigate({
                //     //     pathname: '/job',
                //     //
                //     //     search: `?page=${res?.page ?? 1}`,
                //     // })
                //
                //
                //
                // }
                setLoading(false)
            }
        )
        // if (materialNames?.length == 0) {
        //     dispatch(getMaterialData())
        // }
        getJobProgressData(payload, (res) => {
            setCountStatus(res)
        })
    }

    const prevPage = usePrevious(page_)

    useEffect(() => {
        // if (!view && prevPage !== page_ && pages !== page_) {
        //     fetchData(page_)
        // }
        if (materialNames?.length === 0) {
            dispatch(getMaterialData())
        }
        // if (dateFilter?.length === 0) {
        //     dispatch(getJobTableData())
        // }
        // if (due_dateFilter?.length === 0) {
        //     dispatch(getJobTableData())
        // }
    }, [])

    useEffect(() => {
        // if (materialId) {

        //     fetchData()
        // }
        // // if (statusFilter) {
        // //     fetchData()
        // // }
        // if (dateFilter) {

        //     fetchData()}
        //     if (due_dateFilter) {

        //         fetchData()}
        // else {
        //     fetchData(page_)
        // }

        // setPages(1);
        fetchData(page_)
    }, [
        materialId,
        statusFilter,
        dateFilter,
        due_dateFilter,
        customStartDate,
        customEndDate,
        customDueStartDate,
        customDueEndDate,
    ])
    // useEffect(() => {
    //     if (STATUS_TYPES.includes(statusFilter)) {
    //         handleStatusChange({label: statusFilter});
    //     }
    // }, [statusFilter])
    //
    // useEffect(() => {
    //     //setFilterOpen(true)
    //     if (DATE_FILTER_TYPES.includes(dateFilter)) {
    //         handleDayChange({target: {value: dateFilter}});
    //     }
    // }, [dateFilter]);
    //
    // useEffect(() => {
    //     //setFilterOpen(true)
    //     if (DATE_FILTER_TYPES.includes(due_dateFilter)) {
    //         handleDueDayChange({target: {value: due_dateFilter}});
    //     }
    // }, [due_dateFilter]);

    //  useEffect(() => {
    //     setFilterOpen(true)
    //     if (type === 'pending') {
    //         handleStatusChange({ label: 'TODO' });
    //     }
    //     console.log(type, "type")
    //     if (type === 'ongoing'){
    //         handleStatusChange({ label: 'PROGRESS' });
    //     }
    //     else if (type === 'completed'){
    //         handleStatusChange({ label: 'COMPLETE' });
    //         }
    //     },[type])
    //     console.log(statusFilter,"statusFilter")
    useEffect(() => {
        const statusQueryParam = searchParams.get('statusFilter')
        //setFilterOpen(true)
        if (statusQueryParam === 'TODO') {
            handleStatusChange({ label: 'TODO' })
        }
        //console.log(type, "type")
        if (statusQueryParam === 'PROGRESS') {
            handleStatusChange({ label: 'PROGRESS' })
        } else if (statusQueryParam === 'COMPLETE') {
            handleStatusChange({ label: 'COMPLETE' })
        }
        console.log(statusQueryParam, 'statusQueryParam')
    }, [searchParams])

    useEffect(() => {
        const dateQueryParam = searchParams1.get('dateFilter')
        //setFilterOpen(true)
        if (dateQueryParam === 'THISMONTH') {
            handleDayChange({ target: { value: 'THISMONTH' } })
        }
        if (dateQueryParam === 'THISWEEK') {
            handleDayChange({ target: { value: 'THISWEEK' } })
        }
        if (dateQueryParam === 'THISYEAR') {
            handleDayChange({ target: { value: 'THISYEAR' } })
        }
        if (dateQueryParam === 'TODAY') {
            handleDayChange({ target: { value: 'TODAY' } })
        }
        if (dateQueryParam === 'YESTERDAY') {
            handleDayChange({ target: { value: 'YESTERDAY' } })
        }
        if (dateQueryParam === 'CUSTOM') {
            handleDayChange({ target: { value: 'CUSTOM' } })
        }
    }, [searchParams1])

    const fetchNotification = () => {
        dispatch(
            getNotificationsData({
                user_id: user_id,
                status: 'ARCHIVED',
                visited: false,
                size: 100,
            })
        )
        dispatch(
            getNotificationJobData({
                user_id: user_id,
                status: 'ARCHIVED',
                visited: false,
                size: 100,
            })
        )
    }

    // const handleChangePage = (event) => {
    //     setPages(parseInt(event))
    //     fetchData(parseInt(event))

    // }
    const handleChangePage = (event) => {
        let currentPage = 1
        if (event && parseInt(event)) {
            currentPage = parseInt(event)
        } else if (page_ && parseInt(page_)) {
            currentPage = parseInt(page_)
        }
        changeSearchParams(PAGE, currentPage)
        // setPages(currentPage)
        fetchData(currentPage)
    }

    const createSortHanlder = (property) => (event) => {
        handleRequestSort(event, property)
    }

    const handleRequestSort = (event, property) => {
        const isAscending =
            valueOrderBy === property && orderDirection === 'asc'

        setValueOrderBy(property)
        let newSorting = {
            // ...sorting,
            [property]: sorting[property] === 'asc' ? 'desc' : 'asc',
        }
        setSorting(newSorting)
        setOrderDirection(isAscending ? 'desc' : 'asc')

        getSortedJobTableList(
            {
                [property]: sorting[property] === 'asc' ? 'desc' : 'asc',
                page: page_,
                size: 10,
                status: getStatus(),
                q: query,
                materialId: materialId,
                statusFilter: statusFilter,
                dateFilter: dateFilter,
                due_dateFilter: due_dateFilter,
            },
            (res) => {
                setView(res?.docs)
                setResData(res?.totalPages)
            }
        )
    }

    const OnhandleEdit = (value) => {
        navigate(`/job/edit/${value?._id}`)
    }

    const OnhandleView = (user) => {
        navigate(`/job/view/${user?._id}`)
    }

    const handleDelete = (value, index) => {
        let status = { status: 'ARCHIVED', visited: false }
        let jobId = value._id
        deleteArchivedJobById({ jobId, status }, (res) => {
            let msg = res?.Message
            setOpen(false)
            let _data = view.filter((v, i) => v._id !== value._id)
            setView(_data)
            if (res.status === 200) {
                fetchData(page_)

                fetchNotification()
                setSnack({
                    ...snack,
                    snackOpen: 'true',
                    snackMsg: 'Deleted Successfully',
                    snackVaraint: 'success',
                })
            } else {
                setSnack({
                    ...snack,
                    snackOpen: 'false',
                    snackMsg: msg,
                    snackVaraint: 'error',
                })
            }
        })
    }

    const handlePublish = (value) => {
        publishJobByID(value._id, (res) => {
            let msg = res?.message

            setDialog(false)

            if (res.status === 200) {
                value.publish = true
                fetchData(page_)

                setSnack({
                    ...snack,
                    snackOpen: 'true',
                    snackMsg: msg,
                    snackVaraint: 'success',
                })
            } else {
                setSnack({
                    ...snack,
                    snackOpen: 'false',
                    snackMsg: msg,
                    snackVaraint: 'error',
                })
            }
        })
    }

    const handleSnackClose = () => setSnack(false)

    const handleObservation = (id) => {
        navigate(`/pdf/view/${anchorElJob._id}?type=observation`)
    }

    const handleObservationNull = (id) => {
        navigate(`/pdf/view/${anchorElJob._id}?type=observationNull`)
    }

    const handleOpen = (value) => {
        setOpen(true)
        setValue(value)
    }

    const handleClose = () => setOpen(false)

    const handlePublishOpen = (value) => {
        setDialog(true)
        setValue(value)
    }

    const handlePublishClose = () => setDialog(false)

    const publishBadge = (publish) => {
        if (publish === true)
            return (
                <Tooltip title="Published Job" arrow>
                    <Icon color="success">check_circle</Icon>
                </Tooltip>
            )
        else return <Icon color="action">cancel</Icon>
    }

    //Search handler
    const prevQuery = usePrevious(query)

    const handleSearch = (e) => {
        setQuery(e.target.value)
    }

    useEffect(() => {
        console.log('prevQuery === ', prevQuery)
        console.log('query === ', query)
        if (prevQuery !== query && (prevQuery !== undefined || query !== '')) {
            // setPages(1)
            changeSearchParams(PAGE, 1)
            if (query?.length > 0) {
                fetchData()
            } else {
                fetchData(page_)
            }
        }
    }, [query])

    const debouncedResults = useMemo(() => {
        return debounce(handleSearch, 400)
    }, [])

    useEffect(() => {
        return () => {
            debouncedResults.cancel()
        }
    })

    const getTitle = () => {
        return type === 'ongoing'
            ? 'InProgress Jobs'
            : type === 'completed'
              ? 'Completed Jobs'
              : type === 'totaldocuments'
                ? 'Total'
                : type === 'duedate'
                  ? 'Upcoming/pending Due Date'
                  : type === 'pending'
                    ? 'ToDo Jobs'
                    : type === 'archived'
                      ? 'Deleted Jobs'
                      : 'Jobs'
    }

    const handleFilter = (value) => {
        // setMaterialId(value?._id)
        changeSearchParams(PAGE, 1)
        changeSearchParams(MATERIAL_ID, value?._id ?? '')
    }
    const handleStatusChange = (value) => {
        // setStatusFilter(value?.label || '')
        // changeSearchParams(PAGE, 1)
        searchParams.set(STATUS_FILTER, value?.label || '')
        setSearchParams(searchParams)

        // fetchData(page_, dateFilter, customStartDate, customEndDate);
    }
    const handleReset = () => {
        // setMaterialId('')
        // setStatusFilter('')
        searchParams.set(MATERIAL_ID, '')
        searchParams.set(STATUS_FILTER, '')
        searchParams.set(PAGE, 1)
        setSearchParams(searchParams)
        // setSearchParams2('THISMONTH'); // Set the default value when resetting
        //
        // // Fetch data based on the current state
        // fetchData(page_, 'THISMONTH', customStartDate, customEndDate, 'THISMONTH', customDueStartDate, customDueEndDate);
        // setSelectedDiscipline(null);
        // setSelectedGroup(null);
        // setSelectedSamples(null);
    }
    const handleDayChange = (e) => {
        const selectedMonth = e.target.value
        let startDate = null
        let user_id = Cookies.get('userId')
        // setDateFilter(selectedMonth)
        searchParams.set('dateFilter', selectedMonth)
        if (selectedMonth !== 'CUSTOM') {
            setShowDatePicker(false)
            // setPages(1)
            // searchParams.set(PAGE, 1)
            // fetchData(1, selectedMonth)
        }
        setSearchParams(searchParams)
        // fetchData(page_, statusFilter, dateFilter);
        // setCustomEndDate(null);
        // setCustomStartDate(startDate);
        // dispatch(getRecentTests({ dateFilter: selectedMonth, user_id, startDate,  }));
    }
    const handleDueDayChange = (e) => {
        const selectedDate = e.target.value
        let startDate = null
        let user_id = Cookies.get('userId')
        // setDue_dateFilter(selectedDate)
        searchParams.set(DUE_DATE_FILTER, selectedDate)
        if (selectedDate !== 'CUSTOM') {
            setShowDueDatePicker(false)
            // setPages(1)
            searchParams.set(PAGE, 1)
            // fetchData(1, selectedDate)
        }
        setSearchParams(searchParams)
        // fetchData(page_, statusFilter, due_dateFilter);
        // setCustomEndDate(null);
        // setCustomStartDate(startDate);
        // dispatch(getRecentTests({ dateFilter: selectedMonth, user_id, startDate,  }));
    }
    const handleCustomDateConfirm = (startDate, endDate) => {
        // setDateFilter('CUSTOM')
        searchParams.set(DATE_FILTER, 'CUSTOM')
        searchParams.set(PAGE, 1)
        setSearchParams(searchParams)

        setCustomEndDate(endDate)
        setCustomStartDate(startDate)
        fetchData(1, 'CUSTOM', startDate, endDate)
    }
    const handleCustomDueDateConfirm = (dueStartDate, dueEndDate) => {
        // setDue_dateFilter('CUSTOM')
        searchParams.set('due_dateFilter', 'CUSTOM')
        setSearchParams(searchParams)
        setCustomDueEndDate(dueEndDate)
        setCustomDueStartDate(dueStartDate)
        fetchData(1, 'CUSTOM', dueStartDate, dueEndDate)
    }

    const handlePdf = () => {
        JobPdfTable(view)
    }
    const handleAllPdf = () => {
        if (data.length !== 0) {
            AllJobPdfTable(data)
        }
    }
    const [open_, setOpen_] = useState(false)

    const toggle_ = () => {
        setOpen_(!open_)
        if (totalDocs !== []) {
            getJobTableData(
                {
                    user_id: user_id,
                    q: query,
                    status: getStatus(),
                    materialId,
                    dateFilter,
                    statusFilter,
                    due_dateFilter,
                    size: totalDocs,
                    page: 1,
                    [valueOrderBy]: orderDirection,
                },
                (res) => {
                    setData(res?.docs)
                }
            )
        }
    }
    const [opens, setOpens] = useState(false)
    const toggles = () => {
        setOpens(!opens)
        if (totalDocs !== []) {
            getJobTableData(
                {
                    user_id: user_id,
                    q: query,
                    status: getStatus(),
                    materialId,
                    dateFilter,
                    statusFilter,
                    due_dateFilter,
                    size: totalDocs,
                    page: 1,
                    [valueOrderBy]: orderDirection,
                },
                (res) => {
                    setData(res?.docs)
                }
            )
        }
    }
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorElJob, setAnchorElJob] = useState(null)
    const open1 = Boolean(anchorEl)
    const handleClosed = () => {
        setAnchorEl(null)
    }
    const handleClick = (event, job) => {
        if (job?.material?.name && getDocReport.includes(job?.material?.name)) {
            let jobId = job._id
            let materialId = job?.material?._id
            getReportData({ materialId, jobId }, (res) => {
                if (res) {
                    window.open(res, '_blank')
                }
            })
        } else {
            setAnchorEl(event.currentTarget)
            setAnchorElJob(job)
        }
    }
    const JobExcelTable = (view) => {
        const headers = [
            'Job No',
            'Test',
            'Allocation',
            'CreatedAt',
            'Due Date',
            'Status',
            'Publish Status',
        ]

        const data = view?.map((value) => [
            value.job_no,
            value.material?.name,
            value.allocated_to?.name, /////////
            value.createdAt,
            new Date(value?.due_date).toLocaleDateString(),
            value.status,
            value.publish ? 'True' : 'False',
        ])

        const worksheet = XLSX.utils.json_to_sheet([headers, ...data], {
            skipHeader: true,
        })

        const workbook = {
            Sheets: { data: worksheet },
            SheetNames: ['data'],
        }

        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        })

        const currentDate = new Date()
        const formattedDate = currentDate.toISOString().slice(0, 10)

        const filename = 'Job Table.xlsx'
        saveAsExcel(excelBuffer, filename)
    }

    const saveAsExcel = (buffer, filename) => {
        const data = new Blob([buffer], { type: 'application/octet-stream' })
        const url = window.URL.createObjectURL(data)
        const link = document.createElement('a')

        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }
    const handleExcel = () => {
        JobExcelTable(view)
    }
    const AllJobExcelTable = (data) => {
        const headers = [
            'Job No',
            'Test',
            'Allocation',
            'Create Date',
            'Start Date',
            'Due Date',
            'Status',
            'Publish Status',
        ]

        let allData = []

        data.forEach((value) => {
            const rowData = [
                value.job_no,
                value.material?.name,
                value.allocated_to?.name,
                // value.createdAt,
                new Date(value?.createdAt).toLocaleDateString(),
                new Date(value?.start_date).toLocaleDateString(),
                new Date(value?.due_date).toLocaleDateString(),
                value.status,
                value.publish ? 'True' : 'False',
            ]
            allData.push(rowData)
        })

        const worksheet = XLSX.utils.aoa_to_sheet([headers, ...allData])
        // XLSX.utils.sheet_add_aoa(worksheet, [['All Recent Test Data']], { origin: 'A1' });

        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'All Recent Tests')

        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        })

        const filename = 'all_recent_tests.xlsx'
        saveAsExcelall(excelBuffer, filename)
    }

    const saveAsExcelall = (buffer, filename) => {
        const data = new Blob([buffer], { type: 'application/octet-stream' })
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // For IE
            window.navigator.msSaveOrOpenBlob(data, filename)
        } else {
            const url = window.URL.createObjectURL(data)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    }
    const handleAllExcel = () => {
        if (data.length !== 0) {
            AllJobExcelTable(data)
        }
    }

    return (
        <SimpleCard
            title={getTitle()}
            isStatusEnable={true}
            isTotalDocsEnable={true}
            total={totalDocs}
            countstatus={countStatus}
            handleSearch={debouncedResults}
            label={'Job No. '}
            suggestions={materialNames}
            materialId={materialId}
            handleFilter={handleFilter}
            handleStatusChange={handleStatusChange}
            handleDueDayChange={handleDueDayChange}
            handleDayChange={handleDayChange}
            handleCustomDueDateConfirm={handleCustomDueDateConfirm}
            handleCustomDateConfirm={handleCustomDateConfirm}
            handleReset={handleReset}
            filterLabel={'Test'}
            filterLabel7={'Status'}
            data={view}
            handlePdf={handlePdf}
            handleAllPdf={handleAllPdf}
            open_={open_}
            toggle_={toggle_}
            handleExcel={handleExcel}
            handleAllExcel={handleAllExcel}
            toggles={toggles}
            opens={opens}
            isfilterOpen={
                searchParams.get(MATERIAL_ID) || searchParams.get(STATUS_FILTER)
            }
        >
            {totalDocs === 0 ? (
                <Alert severity="error">No Data Available</Alert>
            ) : loading ? (
                <MatxLoading />
            ) : (
                <Box width="100%">
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            height: '100%',

                            overflowX: 'auto',
                        }}
                    >
                        {/* <Button onClick={() => handlePdf()}>Generate Report</Button> */}

                        <StyledTable>
                            <TableHead>
                                <TableRow hover>
                                    <TableCell align="center" key={'job_no'}>
                                        <TableSortLabel
                                            active={sorting.job_no}
                                            direction={
                                                sorting.job_no
                                                    ? sorting.job_no
                                                    : 'asc'
                                            }
                                            onClick={createSortHanlder(
                                                'job_no'
                                            )}
                                        >
                                            Job No{' '}
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell align="center">Test</TableCell>
                                    <TableCell align="center">
                                        Allocation
                                    </TableCell>
                                    <TableCell align="center" key={'createdAt'}>
                                        <TableSortLabel
                                            active={sorting.createdAt}
                                            direction={
                                                sorting.createdAt
                                                    ? sorting.createdAt
                                                    : 'asc'
                                            }
                                            onClick={createSortHanlder(
                                                'createdAt'
                                            )}
                                        >
                                            Create Date
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell
                                        align="center"
                                        key={'start_date'}
                                    >
                                        <TableSortLabel
                                            active={sorting.start_date}
                                            direction={
                                                sorting.start_date
                                                    ? sorting.start_date
                                                    : 'asc'
                                            }
                                            onClick={createSortHanlder(
                                                'start_date'
                                            )}
                                        >
                                            Start Date
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell align="center" key={'due_date'}>
                                        <TableSortLabel
                                            active={sorting.due_date}
                                            direction={
                                                sorting.due_date
                                                    ? sorting.due_date
                                                    : 'asc'
                                            }
                                            onClick={createSortHanlder(
                                                'due_date'
                                            )}
                                        >
                                            Due Date
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell align="center">Status</TableCell>
                                    {configLicense.clientPublishing ===
                                        true && (
                                        <TableCell align="center">
                                            Publish Status
                                        </TableCell>
                                    )}
                                    <TableCell align="center">
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {view &&
                                view.map((value, index) => {
                                    let tableDueDate = new Date(
                                        value?.due_date
                                    ).toLocaleDateString()
                                    let tableStartDate = new Date(
                                        value?.start_date
                                    ).toLocaleDateString()
                                    let tableCreateDate = new Date(
                                        value?.createdAt
                                    )
                                        .toLocaleDateString()
                                        .slice(0, 10)

                                    return (
                                        <TableBody key={index}>
                                            <TableRow hover>
                                                <TableCell align="center">
                                                    {value.job_no}
                                                </TableCell>
                                                {/* <TableCell align="Right">
                                        {test.sample}
                                    </TableCell> */}

                                                <TableCell align="center">
                                                    {value.material?.name
                                                        ? value.material?.name
                                                        : '--'}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {value.allocated_to?.name
                                                        ? value.allocated_to
                                                              ?.name
                                                        : '--'}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {tableCreateDate}

                                                    {/* {value.createdAt ? value.createdAt  : '--'} */}
                                                </TableCell>

                                                {value.status !== 'TODO' ? (
                                                    <TableCell align="center">
                                                        {tableStartDate !==
                                                        'Invalid Date'
                                                            ? tableStartDate
                                                            : 'Not Yet Started'}
                                                    </TableCell>
                                                ) : (
                                                    <TableCell align="center">
                                                        Not Yet Started
                                                    </TableCell>
                                                )}

                                                <TableCell align="center">
                                                    {tableDueDate}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {StatusBadge(value.status)}
                                                </TableCell>
                                                {configLicense.clientPublishing ===
                                                    true && (
                                                    <TableCell align="center">
                                                        {publishBadge(
                                                            value?.publish
                                                        )}
                                                    </TableCell>
                                                )}

                                                <TableCell align="center">
                                                    <Tooltip title="View" arrow>
                                                        <IconButton
                                                            onClick={(e) => {
                                                                OnhandleView(
                                                                    value
                                                                )
                                                            }}
                                                            data-testid="JobView"
                                                            id={index}
                                                        >
                                                            <StyledIcon>
                                                                visibility
                                                            </StyledIcon>
                                                        </IconButton>
                                                    </Tooltip>
                                                    {role !== 'CUSTOMER' &&
                                                    !(
                                                        value.status ===
                                                            'APPROVED' ||
                                                        value.status ===
                                                            'PAID' ||
                                                        value?.status ==
                                                            'ARCHIVED'
                                                    ) ? (
                                                        <Tooltip
                                                            title="Edit"
                                                            arrow
                                                        >
                                                            <IconButton
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    OnhandleEdit(
                                                                        value
                                                                    )
                                                                }}
                                                                data-testid="JobEdit"
                                                                id={index}
                                                            >
                                                                <StyledIcon>
                                                                    edit
                                                                </StyledIcon>
                                                            </IconButton>
                                                        </Tooltip>
                                                    ) : (
                                                        role !== 'CUSTOMER' && (
                                                            <IconButton
                                                                disabled
                                                            >
                                                                <Icon></Icon>
                                                            </IconButton>
                                                        )
                                                    )}
                                                    {!(
                                                        role === 'CUSTOMER' ||
                                                        value.status ===
                                                            'ARCHIVED'
                                                    ) && (
                                                        <Tooltip
                                                            title="Delete"
                                                            arrow
                                                        >
                                                            <IconButton
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    handleOpen(
                                                                        value
                                                                    )
                                                                }}
                                                                disabled={
                                                                    !(
                                                                        value.status ===
                                                                            'TODO' ||
                                                                        value.status ===
                                                                            'PROGRESS'
                                                                    )
                                                                }
                                                                color="primary"
                                                                data-testid="JobDelete"
                                                                id={index}
                                                            >
                                                                <Icon>
                                                                    delete
                                                                </Icon>
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}

                                                    {!(
                                                        value?.status ==
                                                        'ARCHIVED'
                                                    ) && (
                                                        <Tooltip
                                                            title="Observation"
                                                            arrow
                                                        >
                                                            <IconButton
                                                                data-testid="JobObservation"
                                                                // onClick={(
                                                                //     e
                                                                // ) => {
                                                                //     handleClick(
                                                                //         value
                                                                //     )
                                                                // }}
                                                                onClick={(e) =>
                                                                    handleClick(
                                                                        e,

                                                                        value
                                                                    )
                                                                }
                                                                disabled={
                                                                    value.status ===
                                                                    'TODO'
                                                                        ? 'TODO'
                                                                        : getDocReport.includes(
                                                                                value
                                                                                    .material
                                                                                    ?.name
                                                                            ) ===
                                                                            true
                                                                          ? true
                                                                          : false
                                                                }
                                                                color="primary"
                                                                id={index}
                                                            >
                                                                <Icon>
                                                                    description
                                                                </Icon>
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                    <Menu
                                                        id="basic-menu"
                                                        anchorEl={anchorEl}
                                                        open={open1}
                                                        onClose={handleClosed}
                                                        PaperProps={{
                                                            elevation: 0,
                                                            sx: {
                                                                overflow:
                                                                    'visible',
                                                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
                                                                mt: 1.5,
                                                                '& .MuiAvatar-root':
                                                                    {
                                                                        width: 32,
                                                                        height: 32,
                                                                        ml: -0.5,
                                                                        mr: 1,
                                                                    },
                                                                '&:before': {
                                                                    content:
                                                                        '""',
                                                                    display:
                                                                        'block',
                                                                    position:
                                                                        'absolute',
                                                                    top: 0,
                                                                    right: 14,
                                                                    width: 10,
                                                                    height: 10,
                                                                    bgcolor:
                                                                        'background.paper',
                                                                    transform:
                                                                        'translateY(-50%) rotate(45deg)',
                                                                    zIndex: 0,
                                                                },
                                                            },
                                                        }}
                                                        transformOrigin={{
                                                            horizontal: 'right',
                                                            vertical: 'top',
                                                        }}
                                                        anchorOrigin={{
                                                            horizontal: 'right',
                                                            vertical: 'bottom',
                                                        }}
                                                        key={`Observation_menu_${value._id}`}
                                                    >
                                                        <MenuItem
                                                            sx={{
                                                                color: 'primary.main',
                                                            }}
                                                            key={`Observation_${value._id}`}
                                                            onClick={
                                                                handleObservation
                                                            }
                                                        >
                                                            Observation
                                                        </MenuItem>
                                                        <MenuItem
                                                            sx={{
                                                                color: 'primary.main',
                                                            }}
                                                            key={`Observation-Null_${value._id}`}
                                                            onClick={
                                                                handleObservationNull
                                                            }
                                                        >
                                                            Observation Null
                                                        </MenuItem>
                                                    </Menu>

                                                    {(role === 'ADMIN' || role === 'SUPER_ADMIN') &&
                                                        configLicense.clientPublishing ===
                                                            true &&
                                                        (value.status ===
                                                            'APPROVED' ||
                                                        value.status ===
                                                            'PAID' ||
                                                        value.status ===
                                                            'COMPLETE' ? (
                                                            <Tooltip
                                                                title="Publish"
                                                                arrow
                                                            >
                                                                <IconButton
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        handlePublishOpen(
                                                                            value
                                                                        )
                                                                    }}
                                                                    disabled={
                                                                        value.status ===
                                                                            'TODO' ||
                                                                        value.status ===
                                                                            'PROGRESS' ||
                                                                        value.publish ===
                                                                            true
                                                                    }
                                                                    color="primary"
                                                                    data-testid="JobPublish"
                                                                    id={index}
                                                                >
                                                                    <Icon>
                                                                        publish
                                                                    </Icon>
                                                                </IconButton>
                                                            </Tooltip>
                                                        ) : (
                                                            <IconButton
                                                                disabled
                                                            >
                                                                <Icon></Icon>
                                                            </IconButton>
                                                        ))}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )
                                })}
                        </StyledTable>

                        {/* Delete Dialogue */}
                        <DeleteDilaog
                            value={value}
                            open={open}
                            handleClose={handleClose}
                            handleDelete={handleDelete}
                        />

                        {/* Publish Dialog */}
                        <PublishDialog
                            value={value}
                            dialog={dialog}
                            title={'Are you sure you want to Publish this Job?'}
                            handlePublishClose={handlePublishClose}
                            handlePublish={handlePublish}
                        />

                        <Snackbar
                            open={snack.snackOpen}
                            severity={snack.snackVaraint}
                            autoHideDuration={2000}
                            onClose={() =>
                                setSnack({
                                    ...snack,
                                    snackOpen: false,
                                })
                            }
                        >
                            <Alert
                                onClose={handleSnackClose}
                                severity="success"
                            >
                                {snack.snackMsg}
                            </Alert>
                        </Snackbar>

                        <Grid item>
                            <Pagination
                                onChange={(e, val) => handleChangePage(val)}
                                page={page_}
                                count={resData}
                                variant="text"
                                // shape="rounded"
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                </Box>
            )}
        </SimpleCard>
    )
}

export default JobTable
