import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@mui/material'
import { Transition } from 'app/views/jobs/StyledComponents/StyledComponents'
import React from 'react'

const DeleteDilaog = ({
    value,
    open,
    handleClose,
    handleDelete,
    title,
    index,
}) => {
    return (
        <Dialog
            // fullScreen={fullScreen}
            value={value ?? null}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <DialogContent>
                <DialogContentText>
                    {title ?? ' Are you sure you want to delete?'}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    onClick={(e) => handleDelete(value, index)}
                    autoFocus
                    variant="contained"
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteDilaog
