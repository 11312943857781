import {
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const AbrasionResistanceTable = ({
    data,
    setData,
    setFinalData,
    finalData,
    result,
    setOverall,
    overall,
}) => {
    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        let dryingShrinkage = 0
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && item.data.length > 0) {
                _data.forEach((item1) => {
                    let l1 = Number(
                        item1.dry_len * item1.dry_breadth * item1.dry_height
                    )
                    let l2 = Number(
                        item1.wet_len * item1.wet_breadth * item1.wet_height
                    )
                    let value = ((l1 - l2) / item1.len_specimen) * 100
                    dryingShrinkage += Number(value)
                })
            }
            if (item.groupName === result.groupName) {
                item.data = _data
                item.overall = {
                    ...overall,
                    dryingShrinkage: valueCalc(dryingShrinkage),
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
    }

    const Column = [
        { text: 'Length, L (mm)', dataField: 'length' },
        { text: 'Breadth, B (mm)', dataField: 'breadth' },
        {
            text: 'Height, H (mm)',
            dataField: 'height',
        },
    ]

    return (
        <StyledTable>
            <TableHead>
                <TableRow>
                    <TableCell align="center" rowSpan={2} colSpan={1}>
                        SI.No
                    </TableCell>
                    {/* <TableCell align="center" rowSpan={2} colSpan={2}>
                        Sample ID
                    </TableCell> */}
                    <TableCell align="center" rowSpan={2} colSpan={2}>
                        Loss in Mass after 16 cycles (g)
                    </TableCell>
                    <TableCell colSpan={6} align="center">
                        Volume of the specimen (mm³)
                    </TableCell>
                    <TableCell align={'center'} rowSpan={2} colSpan={2}>
                        Density Input
                    </TableCell>
                    <TableCell align={'center'} rowSpan={2} colSpan={2}>
                        Density of the specimen (g/mm³)
                    </TableCell>
                    <TableCell align={'center'} rowSpan={2} colSpan={2}>
                        Abrasion Resistance (mm³ per 5000 mm²)
                    </TableCell>
                    <TableCell align={'center'} rowSpan={2}>
                        Actions
                    </TableCell>
                </TableRow>
                <TableRow>
                    {Column.map((item, index) => (
                        <TableCell colSpan={2} align={'center'} key={index}>
                            {item.text}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data?.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell align="center" colSpan={1}>
                            {index + 1}
                        </TableCell>
                        {/* <TableCell align="center" colSpan={2}>
                            {item.sampleId}
                        </TableCell> */}
                        <TableCell align="center" colSpan={2}>
                            {item.mass}
                        </TableCell>
                        {Column.map((subItem, idx) => (
                            <TableCell
                                align="center"
                                colSpan={2}
                                key={`row-${idx}-index`}
                            >
                                {item[subItem.dataField]}
                            </TableCell>
                        ))}
                        <TableCell align="center" colSpan={2}>
                            {valueCalc(item.density_input)}
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                            {valueCalc(item.density_specimen)}
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                            {item.abrasion_resistance}
                        </TableCell>
                        <TableCell align="center">
                            <Button onClick={(e) => handleDelete(index, e)}>
                                <Icon>delete</Icon>
                            </Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </StyledTable>
    )
}

export default AbrasionResistanceTable
