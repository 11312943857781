import {
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material'
import { Box } from '@mui/system'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import React from 'react'

const EfflorescenceTable = (props) => {
    const {
        data,
        setData,
        setFinalData,
        finalData,
        result,
        overall,
        setOverall,
    } = props

    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        let avg = 0
        _finalData.forEach((item) => {
            if (item?.groupName === result?.groupName && _data.length !== 0) {
                _data?.forEach((item1) => {
                    let averageDeposition = item1?.efflorescence
                    avg += averageDeposition / _data.length
                })
            }
            if (item.groupName === result.groupName) {
                item.data = _data
                item.overall = {
                    ...overall,
                    average: avg,
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
    }
    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Sample ID</TableCell>
                        <TableCell align="center">Length , L (mm)</TableCell>
                        <TableCell align="center">Breadth , B (mm)</TableCell>
                        <TableCell align="center">Height, H (mm)</TableCell>
                        {/* <TableCell align="center">Final deposition of salts after removal of immence in %</TableCell>
                        <TableCell align="center">Average salt deposition in %</TableCell> */}
                        {/* <TableCell align="center">
                            Determination of Efflorescence
                        </TableCell> */}
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((data, index) => (
                        <TableRow>
                            <TableCell align="center">
                                {(data.sampleId = index + 1)}
                            </TableCell>
                            <TableCell align="center">{data.len}</TableCell>
                            <TableCell align="center">{data.breadth}</TableCell>
                            <TableCell align="center">{data.height}</TableCell>
                            {/* <TableCell align="center">
                                    {data.final_deposition}
                                </TableCell>
                                <TableCell align="center">
                                    {data.average_deposition}
                                </TableCell>
                                <TableCell align="center">
                                    {data.efflorescence}
                                </TableCell> */}
                            <TableCell align="center">
                                <Button onClick={(e) => handleDelete(index, e)}>
                                    <Icon>delete</Icon>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default EfflorescenceTable
