import { Fragment, useContext, useState } from 'react'

import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Modal,
    TextField,
} from '@mui/material'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { AppContext } from 'app/contexts/AppContext'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { valueCalc } from '../../method/method'
import ConcreteCubeTable from './ConcreteCubeTable'

const JOBCARD007C = (props) => {
    const { finalData, setFinalData, EditDetails } = useContext(AppContext)

    const {
        result,
        isView,
        overall,
        setOverall,
        handleCustomRequirementChange,
        handleRequirementChange,
    } = props

    const initialValues = {
        cubeId: EditDetails?.data?.cube ? EditDetails?.data?.cube : ' ',
        len: 150,
        breadth: 150,
        height: 150,
        weight: '',
        area: '',
        compressiveLoad: '',
        compressiveStrength: '',
    }

    const validationSchema = Yup.object().shape({
        cubeId: Yup.string().required('Required'),
        compressiveLoad: Yup.number().required('Required'),
    })

    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [initialState, setInitialState] = useState({ ...initialValues })

    const [data, setData] = useState(result?.data ? result?.data : [])
    // const [overall, setOverall] = useState(
    //     result?.overall ? result?.overall : {}
    // )

    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && arr.length !== 0) {
                let average = 0
                arr.forEach((item1) => {
                    let value = valueCalc(
                        (item1.compressiveLoad / (item1.len * item1.breadth)) *
                            1000
                    )
                    item1.compressiveStrength = value
                    average += Number(value / arr.length)
                })

                item.overall = {
                    ...overall,
                    average: Number(average).toFixed(3),
                }
                setOverall(item.overall)
            }
            if (item.groupName === result.groupName) {
                item.data = arr
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
    }
    return (
        <Fragment>
            <Grid item>
                <Formik
                    initialValues={initialState}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        } = props
                        return (
                            <Form>
                                <Grid
                                    container
                                    spacing={5}
                                    sx={{ pt: 1, mb: 2 }}
                                    justifyContent="space-evenly"
                                >
                                    <Grid item xs={3}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                        >
                                            <DatePicker
                                                disabled
                                                label="Date of Casting"
                                                inputFormat="dd/MM/yyyy"
                                                value={
                                                    EditDetails?.data
                                                        ?.castingDate
                                                        ? EditDetails?.data
                                                              ?.castingDate
                                                        : '-'
                                                }
                                                name="doc"
                                                // onChange={onhandleDateOfCasting}
                                                error={
                                                    errors.doc && touched.doc
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        size="small"
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>

                                        <HelperText>
                                            {errors.doc && touched.doc
                                                ? errors.doc
                                                : ''}
                                        </HelperText>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            size="small"
                                            disabled
                                            id="outlined-basic"
                                            required
                                            fullWidth
                                            label="Age (Days)"
                                            name="age"
                                            value={
                                                EditDetails?.data?.testingDate
                                                    ? EditDetails?.data
                                                          ?.testingDate
                                                    : '-'
                                            }
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.age && touched.age}
                                        />
                                        <HelperText>
                                            {errors.age && touched.age
                                                ? errors.age
                                                : ''}
                                        </HelperText>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                        >
                                            <DatePicker
                                                disabled
                                                label="Date of Testing"
                                                inputFormat="dd/MM/yyyy"
                                                value={
                                                    EditDetails?.data
                                                        ?.dateofTesting
                                                        ? EditDetails?.data
                                                              ?.dateofTesting
                                                        : '-'
                                                }
                                                name="dot"
                                                // onChange={handleChange}
                                                // onBlur={handleBlur}
                                                // error={
                                                //     errors.dot && touched.dot
                                                // }
                                                renderInput={(params) => (
                                                    <TextField
                                                        size="small"
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>

                                        <HelperText>
                                            {errors.dot && touched.dot
                                                ? errors.dot
                                                : ''}
                                        </HelperText>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <JobAccordion
                                        title={result?.groupName}
                                        requirementvalue={overall?.requirements}
                                        remarksValue={overall?.remarks}
                                        handleCustomRequirementChange={
                                            handleCustomRequirementChange
                                        }
                                        handleRequirementChange={
                                            handleRequirementChange
                                        }
                                        overall={overall}
                                        avgLabel={'Average'}
                                        averagevalue={overall?.average}
                                    >
                                        <Grid container spacing={2}>
                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                            >
                                                <StyledCard>
                                                    <CardHeader
                                                        title={
                                                            result?.groupName
                                                        }
                                                    />
                                                    <CardContent
                                                        sx={{
                                                            m: 4,
                                                        }}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={4}
                                                        >
                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    // disabled
                                                                    name="cubeId"
                                                                    value={
                                                                        values.cubeId
                                                                    }
                                                                    label="Cube ID"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.cubeId &&
                                                                        touched.cubeId
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.cubeId &&
                                                                    touched.cubeId
                                                                        ? errors.cubeId
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    value={
                                                                        values.len
                                                                    }
                                                                    name="len"
                                                                    label="Length"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.len &&
                                                                        touched.len
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.len &&
                                                                    touched.len
                                                                        ? errors.len
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    value={
                                                                        values.breadth
                                                                    }
                                                                    name="breadth"
                                                                    label="Breadth"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.breadth &&
                                                                        touched.breadth
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.breadth &&
                                                                    touched.breadth
                                                                        ? errors.breadth
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    value={
                                                                        values.height
                                                                    }
                                                                    name="height"
                                                                    label="Height"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.height &&
                                                                        touched.height
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.height &&
                                                                    touched.height
                                                                        ? errors.height
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    value={
                                                                        values.weight
                                                                    }
                                                                    name="weight"
                                                                    label="Weight"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.weight &&
                                                                        touched.weight
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.weight &&
                                                                    touched.weight
                                                                        ? errors.weight
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={8}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    value={
                                                                        values.compressiveLoad
                                                                    }
                                                                    name="compressiveLoad"
                                                                    label="Compressive Load, F (kN)"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.compressiveLoad &&
                                                                        touched.compressiveLoad
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.compressiveLoad &&
                                                                    touched.compressiveLoad
                                                                        ? errors.compressiveLoad
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button
                                                            size="small"
                                                            onClick={
                                                                handleClose
                                                            }
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            size="small"
                                                            variant="contained"
                                                            type="submit"
                                                            onClick={
                                                                handleSubmit
                                                            }
                                                        >
                                                            Save
                                                        </Button>
                                                    </CardActions>
                                                </StyledCard>
                                            </Modal>

                                            {data && (
                                                <Grid item>
                                                    <ConcreteCubeTable
                                                        data={data}
                                                        setData={setData}
                                                        setFinalData={
                                                            setFinalData
                                                        }
                                                        result={result}
                                                        finalData={finalData}
                                                        setOverall={setOverall}
                                                        overall={overall}
                                                    />
                                                </Grid>
                                            )}

                                            {isView !== true && (
                                                <Grid
                                                    item
                                                    container
                                                    justifyContent="end"
                                                >
                                                    <Button
                                                        sx={{
                                                            boxShadow: 'none',
                                                        }}
                                                        variant="contained"
                                                        endIcon={<AddCircle />}
                                                        onClick={handleOpen}
                                                        size="small"
                                                    >
                                                        Add
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </JobAccordion>
                                </Grid>
                            </Form>
                        )
                    }}
                </Formik>
            </Grid>
        </Fragment>
    )
}

export default JOBCARD007C
