import { Edit } from '@mui/icons-material'
import { Grid, IconButton } from '@mui/material'
import { Fragment, useContext, useState } from 'react'

import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Modal,
    TextField,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'
import {
    HelperText,
    StyledCard,
    StyledIcon,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import SlumpTable from './SLumpTable'

const initialValues = {
    slump: '',
}

const validationSchema = Yup.object().shape({
    slump: Yup.number().required('Required'),
})

const Slump = ({
    result,
    isView,
    overall,
    setOverall,
    handleCustomRequirementChange,
    handleRequirementChange,
}) => {
    const handleClose = () => setOpen(false)

    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)
    const [data, setData] = useState(result.overall ? result.overall : {})
    const [initialState, setInitialState] = useState({ ...initialValues })
    const { finalData, setFinalData } = useContext(AppContext)
    const handleSubmit = (values, { resetForm }) => {
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName) {
                item.overall = {
                    ...values,
                    requirements: overall.requirements,
                    remarks: overall.remarks,
                    requirementStatus: overall.requirementStatus,
                    remarkStatus: overall?.remarkStatus,
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData(values)
        setInitialState({ ...initialValues })
        setOpen(false)
    }
    const handleDeleteTable = () => {
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName) {
                item.overall = {
                    requirements: overall.requirements,
                    requirementStatus: overall.requirementStatus,
                    remarkStatus: overall?.remarkStatus,
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData({ ...initialValues })
    }

    let length = Object.keys(result.overall).length

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <JobAccordion
                        title={result?.groupName}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        requirementvalue={overall?.requirements}
                        remarksValue={overall?.remarks}
                        overall={overall}
                    >
                        <Grid item xs={12}>
                            <Formik
                                initialValues={initialState}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {(props) => {
                                    const {
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    } = props
                                    return (
                                        <Form>
                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                            >
                                                <StyledCard>
                                                    <CardHeader
                                                        title={
                                                            result?.groupName
                                                        }
                                                    />
                                                    <CardContent sx={{ m: 4 }}>
                                                        <Grid
                                                            container
                                                            spacing={4}
                                                        >
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Slump"
                                                                    value={
                                                                        values.slump
                                                                    }
                                                                    name="slump"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.slump &&
                                                                        touched.slump
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.slump &&
                                                                    touched.slump
                                                                        ? errors.slump
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button
                                                            size="small"
                                                            onClick={
                                                                handleClose
                                                            }
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            size="small"
                                                            variant="contained"
                                                            onClick={
                                                                handleSubmit
                                                            }
                                                        >
                                                            Save
                                                        </Button>
                                                    </CardActions>
                                                </StyledCard>
                                            </Modal>
                                        </Form>
                                    )
                                }}
                            </Formik>
                            {data && (
                                <SlumpTable
                                    data={data}
                                    setData={setData}
                                    setFinalData={setFinalData}
                                    finalData={finalData}
                                    result={result}
                                />
                            )}
                            {isView !== true && (
                                <Grid container justifyContent="end">
                                    <IconButton
                                        size="small"
                                        onClick={(e) => handleDeleteTable()}
                                        sx={{
                                            boxShadow: 'none',
                                            mt: 2,
                                        }}
                                    >
                                        <StyledIcon>delete</StyledIcon>
                                    </IconButton>
                                    {length > 4 ? (
                                        <Button
                                            sx={{
                                                boxShadow: 'none',
                                                mt: 2,
                                            }}
                                            variant="contained"
                                            endIcon={<Edit />}
                                            onClick={handleOpen}
                                            size="small"
                                        >
                                            Edit
                                        </Button>
                                    ) : (
                                        <Button
                                            sx={{
                                                boxShadow: 'none',
                                                mt: 2,
                                            }}
                                            variant="contained"
                                            endIcon={<AddCircle />}
                                            onClick={handleOpen}
                                            size="small"
                                        >
                                            Add
                                        </Button>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </JobAccordion>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default Slump
