import { useContext, useState } from 'react'

import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import SieveAnalysisTable from './SieveAnalysisTable'
import { number } from 'prop-types'

const initialValues = {
    // is_sieve_designation: '',
    //passing:'',
    weightRetainedInput: '',
    weightRetained: '',
}

const validationSchema = Yup.object().shape({
    // is_sieve_designation: Yup.number().required('Required'),
    weightRetainedInput: Yup.number().required('Required'),
    // weightRetained: Yup.number().required('Required'),
})
const SieveAnalysisModal = ({ result, isView, setOverall, overall }) => {
    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)
    const [designation, setDesignation] = useState('')
    const [open, setOpen] = useState(false)
    const [data, setData] = useState(result.data ? result.data : [])
    const [gradeAndSize, setGradeAndSize] = useState(result?.gradeAndSize ?? [])
    const [keyAndLabel, setKeyAndLabel] = useState(result?.keyAndLabel ?? [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const { finalData, setFinalData } = useContext(AppContext)

    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]

        let valueData = applyValue(values)

        let addedweightretained = 0
        let cumulativeWeightRetained = 0

        arr.push(valueData)
        arr = applyFormula(arr)
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && arr.length !== 0) {
                let finenessModulus = 0
                arr.forEach((item1) => {
                    addedweightretained += Number.parseFloat(
                        item1.weightRetainedInput
                    )

                    cumulativeWeightRetained += Number.parseFloat(
                        item1.weightRetainedInput
                    )
                    item1.cumulativeWeightRetained =
                        cumulativeWeightRetained.toString()
                    // item1.cumulativeWeightRetained =
                    //     item1?.weightRetainedInput === '0'
                    //         ? item1.weightRetainedInput
                    //         : cumulativeWeightRetained
                })
                arr.forEach((item1) => {
                    item1.weightRetained =
                        item1.cumulativeWeightRetained === '0'
                            ? item1.cumulativeWeightRetained
                            : (
                                  (item1.cumulativeWeightRetained /
                                      addedweightretained) *
                                  100
                              ).toFixed(2)

                    item1.passing = (100 - item1?.weightRetained).toFixed(2)

                    let value = Number(item1.passing)
                    // item1.cumulative_weight_passing = Number(value.toFixed(4));
                    finenessModulus += Number(value / 100)
                })
                item.overall = {
                    ...overall,
                    finenessModulus: finenessModulus
                        ? Number(finenessModulus).toFixed(2)
                        : '0',
                }
                setOverall(item.overall)
            }

            if (item.groupName === result.groupName) {
                item.data = arr
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
        // resetForm()
    }

    const applyValue = (values) => {
        let curr = gradeAndSize?.find(
            (item) => item.sieveDesignation === values?.is_sieve_designation
        )
        if (curr)
            values = {
                ...values,
                ...curr,
            }
        return values
    }

    const applyFormula = (arr) => {
        arr.forEach((item, index) => {
            //  item.passing = (100 - item?.weightRetained).toFixed(2)
        })
        return arr
    }

    const handleChangeDesignation = (e) => {
        setDesignation(e.target.value)
    }
    return (
        <Grid item xs={12}>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props
                    return (
                        <Form>
                            <Modal open={open} onClose={handleClose}>
                                <StyledCard>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <CardHeader
                                            title={
                                                result?.groupName ??
                                                'Sieve Analysis'
                                            }
                                        />
                                        <CardContent>
                                            <Grid container spacing={4}>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={8}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">
                                                            IS Sieve Designation
                                                        </InputLabel>
                                                        <Select
                                                            fullWidth
                                                            labelId="Is Sieve Designation"
                                                            id="Is Sieve Designation"
                                                            value={designation}
                                                            name="is_sieve_designation"
                                                            label="Is Sieve Designation"
                                                            onChange={(e) => {
                                                                handleChange(e)
                                                                handleChangeDesignation(
                                                                    e
                                                                )
                                                            }}
                                                        >
                                                            {gradeAndSize?.map(
                                                                (
                                                                    value,
                                                                    idx
                                                                ) => (
                                                                    <MenuItem
                                                                        value={
                                                                            value.sieveDesignation
                                                                        }
                                                                    >
                                                                        {
                                                                            value.sieveDesignation
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={8}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label="weightRetainedInput"
                                                        value={
                                                            values.weightRetainedInput
                                                        }
                                                        name="weightRetainedInput"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={
                                                            errors.weightRetainedInput &&
                                                            touched.weightRetainedInput
                                                        }
                                                    />
                                                    <HelperText>
                                                        {errors.weightRetainedInput &&
                                                        touched.weightRetainedInput
                                                            ? errors.weightRetainedInput
                                                            : ''}
                                                    </HelperText>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <CardActions>
                                            <Button
                                                size="small"
                                                onClick={handleClose}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                onClick={handleSubmit}
                                            >
                                                Save
                                            </Button>
                                        </CardActions>
                                    </Grid>
                                </StyledCard>
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
            {data && (
                <SieveAnalysisTable
                    data={data}
                    setData={setData}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    result={result}
                    overall={overall}
                    setOverall={setOverall}
                    keyAndLabel={keyAndLabel}
                />
            )}
            {isView !== true && (
                <Grid container justifyContent="end">
                    <Button
                        sx={{ boxShadow: 'none', mt: 2 }}
                        variant="contained"
                        endIcon={<AddCircle />}
                        onClick={handleOpen}
                        size="small"
                    >
                        Add
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default SieveAnalysisModal
