import {
    Button,
    Grid,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { LargeStyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const SandReplacementMethodTable = ({
    data,
    setData,
    setFinalData,
    finalData,
    result,
    setOverall,
    overall,
}) => {
    const handleDelete = (index, e) => {
        let dry_density_arr = []
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let Compaction_arr = []
        let avg = 0
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.data.length > 0) {
                dry_density_arr = _data.map(
                    (item1) => item1.dry_density && valueCalc(item1.dry_density)
                )
                Compaction_arr = _data.map(
                    (item1) => item1.compaction && valueCalc(item1.compaction)
                )
                Compaction_arr.forEach((value) => {
                    avg += parseFloat(value) / _data.length // Assuming values are strings representing numbers
                })
            }
            if (item.groupName === result.groupName) {
                item.data = _data
                item.overall = {
                    ...overall,
                    average: avg,
                    maximunDryDensity: Math.max(...dry_density_arr),
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
    }

    const calibration = [
        {
            text: 'Wt of Sand + Cylinder before Poring, W1 (kg)',
            dataField: 'wt_sand_cylinder_before',
        },
        {
            text: 'Mean wt of Sand in Cone, W2 (kg)',
            dataField: 'mean_wt_sand_cone',
        },
        {
            text: 'Mean wt of sand + Cylinder after Pouring W3, (kg)',
            dataField: 'mean_wt_sand_cylinder_after',
        },

        {
            text: 'Wt of sand to fill calibrating Container ( Wa= W1-W3-W2) (kg)',
            dataField: 'wt_sand_fill',
        },
        {
            text: 'Vol of Calibrating Container (V), in (ml)',
            dataField: 'vol_calibrate',
        },
        {
            text: 'Bulk Density of Sand  (γa)',
            dataField: 'bulk_density_1',
        },
    ]
    const soilDensity = [
        { text: 'Location', dataField: 'location' },
        {
            text: 'Wt of wet soil from hole (Ww) in kg',
            dataField: 'wt_wet_soil',
        },
        {
            text: 'Wt of Sand + Cylinder After pouring (W4) in {kg}',
            dataField: 'wt_sand_cylinder_after',
        },

        {
            text: 'Wt of sand in hole ( Wb = W1-W4-W2) in (kg)',
            dataField: 'wt_sand_hole',
        },
        {
            text: 'Bulk Density γb =(((Ww/Wb)*γa)/1000) kg /m3)',
            dataField: 'bulk_density_2',
        },
        {
            text: 'Water Content (w) percent',
            dataField: 'water_content',
        },
        {
            text: 'Dry Density  γd = (100*γb) / (100+W)',
            dataField: 'dry_density',
        },
        {
            text: 'Maximum Dry Density, MDD (g/cc)',
            dataField: 'maximumDryDensity',
        },
        {
            text: '% of Compaction(%),((yd/MDD)*100)',
            dataField: 'compaction',
        },
    ]

    return (
        <Box sx={{ width: '100%', overflowX: 'auto' }}>
            <Grid container spacing={2}>
                <Grid item>
                    <Typography>Calibration</Typography>

                    <LargeStyledTable>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">S.No</TableCell>
                                {calibration.map((item, index) => (
                                    <TableCell align="center" key={index}>
                                        {item.text}
                                    </TableCell>
                                ))}
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center">
                                        {index + 1}
                                    </TableCell>
                                    {calibration.map((subItem, idx) => (
                                        <TableCell
                                            align="center"
                                            key={`row-${idx}-index`}
                                        >
                                            {valueCalc(item[subItem.dataField])}
                                        </TableCell>
                                    ))}
                                    <TableCell align="center">
                                        <Button
                                            onClick={(e) =>
                                                handleDelete(index, e)
                                            }
                                        >
                                            <Icon>delete</Icon>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </LargeStyledTable>
                </Grid>
                <Grid item>
                    <Typography>Soil Dry Density</Typography>
                    <LargeStyledTable>
                        <TableHead>
                            <TableRow>
                                {/* <TableCell align="center">S.No</TableCell> */}
                                {soilDensity.map((item, index) => (
                                    <TableCell align="center" key={index}>
                                        {item.text}
                                    </TableCell>
                                ))}
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((item, index) => (
                                <TableRow key={index}>
                                    {/* <TableCell align="center">
                                        R{index + 1}
                                    </TableCell> */}
                                    {soilDensity.map((subItem, idx) => (
                                        <TableCell
                                            align="center"
                                            key={`row-${idx}-index`}
                                        >
                                            {valueCalc(item[subItem.dataField])}
                                        </TableCell>
                                    ))}
                                    <TableCell align="center">
                                        <Button
                                            onClick={(e) =>
                                                handleDelete(index, e)
                                            }
                                        >
                                            <Icon>delete</Icon>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </LargeStyledTable>
                </Grid>
            </Grid>
        </Box>
    )
}

export default SandReplacementMethodTable
