import { AddCircle, Edit } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Modal,
    TextField,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
    StyledIcon,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import { useContext, useState } from 'react'
import * as Yup from 'yup'
import { valueCalc } from '../../method/method'
import CrushingValueTable from './CrushingValueTable'

const initialValues = {
    weight_surface_dry_sample: '',
    weight_fraction_passing_through: '',
    aggregate_crushing_value: '',
}
const validationSchema = Yup.object().shape({
    weight_surface_dry_sample: Yup.number().required('Required'),
    weight_fraction_passing_through: Yup.number().required('Required'),
})

const CrushingValueModal = ({ result, isView, overall, setOverall }) => {
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [data, setData] = useState(
        result?.overall ? result?.overall : { ...initialValues }
    )
    const [initialState, setInitialState] = useState({ ...initialValues })

    const { finalData, setFinalData } = useContext(AppContext)

    const handleSubmit = (values, { resetForm }) => {
        let value =
            (values.weight_fraction_passing_through /
                values.weight_surface_dry_sample) *
            100
        values.aggregate_crushing_value = value ? valueCalc(value) : '0'

        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName) {
                item.overall = {
                    ...values,
                    requirements: overall.requirements,
                    remarks: overall.remarks,
                    requirementStatus: overall.requirementStatus,
                    remarkStatus: overall?.remarkStatus,
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData(values)
        setInitialState({ ...initialValues })
        setOpen(false)
    }
    const handleDeleteTable = () => {
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName) {
                setFinalData(
                    ...finalData,
                    (item.overall = {
                        requirements: overall.requirements,
                        requirementStatus: overall.requirementStatus,
                        remarkStatus: overall?.remarkStatus,
                    })
                )
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData({ ...initialState })
    }

    let length = Object.keys(result.overall).length

    return (
        <Grid container soacing={2}>
            <Grid item xs={12}>
                <Formik
                    initialValues={initialState}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        } = props
                        return (
                            <Form>
                                <Modal open={open} onClose={handleClose}>
                                    <StyledCard>
                                        <CardHeader
                                            title={`Aggregate ${result?.groupName}`}
                                        />
                                        <CardContent
                                            sx={{
                                                m: 4,
                                            }}
                                        >
                                            <Grid container spacing={4}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Weight of surface dry sample, A (kg)"
                                                        value={
                                                            values.weight_surface_dry_sample
                                                        }
                                                        name="weight_surface_dry_sample"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={
                                                            errors.weight_surface_dry_sample &&
                                                            touched.weight_surface_dry_sample
                                                        }
                                                    />
                                                    <HelperText>
                                                        {errors.weight_surface_dry_sample &&
                                                        touched.weight_surface_dry_sample
                                                            ? errors.weight_surface_dry_sample
                                                            : ''}
                                                    </HelperText>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Weight of fraction passing through 2.36mm seive after loading, B (kg)"
                                                        value={
                                                            values.weight_fraction_passing_through
                                                        }
                                                        name="weight_fraction_passing_through"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={
                                                            errors.weight_fraction_passing_through &&
                                                            touched.weight_fraction_passing_through
                                                        }
                                                    />
                                                    <HelperText>
                                                        {errors.weight_fraction_passing_through &&
                                                        touched.weight_fraction_passing_through
                                                            ? errors.weight_fraction_passing_through
                                                            : ''}
                                                    </HelperText>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <CardActions>
                                            <Button
                                                size="small"
                                                onClick={handleClose}
                                            >
                                                Cancel
                                            </Button>
                                            {data === result?.overall ? (
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    onClick={handleSubmit}
                                                >
                                                    Edit
                                                </Button>
                                            ) : (
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    onClick={handleSubmit}
                                                >
                                                    Save
                                                </Button>
                                            )}
                                        </CardActions>
                                    </StyledCard>
                                </Modal>
                            </Form>
                        )
                    }}
                </Formik>
                {data && (
                    <CrushingValueTable
                        data={data}
                        setData={setData}
                        setFinalData={setFinalData}
                        result={result}
                        finalData={finalData}
                    />
                )}
                {isView !== true && (
                    <Grid container justifyContent="end">
                        <IconButton
                            size="small"
                            onClick={(e) => handleDeleteTable()}
                            sx={{ boxShadow: 'none', mt: 2 }}
                        >
                            <StyledIcon>delete</StyledIcon>
                        </IconButton>
                        {length > 1 ? (
                            <Button
                                sx={{ boxShadow: 'none', mt: 2 }}
                                variant="contained"
                                endIcon={<Edit />}
                                onClick={handleOpen}
                                size="small"
                            >
                                Edit
                            </Button>
                        ) : (
                            <Button
                                sx={{ boxShadow: 'none', mt: 2 }}
                                variant="contained"
                                endIcon={<AddCircle />}
                                onClick={handleOpen}
                                size="small"
                            >
                                Add
                            </Button>
                        )}
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}

export default CrushingValueModal
