import { useContext, useState } from 'react'

import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    InputLabel,
    Modal,
    TextField,
    Typography,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { valueCalc } from '../../method/method'
import FlexuralStrengthTable from './FlexuralStrengthTable'

const initialValues = {
    sampleId: '',
    thickness: '',
    length: '',
    breadth: '',
    height: '',
    ulti_load: '',
    fluxural_strength: '',
}

const validationSchema = Yup.object().shape({
    // id: Yup.string().required('Required'),
    sampleId: Yup.string().required('Required'),
    thickness: Yup.number().required('Required'),
    length: Yup.number().required('Required'),
    breadth: Yup.number().required('Required'),
    height: Yup.number().required('Required'),
    ulti_load: Yup.number().required('Required'),
})
const FlexuralStrengthModal = ({ result, isView, overall, setOverall }) => {
    const handleClose = () => setOpen(false)
    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)
    const [data, setData] = useState(result.data ? result.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const { finalData, setFinalData } = useContext(AppContext)
    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        arr = applyFormula(arr)
        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && arr.length !== 0) {
                let average = 0
                let total = 0
                arr.forEach((item1) => {
                    total += Number(item1.fluxural_strength)
                    average = Number(total / arr.length)
                })
                item.overall = {
                    ...overall,
                    average: average === 0 ? null : valueCalc(average),
                }
                setOverall(item.overall)
            }
            if (item.groupName === result.groupName) {
                item.data = arr
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
    }

    const applyFormula = (arr) => {
        arr.forEach((item) => {
            item.fluxural_strength = valueCalc(
                (3 * item.ulti_load * item.length) /
                    (2 * item.breadth * (item.thickness * item.thickness))
            )
        })
        return arr
    }

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props
                    return (
                        <Form>
                            <Modal open={open} onClose={handleClose}>
                                <StyledCard>
                                    <CardHeader title={result?.groupName} />
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Sample ID"
                                                    value={values.sampleId}
                                                    name="sampleId"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.sampleId &&
                                                        touched.sampleId
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.sampleId &&
                                                    touched.sampleId
                                                        ? errors.sampleId
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Thickness"
                                                    value={values.thickness}
                                                    name="thickness"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.thickness &&
                                                        touched.thickness
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.thickness &&
                                                    touched.thickness
                                                        ? errors.thickness
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Length, L (mm)"
                                                    value={values.length}
                                                    name="length"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.length &&
                                                        touched.length
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.length &&
                                                    touched.length
                                                        ? errors.length
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Breadth, B (mm)"
                                                    value={values.breadth}
                                                    name="breadth"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.breadth &&
                                                        touched.breadth
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.breadth &&
                                                    touched.breadth
                                                        ? errors.breadth
                                                        : ''}
                                                </HelperText>
                                            </Grid>{' '}
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Height, H (mm)"
                                                    value={values.height}
                                                    name="height"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.height &&
                                                        touched.height
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.height &&
                                                    touched.height
                                                        ? errors.height
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Ultimate Load (kN),P"
                                                    value={values.ulti_load}
                                                    name="ulti_load"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.ulti_load &&
                                                        touched.ulti_load
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.ulti_load &&
                                                    touched.ulti_load
                                                        ? errors.ulti_load
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </StyledCard>
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
            {data && (
                <FlexuralStrengthTable
                    data={data}
                    setData={setData}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    result={result}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {isView !== true && (
                <Grid container justifyContent="end">
                    <Button
                        sx={{ boxShadow: 'none', mt: 2 }}
                        variant="contained"
                        endIcon={<AddCircle />}
                        onClick={handleOpen}
                        size="small"
                    >
                        Add
                    </Button>
                </Grid>
            )}
            <Grid>
                <InputLabel>
                    Note: L = Length , b = Breadth , d = Thickness
                </InputLabel>
            </Grid>
        </Grid>
    )
}

export default FlexuralStrengthModal
