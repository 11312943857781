import React from 'react'
import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Modal,
    TextField,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import { useContext, useState } from 'react'
import * as Yup from 'yup'
import { valueCalc } from '../../../method/method'
import ThermalConductivityTable from '../ThermalConductivityTable'

const initialValues = {
    sampleId: '',
    len: '',
    breadth: '',
    height: '',
    dry_weight: '',
    thermal_conductivity: '',
    average: '',
}
const validationSchema = Yup.object().shape({
    // id: Yup.string().required('Required'),
    // len: Yup.number().required('Required'),
    // breadth: Yup.number().required('Required'),
    // height: Yup.number().required('Required'),
    thermal_conductivity: Yup.number().required('Required'),
})

const ThermalConductivityModal = (props) => {
    const { result, isView, overall, setOverall } = props
    const handleClose = () => setOpen(false)
    const [open, setOpen] = useState(false)
    const [data, setData] = useState(result?.data ? result?.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })

    const handleOpen = () => setOpen(true)
    const { finalData, setFinalData } = useContext(AppContext)
    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        let totalConductivity = 0
        arr.forEach((item) => {
            totalConductivity += Number(item.thermal_conductivity)
        })
        let avgConductivity = totalConductivity / arr.length

        let _finalData = finalData
        _finalData.forEach((item) => {
            if (item?.groupName === result?.groupName && arr.length !== 0) {
                item.overall = {
                    ...overall,
                    average: avgConductivity,
                }
                setOverall(item.overall)
            }
            if (item.groupName === result.groupName) {
                item.data = arr
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
    }

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props
                    return (
                        <Form>
                            <Modal open={open} onClose={handleClose}>
                                <StyledCard>
                                    <CardHeader title="Thermal Conductivity" />
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Length , L (mm)"
                                                    value={values.len}
                                                    name="len"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.len &&
                                                        touched.len
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.len && touched.len
                                                        ? errors.len
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Breadth , B (mm)"
                                                    value={values.breadth}
                                                    name="breadth"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.breadth &&
                                                        touched.breadth
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.breadth &&
                                                    touched.breadth
                                                        ? errors.breadth
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Height, H (mm)"
                                                    value={values.height}
                                                    name="height"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.height &&
                                                        touched.height
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.height &&
                                                    touched.height
                                                        ? errors.height
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Dry Weight in Kgs"
                                                    value={values.dry_weight}
                                                    name="dry_weight"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    // error={
                                                    //     errors.dry_weight &&
                                                    //     touched.dry_weight
                                                    // }
                                                />
                                                {/* <HelperText>
                                                    {errors.dry_weight &&
                                                    touched.dry_weight
                                                        ? errors.dry_weight
                                                        : ''}
                                                </HelperText> */}
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Thermal conductivity in W/m.k"
                                                    value={
                                                        values.thermal_conductivity
                                                    }
                                                    name="thermal_conductivity"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.thermal_conductivity &&
                                                        touched.thermal_conductivity
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.thermal_conductivity &&
                                                    touched.thermal_conductivity
                                                        ? errors.thermal_conductivity
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            {/* <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Average in W/m.k"
                                                    value={values.average}
                                                    name="average"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.average &&
                                                        touched.average
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.average &&
                                                    touched.average
                                                        ? errors.average
                                                        : ''}
                                                </HelperText>
                                            </Grid> */}
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </StyledCard>
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
            {data && (
                <ThermalConductivityTable
                    data={data}
                    setData={setData}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    result={result}
                    overall={overall}
                    setOverall={setOverall}
                />
            )}
            {isView !== true && (
                <Grid item container justifyContent="end">
                    <Button
                        sx={{ boxShadow: 'none', mt: 3 }}
                        variant="contained"
                        endIcon={<AddCircle />}
                        onClick={handleOpen}
                        size="small"
                    >
                        Add
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default ThermalConductivityModal
