import {
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const DryingShrinkageNewTable = ({
    data,
    setData,
    setFinalData,
    finalData,
    result,
    setOverall,
    overall,
}) => {
    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        let dryingShrinkage = 0
        let moistureMovement = 0
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && item.data.length > 0) {
                _data.forEach((item1) => {
                    // item1.l1 = Number(
                    //     item1.wet_len * item1.wet_breadth * item1.wet_height
                    // )
                    // item1.l2 = Number(
                    //     item1.dry_len * item1.dry_breadth * item1.dry_height
                    // )
                    let value = valueCalc(
                        ((item1.wet_len - item1.dry_len) / item1.dry_len) * 100
                    )
                    console.log(value, 'value')
                    // item1.dryingShrinkage = value
                    // dryingShrinkage += Number(value / _data.length)
                    if (result.groupName === 'Moisture Movement') {
                        item1.moistureMovement = value
                        moistureMovement += Number(value / _data.length)
                    } else {
                        item1.dryingShrinkage = value
                        dryingShrinkage += Number(value / _data.length)
                    }
                })
            }
            if (item.groupName === result.groupName) {
                item.data = _data
                item.overall = {
                    ...overall,
                    // dryingShrinkage: valueCalc(dryingShrinkage),
                    [result.groupName === 'Moisture Movement'
                        ? 'moistureMovement'
                        : 'dryingShrinkage']: valueCalc(
                        result.groupName === 'Moisture Movement'
                            ? moistureMovement
                            : dryingShrinkage
                    ),
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
    }

    const Column = [
        { text: 'Length, L (mm)', dataField: 'wet_len' },
        { text: 'Breadth, B (mm)', dataField: 'breadth' },
        { text: 'Height, H (mm)', dataField: 'height' },
        { text: 'L1 (mm)', dataField: 'l1' },
        { text: 'Length, L (mm)', dataField: 'dry_len' },
        { text: 'Breadth, B (mm)', dataField: 'dry_breadth' },
        { text: 'Height, H (mm)', dataField: 'dry_height' },
        { text: 'L2 (mm)', dataField: 'l2' },
    ]

    return (
        <StyledTable>
            <TableHead>
                <TableRow>
                    <TableCell align="center">Sample ID</TableCell>

                    <TableCell align="center">
                        Dimension in mm (Wet) - L1
                    </TableCell>
                    <TableCell align="center">
                        Dimension in mm (Dry) - L2
                    </TableCell>
                    <TableCell align="center">Breadth, B (mm)</TableCell>
                    <TableCell align="center">Height, H (mm)</TableCell>
                    {/* <TableCell align={'center'}>
                        Drying Shrinkage((L1-L2)/L2*100)
                    </TableCell> */}
                    <TableCell align="center">
                        {result.groupName === 'Moisture Movement'
                            ? 'Moisture Movement((L1-L2)/L2*100)'
                            : 'Drying Shrinkage((L1-L2)/L2*100)'}
                    </TableCell>
                    <TableCell align={'center'}>Actions</TableCell>
                </TableRow>
                {/* <TableRow>
                    {Column.map((item, index) => (
                        <TableCell colSpan={2} align={'center'} key={index}>
                            // {item.text}
                        </TableCell>
                    ))}
                </TableRow> */}
            </TableHead>
            <TableBody>
                {data?.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell align="center">
                            {(item.sampleId = index + 1)}
                        </TableCell>

                        <TableCell align="center">{item.wet_len}</TableCell>

                        <TableCell align="center">{item.dry_len}</TableCell>

                        <TableCell align="center">{item.breadth}</TableCell>

                        <TableCell align="center">{item.height}</TableCell>
                        {/* {Column.map((subItem, idx) => (
                            <TableCell
                                align="center"
                                colSpan={2}
                                key={`row-${idx}-index`}
                            >
                                {item[subItem.dataField]}
                            </TableCell>
                        ))} */}
                        <TableCell align="center">
                            {result.groupName === 'Moisture Movement'
                                ? item.moistureMovement
                                : item.dryingShrinkage}
                        </TableCell>
                        <TableCell align="center">
                            <Button onClick={(e) => handleDelete(index, e)}>
                                <Icon>delete</Icon>
                            </Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </StyledTable>
    )
}

export default DryingShrinkageNewTable
