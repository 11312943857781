import { Box, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const CementSoundnessTable = (props) => {
    const { data } = props

    let moistureContent

    moistureContent = [
        {
            label: 'Initial Measurement in mm',
            value: 'initial_measurement_in_mm',
        },
        {
            label: 'Final Measurement in mm',
            value: 'final_measurement_in_mm',
        },
        {
            label: 'Le-chatlier Soundness , (Final-Initial)',
            value: 'total_measurement',
        },
    ]

    const Column = [
        {
            text: 'Observation',
            dataField: 'label',
        },
        {
            text: 'Result',
            dataField: 'value',
        },
    ]

    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">SI.No</TableCell>
                        {Column.map((item, index) => (
                            <TableCell align="center" key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {moistureContent?.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{item.label}</TableCell>
                            <TableCell align="center">
                                {data[item.value] ? data[item.value] : '0'}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default CementSoundnessTable
