import { Box, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { StyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'

const InitialSettingTimeTable = ({ data, initialState }) => {
    let initalSettingTime = [
        {
            observation: 'Initial Setting Time',
            value: 'initialSettingTime',
        },
    ]

    const Column = [
        { text: 'Observation', dataField: 'observation' },
        { text: 'Result', dataField: 'value' },
    ]
    return (
        <Box width="100%">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        {/* <TableCell align="center">Actions</TableCell> */}
                        <TableCell align="center">SI.No</TableCell>
                        {Column.map((item, index) => (
                            <TableCell align="center" key={index}>
                                {item.text}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {initalSettingTime?.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">
                                {item.observation}
                            </TableCell>
                            <TableCell align="center">
                                {data[item.value] ? data[item.value] : '-'}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default InitialSettingTimeTable
