import { useTheme } from '@emotion/react'
import {
    Small,
    StyledIcon,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { useEffect, useRef } from 'react'

function usePrevious(value) {
    const ref = useRef()
    useEffect(() => {
        ref.current = value //assign the value of ref to the argument
    }, [value]) //this code will run when the value of 'value' changes
    return ref.current //in the end, return the current ref value.
}

export default usePrevious

export const StatusBadge = (status) => {
    const { palette } = useTheme()

    const bgError = palette.error.dark
    const bgPrimary = palette.primary.main
    const bgWarning = palette.warning.main
    const bgSuccess = palette.success.main

    if (status === 'TODO') return <Small bgcolor={bgPrimary}> TODO </Small>
    else if (status === 'COMPLETE')
        return <Small bgcolor={bgSuccess}> Completed </Small>
    else if (status === 'APPROVED')
        return <Small bgcolor={bgPrimary}> Approved </Small>
    else if (status === 'PAID') return <Small bgcolor={bgWarning}> Paid </Small>
    else if (status === 'ARCHIVED')
        return <Small bgcolor={bgError}>Deleted</Small>
    else return <Small bgcolor={bgWarning}> Progress</Small>
}

export const AssigneeBadge = (assignee) => {
    const { palette } = useTheme()

    const bgError = palette.error.dark

    const bgSuccess = palette.success.main

    if (assignee === 1)
        return <StyledIcon bgcolor={bgSuccess}>person</StyledIcon>
    else if (assignee > 1)
        return <StyledIcon bgcolor={bgSuccess}>group</StyledIcon>
    else return <StyledIcon bgcolor={bgError}> no_accounts</StyledIcon>
}
